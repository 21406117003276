const SCRIPT_MODALS = {
  STATE_PRIVACY: 'state-privacy',
  BOOK_ORDER: 'book-order',
};

const WRAP_UP_AND_CROSS_SELL_SCRIPT = [
  {
    value:
      'Before we wrap up there are a couple of other services our customers find beneficial and just wanted to quickly walk you through those.',
  },
  {
    id: 1,
    value:
      "Firstly as far as boxes for your move go, we can have those dropped shipped to your door for you so you don't have to worry about trying to fit them all in your car. Would you like for me to order you some boxes and have them delivered for you?",
  },
  {
    id: 2,
    value:
      '(SKIP IF DISCUSSED IN CALL) We can also help with packing those boxes as well. Do you think you will need any assistance getting packed up and “move ready”?',
  },
  {
    id: 3,
    value:
      "Lasty, we also offer item removal services/donation services. Do you have any items that you won't be taking to your new home that you would like for us to take care of?(If interested) Perfect, I can get you prices for those right after I finish placing your order.",
  },
  {
    value:
      '(Services not needed) No worries, if you get closer to move day and feel like you need any of the additional services, please feel free to reach back out and we can get you taken care of.',
  },
];

// eslint-disable-next-line import/prefer-default-export
export { SCRIPT_MODALS, WRAP_UP_AND_CROSS_SELL_SCRIPT };
