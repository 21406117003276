import React from 'react';

import { useCreate, useNotify, usePermissions, useRecordContext, useRefresh } from 'react-admin';
import { useNavigate } from 'react-router';

import { Box, Button } from '@mui/material';

import * as resources from '@/api/resources';

const JobsAddRecruitButton = () => {
  const { permissions } = usePermissions();
  const canAddRecruit = permissions?.tools?.hqadmin?.recruit_jobslot?.create;

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const record = useRecordContext();
  const navigate = useNavigate();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error in creating recruit job slot! - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      notify(`Created recruit job slot`, {
        type: 'success',
      });
      refresh();
      navigate(`/${resources.JOB_SLOTS}/${data?.id}/show`);
    }
  };

  const createRecruit = () => {
    create(`${resources.JOBS}/${record.id}/recruit_job_slot`, null, { onSettled });
  };

  const handleButtonClick = () => {
    createRecruit();
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {canAddRecruit ? (
        <Button onClick={handleButtonClick} variant="contained">
          Add Recruit
        </Button>
      ) : null}
    </Box>
  );
};

export default JobsAddRecruitButton;
