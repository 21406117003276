import React from 'react';

import { string } from 'prop-types';

import { Box } from '@mui/material';

import { THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';
import PayoutConfig from '@/shared/accounts/PayoutConfig';

import Availability from './Availability';
import CrewLeads from './CrewLeads';
import Details from './Details';
import JobHistory from './JobHistory';
import Performance from './Performance';
import Skills from './Skills';
import Support from './Support';
import Transactions from './Transactions';

const ThirdPartyCrewContent = ({ currentTab }) => {
  let content;
  switch (currentTab) {
    case THIRD_PARTY_CREW_ACCOUNT_TAB.AVAILABILITY:
      content = <Availability />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.CREW_LEADS:
      content = <CrewLeads />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS:
      content = <Details />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.JOB_HISTORY:
      content = <JobHistory />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.PAYOUT_CONFIG:
      content = <PayoutConfig />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.PERFORMANCE:
      content = <Performance />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.SKILLS:
      content = <Skills />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.SUPPORT:
      content = <Support />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_TAB.TRANSACTIONS:
      content = <Transactions />;
      break;
    default:
      content = <>Unhandled</>;
  }
  return <Box>{content}</Box>;
};

ThirdPartyCrewContent.propTypes = {
  currentTab: string.isRequired,
};

export default ThirdPartyCrewContent;
