import React from 'react';

import { Form, required, SaveButton, SelectInput, ShowButton, useNotify, useRecordContext, useRedirect } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { BACKGROUND_CHECK } from '@/constants/account';

const ProsDetailsRecruitmentEdit = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const onError = (data) => {
    const error = data.body.detail;
    const displayErrorMessage = error?.[0]?.msg ?? 'Unknown Error';
    notify(`Error on updating worker account: ${displayErrorMessage}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    notify(`Account updated`, {
      type: 'success',
    });
    redirect('show', resources.ACCOUNTS, record?.id);
  };

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Recruitment" />

      <Divider />

      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                source="background_check"
                label="Background Check"
                validate={[required()]}
                choices={BACKGROUND_CHECK}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Update" icon={null} mutationOptions={{ onError, onSuccess }} type="button" />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default ProsDetailsRecruitmentEdit;
