import React, { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import {
  BooleanInput,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  required,
  useGetList,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useReference,
  useRefresh,
  useShowContext,
  useStore,
  useUnselectAll,
  useUpdate,
} from 'react-admin';

import { AddCircleOutline, Warning } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardHeader, Tooltip, Typography } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import { PREFERENCES_KEYS } from '@/constants/preferences';
import QualificationLevelField from '@/fields/QualificationLevelField';
import { JobSlotActionContext } from '@/providers/JobSlotAction';
import LinkButton from '@/shared/LinkButton';
import ListViewChangedTracker from '@/shared/events/ListViewChangedTracker';
import prefDayJs from '@/utils/datetime/prefDayJs';
import getServiceName from '@/utils/serviceName/getServiceName';

import AccountActionsField from '../AccountAccountField';

dayjs.extend(utc);
dayjs.extend(timezone);

const eligibleFilters = [
  <SelectInput
    source="labor_status"
    label="Type"
    alwaysOn
    validate={required()}
    choices={[
      { id: 'available', name: 'Available' },
      { id: 'unsubmitted', name: 'Unsubmitted' },
      { id: 'unavailable', name: 'Unavailable' },
      { id: 'potential', name: 'Potential' },
      { id: 'assigned', name: 'Assigned' },
    ]}
  />,
  <BooleanInput source="underqualified" alwaysOn />,
];

const EligibleWorkersActionButtons = () => {
  const { selectedIds } = useListContext();
  const { record: jobSlotRecord } = useShowContext();

  const { referenceRecord: jobRecord } = useReference({
    reference: resources.JOBS,
    id: jobSlotRecord?.job_id,
    options: { enabled: Boolean(jobSlotRecord?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobRecord?.order_id,
    enabled: Boolean(jobRecord?.order_id),
  });

  const { data: locationsData } = useGetList(
    resources.LOCATIONS,
    { filter: { job_id: jobRecord?.id }, sort: { field: 'sequence', order: 'ASC' } },
    { enabled: Boolean(jobRecord?.id) },
  );

  const metadata = {
    job_id: jobSlotRecord.job_id,
    job_slot_id: jobSlotRecord.id,
  };

  const title = `${getServiceName(jobRecord?.product_id)}`;
  const startingLocation = locationsData?.[0]; // 0 index is always lowest sequence location as the getList call above enforcing sorting on sequence ASC
  const startingLocationString = `${startingLocation?.street_address_1}, ${startingLocation?.city}`;
  const message = `Hey this is Bellhop HQ. We have an open ${getServiceName(jobRecord?.product_id)} job on ${dayjs(
    jobRecord?.start_date_time,
  ).format(
    'MMM D, YYYY @ h:mm A',
  )}, starting at ${startingLocationString}. If you're interested, claim the job below.\n\nBest,\nHopper`;

  const trackFulfillmentMessage = () => {
    mx.track('Fulfillment - Eligible Labor - Create Message', {
      jobId: jobSlotRecord?.job_id,
      jobSlotId: jobSlotRecord?.id,
      confirmationId: orderData?.confirmation_id,
      selectedIds,
    });
  };

  return (
    <LinkButton
      label="Create Message"
      to="/communications/create"
      state={{
        record: {
          user_ids: selectedIds,
          title,
          message,
          metadata,
        },
      }}
      onClick={trackFulfillmentMessage}
    />
  );
};

const AssignField = () => {
  const { setAction, setMetadata } = useContext(JobSlotActionContext);
  const accountRecord = useRecordContext();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const notify = useNotify();
  const { record: jobSlotRecord } = useShowContext();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: jobSlotRecord?.job_id,
    options: { enabled: Boolean(jobSlotRecord?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const onError = (error) => {
    const overrideObj = { message: '', account_id: '' };
    overrideObj.account_id = accountRecord?.id;

    const errorMessage = error?.body?.detail;

    if (errorMessage.includes(accountRecord?.id)) {
      overrideObj.message = errorMessage.replace(accountRecord?.id, accountRecord?.name);
    } else {
      overrideObj.message = errorMessage;
    }

    setMetadata(overrideObj);
    setAction('override_assign');
  };

  const onSuccess = () => {
    mx.track('Fulfillment - Labor Assigned', {
      resource: resources.JOB_SLOTS,
      resourceId: jobSlotRecord?.id,
      parentResource: resources.JOBS,
      parentResourceId: jobSlotRecord?.job_id,
      confirmationId: orderData?.confirmation_id,
      assignedId: accountRecord?.id,
    });
    notify(`${accountRecord.name} assigned`, {
      type: 'success',
    });

    refresh();
    redirect('show', resources.JOBS, jobSlotRecord.job_id);
  };

  const [update] = useUpdate(
    resources.JOB_SLOTS,
    {
      id: jobSlotRecord?.id,
      data: { account_id: accountRecord.id },
      previousData: {},
    },
    { mutationMode: 'pessimistic', onError, onSuccess },
  );

  if (jobSlotRecord?.account_id) {
    // Remove assign button on assigned slots - must unassign first
    return null;
  }

  return (
    <Button
      startIcon={<AddCircleOutline />}
      onClick={(e) => {
        e.preventDefault();
        update();
      }}
    >
      Assign
    </Button>
  );
};

const DistanceField = ({ source }) => {
  const record = useRecordContext();

  if (!record) return null;

  if (!record[source])
    return (
      <Box display="flex">
        <Tooltip
          title={
            <Typography
              variant="body3"
              whiteSpace="pre-wrap"
            >{`Unable to calculate distance from pro to job\nCheck that pro's address on file is valid`}</Typography>
          }
        >
          <Warning color="warning" />
        </Tooltip>
      </Box>
    );
  return <TextField label="Distance" source="est_distance" />;
};

DistanceField.propTypes = {
  source: string.isRequired,
};

const EligibleWorkersDatagrid = () => {
  const { record } = useShowContext();
  const { selectedIds } = useListContext();

  const [timePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const { permissions } = usePermissions();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const [prevSelectedIds, setPrevSelectedIds] = useState([]);

  const canCreateCommunication = permissions.tools?.fulfillment?.communication?.create;
  const canAssign = permissions.tools?.fulfillment?.job_slot?.update && permissions.tools?.fulfillment?.job_slot?.assign;

  useEffect(() => {
    const selectedDiff = selectedIds.length - prevSelectedIds.length;

    if (selectedDiff === 0) {
      return;
    }
    let eventName = 'Resource List - Select';
    if (selectedDiff > 1) {
      eventName = 'Resource List - Select All';
    } else if (selectedDiff < -1) {
      eventName = 'Resource List - Unselect All';
    } else if (selectedDiff === 1) {
      eventName = 'Resource List - Select One';
    } else if (selectedDiff === -1) {
      eventName = 'Resource List - Unselect One';
    }
    mx.track(eventName, {
      resource: resources.ELIGIBLE_LABOR,
      parentResource: resources.JOB_SLOTS,
      parentResourceId: record?.id,
      grandparentResource: resources.JOBS,
      grandparentResourceId: record?.job_id,
      confirmationId: orderData?.confirmation_id,
      selectedIds,
    });
    setPrevSelectedIds(selectedIds);
  }, [selectedIds]);

  return (
    <Datagrid rowClick={null} bulkActionButtons={canCreateCommunication ? <EligibleWorkersActionButtons /> : false}>
      <FunctionField render={(rec) => <Avatar src={rec.image_url} />} sortable={false} />
      <ReferenceField reference={resources.ACCOUNTS} source="id" label="Name" link="show" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <QualificationLevelField source="skill_qualification_level" label="Skill Level" sortable={false} />
      <DistanceField label="Distance" source="est_distance" />
      <TextField label="Moves" source="worker_stats.lifetime_completed_jobs" sortable={false} />
      <TextField label="Rating" source="worker_stats.average_rating" sortable={false} />
      <NumberField label="Removal" source="worker_stats.removal_rate" options={{ style: 'percent' }} sortable={false} />
      <FunctionField
        label="Availability"
        render={({ availability }) => {
          if (typeof availability === 'object') {
            if (availability?.length > 0) {
              return (
                <Box>
                  {availability.map(({ start, end }) => {
                    const startDate = prefDayJs({
                      date: start,
                      timezone: jobData.start_timezone,
                      timePreference,
                    });
                    const endDate = prefDayJs({
                      date: end,
                      timezone: jobData.end_timezone,
                      timePreference,
                    });
                    return <Box>{`${startDate.format('hA')}-${endDate.format('hA')}`}</Box>;
                  })}
                </Box>
              );
            }
          } else {
            return <Box>Error</Box>;
          }
          return 'None';
        }}
      />
      {canAssign && <AssignField />}
      <AccountActionsField />
    </Datagrid>
  );
};

const EligibleWorkersList = () => {
  const { record } = useShowContext();

  const { referenceRecord: jobData, isLoading: isJobLoading } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const unselectAllEligibleLabor = useUnselectAll(resources.ELIGIBLE_LABOR);

  useEffect(() => {
    unselectAllEligibleLabor();
  }, []);

  if (!jobData || isJobLoading) return <>Loading</>;

  const UTC = 'Etc/UTC';

  const startJobDay = dayjs(jobData?.start_date_time).hour(0).minute(0);
  const endJobDay = dayjs(jobData?.start_date_time).hour(0).minute(0).add(1, 'day');

  let onlyRecruit = false;
  if (record?.maximum_qualification_level <= 0) {
    onlyRecruit = true;
  }

  return (
    <Card variant="outlined">
      <CardHeader title="Eligible Workers" />
      <List
        component={Box}
        resource={resources.ELIGIBLE_LABOR}
        filter={{
          job_slot_id: record?.id,
          aggregate_accounts: true,
          start_date_time: startJobDay.tz(UTC).format(),
          end_date_time: endJobDay.tz(UTC).format(),
          product_id: jobData?.product_id,
          recruit_only: onlyRecruit,
        }}
        filters={eligibleFilters}
        filterDefaultValues={{
          labor_status: 'available',
          recruit_only: onlyRecruit,
          underqualified: false,
        }}
        sort={{
          field: 'est_distance',
          order: 'ASC',
        }}
        actions={false}
        storeKey={`${resources.ELIGIBLE_LABOR}.listParams`}
        disableSyncWithLocation
      >
        <ListViewChangedTracker />
        <EligibleWorkersDatagrid />
      </List>
    </Card>
  );
};

EligibleWorkersList.propTypes = {};
EligibleWorkersDatagrid.propTypes = {};
AssignField.propTypes = {};

export default EligibleWorkersList;
