import React, { useEffect, useState } from 'react';

import { DateInput, NumberInput, ReferenceInput, SelectArrayInput, SelectInput, TextInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Grid } from '@mui/material';

import * as resources from '@/api/resources';
import {
  AVAILABILITY_TYPE,
  QUALIFICATION_LEVEL_LABOR,
  QUALIFICATION_LEVEL_TRANSIT,
  WORKER_SKILLS,
} from '@/constants/availabilityConfiguration';

const InputFilterSection = () => {
  const [selectedSkills, setSelectedSkills] = useState([]);

  const { setValue } = useFormContext();

  const productSkills = useWatch({ name: 'products' });

  const handleSelectArrayChange = (selectedValue) => {
    setSelectedSkills(selectedValue.target.value);
  };

  useEffect(() => {
    if (productSkills?.includes(WORKER_SKILLS[4].name)) {
      setValue('qualification_level', null);
    }
  }, [productSkills]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ReferenceInput source="markets" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
          <SelectArrayInput optionText="name" optionValue="id" label="Market" required fullWidth />
        </ReferenceInput>
      </Grid>

      <Grid item xs={4}>
        <SelectArrayInput
          label="Skills"
          source="products"
          choices={WORKER_SKILLS}
          onChange={handleSelectArrayChange}
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={4}>
        <SelectInput
          label="Qualification Level"
          source="qualification_level"
          choices={selectedSkills.includes(WORKER_SKILLS[4].name) ? QUALIFICATION_LEVEL_TRANSIT : QUALIFICATION_LEVEL_LABOR}
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={2}>
        <SelectInput label="Type" source="type" choices={AVAILABILITY_TYPE} required />
      </Grid>

      <Grid item xs={2}>
        <NumberInput label="Buffer/Blackout Count" source="quantity" required fullWidth />
      </Grid>

      <Grid item xs={2}>
        <DateInput label="Start Date" name="start_date" source="start_date" required fullWidth />
      </Grid>

      <Grid item xs={2}>
        <DateInput label="End Date" name="end_date" source="end_date" required fullWidth />
      </Grid>

      <Grid item xs={4}>
        <TextInput label="Reason" source="name" required multiline fullWidth />
      </Grid>
    </Grid>
  );
};

export default InputFilterSection;
