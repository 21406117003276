import React, { useContext } from 'react';

import { Link, ReferenceField, TextField, usePermissions, useRecordContext, useShowContext } from 'react-admin';

import { RemoveCircleOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Card, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_RECORD_TYPE_ARRAY } from '@/constants/account';
import { THIRD_PARTY_CREW_RECORD_TYPE } from '@/constants/thirdPartyCrewAccount';
import { JobSlotActionContext } from '@/providers/JobSlotAction';

import { THIRD_PARTY_WORKER_RECORD_TYPE } from '@/constants/thirdPartyWorkerAccount';
import AccountStats from '../AccountStats';

const AccountProfile = () => {
  const { setAction } = useContext(JobSlotActionContext);
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const { record: jobSlotRecord } = useShowContext();
  const isRemoved = jobSlotRecord?.status !== 'Removed';

  const canUnassign = permissions.tools?.fulfillment?.job_slot?.update && permissions.tools?.fulfillment?.job_slot?.unassign;

  const recordType = record?.account_record_type_name;
  const isCarrier = CARRIER_ACCOUNT_RECORD_TYPE_ARRAY.includes(recordType);
  const is3PC = recordType === THIRD_PARTY_CREW_RECORD_TYPE;
  const is3PW = recordType === THIRD_PARTY_WORKER_RECORD_TYPE;
  let url3PW = null;

  let resourceFlag = resources.ACCOUNTS;

  if (isCarrier) {
    resourceFlag = resources.CARRIERS;
  }

  if (is3PC) {
    resourceFlag = resources.THIRD_PARTY_CREW;
  }

  if (is3PW) {
    resourceFlag = resources.THIRD_PARTY_WORKER;
    url3PW = `/${resourceFlag}/${record.id}/show`;
  }

  if (!record) return null;

  return (
    <Card>
      <Box display="flex" flexDirection="column" alignItems="center" py={2}>
        <Avatar
          component={Box}
          src={record.image_url}
          sx={{
            width: 150,
            height: 150,
          }}
        />
        <Box mt={2}>
          {resourceFlag === resources.THIRD_PARTY_WORKER ? (
            <Link variant="h4" underline="none" to={url3PW}>
              {record.name}
            </Link>
          ) : (
            <ReferenceField reference={resourceFlag} source="id" label="Name" link="show" sortBy="false">
              <TextField source="name" variant="h4" />
            </ReferenceField>
          )}
        </Box>
        {canUnassign && isRemoved && (
          <Box mt={2}>
            <Button color="accent" startIcon={<RemoveCircleOutline color="accent" />} onClick={() => setAction('unassign')}>
              Unassign
            </Button>
          </Box>
        )}
      </Box>
      {resourceFlag === resources.ACCOUNTS ? (
        <>
          <Divider />
          <AccountStats />
        </>
      ) : null}
    </Card>
  );
};

AccountProfile.propTypes = {};

export default AccountProfile;
