import React from 'react';

import {
  Form,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
  required,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS, CARRIER_ACCOUNT_DROPDOWN_REASON } from '@/constants/carrierAccount';

const ProsDeactivateAccount = () => {
  const record = useRecordContext();

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error deactivating account - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      notify('deactivated account successfully!', {
        type: 'success',
      });
      refresh();
    }
  };

  const onSubmit = ({ reason, details }) => {
    const data = {
      reason,
      details,
    };

    create(
      resources.CARRIERS,
      {
        meta: {
          subResource: 'DETAILS/OVERVIEW/DEACTIVATEACCOUNT',
          resourceId: record.id,
        },
        data,
      },
      { onSettled },
    );
  };

  if (!record) return null;

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview - Deactivate Carrier" />

      <Divider />

      <Form onSubmit={onSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                source="new_status"
                choices={CARRIER_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS}
                validate={[required()]}
                fullWidth
                label="Status"
                defaultValue="Inactive"
              />
            </Grid>

            <Grid item xs={4}>
              <SelectInput
                source="reason"
                choices={CARRIER_ACCOUNT_DROPDOWN_REASON}
                validate={[required()]}
                fullWidth
                label="Status Reason"
              />
            </Grid>

            <Grid item xs={4}>
              <TextInput source="details" validate={[required()]} fullWidth label="Status Context" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Deactivate" icon={null} />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default ProsDeactivateAccount;
