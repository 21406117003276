import React from 'react';

import { useRecordContext } from 'react-admin';

import { Box, Card, Grid, Typography } from '@mui/material';

const ThirdPartyCrewHeader = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card px={3} py={2} component={Box}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h5" component="span" pr={1}>
            Third Party Crew:
          </Typography>
          <Box display="inline-flex" alignItems="center">
            <Typography variant="h5" mr={1}>
              {record?.name}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ThirdPartyCrewHeader;
