import React, { useContext } from 'react';

import { SCRIPT_MODALS } from '@/constants/scriptModals';
import { QuoteContext } from '@/providers/Quote';
import CreateOrder from '@/resources/quotes/shared/scriptPrompts/CreateOrder';
import StatePrivacy from '@/resources/quotes/shared/scriptPrompts/StatePrivacy';

const ScriptModals = () => {
  const { scriptModalStates, closeScriptModal } = useContext(QuoteContext);

  return (
    <>
      <StatePrivacy
        open={scriptModalStates?.[SCRIPT_MODALS.STATE_PRIVACY] ?? false}
        onClose={() => closeScriptModal(SCRIPT_MODALS.STATE_PRIVACY)}
      />
      <CreateOrder
        open={scriptModalStates?.[SCRIPT_MODALS.BOOK_ORDER] ?? false}
        onClose={() => closeScriptModal(SCRIPT_MODALS.BOOK_ORDER)}
      />
    </>
  );
};

export default ScriptModals;
