export const SERVICES = {
  FSLOADINGUNLOADING: {
    id: 'FSLOADINGUNLOADING',
    name: 'Full Service Labor',
  },
  HOURLYLABOR: {
    id: 'HOURLYLABOR',
    name: 'Hourly Labor',
  },
  LDLOADING: {
    id: 'LDLOADING',
    name: 'LD Loading',
  },
  LDTRANSIT: {
    id: 'LDTRANSIT',
    name: 'LD Truck / Driver',
  },
  LDUNLOADING: {
    id: 'LDUNLOADING',
    name: 'LD Unloading',
  },
  LOADING: {
    id: 'LOADING',
    name: 'Loading',
  },
  LOADINGUNLOADING: {
    id: 'LOADINGUNLOADING',
    name: 'Loading / Unloading',
  },
  PACKINGSERVICE: {
    id: 'PACKINGSERVICE',
    name: 'Packing',
  },
  TRANSIT: {
    id: 'TRANSIT',
    name: 'Truck / Driver (Local only)',
  },
  UNLOADING: {
    id: 'UNLOADING',
    name: 'Unloading',
  },
};

export const PRICING_LAYER_TYPES = {
  SERVICE: 'service',
  LANE: 'lane',
  DAY_OF_MONTH: 'day_of_month',
  DAY_OF_WEEK: 'day_of_week',
  MONTH_OF_YEAR: 'month_of_year',
  HOUR_OF_DAY: 'hour_of_day',
  LEAD_TIME: 'lead_time',
};

export const PRICING_LAYER_TYPES_DISPLAY = {
  [PRICING_LAYER_TYPES.SERVICE]: 'Service',
  [PRICING_LAYER_TYPES.LANE]: 'Lane',
  [PRICING_LAYER_TYPES.DAY_OF_MONTH]: 'Day of Month',
  [PRICING_LAYER_TYPES.DAY_OF_WEEK]: 'Day of Week',
  [PRICING_LAYER_TYPES.MONTH_OF_YEAR]: 'Month of Year',
  [PRICING_LAYER_TYPES.HOUR_OF_DAY]: 'Hour of Day',
  [PRICING_LAYER_TYPES.LEAD_TIME]: 'Lead Time',
};

export const PRICING_LAYER_TYPE_OPTIONS = Object.values(PRICING_LAYER_TYPES).map((type) => ({
  id: type,
  name: PRICING_LAYER_TYPES_DISPLAY[type],
}));

export const MARKET_GROUPS = {
  ALL: {
    id: 'ALL',
    name: 'All Markets',
  },
  CORE: {
    id: 'CORE',
    name: 'Core',
    markets: [
      'ATLANTA_GA',
      'BALTIMORE_MD',
      'CHATTANOOGA_TN',
      'CHARLOTTE_NC',
      'CHICAGO_IL',
      'CLEVELAND_OH',
      'COLUMBUS_OH',
      'DALLAS-FORT-WORTH_TX',
      'DENVER_CO',
      'HOUSTON_TX',
      'INDIANAPOLIS_IN',
      'KNOXVILLE_TN',
      'LAS_VEGAS_NV',
      'NASHVILLE_TN',
      'ORLANDO_FL',
      'PHILADELPHIA_PA',
      'PHOENIX_AZ',
      'RALEIGH-DURHAM-CHAPEL-HILL_NC',
      'TAMPA_FL',
      'WASHINGTON_D.C._DC',
    ],
  },
  BHN_HYBRID: {
    id: 'BHN_HYBRID',
    name: 'BHN Hybrid',
    markets: ['AUSTIN_TX', 'BIRMINGHAM_AL', 'NEW_YORK_NY', 'PITTSBURGH_PA', 'RICHMOND_VA', 'SAN_ANTONIO_TX'],
  },
  BHN_PURE: {
    id: 'BHN_PURE',
    name: 'BHN Pure',
    markets: [
      'BOSTON_MA',
      'CINCINNATI_OH',
      'COLORADO_SPRINGS_CO',
      'CORPUS_CHRISTI_TX',
      'DETROIT_MI',
      'JACKSONVILLE_FL',
      'KANSAS_CITY_MO',
      'LOS_ANGELES_CA',
      'LOUISVILLE_KY',
      'MEMPHIS_TN',
      'MIAMI_FL',
      'MINNEAPOLIS_MN',
      'MONTGOMERY_AL',
      'MYRTLE_BEACH_SC',
      'OMAHA_NE',
      'ORANGE_COUNTY_CA',
      'PORTLAND_OR',
      'ROCHESTER_NY',
      'SAINT_LOUIS_MO',
      'SALT_LAKE_CITY_UT',
      'SAN_DIEGO_CA',
      'SAN_FRANCISCO_CA',
      'SAN_JOSE_CA',
      'SEATTLE_WA',
      'TUCSON_AZ',
      'VENTURA_CA',
      'VIRGINIA_BEACH_VA',
    ],
  },
};
