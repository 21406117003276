import React from 'react';

import { string } from 'prop-types';
import {
  CreateBase,
  DateInput,
  Form,
  FormDataConsumer,
  ListButton,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import HiddenInput from '@/components/inputs/HiddenInput';
import { PROMO_CODE_STATUSES, PROMO_CODE_TYPES } from '@/constants/promoCode';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between">
    <ListButton resource={resources.PROMO_CODES} label="Cancel" icon={null} />
    <SaveButton label="Create" icon={null} />
  </Box>
);

const PromoCodeCreate = () => (
  <CreateBase resource={resources.PROMO_CODES} redirect="show">
    <Card mt={3} component={Box}>
      <CardHeader title="Create Promo Code" />

      <Divider />
      <Form record={{ promo_id_usage: 0 }}>
        <CardContent>
          <HiddenInput source="promo_id_usage" />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput source="name" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="description" fullWidth required />
            </Grid>

            <Grid item xs={4}>
              <SelectInput source="promo_id_status" label="Status" choices={PROMO_CODE_STATUSES} fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <NumberInput source="maximum_uses" fullWidth required min={0} />
            </Grid>
            <Grid item xs={4}>
              <DateInput source="start_date" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <DateInput source="expiration_date" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="promo_id_type" label="Type" choices={PROMO_CODE_TYPES} fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <PromoCodeAmountInput promoCodeType={formData.promo_id_type} {...rest} fullWidth required min={0} />
                )}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <FormControls />
        </CardContent>
      </Form>
    </Card>
  </CreateBase>
);

export default PromoCodeCreate;

const PromoCodeAmountInput = ({ promoCodeType, ...rest }) => {
  if (promoCodeType === 'Amount') {
    return <NumberInput source="promo_id_amount" label="Amount" {...rest} />;
  }

  if (promoCodeType === 'Percent') {
    return <NumberInput source="promo_id_percent" label="Percent" {...rest} />;
  }

  return null;
};
PromoCodeAmountInput.propTypes = {
  promoCodeType: string,
};
PromoCodeAmountInput.defaultProps = {
  promoCodeType: '',
};
