import React from 'react';

import { FunctionField } from 'react-admin';
import { string } from 'prop-types';

import CurrencyField from '../CurrencyField';
import PercentageField from '../PercentageField';

const PromoCodeAmountField = ({ amountSource, percentSource, typeSource }) => (
  <FunctionField
    label="Amount"
    render={(record) =>
      record[typeSource] === 'Percent' ? (
        <PercentageField source={percentSource} label="Percentage" isFraction={false} />
      ) : (
        <CurrencyField source={amountSource} textAlign="left" />
      )
    }
  />
);

PromoCodeAmountField.propTypes = {
  amountSource: string.isRequired,
  percentSource: string.isRequired,
  typeSource: string,
};

PromoCodeAmountField.defaultProps = {
  typeSource: 'promo_id_type',
};

export default PromoCodeAmountField;
