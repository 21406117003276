import * as React from 'react';

import { Edit, Form, SaveButton, SelectInput, required, useEditContext } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import * as resource from '@/api/resources';
import { JOB_MARKETPLACE_MATCH_STATUS } from '@/constants/jobStatus';

const validateMatchStatus = (value) => {
  if (value === JOB_MARKETPLACE_MATCH_STATUS[0].id) {
    return `User Cannot select ${value}, please select different option!`;
  }
  return undefined;
};

const MatchStatusForm = () => {
  const { record } = useEditContext();

  if (!record) return null;

  return (
    <Card mt={3} component={Box}>
      <Box py={4} mx={2}>
        <Typography variant="h5">Edit Job Details</Typography>
      </Box>

      <Divider />

      <CardContent>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                label="Match Status"
                source="marketplace_match_status"
                choices={JOB_MARKETPLACE_MATCH_STATUS}
                validate={[required(), validateMatchStatus]}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <SaveButton label="Update" icon={null} />
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};

const JobEditMatchStatus = () => (
  <Edit resource={resource.JOBS} mutationOptions={{ meta: { subResource: 'marketplace_match_status' } }} redirect="show">
    <MatchStatusForm />
  </Edit>
);

export default JobEditMatchStatus;
