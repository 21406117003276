import React from 'react';

import { Datagrid, DateField, FunctionField, ReferenceField, TextField, useRecordContext } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { CUSTOMER_ACCOUNT_TAB, CUSTOMER_ORDER_SECTION } from '@/constants/customerAccount';
import CurrencyField from '@/fields/CurrencyField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import CustomerChargesSectionHeader from '../Common/SectionHeader';

const OrderHistoryDatagrid = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card mt={3}>
      <CustomerChargesSectionHeader
        title="Order History"
        tab={CUSTOMER_ACCOUNT_TAB.ORDERS}
        section={CUSTOMER_ORDER_SECTION.ORDER_HISTORY}
      />
      <Divider />
      <Box>
        <List
          disableSyncWithLocation
          resource={resources.CUSTOMERS}
          queryOptions={{ meta: { subResource: 'order_history', resourceId: record.id } }}
          exporter={false}
          empty={<EmptyResourceList text="No Order history on Customer account" />}
          hasCreate={false}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField reference={resources.ORDERS} source="id" label="Confirmation #" link="show" sortable={false}>
              <TextField source="confirmation_id" label="Confirmation #" />
            </ReferenceField>
            <TextField source="status" label="Order Status" sortable={false} />
            <DateField source="created_at" label="Booked on" sortable={false} />
            <TextField source="partner" label="Partner" sortable={false} />
            <FunctionField label="LD" render={(order) => (order.long_distance ? 'LD' : 'Local')} />
            <CurrencyField source="total_amount" label="Total" textAlign="left" sortable={false} />
          </Datagrid>
        </List>
      </Box>
    </Card>
  );
};

const CustomerContentJobHistory = () => <OrderHistoryDatagrid />;

export default CustomerContentJobHistory;
