import React, { useContext } from 'react';

import dayjs from 'dayjs';
import mx from 'mixpanel-browser';
import {
  BooleanInput,
  DateInput,
  Form,
  minValue,
  RecordContextProvider,
  useGetOne,
  useNotify,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

import PaymentMethodAccount from '../../OrderContent/Invoice/PaymentMethods/PaymentMethodAccount';
import PaymentMethodCard from '../../OrderContent/Invoice/PaymentMethods/PaymentMethodCard';
import CurrencyInput from '../AddTips/CurrencyInput';

const EditChargeForm = () => {
  const { setAction } = useContext(OrderActionContext);
  const { control, formState } = useFormContext();

  const { record: { id: orderId } = {} } = useShowContext();

  const { data, error, isLoading } = useGetOne(
    resources.ORDERS,
    {
      id: orderId,
      meta: {
        subResource: 'payment_info',
      },
    },
    {
      retry: false,
    },
  );

  return (
    <>
      {isLoading ? (
        <Box pt={2} width="100%" textAlign="center">
          <CircularProgress />
        </Box>
      ) : null}
      {error ? (
        <>
          <Box p={2}>
            <Alert variant="filled" severity="error">
              Payment method not found for account on order - Cannot add charge
            </Alert>
          </Box>
          <Divider />
          <Box pt={2} px={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
              Cancel
            </Button>
          </Box>
        </>
      ) : null}
      {data ? (
        <>
          <Box p={2}>
            <Typography variant="h6">Payment Method</Typography>
            <RecordContextProvider value={data}>
              {data.stripe_card_id ? <PaymentMethodCard /> : <PaymentMethodAccount />}
            </RecordContextProvider>
          </Box>
          <Divider />
          <Box p={2}>
            <Box mb={2}>
              <Controller
                defaultValue=""
                name="amount"
                control={control}
                rules={{ max: 10000 }}
                render={({ field, fieldState }) => (
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="amount-label">Amount</InputLabel>
                    <CurrencyInput field={field} fieldState={fieldState} />
                    {fieldState?.error && fieldState?.error?.type === 'max' ? (
                      <Typography variant="body2" color="error.main" component={Box} pt={1}>
                        Maximum charge of $10,000 allowed
                      </Typography>
                    ) : null}
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <Box>
                <DateInput required source="date_to_process" validate={minValue(dayjs().format('YYYY-MM-DD'))} />
              </Box>
              <Box>
                <DateInput required source="due_date" validate={minValue(dayjs().format('YYYY-MM-DD'))} />
              </Box>
              <BooleanInput source="auto_capture" />
              <BooleanInput source="authorize_only" />
              <Typography variant="body2">
                <strong>Note:</strong> This description displays on the customer dashboard
              </Typography>
              <Controller
                defaultValue=""
                name="description"
                control={control}
                render={({ field }) => <TextField variant="outlined" label="Description" required fullWidth {...field} />}
              />
            </Box>
          </Box>
          <Divider />
          <Box pt={2} px={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={!formState.isDirty}>
              Submit
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

const EditCharge = () => {
  const { setAction, metadata } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [update, { isLoading }] = useUpdate();
  const { data: transactionData, isLoading: isTransactionLoading } = useGetOne(
    resources.ORDERS,
    {
      id: orderId,
      meta: {
        subResource: 'charges',
        subResourceId: metadata?.transactionId,
      },
    },
    {
      enabled: Boolean(metadata?.transactionId),
      retry: false,
    },
  );
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error editing charge - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track(`Order Management - Charge updated`, {
        resource: resources.ORDERS,
        resourceId: orderId,
        confirmationId,
        transactionId: metadata?.transactionId,
      });

      notify('Charge updated', {
        type: 'success',
      });
      refresh();
      setAction(null);
    }
  };

  const onSubmit = ({
    description,
    amount,
    date_to_process: dateToProcess,
    due_date: dueDate,
    auto_capture: autoCapture,
    authorize_only: authorizeOnly,
  }) => {
    update(
      resources.ORDERS,
      {
        id: orderId,
        meta: {
          subResource: 'charges',
          subResourceId: metadata.transactionId,
        },
        data: {
          amount: parseInt(Number(`${amount}`) * 100, 10), // subtract from zero for negative and multiply by 100 to get cents values
          description,
          date_to_process: dateToProcess,
          due_date: dueDate,
          auto_capture: autoCapture,
          authorize_only: authorizeOnly,
        },
        previousData: transactionData,
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Edit Charge
      </Typography>
      <Divider />
      <Box>
        {isLoading || isTransactionLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form
            onSubmit={onSubmit}
            record={{
              ...transactionData,
              amount: (transactionData.amount / 100).toFixed(2),
            }}
          >
            <EditChargeForm />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default EditCharge;
