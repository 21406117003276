const TARGET_OPTIONS = {
  SPLIT: 'split-amount',
  EVEN: 'even-amount',
  INDIVIDUAL: 'individual-amount',
};

const FIELD_NAMES = {
  TARGET_OPTION: 'tips-target-option',
  TARGET_VALUE: 'tips-target-value',
};

export { TARGET_OPTIONS, FIELD_NAMES };
