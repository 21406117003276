import React from 'react';

import { bool, string } from 'prop-types';
import { Link, useCreatePath, useRecordContext, useReference } from 'react-admin';

import { ContentCopy, Report } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import getAccountResourceType from '@/utils/accounts/getAccountResourceType';

import TextFieldWrapper from '../TextFieldWrapper';

const AccountField = ({ showAvatar, enableLink, emptyText, source, clipboardUserSource }) => {
  const record = useRecordContext();
  const createPath = useCreatePath();

  const accountId = record?.[source];

  const {
    referenceRecord: accountRecord,
    isLoading,
    error,
    isError,
  } = useReference({ reference: resources.ACCOUNTS, id: accountId, enabled: !!accountId });

  const clipboardValue = accountRecord?.[clipboardUserSource];

  if (!record || !source) return null;

  if (source && !accountId) return <TextFieldWrapper value={emptyText} />;

  if (isLoading) return <CircularProgress size={16} />;

  if (isError) {
    if (error.status === 404) {
      return (
        <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
          <Box>
            <Typography>Unknown User</Typography>
            <Typography variant="body3">{accountId}</Typography>
          </Box>
          <Report color="error" />
        </Box>
      );
    }
    return <TextFieldWrapper value="Unknown Error fetching user" />;
  }

  return (
    <Box display="flex" gap={0.5} alignItems="center">
      {showAvatar ? (
        <Avatar
          component={Box}
          src={accountRecord?.image_url}
          mr={0.5}
          sx={{
            width: 36,
            height: 36,
          }}
        />
      ) : null}
      {enableLink && accountRecord ? (
        <Link to={createPath({ resource: getAccountResourceType(accountRecord), type: 'show', id: accountRecord?.id })}>
          <Typography variant="body2">{accountRecord?.name}</Typography>
        </Link>
      ) : (
        <Typography variant="body2">{accountRecord?.name}</Typography>
      )}
      {clipboardUserSource && clipboardValue ? (
        <Tooltip title={`Copy user ${clipboardUserSource} to clipboard`}>
          <IconButton onClick={() => navigator.clipboard.writeText(clipboardValue)}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  );
};

AccountField.propTypes = {
  showAvatar: bool,
  enableLink: bool,
  emptyText: string,
  source: string.isRequired,
  clipboardUserSource: string,
};

AccountField.defaultProps = {
  showAvatar: false,
  enableLink: false,
  emptyText: '',
  clipboardUserSource: null,
};

export default AccountField;
