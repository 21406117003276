import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { useRecordContext, useStore } from 'react-admin';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { PREFERENCES_KEYS, PREFERENCES_VALUES } from '@/constants/preferences';
import _ from 'lodash';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(duration);

const CountdownTimerField = ({ source, timezoneSource, dayjsFormat, emptyText, invalidText, textFieldProps }) => {
  const [countdown, setCountdown] = useState('Loading...');
  const [timePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const record = useRecordContext();

  let date = dayjs(_.get(record, source));
  let today = dayjs();

  if (timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL && timezoneSource) {
    if (_.get(record, timezoneSource)) {
      date = date.tz(_.get(record, timezoneSource));
      today = today.tz(_.get(record, timezoneSource));
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const diffDuration = dayjs.duration(date.diff(today));
      const formattedDuration = diffDuration.format(dayjsFormat);
      setCountdown(formattedDuration);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  if (!record) return null;

  if (!_.get(record, source) && emptyText) return emptyText;

  if (!timezoneSource) return null;

  if (date < today) return <TextFieldWrapper value="Expiration time have passed!" textFieldProps={textFieldProps} />;

  if (!date.isValid()) return <TextFieldWrapper value={invalidText ?? 'N/A'} textFieldProps={textFieldProps} />;

  return <TextFieldWrapper value={countdown} textFieldProps={textFieldProps} />;
};

CountdownTimerField.propTypes = {
  source: string.isRequired,
  timezoneSource: string.isRequired,
  dayjsFormat: string.isRequired,
  emptyText: string,
  invalidText: string,
  textFieldProps: shape({}),
};

CountdownTimerField.defaultProps = {
  emptyText: null,
  invalidText: null,
  textFieldProps: {},
};

export default CountdownTimerField;
