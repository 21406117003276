import React from 'react';

import { func, number } from 'prop-types';

import { AddCircle, RemoveCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

const Incrementer = ({ maxLimit, minLimit, setValue, value }) => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <IconButton
      testid="decrement"
      color="secondary"
      disabled={value === minLimit}
      onClick={() => setValue(value > 1 ? value - 1 : minLimit)}
    >
      <RemoveCircleOutline />
    </IconButton>

    <Typography variant="body1">{value}</Typography>

    <IconButton testid="increment" color="secondary" disabled={value === maxLimit} onClick={() => setValue(value + 1)}>
      <AddCircle />
    </IconButton>
  </Box>
);

Incrementer.propTypes = {
  maxLimit: number.isRequired,
  minLimit: number.isRequired,
  setValue: func.isRequired,
  value: number.isRequired,
};

export default Incrementer;
