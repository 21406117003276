import React from 'react';

import { LoadingIndicator, useShowContext } from 'react-admin';

import { Box } from '@mui/material';

import Authority from './Authority';
import ContactInfo from './ContactInfo';
import Contract from './Contract';
import Overview from './Overview';
import Payment from './Payment';
import Truck from './Truck';

const CarriersContentDetails = () => {
  const { record, isLoading, isFetching } = useShowContext();

  if (!record) return null;

  return (
    <Box>
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <>
          <Overview />
          <ContactInfo />
          <Contract />
          <Truck />
          <Authority />
          <Payment />
        </>
      )}
    </Box>
  );
};

export default CarriersContentDetails;
