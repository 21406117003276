import React, { useContext, useMemo } from 'react';

import { Datagrid, DateField, RecordContextProvider, useGetList } from 'react-admin';

import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { LaneLayerSummaryContext } from '@/providers/Pricing/LaneLayerSummary';

import { PRICING_LAYER_TYPES, SERVICES } from '@/constants/pricingLayers';
import CurrencyField from '@/fields/CurrencyField';
import MarketNameField from '@/fields/MarketNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import getServiceName from '@/utils/serviceName/getServiceName';

const Summary = () => {
  const { dataEnabled, filters } = useContext(LaneLayerSummaryContext);

  const sort = { field: 'effective_date', order: 'DESC' };
  // Note: this is not using a /summary endpoint on web proxy
  // After initial implementation, with restriction of start or end market must be selected, we're expecting a simple list of prices in all markets
  // Additionally, lane pricing is currently unique per market, there is very minimal similar pricing across markets, if this changes we may re-visit doing a grouped view
  const { data, isLoading, isFetching } = useGetList(
    resources.PRICING_LAYERS,
    {
      filter: {
        type: PRICING_LAYER_TYPES.LANE,
        service_id: [SERVICES.LDTRANSIT.id],
        ...filters,
      },
      sort,
      pagination: {
        perPage: 1000,
        page: 1,
      },
    },
    {
      enabled: dataEnabled,
    },
  );

  // Sorts pricing layers within each service group by base price then by per worker price, additional sort by markets for cleaner view
  // Future State: Provide user sort option determining which sort to prioritize below
  const sortedByBasePriceData = useMemo(() => {
    if (!data) return [];

    return data.sort((a, b) => {
      if (a.base_price - b.base_price) {
        return a.base_price - b.base_price;
      }
      if (a.per_worker_price - b.per_worker_price) {
        return a.per_worker_price - b.per_worker_price;
      }
      if (!filters.start_market_id) {
        return a.start_market_id.localeCompare(b.start_market_id);
      }
      if (!filters.end_market_id) {
        return a.end_market_id.localeCompare(b.end_market_id);
      }
      return a.effective_date.localeCompare(b.effective_date);
    });
  }, [data]);

  const headerTextProps = {
    textFieldProps: {
      variant: 'h6',
    },
  };

  return (
    <Card mt={2} component={Box}>
      <CardHeader title="Services" />
      <Divider />
      <CardContent
        sx={{
          backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest),
        }}
      >
        {isLoading || isFetching ? (
          <Box textAlign="center">
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            <Grid xs={8} item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box minWidth={100} textAlign="center">
                  <TextFieldWrapper value={`${getServiceName(SERVICES.LDTRANSIT.id)} - `} {...headerTextProps} />
                  {filters.start_market_id ? (
                    <RecordContextProvider value={{ marketId: filters.start_market_id }}>
                      <TextFieldWrapper value="Starting in " {...headerTextProps} />
                      <MarketNameField source="marketId" {...headerTextProps} />
                    </RecordContextProvider>
                  ) : null}
                  {filters.start_market_id && filters.end_market_id ? (
                    <TextFieldWrapper value=" - " {...headerTextProps} />
                  ) : null}
                  {filters.end_market_id ? (
                    <RecordContextProvider value={{ marketId: filters.end_market_id }}>
                      <TextFieldWrapper value="Ending in " {...headerTextProps} />
                      <MarketNameField source="marketId" {...headerTextProps} />
                    </RecordContextProvider>
                  ) : null}
                </Box>
                <Box my={1} width="100%">
                  <Divider />
                </Box>
                <Card component={Box} mt={1} ml={0} display="flex" justifyContent="center">
                  <Datagrid
                    data={sortedByBasePriceData}
                    total={sortedByBasePriceData?.length ?? 0}
                    isLoading={isLoading || isFetching}
                    sort={sort}
                    bulkActionButtons={false}
                  >
                    <DateField label="Effective since" source="effective_date" />
                    <CurrencyField source="base_price" />
                    <CurrencyField source="per_worker_price" />
                    {!filters.start_market_id ? <MarketNameField source="start_market_id" /> : null}
                    {!filters.end_market_id ? <MarketNameField source="end_market_id" /> : null}
                  </Datagrid>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default Summary;
