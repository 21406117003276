import React from 'react';
import { RecordContextProvider, ReferenceField, useRecordContext, WrapperField } from 'react-admin';
import { node, string } from 'prop-types';

/*
 * NullableReferenceField
 *
 * Useful for when you want to render children even if source value in record is null
 */
const NullableReferenceField = ({ source, children, ...rest }) => {
  const record = useRecordContext();
  if (!record[source]) {
    return (
      <RecordContextProvider record={null}>
        <WrapperField>{children}</WrapperField>
      </RecordContextProvider>
    );
  }
  return (
    <ReferenceField source={source} {...rest}>
      {children}
    </ReferenceField>
  );
};

NullableReferenceField.propTypes = {
  source: string.isRequired,
  children: node.isRequired,
};

export default NullableReferenceField;
