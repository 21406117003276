import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useDataProvider, useGetList, useShowContext } from 'react-admin';

import { Box, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { QUOTE_TYPES } from '@/constants/quoteTemplates';
import { SERVICES } from '@/constants/quotes';
import LinkButton from '@/shared/LinkButton';
import onlyUnique from '@/utils/onlyUnique';
import toPascalCase from '@/utils/toPascalCase';
import { merge } from 'lodash';

dayjs.extend(utc);
dayjs.extend(tz);

const BookDraftOrder = () => {
  const {
    record: { id: orderId, account_id: customerId },
  } = useShowContext();
  const dataProvider = useDataProvider();

  const { data: jobsData, isLoading: isJobsLoading } = useGetList(resources.JOBS, {
    filter: { order_id: orderId, status: ['OPEN'] },
    sort: { field: 'start_date_time', order: 'asc' },
  });

  const [quoteLocations, setQuoteLocations] = useState([]);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);

  const loadingJob = jobsData?.find((job) => job.product_id === SERVICES.LOADING.id);
  const unloadingJob = jobsData?.find((job) => job.product_id === SERVICES.UNLOADING.id);

  const quotePopulationData = {
    customer_id: customerId,
    convert_draft: true,
    inventory: {
      area: 0,
    },
    locations: quoteLocations,
    selected_template: {
      available: true,
      id: 'LONG_DISTANCE',
      inventoryRequired: true,
    },
    selected_quote_type: QUOTE_TYPES.BELLHOP,
    service_groups: jobsData?.map((job) => ({
      service_codes: [job.product_id],
      locations: [],
      start_datetime: dayjs(job.start_date_time).toDate(),
    })),
    preferred: {
      start_date: dayjs(loadingJob?.start_date_time).tz(loadingJob?.start_timezone).toDate(),
      start_time: dayjs(loadingJob?.start_date_time).tz(loadingJob?.start_timezone).hour(),
      end_date: dayjs(unloadingJob?.start_date_time).tz(unloadingJob?.start_timezone).toDate(),
      end_time: dayjs(unloadingJob?.start_date_time).tz(unloadingJob?.start_timezone).hour(),
    },
    original_order_id: orderId,
  };

  const fetchJobsLocations = async () => {
    // Clone original jobs so we can sort with transit always being first
    // LD Draft order jobs all have same start date/time and will arbitrarily return in incorrect order (secondary sort by id)
    // Place transit job always first so when we fetch locations, we have valid sequencing
    // Then the later location fetching results in locations being in proper order as well
    const clonedJobs = merge([], jobsData);
    const jobSortOrder = [SERVICES.TRANSIT.id, SERVICES.PACKINGSERVICE.id, SERVICES.LOADING.id, SERVICES.UNLOADING.id];
    clonedJobs.sort((a, b) => {
      const aSortIndex = jobSortOrder.findIndex((service) => service === a.product_id);
      const bSortIndex = jobSortOrder.findIndex((service) => service === b.product_id);
      if (aSortIndex < bSortIndex) return -1;
      if (aSortIndex > bSortIndex) return 1;
      return 0;
    });
    // Get all locations from all jobs, and flatten to single array with all locations
    const locationData = (
      await Promise.all(
        clonedJobs.map((job) =>
          dataProvider.getList(resources.LOCATIONS, {
            filter: { job_id: job.id },
            sort: { field: 'sequence', order: 'ASC' },
            pagination: { page: 1, perPage: 100 },
          }),
        ),
      ).then((response) => response.map(({ data }) => data))
    ).flat();

    // Map all locations with only needed data and filter to only unique locations
    const locations = locationData
      .map(
        ({
          city,
          country,
          postal_id: zip,
          property_type: propertyType,
          state,
          street_address_1: line1,
          street_address_2: line2,
        }) => ({
          line_1: line1,
          line_2: line2,
          city,
          state,
          postal_id: zip,
          country,
          property_type: propertyType,
        }),
      )
      .filter(onlyUnique);

    // Send all locations to create endpoint to get auto corrected and hashed locations for use in quoting
    const parsedLocations = await Promise.all(
      locations.map((loc) => dataProvider.create(resources.LOCATIONS, { data: loc })),
    ).then((response) => response.map(({ data }) => data));

    // Set hashed quote location objects into expected quote format in state, used in population data
    setQuoteLocations(
      parsedLocations.map((location) => ({
        location_id: location.id,
        location: {
          ...location,
          line_1: toPascalCase(location.line_1),
          line_2: toPascalCase(location.line_2),
          city: toPascalCase(location.city),
        },
      })),
    );
    setIsLocationsLoading(false);
  };

  useEffect(() => {
    if (jobsData) {
      setIsLocationsLoading(true);
      fetchJobsLocations();
    }
  }, [jobsData]);

  return (
    <Box px={2} py={4} maxWidth={432}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Book Draft Order
      </Typography>
      <Divider />
      {isJobsLoading || isLocationsLoading ? (
        <Box minWidth={400} width="100%" pt={5}>
          <Box textAlign="center">
            <CircularProgress />
            <Typography pt={2} variant="h6">
              Fetching data from order
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box p={2}>
            <Typography>
              This will create a Quote for the customer from this draft Order, and allow you to update the draft Order from
              that Quote.
            </Typography>
          </Box>
          {quoteLocations ? (
            <Box display="flex" justifyContent="center" pt={2}>
              <LinkButton
                label="Create Quote from Draft Order"
                to={{
                  pathname: '/quotes/create',
                }}
                state={{
                  record: quotePopulationData,
                }}
              />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default BookDraftOrder;
