import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { bool } from 'prop-types';
import { Form, Labeled, SelectArrayInput, TextField, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_SERVICEABILITY_PARTNERS } from '@/constants/market';
import { MarketActionContext } from '@/providers/MarketAction';

const UpdatePostalCodeForm = ({ isUpdating }) => {
  const { setAction, setMetadata } = useContext(MarketActionContext);

  return (
    <>
      <Box px={2} pt={2} width={175}>
        <Labeled>
          <TextField source="postal_id" label="Postal Code" />
        </Labeled>
      </Box>
      <Box px={2} pt={2}>
        <SelectArrayInput
          source="partner_serviceability"
          choices={Object.values(MARKET_SERVICEABILITY_PARTNERS)}
          fullWidth
        />
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="neutral"
          onClick={() => {
            setAction(null);
            setMetadata(null);
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isUpdating ?? null}
          startIcon={isUpdating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

UpdatePostalCodeForm.propTypes = {
  isUpdating: bool.isRequired,
};

const UpdatePostalCode = () => {
  const { metadata, setAction, setMetadata } = useContext(MarketActionContext);
  const { record: { id: marketId } = {} } = useShowContext();
  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error, variables) => {
    const submittedData = variables.data;
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - updating Postal Code failed - ${error.status} - ${message}`, {
      type: 'error',
    });
    mx.track(`Market Management - Error updating Postal Code`, {
      resource: resources.MARKETS,
      resourceId: marketId,
      postalCode: submittedData.postal_id,
      error,
    });
  };

  const onSuccess = (data) => {
    mx.track(`Market Management - Postal Code updated`, {
      resource: resources.MARKETS,
      resourceId: marketId,
      postalCode: data.postal_id,
    });

    notify(`Postal Code ${data.postal_id} updated`, {
      type: 'success',
    });
    refresh();
    setAction(null);
    setMetadata(null);
  };

  const handleSubmit = (data) => {
    update(
      resources.MARKETS,
      {
        id: marketId,
        data: {
          partner_serviceability: data.partner_serviceability ?? [],
        },
        meta: {
          subResource: 'postal_codes',
          subResourceId: data.postal_id,
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Update Postal Code
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit} record={metadata}>
          <UpdatePostalCodeForm isUpdating={isUpdating} />
        </Form>
      </Box>
    </Box>
  );
};

export default UpdatePostalCode;
