import React, { useState } from 'react';

import { useCreate, useNotify, useRefresh } from 'react-admin';

import * as resources from '@/api/resources';
import { WORKER_SKILLS, WORKER_TYPE } from '@/constants/supplyinventory';

import SupplyInventoryFormFrame from '../../Common/SupplyInventoryFormFrame';

const SupplyInventoryCreateLead = () => {
  const [filterformValues, setfilterFormValues] = useState({
    market_id: '',
    slot_datetime: '',
    qualification_level: 3,
    am_pm: 'AM',
    end_date: '',
    start_date: '',
    difference_In_Days: '',
  });

  const [leadFormValues, setLeadFormValues] = useState({
    Leadfloor: 0,
    Leadceiling: 0,
    Leadgrowth: '0',
    section: 'Lead',
    skill: WORKER_SKILLS[0].id,
  });

  const handleChange = (event, setFormStateValues) => {
    const { name, value } = event.target;
    let newValue = '';
    if (name !== 'market_id') {
      newValue = event.target.value.replace(/%.*$/, '');
      setFormStateValues((prevState) => ({ ...prevState, [name]: newValue }));
    } else {
      setFormStateValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error in creating supply inventory! - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      notify(`Created supply inventory successfully!`, {
        type: 'success',
      });
      refresh();
    }
  };

  const createSupplyInventories = (data) => {
    create(
      `${resources.SUPPLYINVENTORY}`,
      {
        data,
      },
      { onSettled },
    );
  };

  const onSubmit = ({ Leadfloor, Leadceiling, Leadgrowth, slot_datetime: slotdateTime }) => {
    const slottime = new Date(slotdateTime);

    const data = {
      market_id: filterformValues.market_id,
      slot_datetime: slottime.toISOString,
      skill: leadFormValues.skill,
      qualification_level: filterformValues.qualification_level,
      floor: Leadfloor,
      ceiling: Leadceiling,
      growth: Number(Leadgrowth),
      end_date: filterformValues.end_date,
      start_date: slotdateTime,
      difference_In_Days: filterformValues.difference_In_Days,
      am_pm: filterformValues.am_pm,
    };

    createSupplyInventories(data);
    return null;
  };

  return (
    <SupplyInventoryFormFrame
      onSubmit={onSubmit}
      setfilterFormValues={setfilterFormValues}
      filterformValues={filterformValues}
      FormValues={leadFormValues}
      setFormValues={setLeadFormValues}
      handleChange={handleChange}
      title={WORKER_TYPE[0].name}
    />
  );
};

export default SupplyInventoryCreateLead;
