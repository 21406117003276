import React from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { shape, string } from 'prop-types';
import { useRecordContext, useStore } from 'react-admin';

import { Tooltip } from '@mui/material';

import { PREFERENCES_KEYS, PREFERENCES_VALUES } from '@/constants/preferences';
import TextFieldWrapper from '@/fields/TextFieldWrapper';

dayjs.extend(utc);
dayjs.extend(tz);

const PrefDateTimeField = ({
  source,
  timezoneSource,
  dayjsFormat,
  emptyText,
  invalidText,
  textFieldProps,
  tooltipFormat,
}) => {
  const [timePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const record = useRecordContext();

  if (!record) return null;

  if (!_.get(record, source) && emptyText) return emptyText;

  if (timezoneSource && !_.get(record, timezoneSource)) return null;

  let date = dayjs(_.get(record, source));
  if (timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL && timezoneSource) {
    date = date.tz(_.get(record, timezoneSource));
  }

  if (!date.isValid()) return <TextFieldWrapper value={invalidText ?? 'N/A'} textFieldProps={textFieldProps} />;

  let tooltipText;
  if (tooltipFormat) {
    tooltipText = date.format(tooltipFormat);
  }
  return (
    <Tooltip title={tooltipText}>
      <span>
        <TextFieldWrapper value={date.format(dayjsFormat ?? null)} textFieldProps={textFieldProps} />
      </span>
    </Tooltip>
  );
};

PrefDateTimeField.propTypes = {
  source: string.isRequired,
  timezoneSource: string,
  dayjsFormat: string,
  emptyText: string,
  invalidText: string,
  textFieldProps: shape({}),
  tooltipFormat: string,
};

PrefDateTimeField.defaultProps = {
  timezoneSource: null,
  dayjsFormat: null,
  emptyText: null,
  invalidText: null,
  textFieldProps: {},
  tooltipFormat: null,
};

export default PrefDateTimeField;
