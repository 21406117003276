import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { bool, func, string } from 'prop-types';
import {
  ChipField,
  Form,
  RecordContextProvider,
  ReferenceField,
  SelectInput,
  TextInput,
  useCreate,
  useGetManyAggregate,
  useNotify,
  useShowContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material';

import * as resources from '@/api/resources';
import HiddenInput from '@/components/inputs/HiddenInput';
import { STATES } from '@/constants/location';
import useUniqueMarkets from '@/hooks/useUniqueMarkets';
import { QuoteContext } from '@/providers/Quote';
import LocationWarnings from '@/resources/quotes/shared/LocationWarnings';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import objectDiff from '@/utils/objectDiff';
import toPascalCase from '@/utils/toPascalCase';

import CorrectedAddress from './CorrectedAddress';
import Suggestions from './Suggestions';

const EditLocationForm = ({ isUpdating, onClose }) => {
  const { setValue } = useFormContext();

  const clearAllFields = () => {
    mx.track('Order Management - Quote - Location - Clear All Fields');
    setValue(
      'location',
      {
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        postal_id: '',
        property_type: '',
        id: '',
      },
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  return (
    <Grid container>
      <HiddenInput source="location_id" />
      {/* Location ID is duplicated in original data */}
      <HiddenInput source="location.id" />
      <HiddenInput source="location.country" />
      <Grid padding={2} xs={6} item container rowSpacing={1} columnSpacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput label="Line 1" source="location.line_1" fullWidth required />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Line 2" source="location.line_2" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="City" source="location.city" fullWidth required />
        </Grid>
        <Grid item xs={3}>
          <SelectInput label="State" source="location.state" choices={STATES} sx={{ minWidth: 0 }} fullWidth required />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="ZIP" source="location.postal_id" fullWidth required />
        </Grid>
        <Grid item xs={6} ml="auto">
          <SelectInput
            label="Property Type"
            source="location.property_type"
            choices={[
              { id: 'SINGLE_FAMILY_HOME', name: 'Single Family Home' },
              { id: 'MULTI_FAMILY_HOME', name: 'Multi Family Home' },
              { id: 'APARTMENT', name: 'Apartment' },
              { id: 'COMMERCIAL', name: 'Commercial' },
              { id: 'STORAGE', name: 'Storage' },
              { id: 'OTHER', name: 'Other' },
            ]}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button onClick={clearAllFields}>Clear All Fields</Button>
        </Grid>
      </Grid>
      <Grid xs={6} item minHeight={400} py={2} pr={2}>
        <Typography pb={1} variant="h5" textAlign="center">
          Suggestions
        </Typography>
        <Suggestions />
      </Grid>
      <Grid xs={12} item my={2}>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <Box display="flex" justifyContent="space-between" px={2} pb={2}>
          <Button variant="contained" color="neutral" onClick={onClose} disabled={isUpdating ?? null}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            endIcon={isUpdating ? <CircularProgress size={18} /> : null}
            disabled={isUpdating ?? null}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

EditLocationForm.propTypes = {
  isUpdating: bool.isRequired,
  onClose: func.isRequired,
};

const EditLocationModal = ({ open, onClose, selectedLocation }) => {
  const { record: { id: quoteId } = {} } = useShowContext();
  const [create] = useCreate();
  const notify = useNotify();

  const { locations, setLocations } = useContext(QuoteContext);
  const { getValues, setValue } = useFormContext();

  const [displayCorrectedAddress, setDisplayCorrectedAddress] = useState(false);
  const [submittedAddress, setSubmittedAddress] = useState({});
  const [correctedAddress, setCorrectedAddress] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  if (!selectedLocation && selectedLocation === null) return null;

  const locationToEdit = _.find(locations, (location) => location.location_id === selectedLocation);

  const updateLocations = (newLocation) => {
    const oldLocation = { ...locationToEdit };
    const newLocationData = [...locations];

    if (!locationToEdit) {
      mx.track('Order Management - Quote - Location locally added', {
        quoteId,
      });
      newLocationData.push({
        location: newLocation,
        location_id: newLocation.id,
      });
      onClose();
      setLocations(newLocationData);
      notify('Location set successfully', { type: 'success' });
      return;
    }

    const index = _.findIndex(newLocationData, (location) => location.location.id === locationToEdit.location.id);

    newLocationData[index] = {
      location: newLocation,
      location_id: newLocation.id,
    };

    onClose();
    setLocations(newLocationData);

    // replace old id with new id on all fields that have old id currently set
    const locationsToUpdate = [];
    const serviceGroupInputFields = getValues('service_groups');
    serviceGroupInputFields.forEach((serviceGroup, serviceGroupIndex) => {
      serviceGroup.locations.forEach((location, locationIndex) => {
        if (location.location_id === oldLocation.location_id) {
          locationsToUpdate.push(`service_groups.${serviceGroupIndex}.locations.${locationIndex}.location_id`);
        }
      });
    });

    locationsToUpdate.forEach((location) => setValue(location, newLocation.id, { shouldDirty: true }));
    mx.track('Order Management - Quote - Location locally updated', {
      quoteId,
    });
    notify('Location set successfully', { type: 'success' });
  };

  const setLocationOnSuccess = (data, variables) => {
    const submittedData = variables.data;
    const oldLocation = locationToEdit ? { ...locationToEdit.location } : null;
    const newLocation = {
      ...data,
      line_1: toPascalCase(data.line_1),
      line_2: data.line_2 ? toPascalCase(data.line_2) : '',
      city: toPascalCase(data.city),
    };

    if (oldLocation && oldLocation.market_id !== newLocation.market_id) {
      notify('Location is not in the same market', { type: 'error' });
      return;
    }

    const dataDiff = objectDiff(
      {
        ...newLocation,
        line_1: newLocation.line_1?.toLowerCase() ?? '',
        line_2: newLocation.line_2?.toLowerCase() ?? '',
      },
      {
        ...submittedData,
        line_1: submittedData.line_1?.toLowerCase() ?? '',
        line_2: submittedData.line_2?.toLowerCase() ?? '',
      },
    );

    delete dataDiff.id; // Location ID is expected to be different
    delete dataDiff.market_id; // Ignore market - clear location edge case - market restriction handled above

    if (!_.isEmpty(dataDiff)) {
      mx.track('Order Management - Quote - Location - Corrected Address displayed', {
        quoteId,
        submittedLocation: submittedData,
        correctedLocation: newLocation,
      });
      notify('Location was auto corrected - Verify location for accuracy', { type: 'warning' });
      setDisplayCorrectedAddress(true);
      setSubmittedAddress(submittedData);
      setCorrectedAddress(newLocation);
    } else {
      updateLocations(newLocation);
    }
    setIsUpdating(false);
  };

  const setLocationOnError = (error) => {
    const { message, status } = error;
    mx.track('Order Management - Quote - Location - Error adding / updating local Location', {
      quoteId,
      error,
    });
    if (status === 404) {
      notify('Location is not in service area', { type: 'error' });
    } else if (status === 400 && message.includes('Unable to validate the address provided:')) {
      notify('Could not validate address', { type: 'error' });
    } else {
      notify('Error occurred while saving location', { type: 'error' });
    }
    setIsUpdating(false);
  };

  const onSubmit = (submittedData) => {
    setIsUpdating(true);
    const data = {
      ...submittedData.location,
      country: 'US',
    };
    delete data.id;
    delete data.market_id;

    create(
      resources.LOCATIONS,
      { data },
      {
        onError: setLocationOnError,
        onSuccess: setLocationOnSuccess,
      },
    );
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (displayCorrectedAddress) {
      setDisplayCorrectedAddress(false);
      setSubmittedAddress({});
      setCorrectedAddress({});
      onClose();
      updateLocations(correctedAddress);
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      sx={{
        outline: 0,
      }}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="80vw"
        overflow="auto"
        bgcolor="background.paper"
        color="text.primary"
        boxShadow={24}
        sx={{
          transform: 'translate(-50%, -50%)',
          outline: 0,
        }}
      >
        {displayCorrectedAddress ? (
          <CorrectedAddress
            onClose={handleModalClose}
            submittedAddress={submittedAddress}
            correctedAddress={correctedAddress}
          />
        ) : (
          <Form record={locationToEdit ?? {}} onSubmit={onSubmit}>
            <EditLocationForm onClose={handleModalClose} isUpdating={isUpdating} />
          </Form>
        )}
      </Box>
    </Modal>
  );
};

EditLocationModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  selectedLocation: string,
};

EditLocationModal.defaultProps = {
  selectedLocation: null,
};

const QuoteLocations = () => {
  const { locations } = useContext(QuoteContext);
  const { setValue } = useFormContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();

  const [uniqueMarkets] = useUniqueMarkets({ locations });
  const {
    data: marketsData,
    isFetching,
    refetch,
  } = useGetManyAggregate(resources.MARKETS, { ids: uniqueMarkets }, { enabled: Boolean(uniqueMarkets?.length) });

  const selectLocation = (locationId) => {
    setSelectedLocation(locationId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedLocation(null);
  };

  useEffect(() => {
    setValue('marketsData', marketsData);
  }, [marketsData, isFetching]);

  useEffect(() => {
    refetch();
  }, [Boolean(uniqueMarkets?.length)]);

  return (
    <>
      <EditLocationModal open={modalOpen} onClose={closeModal} selectedLocation={selectedLocation} />
      <Card>
        <CardHeader title="Quote Locations" />
        <Divider />
        <CardContent>
          {locations?.map(({ location }) => (
            <Box display="flex" key={location.id} alignItems="center" py={1} gap={1}>
              <Button variant="outlined" onClick={() => selectLocation(location.id)} startIcon={<Edit />}>
                Edit
              </Button>
              <Typography px={1}>{formatAddressParts(location)}</Typography>
              <RecordContextProvider value={location}>
                <ReferenceField source="market_id" reference={resources.MARKETS}>
                  <ChipField source="name" />
                </ReferenceField>
                <LocationWarnings />
              </RecordContextProvider>
            </Box>
          ))}
        </CardContent>
        <Divider />
        <CardContent>
          <Button variant="outlined" onClick={() => selectLocation('')}>
            Add Location
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default QuoteLocations;
