import React from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useGetList, useRecordContext } from 'react-admin';

import { Box, Card } from '@mui/material';

import * as resources from '@/api/resources';
import LocationsContent from '@/shared/LocationsContent';

dayjs.extend(duration);

const JobExpandPanel = () => {
  const record = useRecordContext();

  const {
    data: locationsData,
    isLoading: isLocationsLoading,
    isFetching: isLocationsFetching,
  } = useGetList(
    resources.LOCATIONS,
    {
      filter: { job_id: record?.id },
      sort: { field: 'sequence', order: 'ASC' },
    },
    { enabled: Boolean(record?.id) },
  );

  if (!record) return null;

  return (
    <Card component={Box} my={2} display="flex" alignItems="center">
      <Box flex={1}>
        <LocationsContent data={locationsData} isLoading={isLocationsLoading || isLocationsFetching} />
      </Box>
    </Card>
  );
};

export default JobExpandPanel;
