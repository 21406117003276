import React, { useContext } from 'react';

import { usePermissions, useShowContext } from 'react-admin';

import { Box, Button, Card, CardContent, Divider, Icon, Tooltip, Typography } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';
import { Help } from '@mui/icons-material';

const Notes = () => {
  const { setAction } = useContext(OrderActionContext);
  const { permissions } = usePermissions();
  const { record: { notes } = {} } = useShowContext();

  return (
    <Card>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="h5" mr={1}>
            Notes
          </Typography>
          <Tooltip title="These Inventory Notes are external - they are visible to external parties such as customers, Pros, BPPs, and Carriers.  To leave an internal-facing note for Bellhop Operators only, go to Operator Notes.">
            <Icon>
              <Help />
            </Icon>
          </Tooltip>
        </Box>
        {permissions?.tools?.hqadmin?.order_notes?.update ? (
          <Button variant="outlined" onClick={() => setAction('edit_notes')}>
            Edit
          </Button>
        ) : null}
      </Box>
      <Divider />
      <CardContent>
        <Typography component={Box} px={1} whiteSpace="pre-wrap" variant="body2">
          {notes}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Notes;
