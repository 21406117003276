import React, { useContext } from 'react';

import { bool } from 'prop-types';
import { BooleanInput, Form, NumberInput, useNotify, useRefresh, useUpdate, SelectInput } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MarketActionContext } from '@/providers/MarketAction';
import { MARKET_PRODUCT_SERVICES } from '@/constants/market';

const UpdateMarketProductForm = ({ isUpdating }) => {
  const { setAction, setMetadata } = useContext(MarketActionContext);

  return (
    <>
      <Box px={2} pt={2}>
        <Box>
          <SelectInput source="product_id" choices={MARKET_PRODUCT_SERVICES} fullWidth required />
        </Box>
        <Box>
          <NumberInput source="distance_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="customer_rating_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="job_count_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="cost_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="maximum_lead_days" fullWidth />
        </Box>
        <Box>
          <NumberInput source="removal_rate_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="job_details_lead_time" fullWidth />
        </Box>
        <Box>
          <BooleanInput source="skip_autoassign" fullWidth />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="neutral"
          onClick={() => {
            setAction(null);
            setMetadata(null);
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isUpdating ?? null}
          startIcon={isUpdating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

UpdateMarketProductForm.propTypes = {
  isUpdating: bool.isRequired,
};

const UpdateMarketProduct = () => {
  const { metadata, setAction, setMetadata } = useContext(MarketActionContext);
  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - updating market product failed - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    notify(`Market product ${data.product_id} updated`, {
      type: 'success',
    });
    refresh();
    setAction(null);
    setMetadata(null);
  };

  const handleSubmit = (data) => {
    update(
      resources.MARKET_PRODUCTS,
      {
        id: metadata.market_id,
        data,
        meta: {
          subResource: 'products',
          subResourceId: metadata.product_id,
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Update Market Product
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit} record={metadata}>
          <UpdateMarketProductForm isUpdating={isUpdating} />
        </Form>
      </Box>
    </Box>
  );
};

export default UpdateMarketProduct;
