import React from 'react';

import { BooleanField, Labeled, NumberField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

const AccessFlags = () => (
  <Card>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Access flags</Typography>
    </Box>

    <Divider />

    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="access_flags.stairs" label="Stairs" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <BooleanField source="access_flags.long_walk_to_truck" label="Is long walk to truck" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <BooleanField source="access_flags.elevator" label="Elevator" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <BooleanField source="access_flags.elevator_reserved" label="Elevator Reserved" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default AccessFlags;
