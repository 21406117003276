import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { CheckboxGroupInput, ReferenceArrayInput, required, useGetList } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_GROUPS } from '@/constants/pricingLayers';

const MarketGroupSelection = () => {
  const { setValue } = useFormContext();
  const selectedMarketGroups = useWatch({ name: 'market_groups' });
  const selectedMarketIds = useWatch({ name: 'market_ids' });
  const [prevSelectedMarketGroups, setPrevSelectedMarketGroups] = useState(selectedMarketGroups);

  const { data: allMarkets } = useGetList(resources.MARKETS, {
    pagination: { perPage: 999, page: 1 },
    sort: { field: 'name', order: 'ASC' },
  });
  const allMarketIds = allMarkets?.map(({ id }) => id) ?? [];

  useEffect(() => {
    if (
      _.difference(prevSelectedMarketGroups, selectedMarketGroups).length === 0 &&
      _.difference(selectedMarketGroups, prevSelectedMarketGroups).length === 0
    ) {
      // No diff between prev and selected (typically because they changed at the same time as result of useEffect below)
      // Exit - no changes needed
      return;
    }
    // When market groups change, determine which was selected / deselected and add / remove individual markets as applicable
    const addedMarketGroup = _.difference(selectedMarketGroups, prevSelectedMarketGroups)?.[0];
    const removedMarketGroup = _.difference(prevSelectedMarketGroups, selectedMarketGroups)?.[0];

    // above are exclusive, only one or the other has a value each time this runs
    const marketsToSelect =
      addedMarketGroup === MARKET_GROUPS.ALL.id ? allMarketIds : MARKET_GROUPS[addedMarketGroup]?.markets ?? [];
    const marketsToDeselect =
      removedMarketGroup === MARKET_GROUPS.ALL.id ? allMarketIds : MARKET_GROUPS[removedMarketGroup]?.markets ?? [];

    if (addedMarketGroup) {
      setValue('market_ids', [...(selectedMarketIds ?? []), ...marketsToSelect]);
    }
    if (removedMarketGroup) {
      setValue(
        'market_ids',
        selectedMarketIds?.filter((market) => !marketsToDeselect.includes(market)),
      );
    }

    setPrevSelectedMarketGroups(selectedMarketGroups);
  }, [selectedMarketGroups, prevSelectedMarketGroups]);

  useEffect(() => {
    // When individual markets change, determine if market group selection needs to be updated to keep in sync
    const syncedMarketGroups = Object.values(MARKET_GROUPS)
      .map((marketGroup) => {
        const marketsMissing = _.difference(
          marketGroup.id === MARKET_GROUPS.ALL.id ? allMarketIds : marketGroup.markets,
          selectedMarketIds,
        );

        if (marketsMissing?.length === 0) {
          return marketGroup.id;
        }
        return null;
      })
      .filter((item) => !!item);

    if (
      _.difference(syncedMarketGroups, selectedMarketGroups)?.length ||
      _.difference(selectedMarketGroups, syncedMarketGroups)?.length
    ) {
      // Difference exists between synced and selected - update previously selected so above useEffect does not create a loop and then update selected in form
      setPrevSelectedMarketGroups(syncedMarketGroups);
      setValue('market_groups', syncedMarketGroups);
    }
  }, [selectedMarketIds]);

  return (
    <Box display="flex">
      <Box flex={{ xs: 2, lg: 1.5 }}>
        <Typography variant="h6">Market Groups</Typography>
        <CheckboxGroupInput
          row={false}
          label=""
          source="market_groups"
          choices={Object.values(MARKET_GROUPS)}
          sx={{ '& .MuiFormControlLabel-root': { my: 1, mx: 0 } }}
        />
      </Box>
      <Box mr={3}>
        <Divider orientation="vertical" />
      </Box>
      <Box flex={10}>
        <Typography variant="h6">Individual Markets</Typography>
        <ReferenceArrayInput
          reference={resources.MARKETS}
          source="market_ids"
          perPage={999}
          defaultValue={[]}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <CheckboxGroupInput
            label=""
            sx={{
              '& .MuiFormControlLabel-root': { width: { xs: '25%', lg: '20%' }, my: 1, mx: 0 },
              '& .MuiFormControlLabel-label': { fontSize: '0.9rem' },
            }}
            validate={required()}
            helperText="At least one market is required"
          />
        </ReferenceArrayInput>
      </Box>
    </Box>
  );
};

export default MarketGroupSelection;
