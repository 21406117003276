import * as resources from '@/api/resources';

import StripeTransactionEdit from './Edit';
import StripeTransactionList from './List';
import StripeTransactionShow from './Show';

const stripeTransactions = () => ({
  name: resources.ADMIN_STRIPE_TRANSACTIONS,
  edit: StripeTransactionEdit,
  list: StripeTransactionList,
  show: StripeTransactionShow,
});

export default stripeTransactions;
