import { defaultTheme } from 'react-admin';
import { merge } from 'lodash';

import mixins from './mixins';
import typography from './typography';
import { lightPalette, darkPalette } from './palette';
import components from './components';
import reactAdmin from './reactAdmin';

const theme = merge({}, defaultTheme, {
  version: 1,
  mixins,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1300,
      lg: 1800,
      xl: 2400,
    },
  },
  ...reactAdmin,
});

const lightComponents = components({
  palette: lightPalette,
});
const darkComponents = components({
  palette: darkPalette,
});

export const lightTheme = merge({}, theme, {
  mode: 'light',
  palette: lightPalette,
  components: lightComponents,
});
export const darkTheme = merge({}, theme, {
  mode: 'dark',
  palette: darkPalette,
  components: darkComponents,
});
