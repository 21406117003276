import React, { useEffect, useState } from 'react';

import { func, string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import JobsAddRecruitButton from '../AddRecruitButton';

const JobsSectionHeader = ({ title, setAsideAction }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const objNavigation = {
    editmatchstatus: 'editmatchstatus',
    convertordertobhn: 'convertordertobhn',
  };
  const { permissions } = usePermissions();
  const canEditMatchStatus = permissions?.tools?.hqadmin?.job_match_status?.update;

  const jobConvertableToBhn = ['COMPLETE', 'OPEN'].includes(record?.status);
  const canConvertBNH = permissions?.tools?.hqadmin?.jobs?.convert_to_bhn && jobConvertableToBhn;
  const isTransit = record?.product_id === 'TRANSIT';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (value) => {
    setSelectedValue(value);
    setAnchorEl(null);
  };

  useEffect(() => {
    switch (selectedValue) {
      case objNavigation.editmatchstatus:
        navigate(`/${resources.JOBS}/${record.id}/edit/matchStatus`);
        break;
      case objNavigation.convertordertobhn:
        setAsideAction(objNavigation.convertordertobhn);
        setSelectedValue('');
        break;
      default:
        break;
    }
  }, [selectedValue]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">{title}</Typography>

      <Box sx={{ marginLeft: 'auto' }}>{isTransit ? '' : <JobsAddRecruitButton />}</Box>
      <Box>
        <>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu id="iconmenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {canEditMatchStatus ? (
              <MenuItem onClick={() => handleButtonClick(objNavigation.editmatchstatus)}>Edit Match Status</MenuItem>
            ) : null}
            {canConvertBNH ? (
              <MenuItem onClick={() => handleButtonClick(objNavigation.convertordertobhn)}>Convert to BHN</MenuItem>
            ) : null}
          </Menu>
        </>
      </Box>
    </Box>
  );
};

JobsSectionHeader.propTypes = {
  title: string.isRequired,
  setAsideAction: func.isRequired,
};

export default JobsSectionHeader;
