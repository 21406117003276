import React, { useContext } from 'react';

import { func } from 'prop-types';
import {
  Datagrid,
  DateField,
  LoadingIndicator,
  TextField,
  usePermissions,
  useRecordContext,
  useShowContext,
} from 'react-admin';

import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Card, CardContent, Divider, IconButton } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_ACTION } from '@/constants/market';
import { MarketActionContext } from '@/providers/MarketAction';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import MarketProductSectionHeader from './Common/SectionHeader';

const EditMarketProduct = ({ setAction, setMetadata }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const canUpdateMarketProduct = permissions?.tools?.hqadmin?.market_product?.admin;

  return canUpdateMarketProduct ? (
    <IconButton
      size="small"
      color="primary"
      onClick={() => {
        setAction(MARKET_ACTION.UPDATE_MARKET_PRODUCT);
        setMetadata(record);
      }}
    >
      <Edit fontSize="small" />
    </IconButton>
  ) : null;
};

EditMarketProduct.propTypes = {
  setAction: func.isRequired,
  setMetadata: func.isRequired,
};

const DeleteMarketProduct = ({ setAction, setMetadata }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const canDeleteMarketProduct = permissions?.tools?.hqadmin?.market_product?.admin;

  return canDeleteMarketProduct ? (
    <IconButton
      size="small"
      color="accent"
      onClick={() => {
        setAction(MARKET_ACTION.DELETE_MARKET_PRODUCT);
        setMetadata(record);
      }}
    >
      <RemoveCircleOutline fontSize="small" />
    </IconButton>
  ) : null;
};

DeleteMarketProduct.propTypes = {
  setAction: func.isRequired,
  setMetadata: func.isRequired,
};

const MarketProductList = () => {
  const { record, isLoading, isFetching } = useShowContext();
  const { setAction, setMetadata } = useContext(MarketActionContext);
  const url = `${resources.MARKET_PRODUCTS}/${record.id}/products`;

  return (
    <Box width="100%">
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <Card>
          <MarketProductSectionHeader title="Market Products" setAction={setAction} setMetadata={setMetadata} />
          <Divider />
          <CardContent>
            <List
              disableSyncWithLocation
              resource={url}
              exporter={false}
              empty={<EmptyResourceList text="No Market Products" />}
              storeKey={`${resources.MARKET_PRODUCTS}.listParams`}
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="id" emptyText="N/A" />
                <TextField source="market_id" emptyText="N/A" />
                <TextField source="product_id" emptyText="N/A" />
                <TextField source="status" emptyText="N/A" />
                <DateField source="created_at" emptyText="N/A" />
                <Box>
                  <EditMarketProduct setAction={setAction} setMetadata={setMetadata} />
                  <DeleteMarketProduct setAction={setAction} setMetadata={setMetadata} />
                </Box>
              </Datagrid>
            </List>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default MarketProductList;
