import _ from 'lodash';

const onlyUnique = (value, index, self) => {
  if (typeof value === 'object') {
    return _.findIndex(self, value) === index;
  }
  return self.indexOf(value) === index;
};

export default onlyUnique;
