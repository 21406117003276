import React, { useState } from 'react';

import { useRecordContext } from 'react-admin';

import { Box, Card } from '@mui/material';

import UnknownTypeEvent from './UnknownType';

const Event = () => {
  const record = useRecordContext();

  const [expanded, setExpanded] = useState(false);

  if (!record) return null;

  const { object_type: objectType } = record;

  const eventComponent = () => {
    switch (objectType) {
      default:
        return <UnknownTypeEvent expanded={expanded} setExpanded={setExpanded} />;
    }
  };

  return (
    <Card elevation={4}>
      <Box pt={1} px={2}>
        {eventComponent()}
      </Box>
    </Card>
  );
};

export default Event;
