import React from 'react';
import { Alert } from '@mui/material';

const AlertActiveDevelopment = () => (
  <Alert variant="filled" severity="warning">
    Danger - Untested Software Ahead - Information is WIP - Under Active Development - Information displayed may not be
    accurate
  </Alert>
);

export default AlertActiveDevelopment;
