import React from 'react';
import {
  FunctionField,
  RecordContextProvider,
  TextField,
  useGetList,
  usePermissions,
  useReference,
  useShowContext,
} from 'react-admin';

import { Alert, Box, CircularProgress } from '@mui/material';

import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import AddPromoCode from './AddPromoCode';
import RemovePromoCode from './RemovePromoCode';

const PromoCode = () => {
  const { record: { id: orderId } = {} } = useShowContext();
  const { permissions } = usePermissions();

  const { data: adjustmentsData } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'adjustments', resourceId: orderId } },
    { enabled: Boolean(orderId) },
  );

  const activePromoCodes = adjustmentsData?.filter((adj) => adj.product_id === 'PROMOCODE' && adj.status === 'OPEN');
  const activePromoCode = adjustmentsData?.find((adj) => adj.product_id === 'PROMOCODE' && adj.status === 'OPEN');

  const { referenceRecord, isLoading } = useReference({
    reference: 'promo_codes',
    id: activePromoCode?.promo_id,
    options: { enabled: Boolean(activePromoCode?.promo_id) },
  });

  if (!adjustmentsData) return null;

  return (
    <Box>
      {activePromoCodes.length > 1 ? (
        <Alert variant="filled" severity="warning">
          Multiple active promo codes found
        </Alert>
      ) : null}
      {activePromoCode ? (
        <Box display="flex" justifyContent="space-between" mb={1} alignItems="center">
          {isLoading ? (
            <Box display="flex" justifyContent="center" width="100%">
              <CircularProgress />
            </Box>
          ) : (
            <RecordContextProvider value={referenceRecord}>
              <Box display="flex" alignItems="center">
                {permissions?.tools?.hqadmin?.order_promo_code?.delete ? <RemovePromoCode /> : null}
                <TextField source="id" />
              </Box>
              <TextField source="promo_id_type" />
              <FunctionField
                render={(record) => {
                  switch (record.promo_id_type) {
                    case 'Amount':
                      return <CurrencyField source="promo_id_amount" />;
                    case 'Percent':
                      return <TextFieldWrapper value={`${record.promo_id_percent}%`} />;
                    default:
                      return <TextFieldWrapper value="Unknown Promo Code Type" />;
                  }
                }}
              />
            </RecordContextProvider>
          )}
        </Box>
      ) : null}
      {permissions?.tools?.hqadmin?.order_promo_code?.update ? <AddPromoCode /> : 'No promo code on order'}
    </Box>
  );
};

export default PromoCode;
