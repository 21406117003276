import React, { useEffect, useState } from 'react';

import mx from 'mixpanel-browser';
import { bool, func } from 'prop-types';
import { Form, useDataProvider, useNotify, useRefresh, useShowContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Box, CircularProgress } from '@mui/material';

import * as resources from '@/api/resources';
import PromoCodeForm from '@/resources/orders/Show/OrderContent/Invoice/PromoCode/AddPromoCode/PromoCodeForm';
import { useMutation } from 'react-query';

const ResetForm = ({ resetForm, setResetForm }) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (resetForm) {
      reset({ promo_code_id: '' });
      setResetForm(false);
    }
  }, [resetForm]);
  return null;
};

ResetForm.propTypes = {
  resetForm: bool.isRequired,
  setResetForm: func.isRequired,
};

const AddPromoCode = () => {
  const { record: quoteRecord } = useShowContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const { mutate: operationUpdate, isLoading } = useMutation(([resource, params]) =>
    dataProvider.operationUpdate(resource, params),
  );

  const [resetForm, setResetForm] = useState(false);

  const onError = (error) => {
    mx.track('Order Management - Quote - Error adding / updating Promo Code', {
      quoteId: quoteRecord?.id,
      error,
    });
    if (error?.body?.detail) {
      if (error.body?.detail.includes('not found')) {
        notify('Promo code not found', {
          type: 'error',
        });
      } else if (error.body?.detail.includes('promo code already applied')) {
        notify('Promo code already applied on order', {
          type: 'error',
        });
      } else if (error.body?.detail.includes('promocode to a completed order')) {
        notify('Cannot apply a Promo Code to a completed order', {
          type: 'error',
        });
      } else {
        notify(`Unknown error occurred applying promo code - ${error}`, {
          type: 'error',
        });
      }
    } else {
      notify(`Unknown error occurred applying promo code - ${error}`, {
        type: 'error',
      });
    }
  };

  const onSuccess = () => {
    mx.track('Order Management - Quote - Promo Code added / updated', {
      quoteId: quoteRecord?.id,
    });

    notify('Promo code applied to quote', {
      type: 'success',
    });

    refresh();
    setResetForm(true);
  };

  const onSubmit = (submittedData) => {
    const promoObject = submittedData.promo_code_id;

    const operationsNeeded = [
      {
        op: 'replace',
        path: `/promo_code`,
        value: {
          promo_code_name: promoObject.id,
        },
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  return (
    <Form onSubmit={onSubmit}>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <PromoCodeForm />
          <ResetForm resetForm={resetForm} setResetForm={setResetForm} />
        </>
      )}
    </Form>
  );
};

export default AddPromoCode;
