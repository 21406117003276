import React from 'react';

import { Datagrid, EmailField, TextField, TextInput, useShowContext } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import ThirdPartyWorkerSectionHeader from './Common/SectionHeader';

const AccountFilter = [
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
  <TextInput source="primary_email" label="Primary email" alwaysOn resettable autoComplete="off" />,
  <TextInput source="phone" alwaysOn resettable autoComplete="off" />,
];

const CrewLeads = () => {
  const { record } = useShowContext();

  return (
    <Card>
      <Box px={3} py={2}>
        <ThirdPartyWorkerSectionHeader title="Crew Lead Accounts" />
      </Box>

      <Divider />

      <List
        disableSyncWithLocation
        resource={resources.THIRD_PARTY_WORKER}
        filter={{ id: record.id }}
        filters={AccountFilter}
        exporter={false}
        empty={<EmptyResourceList text="No Crew Lead Accounts" />}
        hasCreate={false}
        storeKey={`${resources.THIRD_PARTY_WORKER}.listParams`}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="name" />
          <EmailField source="primary_email" sortable={false} />
          <TextField source="phone" sortable={false} />
          <TextField source="status" sortable={false} />
        </Datagrid>
      </List>
    </Card>
  );
};

export default CrewLeads;
