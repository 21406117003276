import getAssignedSlotCount from './getAssignedSlotCount';
import getTotalSlotCount from './getTotalSlotCount';

const isTransitFunctional = (jobs) => {
  const assignedSlots = getAssignedSlotCount(jobs);
  const totalSlots = getTotalSlotCount(jobs);

  return assignedSlots === totalSlots;
};

export default isTransitFunctional;
