import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { bool } from 'prop-types';
import { Form, SelectInput, TextInput, useCreate, useNotify, useRefresh, useShowContext } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { ORDER_OPERATOR_NOTE_DOMAINS } from '@/constants/orderOperatorNotes';
import { OrderActionContext } from '@/providers/OrderAction';

const AddOperatorNoteForm = ({ isCreating }) => {
  const { setAction } = useContext(OrderActionContext);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <SelectInput fullWidth required label="Domain" source="domain" choices={ORDER_OPERATOR_NOTE_DOMAINS} />
        </Grid>
        <Grid item xs={5}>
          <TextInput fullWidth source="zendesk_ticket" label="Zendesk Ticket #" />
        </Grid>
        <Grid item xs={12}>
          <TextInput required source="operator_note" label="Note" fullWidth multiline minRows={3} maxRows={20} />
        </Grid>
      </Grid>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          startIcon={isCreating ? <CircularProgress size={18} /> : null}
          disabled={isCreating ?? null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

AddOperatorNoteForm.propTypes = {
  isCreating: bool.isRequired,
};

const AddOperatorNote = () => {
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const { setAction } = useContext(OrderActionContext);

  const [create, { isLoading: isCreating }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    const message = error.body?.detail ?? error.message;

    mx.track(`Order Management - Error adding Operator Note`, {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
      error,
    });

    notify(`Error occurred adding Operator Note - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track(`Order Management - Operator Note Added`, {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
    });

    notify('Operator Note Added', {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    create(
      resources.ORDERS,
      {
        data: {
          notes: data.operator_note,
          zendesk_ticket: data.zendesk_ticket,
          domain: data.domain,
        },
        meta: {
          resourceId: orderId,
          subResource: 'operator_notes',
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={500}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Operator Note
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <AddOperatorNoteForm isCreating={isCreating} />
        </Form>
      </Box>
    </Box>
  );
};

export default AddOperatorNote;
