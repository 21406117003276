import dayjs from 'dayjs';
import { shape, string } from 'prop-types';
import React from 'react';
import { useRecordContext } from 'react-admin';
import TextFieldWrapper from '../TextFieldWrapper';

// No zero/one index issue present for day of week
// Python uses Monday = 1, Sunday = 7
// DayJs uses Sunday = 0, Saturday = 6
// DayJs auto applied modulo of 7 setting .day(7) results in 0, which is Sunday
const DayOfWeekField = ({ source, textFieldProps }) => {
  const record = useRecordContext();

  if (!record || !record[source]) return null;

  const dayjsObj = dayjs().day(record[source]);

  return <TextFieldWrapper textFieldProps={{ whiteSpace: 'pre-wrap', ...textFieldProps }} value={dayjsObj.format('dddd')} />;
};

DayOfWeekField.propTypes = {
  source: string.isRequired,
  textFieldProps: shape({}),
};

DayOfWeekField.defaultProps = {
  textFieldProps: {},
};

export default DayOfWeekField;
