import React, { useEffect } from 'react';
import { Datagrid, Pagination } from 'react-admin';
import mx from 'mixpanel-browser';

import { Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { useCustomParams } from '@/hooks';
import List from '@/ReactAdmin/List';
import ViewUndefinedCard from '@/shared/ViewUndefinedCard';

import JobsPanel from './ExpandPanel';
import views from './views';

const OrdersDatagrid = () => {
  const { view } = useCustomParams();

  const columns = views[view]?.columns ?? [<TextFieldWrapper value="No columns for view defined" />];

  return (
    <Datagrid rowClick="show" expand={<JobsPanel />} bulkActionButtons={false} expandSingle>
      {columns}
    </Datagrid>
  );
};

const OrdersPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

const OrderList = () => {
  const { view } = useCustomParams();

  useEffect(() => {
    mx.track('Resource List - Viewed', {
      resource: resources.ORDERS,
      view,
    });
  }, []);

  if (!views || !views[view]) return <ViewUndefinedCard />;

  const filters = [...views.all.filters, ...(view !== 'all' && views[view] ? views[view].filters : [])];

  const filterDefaultValues = {
    ...views?.all?.filterDefaultValues,
    ...(view !== 'all' && views[view] ? views[view].filterDefaultValues : []),
  };

  return (
    <Card mt={3} component={Box}>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Orders</Typography>
      </Box>
      <Divider />
      <List
        resource={resources.ORDERS}
        perPage={10}
        filterDefaultValues={filterDefaultValues}
        filters={filters}
        pagination={<OrdersPagination />}
        disableSyncWithLocation
        exporter={false}
        storeKey={`${resources.ORDERS}.${view}.listParams`}
      >
        <OrdersDatagrid />
      </List>
    </Card>
  );
};

export default OrderList;
