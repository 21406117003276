import React from 'react';

import { Box, Card, Divider } from '@mui/material';
import { BooleanField, Datagrid, ReferenceField, TextField } from 'react-admin';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import MarketSectionHeader from './Common/SectionHeader';

const MarketList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} justifyContent="space-between" alignItems="center">
      <MarketSectionHeader title="Markets" />
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.MARKETS}
      exporter={false}
      empty={<EmptyResourceList text="No Markets" />}
      hasCreate={false}
      storeKey={`${resources.MARKETS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" label="Name" />
        <TextField source="status" />
        <TextField source="region" />
        <TextField source="market_type" />
        <ReferenceField reference={resources.USERS} source="market_manager" sortable={false} link={false}>
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="is_pod_available" label="PODS" />
        <BooleanField source="is_marketplace" label="Marketplace" />
        <BooleanField source="is_labor_only" label="Labor Only" />
      </Datagrid>
    </List>
  </Card>
);

export default MarketList;
