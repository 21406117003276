import React from 'react';

import _ from 'lodash';
import { bool, number, string } from 'prop-types';
import { Datagrid, Pagination, useListContext, useRecordContext } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { ChangeHistory, Remove, TrendingDown, TrendingUp } from '@mui/icons-material';
import { Alert, Box, Card, CircularProgress, Divider, Typography } from '@mui/material';

import ListBase from '@/ReactAdmin/List/ListBase';
import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES } from '@/constants/pricingLayers';
import CurrencyField from '@/fields/CurrencyField';
import DayJsField from '@/fields/DayJsField';
import MarketNameField from '@/fields/MarketNameField';
import ServiceNameField from '@/fields/ServiceNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import EmptyResourceList from '@/shared/EmptyResourceList';
import formatCurrency from '@/utils/currency/formatCurrency';

const PriceDeltaLabel = ({ text }) => (
  <Box display="flex" justifyContent="flex-end" alignItems="center" gap={0.5} textAlign="right">
    <span>{text}</span>
    <ChangeHistory fontSize="small" />
  </Box>
);

PriceDeltaLabel.propTypes = {
  text: string.isRequired,
};

const DeltaIcon = ({ delta }) => {
  if (delta < 0) {
    return <TrendingDown fontSize="small" color="error" />;
  }
  if (delta > 0) {
    return <TrendingUp fontSize="small" color="success" />;
  }
  return <Remove fontSize="small" color="neutral" />;
};

DeltaIcon.propTypes = {
  delta: number.isRequired,
};

const PriceDeltaField = ({ source, inputName }) => {
  const record = useRecordContext();

  const inputValue = useWatch({ name: inputName });

  if (!source || !record?.[source]) return null;

  const delta = inputValue - record[source];

  return (
    <Box display="flex" justifyContent="space-between" minWidth={75}>
      <DeltaIcon delta={delta} />
      <TextFieldWrapper value={formatCurrency(delta)} />
    </Box>
  );
};

PriceDeltaField.propTypes = {
  source: string.isRequired,
  inputName: string.isRequired,
};

const PriceDeltaCard = ({ minimumInputValid }) => {
  const { isFetching, isLoading, total } = useListContext();

  return (
    <Card component={Box} mt={2}>
      <Box py={2} px={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Price Delta</Typography>
        <Alert variant="filled" severity="info">
          Delta is displayed against <strong>current</strong> pricing, not any upcoming changes
        </Alert>
      </Box>
      <Divider />
      {minimumInputValid ? (
        <>
          {isFetching || isLoading ? (
            <Box display="flex" justifyContent="center" py={2}>
              <CircularProgress size={50} />
            </Box>
          ) : null}
          <Datagrid
            isLoading={isFetching || isLoading}
            bulkActionButtons={false}
            empty={<EmptyResourceList text="No layers to compare against" />}
          >
            <ServiceNameField source="service_id" />
            <DayJsField format="MM/DD/YYYY" source="effective_date" />
            <MarketNameField source="start_market_id" label="Market" />
            <CurrencyField source="base_price" />
            <PriceDeltaField
              label={<PriceDeltaLabel text="Base" />}
              textAlign="right"
              source="base_price"
              inputName="base_price"
            />
            <CurrencyField source="per_worker_price" />
            <PriceDeltaField
              label={<PriceDeltaLabel text="Per worker" />}
              textAlign="right"
              source="per_worker_price"
              inputName="per_worker_price"
            />
          </Datagrid>
          {total > 0 ? <Pagination /> : null}
        </>
      ) : (
        <EmptyResourceList text="Minimum inputs required" />
      )}
    </Card>
  );
};

PriceDeltaCard.propTypes = {
  minimumInputValid: bool.isRequired,
};

const PriceDelta = () => {
  const selectedEffectiveDate = useWatch({ name: 'effective_date' });
  const selectedMarketIds = useWatch({ name: 'market_ids' });
  const selectedServiceIds = useWatch({ name: 'service_ids' });

  const minimumInputValid = Boolean(
    selectedEffectiveDate && !_.isEmpty(selectedMarketIds) && !_.isEmpty(selectedServiceIds),
  );

  return (
    <ListBase
      resource={resources.PRICING_LAYERS}
      filter={{
        is_current: true,
        service_id: selectedServiceIds,
        start_market_id: selectedMarketIds,
        type: PRICING_LAYER_TYPES.SERVICE,
      }}
      sort={{ field: 'start_market_id', order: 'DESC' }}
      storeKey={`${resources.PRICING_LAYERS}.service.price_delta`}
      queryOptions={{
        enabled: minimumInputValid,
      }}
    >
      <PriceDeltaCard minimumInputValid={minimumInputValid} />
    </ListBase>
  );
};

export default PriceDelta;
