import React from 'react';

import {
  BooleanInput,
  DateInput,
  EditBase,
  Form,
  NumberInput,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PROMO_CODE_STATUSES } from '@/constants/promoCode';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between">
    <ShowButton label="Cancel" icon={null} />
    <SaveButton label="Update" icon={null} />
  </Box>
);

const DetailsForm = () => (
  <Form>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput source="description" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <DateInput source="start_date" fullWidth />
        </Grid>

        <Grid item xs={4}>
          <SelectInput source="promo_id_status" label="Status" choices={PROMO_CODE_STATUSES} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <DateInput source="expiration_date" fullWidth />
        </Grid>

        <Grid item xs={4}>
          <NumberInput source="maximum_uses" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <BooleanInput source="unlimited" fullWidth />
        </Grid>
      </Grid>
    </CardContent>
    <Divider />
    <CardContent>
      <FormControls />
    </CardContent>
  </Form>
);

const PromoCodeEdit = () => {
  const { id: promoCodeId } = useParams();

  return (
    <EditBase
      resource={resources.PROMO_CODES}
      id={promoCodeId}
      mutationMode="pessimistic"
      redirect={`/${resources.PROMO_CODES}/${promoCodeId}/show`}
      actions={false}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Overview" />
        <Divider />
        <DetailsForm />
      </Card>
    </EditBase>
  );
};

export default PromoCodeEdit;
