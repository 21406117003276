import { func, shape } from 'prop-types';
import React from 'react';

import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import objectDiff from '@/utils/objectDiff';

const AddressDiff = ({ address, diff }) => {
  const addressKeys = ['line_1', 'line_2', 'city', 'state', 'postal_id'];
  return (
    <Box>
      {addressKeys.map((addressKey) => (
        <Typography
          key={`${address.id}-${addressKey}`}
          sx={{ fontStyle: diff[addressKey] ? 'italic' : 'normal' }}
          color={diff[addressKey] ? 'text.warning' : 'text.primary'}
        >
          {address[addressKey]}
        </Typography>
      ))}
    </Box>
  );
};

AddressDiff.propTypes = {
  address: shape({}).isRequired,
  diff: shape({}).isRequired,
};

const CorrectedAddress = ({ onClose, submittedAddress, correctedAddress }) => {
  const addressDiff = objectDiff(correctedAddress, submittedAddress);

  return (
    <>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Submitted" />
            <Divider />
            <CardContent>
              <AddressDiff address={submittedAddress} diff={addressDiff} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Corrected" />
            <Divider />
            <CardContent>
              <AddressDiff address={correctedAddress} diff={addressDiff} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={onClose} variant="contained">
          Acknowledge
        </Button>
      </Box>
    </>
  );
};

CorrectedAddress.propTypes = {
  onClose: func.isRequired,
  submittedAddress: shape({}).isRequired,
  correctedAddress: shape({}).isRequired,
};

export default CorrectedAddress;
