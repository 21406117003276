import React from 'react';

import { string } from 'prop-types';

import { Box, Typography } from '@mui/material';

const SupplyInventorySectionHeader = ({ title }) => (
  <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h5">{title}</Typography>
  </Box>
);

SupplyInventorySectionHeader.propTypes = {
  title: string.isRequired,
};

export default SupplyInventorySectionHeader;
