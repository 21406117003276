export const PROMO_CODE_STATUSES = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
];

export const PROMO_CODE_TYPES = [
  { id: 'Amount', name: 'Amount' },
  { id: 'Percent', name: 'Percent' },
];

export const PROMO_CODES_TABS = {
  DETAILS: 'details',
};
