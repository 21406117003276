import React from 'react';

import { NumberInput } from 'react-admin';

import { Grid } from '@mui/material';

const OfferpadInventoryInput = () => (
  <Grid container spacing={4}>
    <Grid item xs={4} xl={12 / 3}>
      <NumberInput source="inventory.extra_data.weight" label="Inventory Weight" min={0} max={100000} defaultValue={0} />
    </Grid>
    <Grid item xs={4} xl={12 / 3}>
      <NumberInput source="inventory.extra_data.assembly" label="Assembly Items" min={0} max={50} defaultValue={0} />
    </Grid>
    <Grid item xs={4} xl={12 / 3}>
      <NumberInput source="inventory.extra_data.disassembly" label="Disassembly Items" min={0} max={50} defaultValue={0} />
    </Grid>
  </Grid>
);

export default OfferpadInventoryInput;
