import React from 'react';

import {
  EditBase,
  Form,
  SelectInput,
  TextInput,
  required,
  useEditContext,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { STATES } from '@/constants/location';
import { useNavigate } from 'react-router';

const FormControls = () => {
  const { isLoading: isUpdating } = useEditContext();
  const accountRecord = useRecordContext();

  const navigate = useNavigate();

  const navigateBack = () => navigate(`/${resources.ACCOUNTS}/${accountRecord.id}/show`);

  return (
    <Box display="flex" justifyContent="space-between">
      <Button variant="contained" color="neutral" onClick={navigateBack}>
        Back
      </Button>
      <Button
        variant="contained"
        type="submit"
        disabled={isUpdating ?? null}
        endIcon={isUpdating ? <CircularProgress size={18} /> : null}
      >
        Save
      </Button>
    </Box>
  );
};

const ProsDetailsOverviewLocationEdit = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const transform = (data) => ({
    line_1: data.location.street_address_1,
    line_2: data.location.street_address_2 ?? null,
    city: data.location.city,
    state: data.location.state,
    postal_id: data.location.postal_id,
    country: 'US',
  });

  const onSuccess = () => {
    refresh();
    redirect(`/${resources.ACCOUNTS}/${record.id}/show`);
  };

  if (!record) return null;

  return (
    <EditBase
      resource={resources.ACCOUNTS}
      id={record.id}
      mutationOptions={{ meta: { subResource: 'DETAILS/CONTACTINFO/UPDATELOCATION' }, onSuccess }}
      mutationMode="pessimistic"
      redirect={`/${resources.ACCOUNTS}/${record.id}/show`}
      actions={false}
      transform={transform}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Contact Info - Location" />
        <Divider />
        <Form>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput source="location.street_address_1" label="Line 1" validate={[required()]} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="location.street_address_2" label="Line 2" validate={[]} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="location.city" label="City" validate={[required()]} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  label="State"
                  source="location.state"
                  choices={STATES}
                  validate={[required()]}
                  fullWidth
                  defaultValue="AK"
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  label="Postal Code"
                  source="location.postal_id"
                  validate={[required()]}
                  inputProps={{ maxLength: 5 }}
                  fullWidth
                  placeholder="ex:11111"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <FormControls />
          </CardContent>
        </Form>
      </Card>
    </EditBase>
  );
};

export default ProsDetailsOverviewLocationEdit;
