import * as resources from '@/api/resources';

import ConnectedAccountEdit from './Edit';
import ConnectedAccountList from './List';
import ConnectedAccountListShow from './Show';

const connectedAccounts = () => ({
  name: resources.ADMIN_CONNECTED_ACCOUNTS,
  edit: ConnectedAccountEdit,
  list: ConnectedAccountList,
  show: ConnectedAccountListShow,
});

export default connectedAccounts;
