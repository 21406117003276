import { useEffect } from 'react';
import { useListContext, useShowContext } from 'react-admin';
import mx from 'mixpanel-browser';

import { useCustomParams } from '@/hooks';

const ListViewChangedTracker = () => {
  const { resource: parentResource } = useShowContext();
  const { total, page, perPage, filterValues, resource } = useListContext();
  const { view } = useCustomParams();

  useEffect(() => {
    if (typeof total !== 'undefined') {
      mx.track('Resource List - View Changed', {
        resource,
        parentResource, // passes null if list is not on show view
        view,
        viewOptions: {
          page,
          perPage,
          filterValues,
        },
        total,
      });
    }
  }, [total, page, perPage, filterValues]);

  return null;
};

export default ListViewChangedTracker;
