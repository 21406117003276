import React from 'react';

import { shape } from 'prop-types';

import { Box, Typography } from '@mui/material';

const TemplateUnavailableTooltip = ({ template }) => (
  <Box p={1}>
    <Typography variant="h6">Unavailable</Typography>
    <Typography variant="body2" whiteSpace="pre-line">
      {template.reasons?.join('\n')}
    </Typography>
  </Box>
);

TemplateUnavailableTooltip.propTypes = {
  template: shape({}).isRequired,
};

export default TemplateUnavailableTooltip;
