import React from 'react';

import { Labeled, NumberField, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CARRIER_ACCOUNT_DETAIL_SECTION, CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';
import CurrencyField from '@/fields/CurrencyField';

import CarrierDetailsSectionHeader from '../Common/SectionHeader';

const Contract = () => (
  <Card mt={3} component={Box}>
    <CarrierDetailsSectionHeader
      title="Contract"
      tab={CARRIER_ACCOUNT_TAB.DETAILS}
      section={CARRIER_ACCOUNT_DETAIL_SECTION.CONTRACT}
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="contract_type" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <CurrencyField source="hourly_rate" textAlign="left" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <CurrencyField source="late_cancel_reschedule_amount" textAlign="left" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <CurrencyField source="weekly_minimum" textAlign="left" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="compensation_minimum_type" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <CurrencyField source="compensation_minimum" textAlign="left" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <CurrencyField source="cancel_reschedule_after_start_amount" textAlign="left" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <CurrencyField source="mileage_bonus" textAlign="left" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <NumberField source="mileage_bonus_minimum_distance" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <NumberField source="regional_move_rate" emptyText="N/A" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default Contract;
