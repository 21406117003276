import React, { useContext, useEffect } from 'react';

import dayjs from 'dayjs';
import { DateInput, RadioButtonGroupInput, ReferenceInput, SelectArrayInput, SelectInput } from 'react-admin';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';

import { Alert, Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/pricingLayers';
import { ServiceLayerSummaryContext } from '@/providers/Pricing/ServiceLayerSummary';

const PricingOptions = () => {
  const { setDataEnabled, setFilters } = useContext(ServiceLayerSummaryContext);
  const { getValues, formState, reset, setValue, clearErrors } = useFormContext();

  const dateLookup = useWatch({ name: 'date_lookup' });
  const isHistorical = dateLookup === 'historical';

  const selectedMarket = useWatch({ name: 'market_id' });
  const selectedService = useWatch({ name: 'service_id' });

  useEffect(() => {
    const { isDirty, isSubmitSuccessful } = formState;
    if (isDirty) {
      setDataEnabled(false);
    }
    if (isSubmitSuccessful) {
      const formValues = getValues();
      reset(formValues, { keepDirty: false });
      formValues.start_market_id = formValues.market_id;
      formValues.end_market_id = formValues.market_id;
      // Filter null entries from form data and set in context
      setFilters(Object.fromEntries(Object.entries(formValues).filter(([, v]) => v != null)));
    }
  }, [formState]);

  useEffect(() => {
    if (dateLookup === 'current') {
      const nowDateString = dayjs().format('YYYY-MM-DD');
      setValue('effective_date_lte', nowDateString);
      setValue('is_current', true);
    } else {
      // Want to unset this filter for non current lookup, because layers may be a mix of current/non current that make up historical results
      setValue('is_current', null);
    }
  }, [dateLookup]);

  useEffect(() => {
    clearErrors('minimumFilter');
  }, [selectedMarket, selectedService, dateLookup]);

  return (
    <Card mt={3} component={Box}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <RadioButtonGroupInput
              label=""
              defaultValue="current"
              source="date_lookup"
              choices={[
                { id: 'current', name: 'Current' },
                { id: 'historical', name: 'Historical' },
              ]}
            />
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Box display={isHistorical ? 'block' : 'none'}>
              <DateInput source="effective_date_lte" required label="Effective Date" />
            </Box>
            <ReferenceInput
              source="market_id"
              reference={resources.MARKETS}
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            {/* For Service filter - we want all except LD Transit (handled by lane pricing view) */}
            <SelectArrayInput
              label="Service"
              autoComplete="off"
              source="service_id"
              choices={Object.values(SERVICES).filter(({ id }) => id !== SERVICES.LDTRANSIT.id)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {formState.errors.minimumFilter ? (
              <Alert variant="outlined" severity="warning">
                {formState.errors.minimumFilter.message}
              </Alert>
            ) : null}
          </Box>
          <Box>
            <Button variant="outlined" type="submit">
              Search
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const PricingOptionsForm = () => {
  const methods = useForm({ defaultValues: { date_lookup: 'current', effective_date_lte: dayjs().format('YYYY-MM-DD') } });

  const onSubmit = () => {};

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PricingOptions />
      </form>
    </FormProvider>
  );
};

export default PricingOptionsForm;
