// #region

export const PRO_ACCOUNT_RECORD_TYPE = 'Bellhop';

export const PRO_ACCOUNT_TAB = {
  AVAILABILITY: 'availability',
  DETAILS: 'details',
  JOB_HISTORY: 'job-history',
  PAYOUT_CONFIG: 'payout-config',
  PERFORMANCE: 'performance',
  SKILLS: 'skills',
  SUPPORT: 'support',
  TRANSACTIONS: 'transactions',
};

export const PRO_ACCOUNT_DETAIL_SECTION = {
  OVERVIEW: 'overview',
  CONTACT_INFO: 'contact-info',
  STATS: 'stats',
  RECRUITMENT: 'recruitment',
  PAYMENT: 'payment',
};

export const PRO_ACCOUNT_SKILL_SECTION = {
  SKILL_LIST: 'skill-list',
};

export const PRO_ACCOUNT_PERFORMANCE_SECTION = {
  RATING: 'rating',
  LATE_RATE: 'late-rate',
  DAMAGE: 'damage',
  REMOVAL: 'removal',
};

export const PRO_ACCOUNT_OVERVIEW_CARD = {
  STATUS: 'status',
  DEACTIVATE: 'deactivate',
};

export const PRO_ACCOUNT_CONTACT_INFO_CARD = {
  LOCATION: 'location',
};

export const PRO_ACCOUNT_DROPDOWN_STATUSES = [
  { id: 'Active', name: 'Active' },
  { id: 'Onboarding', name: 'Onboarding' },
  { id: 'Pending Review', name: 'Pending Review' },
  { id: 'Opportunity', name: 'Opportunity' },
  { id: 'Inactive', name: 'Inactive', isActive: false },
];

export const PRO_ACCOUNT_DROPDOWN_REASONS = [{ id: 'Other', name: 'Other' }];

export const PRO_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS = [
  { id: 'No Show', name: 'No Show' },
  { id: 'Seasonal', name: 'Seasonal' },
  { id: 'Quality', name: 'Quality' },
  { id: 'Bellhop XP', name: 'Bellhop XP' },
  { id: 'Frozen Account', name: 'Frozen Account' },
  { id: 'Past Balance Outstanding', name: 'Past Balance Outstanding' },
  { id: 'Harassing Behavior', name: 'Harassing Behavior' },
  { id: 'Fraudulent Activity', name: 'Fraudulent Activity' },
  { id: 'Other', name: 'Other' },
  { id: 'Self selected (In-App)', name: 'Self selected (In-App)' },
  { id: 'Deactivated from poor - Damage Rate', name: 'Deactivated from poor - Damage Rate' },
  { id: 'Deactivated from poor - Late Rate', name: 'Deactivated from poor - Late Rate' },
  { id: 'Deactivated from poor - Removal Rate', name: 'Deactivated from poor - Removal Rate' },
  { id: 'Deactivated from poor - Damage Rate, Late Rate', name: 'Deactivated from poor - Damage Rate, Late Rate' },
  { id: 'Deactivated from poor - Damage Rate, Removal Rate', name: 'Deactivated from poor - Damage Rate, Removal Rate' },
  { id: 'Deactivated from poor - Late Rate, Removal Rate', name: 'Deactivated from poor - Late Rate, Removal Rate' },
  {
    id: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
    name: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
  },
  { id: 'Background Check', name: 'Background Check' },
];

export const PRO_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS = [{ id: 'Inactive', name: 'Inactive' }];

export const PRO_ACCOUNT_TSHIRT_SIZE = [
  { id: 'S', name: 'Small' },
  { id: 'M', name: 'Medium' },
  { id: 'L', name: 'Large' },
  { id: 'XL', name: 'X-Large' },
  { id: 'XXL', name: 'XX-Large' },
  { id: '2XL', name: '2X-Large' },
];
