const ADJUSTMENT_REASONS = [
  'Booking Error',
  'Honor Original Pricing due to Order Change',
  'HQRS',
  'Missing / Insufficient Equipment',
  'Promo Code Applied Post-Move',
  'Truck Driver Behavior / Professionalism',
  'Truck Driver Skills / Ability',
  'Truck Not Used (LD Only - TONU)',
  'Truck Quality / Cleanliness',
  'Truck(s) Late / Left Early',
  'Waive Late Cancellation Fee',
  'Worker Behavior / Professionalism',
  'Worker Skills / Ability',
  'Worker / Transit Downtime',
  'Worker(s) Late / Left Early',
];

export default ADJUSTMENT_REASONS;
