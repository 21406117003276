/* React Admin auth provider requires custom object in Promise rejects, not standard JS Error object */
/* eslint-disable prefer-promise-reject-errors */
import { Auth0Client } from '@auth0/auth0-spa-js';
import authConfig from './authConfig';

const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const clientOptions = {
  audience,
};

const auth0 = new Auth0Client({
  domain: authConfig.domain,
  client_id: authConfig.clientID,
  ...clientOptions,
});

export default {
  // called when the user attempts to log in
  login: ({ code, state, location, targetUrl }) => {
    if (!(code && state)) {
      return auth0
        .loginWithRedirect({
          redirect_uri: `${window.location.origin}/login`,
          ...clientOptions,
          ...(targetUrl && {
            appState: {
              targetUrl,
            },
          }),
        })
        .then(() => ({ redirectTo: false }));
    }

    return auth0
      .handleRedirectCallback(`${location.pathname}${location.search !== '' ? `?${location.search}` : ''}`)
      .then((res) => {
        const { appState: { targetUrl: redirectTarget } = {} } = res;
        return auth0
          .getIdTokenClaims()
          .then(({ user_permissions: userPermissions }) =>
            Promise.resolve(
              userPermissions.reduce((prev, permission) => {
                const [action, tool, resource] = permission.split(':');
                return {
                  ...prev,
                  [tool]: {
                    ...(prev[tool] ?? {}),
                    ...(resource
                      ? {
                          [resource]: {
                            ...(prev[tool]?.[resource] ?? {}),
                            [action]: true,
                          },
                        }
                      : {
                          [action]: true,
                        }),
                  },
                };
              }, {}),
            ),
          )
          .then((permissions) => {
            const appPermissions = {};
            appPermissions.tools = permissions;
            localStorage.setItem('permissions', JSON.stringify(appPermissions));
            return Promise.resolve({
              redirectTo: redirectTarget ?? '/',
            });
          });
      });
  },
  // called when the user clicks on the logout button
  logout: () =>
    auth0.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        // need to check for this as react-admin calls logout in case checkAuth failed
        localStorage.removeItem('permissions');
        return auth0.logout({
          returnTo: window.location.origin,
        });
      }
      return Promise.resolve();
    }),
  // called when the API returns an error
  checkError: (error) => {
    const { status, message } = error ?? {};
    if (status === 401 || status === 403) {
      return Promise.reject({
        redirectTo: '/',
        message,
        logoutUser: false,
      });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () =>
    auth0.isAuthenticated().then(async (isAuthenticated) => {
      if (isAuthenticated) {
        return Promise.resolve();
      }
      try {
        return auth0.getTokenSilently();
      } catch (err) {
        console.error('Caught exception in checkAuth', err);
        return Promise.reject({
          redirectTo: '/login',
          logoutUser: true,
        });
      }
    }),
  // get the user's profile
  getIdentity: () =>
    // React Admin needs fullName and avatar set in identity for user menu
    // Added id stripped from subject for API use
    Promise.resolve(auth0.getUser()).then((user) => ({
      ...user,
      id: process.env.REACT_APP_ACT_AS_USER ?? user?.sub.replace(/^auth0\|/, ''),
      fullName: user.name,
      avatar: user.picture,
    })),
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const perms = JSON.parse(localStorage.getItem('permissions'));
    return perms
      ? Promise.resolve(perms)
      : Promise.reject({
          redirectTo: '/login',
          logoutUser: true,
        });
  },
  getAccessToken: () => auth0.getTokenSilently(),
};
