import * as resources from '@/api/resources';

import OrderItemEdit from './Edit';
import OrderItemEditList from './List';
import OrderItemEditShow from './Show';

const orderItems = () => ({
  name: resources.ADMIN_ORDER_ITEMS,
  edit: OrderItemEdit,
  list: OrderItemEditList,
  show: OrderItemEditShow,
});

export default orderItems;
