import React, { useContext, useEffect } from 'react';

import mx from 'mixpanel-browser';
import { RecordContextProvider, ReferenceField, ShowBase, TextField, useReference, useShowContext } from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, Chip, CssBaseline, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { QuoteContext, QuoteProvider } from '@/providers/Quote';

import AddFee from './actions/AddFee';
import AddProduct from './actions/AddProduct';
import AddServiceGroup from './actions/AddServiceGroup';
import EditFee from './actions/EditFee';
import EditProduct from './actions/EditProduct';
import ModifyDateTime from './actions/ModifyDateTime';
import ModifyInventory from './actions/ModifyInventory';
import ModifyLocations from './actions/ModifyLocations';
import ModifyServiceDetails from './actions/ModifyServiceDetails';
import UpdateAccountPaymentMethod from './actions/UpdateAccountPaymentMethod';

import QuoteOverview from './QuoteOverview';
import ScriptModals from './QuoteOverview/ScriptModals';

const RelatedInfo = () => {
  const { record: quoteRecord } = useShowContext();
  const { accountId, orderId, isLongDistanceV2 } = useContext(QuoteContext);

  const { referenceRecord: orderRecord } = useReference({
    reference: resources.ORDERS,
    id: orderId,
    options: { enabled: Boolean(orderId) },
  });

  const { referenceRecord: accountRecord } = useReference({
    reference: resources.CUSTOMERS,
    id: accountId,
    options: { enabled: Boolean(accountId) },
  });

  if (!orderId && !accountId) return null;

  return (
    <Card my={3} px={2} py={2} component={Box}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={3}>
          {orderId ? (
            <>
              <Typography variant="h5" component="span" pr={1}>
                Order #:
              </Typography>
              <RecordContextProvider value={orderRecord}>
                <ReferenceField source="id" reference={resources.ORDERS} link="show">
                  <TextField variant="h5" source="confirmation_id" />
                </ReferenceField>
              </RecordContextProvider>
            </>
          ) : null}
        </Grid>
        <Grid item xs={6} display="flex" gap={1} justifyContent="center">
          {isLongDistanceV2 && quoteRecord?.partner_id !== 'PODS' ? (
            <Chip variant="outlined" color="primary" label="LD" />
          ) : null}
          {quoteRecord?.partner_id ? <Chip variant="outlined" color="primary" label={quoteRecord?.partner_id} /> : null}
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end">
          {accountId ? (
            <>
              <Typography variant="h5" component="span" pr={1}>
                Account:
              </Typography>
              <RecordContextProvider value={accountRecord}>
                <ReferenceField source="id" reference={resources.CUSTOMERS} link="show">
                  <TextField variant="h5" source="name" />
                </ReferenceField>
              </RecordContextProvider>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
};

const Quote = () => {
  const { record: { id, confirmation_id: confirmationId } = {} } = useShowContext();

  const { action, resourceId } = useParams();

  useEffect(() => {
    if (id) {
      mx.track('Resource Show - Viewed', {
        resource: resources.ORDERS,
        resourceId: id,
        confirmationId,
      });
    }
  }, [id]);

  switch (action) {
    case 'add-fee':
      return <AddFee />;
    case 'add-product':
      return <AddProduct />;
    case 'add-service-group':
      return <AddServiceGroup />;
    case 'edit-fee':
      return <EditFee feeId={resourceId} />;
    case 'edit-product':
      return <EditProduct productId={resourceId} />;
    case 'modify-datetime':
      return <ModifyDateTime serviceGroupId={resourceId} />;
    case 'modify-inventory':
      return <ModifyInventory />;
    case 'modify-locations':
      return <ModifyLocations />;
    case 'modify-service-details':
      return <ModifyServiceDetails serviceGroupId={resourceId} />;
    case 'update-account-payment-method':
      return <UpdateAccountPaymentMethod />;
    default:
      return <QuoteOverview />;
  }
};

const QuoteShow = () => (
  <ShowBase queryOptions={{ meta: { filter: { aggregate_availability: true } } }}>
    {/* CssBaseline needed to be loaded to override calendar styling */}
    <CssBaseline />
    <QuoteProvider>
      <ScriptModals />
      <RelatedInfo />
      <Quote />
    </QuoteProvider>
  </ShowBase>
);

export default QuoteShow;
