export const latestEventByField = (events, fieldName, fieldNoneValue) =>
  events?.find((event) =>
    event.event_audit_fields.some(
      (field) => field.name === fieldName && field.new_value && field.new_value !== fieldNoneValue,
    ),
  );

export const eventPrevValueByField = (event, fieldName) =>
  event?.event_audit_fields?.find((field) => field.name === fieldName)?.previous_value;

export const eventNewValueByField = (event, fieldName) =>
  event?.event_audit_fields?.find((field) => field.name === fieldName)?.new_value;
