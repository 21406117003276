import React, { useContext } from 'react';

import { usePermissions, useShowContext } from 'react-admin';

import { Box, Button } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';

const ConvertToBhnButton = () => {
  const {
    record: { status: orderStatus },
  } = useShowContext();

  const { permissions } = usePermissions();

  const isCreatedOrder = orderStatus === 'CREATED';
  const canConvertBNH = permissions?.tools?.hqadmin?.orders?.convert_to_bhn && isCreatedOrder;

  const { setAction } = useContext(OrderActionContext);

  const handleButtonClick = () => {
    setAction('convert_to_bhn');
  };

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {canConvertBNH ? (
        <Button onClick={handleButtonClick} variant="outlined">
          Convert To BHN
        </Button>
      ) : null}
    </Box>
  );
};

export default ConvertToBhnButton;
