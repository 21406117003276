import React, { useState } from 'react';

import dayjs from 'dayjs';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import { Loading } from 'react-admin';
import Calendar from 'react-calendar';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';

import DateCell from './DateCell';

const getWeekdayLabel = (d) => {
  let dd = '';

  switch (d) {
    case 1:
      dd = 'S';
      break;
    case 2:
      dd = 'M';
      break;
    case 3:
      dd = 'T';
      break;
    case 4:
      dd = 'W';
      break;
    case 5:
      dd = 'T';
      break;
    case 6:
      dd = 'F';
      break;
    case 7:
      dd = 'S';
      break;

    default:
      dd = getWeekdayLabel(d % 7);
      break;
  }

  return dd;
};

const formatShortWeekday = (date) => {
  const d = dayjs(date).format('d');

  return getWeekdayLabel(Number(d) + 1);
};

const renderDateCell = (date, view, availabilities, activeStartDate) => (
  <DateCell date={date} view={view} availabilities={availabilities} activeStartDate={activeStartDate} />
);

const AvailabilityCalendar = ({ availabilities, setRefDate, loading }) => {
  const [currDate] = useState(new Date());

  const onActiveStartDateChange = ({ activeStartDate, view }) => {
    if (view === 'month') {
      setRefDate(dayjs(activeStartDate).add(1, 'day'));
    }
  };

  const onViewChange = ({ activeStartDate, view }) => {
    if (view === 'month') {
      setRefDate(dayjs(activeStartDate).add(1, 'day'));
    }
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <Box display={loading ? 'none' : 'block'}>
        <Calendar
          minDetail="year"
          className="account-availability-calendar"
          calendarType="US"
          value={currDate}
          prevLabel={<KeyboardArrowLeft />}
          prev2Label={null}
          nextLabel={<KeyboardArrowRight />}
          next2Label={null}
          tileContent={({ activeStartDate, date, view }) => renderDateCell(date, view, availabilities, activeStartDate)}
          tileClassName="cell"
          formatDay={(_, date) => dayjs(date).format('DD')}
          formatWeekday={(_, date) => dayjs(date).format('ddd')}
          formatShortWeekday={(_, date) => formatShortWeekday(date)}
          onActiveStartDateChange={onActiveStartDateChange}
          onViewChange={onViewChange}
        />
      </Box>
    </>
  );
};

AvailabilityCalendar.propTypes = {
  availabilities: arrayOf(
    shape({ dateTime: string, morning: bool, afternoon: bool, hourly: shape({ [oneOfType([number, string])]: string }) }),
  ),
  setRefDate: func,
  loading: bool.isRequired,
};

AvailabilityCalendar.defaultProps = {
  availabilities: [],
  setRefDate: () => {},
};

export default AvailabilityCalendar;
