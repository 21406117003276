import React from 'react';

import { func, shape } from 'prop-types';
import { DateInput, ReferenceInput, SelectInput, required } from 'react-admin';

import { Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { QUALIFICATION_LEVEL, TIME_AM_PM } from '@/constants/supplyinventory';

const InputFilterSection = ({ handleChange, setFormValues, obj }) => {
  const validateStartDate = (value) => {
    const today = new Date();
    const selectedDate = new Date(value);

    if (selectedDate <= today) {
      return 'Date must be at least one day ahead of today!';
    }
    return undefined;
  };

  const validateEndDate = (value) => {
    const startDate = new Date(obj.slot_datetime);
    const selectedDate = new Date(value);
    const differenceInDays = Math.round((selectedDate - startDate) / (1000 * 60 * 60 * 24));

    if (!differenceInDays) return null;
    setFormValues({ ...obj, difference_In_Days: differenceInDays });

    if (differenceInDays < 365 && differenceInDays > 0) {
      return undefined;
    }
    return 'The date must be less or equal to 365 days from today!';
  };

  return (
    <Grid mt={2} container display="flex">
      <Grid item xs={12}>
        <Typography variant="h5">Filter</Typography>
      </Grid>
      <Grid item xs={3} mt={1}>
        <ReferenceInput
          name="market_id"
          source="market_id"
          reference={resources.MARKETS}
          perPage={999}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" optionValue="id" label="Market" onChange={handleChange} validate={[required()]} />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3} mt={1}>
        <DateInput
          label="Start Date"
          name="slot_datetime"
          source="slot_datetime"
          validate={[required(), validateStartDate]}
          onChange={handleChange}
          defaultValue={new Date()}
        />
      </Grid>
      <Grid item xs={3} mt={1}>
        <DateInput
          label="End Date"
          name="end_date"
          source="start_date"
          validate={[required(), validateEndDate]}
          onChange={(event) => {
            validateEndDate(event.target.value);
            handleChange(event, setFormValues);
          }}
        />
      </Grid>
      <Grid item xs={3} mt={1}>
        <SelectInput
          label="AM/PM"
          name="am_pm"
          source="am_pm"
          validate={[required()]}
          choices={TIME_AM_PM}
          defaultValue="AM"
          onChange={handleChange}
        />
      </Grid>
      {obj.section === 'Transit' ? (
        <Grid item xs={3} mt={1}>
          <SelectInput
            label="Qualification Level"
            name="qualification_level"
            source="qualification_level"
            validate={[required()]}
            choices={QUALIFICATION_LEVEL}
            defaultValue={1}
            onChange={handleChange}
          />
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default InputFilterSection;

InputFilterSection.propTypes = {
  obj: shape({}).isRequired,
  handleChange: func.isRequired,
  setFormValues: func.isRequired,
};
