import React from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { Form, useDataProvider, useNotify, useShowContext } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';

import { string } from 'prop-types';
import FeeForm from '../shared/FeeForm';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const EditFee = ({ feeId }) => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Fee updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Fee updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Fee', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating fee on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = ({ amount, description, type }) => {
    const operationsNeeded = [
      {
        op: 'replace',
        path: `/fees/${feeId}`,
        value: {
          adjustment_id: type,
          adjustment_value: Number(amount),
          adjustment_description: description,
        },
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  const validate = ({ amount }) => {
    const errors = {};

    if (amount === 0) {
      errors.amount = 'Amount must be greater than 0';
    }

    return errors;
  };

  const feeRecord = _.find(quoteRecord?.price_adjustments, (adjustment) => adjustment.id === feeId);

  if (!quoteRecord || !feeRecord) return null;

  return (
    <Form
      record={{
        amount: feeRecord?.adjustment_value,
        type: feeRecord?.adjustment_id,
        description: feeRecord?.adjustment_description ?? '',
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <FeeForm />
      <FormControls />
    </Form>
  );
};

EditFee.propTypes = {
  feeId: string.isRequired,
};

export default EditFee;
