import React from 'react';

import { string } from 'prop-types';
import { useNavigate } from 'react-router';
import { usePermissions } from 'react-admin';

import { BarChart, Description, EmojiTransportation, LocalAtm, LocationCity, Category } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';

import { MARKET_TAB } from '@/constants/market';

const MarketsTabs = ({ currentTab }) => {
  const { permissions } = usePermissions();

  const canViewMarketProduct = permissions?.tools?.hqadmin?.market_product?.admin;

  const tabs = [
    { id: MARKET_TAB.DETAILS, icon: <Description /> },
    { id: MARKET_TAB.PERFORMANCE_THRESHOLDS, icon: <BarChart /> },
    { id: MARKET_TAB.BASE_PAY, icon: <LocalAtm /> },
    { id: MARKET_TAB.TRAVEL_PAY, icon: <EmojiTransportation /> },
    { id: MARKET_TAB.SERVICE_AREA, icon: <LocationCity /> },
    ...(canViewMarketProduct ? [{ id: MARKET_TAB.MARKET_PRODUCT, icon: <Category /> }] : []),
  ];

  const navigate = useNavigate();

  return (
    <Tabs
      value={currentTab}
      onChange={(e, newTab) => {
        navigate(newTab);
      }}
      sx={{
        borderBottom: ({ palette }) => `1px solid ${palette.mode === 'dark' ? palette.neutral.dark : palette.neutral.light}`,
        '& .MuiTab-root': {
          textTransform: 'capitalize',
          minHeight: 0,
        },
      }}
    >
      {tabs.map(({ id, icon }, index) => (
        <Tab
          id={`markets-details-tab-${index}`}
          key={id}
          label={id.replace('-', ' ')}
          value={id}
          icon={icon}
          iconPosition="start"
          aria-controls={`markets-details-tabpanel-${index}`}
        />
      ))}
      ;
    </Tabs>
  );
};

MarketsTabs.propTypes = {
  currentTab: string.isRequired,
};

export default MarketsTabs;
