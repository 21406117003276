import React from 'react';

import { string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { Chip } from '@mui/material';

const ZendeskTicketStatusField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !record?.[source]) return null;

  const ticketStatus = record[source];

  return (
    <Chip
      label={ticketStatus.toUpperCase()}
      color={`zendesk.${ticketStatus}`}
      sx={{ borderRadius: '10%', fontSize: 11 }}
      size="small"
    />
  );
};

ZendeskTicketStatusField.propTypes = {
  source: string.isRequired,
};

export default ZendeskTicketStatusField;
