export const EVENT_ACTIONS = {
  JOB_STARTED: 'job.started',
  JOB_CLAIMED: 'job.claimed',
  SLOT_CLOCKED: 'job_slot.clocked',
  SLOT_EDITED: 'job_slot.edited',
};

export const EVENT_ACTIONS_TAKEN_BY_ACCOUNTS = [
  EVENT_ACTIONS.SLOT_CLOCKED,
  EVENT_ACTIONS.JOB_STARTED,
  EVENT_ACTIONS.JOB_CLAIMED,
];

export const EVENT_FIELDS = {
  MOBILE_CLOCK_IN: 'clock_in_datetime',
  MOBILE_CLOCK_OUT: 'clock_out_datetime',
  CLOCK_IN: 'clock_in_date_time',
  CLOCK_OUT: 'clock_out_date_time',
  NONE_VALUE: 'None',
};
