import React from 'react';

import { shape, string } from 'prop-types';
import { useRecordContext, ReferenceField, TextField } from 'react-admin';

import TextFieldWrapper from '@/fields/TextFieldWrapper';

const CompanyField = ({ source, textFieldProps }) => {
  const record = useRecordContext();
  if (!record) return null;

  const value = record[source];
  let accountName = '';
  if (!value) {
    accountName = record.name;
  }

  const displayField = value ? (
    <ReferenceField source="company_id" reference="accounts" label="Company">
      <TextField source="name" />
    </ReferenceField>
  ) : (
    <TextFieldWrapper value={accountName} textFieldProps={textFieldProps} />
  );

  return displayField;
};

CompanyField.propTypes = {
  source: string.isRequired,
  textFieldProps: shape({}),
};

CompanyField.defaultProps = {
  textFieldProps: {},
};

export default CompanyField;
