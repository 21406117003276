import React from 'react';

import { BooleanField, Labeled, NumberField, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CARRIER_ACCOUNT_DETAIL_SECTION, CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';

import CarrierDetailsSectionHeader from '../Common/SectionHeader';

const Truck = () => (
  <Card mt={3} component={Box}>
    <CarrierDetailsSectionHeader
      title="Truck"
      tab={CARRIER_ACCOUNT_TAB.DETAILS}
      section={CARRIER_ACCOUNT_DETAIL_SECTION.TRUCK}
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="truck_count" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <NumberField source="driver_count" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <BooleanField source="regional_mover" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="truck_size_1" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <NumberField source="truck_size_1_quantity" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField label="DOT Number" source="dot_number" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="truck_size_2" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <NumberField source="truck_size_2_quantity" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField label="MC Number" source="mc_number" emptyText="N/A" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default Truck;
