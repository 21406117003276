import React from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import {
  EditBase,
  Form,
  NumberInput,
  RecordContextProvider,
  SaveButton,
  SelectInput,
  useNotify,
  useRecordContext,
  useRedirect,
  useReference,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { PAYOUT_CONFIG_MINIMUM_TYPES } from '@/constants/account';
import ServiceNameField from '@/fields/ServiceNameField';
import getAccountResourceType from '@/utils/accounts/getAccountResourceType';
import getServiceName from '@/utils/serviceName/getServiceName';

import AccountHeader from '../AccountHeader';

const FormControls = () => {
  const { account_id: accountId } = useRecordContext();
  const redirect = useRedirect();

  const { referenceRecord: accountRecord } = useReference({ reference: resources.ACCOUNTS, id: accountId });

  const resourceType = getAccountResourceType(accountRecord);

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="neutral"
          onClick={() => redirect(`/${resourceType}/${accountId}/show/payout-config`)}
        >
          Cancel
        </Button>
        <SaveButton label="Update" icon={null} />
      </CardContent>
    </Card>
  );
};

const PayoutConfigForm = () => {
  const record = useRecordContext();

  if (!record) return null;

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <Form>
      <Card component={Box} mt={3}>
        <CardContent>
          <Typography variant="h5">
            <ServiceNameField source="service" />
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <NumberInput
              label="Base Hourly"
              source="base_hourly_amount_cents"
              format={formatValue}
              parse={parseValue}
              required
            />
            <NumberInput
              label="Per Resource Hourly"
              source="per_resource_hourly_amount_cents"
              format={formatValue}
              parse={parseValue}
              required
              min={0}
            />
            <NumberInput
              label="Minimum"
              source="minimum_amount_per_job_cents"
              format={formatValue}
              parse={parseValue}
              required
              min={0}
            />
            <SelectInput required source="minimum_pay_type" choices={Object.values(PAYOUT_CONFIG_MINIMUM_TYPES)} />
          </Box>
        </CardContent>
      </Card>
      <FormControls />
    </Form>
  );
};

const PayoutConfigEdit = () => {
  const { accountId, payoutConfigId } = useParams();

  const { referenceRecord: accountRecord } = useReference({ reference: resources.ACCOUNTS, id: accountId });

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const resourceType = getAccountResourceType(accountRecord);

  const transform = (data) => {
    const transformedData = _.cloneDeep(data);

    delete transformedData.id;
    delete transformedData.service;

    return transformedData;
  };

  const onSuccess = (data) => {
    mx.track('Resource - Updated', {
      resource: resources.ACCOUNTS,
      resourceId: accountId,
      metadata: {
        subResource: 'payout_config',
        payoutConfigId,
        service: data.service,
      },
    });
    notify(`${getServiceName(data.service)} Payout Config updated`, { type: 'success' });
    refresh();
    redirect(`/${resourceType}/${accountId}/show/payout-config`);
  };

  if (!accountRecord) return null;

  return (
    <>
      <Box mt={3}>
        <RecordContextProvider value={accountRecord}>
          <AccountHeader />
        </RecordContextProvider>
      </Box>
      <EditBase
        id={payoutConfigId}
        resource={`${resources.ACCOUNTS}/payout_config`}
        queryOptions={{
          meta: {
            filter: {
              account_id: accountId,
            },
          },
        }}
        mutationOptions={{
          meta: {
            method: 'PUT',
            fullData: true,
          },
          onSuccess,
        }}
        mutationMode="pessimistic"
        transform={transform}
      >
        <PayoutConfigForm />
      </EditBase>
    </>
  );
};

export default PayoutConfigEdit;
