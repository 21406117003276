import React from 'react';

import { Box, Card } from '@mui/material';

import { PRO_ACCOUNT_PERFORMANCE_SECTION } from '@/constants/prosAccount';
import AlertActiveDevelopment from '@/shared/AlertActiveDevelopment';

import Collapse from './Collapse';

const Performance = () => (
  <>
    <AlertActiveDevelopment />
    <Card mt={2} component={Box}>
      <Collapse title="Rating" section={PRO_ACCOUNT_PERFORMANCE_SECTION.RATING} />
    </Card>
    <Card mt={2} component={Box}>
      <Collapse title="Late Rate" section={PRO_ACCOUNT_PERFORMANCE_SECTION.LATE_RATE} />
    </Card>
    <Card mt={2} component={Box}>
      <Collapse title="Damage" section={PRO_ACCOUNT_PERFORMANCE_SECTION.DAMAGE} />
    </Card>
    <Card mt={2} component={Box}>
      <Collapse title="Removal" section={PRO_ACCOUNT_PERFORMANCE_SECTION.REMOVAL} />
    </Card>
  </>
);

export default Performance;
