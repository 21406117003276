import React from 'react';

import { BooleanField, Datagrid, DateField, TextField, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';
import ContractUrlButton from './ContractUrlButton';

const Compliance = () => {
  const record = useRecordContext();

  return (
    <Card component={Box}>
      <CardHeader title="Compliance" />
      <Divider />
      <CardContent>
        <List
          disableSyncWithLocation
          resource={resources.IRONCLAD_CONTRACTS}
          queryOptions={{ meta: { subResource: 'contract_requests', resourceId: record.id } }}
          exporter={false}
          actions={false}
          empty={<EmptyResourceList text="No ironclad contract available" />}
          storeKey={`${resources.IRONCLAD_CONTRACTS}.compliance.listParams`}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <BooleanField source="approved" />
            <BooleanField source="expired" />
            <DateField source="created_at" showTime />
            <ContractUrlButton source="id" label="" sortable={false} />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

export default Compliance;
