import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import PlacesSuggestions from '@/shared/PlacesSuggestions';
import formatAddressParts from '@/utils/locations/formatAddressParts';

const Suggestions = () => {
  const { watch, getValues, setValue } = useFormContext();
  const { dirtyFields } = useFormState();

  const line1 = watch(`location.line_1`);
  const line2 = watch(`location.line_2`);
  const city = watch(`location.city`);
  const state = watch(`location.state`);
  const postalId = watch(`location.postal_id`);

  const formattedAddress = formatAddressParts({ line_1: line1, line_2: line2, city, state, postal_id: postalId });

  const selectAddress = (addr) => {
    setValue(`location`, {
      ...addr,
      line_2: addr.line_2 ?? '',
      property_type: getValues(`location.property_type`),
      id: getValues(`location.id`),
    });
  };

  const isLocationDirty = Boolean(dirtyFields);

  return <PlacesSuggestions addressInput={isLocationDirty ? formattedAddress : ''} selectAddress={selectAddress} />;
};

export default Suggestions;
