import React from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { Form, useDataProvider, useNotify, useShowContext } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';

import { string } from 'prop-types';
import ProductForm from '../shared/ProductForm';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const EditProduct = ({ productId }) => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Product updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Product updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Product', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating product on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = ({ amount, type }) => {
    const operationsNeeded = [
      {
        op: 'replace',
        path: `/products/${productId}`,
        value: {
          product_id: type,
          estimated_quantity: 1,
          estimated_unit_price: Number(amount),
        },
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  const productRecord = _.find(quoteRecord?.products, (product) => product.id === productId);

  if (!quoteRecord || !productRecord) return null;

  return (
    <Form record={{ amount: productRecord?.estimated_unit_price, type: productRecord?.product_id }} onSubmit={onSubmit}>
      <ProductForm />
      <FormControls />
    </Form>
  );
};

EditProduct.propTypes = {
  productId: string.isRequired,
};

export default EditProduct;
