import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { bool } from 'prop-types';
import { Form, SelectArrayInput, TextInput, useCreate, useNotify, useRefresh, useShowContext } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_SERVICEABILITY_PARTNERS } from '@/constants/market';
import { MarketActionContext } from '@/providers/MarketAction';

const CreatePostalCodeForm = ({ isCreating }) => {
  const { setAction } = useContext(MarketActionContext);

  return (
    <>
      <Box px={2} pt={2} width={175}>
        <TextInput source="postal_id" label="Postal Code" fullWidth required />
      </Box>
      <Box px={2} pt={2}>
        <SelectArrayInput
          source="partner_serviceability"
          choices={Object.values(MARKET_SERVICEABILITY_PARTNERS)}
          fullWidth
        />
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isCreating ?? null}
          startIcon={isCreating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

CreatePostalCodeForm.propTypes = {
  isCreating: bool.isRequired,
};

const CreatePostalCode = () => {
  const { setAction } = useContext(MarketActionContext);
  const { record: { id: marketId } = {} } = useShowContext();
  const [create, { isLoading: isCreating }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - adding Postal Code failed - ${error.status} - ${message}`, {
      type: 'error',
    });
    mx.track(`Market Management - Error adding Postal Code`, {
      resource: resources.MARKETS,
      resourceId: marketId,
      error,
    });
  };

  const onSuccess = (data) => {
    mx.track(`Market Management - Postal Code added`, {
      resource: resources.MARKETS,
      resourceId: marketId,
    });

    notify(`Postal Code ${data.postal_id} added`, {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    create(
      resources.MARKETS,
      {
        data: {
          postal_id: data.postal_id,
          partner_serviceability: data.partner_serviceability ?? [],
        },
        meta: {
          resourceId: marketId,
          subResource: 'postal_codes',
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Postal Code
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <CreatePostalCodeForm isCreating={isCreating} />
        </Form>
      </Box>
    </Box>
  );
};

export default CreatePostalCode;
