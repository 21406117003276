export const QUALIFICATION_LEVEL_LABOR = [
  { id: 1, name: 'Standard' },
  { id: 3, name: 'Captain' },
];

export const QUALIFICATION_LEVEL_TRANSIT = [{ id: 3, name: 'Captain' }];

export const AVAILABILITY_ALL_OPTIONS = [
  { id: 'qualifications', name: 'All Qualifications' },
  { id: 'services', name: 'All Services' },
  { id: 'markets', name: 'All Markets' },
];

export const WORKER_SKILLS = [
  { id: 'HOURLYLABOR', name: 'HOURLY LABOR' },
  { id: 'LOADING', name: 'LOADING' },
  { id: 'LOADINGUNLOADING', name: 'LOADING / UNLOADING' },
  { id: 'PACKINGSERVICE', name: 'PACKING SERVICE' },
  { id: 'TRANSIT', name: 'TRANSIT' },
  { id: 'UNLOADING', name: 'UNLOADING' },
];

export const AVAILABILITY_TYPE = [
  { id: 'Buffer', name: 'Buffer' },
  { id: 'Blackout', name: 'Blackout' },
];
