import React from 'react';

import { ShowBase, useShowContext } from 'react-admin';

import { Box, Card } from '@mui/material';

import AdminConnectedAccountsDetails from './Details';
import ConnectedAccountSectionHeader from './SectionHeader';

const ConnectedAccount = () => {
  const { record } = useShowContext();

  if (!record) return null;
  return (
    <Box>
      <Card pt={1} component={Box} mt={3}>
        <ConnectedAccountSectionHeader title={`Connected Account Name: ${record?.name}`} />
        <Box
          p={3}
          sx={{
            backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.light : palette.neutral.darkest),
          }}
        >
          <AdminConnectedAccountsDetails />
        </Box>
      </Card>
    </Box>
  );
};

const AdminConnectedAccountShow = () => (
  <ShowBase>
    <ConnectedAccount />
  </ShowBase>
);

export default AdminConnectedAccountShow;
