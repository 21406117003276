import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import {
  BooleanInput,
  Form,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';

import { Box, Button, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { WARNING_ALERT_ORDER_TEXT } from '@/constants/order';
import { OrderActionContext } from '@/providers/OrderAction';
import OrderAlertField from '@/resources/orders/Show/OrderContent/Common/OrderAlertField';

const CompleteForm = () => {
  const { setAction } = useContext(OrderActionContext);

  const { permissions } = usePermissions();

  const canOverrideDuration = permissions?.tools?.hqadmin?.orders?.duration_override_complete;

  const record = useRecordContext();

  return (
    <>
      <Box p={2}>
        <Typography>All jobs must be completed before completing order.</Typography>
      </Box>
      {canOverrideDuration ? (
        <>
          <Divider />
          <Box px={2} pt={2}>
            <BooleanInput source="duration_override" />
          </Box>
        </>
      ) : null}
      <Divider />
      <OrderAlertField
        variant="filled"
        severity="warning"
        text={WARNING_ALERT_ORDER_TEXT.PAST_8_DAYS_RECONCILE}
        orderStartDate={record?.start_date_time}
      />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const CompleteOrder = () => {
  const { setAction } = useContext(OrderActionContext);
  const {
    record: { id: orderId, confirmation_id: confirmationId },
  } = useShowContext();
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    const { status } = error;
    let { message } = error;

    message = message.replaceAll('"', '');

    if (message.includes('job has too much quantity')) {
      message = `Job duration is too long for standard completion\n\nRequires manager duration override`;
    }

    notify(`Order Complete failed - ${status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track(`Order Management - Order Completed`, {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
    });

    notify('Order Completed', {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    const completeOrderData = {
      duration_override: data?.duration_override,
    };

    update(
      resources.ORDERS,
      {
        id: orderId,
        data: completeOrderData,
        meta: {
          method: 'POST',
          subResource: 'complete',
        },
      },
      { onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} maxWidth={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Complete Order
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <CompleteForm />
        </Form>
      </Box>
    </Box>
  );
};

export default CompleteOrder;
