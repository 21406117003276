import React from 'react';

import { BooleanField, DateField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const AdminConnectedAccountsDetails = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card>
      <CardHeader title="Details" />

      <Divider />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="id" emptyText="N/A" />
              <TextField source="name" emptyText="N/A" />
              <TextField source="_hc_lastop" emptyText="N/A" />
              <TextField source="_hc_err" emptyText="N/A" />
              <TextField source="sfid" emptyText="N/A" />
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="bt_stripe__account__r__id__c" label="Bt Stripe Account Code" emptyText="N/A" />
              <TextField source="bt_stripe__account__c" label="Bt Stripe Account SFID" emptyText="N/A" />
              <TextField source="bt_stripe__connected_account_id__c" label="Bt Stripe Connected Account" emptyText="N/A" />
              <TextField
                source="bt_stripe__connect_account_type__c"
                label="Bt Stripe Connected Account Type"
                emptyText="N/A"
              />
              <TextField
                source="bt_stripe__payment_gateway__r__id__c"
                label="Bt Stripe Payment Gateway Code"
                emptyText="N/A"
              />
              <TextField source="bt_stripe__payment_gateway__c" label="Bt Stripe Payment Gateway SFID" emptyText="N/A" />
              <TextField source="bt_stripe__country__c" label="Bt Stripe Country" emptyText="N/A" />
              <BooleanField source="bt_stripe__ssn_last_4_provided__c" label="Bt Stripe Last 4 Provided?" emptyText="N/A" />
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="lastmodifiedbyid" label="Last Modified by SFID" emptyText="N/A" />
              <TextField source="lastmodifiedby__id__c" label="Last Modified by ID Code" emptyText="N/A" />
              <BooleanField source="isdeleted" label="isDeleted?" emptyText="N/A" />
              <DateField source="systemmodstamp" label="System Mod Stamp" emptyText="N/A" />
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="createdbyid" label="Created by SFID" emptyText="N/A" />
              <TextField source="createdby__id__c" label="Created by ID Code" emptyText="N/A" />
              <DateField source="createddate" label="Created Date" emptyText="N/A" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AdminConnectedAccountsDetails;
