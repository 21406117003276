import React from 'react';

import { BooleanField, Datagrid, DateField, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

const StripeTransactionFilter = [
  <TextInput source="code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="order_code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="job_slot_code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="connected_account_code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="stripe_customer_code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="transaction_id" label="Transaction id" alwaysOn resettable autoComplete="off" />,
  <TextInput source="customer_id" label="customer id" alwaysOn resettable autoComplete="off" />,
];

const AdminStripeTransactionList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Stripe Transactions</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.ADMIN_STRIPE_TRANSACTIONS}
      filters={StripeTransactionFilter}
      exporter={false}
      empty={<EmptyResourceList text="No Stripe Transactions" />}
      hasCreate={false}
      storeKey={`${resources.ADMIN_STRIPE_TRANSACTIONS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" emptyText="N/A" />
        <TextField source="name" emptyText="N/A" />
        <TextField source="description" emptyText="N/A" />
        <CurrencyField source="amount" />
        <CurrencyField source="authorization_amount" emptyText="N/A" />
        <CurrencyField source="retained_revenue_amount" emptyText="N/A" />
        <DateField source="due_date" emptyText="N/A" />
        <DateField source="date_to_process" emptyText="N/A" />
        <TextField source="payment_status" emptyText="N/A" />
        <DateField source="processed_date" emptyText="N/A" />
        <TextField source="transaction_status" emptyText="N/A" />
        <TextField source="transaction_type" emptyText="N/A" />
        <TextField source="transaction_reason" emptyText="N/A" />
        <TextField source="family" emptyText="N/A" />
        <TextField source="transaction_id" emptyText="N/A" label="Transaction id" />
        <BooleanField source="authorize_only" emptyText="N/A" />
        <BooleanField source="auto_capture" emptyText="N/A" />
        <BooleanField source="non_stripe" emptyText="N/A" />
        <TextField source="connected_account_code" emptyText="N/A" />
        <TextField source="connected_account_id" emptyText="N/A" label="Connected account id" />
        <TextField source="customer_id" emptyText="N/A" label="Customer id" />
        <TextField source="stripe_customer_code" emptyText="N/A" />
        <TextField source="payment_gateway_code" emptyText="N/A" />
        <TextField source="payment_method_code" emptyText="N/A" />
        <TextField source="related_account_code" emptyText="N/A" />
        <TextField source="parent_transaction_id" emptyText="N/A" label="Parent transaction id" />
        <TextField source="parent_transaction_code" emptyText="N/A" />
        <TextField source="reattempted_transaction" emptyText="N/A" />
        <TextField source="reattempted_transaction_code" emptyText="N/A" />
        <TextField source="job_slot_code" emptyText="N/A" />
        <TextField source="order_code" emptyText="N/A" />
        <TextField source="currency_iso" emptyText="N/A" />
        <TextField source="error_code" emptyText="N/A" />
        <TextField source="error_message" emptyText="N/A" />
        <BooleanField source="isdeleted" emptyText="N/A" />
      </Datagrid>
    </List>
  </Card>
);

export default AdminStripeTransactionList;
