export const MAX_NUMBER_GROWTH = 500;

export const TIME_AM_PM = [
  { id: 'AM', name: 'AM' },
  { id: 'PM', name: 'PM' },
];

export const WORKER_TYPE = [
  { id: '1', name: 'Lead' },
  { id: '2', name: 'Standard' },
  { id: '3', name: 'Transit' },
];

export const WORKER_SKILLS = [
  { id: 'Labor', name: 'Labor' },
  { id: 'Transit', name: 'Transit' },
  { id: 'Packing', name: 'Packing' },
];

export const QUALIFICATION_LEVEL = [
  { id: '0', name: 'Recruit' },
  { id: '1', name: 'Standard' },
  { id: '2', name: 'Lead' },
  { id: '3', name: 'Captain' },
];
