const { useState, useMemo } = require('react');

const useScriptModals = () => {
  const [modalStates, setModalStates] = useState({});
  const [modalCloseFunc, setModalCloseFunc] = useState({});

  const openModal = (key) => {
    if (process.env.REACT_APP_SKIP_SCRIPT_PROMPTS) return;
    const newState = {
      ...modalStates,
      [key]: true,
    };
    setModalStates(newState);
  };

  const closeModal = (key) => {
    if (modalCloseFunc?.[key]) {
      modalCloseFunc[key]();
    }
    setModalStates({
      ...modalStates,
      [key]: false,
    });
  };

  const updateModalCloseFunc = (key, func) => {
    setModalCloseFunc({
      ...modalCloseFunc,
      [key]: func,
    });
  };

  const data = useMemo(
    () => ({
      modalStates,
      openModal,
      closeModal,
      updateModalCloseFunc,
    }),
    [modalStates, modalCloseFunc],
  );

  return { ...data };
};

export default useScriptModals;
