import React, { useContext } from 'react';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Link, useCreatePath, usePermissions, useRecordContext, useStore } from 'react-admin';

import { ExitToApp } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JOB_COMPLETABLE_STATUSES } from '@/constants/jobStatus';
import { PREFERENCES_KEYS } from '@/constants/preferences';
import { OrderActionContext } from '@/providers/OrderAction';
import prefDayJs from '@/utils/datetime/prefDayJs';
import getServiceName from '@/utils/serviceName/getServiceName';
import JobMenu from './JobMenu';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const JobHeader = () => {
  const [timePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const record = useRecordContext();
  const createPath = useCreatePath();
  const { permissions } = usePermissions();
  const { setAction, setMetadata } = useContext(OrderActionContext);

  if (!record) return null;

  const startDate = prefDayJs({
    date: record.start_date_time,
    timezone: record.start_timezone,
    timePreference,
  });
  const endDate = prefDayJs({
    date: record.end_date_time,
    timezone: record.end_timezone,
    timePreference,
  });

  const jobTime = `${startDate.format('ha')}-${endDate.format('ha')}`;
  const jobDate = startDate.format('dddd, MMM DD, YYYY');

  const canCompleteJob = permissions?.tools?.hqadmin?.jobs?.complete; // Reuses complete order perm as they are similar functions
  const isJobCompletable =
    process.env.REACT_APP_ALLOW_ALL_JOB_COMPLETE === 'true' ||
    (JOB_COMPLETABLE_STATUSES.includes(record?.status) && dayjs(record.start_date_time) < dayjs());

  return (
    <Box px={3} py={2}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={2.5}>
          <Link
            to={createPath({
              resource: resources.JOBS,
              type: 'show',
              id: record.id,
            })}
          >
            <Box display="inline-flex" alignItems="center">
              <Typography variant="h6" mr={1}>
                {getServiceName(record.product_id)}
              </Typography>
              <ExitToApp fontSize="small" />
            </Box>
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{`Workers: ${record.workers}`}</Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center" alignItems="center" gap={2}>
          <Typography variant="h6">{`Status: ${record.status}`}</Typography>
          {canCompleteJob && isJobCompletable ? (
            <Button
              variant="outlined"
              onClick={() => {
                setMetadata({ job_id: record?.id });
                setAction('complete_job');
              }}
            >
              Complete job
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Typography variant="h6" component={Box}>
            {`${jobDate}, ${jobTime}`}
          </Typography>
        </Grid>
        <Grid item xs={0.5} display="flex" justifyContent="flex-end">
          <JobMenu />
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobHeader;
