import React from 'react';

import { DateInput, Form, SaveButton, SelectInput, ShowButton, TextInput, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { PRO_ACCOUNT_TSHIRT_SIZE } from '@/constants/prosAccount';

const ProsDetailsContactInfoEdit = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Contact Info" />

      <Divider />

      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextInput source="phone" label="Phone number" required fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="email" label="Email address" required fullWidth />
            </Grid>
            <Grid item xs={3}>
              <DateInput source="birthday" label="Birthday" required fullWidth />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="shirt_size"
                label="T-shirt size"
                choices={PRO_ACCOUNT_TSHIRT_SIZE}
                required
                fullWidth
                defaultValue={record.tshirt_size}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Update" icon={null} />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default ProsDetailsContactInfoEdit;
