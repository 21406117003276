import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import _ from 'lodash';

import { QUOTE_CONTRACT_PRICE_PARTNERS, QUOTE_PRODUCT_BRE_PRICE_PARTNERS } from '@/constants/quotePartners';
import { QUOTE_PRODUCT_MAP } from '@/constants/quoteProducts';

dayjs.extend(tz);

const productsTransform = (data) => {
  const retData = _.cloneDeep(data);

  if (
    QUOTE_CONTRACT_PRICE_PARTNERS.includes(retData?.partner_id) ||
    QUOTE_PRODUCT_BRE_PRICE_PARTNERS.includes(retData?.partner_id)
  ) {
    // Include contract price product, will auto populate price from BRE for some partners
    // For non BRE priced product partners, price will remain zero for operator to manually update
    retData.products = [
      {
        product_id: 'CONTRACT_PRICE',
        estimated_quantity: 1,
        estimated_unit_price: 0,
      },
    ];
  }

  if (QUOTE_PRODUCT_BRE_PRICE_PARTNERS.includes(retData?.partner_id)) {
    // Always include excess mileage on BRE price partners - dependent on value if > 0 cost
    retData.products.push(
      ...[
        {
          product_id: 'EXCESSMILEAGE',
          estimated_quantity: 1,
          estimated_unit_price: 0,
        },
      ],
    );

    // Duplicate stairs and elevator (forced int instead of boolean) value in extra_data for BRE pricing
    if (!retData?.inventory?.extra_data) {
      retData.inventory.extra_data = {};
    }
    retData.inventory.extra_data.stairs = Number(retData?.inventory?.access_flags?.stairs);
    retData.inventory.extra_data.elevator = Number(retData?.inventory?.access_flags?.elevator);

    const neededDynamicProducts =
      Object.keys(QUOTE_PRODUCT_MAP[retData?.partner_id])
        ?.map((dynamicProduct) => {
          if (_.get(retData, dynamicProduct)) {
            return {
              product_id: QUOTE_PRODUCT_MAP[retData?.partner_id]?.[dynamicProduct],
              estimated_quantity: 1,
              estimated_unit_price: 0,
            };
          }
          return null;
        })
        .filter((item) => !!item) ?? [];

    if (neededDynamicProducts.length) {
      retData.products.push(...neededDynamicProducts);
    }
  }

  return retData;
};

export default productsTransform;
