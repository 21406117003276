import React from 'react';

import { EditBase, Form, NumberInput, SaveButton, ShowButton } from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_TAB } from '@/constants/market';
import MarketHeader from '@/resources/markets/Show/MarketHeader';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between">
    <ShowButton label="Cancel" icon={null} />
    <SaveButton label="Update" icon={null} />
  </Box>
);

const DetailsForm = () => (
  <Form>
    <CardContent>
      <Grid container spacing={2}>
        {/* Late Rate Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Late Rate</Typography>
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="late_rate_excellent_threshold" label="Excellent Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="late_rate_good_threshold" label="Good Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="late_rate_needs_review_threshold" label="Needs Review Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="late_rate_poor_threshold" label="Poor Threshold" fullWidth />
        </Grid>
        <Divider />

        {/* Removal Rate Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Removal Rate</Typography>
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="removal_rate_excellent_threshold" label="Excellent Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="removal_rate_good_threshold" label="Good Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="removal_rate_needs_review_threshold" label="Needs Review Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="removal_rate_poor_threshold" label="Poor Threshold" fullWidth />
        </Grid>
        <Divider />

        {/* Damage Rate Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Damage Rate</Typography>
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="damage_rate_excellent_threshold" label="Excellent Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="damage_rate_good_threshold" label="Good Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="damage_rate_needs_review_threshold" label="Needs Review Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="damage_rate_poor_threshold" label="Poor Threshold" fullWidth />
        </Grid>
        <Divider />

        {/* No Pad Wrap Rate Section */}
        <Grid item xs={12}>
          <Typography variant="h6">No Pad Wrap Rate</Typography>
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="no_pad_wrap_excellent_threshold" label="Excellent Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="no_pad_wrap_good_threshold" label="Good Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="no_pad_wrap_needs_review_threshold" label="Needs Review Threshold" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="no_pad_wrap_poor_threshold" label="Poor Threshold" fullWidth />
        </Grid>
      </Grid>
    </CardContent>
    <Divider />
    <CardContent>
      <FormControls />
    </CardContent>
  </Form>
);

const MarketPerformanceThresholdsThresholdsEdit = () => {
  const { marketId } = useParams();

  return (
    <EditBase
      resource={resources.MARKETS}
      id={marketId}
      mutationMode="pessimistic"
      redirect={`/${resources.MARKETS}/${marketId}/show/${MARKET_TAB.PERFORMANCE_THRESHOLDS}`}
      actions={false}
    >
      <Box mt={3}>
        <MarketHeader />
      </Box>
      <Card mt={3} component={Box}>
        <CardHeader title="Thresholds" />
        <Divider />
        <DetailsForm />
      </Card>
    </EditBase>
  );
};

export default MarketPerformanceThresholdsThresholdsEdit;
