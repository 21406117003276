import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { number, string } from 'prop-types';
import { BooleanInput, Form, NumberInput, TextInput, useDataProvider, useNotify, useShowContext } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import * as resources from '@/api/resources';
import HiddenInput from '@/components/inputs/HiddenInput';
import { INVENTORY_ADDITIONAL_ITEMS, INVENTORY_ROOMS } from '@/constants/quotes';
import { DEFAULT_INVENTORY_NOTES, INVENTORY_AREA_CHOICES, SPECIAL_INVENTORY_TYPES } from '@/constants/inventory';
import { QUOTE_PARTNER_INVENTORY_AREA_CHOICES, QUOTE_PRODUCT_BRE_PRICE_PARTNERS } from '@/constants/quotePartners';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { QuoteContext } from '@/providers/Quote';
import OfferpadInventoryInput from '@/resources/quotes/shared/OfferpadInventoryInput';
import ZippyInventoryInput from '@/resources/quotes/shared/ZippyInventoryInput';
import Incrementer from '@/shared/Incrementer';
import objectDiff from '@/utils/objectDiff';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const RoomIncrementer = ({ roomSlug, index }) => {
  const { record: quoteRecord } = useShowContext();
  const { setValue } = useFormContext();

  const roomRecord = _.find(quoteRecord?.inventory?.rooms, (room) => room.slug === roomSlug);

  const [roomCount, setRoomCount] = useState(roomRecord?.count ?? 0);

  const updateRoomCount = (newValue) => {
    setRoomCount(newValue);
    setValue(`inventory.rooms.${index}.count`, newValue, { shouldDirty: true });
  };

  return (
    <Box display="flex" alignItems="center">
      <HiddenInput source={`inventory.rooms.${index}.count`} />
      <Incrementer value={roomCount} setValue={updateRoomCount} minLimit={0} maxLimit={10} />
      <TextFieldWrapper value={INVENTORY_ROOMS[roomSlug]?.name} />
    </Box>
  );
};

RoomIncrementer.propTypes = {
  roomSlug: string.isRequired,
  index: number.isRequired,
};

const RoomsInput = () => {
  const { record: quoteRecord } = useShowContext();
  const { forceInventoryUpdate } = useContext(QuoteContext);

  if (!quoteRecord) return null;

  return (
    <Grid container spacing={2}>
      {!forceInventoryUpdate
        ? quoteRecord.inventory.rooms.map((roomObj, index) => (
            <Grid item xs={12 / 2} lg={12 / 3} key={roomObj.slug}>
              <RoomIncrementer roomSlug={roomObj.slug} index={index} />
            </Grid>
          ))
        : Object.keys(INVENTORY_ROOMS).map((roomKey, index) => (
            <Grid item xs={12 / 2} lg={12 / 3} key={roomKey}>
              <RoomIncrementer roomSlug={roomKey} index={index} />
            </Grid>
          ))}
    </Grid>
  );
};

const ItemIncrementer = ({ itemSlug, index }) => {
  const { record: quoteRecord } = useShowContext();
  const { setValue } = useFormContext();

  const roomRecord = _.find(quoteRecord?.inventory?.additional_items, (item) => item.slug === itemSlug);

  const [roomCount, setRoomCount] = useState(roomRecord?.count ?? 0);

  const updateRoomCount = (newValue) => {
    setRoomCount(newValue);
    setValue(`inventory.additional_items.${index}.count`, newValue, { shouldDirty: true });
  };

  return (
    <Box display="flex" alignItems="center">
      <HiddenInput source={`inventory.additional_items.${index}.count`} />
      <Incrementer value={roomCount} setValue={updateRoomCount} minLimit={0} maxLimit={10} />
      <TextFieldWrapper value={INVENTORY_ADDITIONAL_ITEMS[itemSlug]?.name} />
    </Box>
  );
};

ItemIncrementer.propTypes = {
  itemSlug: string.isRequired,
  index: number.isRequired,
};

const AdditionalItemsInput = () => {
  const { record: quoteRecord } = useShowContext();
  const { forceInventoryUpdate } = useContext(QuoteContext);

  if (!quoteRecord) return null;

  return (
    <Grid container spacing={2}>
      {!forceInventoryUpdate
        ? quoteRecord.inventory.additional_items.map((itemObj, index) => (
            <Grid item xs={12 / 2} lg={12 / 3} key={itemObj.slug}>
              <ItemIncrementer itemSlug={itemObj.slug} index={index} />
            </Grid>
          ))
        : Object.keys(INVENTORY_ADDITIONAL_ITEMS).map((itemKey, index) => (
            <Grid item xs={12 / 2} lg={12 / 3} key={itemKey}>
              <ItemIncrementer itemSlug={itemKey} index={index} />
            </Grid>
          ))}
    </Grid>
  );
};

const AreaInput = () => {
  const { record: quoteRecord } = useShowContext();
  const { setValue } = useFormContext();

  const [inventoryArea, setInventoryArea] = useState('');
  const [areaChoices, setAreaChoices] = useState([]);

  const handleChange = (event) => {
    setInventoryArea(event.target.value);
    setValue('inventory.area', event.target.value, { shouldDirty: true, shouldTouch: true });
  };

  useEffect(() => {
    if (quoteRecord) {
      let bestFitArea;
      let newAreaChoices;

      if (QUOTE_PARTNER_INVENTORY_AREA_CHOICES[quoteRecord?.partner_id]) {
        newAreaChoices = QUOTE_PARTNER_INVENTORY_AREA_CHOICES[quoteRecord?.partner_id];
      } else {
        newAreaChoices = INVENTORY_AREA_CHOICES;
      }

      Object.values(newAreaChoices).every((areaChoice, index, { length }) => {
        if (quoteRecord.inventory.area <= areaChoice.id || index + 1 === length) {
          bestFitArea = areaChoice.id;
          return false;
        }
        return true;
      });

      setInventoryArea(bestFitArea);
      setValue('inventory.area', bestFitArea, { shouldDirty: true, shouldTouch: true });
      setAreaChoices(newAreaChoices);
    }
  }, [quoteRecord]);

  if (!quoteRecord) return null;

  return (
    <>
      <HiddenInput source="inventory.area" />
      <Box width={150} mb={3}>
        <FormControl variant="filled" fullWidth required>
          <InputLabel id="inventory-area-label">Area</InputLabel>
          <Select
            id="inventory-area"
            name="area"
            labelId="inventory-area-label"
            value={inventoryArea}
            onChange={handleChange}
            label="Area"
          >
            {areaChoices.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const InventoryForm = () => {
  const { record: quoteRecord } = useShowContext();

  const { forceInventoryUpdate } = useContext(QuoteContext);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <Card sx={{ height: '100%' }}>
          <CardHeader title="Notes" />
          <Divider />
          <CardContent>
            <Box height="100%">
              <TextInput
                source="inventory.notes"
                label=""
                variant="outlined"
                multiline
                fullWidth
                minRows={6}
                maxRows={35}
                defaultValue={DEFAULT_INVENTORY_NOTES}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Quote Inventory" />
          <Divider />
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} lg={12 / 5}>
                <AreaInput />
              </Grid>
              <Grid item xs={6} lg={12 / 5}>
                <NumberInput source="inventory.access_flags.stairs" label="Stairs" />
              </Grid>
              <Grid item xs={4} lg={12 / 5}>
                <BooleanInput source="inventory.access_flags.elevator" label="Elevator" />
              </Grid>
              <Grid item xs={4} lg={12 / 5}>
                <BooleanInput source="inventory.access_flags.elevator_reserved" label="Elevator Reserved" />
              </Grid>
              <Grid item xs={4} lg={12 / 5}>
                <BooleanInput source="inventory.access_flags.long_walk_to_truck" label="Long Walk to Truck" />
              </Grid>
            </Grid>
          </CardContent>
          {forceInventoryUpdate || quoteRecord?.inventory?.is_room_based_inventory ? (
            <>
              <Divider />
              <CardContent>
                <RoomsInput />
              </CardContent>
            </>
          ) : null}
          {quoteRecord?.inventory_type === 'ZIPPY' ? (
            <CardContent>
              <ZippyInventoryInput />
            </CardContent>
          ) : null}
          {quoteRecord?.inventory_type === 'OFFERPAD' ? (
            <CardContent>
              <OfferpadInventoryInput />
            </CardContent>
          ) : null}
        </Card>
        {forceInventoryUpdate || quoteRecord?.inventory?.is_room_based_inventory ? (
          <Card component={Box} mt={3}>
            <CardHeader title="Additional Items" />
            <Divider />
            <CardContent>
              <AdditionalItemsInput />
            </CardContent>
          </Card>
        ) : null}
      </Grid>
    </Grid>
  );
};

const ModifyInventory = () => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { forceInventoryUpdate } = useContext(QuoteContext);

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Inventory updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Locations updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Inventory', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating inventory on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = (data) => {
    const operationsNeeded = [];

    const diff = objectDiff(data.inventory, quoteRecord.inventory);

    const inventoryChanged = !_.isEmpty(diff);

    if (inventoryChanged) {
      const newInventory = data.inventory;

      if (!SPECIAL_INVENTORY_TYPES.includes(quoteRecord?.inventory_type)) {
        // Cleanup unused data for submission
        delete newInventory.is_room_based_inventory;
      }
      if (!newInventory.intent) {
        newInventory.intent = 'EVERYTHING'; // TODO: Temp until platform removes
      }

      if (!newInventory.additional_items) {
        newInventory.additional_items = [];
      }

      newInventory.rooms = newInventory.rooms?.map(({ slug, count }) => ({
        slug,
        count,
      }));

      operationsNeeded.push({
        op: 'replace',
        path: '/inventory',
        value: newInventory,
      });

      if (QUOTE_PRODUCT_BRE_PRICE_PARTNERS.includes(quoteRecord?.partner_id)) {
        operationsNeeded.push({
          op: 'replace',
          path: '/refresh_prices',
          value: {},
        });
      }
    }

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  const defaultInventoryRooms =
    forceInventoryUpdate || quoteRecord?.inventory?.is_room_based_inventory
      ? Object.keys(INVENTORY_ROOMS).map((room) => ({
          slug: room,
          count: 0,
        }))
      : [];

  const defaultAdditionalItems = Object.keys(INVENTORY_ADDITIONAL_ITEMS).map((item) => ({
    slug: item,
    count: 0,
  }));

  if (!quoteRecord) return null;

  return (
    <Form
      defaultValues={{ inventory: { rooms: defaultInventoryRooms, additional_items: defaultAdditionalItems } }}
      record={quoteRecord}
      onSubmit={onSubmit}
    >
      <InventoryForm />
      <FormControls />
    </Form>
  );
};

export default ModifyInventory;
