import getAssignedSlotCount from './getAssignedSlotCount';
import getTotalSlotCount from './getTotalSlotCount';

const jobSlotsMinimum = 2;

const isLaborFunctional = (jobs) => {
  let functional = true;

  // Check each job as functional, if one job is non functional returns false
  jobs.forEach((job) => {
    const assignedSlots = getAssignedSlotCount([job]);
    const totalSlots = getTotalSlotCount([job]);

    // If all slots for job are assigned, job is functional, skip next check, fixes edge case where total slots is less than minimum
    if (assignedSlots === totalSlots) {
      return;
    }
    // If less than 2 assigned slots or more than 2 unassigned slots, this job is non functional
    if (assignedSlots < jobSlotsMinimum || assignedSlots < totalSlots - 1) {
      functional = false;
    }
  });

  return functional;
};

export default isLaborFunctional;
