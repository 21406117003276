const SERVICE_NAMES = {
  FSLOADINGUNLOADING: 'Full Service Labor',
  FULLSERVICE: 'Full Service',
  HOURLYLABOR: 'Hourly Labor',
  LDLOADING: 'LD Loading',
  LDTRANSIT: 'LD Truck / Driver',
  LDUNLOADING: 'LD Unloading',
  LOADING: 'Loading',
  LOADINGUNLOADING: 'Loading / Unloading',
  PACKINGSERVICE: 'Packing',
  RECRUITORIENTATION: 'Recruit Orientation',
  TRANSIT: 'Truck / Driver',
  UNLOADING: 'Unloading',
};

const SERVICES = {
  HOURLYLABOR: {
    id: 'HOURLYLABOR',
    name: 'Hourly Labor',
  },
  LOADING: {
    id: 'LOADING',
    name: 'Loading',
  },
  LOADINGUNLOADING: {
    id: 'LOADINGUNLOADING',
    name: 'Loading / Unloading',
  },
  PACKINGSERVICE: {
    id: 'PACKINGSERVICE',
    name: 'Packing',
  },
  TRANSIT: {
    id: 'TRANSIT',
    name: 'Truck / Driver',
  },
  UNLOADING: {
    id: 'UNLOADING',
    name: 'Unloading',
  },
};

export { SERVICE_NAMES, SERVICES };
