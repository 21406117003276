import React, { useContext } from 'react';

import { Box, Button, Drawer, Typography } from '@mui/material';

import { MarketActionContext } from '@/providers/MarketAction';
import { MARKET_ACTION } from '@/constants/market';

import CreatePostalCode from './CreatePostalCode';
import UpdatePostalCode from './UpdatePostalCode';

import CreateMarketProduct from './CreateMarketProduct';
import DeleteMarketProduct from './DeleteMarketProduct';
import UpdateMarketProduct from './UpdateMarketProduct';

const UnknownAction = () => {
  const { action, setAction, metadata, setMetadata } = useContext(MarketActionContext);
  return (
    <Box px={6} py={4} minWidth={400} maxWidth={550}>
      <Typography variant="h5" component={Box} pb={4}>{`Unknown Action - ${action}`}</Typography>
      {metadata ? <pre>{JSON.stringify(metadata, null, 2)}</pre> : null}
      <Button
        variant="contained"
        color="neutral"
        onClick={() => {
          setAction(null);
          setMetadata(null);
        }}
      >
        Close
      </Button>
    </Box>
  );
};

const MarketActionDrawer = () => {
  const { action, setAction } = useContext(MarketActionContext);

  if (!action) return null;

  let drawerContent;
  switch (action) {
    case MARKET_ACTION.CREATE_POSTAL_CODE:
      drawerContent = <CreatePostalCode />;
      break;
    case MARKET_ACTION.UPDATE_POSTAL_CODE:
      drawerContent = <UpdatePostalCode />;
      break;
    case MARKET_ACTION.CREATE_MARKET_PRODUCT:
      drawerContent = <CreateMarketProduct />;
      break;
    case MARKET_ACTION.DELETE_MARKET_PRODUCT:
      drawerContent = <DeleteMarketProduct />;
      break;
    case MARKET_ACTION.UPDATE_MARKET_PRODUCT:
      drawerContent = <UpdateMarketProduct />;
      break;
    default:
      drawerContent = <UnknownAction />;
  }

  return (
    <Drawer anchor="right" open={Boolean(action)} onClose={() => setAction(null)}>
      {drawerContent}
    </Drawer>
  );
};

export default MarketActionDrawer;
