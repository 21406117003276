import React from 'react';

import { Form, SaveButton, SelectInput, ShowButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { PAYOUT_CONFIG } from '@/constants/account';

const CarrierDetailsPaymentEdit = () => (
  <Card mt={3} component={Box}>
    <CardHeader title="Payment" />

    <Divider />

    <Form>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextInput source="stripe_id" label="Stripe ID" fullWidth disabled />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              source="account_payout_config"
              label="Payout config"
              choices={Object.values(PAYOUT_CONFIG)}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <ShowButton label="Cancel" icon={null} color="secondary" />
          <SaveButton label="Update" icon={null} />
        </Box>
      </CardContent>
    </Form>
  </Card>
);

export default CarrierDetailsPaymentEdit;
