import React from 'react';

import {
  BooleanField,
  Datagrid,
  TextField,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';

import { Box, Button, Card, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { CUSTOMER_ACCOUNT_TAB, CUSTOMER_CHARGES_SECTION } from '@/constants/customerAccount';
import ConcatenatingStringField from '@/fields/ConcatenatingStringField';
import ExpirationDateField from '@/fields/ExpirationDateField';
import ListBase from '@/ReactAdmin/List/ListBase';
import EmptyResourceList from '@/shared/EmptyResourceList';

import CustomerChargesSectionHeader from '../Common/SectionHeader';

const SetAsDefaultAction = () => {
  const record = useRecordContext();
  const { record: customerRecord } = useShowContext();
  const refresh = useRefresh();
  const [update] = useUpdate(
    resources.CUSTOMERS,
    {
      id: customerRecord.id,
      data: { provider: 'STRIPE_CARD', token: record.stripe_card_id },
      meta: { subResource: 'payment_method' },
    },
    {
      onSuccess: () => refresh(),
    },
  );

  const setDefault = () => {
    update();
  };

  if (!record || record.is_default) return null;

  return (
    <Button variant="outlined" onClick={setDefault}>
      Set as Default
    </Button>
  );
};

const PaymentMethodGrid = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (!record) return null;

  return (
    <Card component={Box} mb={2}>
      <CustomerChargesSectionHeader
        title="Payment Methods"
        tab={CUSTOMER_ACCOUNT_TAB.CHARGES}
        section={CUSTOMER_CHARGES_SECTION.PAYMENT_METHOD}
      />
      <Divider />
      <Box>
        <ListBase
          disableSyncWithLocation
          resource={resources.CUSTOMERS}
          queryOptions={{ meta: { subResource: 'payment_methods', resourceId: record.id } }}
          exporter={false}
          empty={<EmptyResourceList text="No Payment methods on Customer account" />}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="holder_name" label="Name on card" sortable={false} />
            <ConcatenatingStringField start="****" source="last_4" label="Card number" sortable={false} />
            <ExpirationDateField
              months="expiration_month"
              years="expiration_year"
              label="Expiration date"
              sortable={false}
            />
            <BooleanField source="is_default" sortable={false} />
            {permissions.tools?.hqadmin?.customer_payment_method?.update ? <SetAsDefaultAction /> : null}
          </Datagrid>
        </ListBase>
      </Box>
    </Card>
  );
};

const CustomerContentPaymentMethod = () => <PaymentMethodGrid />;

export default CustomerContentPaymentMethod;
