import React from 'react';

import {
  Form,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
  Toolbar,
  required,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import {
  THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS,
  THIRD_PARTY_CREW_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS,
} from '@/constants/thirdPartyCrewAccount';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Deactivate" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewDeactivateAccount = () => {
  const record = useRecordContext();

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error deactivating account - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      notify('deactivated account successfully!', {
        type: 'success',
      });
      refresh();
    }
  };

  const onSubmit = ({ reason, details }) => {
    const data = {
      reason,
      details,
    };

    create(
      resources.THIRD_PARTY_CREW,
      {
        meta: {
          subResource: 'DETAILS/OVERVIEW/DEACTIVATEACCOUNT',
          resourceId: record.id,
        },
        data,
      },
      { onSettled },
    );
  };

  if (!record) return null;

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview - Deactivate Third Party Crew" />

      <Divider />

      <CardContent>
        <Form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectInput
                source="new_status"
                choices={THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS}
                validate={[required()]}
                fullWidth
                label="Status"
                defaultValue="Inactive"
              />
            </Grid>

            <Grid item xs={3}>
              <SelectInput
                source="reason"
                choices={THIRD_PARTY_CREW_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS}
                validate={[required()]}
                fullWidth
                label="Status Reason"
              />
            </Grid>

            <Grid item xs={3}>
              <TextInput source="details" validate={[required()]} fullWidth label="Status Context" />
            </Grid>
          </Grid>

          <Divider />
          <CustomToolbar />
        </Form>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDeactivateAccount;
