import onlyUnique from '@/utils/onlyUnique';
import toPascalCase from '@/utils/toPascalCase';

const getUniqueMarkets = (locations) => {
  const uniqueLocations = locations
    ?.map(({ location, location_id: id } = {}) => ({
      location: {
        ...location,
        line_1: toPascalCase(location.line_1),
        line_2: location.line_2 ? toPascalCase(location.line_2) : '',
        city: toPascalCase(location.city),
      },
      location_id: id,
    }))
    .filter(onlyUnique);

  const uniqueMarkets =
    uniqueLocations?.reduce((markets, { location }) => [...markets, location.market_id], [])?.filter(onlyUnique) ?? [];

  uniqueMarkets.sort();

  return uniqueMarkets ?? [];
};

export default getUniqueMarkets;
