import React from 'react';

import { useRecordContext } from 'react-admin';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import CreditCardIcon from './CreditCardIcon';

const PaymentMethodCard = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Box display="flex">
      <Box pr={1}>
        <Box pt={1} px={0.75} width={46} textAlign="center">
          <CreditCardIcon brand={record?.brand} />
        </Box>
        <Tooltip title="Open Customer in Stripe" placement="top">
          <IconButton href={`${process.env.REACT_APP_STRIPE_URI}/customers/${record?.stripe_customer_id}`} target="_blank">
            <Box width={30} height={30} borderRadius="50%" overflow="hidden">
              <img src="/svgs/stripe/stripe-icon-square.svg" alt="Stripe Logo" width="100%" />
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Typography>{record?.holder_name}</Typography>
        <Typography>{`****${record?.last_4}`}</Typography>
        <Typography>{`Expires ${record?.expiration_month}/${record?.expiration_year}`}</Typography>
      </Box>
    </Box>
  );
};

export default PaymentMethodCard;
