import React from 'react';

import { Labeled, LoadingIndicator, NumberField, usePermissions, useShowContext } from 'react-admin';
import { useNavigate } from 'react-router';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_TAB, MARKET_TRAVEL_PAY_SECTION } from '@/constants/market';
import CurrencyField from '@/fields/CurrencyField';

import MarketSectionHeader from '../Common/SectionHeader';

const MarketTravelPay = () => {
  const { record, isLoading, isFetching } = useShowContext();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  if (!record) return null;

  const contextMenuItems = [
    ...(permissions.tools?.hqadmin?.markets?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(
                `/${resources.MARKETS}/${record?.id}/edit/${MARKET_TAB.TRAVEL_PAY}/${MARKET_TRAVEL_PAY_SECTION.OVERVIEW}`,
              );
            },
            component: 'Edit travel pay',
          },
        ]
      : []),
  ];

  return (
    <Box width="100%">
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <Card>
          <MarketSectionHeader
            title="Overview"
            tab={MARKET_TAB.TRAVEL_PAY}
            section={MARKET_TRAVEL_PAY_SECTION.OVERVIEW}
            contextMenuItems={contextMenuItems}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2} px={2}>
              <Grid item xs={4}>
                <Labeled>
                  <NumberField source="mileage_minimum" emptyText="N/A" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <NumberField source="tier_1_mileage_maximum" emptyText="N/A" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <NumberField source="mileage_maximum" emptyText="N/A" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <CurrencyField source="tier_1_mileage_reimbursement" isFraction textAlign="left" emptyText="N/A" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <CurrencyField source="tier_2_mileage_reimbursement" isFraction textAlign="left" emptyText="N/A" />
                </Labeled>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default MarketTravelPay;
