import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';
import PricingLayersCreate from './Create';
import DateLayerCreateBatch from './CreateBatch/DateLayerCreateBatch';
import ServiceLayerCreateBatch from './CreateBatch/ServiceLayerCreateBatch';
import PricingLayersDashboard from './Dashboard';
import PricingLayersDateLayerSummary from './DateLayerSummary';
import PricingLayersLaneLayerSummary from './LaneLayerSummary';
import PricingLayersList from './List';
import PricingLayersServiceLayerSummary from './ServiceLayerSummary';
import LaneLayerCreateBatch from './CreateBatch/LaneLayerCreateBatch';

const pricingLayers = () => ({
  name: resources.PRICING_LAYERS,
  list: PricingLayersList,
  create: PricingLayersCreate,
  children: [
    <Route key="pricing-layers-dashboard" path="/dashboard" element={<PricingLayersDashboard />} />,
    <Route
      key="pricing-layers-service-layer-summary"
      path="/service_layer_summary"
      element={<PricingLayersServiceLayerSummary />}
    />,
    <Route key="pricing-layers-lane-layer-summary" path="/lane_layer_summary" element={<PricingLayersLaneLayerSummary />} />,
    <Route key="pricing-layers-date-layer-summary" path="/date_layer_summary" element={<PricingLayersDateLayerSummary />} />,
    <Route
      key="pricing-layers-service-layer-create-batch"
      path="/service_layer_create_batch"
      element={<ServiceLayerCreateBatch />}
    />,
    <Route
      key="pricing-layers-date-layer-create-batch"
      path="/date_layer_create_batch"
      element={<DateLayerCreateBatch />}
    />,
    <Route
      key="pricing-layers-lane-layer-create-batch"
      path="/lane_layer_create_batch"
      element={<LaneLayerCreateBatch />}
    />,
  ],
});

export default pricingLayers;
