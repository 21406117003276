import prefDayJs from '../datetime/prefDayJs';
import getHourlyAvailability from './getHourlyAvailability';

const transformAvailabilities = (data, timePreference, manualTimezone = null) =>
  data?.map(({ start, end, timezone }) => {
    const startDate = prefDayJs({
      date: start,
      timezone: manualTimezone ?? timezone,
      timePreference,
    });
    const endDate = prefDayJs({
      date: end,
      timezone: manualTimezone ?? timezone,
      timePreference,
    });
    const dateTime = startDate.format();
    const hourly = getHourlyAvailability(startDate, endDate);

    return { dateTime, hourly };
  });

export default transformAvailabilities;
