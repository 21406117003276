import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const useLazyEffect = (effect, deps = [], wait = 300) => {
  const cleanUp = useRef();
  const effectRef = useRef();
  effectRef.current = useCallback(effect, deps);
  const lazyEffect = useCallback(
    debounce(() => {
      if (cleanUp.current instanceof Function) {
        cleanUp.current();
      }
      cleanUp.current = effectRef.current?.();
    }, wait),
    [],
  );
  useEffect(lazyEffect, deps);
  useEffect(() => () => cleanUp.current instanceof Function ? cleanUp.current() : undefined, []);
};

export default useLazyEffect;
