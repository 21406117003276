import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import {
  Form,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';

import * as resources from '@/api/resources';
import {
  PRO_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS,
  PRO_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS,
} from '@/constants/prosAccount';

const ProsDeactivateAccount = () => {
  const record = useRecordContext();

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onError = ({ message, status }) => {
    notify(`Error deactivating account - ${status} - ${message}`, { type: 'error' });
  };

  const onSuccess = () => {
    notify('Account deactivated successfully', {
      type: 'success',
    });
    refresh();
    redirect(`/${resources.ACCOUNTS}/${record.id}/show`);
  };

  const onSubmit = ({ reason, details }) => {
    const data = {
      reason,
      details,
    };

    create(
      resources.ACCOUNTS,
      {
        meta: {
          subResource: 'DETAILS/OVERVIEW/DEACTIVATEACCOUNT',
          resourceId: record.id,
        },
        data,
      },
      { onError, onSuccess },
    );
  };

  if (!record) return null;

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview - Deactivate Pro" />

      <Divider />

      <Form onSubmit={onSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                source="new_status"
                choices={PRO_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS}
                required
                fullWidth
                label="Status"
                defaultValue="Inactive"
              />
            </Grid>

            <Grid item xs={4}>
              <SelectInput
                source="reason"
                choices={PRO_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS}
                required
                fullWidth
                label="Status Reason"
              />
            </Grid>

            <Grid item xs={4}>
              <TextInput source="details" required fullWidth label="Status Context" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Deactivate" icon={null} />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default ProsDeactivateAccount;
