import React from 'react';

import { ArrayField, Datagrid, TextField } from 'react-admin';

import { Box, Card, Typography } from '@mui/material';

const AdditionalItems = () => (
  <Card>
    <ArrayField source="additional_items">
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Additional Items</Typography>
      </Box>
      <Datagrid bulkActionButtons={false}>
        <TextField source="pretty_name" label="Name" />
        <TextField source="count" />
      </Datagrid>
    </ArrayField>
  </Card>
);

export default AdditionalItems;
