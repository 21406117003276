import React, { useContext } from 'react';

import { bool } from 'prop-types';
import { Form, useDelete, useNotify, useRefresh } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MarketActionContext } from '@/providers/MarketAction';

const DeleteMarketProductForm = ({ isDeleting }) => {
  const { setAction } = useContext(MarketActionContext);

  return (
    <>
      <Box px={2} pt={2}>
        <Typography>Are you sure you want to delete this market product?</Typography>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isDeleting ?? null}
          startIcon={isDeleting ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

DeleteMarketProductForm.propTypes = {
  isDeleting: bool.isRequired,
};

const DeleteMarketProduct = () => {
  const { setAction, metadata } = useContext(MarketActionContext);
  const [deleteOne, { isLoading: isDeleting }] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - deleting market product failed - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    notify(`Market product: ${metadata.id} was deleted!`, {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = () => {
    deleteOne(
      resources.MARKET_PRODUCTS,
      {
        id: metadata.market_id,
        meta: {
          resourceId: metadata.product_id,
          subResource: 'products',
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Delete Market Product
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <DeleteMarketProductForm isDeleting={isDeleting} />
        </Form>
      </Box>
    </Box>
  );
};

export default DeleteMarketProduct;
