import React, { useContext, useState } from 'react';

import { usePermissions, useRecordContext, useRedirect, useShowContext } from 'react-admin';

import {
  Cancel,
  CurrencyExchange,
  Edit,
  MoreVert,
  PauseCircle,
  PlayCircle,
  ShoppingCartCheckout,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import * as resources from '@/api/resources';
import { TXN_STATUS } from '@/constants/transactions';
import usePauseTransaction from '@/hooks/transactions/usePauseTransaction';
import useProcessTransaction from '@/hooks/transactions/useProcessTransaction';
import { JobSlotActionContext } from '@/providers/JobSlotAction';

const TransactionMenu = () => {
  const { setAction, setMetadata } = useContext(JobSlotActionContext);
  const { record: { id: jobSlotId } = {} } = useShowContext();
  const transaction = useRecordContext();

  const { permissions } = usePermissions();
  const redirect = useRedirect();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleTransactionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeTransactionMenu = () => setAnchorEl(null);

  const [{ processTransaction }, { isLoading: processTransactionIsLoading }] = useProcessTransaction();
  const [{ pauseTransaction }, { isLoading: pauseTransactionIsLoading }] = usePauseTransaction();

  if (!transaction) return null;

  const canPauseTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.update;
  const isTransactionPausable = transaction.transaction_status !== TXN_STATUS.COMPLETED && transaction.auto_capture;

  const canUnpauseTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.update;
  const isTransactionUnpausable = transaction.transaction_status !== TXN_STATUS.COMPLETED && !transaction.auto_capture;

  const canEditTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.update;
  const isTransactionEditable = transaction.transaction_status === TXN_STATUS.OPEN && transaction.amount > 0;

  const canReverseTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.reverse;
  const isTransactionReversable =
    transaction?.retained_amount > 0 && transaction?.transaction_status !== TXN_STATUS.OPEN && !transaction?.non_stripe;

  const canProcessTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.process;
  const isTransactionProcessable = transaction?.transaction_status === TXN_STATUS.OPEN && !transaction?.non_stripe;

  const isTransactionCancelable = transaction.transaction_status === TXN_STATUS.OPEN;

  const menuItems = [
    canEditTransaction && isTransactionEditable ? (
      <MenuItem
        key="edit_transaction"
        onClick={() => redirect(`/${resources.JOB_SLOT_TRANSACTIONS}/${transaction.job_slot_id}/${transaction.id}/edit`)}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
    ) : null,
    canPauseTransaction && isTransactionPausable ? (
      <MenuItem
        key="pause_transaction"
        onClick={() => {
          pauseTransaction({ jobSlotId, transactionId: transaction?.id });
          closeTransactionMenu();
        }}
        disabled={pauseTransactionIsLoading ?? null}
      >
        <ListItemIcon>
          <PauseCircle />
        </ListItemIcon>
        <ListItemText>Pause</ListItemText>
      </MenuItem>
    ) : null,
    canUnpauseTransaction && isTransactionUnpausable ? (
      <MenuItem
        key="unpause_transaction"
        onClick={() => {
          setMetadata({ jobSlotId, transactionId: transaction?.id });
          setAction('unpause');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <PlayCircle />
        </ListItemIcon>
        <ListItemText>Unpause</ListItemText>
      </MenuItem>
    ) : null,
    canReverseTransaction && isTransactionReversable ? (
      <MenuItem
        key="reverse_transaction"
        onClick={() => {
          setMetadata(transaction);
          setAction('reverse_transfer');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <CurrencyExchange />
        </ListItemIcon>
        <ListItemText>Reverse</ListItemText>
      </MenuItem>
    ) : null,
    canProcessTransaction && isTransactionProcessable ? (
      <MenuItem
        key="process_transaction"
        onClick={() => {
          processTransaction({ jobSlotId, transactionId: transaction?.id });
          closeTransactionMenu();
        }}
        disabled={processTransactionIsLoading ?? null}
      >
        <ListItemIcon>
          <ShoppingCartCheckout />
        </ListItemIcon>
        <ListItemText>Process</ListItemText>
      </MenuItem>
    ) : null,
    canEditTransaction && isTransactionCancelable ? (
      <MenuItem
        key="cancel_charge"
        onClick={() => {
          setMetadata(transaction);
          setAction('cancel_charge');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <Cancel />
        </ListItemIcon>
        <ListItemText>Cancel</ListItemText>
      </MenuItem>
    ) : null,
  ].filter((item) => !!item);

  return menuItems?.length > 0 ? (
    <>
      <IconButton onClick={handleTransactionMenu}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeTransactionMenu}>
        {menuItems}
      </Menu>
    </>
  ) : null;
};

export default TransactionMenu;
