import React from 'react';
import { Route } from 'react-router';

import * as resources from '@/api/resources';

import JobEditMatchStatus from './Edit/Details/JobContent';
import JobList from './List';
import JobShow from './Show';

const jobs = () => ({
  name: resources.JOBS,
  list: JobList,
  show: JobShow,
  children: [<Route key="job-match-status-edit" path=":id/edit/matchStatus" element={<JobEditMatchStatus />} />],
});

export default jobs;
