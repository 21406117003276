import React from 'react';

import {
  Form,
  NumberInput,
  SaveButton,
  SelectInput,
  ShowButton,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { COMPENSATION_MINIMUM_TYPE, CONTRACT_TYPE, REGIONAL_MOVE_RATE } from '@/constants/carrierAccount';

const CarrierDetailsContractEdit = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const onError = (data) => {
    const error = data.body.detail;
    const displayErrorMessage = error?.[0]?.msg ?? 'Unknown Error';
    notify(`Error on updating carrier account: ${displayErrorMessage}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    notify(`Carrier account has been updated!`, {
      type: 'success',
    });
    redirect('show', resources.CARRIERS, record?.id);
  };

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Contract" />

      <Divider />

      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectInput source="contract_type" choices={CONTRACT_TYPE} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <NumberInput source="hourly_rate" label="Hourly rate" fullWidth format={formatValue} parse={parseValue} />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                source="late_cancel_reschedule_amount"
                label="Late cancel reschedule amount"
                fullWidth
                format={formatValue}
                parse={parseValue}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                source="weekly_minimum"
                label="Weekly minimum"
                fullWidth
                format={formatValue}
                parse={parseValue}
              />
            </Grid>

            <Grid item xs={4}>
              <SelectInput source="compensation_minimum_type" choices={COMPENSATION_MINIMUM_TYPE} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                source="compensation_minimum"
                label="Compensation minimum"
                fullWidth
                format={formatValue}
                parse={parseValue}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                source="cancel_reschedule_after_start_amount"
                label="Cancel reschedule after start amount"
                fullWidth
                format={formatValue}
                parse={parseValue}
              />
            </Grid>

            <Grid item xs={4}>
              <NumberInput source="mileage_bonus" label="Mileage bonus" fullWidth format={formatValue} parse={parseValue} />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                source="mileage_bonus_minimum_distance"
                label="Mileage bonus minimum distance"
                validate={[]}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="regional_move_rate" choices={REGIONAL_MOVE_RATE} fullWidth />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Update" icon={null} mutationOptions={{ onError, onSuccess }} type="button" />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default CarrierDetailsContractEdit;
