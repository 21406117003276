import React from 'react';

import { Datagrid, DateField, TextField, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';
import MoverNotes from './MoverNotes/indes';

const OrderAttachments = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card component={Box} mt={2}>
      <CardHeader title="Order Attachments" />
      <Divider />
      <CardContent>
        <List
          disableSyncWithLocation
          resource={resources.ORDERS}
          queryOptions={{ meta: { subResource: 'attachments', resourceId: record.id } }}
          exporter={false}
          actions={false}
          sort={{ field: 'timestamp', order: 'ASC' }}
          empty={<EmptyResourceList text="No order attachments available" />}
          storeKey={`${resources.ORDERS}.order_attachments.listParams`}
        >
          <Datagrid bulkActionButtons={false}>
            <MoverNotes source="move_answers" label="Mover Notes" sortable={false} />
            <TextField source="attachment_type" sortable={false} />
            <DateField source="timestamp" showTime sortable />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

export default OrderAttachments;
