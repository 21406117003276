import React, { useMemo } from 'react';

import dayjs from 'dayjs';
import { bool, func, string } from 'prop-types';
import { Datagrid, Labeled, Link, TextField, useCreatePath, useRecordContext } from 'react-admin';

import { ChevronRight } from '@mui/icons-material';
import { Box, Collapse, Divider, Grid, IconButton, Tooltip } from '@mui/material';

import * as resources from '@/api/resources';
import { EVENT_ACTIONS_TAKEN_BY_ACCOUNTS } from '@/constants/events';
import AccountField from '@/fields/AccountField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import UserField from '@/fields/UserField';
import ZendeskIcon from '@/shared/ZendeskIcon';

const DayJsField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !record?.[source]) return null;

  return (
    <TextFieldWrapper
      textFieldProps={{ whiteSpace: 'pre-wrap' }}
      value={dayjs(record[source]).format('MM/DD/YYYY\nh:mm A')}
    />
  );
};

DayJsField.propTypes = {
  source: string.isRequired,
};

const UnknownTypeEvent = ({ expanded, setExpanded }) => {
  const record = useRecordContext();
  const createPath = useCreatePath();

  const objectLink = useMemo(() => {
    if (!record) return false;
    switch (record.object_type) {
      case 'job':
        return createPath({ resource: resources.JOBS, id: record.object_id, type: 'show' });
      case 'job_slot':
        return createPath({ resource: resources.JOB_SLOTS, id: record.object_id, type: 'show' });
      case 'job_slot_transaction': {
        // Job slot transactions don't have standalone view, go to slot view
        const slotIdFromTransactionEvent = record.metadata?.job_slot_id;
        if (slotIdFromTransactionEvent) {
          return createPath({ resource: resources.JOB_SLOTS, id: slotIdFromTransactionEvent, type: 'show' });
        }
        return false;
      }
      case 'adjustment':
      case 'order_transaction':
        // Order transactions don't have standalone view, just go to invoice tab
        return createPath({ type: 'invoice' });
      default:
        return false;
    }
  }, [record]);

  const objectIdComponent = (
    <Labeled fullWidth>
      <TextField label="Object ID" source="object_id" />
    </Labeled>
  );

  if (!record) return null;

  return (
    <Grid container alignItems="center" gap={1}>
      <Grid item xs={0.5}>
        <IconButton
          onClick={() => setExpanded(!expanded)}
          sx={{
            transform: !expanded ? 'rotate(0deg)' : 'rotate(90deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          }}
        >
          <ChevronRight />
        </IconButton>
      </Grid>
      <Grid item xs={1.5}>
        <DayJsField source="timestamp" showTime />
      </Grid>
      <Grid item xs={3}>
        <TextField source="action" />
      </Grid>
      <Grid item xs={3}>
        {EVENT_ACTIONS_TAKEN_BY_ACCOUNTS.includes(record?.action) ? (
          <AccountField showAvatar enableLink source="user_id" emptyText="No user ID on event" clipboardUserSource="email" />
        ) : (
          <UserField source="user_id" emptyText="No user ID on event" clipboardUserSource="email" />
        )}
      </Grid>
      <Grid item xs={3} ml="auto">
        <TextFieldWrapper
          textFieldProps={{ whiteSpace: 'pre-wrap' }}
          value={`Unknown Event Object Type\n${record?.object_type}`}
        />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded}>
          <Divider />
          <Box py={1}>
            {objectLink ? <Link to={objectLink}>{objectIdComponent}</Link> : objectIdComponent}
            {record?.event_audit_fields?.length ? (
              <Datagrid data={record?.event_audit_fields} bulkActionButtons={false} sort={false}>
                <TextField source="name" />
                <TextField source="previous_value" />
                <TextField source="new_value" />
              </Datagrid>
            ) : null}
            {record?.metadata ? (
              <Grid container spacing={2} mt={0.5}>
                {record?.metadata?.zendesk_id ? (
                  <Grid item xs={3}>
                    <Labeled label="Zendesk Ticket">
                      <Box display="flex" gap={0.5} alignItems="center">
                        <Tooltip title="Open Zendesk ticket" placement="top">
                          <IconButton
                            size="small"
                            href={`${process.env.REACT_APP_ZENDESK_TICKET_URI}/${record?.metadata?.zendesk_id}`}
                            target="_blank"
                          >
                            <Box width={20} height={20} display="flex" alignItems="center">
                              <ZendeskIcon />
                            </Box>
                          </IconButton>
                        </Tooltip>
                        <TextField source="metadata.zendesk_id" />
                      </Box>
                    </Labeled>
                  </Grid>
                ) : null}
                {record?.metadata?.context ? (
                  <Grid item xs={3}>
                    <Labeled>
                      <TextField label="Context" source="metadata.context" />
                    </Labeled>
                  </Grid>
                ) : null}
                {record?.metadata?.time_log_edit_reason ? (
                  <Grid item xs={3}>
                    <Labeled>
                      <TextField label="Time Log Edit Reason" source="metadata.time_log_edit_reason" />
                    </Labeled>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

UnknownTypeEvent.propTypes = {
  expanded: bool.isRequired,
  setExpanded: func.isRequired,
};

export default UnknownTypeEvent;
