import React from 'react';
import { LoadingIndicator } from 'react-admin';

import { Box, Divider, Typography } from '@mui/material';
import { arrayOf, bool, shape } from 'prop-types';
import locationSequenceSort from '@/utils/locations/sequenceSort';
import toPascalCase from '@/utils/toPascalCase';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import RouteInfo from '../RouteInfo';

const LocationsContent = ({ isLoading, data }) => {
  data.sort(locationSequenceSort);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <Box display="flex" alignItems="center">
      <Box flex={1}>
        {data?.map((location, i, { length }) => {
          const locationString = formatAddressParts({
            line_1: toPascalCase(location.street_address_1),
            line_2: toPascalCase(location.street_address_2),
            city: toPascalCase(location.city),
            state: location.state,
            postal_id: location.postal_id,
          });
          return (
            <React.Fragment key={location.id}>
              <Box px={2} py={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{locationString}</Typography>
              </Box>
              {i < length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </Box>
      <Divider orientation="vertical" flexItem />
      <RouteInfo locations={data} />
    </Box>
  );
};

LocationsContent.propTypes = {
  isLoading: bool.isRequired,
  data: arrayOf(shape({})),
};

LocationsContent.defaultProps = {
  data: [],
};

export default LocationsContent;
