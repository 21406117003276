import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, TextInput, useCreate, useNotify, useRefresh, useShowContext } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

import ADJUSTMENT_REASONS from '../AddPriceAdjustment/adjustmentReasons';
import CurrencyInput from '../AddTips/CurrencyInput';

const AddDiscountForm = () => {
  const { setAction } = useContext(OrderActionContext);
  const { control } = useFormContext();

  return (
    <>
      <Box p={2}>
        <Box mb={2}>
          <Controller
            defaultValue=""
            name="unitprice"
            control={control}
            rules={{ max: 1000 }}
            render={({ field, fieldState }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="unitprice-label">Amount</InputLabel>
                <CurrencyInput field={field} fieldState={fieldState} />
                {fieldState?.error && fieldState?.error?.type === 'max' ? (
                  <Typography variant="body2" color="error.main" component={Box} pt={1}>
                    Maximum discount of $1,000 allowed
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <TextInput source="zendeskId" label="Zendesk Ticket Id" fullwidth variant="outlined" />
        </Box>

        <Box>
          <TextInput source="context" label="Zendesk Context" multiline fullwidth variant="outlined" />
        </Box>

        <Box mb={2}>
          <Controller
            defaultValue=""
            name="reason"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="reason-label">Reason</InputLabel>
                <Select labelId="reason-label" label="Reason" {...field} required>
                  {ADJUSTMENT_REASONS.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>

        <Box>
          <TextInput
            inputProps={{ maxLength: 255 }}
            source="description"
            label="Context"
            multiline
            fullWidth
            variant="outlined"
            required
          />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const AddDiscount = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error adding discount - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track(`Order Management - Discount created`, {
        resource: resources.ORDERS,
        resourceId: orderId,
        confirmationId,
      });

      notify('Discount created', {
        type: 'success',
      });
      refresh();
      setAction(null);
    }
  };

  const onSubmit = ({ description, unitprice, zendeskId, context, reason }) => {
    const actionMetadata = {
      zendesk_id: zendeskId,
      context,
    };

    create(
      resources.ORDERS,
      {
        meta: {
          subResource: 'discounts',
          resourceId: orderId,
          resourceVersion: 'v2',
        },
        data: {
          unitprice: parseInt(0 - Number(`${unitprice}`) * 100, 10), // subtract from zero for negative and multiply by 100 to get cents values
          description,
          context: description,
          reason,
          type: 'HQ_OPS',
          actionMetadata,
        },
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Discount
      </Typography>
      <Divider />
      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form onSubmit={onSubmit}>
            <AddDiscountForm />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default AddDiscount;
