import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import { MARKET_DETAIL_SECTION, MARKET_TAB } from '@/constants/market';

import SectionHeader from '../../../Common/SectionHeader';

const MarketDetailsSectionHeader = ({ tab, section, title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const primaryInformationMenuItem = [
    ...(permissions.tools?.hqadmin?.markets?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.MARKETS}/${record?.id}/edit/${tab}/${section}`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  switch (section) {
    case MARKET_DETAIL_SECTION.PRIMARY_INFORMATION:
      contextMenuItems = primaryInformationMenuItem;
      break;
    default:
      break;
  }

  return <SectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

MarketDetailsSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

MarketDetailsSectionHeader.defaultProps = {
  tab: MARKET_TAB.DETAILS,
};
export default MarketDetailsSectionHeader;
