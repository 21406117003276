const ORDER_TABS = {
  DETAILS: 'details',
  INVOICE: 'invoice',
  CREW_INFO: 'crew-info',
  LOCATIONS: 'locations',
  INVENTORY: 'inventory',
  COMPLIANCE: 'compliance',
  HISTORY: 'history',
  SUPPORT: 'support',
};

const ORDER_ATTACHMENT_FORMS = {
  PRE_MOVE: 'Pre-move Form',
  NOTE: 'Note Form',
  CUSTOMER_WALKTHROUGH: 'Customer Walkthrough Form',
  PODS: 'Pods Form',
};

const ORDER_RESCHEDULE_TYPE = [
  { id: 'HQ', name: 'HQ' },
  { id: 'Customer Called/Emailed', name: 'Customer Called/Emailed' },
  { id: 'Customer', name: 'Customer' },
];

const ORDER_CANCEL_TYPE = [
  { id: 'HQ', name: 'HQ' },
  { id: 'HQ on Behalf of Customer', name: 'Customer Called/Emailed' },
  { id: 'Customer', name: 'Customer' },
];

const ORDER_CREW_EDIT_TIME_LOG_REASONS = [
  { id: 'Customer not on site', name: 'Customer not on site' },
  { id: "Customer can't find QR code", name: "Customer can't find QR code" },
  { id: 'Customer pre-move documents not signed', name: 'Customer pre-move documents not signed' },
  { id: "Worker's phone not working", name: "Worker's phone not working" },
  { id: 'App issues / crashes', name: 'App issues / crashes' },
  { id: 'Other move day issue', name: 'Other move day issue' },
  { id: 'Captain did not clock out', name: 'Captain did not clock out' },
];

const WARNING_ALERT_ORDER_TEXT = {
  PAST_8_DAYS_RECONCILE:
    "This order's end date was more than 8 days ago. The customer's bill will not automatically update to reflect any duration changes. New transactions will have to be manually created.",
};

export {
  ORDER_TABS,
  ORDER_ATTACHMENT_FORMS,
  ORDER_RESCHEDULE_TYPE,
  ORDER_CANCEL_TYPE,
  ORDER_CREW_EDIT_TIME_LOG_REASONS,
  WARNING_ALERT_ORDER_TEXT,
};
