import React from 'react';

import mx from 'mixpanel-browser';
import { bool, func } from 'prop-types';
import { SelectInput, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';

import HiddenInput from '@/components/inputs/HiddenInput';

import { STATES } from '@/constants/location';
import Suggestions from './Suggestions';

const EditLocationForm = ({ isUpdating, onClose }) => {
  const { setValue } = useFormContext();

  const clearAllFields = () => {
    mx.track('Order Management - Create Quote - Location - Clear All Fields');
    setValue(
      'location',
      {
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        postal_id: '',
        property_type: '',
        id: '',
      },
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  return (
    <Grid container>
      <HiddenInput source="location_id" />
      {/* Location ID is duplicated in original data */}
      <HiddenInput source="location.id" />
      <HiddenInput source="location.country" />
      <Grid padding={2} xs={6} item container rowSpacing={1} columnSpacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput label="Line 1" source="location.line_1" fullWidth required />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Line 2" source="location.line_2" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="City" source="location.city" fullWidth required />
        </Grid>
        <Grid item xs={3}>
          <SelectInput label="State" source="location.state" choices={STATES} sx={{ minWidth: 0 }} fullWidth required />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="ZIP" source="location.postal_id" fullWidth required />
        </Grid>
        <Grid item xs={6} ml="auto">
          <SelectInput
            label="Property Type"
            source="location.property_type"
            choices={[
              { id: 'SINGLE_FAMILY_HOME', name: 'Single Family Home' },
              { id: 'MULTI_FAMILY_HOME', name: 'Multi Family Home' },
              { id: 'APARTMENT', name: 'Apartment' },
              { id: 'COMMERCIAL', name: 'Commercial' },
              { id: 'STORAGE', name: 'Storage' },
              { id: 'OTHER', name: 'Other' },
            ]}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button onClick={clearAllFields}>Clear All Fields</Button>
        </Grid>
      </Grid>
      <Grid xs={6} item minHeight={400} py={2} pr={2}>
        <Typography pb={1} variant="h5" textAlign="center">
          Suggestions
        </Typography>
        <Suggestions />
      </Grid>
      <Grid xs={12} item my={2}>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <Box display="flex" justifyContent="space-between" px={2} pb={2}>
          <Button variant="contained" color="neutral" onClick={onClose} disabled={isUpdating ?? null}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            endIcon={isUpdating ? <CircularProgress size={18} /> : null}
            disabled={isUpdating ?? null}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

EditLocationForm.propTypes = {
  isUpdating: bool.isRequired,
  onClose: func.isRequired,
};

export default EditLocationForm;
