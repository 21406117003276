import React from 'react';

import { NumberInput, SelectInput } from 'react-admin';

import { Box, Card, CardContent } from '@mui/material';

import { AVAILABLE_PRODUCTS } from '@/constants/quotes';

const ProductForm = () => {
  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <Card component={Box} mb={3}>
      <CardContent>
        <SelectInput source="type" choices={AVAILABLE_PRODUCTS} required variant="outlined" />
        <Box width={250}>
          <NumberInput source="amount" format={formatValue} parse={parseValue} required fullWidth min={0} max={50000} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductForm;
