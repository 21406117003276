import React, { createContext, useMemo, useState } from 'react';

import { node } from 'prop-types';

const MarketActionContext = createContext({});

const MarketActionProvider = ({ children }) => {
  const [action, setAction] = useState('');
  const [metadata, setMetadata] = useState({});

  const value = useMemo(() => ({ action, setAction, metadata, setMetadata }), [action, metadata]);

  return <MarketActionContext.Provider value={value}>{children}</MarketActionContext.Provider>;
};

MarketActionProvider.propTypes = {
  children: node.isRequired,
};

export { MarketActionContext, MarketActionProvider };
