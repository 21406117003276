import React from 'react';

import { BooleanField, Datagrid, DateField, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import PromoCodeAmountField from '@/fields/PromoCodeAmountField';
import EmptyResourceList from '@/shared/EmptyResourceList';

import PromoCodeSectionHeader from './Common/SectionHeader';

const PromoCodeList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} justifyContent="space-between" alignItems="center">
      <PromoCodeSectionHeader title="Promo Codes" />
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.PROMO_CODES}
      exporter={false}
      empty={<EmptyResourceList text="No Promo Codes" />}
      hasCreate={false}
      storeKey={`${resources.PROMO_CODES}.listParams`}
      filters={[<TextInput source="search" alwaysOn resettable autoComplete="off" />]}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" label="Name" />
        <PromoCodeAmountField label="Amount" amountSource="promo_id_amount" percentSource="promo_id_percent" />
        <TextField source="promo_id_status" label="Status" />
        <DateField source="expiration_date" sortable={false} />
        <TextField source="description" sortable={false} />
        <BooleanField source="unlimited" />
        <TextField source="promo_id_usage" label="Promo Usage" />
        <TextField source="maximum_uses" label="Promo Max Usage" sortable={false} />
        <DateField source="created_at" />
      </Datagrid>
    </List>
  </Card>
);

export default PromoCodeList;
