import React from 'react';

import { bool, func } from 'prop-types';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { WRAP_UP_AND_CROSS_SELL_SCRIPT } from '@/constants/scriptModals';

const CreateOrder = ({ open, onClose }) => {
  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Modal open={open} onClose={handleModalClose} sx={{ outline: 0 }}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        maxHeight="800px"
        width="40vw"
        overflow="auto"
        bgcolor="background.paper"
        color="text.primary"
        boxShadow={24}
        sx={{
          transform: 'translate(-50%, -50%)',
          outline: 0,
        }}
      >
        <Card>
          <CardHeader title="Call Wrap-Up & Cross-Sell" />
          <Divider />
          <CardContent>
            <Typography px={2}>
              <Typography>{WRAP_UP_AND_CROSS_SELL_SCRIPT[0].value}</Typography>

              <List>
                {WRAP_UP_AND_CROSS_SELL_SCRIPT.filter((obj) => obj.id != null).map((filteredObj) => (
                  <ListItem key={filteredObj.id}>
                    <ListItemText primary={`${filteredObj.id}. ${filteredObj.value}`} />
                  </ListItem>
                ))}
              </List>

              <Typography>{WRAP_UP_AND_CROSS_SELL_SCRIPT[4].value}</Typography>
            </Typography>
          </CardContent>
          <Divider />
          <CardHeader title="Payment Schedule" />
          <Divider />
          <CardContent>
            <Typography px={2}>
              There are three steps of payment here at Bellhop. The first is a 10% deposit that comes out 48 hours after
              booking your move. The second step is a card authorization which occurs 2 days before your move. This will
              reflect the estimated total of your move that we just discussed. The final step is the official charge to your
              card for the actual duration of your move, where you will see your bill level out. This will occur 48 hours
              after the completion of your move.
            </Typography>
          </CardContent>
          <Divider />
          <CardHeader title="Terms of Service" />
          <Divider />
          <CardContent>
            <Typography px={2}>
              All orders for moving services are governed by our Privacy Policy and Terms of Service, which are available
              online at www.bellhop.com/terms-of-service and which can be accessed through web links that we will provide to
              you via email. By directing me to place your order for services, you acknowledge that you have been given an
              opportunity to review our Terms of Service, and you agree that your order will be governed by our Terms of
              Service. With that understanding, would you like me to place your order today?
            </Typography>
          </CardContent>
          <Divider />
          <CardHeader title="Insurance & Doc Acknowledgement (Full Service only)" />
          <Divider />
          <CardContent>
            <Typography px={2}>
              <Typography paragraph>
                Perfect! You&apos;re all booked up. Be on the lookout for some documents to sign that will be sent to you via
                email.
              </Typography>
              <Typography>
                In those pre-move docs you can detail out any higher value items that you have and purchase additional
                coverage if you would like.
              </Typography>
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="center">
              <Button variant="contained" onClick={handleModalClose}>
                Acknowledge
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

CreateOrder.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default CreateOrder;
