import React from 'react';

import { EditBase } from 'react-admin';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { THIRD_PARTY_WORKER_ACCOUNT_TAB } from '@/constants/thirdPartyWorkerAccount';

import ThirdPartyWorkerHeader from '../Show/ThirdPartyWorkerHeader';
import ThirdPartyWorkerContentDetailsEdit from './Details';

const ThirdPartyWorkerAccountEdit = () => {
  const { tab: currentTab, accountId } = useParams();

  return (
    <EditBase id={accountId}>
      <Box mt={3}>
        <ThirdPartyWorkerHeader />
      </Box>
      <Box display={currentTab === THIRD_PARTY_WORKER_ACCOUNT_TAB.DETAILS ? 'block' : 'none'}>
        <ThirdPartyWorkerContentDetailsEdit />
      </Box>
    </EditBase>
  );
};

export default ThirdPartyWorkerAccountEdit;
