import React, { useContext, useState } from 'react';
import { usePermissions, useRecordContext, useShowContext } from 'react-admin';

import {
  Cancel,
  CurrencyExchange,
  Edit,
  MoreVert,
  PauseCircle,
  PlayCircle,
  ShoppingCartCheckout,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import { TXN_PAYMENT_STATUS, TXN_STATUS } from '@/constants/transactions';
import usePauseTransaction from '@/hooks/transactions/usePauseTransaction';
import useProcessTransaction from '@/hooks/transactions/useProcessTransaction';
import { OrderActionContext } from '@/providers/OrderAction';

const TransactionMenu = () => {
  const { record: { id: orderId } = {} } = useShowContext();
  const transaction = useRecordContext();

  const { setAction, setMetadata } = useContext(OrderActionContext);
  const { permissions } = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleTransactionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeTransactionMenu = () => setAnchorEl(null);

  const [{ processTransaction }, { isLoading: processTransactionIsLoading }] = useProcessTransaction();
  const [{ pauseTransaction }, { isLoading: pauseTransactionIsLoading }] = usePauseTransaction();
  if (!transaction) return null;

  const canPauseTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.update;
  const isTransactionPausable = transaction.transaction_status !== TXN_STATUS.COMPLETED && transaction.auto_capture;

  const canUnpauseTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.update;
  const isTransactionUnpausable = transaction.transaction_status !== TXN_STATUS.COMPLETED && !transaction.auto_capture;

  const canEditTransaction = permissions?.tools?.hqadmin?.order_charges?.update;
  const isTransactionEditable = transaction.transaction_status === TXN_STATUS.OPEN && transaction.amount > 0;
  const isTransactionCancelable = transaction.transaction_status === TXN_STATUS.OPEN;

  const canRefundTransaction = permissions?.tools?.hqadmin?.order_charges?.refund;
  const isTransactionRefundable =
    transaction?.transaction_status === TXN_STATUS.COMPLETED &&
    (transaction?.payment_status === TXN_PAYMENT_STATUS.CAPTURED ||
      transaction?.payment_status === TXN_PAYMENT_STATUS.DISPUTED ||
      transaction?.payment_status === TXN_PAYMENT_STATUS.PARTIALLY_REFUNDED);

  const canProcessTransaction = permissions?.tools?.hqadmin?.order_charges?.process;
  const isTransactionProcessable =
    transaction?.transaction_status === TXN_STATUS.OPEN ||
    (transaction?.payment_status === TXN_PAYMENT_STATUS.AUTHORIZED &&
      transaction?.transaction_status === TXN_STATUS.COMPLETED);

  const menuItems = [
    canEditTransaction && isTransactionEditable ? (
      <MenuItem
        key="edit_charge"
        onClick={() => {
          setMetadata({ transactionId: transaction.id });
          setAction('edit_charge');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
    ) : null,
    canRefundTransaction && isTransactionRefundable ? (
      <MenuItem
        key="refund_charge"
        onClick={() => {
          setMetadata({ transactionId: transaction.id });
          setAction('refund_charge');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <CurrencyExchange />
        </ListItemIcon>
        <ListItemText>Refund</ListItemText>
      </MenuItem>
    ) : null,
    canUnpauseTransaction && isTransactionUnpausable ? (
      <MenuItem
        key="unpause_transaction"
        onClick={() => {
          setMetadata({ orderId, transactionId: transaction?.id });
          setAction('unpause');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <PlayCircle />
        </ListItemIcon>
        <ListItemText>Unpause</ListItemText>
      </MenuItem>
    ) : null,
    canPauseTransaction && isTransactionPausable ? (
      <MenuItem
        key="pause_transaction"
        onClick={() => {
          pauseTransaction({ orderId, transactionId: transaction?.id });
          closeTransactionMenu();
        }}
        disabled={pauseTransactionIsLoading ?? null}
      >
        <ListItemIcon>
          <PauseCircle />
        </ListItemIcon>
        <ListItemText>Pause</ListItemText>
      </MenuItem>
    ) : null,
    canProcessTransaction && isTransactionProcessable ? (
      <MenuItem
        key="process_charge"
        onClick={() => {
          processTransaction({ orderId, transactionId: transaction.id });
          closeTransactionMenu();
        }}
        disabled={processTransactionIsLoading ?? null}
      >
        <ListItemIcon>
          <ShoppingCartCheckout />
        </ListItemIcon>
        <ListItemText>Process</ListItemText>
      </MenuItem>
    ) : null,
    canEditTransaction && isTransactionCancelable ? (
      <MenuItem
        key="cancel_charge"
        onClick={() => {
          setMetadata({ transactionId: transaction.id });
          setAction('cancel_charge');
          closeTransactionMenu();
        }}
      >
        <ListItemIcon>
          <Cancel />
        </ListItemIcon>
        <ListItemText>Cancel</ListItemText>
      </MenuItem>
    ) : null,
  ].filter((item) => !!item);

  return menuItems.length > 0 ? (
    <>
      <IconButton onClick={handleTransactionMenu}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeTransactionMenu}>
        {menuItems}
      </Menu>
    </>
  ) : null;
};

export default TransactionMenu;
