import React from 'react';

import { Datagrid, ReferenceInput, SelectInput, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_STATUSES, CARRIER_ACCOUNT_TYPES } from '@/constants/carrierAccount';
import MarketNameField from '@/fields/MarketNameField';
import EmptyResourceList from '@/shared/EmptyResourceList';

import CarrierSectionHeader from './Common/SectionHeader';

const AccountFilter = [
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
  <TextInput source="email" alwaysOn resettable autoComplete="off" />,
  <TextInput source="phone" alwaysOn resettable autoComplete="off" />,
  <SelectInput source="account_status" label="Status" alwaysOn choices={CARRIER_ACCOUNT_STATUSES} />,
  <SelectInput source="account_type" label="Type" choices={CARRIER_ACCOUNT_TYPES} />,
  <ReferenceInput source="market" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <TextInput source="id" label="ID" autoComplete="off" />,
];

const CarrierAccountList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2}>
      <CarrierSectionHeader title="Carrier Accounts" />
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.CARRIERS}
      filters={AccountFilter}
      filterDefaultValues={{ account_status: 'Active' }}
      exporter={false}
      empty={<EmptyResourceList text="No Carrier accounts" />}
      hasCreate={false}
      storeKey={`${resources.CARRIERS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick={(id) => `/carriers/${id}/show`}>
        <TextField source="name" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="status" sortable={false} />
        <TextField source="account_record_type_name" label="Record Type" sortable={false} />
        <MarketNameField source="primary_market" sortable={false} />
      </Datagrid>
    </List>
  </Card>
);

export default CarrierAccountList;
