import React, { useMemo } from 'react';

import { Labeled, useGetList, useRecordContext } from 'react-admin';

import { Info } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { EVENT_ACTIONS, EVENT_FIELDS } from '@/constants/events';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import { eventNewValueByField, latestEventByField } from '@/utils/events';

import ClockEdits from './ClockEdits';

const TimeCard = () => {
  const { id: jobSlotId, clock_in_date_time: clockIn, clock_out_date_time: clockOut } = useRecordContext();

  const { data } = useGetList(resources.JOB_SLOTS, {
    meta: {
      resourceId: jobSlotId,
      subResource: 'event_history',
    },
    sort: {
      field: 'timestamp',
      order: 'DESC',
    },
    pagination: {
      perPage: 999,
      page: 1,
    },
  });

  const clockedEventData = useMemo(() => {
    if (!data) return null;
    const clockedEvents = data?.filter((event) => event.action === EVENT_ACTIONS.SLOT_CLOCKED);
    const latestClockInEvent = latestEventByField(clockedEvents, EVENT_FIELDS.MOBILE_CLOCK_IN, EVENT_FIELDS.NONE_VALUE);
    const latestClockOutEvent = latestEventByField(clockedEvents, EVENT_FIELDS.MOBILE_CLOCK_OUT, EVENT_FIELDS.NONE_VALUE);
    const latestClockInValue = eventNewValueByField(latestClockInEvent, EVENT_FIELDS.MOBILE_CLOCK_IN);
    const latestClockOutValue = eventNewValueByField(latestClockOutEvent, EVENT_FIELDS.MOBILE_CLOCK_OUT);

    return { rawEvents: clockedEvents, latestClockInValue, latestClockOutValue };
  }, [data]);

  const manualClockEditEventData = useMemo(
    () => data?.filter((event) => event.action === EVENT_ACTIONS.SLOT_EDITED),
    [data],
  );

  const clockTimesExist = clockIn || clockOut;
  // Assumption - if clock out value exists & no manual edits & no latest clock out value from clocked events - then slot was clocked out by captain on job
  // Data check is just to wait until event data loads
  const clockOutFromCaptain = Boolean(
    data && clockOut && !manualClockEditEventData?.length && !clockedEventData?.latestClockOutValue,
  );

  return (
    <Card>
      <CardHeader title="Time Card" />
      <Divider />
      <CardContent>
        {clockTimesExist ? (
          <Grid container textAlign="center">
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Labeled label="Clock In">
                  <PrefDateTimeField
                    source="clock_in_date_time"
                    timezoneSource="clock_in_timezone"
                    dayjsFormat="h:mm a"
                    emptyText="Pending"
                  />
                </Labeled>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Labeled label="Clock Out">
                  <PrefDateTimeField
                    source="clock_out_date_time"
                    timezoneSource="clock_out_timezone"
                    dayjsFormat="h:mm a"
                    emptyText="Pending"
                  />
                </Labeled>
                {clockOutFromCaptain ? (
                  <Box pl={1}>
                    <Tooltip title="Clocked out by captain">
                      <Info />
                    </Tooltip>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h6" textAlign="center">
            Pending Clock In
          </Typography>
        )}
      </CardContent>
      {clockTimesExist ? (
        <>
          <Divider />
          <ClockEdits />
        </>
      ) : null}
    </Card>
  );
};

export default TimeCard;
