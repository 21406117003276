import React from 'react';

import { useTheme } from 'react-admin';

import { Box } from '@mui/material';

import { ReactComponent as ZendeskSvg } from './zendesk-icon-square.svg';

const ZendeskIcon = () => {
  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');

  return (
    <Box
      color={lightMode ? 'neutral.darkest' : 'neutral.light'}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
    >
      <ZendeskSvg />
    </Box>
  );
};

export default ZendeskIcon;
