import { string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { SLOT_TRANSACTION_REASON } from '@/constants/slotTransactions';

const SlotTransactionReasonField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !record[source]) return null;

  return SLOT_TRANSACTION_REASON[record[source]]?.name ?? `Unknown Reason: ${record[source]}`;
};

SlotTransactionReasonField.propTypes = {
  source: string.isRequired,
};

export default SlotTransactionReasonField;
