import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';

import SectionHeader from '../SectionHeader';

const UserSectionHeader = ({ title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const userMenuItem = [
    ...(permissions.tools?.hqadmin?.users?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.USERS}/${record.id}/edit`);
            },
            component: 'Edit user',
          },
        ]
      : []),
  ];

  contextMenuItems = userMenuItem;

  return <SectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

UserSectionHeader.propTypes = {
  title: string.isRequired,
};

export default UserSectionHeader;
