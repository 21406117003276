import React, { useMemo } from 'react';

import { merge } from 'lodash';
import { Datagrid, useListContext } from 'react-admin';

import { SERVICES } from '@/constants/serviceName';
import CurrencyField from '@/fields/CurrencyField';
import ServiceNameField from '@/fields/ServiceNameField';

const PayoutConfigExamples = () => {
  const { data } = useListContext();

  const staticSortedData = useMemo(() => {
    if (!data) return [];
    const clonedData = merge([], data);
    clonedData.sort((a, b) => {
      if (a.service < b.service) return -1;
      if (a.service > b.service) return 1;
      return 0;
    });
    return clonedData;
  }, [data]);

  const fullServiceExampleData = useMemo(() => {
    const laborData = staticSortedData?.find(({ service }) => service === SERVICES.LOADINGUNLOADING.id);
    const transitData = staticSortedData?.find(({ service }) => service === SERVICES.TRANSIT.id);

    if (!laborData || !transitData) {
      return null;
    }

    return {
      two_resource_hourly:
        laborData.base_hourly_amount_cents +
        transitData.base_hourly_amount_cents +
        transitData.per_resource_hourly_amount_cents +
        laborData.per_resource_hourly_amount_cents * 2,
      additional_labor_hourly: laborData.per_resource_hourly_amount_cents,
      additional_transit_hourly: transitData.per_resource_hourly_amount_cents,
    };
  }, [staticSortedData]);

  const laborExampleData = useMemo(() => {
    if (!staticSortedData) return [];
    return [
      ...(staticSortedData
        ?.filter(({ service }) => service !== SERVICES.TRANSIT.id)
        .map((config) => ({
          service: config.service,
          two_resource_hourly: config.base_hourly_amount_cents + config.per_resource_hourly_amount_cents * 2,
          additional_labor_hourly: config.per_resource_hourly_amount_cents,
        })) ?? []),
      ...(fullServiceExampleData
        ? [
            {
              service: 'FULLSERVICE',
              ...fullServiceExampleData,
            },
          ]
        : []),
    ];
  }, [staticSortedData, fullServiceExampleData]);

  return (
    <Datagrid data={laborExampleData} sort={{ field: 'service', order: 'ASC' }} bulkActionButtons={false}>
      <ServiceNameField source="service" sortable={false} />
      <CurrencyField
        suffix=" / hr"
        source="two_resource_hourly"
        label="2&nbsp;Resource Rate"
        textAlign="left"
        sortable={false}
      />
      <CurrencyField
        suffix=" / hr"
        source="additional_labor_hourly"
        label="Additional Labor"
        textAlign="left"
        sortable={false}
      />
      <CurrencyField
        suffix=" / hr"
        source="additional_transit_hourly"
        label="Additional Truck"
        textAlign="left"
        sortable={false}
      />
    </Datagrid>
  );
};

export default PayoutConfigExamples;
