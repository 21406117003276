import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { BooleanInput, Form, SelectInput, useCreate, useNotify, useRefresh, useShowContext } from 'react-admin';

import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';
import { Help, Warning } from '@mui/icons-material';

const AddJobSlotForm = () => {
  const { setAction, setMetadata } = useContext(OrderActionContext);

  return (
    <>
      <Box p={2}>
        <SelectInput
          fullWidth
          required
          label="Skill Level"
          source="minimum_qualification"
          choices={[
            {
              id: 1,
              name: 'Standard',
            },
            {
              id: 2,
              name: 'Lead',
            },
            {
              id: 3,
              name: 'Captain',
            },
          ]}
        />
        <Box display="flex">
          <BooleanInput source="override" />
          <Box mt={0.75}>
            <Tooltip
              title={
                <Typography whiteSpace="pre-wrap">{`Only necessary if adding slots to cancelled/completed jobs\n\nUse with caution`}</Typography>
              }
            >
              <Help />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="neutral"
          onClick={() => {
            setAction(null);
            setMetadata(null);
          }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const AddJobSlot = () => {
  const { setAction, metadata, setMetadata } = useContext(OrderActionContext);
  const {
    record: { confirmation_id: confirmationId },
  } = useShowContext();
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    let message = error.body?.detail ?? error.message;

    if (message.includes('not eligible to add job slots')) {
      message = `Job is not eligible to add job slots - Use override if necessary and proceed with caution`;
    }

    notify(`Add Job Slot failed - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track(`Order Management - Job Slot Added`, {
      resource: resources.JOBS,
      resourceId: metadata?.jobId,
      confirmationId,
    });

    notify('Job Slot Added', {
      type: 'success',
    });
    refresh();
    setAction(null);
    setMetadata(null);
  };

  const handleSubmit = (data) => {
    create(
      resources.JOBS,
      {
        data: {
          minimum_qualification: data.minimum_qualification,
          override: data.override,
        },
        meta: {
          resourceId: metadata?.jobId,
          subResource: 'job_slots',
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={500}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Job Slot
      </Typography>
      <Divider />
      <Box display="flex" gap={2} py={2}>
        <Warning color="warning" />
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            Adding a slot manually will increase the hourly price charged to the customer on this service.
          </Typography>
          <Typography>The hourly price will increase by the average cost of a slot based on the current price.</Typography>
          <Typography>
            Add a price adjustment on the Invoice tab if you do not wish the customer to get charged for this addition.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <AddJobSlotForm />
        </Form>
      </Box>
    </Box>
  );
};

export default AddJobSlot;
