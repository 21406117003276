import React, { useContext, useEffect, useState } from 'react';

import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  useCreate,
  useGetList,
  useList,
  useNotify,
  usePermissions,
  useRedirect,
  useReference,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { HelpOutlined } from '@mui/icons-material';
import { Box, Button, CardContent, Divider, Tab, Tabs, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { RESCHEDULE_REASONS_BY_TYPE } from '@/constants/quotes';
import { SCRIPT_MODALS } from '@/constants/scriptModals';
import { ORDER_TAG_CHOICES } from '@/constants/tags';
import { QuoteContext } from '@/providers/Quote';
import EmptyResourceList from '@/shared/EmptyResourceList';

const SelectOrderFromAccount = () => {
  const { accountId } = useContext(QuoteContext);
  const redirect = useRedirect();

  const { data: customerOrders, isLoading } = useGetList(resources.CUSTOMERS, {
    meta: {
      subResource: 'order_history',
      resourceId: accountId,
    },
    pagination: {
      page: 1,
      perPage: 999,
    },
  });

  const availableOrdersToUpdate = customerOrders?.filter((order) => ['CREATED', 'DRAFT'].includes(order.status)) ?? [];

  const listContext = useList({
    data: availableOrdersToUpdate,
    isLoading,
    sort: { field: 'created_at', order: 'DESC' },
  });

  if (!accountId) return null;

  return (
    <>
      <Typography variant="h6" pb={2}>
        Select Order to Update
      </Typography>
      <Box>
        <ListContextProvider value={listContext}>
          <Datagrid
            bulkActionButtons={false}
            empty={<EmptyResourceList text="Customer has no available orders to update" />}
          >
            <TextField source="confirmation_number" label="Confirm #" sortable={false} />
            <TextField source="status" sortable={false} />
            <DateField source="created_at" label="Created" />
            <FunctionField
              render={({ id }) => (
                <Button variant="contained" size="small" onClick={() => redirect(`?orderId=${id}`)}>
                  Select
                </Button>
              )}
            />
          </Datagrid>
        </ListContextProvider>
      </Box>
    </>
  );
};

const AccountQuoteTags = () => {
  const { accountId } = useContext(QuoteContext);

  const { referenceRecord: accountRecord } = useReference({
    reference: resources.CUSTOMERS,
    id: accountId,
    options: { enabled: Boolean(accountId) },
  });

  if (!accountId || !accountRecord?.tags?.length) return null;

  const availableOrderTags = ORDER_TAG_CHOICES.filter((tag) => accountRecord.tags.includes(tag.id));

  if (!availableOrderTags.length) return null;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" mr={0.5}>
          Select the account tags to copy to order:
        </Typography>
        <Tooltip title="Tags must be on account to show in dropdown - Tags may be manually added to orders after booking via Update Order">
          <HelpOutlined />
        </Tooltip>
      </Box>
      <SelectArrayInput choices={availableOrderTags} source="tags" />
    </Box>
  );
};

const CreateOrderFromQuote = () => {
  const { record: quoteRecord } = useShowContext();

  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();

  const { watch } = useFormContext();
  const { openScriptModal, updateScriptModalCloseFunc, accountDefaultPaymentMethod, needCustomerPaymentMethod } =
    useContext(QuoteContext);

  const onError = (error) => {
    mx.track('Order Management - Error creating Order from Quote', {
      quoteId: quoteRecord?.id,
      error,
    });

    const { message, status } = error;

    notify(`Create order failed - ${status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    mx.track('Order Management - Order created from Quote', {
      orderId: data.id,
      quoteId: quoteRecord?.id,
      quoteAvailable: quoteRecord?.is_available,
    });

    notify('Order Created', {
      type: 'success',
    });
    refresh();
    navigate(`/${resources.ORDERS}/${data.id}/show`);
  };

  const [create, { isLoading }] = useCreate();

  const createOrder = () => {
    if (process.env.REACT_APP_SKIP_SCRIPT_PROMPTS) {
      create(
        resources.ORDERS,
        {
          data: {
            quote_id: quoteRecord?.id,
            opportunity_id: watch('opportunity_id') ?? null,
            tags: watch('tags') ?? null,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
      return;
    }
    updateScriptModalCloseFunc(SCRIPT_MODALS.BOOK_ORDER, () => {
      create(
        resources.ORDERS,
        {
          data: {
            quote_id: quoteRecord?.id,
            opportunity_id: watch('opportunity_id') ?? null,
            tags: watch('tags') ?? null,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    });
    openScriptModal(SCRIPT_MODALS.BOOK_ORDER);
  };

  const canCreateOrder = permissions?.tools?.hqadmin?.orders?.create;

  if (!canCreateOrder)
    return (
      <CardContent>
        <Typography textAlign="center" variant="h5">
          Coming soon!
        </Typography>
      </CardContent>
    );

  return (
    <>
      <CardContent>
        <TextInput label="Opportunity SFID" source="opportunity_id" fullWidth />
        <AccountQuoteTags />
      </CardContent>
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => createOrder()}
            disabled={(isLoading || (needCustomerPaymentMethod && !accountDefaultPaymentMethod)) ?? null}
          >
            Create Order
          </Button>
        </Box>
      </CardContent>
    </>
  );
};

const UpdateOrderFromQuote = () => {
  const { record: quoteRecord } = useShowContext();
  const { orderId, accountDefaultPaymentMethod, needCustomerPaymentMethod } = useContext(QuoteContext);
  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();

  const { setValue, watch } = useFormContext();

  const { referenceRecord: { status: orderStatus } = {} } = useReference({
    reference: resources.ORDERS,
    id: orderId,
    enabled: Boolean(orderId),
  });

  const onError = (error) => {
    mx.track('Order Management - Error updating Order from Quote', {
      orderId,
      quoteId: quoteRecord?.id,
      error,
    });

    let { message } = error;

    if (error?.body?.detail) {
      message = error.body.detail;
    }

    notify(`Updating order failed - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    mx.track('Order Management - Order updated from Quote', {
      orderId,
      quoteId: quoteRecord?.id,
      quoteAvailable: quoteRecord?.is_available,
    });

    notify('Order Updated', {
      type: 'success',
    });
    refresh();
    navigate(`/${resources.ORDERS}/${data.id}/show`);
  };

  const rescheduleType = watch('reschedule_type');
  const rescheduleReason = watch('reschedule_reason');

  const canRescheduleOrder = permissions?.tools?.hqadmin?.orders?.update_from_quote;

  const [update, { isLoading }] = useUpdate(
    resources.QUOTES,
    {
      id: quoteRecord.id,
      data: {
        update_reason: rescheduleReason,
        update_type: rescheduleType,
      },
      meta: {
        subResource: 'update_order',
        subResourceId: orderId,
      },
    },
    { onError, onSuccess },
  );

  useEffect(() => {
    setValue('reschedule_reason', null);
  }, [rescheduleType]);

  useEffect(() => {
    if (orderStatus === 'DRAFT') {
      setValue('reschedule_type', 'HQ');
      setValue('reschedule_reason', 'Draft');
    }
  }, [orderStatus]);

  const isUpdateButtonDisabled =
    !canRescheduleOrder ||
    !rescheduleType ||
    !rescheduleReason ||
    isLoading ||
    (needCustomerPaymentMethod && !accountDefaultPaymentMethod);
  const isDraftOrder = orderStatus === 'DRAFT';

  return (
    <>
      <CardContent>
        <SelectInput
          label="Type"
          source="reschedule_type"
          choices={[
            {
              id: 'HQ',
              name: 'HQ',
            },
            {
              id: 'Customer Called/Emailed',
              name: 'Customer Called/Emailed',
            },
            {
              id: 'Customer',
              name: 'Customer',
            },
          ]}
          fullWidth
          defaultValue={isDraftOrder ? 'HQ' : ''}
          disabled={isDraftOrder ?? null}
        />
        <SelectInput
          label="Reason"
          source="reschedule_reason"
          choices={
            RESCHEDULE_REASONS_BY_TYPE[rescheduleType]
              ?.map((item) => ({
                id: item,
                name: item,
              }))
              ?.filter((item) => isDraftOrder || item.id !== 'Draft') ?? []
          }
          fullWidth
          disabled={!rescheduleType || isDraftOrder}
          defaultValue={isDraftOrder ? 'Draft' : ''}
        />
      </CardContent>
      <Divider />
      <CardContent>
        {orderId ? (
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={() => update()} disabled={isUpdateButtonDisabled}>
              Update Order
            </Button>
          </Box>
        ) : (
          <Box>
            <SelectOrderFromAccount />
          </Box>
        )}
      </CardContent>
    </>
  );
};

const QuoteAction = ({ currentTab }) => {
  let content;
  switch (currentTab) {
    case 'update-order':
      content = <UpdateOrderFromQuote />;
      break;
    case 'new-order':
    default:
      content = <CreateOrderFromQuote />;
      break;
  }

  return <Box>{content}</Box>;
};

QuoteAction.propTypes = {
  currentTab: string.isRequired,
};

const QuoteActionForm = () => {
  const { orderId } = useContext(QuoteContext);

  const [currentTab, setCurrentTab] = useState('new-order');

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (orderId) {
      setCurrentTab('update-order');
    }
  }, [orderId]);

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Create New Order" value="new-order" />
        <Tab label="Update Order" value="update-order" />
      </Tabs>
      <QuoteAction currentTab={currentTab} />
    </>
  );
};

export default QuoteActionForm;
