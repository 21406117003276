import React, { useContext, useEffect, useRef, useState } from 'react';

import { func, string } from 'prop-types';
import { useNotify } from 'react-admin';
import { geocodeByAddress } from 'react-places-autocomplete';

import { Box, CircularProgress, List, ListItemButton, ListItemText, Typography } from '@mui/material';

import useLazyEffect from '@/hooks/useLazyEffect';
import { MountedScriptsContext } from '@/providers/MountedScripts';
import { getAddressParts, normalizeParts } from '@/utils/locations/googleHelpers';

const PlacesSuggestions = ({ addressInput, selectAddress }) => {
  const { scripts } = useContext(MountedScriptsContext);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [ready, setReady] = useState(false);
  const autocompleteService = useRef(null);
  const autocompleteOK = useRef(null);

  const notify = useNotify();

  const handleSelect = async (addr) => {
    try {
      const geocode = await geocodeByAddress(addr);
      if (geocode && geocode.length) {
        const [googleLocation] = geocode;
        const address = normalizeParts(getAddressParts(googleLocation.address_components));
        selectAddress(address);
      }
    } catch (e) {
      notify(`Error geocoding address - ${e}`, { type: 'error' });
    }
  };

  const autocompleteCallback = (predictions, status) => {
    setLoading(false);
    if (status !== autocompleteOK.current) {
      setSuggestions([]);
      return;
    }

    setSuggestions(
      predictions
        .filter((prediction) => !prediction.types.includes('route'))
        .map((prediction, index) => ({
          ...prediction,
          active: false,
          index,
        })),
    );
  };

  const fetchPredictions = () => {
    autocompleteService.current.getPlacePredictions(
      {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        input: addressInput,
      },
      autocompleteCallback,
    );
  };

  const init = () => {
    if (!window.google) {
      throw new Error('[PlacesSuggestions]: Google Maps JavaScript API library must be loaded.');
    }

    if (!window.google.maps.places) {
      throw new Error(
        '[PlacesSuggestions]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL.',
      );
    }

    autocompleteService.current = new window.google.maps.places.AutocompleteService();
    autocompleteOK.current = window.google.maps.places.PlacesServiceStatus.OK;
    setReady(true);
  };

  useEffect(() => {
    const isPlacesLoaded = Boolean(window?.google?.maps?.places);
    // If not already initialized, google script is mounted, and places has loaded on window, initialize
    if (!ready && scripts.includes('google') && isPlacesLoaded) {
      init();
    }
  }, [scripts]);

  useEffect(() => {
    if (addressInput.length) {
      // Immediately after address user input, set loading, lazy effect sets back to false
      setLoading(true);
    } else {
      // If user clears all input, immediately set no suggestions
      setSuggestions([]);
    }
  }, [addressInput]);

  useLazyEffect(() => {
    // Lazy / debounced useEffect to fetch predictions
    if (ready && autocompleteService?.current && addressInput.length) {
      fetchPredictions();
    }
  }, [ready, autocompleteService, addressInput]);

  return loading || !ready ? (
    <CircularProgress />
  ) : (
    <Box>
      <List>
        {suggestions?.map((suggestion) => (
          <ListItemButton
            id={`suggestion-${suggestion.place_id}`}
            divider
            selected={suggestion.active}
            key={suggestion.place_id}
            onClick={() => {
              handleSelect(suggestion.description);
            }}
          >
            <ListItemText
              primary={suggestion.structured_formatting.main_text}
              primaryTypographyProps={{
                variant: 'body1',
                noWrap: true,
              }}
              secondary={suggestion.structured_formatting.secondary_text}
              sx={{
                my: 0,
              }}
            />
          </ListItemButton>
        ))}
        {addressInput.length && suggestions.length === 0 && !loading ? <Typography>No results found</Typography> : null}
      </List>
    </Box>
  );
};

PlacesSuggestions.propTypes = {
  addressInput: string,
  selectAddress: func.isRequired,
};

PlacesSuggestions.defaultProps = {
  addressInput: '',
};

export default PlacesSuggestions;
