import React from 'react';

import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { Box, Chip } from '@mui/material';

import { AVAILABLE_TAGS } from '@/constants/tags';

// Expects record[source] to be an array of strings
const TagsField = ({ source, tagKeyMap }) => {
  const record = useRecordContext();
  if (!record?.[source]) {
    return null;
  }

  const tags = record[source].sort();

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {tags?.map((tag) => (
        <Chip label={tagKeyMap?.[tag]?.name ?? tag} />
      ))}
    </Box>
  );
};

TagsField.propTypes = {
  source: string.isRequired,
  tagKeyMap: shape({}),
};

TagsField.defaultProps = {
  tagKeyMap: AVAILABLE_TAGS,
};

export default TagsField;
