const INVENTORY_STATUS = [
  { id: 'SECURED', name: 'SECURED' },
  { id: 'PROJECTED', name: 'PROJECTED' },
];

const GUARANTEED_STATUS = [
  { id: false, name: 'Non Guaranteed' },
  { id: true, name: 'Guaranteed' },
];

const MATCH_STATUS = [
  { id: 'ACCEPTED', name: 'ACCEPTED' },
  { id: 'FAILED', name: 'FAILED' },
  { id: 'FULFILLED', name: 'FULFILLED' },
  { id: 'FUNCTIONAL_OFFER', name: 'FUNCTIONAL_OFFER' },
  { id: 'IN_WAIVER', name: 'IN_WAIVER' },
  { id: 'ON_JOB_BOARD', name: 'ON_JOB_BOARD' },
  { id: 'OPERATOR_OWNED', name: 'OPERATOR_OWNED' },
];

export { INVENTORY_STATUS, MATCH_STATUS, GUARANTEED_STATUS };
