import React, { useContext, useMemo } from 'react';

import dayjs from 'dayjs';
import { oneOf } from 'prop-types';
import { DateField, FunctionField, Labeled, RecordContextProvider, TextField } from 'react-admin';

import { Dialog, DialogContent, DialogTitle, Divider, Grid, Tooltip, Typography } from '@mui/material';

import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPES_DISPLAY } from '@/constants/pricingLayers';
import CurrencyField from '@/fields/CurrencyField';
import DayOfWeekField from '@/fields/DayOfWeekField';
import MarketNameField from '@/fields/MarketNameField';
import MonthOfYearField from '@/fields/MonthOfYearField';
import ServiceNameField from '@/fields/ServiceNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { DateLayerSummaryContext } from '@/providers/Pricing/DateLayerSummary';

const DateNumericField = ({ type }) => {
  switch (type) {
    case PRICING_LAYER_TYPES.DAY_OF_WEEK:
      return <DayOfWeekField source="day" />;
    case PRICING_LAYER_TYPES.DAY_OF_MONTH:
      return <TextField source="day" />;
    case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
      return <MonthOfYearField source="month" oneIndexed />;
    case PRICING_LAYER_TYPES.LEAD_TIME:
      return <FunctionField render={({ days }) => <TextFieldWrapper value={`${days} day${days !== 1 ? 's' : ''}`} />} />;
    default:
      return null;
  }
};

DateNumericField.propTypes = {
  type: oneOf(Object.values(PRICING_LAYER_TYPES)).isRequired,
};

const LayerGroupDialog = () => {
  const { filters, selectedLayerGroup, setSelectedLayerGroup } = useContext(DateLayerSummaryContext);

  const layerGroupIsMultiplier = selectedLayerGroup?.price?.startsWith('multi_');
  const isSingleLayer = selectedLayerGroup?.layers?.length === 1;
  const singleLayerData = selectedLayerGroup?.layers?.[0];

  const sortedGroupLayers = useMemo(() => {
    if (!selectedLayerGroup?.layers) return [];
    if (!filters) return [];

    return [...selectedLayerGroup.layers].sort((a, b) => {
      if (filters.market_id) {
        return a.service_id.localeCompare(b.service_id);
      }
      if (filters.service_id) {
        return a.market_id.localeCompare(b.market_id);
      }
      return a.id.localeCompare(b.id);
    });
  }, [selectedLayerGroup, filters]);

  return (
    <Dialog maxWidth="md" open={!!selectedLayerGroup} onClose={() => setSelectedLayerGroup(null)} fullWidth>
      {!filters || !selectedLayerGroup ? null : (
        <>
          <DialogTitle>As of {dayjs(filters.effective_date_lte).format('M/D/YYYY')}</DialogTitle>
          <Divider />
          <DialogContent>
            <RecordContextProvider value={selectedLayerGroup}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Labeled label={layerGroupIsMultiplier ? 'Multiplier' : 'Price'}>
                    {layerGroupIsMultiplier ? (
                      <TextFieldWrapper value={selectedLayerGroup.price.replace('multi_', '')} />
                    ) : (
                      <CurrencyField source="price" />
                    )}
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled label={PRICING_LAYER_TYPES_DISPLAY[selectedLayerGroup.type]}>
                    <DateNumericField type={selectedLayerGroup.type} />
                  </Labeled>
                </Grid>
                {isSingleLayer || filters.market_id ? (
                  <Grid item xs={4}>
                    <Labeled label="Market">
                      <RecordContextProvider value={{ market: filters.market_id ?? singleLayerData.market_id }}>
                        <MarketNameField source="market" />
                      </RecordContextProvider>
                    </Labeled>
                  </Grid>
                ) : null}
                {isSingleLayer || filters.service_id ? (
                  <Grid item xs={4}>
                    <Labeled label="Service">
                      <RecordContextProvider value={{ service: filters.service_id ?? singleLayerData.service_id }}>
                        <ServiceNameField source="service" />
                      </RecordContextProvider>
                    </Labeled>
                  </Grid>
                ) : null}
                {isSingleLayer ? (
                  <Grid item xs={4}>
                    <Labeled label="Effective Since">
                      <RecordContextProvider value={singleLayerData}>
                        <DateField source="effective_date" />
                      </RecordContextProvider>
                    </Labeled>
                  </Grid>
                ) : null}
                {!isSingleLayer ? (
                  <Grid item xs={12}>
                    <Typography variant="body1" fontSize="0.8em">
                      {filters.market_id ? 'Service List' : 'Market List'}
                    </Typography>
                    <Grid container spacing={2} mt={0}>
                      {sortedGroupLayers.map((layer) => (
                        <RecordContextProvider value={layer} key={layer.id}>
                          <Grid item xs={4}>
                            <Tooltip title={`Effective since ${dayjs(layer.effective_date).format('M/D/YYYY')}`}>
                              <span>
                                {filters.market_id ? (
                                  <ServiceNameField source="service_id" />
                                ) : (
                                  <MarketNameField source="market_id" />
                                )}
                              </span>
                            </Tooltip>
                          </Grid>
                        </RecordContextProvider>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </RecordContextProvider>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default LayerGroupDialog;
