import React from 'react';
import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';
import TextFieldWrapper from '@/fields/TextFieldWrapper';

const ExpirationDateField = ({ months, years, textFieldProps }) => {
  const record = useRecordContext();
  if (!record || !record[months] || !record[years]) return null;

  const month = record[months];
  const year = record[years];

  const formatteddate = `${month}/${year}`;

  return <TextFieldWrapper value={formatteddate} textFieldProps={textFieldProps} />;
};

ExpirationDateField.propTypes = {
  months: string.isRequired,
  years: string.isRequired,

  textFieldProps: shape({}),
};

ExpirationDateField.defaultProps = {
  textFieldProps: {},
};

export default ExpirationDateField;
