import { useMemo } from 'react';

import mx from 'mixpanel-browser';
import { useNotify, useRefresh, useUpdate } from 'react-admin';

import * as resources from '@/api/resources';

const useProcessTransaction = () => {
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error, { resource, id, meta }) => {
    refresh();
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }
    if (resource === resources.JOB_SLOTS) {
      mx.track('Order Management - Error Processing Job Slot Transaction', {
        jobSlotId: id,
        transactionId: meta?.subResourceId,
      });
      notify(`Error processing job slot transaction - ${error.status} - ${message}`, { type: 'error' });
    }
    if (resource === resources.ORDERS) {
      mx.track('Order Management - Error Processing Order Transaction', {
        orderId: id,
        transactionId: meta?.subResourceId,
      });
      notify(`Error processing order transaction - ${error.status} - ${message}`, { type: 'error' });
    }
  };

  const onSuccess = (data, { resource, id, meta }) => {
    refresh();
    if (resource === resources.JOB_SLOTS) {
      mx.track('Order Management - Job Slot Transaction Processed', {
        jobSlotId: id,
        transactionId: meta?.subResourceId,
      });
      notify('Job Slot Transaction processed', { type: 'success' });
    }
    if (resource === resources.ORDERS) {
      mx.track('Order Management - Order Transaction Processed', {
        orderId: id,
        transactionId: meta?.subResourceId,
      });
      notify('Order Transaction processed', { type: 'success' });
    }
  };

  const processTransaction = ({ orderId, jobSlotId, transactionId }) => {
    if (orderId) {
      update(
        resources.ORDERS,
        {
          data: {},
          id: orderId,
          meta: {
            subResource: 'process_charge',
            subResourceId: transactionId,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    }
    if (jobSlotId) {
      update(
        resources.JOB_SLOTS,
        {
          data: {},
          id: jobSlotId,
          meta: {
            subResource: 'process',
            subResourceId: transactionId,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    }
  };

  const data = useMemo(
    () => ({
      processTransaction,
    }),
    [],
  );

  return [data, { isLoading }];
};

export default useProcessTransaction;
