import React from 'react';
import { useRecordContext } from 'react-admin';

import { Box } from '@mui/material';

const AccountActionsField = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Box display="flex" justifyContent="flex-end">
      {/*
      TEMP rmeoving phone icon as ops not using due to needing to link into Amazon Connect
       {record.phone ? (
        <IconButton
          color="primary"
          aria-label="call phone"
          onClick={() => {
            window.location.href = `tel:${record.phone}`;
          }}
        >
          <Phone />
        </IconButton>
      ) : null} */}
    </Box>
  );
};

AccountActionsField.propTypes = {};

export default AccountActionsField;
