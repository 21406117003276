import React from 'react';

import { ArrayInput, CreateBase, Form, SimpleFormIterator } from 'react-admin';

import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import CsvInput from './CsvInput';
import FormControls from './FormControls';

const LaneLayerCreateBatch = () => {
  const downloadCsvTemplate = () => {
    const csvDataMap = [
      {
        effective_date: 'YYYY-MM-DD',
        start_market_id: '<market_code__c>',
        end_market_id: '<market_code__c>',
        base_price: '<price in cents>',
        per_worker_price: '<price in cents>',
        fixed_price_modifier: 1,
      },
    ];
    const headers = [
      'effective_date',
      'start_market_id',
      'end_market_id',
      'base_price',
      'per_worker_price',
      'fixed_price_modifier',
    ];
    const csvContent = `data:text/csv;charset=utf-8, ${headers}\r\n${csvDataMap
      .map((e) => Object.values(e).join(','))
      .join(`\r\n`)}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'test.csv');
    link.click();
  };

  return (
    <CreateBase resource={`${resources.PRICING_LAYERS}/batch`}>
      <Form>
        <Card component={Box} mt={3}>
          <Box py={2} px={3} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Create Batch - Lane Pricing Layers</Typography>
            <Button variant="outlined" onClick={downloadCsvTemplate}>
              Download Empty Template
            </Button>
          </Box>
          <Divider />
          <CardContent>
            {/* Hidden input holder for layers parsed from CSV */}
            <ArrayInput source="layers" label="">
              <SimpleFormIterator inline sx={{ display: 'none' }} />
            </ArrayInput>
            <CsvInput />
          </CardContent>
        </Card>
        <FormControls />
      </Form>
    </CreateBase>
  );
};

export default LaneLayerCreateBatch;
