import React from 'react';

import { BooleanField, Datagrid, DateField, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

const ConnectedAccountFilter = [
  <TextInput source="code__c" label="Code" alwaysOn resettable autoComplete="off" />,
  <TextInput
    source="bt_stripe__account__r__code__c"
    label="Bt Stripe Account Code"
    alwaysOn
    resettable
    autoComplete="off"
  />,
  <TextInput
    source="bt_stripe__connected_account_id__c"
    label="Bt Stripe Connected Account ID"
    alwaysOn
    resettable
    autoComplete="off"
  />,
];

const AdminConnectedAccounts = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Connected Accounts</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.ADMIN_CONNECTED_ACCOUNTS}
      filters={ConnectedAccountFilter}
      exporter={false}
      empty={<EmptyResourceList text="No Connected Accounts" />}
      hasCreate={false}
      storeKey={`${resources.ADMIN}_connectedaccounts.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" emptyText="N/A" />
        <TextField source="name" emptyText="N/A" />
        <TextField source="bt_stripe__account__r__id__c" label="Bt Stripe Account Code" emptyText="N/A" />
        <TextField source="bt_stripe__account__c" label="Bt Stripe Account SFID" emptyText="N/A" />
        <TextField source="bt_stripe__connected_account_id__c" label="Bt Stripe Connected Account ID" emptyText="N/A" />
        <TextField source="bt_stripe__connect_account_type__c" label="Bt Stripe Connected Account Type" emptyText="N/A" />
        <TextField source="bt_stripe__payment_gateway__r__id__c" label="Bt Stripe Payment Gateway Code" emptyText="N/A" />
        <TextField source="bt_stripe__payment_gateway__c" label="Bt Stripe Payment Gateway SFID" />
        <TextField source="bt_stripe__country__c" label="Bt Stripe Country" emptyText="N/A" />
        <BooleanField source="bt_stripe__ssn_last_4_provided__c" label="Bt Stripe Last 4 Provided?" emptyText="N/A" />
        <TextField source="_hc_lastop" emptyText="N/A" />
        <TextField source="_hc_err" emptyText="N/A" />
        <TextField source="sfid" emptyText="N/A" />
        <TextField source="lastmodifiedbyid" label="Last Modified by SFID" emptyText="N/A" />
        <TextField source="lastmodifiedby__id__c" label="Last Modified by ID Code" emptyText="N/A" />
        <BooleanField source="isdeleted" label="isDeleted?" emptyText="N/A" />
        <DateField source="systemmodstamp" label="System Mod Stamp" emptyText="N/A" />
        <TextField source="createdbyid" label="Created by SFID" emptyText="N/A" />
        <TextField source="createdby__id__c" label="Created by ID Code" emptyText="N/A" />
        <DateField source="createddate" label="Created Date" emptyText="N/A" />
      </Datagrid>
    </List>
  </Card>
);

export default AdminConnectedAccounts;
