import * as resources from '@/api/resources';
import { JobSlotActionContext } from '@/providers/JobSlotAction';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import mx from 'mixpanel-browser';
import React, { useContext } from 'react';
import {
  Form,
  SelectInput,
  TextInput,
  useNotify,
  useRedirect,
  useReference,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';

const Unassign = () => {
  const { setAction, setMetadata } = useContext(JobSlotActionContext);
  const { record } = useShowContext();

  const { referenceRecord: accountData } = useReference({
    reference: resources.ACCOUNTS,
    id: record?.account_id,
    options: { enabled: Boolean(record?.account_id) },
  });

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const removalReasons = [
    { id: 'No Show', name: 'No Show' },
    { id: 'Removal', name: 'Removal' },
    { id: 'Self-Removed', name: 'Self-Removed' },
    {
      id: 'Found Coverage',
      name: 'Found Coverage',
    },
    {
      id: 'Order Status Change',
      name: 'Order Status Change',
    },
    { id: 'Role Change', name: 'Role Change' },
    { id: 'Deactivated', name: 'Deactivated' },
  ];

  const onError = (error) => {
    notify(`Unassign failed - ${error.status} - ${error.message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data, variables) => {
    const jobIsTransit = jobData?.product_id.indexOf('TRANSIT') >= 0;

    mx.track(`Fulfillment - ${jobIsTransit ? 'Transit' : 'Labor'} Unassigned`, {
      resource: resources.JOB_SLOTS,
      resourceId: record?.id,
      parentResource: resources.JOBS,
      parentResourceId: record?.job_id,
      confirmationId: orderData?.confirmation_id,
      assignedId: record?.account_id,
    });

    notify(`${accountData.name} unassigned - ${variables?.removal_reason}`, {
      type: 'success',
    });
    setMetadata(null);
    setAction(null);
    refresh();
    redirect('show', resources.JOBS, record.job_id);
  };

  const handleSubmit = ({ reason, details }) => {
    update(
      resources.JOB_SLOTS,
      {
        id: record.id,
        data: {
          account_id: null,
          removal_reason: reason,
          removal_details: details,
        },
        previousData: {
          account_id: record.account_id,
        },
      },
      {
        mutationMode: 'pessimistic',
        onError,
        onSuccess,
      },
    );
  };

  return (
    <Box px={6} py={4} minWidth={400}>
      <Typography variant="h4" component={Box} pb={4}>
        Unassign
      </Typography>
      <Box>
        <Form onSubmit={handleSubmit}>
          <SelectInput source="reason" choices={removalReasons} fullWidth required variant="outlined" />
          <Box mt={2}>
            <TextInput source="details" multiline rows={4} variant="outlined" fullWidth required />
          </Box>
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="neutral"
              onClick={() => {
                setMetadata(null);
                setAction(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="accent"
              type="submit"
              disabled={isUpdating ?? null}
              endIcon={isUpdating ? <CircularProgress size={18} /> : null}
            >
              Unassign
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

Unassign.propTypes = {};

export default Unassign;
