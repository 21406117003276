const BACKGROUND_CHECK = [
  { id: 'created', name: 'Created' },
  { id: 'clear', name: 'Clear' },
  { id: 'pending', name: 'Pending' },
  { id: 'processing', name: 'Processing' },
  { id: 'proceed', name: 'Proceed' },
];

const CARRIER_ACCOUNT_RECORD_TYPE_ARRAY = ['Carrier', 'Multi-ID Carrier Child', 'Single-ID Carrier'];

const PAYOUT_CONFIG = {
  CONTRACT_RATE: {
    id: 'Contract Rate',
    name: 'Contract Rate',
  },
  MARKET_RATE: {
    id: 'Market Rate',
    name: 'Market Rate',
  },
};

const PAYOUT_CONFIG_MINIMUM_TYPES = {
  PER_DAY: {
    id: 'PER_DAY',
    name: 'Per Day',
  },
  PER_MOVE: {
    id: 'PER_MOVE',
    name: 'Per Move',
  },
  SERVICE_MINIMUM: {
    id: 'SERVICE_MINIMUM',
    name: 'Service Minimum',
  },
};

const PAYOUT_PROVIDER = [
  { id: 'PayReel', name: 'PayReel' },
  { id: 'Stripe - Bellhop Inc', name: 'Stripe - Bellhop Inc.' },
  { id: 'Stripe - BHH Servies', name: 'Stripe - BHH Services' },
];

export { BACKGROUND_CHECK, CARRIER_ACCOUNT_RECORD_TYPE_ARRAY, PAYOUT_CONFIG, PAYOUT_CONFIG_MINIMUM_TYPES, PAYOUT_PROVIDER };
