import React from 'react';

import { Labeled, TextField, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, IconButton } from '@mui/material';

import { CARRIER_ACCOUNT_DETAIL_SECTION, CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';

import CarrierDetailsSectionHeader from '../Common/SectionHeader';

const CarrierDetailsPaymentSection = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card mt={3} component={Box}>
      <CarrierDetailsSectionHeader
        title="Payment"
        tab={CARRIER_ACCOUNT_TAB.DETAILS}
        section={CARRIER_ACCOUNT_DETAIL_SECTION.PAYMENT}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2} px={2}>
          <Grid item xs={3}>
            <Labeled>
              <TextField source="account_payout_config" emptyText="N/A" />
            </Labeled>
          </Grid>
          {record?.stripe_id ? (
            <Grid item xs={3}>
              <Labeled label="Payment link">
                <IconButton href={`${process.env.REACT_APP_STRIPE_URI}/carriers/${record?.stripe_id}`} target="_blank">
                  <Box width={30} height={30} borderRadius="50%" overflow="hidden">
                    <img src="/svgs/stripe/stripe-icon-square.svg" alt="Stripe Logo" width="100%" />
                  </Box>
                </IconButton>
              </Labeled>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CarrierDetailsPaymentSection;
