import React, { useContext, useState } from 'react';

import { Add, MoreVert } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { OrderActionContext } from '@/providers/OrderAction';
import { usePermissions, useRecordContext } from 'react-admin';

const JobMenu = () => {
  const { setAction, setMetadata } = useContext(OrderActionContext);

  const { id: jobId } = useRecordContext();
  const { permissions } = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = ({ currentTarget }) => setAnchorEl(currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const canAddJobSlot = permissions?.tools?.hqadmin?.job_slots?.create;

  const menuItems = [
    canAddJobSlot ? (
      <MenuItem
        key="Add slot"
        onClick={() => {
          setMetadata({
            jobId,
          });
          setAction('add_job_slot');
          closeMenu();
        }}
      >
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText>Add Slot</ListItemText>
      </MenuItem>
    ) : null,
  ].filter((item) => !!item);

  return menuItems?.length > 0 ? (
    <>
      <IconButton onClick={handleMenu}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        {menuItems}
      </Menu>
    </>
  ) : null;
};

export default JobMenu;
