import React from 'react';

import {
  Form,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import {
  THIRD_PARTY_WORKER_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS,
  THIRD_PARTY_WORKER_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS,
} from '@/constants/thirdPartyWorkerAccount';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between" mt={1}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Deactivate" icon={null} />
  </Box>
);

const ThirdPartyWorkerDeactivateAccount = () => {
  const record = useRecordContext();

  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }
    notify(`Error deactivating third party worker account - ${error.status} - ${message}`, { type: 'error' });
  };

  const onSuccess = () => {
    notify('Deactivated third party worker account successfully!', {
      type: 'success',
    });
    refresh();
    redirect(`/${resources.THIRD_PARTY_WORKER}/${record.id}/show`);
  };

  const onSubmit = ({ reason, details }) => {
    const crewCode = record.company_id;
    const data = {
      reason,
      details,
      crewCode,
    };

    update(
      resources.THIRD_PARTY_WORKER,
      {
        id: record.id,
        meta: {
          subResource: 'deactivate',
        },
        data,
      },
      { mutationMode: 'pessimistic', onSuccess, onError },
    );
  };

  if (!record) return null;
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview - Deactivate Third Party Worker" />

      <Divider />

      <CardContent>
        <Form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectInput
                source="new_status"
                choices={THIRD_PARTY_WORKER_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS}
                required
                fullWidth
                label="Status"
                defaultValue="Inactive"
              />
            </Grid>

            <Grid item xs={3}>
              <SelectInput
                source="reason"
                choices={THIRD_PARTY_WORKER_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS}
                required
                fullWidth
                label="Status Reason"
              />
            </Grid>

            <Grid item xs={3}>
              <TextInput source="details" required fullWidth label="Status Context" />
            </Grid>
          </Grid>

          <Divider />
          <FormControls />
        </Form>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyWorkerDeactivateAccount;
