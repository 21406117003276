import React from 'react';

import mx from 'mixpanel-browser';
import { useDelete, useNotify, useRecordContext, useRefresh, useShowContext } from 'react-admin';

import { RemoveCircle } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';

import * as resources from '@/api/resources';

const RemovePromoCode = () => {
  const {
    record: { id: orderId, confirmation_id: confirmationId },
  } = useShowContext();
  const record = useRecordContext();

  const [deleteOne, { isLoading }] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();

  const remove = () => {
    deleteOne(
      resources.ORDERS,
      {
        id: orderId,
        meta: {
          subResource: 'promo_codes',
          resourceId: record.id,
        },
      },
      {
        onSuccess: () => {
          mx.track('Order Management - Promo code removed', {
            resource: resources.ORDERS,
            resourceId: orderId,
            confirmationId,
          });
          notify('Promo code removed', {
            type: 'success',
          });
          refresh();
        },
      },
    );
  };

  return (
    <Box mr={1}>
      {isLoading ? (
        <Box width={40} height={40} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={24} />
        </Box>
      ) : (
        <IconButton onClick={remove}>
          <RemoveCircle color="accent" />
        </IconButton>
      )}
    </Box>
  );
};

export default RemovePromoCode;
