import React, { useContext } from 'react';

import { Form, TextInput, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JobSlotActionContext } from '@/providers/JobSlotAction';

const CancelCharge = () => {
  const { setAction, metadata } = useContext(JobSlotActionContext);
  const { record } = useShowContext();

  const [update, { isLoading: isUpdateLoading }] = useUpdate();

  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    notify(`Error cancelling transaction! - ${error.status} - ${error.message}`, { type: 'error' });
  };

  const onSuccess = () => {
    notify(`Transaction cancel successfully!`, { type: 'success' });
    refresh();
    setAction(null);
  };

  const onSubmit = ({ zendeskId, context, description }) => {
    const actionMetadata = {
      zendesk_id: zendeskId,
      context,
    };

    update(
      resources.JOB_SLOTS,
      {
        id: record.id,
        meta: {
          subResource: 'transactions',
          subResourceId: metadata.id,
        },
        data: {
          transaction_status: 'Canceled',
          description,
          metadata: actionMetadata,
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  if (!record) return null;
  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Cancel Charge
      </Typography>
      <Divider />

      <Box>
        <Form onSubmit={onSubmit}>
          <Box p={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput source="description" required multiline fullwidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="zendeskId" label="Zendesk Ticket Id" multiline fullwidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="context" label="Context" multiline fullwidth />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box pt={2} px={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isUpdateLoading ?? null}
              endIcon={isUpdateLoading ? <CircularProgress size={18} /> : null}
            >
              Submit
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default CancelCharge;
