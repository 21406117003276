const JOB_STATUS = {
  CANCELLED: 'Cancelled',
  COMPLETE: 'Complete',
  OPEN: 'Open',
  REMOVED: 'Removed',
};

const JOB_ACTIVE_STATUSES = ['OPEN', 'COMPLETE'];

const JOB_COMPLETABLE_STATUSES = ['OPEN', 'COMPLETE'];

const JOB_MARKETPLACE_MATCH_STATUS = [
  { id: 'IN_WAIVER', name: 'IN_WAIVER' },
  { id: 'FUNCTIONAL_OFFER', name: 'FUNCTIONAL_OFFER' },
  { id: 'OPERATOR_OWNED', name: 'OPERATOR_OWNED' },
  { id: 'ACCEPTED', name: 'ACCEPTED' },
];

export { JOB_STATUS, JOB_ACTIVE_STATUSES, JOB_MARKETPLACE_MATCH_STATUS, JOB_COMPLETABLE_STATUSES };
