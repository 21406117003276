import React from 'react';

import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import TextFieldWrapper from '@/fields/TextFieldWrapper';

const AccountTypeField = ({ source, textFieldProps }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  const value = record[source].toLowerCase();
  let accountType = 'Worker';

  if (!value.includes(accountType.toLowerCase())) {
    accountType = 'Crew';
  }

  return <TextFieldWrapper value={accountType} textFieldProps={textFieldProps} />;
};

AccountTypeField.propTypes = {
  source: string.isRequired,
  textFieldProps: shape({}),
};

AccountTypeField.defaultProps = {
  textFieldProps: {},
};

export default AccountTypeField;
