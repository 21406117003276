import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import {
  PRO_ACCOUNT_CONTACT_INFO_CARD,
  PRO_ACCOUNT_DETAIL_SECTION,
  PRO_ACCOUNT_OVERVIEW_CARD,
  PRO_ACCOUNT_TAB,
} from '@/constants/prosAccount';

import ProsSectionHeader from '../../../Common/SectionHeader';

const ProsContentDetailsSectionHeader = ({ tab, section, title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const contextMenuItemOverview = [
    ...(permissions.tools?.hqadmin?.pro_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
          {
            key: 'edit-account-status',
            onClick: () => {
              navigate(`/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/${PRO_ACCOUNT_OVERVIEW_CARD.STATUS}/edit`);
            },
            component: 'Edit status',
          },
        ]
      : []),
    ...(permissions.tools?.hqadmin?.pro_accounts?.deactivate
      ? [
          {
            key: 'post-deactivate-account',
            onClick: () => {
              navigate(`/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/${PRO_ACCOUNT_OVERVIEW_CARD.DEACTIVATE}/edit`);
            },
            component: 'Deactivate Pro',
          },
        ]
      : []),
  ];

  const contextMenuItemContactInfo = [
    ...(permissions.tools?.hqadmin?.pro_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
          {
            key: 'edit-location',
            onClick: () => {
              navigate(
                `/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/${PRO_ACCOUNT_CONTACT_INFO_CARD.LOCATION}/edit`,
              );
            },
            component: 'Edit location',
          },
        ]
      : []),
  ];

  const contextMenuItemRecruitment = [
    ...(permissions.tools?.hqadmin?.pro_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemPayment = [
    ...(permissions.tools?.hqadmin?.pro_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.ACCOUNTS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemDefault = [];

  switch (section) {
    case PRO_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      contextMenuItems = contextMenuItemOverview;
      break;
    case PRO_ACCOUNT_DETAIL_SECTION.CONTACT_INFO:
      contextMenuItems = contextMenuItemContactInfo;
      break;
    case PRO_ACCOUNT_DETAIL_SECTION.RECRUITMENT:
      contextMenuItems = contextMenuItemRecruitment;
      break;
    case PRO_ACCOUNT_DETAIL_SECTION.PAYMENT:
      contextMenuItems = contextMenuItemPayment;
      break;
    default:
      contextMenuItems = contextMenuItemDefault;
  }

  return <ProsSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

ProsContentDetailsSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

ProsContentDetailsSectionHeader.defaultProps = {
  tab: PRO_ACCOUNT_TAB.DETAILS,
};
export default ProsContentDetailsSectionHeader;
