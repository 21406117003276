import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, NumberInput, useGetList, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

const EditProductForm = () => {
  const { setAction } = useContext(OrderActionContext);
  const { formState } = useFormContext();

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <>
      <Box>
        <NumberInput source="unitprice" label="Price" required format={formatValue} parse={parseValue} />
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={!formState.isDirty}>
          Submit
        </Button>
      </Box>
    </>
  );
};

const EditProduct = () => {
  const { metadata, setAction, setMetadata } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [update, { isLoading }] = useUpdate();

  const { data: productsData, isLoading: isProductsLoading } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'products', resourceId: orderId } },
    { enabled: Boolean(orderId) },
  );

  const productItem = productsData?.find((item) => item.id === metadata?.productId);

  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error editing product - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track(`Order Management - Product updated`, {
        resource: resources.ORDERS,
        resourceId: orderId,
        confirmationId,
        productId: metadata?.productId,
      });

      notify('Product updated', {
        type: 'success',
      });
      refresh();
      setAction(null);
      setMetadata(null);
    }
  };

  const onSubmit = ({ unitprice }) => {
    update(
      resources.ORDERS,
      {
        id: orderId,
        meta: {
          subResource: 'products',
          subResourceId: metadata.productId,
        },
        data: {
          unitprice,
        },
        previousData: productItem,
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Edit Product
      </Typography>
      <Divider />
      <Box>
        {isLoading || isProductsLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form
            onSubmit={onSubmit}
            record={{
              unitprice: productItem?.unitprice,
            }}
          >
            <EditProductForm />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default EditProduct;
