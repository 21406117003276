import { Menu, MenuItem } from '@mui/material';
import { arrayOf, element, func, oneOfType, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

const ContextMenu = ({ contextItems, clickEvent }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleShowMenu = (e) => {
    setAnchorEl(e.target);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (clickEvent) {
      handleShowMenu(clickEvent);
    }
  }, [clickEvent]);

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
      {contextItems.map((item, index) => (
        <MenuItem key={item?.key ?? index} onClick={item.onClick}>
          {item.component}
        </MenuItem>
      ))}
    </Menu>
  );
};

ContextMenu.propTypes = {
  contextItems: arrayOf(shape({ key: string, onClick: func, component: oneOfType([string, element]) })),
  clickEvent: shape({}),
};

ContextMenu.defaultProps = {
  contextItems: [],
  clickEvent: null,
};

export default ContextMenu;
