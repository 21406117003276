import React from 'react';

import { func, shape } from 'prop-types';
import { NumberInput, TextInput, regex, required } from 'react-admin';

import { Grid, Typography } from '@mui/material';

import { MAX_NUMBER_GROWTH } from '@/constants/supplyinventory';

const InputFieldSection = ({ obj, handleChange }) => {
  const name = obj.section;

  const validateMaxNumber = (value) => {
    if (value > MAX_NUMBER_GROWTH) {
      return `Please enter a number that does not exceed ${MAX_NUMBER_GROWTH}!`;
    }
    return undefined;
  };

  if (!handleChange) return null;
  return (
    <Grid mt={2} container display="flex">
      <Grid item xs={12}>
        <Typography variant="h5">{obj.section}</Typography>
      </Grid>
      <Grid item xs={3} mt={1} mr={1}>
        <NumberInput
          label="Floor"
          name={`${name}floor`}
          source="floor"
          validate={[required()]}
          min={0}
          defaultValue={0}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={3} mt={1} mr={1}>
        <NumberInput
          label="Ceiling"
          name={`${name}ceiling`}
          source="ceiling"
          validate={[required()]}
          min={0}
          defaultValue={0}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={3} mt={1}>
        <TextInput
          label="Growth %"
          name={`${name}growth`}
          source="growth"
          validate={[
            required(),
            validateMaxNumber,
            regex(/^\d+(?:\.\d+)?%?$/, `You can only enter from 0-${MAX_NUMBER_GROWTH}`),
          ]}
          max={MAX_NUMBER_GROWTH}
          min={0}
          defaultValue={0}
          onChange={handleChange}
          placeholder={`Max is ${MAX_NUMBER_GROWTH}`}
          format={(value) => `${value}%`}
          parse={(value) => value.replace('%', '')}
        />
      </Grid>
    </Grid>
  );
};

export default InputFieldSection;

InputFieldSection.propTypes = {
  obj: shape({}).isRequired,
  handleChange: func.isRequired,
};
