import React from 'react';
import { useSidebarState, useTheme } from 'react-admin';

import { Box, useTheme as useMuiTheme } from '@mui/material';

import ModuleBar from './ModuleBar';
import MySidebarToggleButton from './MySidebarToggleButton';

const Menu = () => {
  const [sidebarOpen] = useSidebarState();
  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');
  const { palette } = useMuiTheme();

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: lightMode ? 'neutral.lightest' : 'secondary.darker',
        borderRight: `2px solid ${lightMode ? palette.neutral.light : palette.neutral.dark}`,
      }}
    >
      {sidebarOpen ? (
        <Box mt={3} ml={3} mb={6} maxWidth={160}>
          <img
            src={lightMode ? '/svgs/LogoDesktopNavy.svg' : '/svgs/LogoDesktopWhite.svg'}
            alt="Bellhop Logo"
            width="100%"
          />
        </Box>
      ) : (
        <Box mt={1.5} ml={1.5} maxWidth={38}>
          <img src="/svgs/LogoMobile.svg" alt="Bellhop Logo" width="100%" />
        </Box>
      )}

      {/* <Box mt={3} ml={3} width={160}>
      <img src="/svgs/LogoDesktopWhite.svg" alt="Bellhop Logo" width="100%" />
    </Box> */}
      <Box height="100%">
        <ModuleBar />
      </Box>
      <Box
        mt="auto"
        display="flex"
        justifyContent={sidebarOpen ? 'flex-end' : 'center'}
        sx={{
          borderTop: `2px solid ${lightMode ? palette.neutral.light : palette.neutral.dark}`,
        }}
      >
        <Box m={0.5}>
          <MySidebarToggleButton />
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
