export const TRUCK_SIZE = [
  { id: 'Unknown', name: 'Unknown' },
  { id: "16'R", name: "16'R" },
  { id: "16'L", name: "16'L" },
  { id: "18'R", name: "18'R" },
  { id: "18'L", name: "18'L" },
  { id: "20'R", name: "20'R" },
  { id: "20'L", name: "20'L" },
  { id: "22'R", name: "22'R" },
  { id: "22'L", name: "22'L" },
  { id: "24'R", name: "24'R" },
  { id: "24'L", name: "24'L" },
  { id: "26'R", name: "26'R" },
  { id: "26'L", name: "26'L" },
  { id: "48'R", name: "48'R" },
  { id: "48'L", name: "48'L" },
  { id: "53'R", name: "53'R" },
  { id: "53'L", name: "53'L" },
];
export const TITLE = [
  { id: 'OWNER/OPERATOR', name: 'OWNER/OPERATOR' },
  { id: 'DISPATCHER', name: 'DISPATCHER' },
];

export const BROKER_AUTHORITY = [
  { id: 'A', name: 'A' },
  { id: 'I', name: 'I' },
  { id: 'N', name: 'N' },
];

export const COMMON_AUTHORITY = [
  { id: 'A', name: 'A' },
  { id: 'I', name: 'I' },
  { id: 'N', name: 'N' },
];

export const CONTRACT_AUTHORITY = [
  { id: 'A', name: 'A' },
  { id: 'I', name: 'I' },
  { id: 'N', name: 'N' },
];

export const DOT_AUTHORITY = [
  { id: 'Interstate', name: 'Interstate' },
  { id: 'Intrastate', name: 'Intrastate' },
  { id: 'Hybrid', name: 'Hybrid' },
];

export const CONTRACT_TYPE = [
  { id: '00 - Standard Agreement', name: '00 - Standard Agreement' },
  { id: 'Non-Standard', name: 'Non-Standard' },
];

export const COMPENSATION_MINIMUM_TYPE = [
  { id: 'Per Move', name: 'Per Move' },
  { id: 'Per Day', name: 'Per Day' },
];

export const REGIONAL_MOVE_RATE = [
  { id: 'N/A', name: 'N/A' },
  { id: 'Per Rate Con', name: 'Per Rate Con' },
];

// #region Navigation
export const CARRIER_ACCOUNT_TAB = {
  AVAILABILITY: 'availability',
  DETAILS: 'details',
  JOB_HISTORY: 'job-history',
  PAYOUT_CONFIG: 'payout-config',
  SKILLS: 'skills',
  SUPPORT: 'support',
  TRANSACTIONS: 'transactions',
};

export const CARRIER_ACCOUNT_DETAIL_SECTION = {
  OVERVIEW: 'overview',
  CONTACT_INFO: 'contact-info',
  CONTRACT: 'contract',
  TRUCK: 'truck',
  AUTHORITY: 'authority',
  PAYMENT: 'payment',
};

export const CARRIER_ACCOUNT_SKILL_SECTION = {
  SKILL_LIST: 'skill-list',
};

export const CARRIER_ACCOUNT_DROPDOWN_NEWSTATUS = [
  { id: 'Active', name: 'Active' },
  { id: 'Onboarding', name: 'Onboarding' },
  { id: 'Pending Review', name: 'Pending Review' },
  { id: 'Opportunity', name: 'Opportunity' },
  { id: 'Do Not Book', name: 'Do Not Book' },
];

export const CARRIER_ACCOUNT_TYPES = [
  { id: 'Multi-ID Carrier Child', name: 'Multi-ID Carrier Child' },
  { id: 'Multi-ID Carrier Parent', name: 'Multi-ID Carrier Parent' },
  { id: 'Single-ID Carrier', name: 'Single-ID Carrier' },
  { id: 'Driver', name: 'Driver' },
];

export const CARRIER_ACCOUNT_STATUSES = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
  { id: 'Onboarding', name: 'Onboarding' },
  // { id: 'Onboarding Lost', name: 'Onboarding Lost' }, Commented out as this is a valid status but Core API does not accept
  { id: 'Opportunity', name: 'Opportunity' },
  { id: 'Opportunity Lost', name: 'Opportunity Lost' },
  { id: 'Opportunity Won', name: 'Opportunity Won' },
  { id: 'Pending Review', name: 'Pending Review' },
  { id: 'Renewal Lost', name: 'Renewal Lost' },
  { id: 'Terminated', name: 'Terminated' },
];

export const CARRIER_ACCOUNT_OVERVIEW_CARD = {
  STATUS: 'STATUS',
  DEACTIVATE: 'DEACTIVATE',
};

export const CARRIER_ACCOUNT_CONTACT_INFO_CARD = {
  LOCATION: 'location',
};

export const CARRIER_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS = [{ id: 'Inactive', name: 'Inactive' }];

export const CARRIER_ACCOUNT_DROPDOWN_REASON = [
  { id: 'No Show', name: 'No Show' },
  { id: 'Seasonal', name: 'Seasonal' },
  { id: 'Quality', name: 'Quality' },
  { id: 'Bellhop XP', name: 'Bellhop XP' },
  { id: 'Frozen Account', name: 'Frozen Account' },
  { id: 'Past Balance Outstanding', name: 'Past Balance Outstanding' },
  { id: 'Harassing Behavior', name: 'Harassing Behavior' },
  { id: 'Fraudulent Activity', name: 'Fraudulent Activity' },
  { id: 'Other', name: 'Other' },
  { id: 'Self selected (In-App)', name: 'Self selected (In-App)' },
  { id: 'Deactivated from poor - Damage Rate', name: 'Deactivated from poor - Damage Rate' },
  { id: 'Deactivated from poor - Late Rate', name: 'Deactivated from poor - Late Rate' },
  { id: 'Deactivated from poor - Removal Rate', name: 'Deactivated from poor - Removal Rate' },
  { id: 'Deactivated from poor - Damage Rate, Late Rate', name: 'Deactivated from poor - Damage Rate, Late Rate' },
  { id: 'Deactivated from poor - Damage Rate, Removal Rate', name: 'Deactivated from poor - Damage Rate, Removal Rate' },
  { id: 'Deactivated from poor - Late Rate, Removal Rate', name: 'Deactivated from poor - Late Rate, Removal Rate' },
  {
    id: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
    name: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
  },
  { id: 'Background Check', name: 'Background Check' },
];

export const CARRIER_ACCOUNT_RECORD_TYPE = [
  { id: 'Multi-ID Carrier Child', name: 'Multi-ID Carrier Child' },
  { id: 'Multi-ID Carrier Parent', name: 'Multi-ID Carrier Parent' },
  { id: 'Single-ID Carrier', name: 'Single-ID Carrier' },
];
