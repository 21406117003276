import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';

import QuoteCreate from './Create';
import QuoteEdit from './Edit';

const quotes = () => ({
  name: resources.QUOTES,
  create: QuoteCreate,
  edit: QuoteEdit,
  children: [
    <Route key="quote-edit-action" path=":id/edit/:action" element={<QuoteEdit />} />,
    <Route key="quote-edit-action-id" path=":id/edit/:action/:resourceId" element={<QuoteEdit />} />,
  ],
});

export default quotes;
