/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import Papa from 'papaparse';
import { Datagrid, FileField, FileInput, FunctionField, NumberField, TextField } from 'react-admin';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';

import { Error } from '@mui/icons-material';
import { Alert, Box, CircularProgress, Grid, Tooltip } from '@mui/material';

import CurrencyField from '@/fields/CurrencyField';
import MarketNameField from '@/fields/MarketNameField';

const CsvInput = () => {
  const { setValue, setError, clearErrors } = useFormContext();
  const laneLayerCsvFile = useWatch({ name: 'lane_layers' });
  const layers = useWatch({ name: 'layers' });

  const [parsedErrors, setParsedErrors] = useState([]);
  const [isParsing, setIsParsing] = useState(false);

  const minEffectiveDate = dayjs().add(1, 'day').format('YYYY-MM-DD'); // Minimum date for effective date is tomorrow, cannot input new pricing for the past
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

  const validateRow = ({ effective_date, start_market_id, end_market_id, per_worker_price, fixed_price_modifier }) => {
    if (!effective_date || !start_market_id || !end_market_id) {
      return {
        result: false,
        error: 'missing values',
      };
    }
    if (isProduction && dayjs(effective_date).isBefore(minEffectiveDate)) {
      setError('effective_date', { message: `effective date must be at least ${minEffectiveDate}`, type: 'min' });
      return {
        result: false,
        error: `effective date must be at least ${minEffectiveDate}`,
      };
    }
    if (start_market_id === end_market_id) {
      return {
        result: false,
        error: 'start / end market must be different',
      };
    }
    if (per_worker_price && per_worker_price < 0) {
      return {
        result: false,
        error: 'per worker price must be greater than or equal to zero',
      };
    }
    if (fixed_price_modifier && fixed_price_modifier < 0) {
      return {
        result: false,
        error: 'fixed price multiplier must be greater than or equal to zero',
      };
    }
    return {
      result: true,
    };
  };

  const parseResults = (results) => {
    const parsed = results.data
      .map((result) => {
        const { effective_date, start_market_id, end_market_id, base_price, per_worker_price, fixed_price_modifier } =
          result;
        const valid = validateRow(result);
        return {
          effective_date,
          start_market_id,
          end_market_id,
          base_price,
          per_worker_price,
          fixed_price_modifier,
          valid,
        };
      })
      .filter((item) => !!item);

    const errors = parsed.filter((item) => item.valid?.result === false);
    setParsedErrors(errors);
    setValue('layers', parsed);
    setIsParsing(false);
  };

  useEffect(() => {
    setParsedErrors([]);
    clearErrors();
    if (laneLayerCsvFile?.rawFile) {
      setIsParsing(true);
      Papa.parse(laneLayerCsvFile.rawFile, {
        complete: parseResults,
        skipEmptyLines: true,
        header: true,
        transform: (value, fieldName) => {
          if (fieldName === 'effective_date') {
            return dayjs(value).format('YYYY-MM-DD');
          }
          if (['base_price', 'per_worker_price', 'fixed_price_modifier'].includes(fieldName)) {
            return value ? Number(value) : 0;
          }
          return value;
        },
      });
    }
  }, [laneLayerCsvFile]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FileInput label={false} source="lane_layers" accept="text/csv" multiple={false}>
            <FileField source="src" title="title" />
          </FileInput>
          <Box display="flex" flexDirection="column" gap={2}>
            {parsedErrors?.length ? (
              <Alert variant="filled" severity="error">{`${parsedErrors.length} error${
                parsedErrors.length !== 1 ? 's' : ''
              } found`}</Alert>
            ) : null}
            {layers ? (
              <Alert variant="filled" severity="info">{`${layers.length} layer${
                layers.length !== 1 ? 's' : ''
              } parsed from CSV`}</Alert>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={10}>
          {isParsing ? <CircularProgress size={40} /> : null}
          {!isParsing && layers ? (
            <Datagrid
              data={layers}
              total={layers.length}
              isLoading={false}
              sort={{ field: 'effective_date', order: 'DESC' }}
              bulkActionButtons={false}
            >
              <FunctionField
                render={({ effective_date, start_market_id, end_market_id, per_worker_price, fixed_price_modifier }) => {
                  if (!effective_date || !start_market_id || !end_market_id) {
                    return (
                      <Tooltip title="Missing values">
                        <Error color="error" />
                      </Tooltip>
                    );
                  }
                  if (isProduction && dayjs(effective_date).isBefore(minEffectiveDate)) {
                    return (
                      <Tooltip title={`Effective Date must be at least ${minEffectiveDate}`}>
                        <Error color="error" />
                      </Tooltip>
                    );
                  }
                  if (start_market_id === end_market_id) {
                    return (
                      <Tooltip title="Start and End Market should be different">
                        <Error color="error" />
                      </Tooltip>
                    );
                  }
                  if (per_worker_price && per_worker_price < 0) {
                    return (
                      <Tooltip title="Per worker price must be greater or equal to 0">
                        <Error color="error" />
                      </Tooltip>
                    );
                  }
                  if (fixed_price_modifier && fixed_price_modifier < 0) {
                    return (
                      <Tooltip title="Fixed price multiplier must be greater or equal to 0">
                        <Error color="error" />
                      </Tooltip>
                    );
                  }
                  return null;
                }}
              />
              <TextField source="effective_date" />
              <MarketNameField source="start_market_id" />
              <MarketNameField source="end_market_id" />
              <CurrencyField source="base_price" />
              <CurrencyField source="per_worker_price" />
              <NumberField source="fixed_price_modifier" />
            </Datagrid>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CsvInput;
