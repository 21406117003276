import React from 'react';

import { string } from 'prop-types';
import { usePermissions } from 'react-admin';
import { useNavigate } from 'react-router';

import {
  AlignVerticalBottom,
  Description,
  LocationCity,
  Paid,
  RequestPage,
  RequestQuote,
  Support,
  Tune,
} from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';

import { PRO_ACCOUNT_TAB } from '@/constants/prosAccount';

const ProsTabs = ({ currentTab }) => {
  const { permissions } = usePermissions();

  const canViewZendesk = permissions?.tools?.hqadmin?.zendesk_support?.view;

  const tabs = [
    { id: PRO_ACCOUNT_TAB.DETAILS, icon: <Description /> },
    { id: PRO_ACCOUNT_TAB.SKILLS, icon: <RequestPage /> },
    {
      id: PRO_ACCOUNT_TAB.TRANSACTIONS,
      icon: <Paid />,
    },
    { id: PRO_ACCOUNT_TAB.JOB_HISTORY, icon: <LocationCity /> },
    { id: PRO_ACCOUNT_TAB.AVAILABILITY, icon: <Tune /> },
    { id: PRO_ACCOUNT_TAB.PERFORMANCE, icon: <AlignVerticalBottom /> },
    { id: PRO_ACCOUNT_TAB.PAYOUT_CONFIG, icon: <RequestQuote /> },
    ...(canViewZendesk ? [{ id: PRO_ACCOUNT_TAB.SUPPORT, icon: <Support /> }] : []),
  ];

  const navigate = useNavigate();

  return (
    <Tabs
      value={currentTab}
      onChange={(e, newTab) => {
        navigate(newTab);
      }}
      sx={{
        borderBottom: ({ palette }) => `1px solid ${palette.mode === 'dark' ? palette.neutral.dark : palette.neutral.light}`,
        '& .MuiTab-root': {
          textTransform: 'capitalize',
          minHeight: 0,
        },
      }}
    >
      {tabs.map(({ id, icon }, index) => (
        <Tab
          id={`pros-details-tab-${index}`}
          key={id}
          label={id.replace('-', ' ')}
          value={id}
          icon={icon}
          iconPosition="start"
          aria-controls={`pros-details-tabpanel-${index}`}
        />
      ))}
      ;
    </Tabs>
  );
};

ProsTabs.propTypes = {
  currentTab: string.isRequired,
};

export default ProsTabs;
