export const SETTLEMENT_COVERAGE_TYPES = {
  STANDARD_RELEASE_VALUE: {
    id: 'STANDARD_RELEASE_VALUE',
    name: 'Standard Release Value',
  },
  ACTUAL_CASH_VALUE: { id: 'ACTUAL_CASH_VALUE', name: 'Actual Cash Value' },
  REPLACEMENT_VALUE: { id: 'REPLACEMENT_VALUE', name: 'Replacement Value' },
};

export const SETTLEMENT_REASONS = {
  ITEMS_DAMAGED: { id: 'ITEMS_DAMAGED', name: 'Items / Goods Damage' },
  STRUCTURAL_DAMAGE: { id: 'STRUCTURAL_DAMAGE', name: 'Structural Damage' },
  EXTERIOR_LAWN_DAMAGE: { id: 'EXTERIOR_LAWN_DAMAGE', name: 'Exterior / Lawn Damage' },
  VEHICLE_DAMAGE: { id: 'VEHICLE_DAMAGE', name: 'Vehicle Damage' },
  MISSING_ITEMS: { id: 'MISSING_ITEMS', name: 'Missing Items / Goods' },
};

export const SETTLEMENT_PARTY_AT_FAULT = {
  HOM: { id: 'HOM', name: 'HOM' },
  NONE: { id: 'NONE', name: 'None' },
  ALL: { id: 'ALL', name: 'All' },
};

export const SETTLEMENT_PAYMENT_METHODS = {
  REFUND: { id: 'Refund', name: 'Refund' },
  CHECK: { id: 'Check', name: 'Check' },
};
