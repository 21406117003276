import React from 'react';

import mx from 'mixpanel-browser';
import { useDataProvider, useListContext, useNotify, useShowContext } from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, Chip, Divider, IconButton, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import formatCurrency from '@/utils/currency/formatCurrency';

import getProductName from '@/utils/productName/getProductName';
import { Add, Edit, RemoveCircleOutlined } from '@mui/icons-material';

const Products = () => {
  const { record: { id: quoteId } = {}, refetch } = useShowContext();
  const { data: productsData } = useListContext();
  const navigate = useNavigate();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteId}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Product removed', {
      quoteId,
    });
    refetch();
    navigateOverview();
    notify('Quote updated', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error removing Product', {
      quoteId,
      error,
    });
    notify(`Error occurred removing product on quote - ${error.message}`, { type: 'error' });
  };

  const removeProduct = (productId) => {
    const params = {
      data: [
        {
          op: 'remove',
          path: `/products/${productId}`,
        },
      ],
      id: quoteId,
      meta: {
        operationPatch: true,
      },
    };
    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  if (!productsData) return null;

  return (
    <Card component={Box} mb={2}>
      <CardHeader title="Products" />
      <Divider />
      <CardContent>
        {productsData?.length ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {productsData.map((product) => (
              <Box key={product.id}>
                <Chip label={`${getProductName(product.product_id)} - ${formatCurrency(product.estimated_unit_price)}`} />
                <IconButton color="primary" title="Edit Product" onClick={() => navigate(`edit/edit-product/${product.id}`)}>
                  <Edit />
                </IconButton>
                <IconButton color="accent" title="Remove Product" onClick={() => removeProduct(product.id)}>
                  <RemoveCircleOutlined />
                </IconButton>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography>No products on quote</Typography>
        )}
      </CardContent>
      <Divider />
      <CardContent>
        <Button startIcon={<Add />} variant="outlined" onClick={() => navigate(`edit/add-product`)}>
          Add Product
        </Button>
      </CardContent>
    </Card>
  );
};

export default Products;
