import { MARKET_STATUS } from '@/constants/market';
import { QUOTE_NON_PARTNER_TEMPLATES, QUOTE_TEMPLATE_UNAVAILABLE_REASONS } from '@/constants/quoteTemplates';

const zippyCorpReloTemplates = ({ locations, marketsData, uniqueMarkets }) => {
  if (!locations || locations?.length <= 1) {
    // No locations or one location, template unavailable
    return [
      {
        ...QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE,
        available: false,
        reasons: [
          QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS,
          QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS,
        ],
      },
    ];
  }

  if (marketsData?.every((market) => market.status === MARKET_STATUS.INACTIVE.id)) {
    // All markets inactive, all templates unavailable
    return [
      {
        ...QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.INACTIVE_MARKET],
      },
    ];
  }

  if (uniqueMarkets?.length <= 1) {
    // Single market, template unavailable
    return [
      {
        ...QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS],
      },
    ];
  }

  return [
    {
      ...QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE,
      available: true,
    },
  ];
};

export default zippyCorpReloTemplates;
