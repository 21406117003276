import React from 'react';

import mx from 'mixpanel-browser';
import {
  BooleanInput,
  DeleteButton,
  EditBase,
  Form,
  RecordContextProvider,
  SaveButton,
  SelectInput,
  useEditContext,
  useNotify,
  useRedirect,
  useReference,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Button, Card, CardContent, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';
import getServiceName from '@/utils/serviceName/getServiceName';

import CarrierHeader from '../../Show/CarrierHeader';

const SkillForm = () => {
  const { accountId, accountProductId } = useParams();
  const { isLoading, isFetching } = useEditContext();
  const refresh = useRefresh();
  const redirect = useRedirect();

  if (isLoading || isFetching)
    return (
      <Box display="flex" justifyContent="center" py={4} width="100%">
        <CircularProgress size={100} />
      </Box>
    );

  return (
    <Box display="flex" mt={3}>
      <Card component={Box} minWidth={400}>
        <Box px={3} py={2} display="flex" justifyContent="space-between" gap={4}>
          <Typography variant="h5">Edit Skill - {getServiceName(accountProductId)}</Typography>
          <DeleteButton
            label="Remove"
            icon={null}
            resource={resources.CARRIERS}
            id={accountId}
            confirmTitle={`Delete ${getServiceName(accountProductId)} Skill`}
            mutationOptions={{
              meta: {
                subResource: 'account_product',
                resourceId: accountProductId,
              },
              onSuccess: () => {
                refresh();
                redirect(`/${resources.CARRIERS}/${accountId}/show/${CARRIER_ACCOUNT_TAB.SKILLS}`);
              },
            }}
          />
        </Box>

        <Divider />

        <Form>
          <CardContent>
            <SelectInput
              required
              source="qualification_level"
              label="Qualification"
              choices={[
                { id: '0', name: 'Recruit' },
                { id: '1', name: 'Standard' },
                { id: '2', name: 'Lead' },
                { id: '3', name: 'Captain' },
              ]}
              validate={[]}
            />
            <BooleanInput source="skip_autoassign" label="Skip auto-assign" defaultChecked={false} />
          </CardContent>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="neutral"
                onClick={() => redirect(`/${resources.CARRIERS}/${accountId}/show/${CARRIER_ACCOUNT_TAB.SKILLS}`)}
              >
                Cancel
              </Button>
              <SaveButton label="Update" icon={null} />
            </Box>
          </CardContent>
        </Form>
      </Card>
    </Box>
  );
};

const CarrierSkillsEdit = () => {
  const { accountId, accountProductId } = useParams();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();

  const { referenceRecord: account } = useReference({ reference: resources.CARRIERS, id: accountId });

  const transform = (data) => ({
    qualification_level: data.qualification_level,
    skip_autoassign: data.skip_autoassign,
  });

  const onSuccess = (data) => {
    mx.track('Resource - Updated', {
      resource: resources.THIRD_PARTY_CREW,
      resourceId: accountId,
      metadata: {
        subResource: 'skills',
        skill: accountProductId,
        qualificationLevel: data.qualification_level,
        skipAutoAssign: data.skip_autoassign,
      },
    });
    notify(`${getServiceName(accountProductId)} Skill updated`, { type: 'success' });
    refresh();
    redirect(`/${resources.CARRIERS}/${accountId}/show/${CARRIER_ACCOUNT_TAB.SKILLS}`);
  };

  if (!account) return null;

  return (
    <>
      <Box mt={3}>
        <RecordContextProvider value={account}>
          <CarrierHeader />
        </RecordContextProvider>
      </Box>
      <EditBase
        resource={resources.CARRIERS}
        id={accountId}
        queryOptions={{
          meta: {
            subResource: 'account_product',
            filter: {
              product_id: accountProductId,
            },
          },
        }}
        mutationOptions={{
          meta: {
            method: 'PUT',
            subResource: 'account_product',
            subResourceId: accountProductId,
            fullData: true,
          },
          onSuccess,
        }}
        mutationMode="pessimistic"
        transform={transform}
      >
        <SkillForm />
      </EditBase>
    </>
  );
};

export default CarrierSkillsEdit;
