import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, SelectInput, TextInput, useDelete, useNotify, useRefresh, useShowContext } from 'react-admin';

import { Warning } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

const DeleteJobSlotForm = () => {
  const { setAction } = useContext(OrderActionContext);

  return (
    <>
      <Box p={2}>
        <SelectInput
          source="reason"
          choices={[{ id: 'Manual Removal', name: 'Manual Removal' }]}
          defaultValue="Manual Removal"
          disabled
          fullWidth
          required
          variant="outlined"
        />
        <Box>
          <TextInput source="details" multiline rows={4} variant="outlined" fullWidth required />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const DeleteJobSlot = () => {
  const { metadata, setAction, setMetadata } = useContext(OrderActionContext);
  const {
    record: { confirmation_id: confirmationId },
  } = useShowContext();
  const [deleteOne] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    mx.track(`Order Management - Error removing Job Slot`, {
      resource: resources.JOB_SLOTS,
      resourceId: metadata?.job_slot_id,
      confirmationId,
      error,
    });
    notify(`Removing Job Slot failed - ${error.status} - ${error.message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track(`Order Management - Remove Job Slot`, {
      resource: resources.JOB_SLOTS,
      resourceId: metadata?.job_slot_id,
      confirmationId,
    });

    notify('Job Slot Removed', {
      type: 'success',
    });
    refresh();
    setAction(null);
    setMetadata(null);
  };

  const handleSubmit = ({ reason, details }) => {
    deleteOne(
      resources.JOB_SLOTS,
      {
        id: metadata?.job_slot_id,
        meta: {
          data: {
            removal_reason: reason,
            removal_details: details,
          },
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={500}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Remove Job Slot
      </Typography>
      <Divider />
      <Box display="flex" gap={2} py={2}>
        <Warning color="warning" />
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            Removing a job slot manually will not create a replacement job slot, unassign the job slot instead if you intend
            to assign a new worker.
          </Typography>
          <Typography>
            The hourly price{' '}
            <strong>
              <u>will NOT</u>
            </strong>{' '}
            decrease on the service.
          </Typography>
          <Typography>
            Add a price adjustment on the Invoice tab if you wish to reduce the amount owed by the customer for this removal.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <DeleteJobSlotForm />
        </Form>
      </Box>
    </Box>
  );
};

export default DeleteJobSlot;
