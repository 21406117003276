const getQualificationLevel = (qualificationLevelNumber) => {
  switch (qualificationLevelNumber?.toString()) {
    case '3':
      return 'Captain';
    case '2':
      return 'Lead';
    case '1':
      return 'Standard';
    case '0':
      return 'Recruit';
    default:
      return 'Unknown';
  }
};

export default getQualificationLevel;
