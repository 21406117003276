import React from 'react';
import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import TextFieldWrapper from '@/fields/TextFieldWrapper';

const LocationFormatField = ({ street1, street2, city, state, zip, textFieldProps }) => {
  const record = useRecordContext();
  if (!record) return null;

  const locObj = {
    street1: record[street1],
    street2: record[street2],
    city: record[city],
    state: record[state],
    zip: record[zip],
  };

  const address2 = locObj.street2 ? `${locObj.street2},` : '';

  const address = `${locObj.street1}, ${address2} ${locObj.city} ${locObj.state}, ${locObj.zip}`;

  return <TextFieldWrapper value={address} textFieldProps={textFieldProps} />;
};

LocationFormatField.propTypes = {
  city: string.isRequired,
  state: string.isRequired,
  street1: string.isRequired,
  zip: string.isRequired,
  street2: string,
  textFieldProps: shape({}),
};

LocationFormatField.defaultProps = {
  textFieldProps: {},
  street2: '',
};

export default LocationFormatField;
