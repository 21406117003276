import React, { useContext } from 'react';

import { Form, NumberInput, TextInput, useCreate, useNotify, useRefresh } from 'react-admin';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JobSlotActionContext } from '@/providers/JobSlotAction';

const ReverseTransfer = () => {
  const { metadata, setAction, setMetadata } = useContext(JobSlotActionContext);
  const [create, { isLoading: isCreating }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Reverse transfer transaction failed - ${error.status} - ${error?.body?.detail ?? error.message}`, {
        type: 'error',
      });
    } else if (data) {
      notify(`Reverse transfer transaction is completed!`, {
        type: 'success',
      });
      refresh();
      setMetadata(null);
      setAction(null);
    }
  };

  const handleSubmit = ({ description, amount }) => {
    create(
      `${resources.JOB_SLOTS}/${metadata.job_slot_id}/transactions/${metadata.id}/reverse`,
      {
        data: { description, amount: 0 - amount },
      },
      { onSettled },
    );
  };

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <Box px={6} py={4} minWidth={400}>
      <Typography variant="h4" component={Box} pb={4}>
        Reverse Transfer
      </Typography>
      <Box>
        <Box mt={2}>Do you want to reverse the transfer transaction?</Box>
        <Form onSubmit={handleSubmit}>
          <TextInput source="description" required fullWidth multiline />
          <NumberInput source="amount" format={formatValue} parse={parseValue} required fullWidth min={0} />
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="neutral"
              onClick={() => {
                setMetadata(null);
                setAction(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isCreating ?? null}
              endIcon={isCreating ? <CircularProgress size={18} /> : null}
            >
              Submit
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

ReverseTransfer.propTypes = {};

export default ReverseTransfer;
