import React from 'react';

import { ChipField, Datagrid, FunctionField, TextField, useShowContext } from 'react-admin';

import { Box, IconButton, Tooltip } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import DayJsField from '@/fields/DayJsField';
import UserField from '@/fields/UserField';
import ZendeskIcon from '@/shared/ZendeskIcon';
import EmptyResourceList from '@/shared/EmptyResourceList';

const OperatorNotes = () => {
  const {
    record: { id: orderId },
  } = useShowContext();

  return (
    <List
      resource={resources.ORDERS}
      queryOptions={{
        meta: {
          subResource: 'operator_notes',
          resourceId: orderId,
        },
      }}
      sort={{
        field: 'timestamp',
        order: 'DESC',
      }}
      storeKey={`${resources.ORDERS}.operatorNotes.listParams`}
      actions={false}
      disableSyncWithLocation
      empty={<EmptyResourceList text="No Operator Notes" />}
    >
      <Datagrid bulkActionButtons={false}>
        <UserField source="user_id" clipboardUserSource="email" />
        <DayJsField source="timestamp" format={`MM/DD/YYYY\nh:mm A`} />
        <TextField source="notes" sortable={false} whiteSpace="pre-wrap" textOverflow="ellipsis" />
        <FunctionField
          label="Ticket"
          sortable={false}
          render={({ zendesk_ticket: ticketNumber }) =>
            ticketNumber ? (
              <Box display="flex" gap={0.5} alignItems="center">
                <Tooltip title="Open Zendesk ticket" placement="top">
                  <IconButton
                    size="small"
                    href={`${process.env.REACT_APP_ZENDESK_TICKET_URI}/${ticketNumber}`}
                    target="_blank"
                  >
                    <Box width={20} height={20} display="flex" alignItems="center">
                      <ZendeskIcon />
                    </Box>
                  </IconButton>
                </Tooltip>
                <TextField source="zendesk_ticket" />
              </Box>
            ) : null
          }
        />
        <ChipField source="domain" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default OperatorNotes;
