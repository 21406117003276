import { MARKET_BELLHOP_NATION, MARKET_STATUS } from '@/constants/market';
import { QUOTE_PARTNER_TEMPLATES, QUOTE_TEMPLATE_UNAVAILABLE_REASONS } from '@/constants/quoteTemplates';

const allUnavailable = [
  {
    ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
    available: false,
  },
  {
    ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
    available: false,
  },
  {
    ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
    available: false,
  },
];

const podsTemplates = ({ locations, marketsData, uniqueMarkets }) => {
  if (!locations || locations?.length === 0) {
    // No locations, all templates unavailable
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS],
      },
    ];
  }

  if (uniqueMarkets?.length === 1 && uniqueMarkets.includes(MARKET_BELLHOP_NATION)) {
    // Only one market and it is bellhop nation, all templates unavailable
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.BELLHOP_NATION_MARKET],
    }));
  }

  if (locations?.length > 2) {
    // Greater than 2 locations, none available
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.TWO_LOCATIONS],
      },
    ];
  }

  if (marketsData?.every((market) => market.status === MARKET_STATUS.INACTIVE.id)) {
    // All markets inactive, all templates unavailable
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.INACTIVE_MARKET],
    }));
  }

  if (marketsData?.every((market) => market.is_pod_available)) {
    if (locations?.length === 1) {
      // One location only Loading or Unloading only
      return [
        {
          ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
          available: true,
        },
        {
          ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
          available: true,
        },
        {
          ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
          available: false,
          reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS],
        },
      ];
    }

    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
        available: true,
      },
    ];
  }

  // Special case - not all markets are PODS available
  // we allow PODS unloading in ALL markets
  if (locations?.length === 1) {
    // Only one location and not PODS available, only unloading available
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.PODS_MARKET],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
        available: true,
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.PODS_MARKET],
      },
    ];
  }

  if (locations?.length === 2) {
    // One market is PODS available, the other is not, allow loading/unloading
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING,
        available: true,
        reasons: ['Special case - ensure the non PODS available location is listed second in the list above'],
      },
    ];
  }

  // Fallback - should never reach
  return allUnavailable.map((template) => ({
    ...template,
    reasons: ['Unable to determine available services'],
  }));
};

export default podsTemplates;
