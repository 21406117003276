import React from 'react';

import { Datagrid, ReferenceInput, SelectArrayInput, SelectInput, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import { CUSTOMER_STATUS } from '@/constants/customerAccount';
import { ACCOUNT_TAG_CHOICES } from '@/constants/tags';
import MarketNameField from '@/fields/MarketNameField';
import CustomerSectionHeader from '@/resources/customers/List/Common/SectionHeader';
import EmptyResourceList from '@/shared/EmptyResourceList';

const CustomerFilter = [
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
  <TextInput source="email" alwaysOn resettable autoComplete="off" />,
  <TextInput source="phone" alwaysOn resettable autoComplete="off" />,
  <SelectInput source="account_status" label="Status" alwaysOn choices={CUSTOMER_STATUS} />,
  <ReferenceInput source="market" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <TextInput source="id" label="ID" autoComplete="off" />,
  <SelectArrayInput source="tags" choices={ACCOUNT_TAG_CHOICES} />,
];

const CustomerList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2}>
      <CustomerSectionHeader title="Customers" />
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.ACCOUNTS}
      filters={CustomerFilter}
      filter={{
        account_type: ['Customer'],
      }}
      exporter={false}
      hasCreate={false}
      empty={<EmptyResourceList text="No Customer accounts" />}
      storeKey={`${resources.CUSTOMERS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick={(id) => `/customers/${id}/show`}>
        <TextField source="name" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="status" sortable={false} />
        <MarketNameField source="primary_market" sortable={false} />
      </Datagrid>
    </List>
  </Card>
);

export default CustomerList;
