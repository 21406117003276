import React from 'react';

import { EditBase, useRedirect, useRefresh } from 'react-admin';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import * as resources from '@/api/resources';
import { THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewHeader from '../Show/ThirdPartyCrewHeader';
import ThirdPartyCrewContentDetailsEdit from './Details';

const ThirdPartyCrewAccountEdit = () => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { tab: currentTab, accountId } = useParams();

  return (
    <EditBase
      id={accountId}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          refresh();
          redirect(`/${resources.THIRD_PARTY_CREW}/${accountId}/show`);
        },
      }}
      transform={(data) => ({ ...data, email: data?.email?.toLowerCase() })}
    >
      <Box mt={3}>
        <ThirdPartyCrewHeader />
      </Box>
      <Box display={currentTab === THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS ? 'block' : 'none'}>
        <ThirdPartyCrewContentDetailsEdit />
      </Box>
    </EditBase>
  );
};

export default ThirdPartyCrewAccountEdit;
