import { string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { SLOT_TRANSACTION_FAMILY } from '@/constants/slotTransactions';

const SlotTransactionFamilyField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !record[source]) return null;

  return SLOT_TRANSACTION_FAMILY[record[source]].name;
};

SlotTransactionFamilyField.propTypes = {
  source: string.isRequired,
};

export default SlotTransactionFamilyField;
