import React from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { bool, func, shape } from 'prop-types';

import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';

import formatCurrency from '@/utils/currency/formatCurrency';

dayjs.extend(utc);
dayjs.extend(tz);

const SlotQuote = ({ quote, selectTimeSlot, active }) => {
  const slotTime = dayjs(quote.start_datetime).tz(quote.timezone).format('h A');
  const formattedCost = formatCurrency(quote.total_cost, 0);

  const onClick = () => {
    selectTimeSlot(quote);
  };

  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent
          component={Box}
          sx={{
            borderTop: ({ palette }) => `5px solid ${quote.is_available ? palette.primary.light : palette.accent.light}`,
          }}
          {...(active ? { backgroundColor: 'secondary.dark' } : {})}
        >
          <Typography>{slotTime}</Typography>
          <Typography>{formattedCost}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

SlotQuote.propTypes = {
  quote: shape({}).isRequired,
  selectTimeSlot: func.isRequired,
  active: bool.isRequired,
};

export default SlotQuote;
