import React, { useContext } from 'react';

import { SCRIPT_MODALS } from '@/constants/scriptModals';
import { QuoteContext } from '@/providers/Quote';

import StatePrivacy from '../../shared/scriptPrompts/StatePrivacy';

const ScriptModals = () => {
  const { scriptModalStates, closeScriptModal } = useContext(QuoteContext);

  return (
    <StatePrivacy
      open={scriptModalStates?.[SCRIPT_MODALS.STATE_PRIVACY] ?? false}
      onClose={() => closeScriptModal(SCRIPT_MODALS.STATE_PRIVACY)}
    />
  );
};

export default ScriptModals;
