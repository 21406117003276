import { LabeledClasses } from 'react-admin';

export default () => ({
  styleOverrides: {
    root: {
      [`& .${LabeledClasses.label}.MuiTypography-root`]: {
        fontSize: '0.8em',
      },
      [`& .MuiTypography-root`]: {
        fontSize: '1em',
      },
    },
  },
});
