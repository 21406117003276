const ORDER_STATUS = {
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  CREATED: 'Created',
  DRAFT: 'Draft',
};

const ORDER_CANCELLABLE_STATUSES = ['CREATED'];

const ORDER_COMPLETABLE_STATUSES = ['CREATED', 'COMPLETED'];

const ORDER_RESCHEDULABLE_STATUSES = ['CREATED'];

export { ORDER_STATUS, ORDER_CANCELLABLE_STATUSES, ORDER_COMPLETABLE_STATUSES, ORDER_RESCHEDULABLE_STATUSES };
