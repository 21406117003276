import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import { MenuItem, Select } from '@mui/material';

import * as Resource from '@/api/resources';
import { WORKER_TYPE } from '@/constants/supplyinventory';

const SelectNavigationField = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('0');

  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    switch (selectedOption) {
      case WORKER_TYPE[0].id:
        navigate(`/${Resource.SUPPLYINVENTORY}/create/lead`);
        break;
      case WORKER_TYPE[1].id:
        navigate(`/${Resource.SUPPLYINVENTORY}/create/standard`);
        break;
      case WORKER_TYPE[2].id:
        navigate(`/${Resource.SUPPLYINVENTORY}/create/transit`);
        break;
      default:
        break;
    }

    return undefined;
  };

  if (!selectedValue) return null;
  return (
    <Select value="0" onChange={handleChange} variant="filled">
      <MenuItem value="0">Select an option</MenuItem>
      <MenuItem value="1">Lead</MenuItem>
      <MenuItem value="2">Standard</MenuItem>
      <MenuItem value="3">Transit</MenuItem>
    </Select>
  );
};

export default SelectNavigationField;
