import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, RecordContextProvider, useCreate, useGetList, useNotify, useRedirect, useShowContext } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import ServiceNameField from '@/fields/ServiceNameField';
import { OrderActionContext } from '@/providers/OrderAction';
import { bool } from 'prop-types';

const RescheduleOrderForm = ({ isCreating }) => {
  const {
    record: { id: orderId },
  } = useShowContext();
  const { setAction } = useContext(OrderActionContext);

  const { data: completedJobsData, isLoading: isCompletedJobsLoading } = useGetList(
    resources.JOBS,
    {
      filter: {
        order_id: orderId,
        status: ['COMPLETE'],
      },
    },
    {
      enabled: Boolean(orderId),
    },
  );

  return (
    <>
      <Box p={2}>
        <Typography>
          The following services have been completed, they will be locked on the quote, no details for these services can be
          edited on the quote.
        </Typography>
      </Box>
      <Divider />
      <Box>
        {isCompletedJobsLoading ? (
          <CircularProgress />
        ) : (
          completedJobsData?.map((job) => (
            <Box display="flex" gap={2} px={4} py={2}>
              <RecordContextProvider value={job}>
                <ServiceNameField source="product_id" />
                <PrefDateTimeField
                  source="start_date_time"
                  timezoneSource="start_timezone"
                  dayjsFormat="MMM D, YYYY @ h:mm A"
                />
              </RecordContextProvider>
            </Box>
          ))
        )}
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isCreating ?? null}
          startIcon={isCreating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

RescheduleOrderForm.propTypes = {
  isCreating: bool.isRequired,
};

const RescheduleOrder = () => {
  const { setAction } = useContext(OrderActionContext);
  const {
    record: { id: orderId, confirmation_id: confirmationId },
  } = useShowContext();
  const [create, { isLoading: isCreating }] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const { data: completedJobsData, isLoading: isCompletedJobsLoading } = useGetList(
    resources.JOBS,
    {
      filter: {
        order_id: orderId,
        status: ['COMPLETE'],
      },
    },
    {
      enabled: Boolean(orderId),
    },
  );

  const onError = (error) => {
    let { message } = error;

    if (error.body?.detail) {
      message = error.body.detail;
    }

    mx.track('Order Management - Quote - Error creating Quote from Order', {
      orderId,
      partiallyCompletedOrder: true,
      error,
    });

    notify(`Error occurred while creating quote from order - ${error.status} - ${message}`, {
      type: 'error',
      multiLine: true,
    });
  };

  const onSuccess = (data) => {
    mx.track(`Order Management - Quote - Quote created from Order`, {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
      partiallyCompletedOrder: true,
    });

    setAction(null);
    redirect(`/quotes/${data.id}/edit?orderId=${orderId}`);
  };

  const handleSubmit = () => {
    create(
      resources.QUOTES,
      {
        data: {
          order_id: orderId,
          lock_service_ids: completedJobsData?.map((job) => job.id),
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} maxWidth={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Reschedule Order
      </Typography>
      <Divider />
      <Box>
        {isCompletedJobsLoading ? (
          <CircularProgress />
        ) : (
          <Form onSubmit={handleSubmit}>
            <RescheduleOrderForm isCreating={isCreating} />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default RescheduleOrder;
