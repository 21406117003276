import React, { useContext } from 'react';
import { usePermissions, useShowContext } from 'react-admin';

import { Box, Button } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';
import { ORDER_COMPLETABLE_STATUSES } from '@/constants/orderStatus';

const CompleteAction = () => {
  const { setAction } = useContext(OrderActionContext);
  const { permissions } = usePermissions();

  const {
    record: { status: orderStatus },
  } = useShowContext();

  const canCompleteOrderPerm = permissions?.tools?.hqadmin?.orders?.complete;
  const isOrderStatusCompletable = ORDER_COMPLETABLE_STATUSES.includes(orderStatus);

  return canCompleteOrderPerm && isOrderStatusCompletable ? (
    <Box display="flex" justifyContent="center" mt={2}>
      <Button variant="outlined" color="primary" onClick={() => setAction('complete_order')}>
        Complete Order
      </Button>
    </Box>
  ) : null;
};

export default CompleteAction;
