import React, { useState } from 'react';

import { string } from 'prop-types';
import { useRecordContext, useRedirect } from 'react-admin';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import * as resources from '@/api/resources';

const ConnectedAccountSectionHeader = ({ title }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const redirect = useRedirect();
  const record = useRecordContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = () => {
    redirect('edit', resources.ADMIN_CONNECTED_ACCOUNTS, record.id);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
      <Typography variant="h5">{title}</Typography>

      <>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu id="iconmenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleClickMenuItem}>Edit details</MenuItem>
        </Menu>
      </>
    </Box>
  );
};

ConnectedAccountSectionHeader.propTypes = {
  title: string.isRequired,
};

export default ConnectedAccountSectionHeader;
