import React, { useState } from 'react';

import { string } from 'prop-types';
import { usePermissions } from 'react-admin';
import { useNavigate } from 'react-router';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import * as resources from '@/api/resources';

const MarketSectionHeader = ({ title }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const { permissions } = usePermissions();
  const canCreateMarket = permissions?.tools?.hqadmin?.markets?.create;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateMarket = () => {
    navigate(`/${resources.MARKETS}/create`);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">{title}</Typography>

      {canCreateMarket ? (
        <>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu id="iconmenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleCreateMarket}>Create new Market</MenuItem>
          </Menu>
        </>
      ) : null}
    </Box>
  );
};

MarketSectionHeader.propTypes = {
  title: string.isRequired,
};

export default MarketSectionHeader;
