import dayjs from 'dayjs';
import { bool, shape, string } from 'prop-types';
import React from 'react';
import { useRecordContext } from 'react-admin';
import TextFieldWrapper from '../TextFieldWrapper';

// OneIndexed prop prseent to account for different date standards between Python/JS
// Python uses January = 1, December = 12
// DayJs uses January = 0, December = 11
const MonthOfYearField = ({ source, oneIndexed, textFieldProps }) => {
  const record = useRecordContext();

  if (!record || !record[source]) return null;

  const dayjsObj = dayjs().month(oneIndexed ? record[source] - 1 : record[source]);

  return <TextFieldWrapper textFieldProps={{ whiteSpace: 'pre-wrap', ...textFieldProps }} value={dayjsObj.format('MMMM')} />;
};

MonthOfYearField.propTypes = {
  source: string.isRequired,
  oneIndexed: bool,
  textFieldProps: shape({}),
};

MonthOfYearField.defaultProps = {
  oneIndexed: false,
  textFieldProps: {},
};

export default MonthOfYearField;
