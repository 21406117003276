import React, { useEffect } from 'react';

import mx from 'mixpanel-browser';

import { Admin, CustomRoutes, Resource, usePermissions, useRedirect } from 'react-admin';
import { Route } from 'react-router';

import { Box, Card, CardContent, Typography } from '@mui/material';

import MyLayout from '@/ReactAdmin/Layout';
import Login from '@/ReactAdmin/Login';
import queryClient from '@/ReactAdmin/queryClient';
import store from '@/ReactAdmin/store';
import dataProvider from '@/api/dataProvider';
import * as resources from '@/api/resources';
import { ADMIN_RESOURCES, STANDARD_RESOURCES } from '@/components/resources';
import CreateAvailabilityConfiguration from '@/resources/availabilityConfiguration';
import SupplyInventoryCreate from '@/resources/supplyinventory/Create/';
import SupplyInventoryCreateSection from '@/resources/supplyinventory/Create/Pages';
import authProvider from './authProvider';
import { darkTheme, lightTheme } from './theme';

mx.init(process.env.REACT_APP_MIXPANEL_KEY, process.env.NODE_ENV === 'production' ? {} : { debug: true });

const TempDashboard = () => {
  // No Fulfillment Dashboard use cases yet - if user has permissions to view tool they will be redirected to the Orders view
  // If they do not have permissions they will receive the no access message.
  // Future State: Add summary views/cards, high level graphs of fulfillment status, stop redirecting to Orders List
  const { permissions } = usePermissions();
  const redirect = useRedirect();

  useEffect(() => {
    if (permissions?.tools?.fulfillment?.view) {
      // User has permissions, send to orders list
      redirect(`/${resources.ORDERS}?view=fulfillment`);
    }
  }, [permissions]);

  return permissions?.tools?.fulfillment?.view ? null : (
    <Card component={Box} mt={4}>
      <CardContent>
        <Typography pt={2} textAlign="center" variant="h6">
          You do not have access to this tool.
        </Typography>
      </CardContent>
    </Card>
  );
};

const App = () => (
  <Admin
    loginPage={Login}
    authProvider={authProvider}
    queryClient={queryClient}
    store={store}
    dataProvider={dataProvider()}
    layout={MyLayout}
    theme={lightTheme}
    darkTheme={darkTheme}
    disableTelemetry
    requireAuth
    dashboard={TempDashboard}
  >
    {(permissions) =>
      permissions?.tools?.fulfillment?.view ? (
        <>
          {/* Standard Resources - standard views - may include custom routes - see individual resource definition */}
          {STANDARD_RESOURCES.map((resource) =>
            resource ? <Resource key={resource.name} {...resource(permissions)} /> : null,
          )}

          {ADMIN_RESOURCES.map((resource) =>
            resource ? <Resource key={resource.name} {...resource(permissions)} /> : null,
          )}

          {/* Resources only used in nested or reference views */}
          <Resource name={resources.LOCATIONS} />
          <Resource name={resources.ELIGIBLE_LABOR} />
          <Resource name={resources.ELIGIBLE_TRANSIT} />
          <Resource name={resources.FULFILLMENT_STATUS} />

          <Resource name={resources.AVAILABILITY_CONFIGURATION} create={<CreateAvailabilityConfiguration />} />

          <CustomRoutes>
            {permissions.tools?.hqadmin?.supply_growth_inventory?.create ? (
              <>
                <Route path="/supply_inventory/create" element={<SupplyInventoryCreate />} />
                <Route path="/supply_inventory/create/:section" element={<SupplyInventoryCreateSection />} />
              </>
            ) : null}
          </CustomRoutes>
        </>
      ) : null
    }
  </Admin>
);

export default App;
