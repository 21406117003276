import React from 'react';

import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { MARKET_TAB } from '@/constants/market';

import MarketDetailsEdit from './Details';
import MarketPerformanceThresholdsEdit from './PerformanceThresholds';
import MarketTravelPayEdit from './TravelPay';

const MarketEdit = () => {
  const { tab: currentTab } = useParams();

  return (
    <>
      <Box display={currentTab === MARKET_TAB.DETAILS ? 'block' : 'none'}>
        <MarketDetailsEdit />
      </Box>
      <Box display={currentTab === MARKET_TAB.PERFORMANCE_THRESHOLDS ? 'block' : 'none'}>
        <MarketPerformanceThresholdsEdit />
      </Box>
      <Box display={currentTab === MARKET_TAB.TRAVEL_PAY ? 'block' : 'none'}>
        <MarketTravelPayEdit />
      </Box>
    </>
  );
};

export default MarketEdit;
