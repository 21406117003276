import * as resources from '@/api/resources';

const getAccountResourceType = ({ account_record_type_name: recordTypeName } = {}) => {
  if (!recordTypeName) return null;

  let resourceFlag;

  switch (recordTypeName) {
    case 'Third Party Crew':
      resourceFlag = resources.THIRD_PARTY_CREW;
      break;
    case 'Third Party Worker':
      resourceFlag = resources.THIRD_PARTY_WORKER;
      break;
    case 'Carrier':
    case 'Multi-ID Carrier Child':
    case 'Multi-ID Carrier Parent':
    case 'Single-ID Carrier':
      resourceFlag = resources.CARRIERS;
      break;
    case 'Bellhop':
    default:
      resourceFlag = resources.ACCOUNTS;
  }

  return resourceFlag;
};

export default getAccountResourceType;
