import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import { CUSTOMER_ACCOUNT_TAB, CUSTOMER_CHARGES_SECTION } from '@/constants/customerAccount';

import CustomerSectionHeader from '../../../Common/SectionHeader';

const CustomerChargesSectionHeader = ({ tab, section, title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const paymentmethodMenuItem = [
    ...(permissions.tools?.hqadmin?.customer_payment_method?.create
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CUSTOMERS}/${record?.id}/${tab}/${section}/edit`);
            },
            component: 'Add Payment Method',
          },
        ]
      : []),
  ];

  switch (section) {
    case CUSTOMER_CHARGES_SECTION.PAYMENT_METHOD:
      contextMenuItems = paymentmethodMenuItem;
      break;
    case CUSTOMER_CHARGES_SECTION.HISTORY_OF_CHARGES:
      break;
    default:
      break;
  }

  return <CustomerSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

CustomerChargesSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

CustomerChargesSectionHeader.defaultProps = {
  tab: CUSTOMER_ACCOUNT_TAB.DETAILS,
};
export default CustomerChargesSectionHeader;
