import React from 'react';

import { useParams } from 'react-router';

import { MARKET_DETAIL_SECTION } from '@/constants/market';

import MarketDetailsPrimaryInformationEdit from './PrimaryInformation';

const MarketDetailsEdit = () => {
  const { section: currentSection } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case MARKET_DETAIL_SECTION.PRIMARY_INFORMATION:
      EditComponent = <MarketDetailsPrimaryInformationEdit />;
      break;
    default:
      break;
  }

  return EditComponent;
};

export default MarketDetailsEdit;
