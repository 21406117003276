import React from 'react';

import { usePermissions, useRecordContext } from 'react-admin';
import { string } from 'prop-types';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';

import PromoCodeSectionHeader from '../../../Common/SectionHeader';

const PromoCodeDetailsSectionHeader = ({ title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const contextMenuItems = [
    ...(permissions.tools?.hqadmin?.promo_codes?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.PROMO_CODES}/${record?.id}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  return <PromoCodeSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

PromoCodeDetailsSectionHeader.propTypes = {
  title: string.isRequired,
};

export default PromoCodeDetailsSectionHeader;
