import React from 'react';

import { arrayOf, element, func, oneOfType, shape, string } from 'prop-types';

import { Box, Typography } from '@mui/material';

import ContextMenuButton from '@/shared/ContextMenuButton';

const CustomerSectionHeader = ({ title, contextMenuItems }) => (
  <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h5">{title}</Typography>

    <ContextMenuButton contextMenuItems={contextMenuItems} />
  </Box>
);

CustomerSectionHeader.propTypes = {
  title: string.isRequired,
  contextMenuItems: arrayOf(shape({ key: string, onClick: func, component: oneOfType([string, element]) })).isRequired,
};

export default CustomerSectionHeader;
