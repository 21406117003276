import React from 'react';

import { string } from 'prop-types';
import { Button, CreateBase, Form, SaveButton, TextInput } from 'react-admin';
import { useNavigate, useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';

const FormControls = ({ crewCode }) => {
  const navigate = useNavigate();
  const path = `/${resources.THIRD_PARTY_CREW}/${crewCode}/show/crew-leads`;

  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Button label="Cancel" icon={null} color="secondary" onClick={() => navigate(path)} />
      <SaveButton label="Create" icon={null} />
    </Box>
  );
};

FormControls.propTypes = {
  crewCode: string.isRequired,
};

const ThirdPartyWorkerAccountCreate = () => {
  const params = useParams();
  const { crew_code: crewCode } = params;

  const transform = (data) => ({
    ...data,
    crewCode,
    email: data?.email?.toLowerCase(),
  });

  return (
    <CreateBase transform={transform} redirect="show">
      <Card mt={3} component={Box}>
        <CardHeader title="Create New Third Party Worker Account" />

        <Divider />

        <CardContent>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput source="name" label="Name" required fullWidth />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="phone" label="Phone number" required fullWidth />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="email" label="Email address" required fullWidth />
              </Grid>
            </Grid>

            <Divider />
            <FormControls crewCode={crewCode} />
          </Form>
        </CardContent>
      </Card>
    </CreateBase>
  );
};
export default ThirdPartyWorkerAccountCreate;
