import React, { useState } from 'react';

import { string } from 'prop-types';
import { usePermissions } from 'react-admin';
import { useNavigate } from 'react-router';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { PROMO_CODES } from '@/api/resources';

const PromoCodeSectionHeader = ({ title }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const { permissions } = usePermissions();

  const canCreatePromoCode = permissions?.tools?.hqadmin?.promo_codes?.create;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreatePromoCode = () => {
    navigate(`/${PROMO_CODES}/create`);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">{title}</Typography>

      {canCreatePromoCode ? (
        <>
          <IconButton onClick={handleClick} sx={{ marginLeft: 'auto' }}>
            <MoreVert />
          </IconButton>
          <Menu id="iconmenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleCreatePromoCode}>Create new Promo Code</MenuItem>
          </Menu>
        </>
      ) : null}
    </Box>
  );
};

PromoCodeSectionHeader.propTypes = {
  title: string.isRequired,
};

export default PromoCodeSectionHeader;
