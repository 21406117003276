import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link, useCreatePath, useRecordContext, useStore } from 'react-admin';

import { Box, Card, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import getServiceName from '@/utils/serviceName/getServiceName';
import { ExitToApp } from '@mui/icons-material';
import prefDayJs from '@/utils/datetime/prefDayJs';
import { PREFERENCES_KEYS } from '@/constants/preferences';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const JobHeader = () => {
  const [timePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const record = useRecordContext();
  const createPath = useCreatePath();

  if (!record) return null;

  const startDate = prefDayJs({
    date: record.start_date_time,
    timezone: record.start_timezone,
    timePreference,
  });
  const endDate = prefDayJs({
    date: record.end_date_time,
    timezone: record.end_timezone,
    timePreference,
  });

  const jobTime = `${startDate.format('ha')}-${endDate.format('ha')}`;
  const jobDate = startDate.format('dddd, MMM DD, YYYY');

  return (
    <Card mt={3} px={3} py={2} component={Box}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={4}>
          <Link
            to={createPath({
              resource: resources.JOBS,
              type: 'show',
              id: record.id,
            })}
          >
            <Box display="inline-flex" alignItems="center">
              <Typography variant="h5" mr={1}>
                {getServiceName(record.product_id)}
              </Typography>
              <ExitToApp fontSize="small" />
            </Box>
          </Link>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography variant="h5" component={Box}>
            {`Status: ${record.status}`}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Typography variant="h5" component={Box} py={0.45}>
            {`${jobDate}, ${jobTime}`}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default JobHeader;
