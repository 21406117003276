import React, { useEffect, useState } from 'react';

import mx from 'mixpanel-browser';
import { LoadingIndicator, ShowBase, useShowContext } from 'react-admin';
import { useParams } from 'react-router-dom';

import { Box, Card } from '@mui/material';

import * as resources from '@/api/resources';

import ThirdPartyCrewContent from './ThirdPartyCrewContent';
import ThirdPartyCrewHeader from './ThirdPartyCrewHeader';
import ThirdPartyCrewTab from './ThirdPartyCrewTab';

const ThirdPartyCrewAccount = () => {
  const { record: { id } = {}, isLoading, isFetching } = useShowContext();

  const params = useParams();

  const tabValue = params['*'];

  const [currentTab, setCurrentTab] = useState(tabValue !== '' ? tabValue : 'details');

  const trackOrderTabChange = (selectedTab) => {
    mx.track('Resource Show - Tab Changed', {
      resource: resources.THIRD_PARTY_CREW,
      resourceId: id,
      selectedTab,
    });
  };

  useEffect(() => {
    if (tabValue !== '') {
      setCurrentTab(tabValue);
      trackOrderTabChange(tabValue);
    } else {
      setCurrentTab('details');
    }
  }, [tabValue]);

  useEffect(() => {
    if (id) {
      mx.track('Resource Show - Viewed', {
        resource: resources.THIRD_PARTY_CREW,
        resourceId: id,
      });
    }
  }, [id]);

  return (
    <Box width="100%" mt={3}>
      {!id && (isLoading || isFetching) ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <>
          <ThirdPartyCrewHeader />
          <Card pt={1} component={Box} mt={3}>
            <ThirdPartyCrewTab currentTab={currentTab} />
            <Box
              p={3}
              // Change the color of the background sx
              sx={{
                backgroundColor: ({ palette }) =>
                  palette.mode === 'light' ? palette.neutral.light : palette.neutral.darkest,
              }}
            >
              <ThirdPartyCrewContent currentTab={currentTab} />
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

const ThirdPartyCrewAccountShow = () => (
  <ShowBase>
    <ThirdPartyCrewAccount />
  </ShowBase>
);

export default ThirdPartyCrewAccountShow;
