/*
 * This is a direct clone of RA's useListParams hook in order to fix filter persistence in local storage
 *
 * Changes were made around disableSyncWithLocation prop
 * This prop in official release was using a React useState to store the params, which hold filters, sort, and pagination options
 * These changes allow the params to continue to be stored in the normal store (local storage by default) and continue persistence
 *
 * The following items needed to be clone in order to support changing that hook
 * List -> ListBase -> useListController -> useListParams
 *
 * PR was opened on react-admin official repo to potentially fix this in a future release
 */
import { useCallback, useMemo, useEffect, useRef } from 'react';
import { parse, stringify } from 'query-string';
import lodashDebounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  HIDE_FILTER,
  queryReducer,
  removeEmpty,
  SET_FILTER,
  SET_PAGE,
  SET_PER_PAGE,
  SET_SORT,
  SHOW_FILTER,
  SORT_ASC,
  useIsMounted,
  useStore,
} from 'react-admin';
import { isNaN } from 'lodash';

const getNumberOrDefault = (possibleNumber, defaultValue) => {
  const parsedNumber = typeof possibleNumber === 'string' ? parseInt(possibleNumber, 10) : possibleNumber;

  return isNaN(parsedNumber) ? defaultValue : parsedNumber;
};

const emptyObject = {};

const defaultSort = {
  field: 'id',
  order: SORT_ASC,
};

const defaultParams = {};

const validQueryParams = ['page', 'perPage', 'sort', 'order', 'filter', 'displayedFilters'];

const parseObject = (query, field) => {
  if (query[field] && typeof query[field] === 'string') {
    try {
      // eslint-disable-next-line no-param-reassign
      query[field] = JSON.parse(query[field]);
    } catch (err) {
      // eslint-disable-next-line no-param-reassign
      delete query[field];
    }
  }
};

const parseQueryFromLocation = ({ search }) => {
  const query = pickBy(parse(search), (v, k) => validQueryParams.indexOf(k) !== -1);
  parseObject(query, 'filter');
  parseObject(query, 'displayedFilters');
  return query;
};

/**
 * Check if user has already set custom sort, page, or filters for this list
 *
 * User params come from the store as the params props. By default,
 * this object is:
 *
 * { filter: {}, order: null, page: 1, perPage: null, sort: null }
 *
 * To check if the user has custom params, we must compare the params
 * to these initial values.
 *
 * @param {Object} params
 */
const hasCustomParams = (params) =>
  params &&
  params.filter &&
  (Object.keys(params.filter).length > 0 ||
    params.order != null ||
    params.page !== 1 ||
    params.perPage != null ||
    params.sort != null);

/**
 * Merge list params from 3 different sources:
 *   - the query string
 *   - the params stored in the state (from previous navigation)
 *   - the props passed to the List component (including the filter defaultValues)
 */
const getQuery = ({ queryFromLocation, params, filterDefaultValues, sort, perPage }) => {
  const query =
    // eslint-disable-next-line no-nested-ternary
    Object.keys(queryFromLocation).length > 0
      ? queryFromLocation
      : hasCustomParams(params)
      ? { ...params }
      : { filter: filterDefaultValues || {} };

  if (!query.sort) {
    query.sort = sort.field;
    query.order = sort.order;
  }
  if (query.perPage == null) {
    query.perPage = perPage;
  }
  if (query.page == null) {
    query.page = 1;
  }

  return {
    ...query,
    page: getNumberOrDefault(query.page, 1),
    perPage: getNumberOrDefault(query.perPage, 10),
  };
};

/**
 * Get the list parameters (page, sort, filters) and modifiers.
 *
 * These parameters are merged from 3 sources:
 *   - the query string from the URL
 *   - the params stored in the state (from previous navigation)
 *   - the options passed to the hook (including the filter defaultValues)
 *
 * @returns {Array} A tuple [parameters, modifiers].
 * Destructure as [
 *    { page, perPage, sort, order, filter, filterValues, displayedFilters, requestSignature },
 *    { setFilters, hideFilter, showFilter, setPage, setPerPage, setSort }
 * ]
 *
 * @example
 *
 * const [listParams, listParamsActions] = useListParams({
 *      resource: 'posts',
 *      location: location // From react-router. Injected to your component by react-admin inside a List
 *      filterDefaultValues: {
 *          published: true
 *      },
 *      sort: {
 *          field: 'published_at',
 *          order: 'DESC'
 *      },
 *      perPage: 25
 * });
 *
 * const {
 *      page,
 *      perPage,
 *      sort,
 *      order,
 *      filter,
 *      filterValues,
 *      displayedFilters,
 *      requestSignature
 * } = listParams;
 *
 * const {
 *      setFilters,
 *      hideFilter,
 *      showFilter,
 *      setPage,
 *      setPerPage,
 *      setSort,
 * } = listParamsActions;
 */
export default ({
  debounce = 500,
  disableSyncWithLocation = false,
  filterDefaultValues,
  perPage = 10,
  resource,
  sort = defaultSort,
  storeKey = `${resource}.listParams`,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [params, setParams] = useStore(storeKey, defaultParams);
  const tempParams = useRef();
  const isMounted = useIsMounted();

  const requestSignature = [
    location.search,
    resource,
    storeKey,
    JSON.stringify(params),
    JSON.stringify(filterDefaultValues),
    JSON.stringify(sort),
    perPage,
    disableSyncWithLocation,
  ];

  const queryFromLocation = disableSyncWithLocation ? {} : parseQueryFromLocation(location);

  const query = useMemo(
    () =>
      getQuery({
        queryFromLocation,
        params,
        filterDefaultValues,
        sort,
        perPage,
      }),
    requestSignature,
  );

  // if the location includes params (for example from a link like
  // the categories products on the demo), we need to persist them in the
  // store as well so that we don't lose them after a redirection back
  // to the list
  useEffect(() => {
    if (Object.keys(queryFromLocation).length > 0) {
      setParams(query);
    }
  }, [location.search]); // eslint-disable-line

  const changeParams = useCallback(
    (action) => {
      // do not change params if the component is already unmounted
      // this is necessary because changeParams can be debounced, and therefore
      // executed after the component is unmounted
      if (!isMounted.current) return;

      if (!tempParams.current) {
        // no other changeParams action dispatched this tick
        tempParams.current = queryReducer(query, action);
        // schedule side effects for next tick
        setTimeout(() => {
          if (disableSyncWithLocation) {
            setParams(tempParams.current);
          } else {
            // the useEffect above will apply the changes to the params in the store
            navigate(
              {
                search: `?${stringify({
                  ...tempParams.current,
                  filter: JSON.stringify(tempParams.current.filter),
                  displayedFilters: JSON.stringify(tempParams.current.displayedFilters),
                })}`,
              },
              {
                state: {
                  _scrollToTop: action.type === SET_PAGE,
                },
              },
            );
          }
          tempParams.current = undefined;
        }, 0);
      } else {
        // side effects already scheduled, just change the params
        tempParams.current = queryReducer(tempParams.current, action);
      }
    },
    [...requestSignature, navigate],
  );

  const setSort = useCallback(
    (cbSort) =>
      changeParams({
        type: SET_SORT,
        payload: cbSort,
      }),
    [changeParams],
  );

  const setPage = useCallback((newPage) => changeParams({ type: SET_PAGE, payload: newPage }), [changeParams]);

  const setPerPage = useCallback((newPerPage) => changeParams({ type: SET_PER_PAGE, payload: newPerPage }), [changeParams]);

  const filterValues = query.filter || emptyObject;
  const displayedFilterValues = query.displayedFilters || emptyObject;

  const debouncedSetFilters = lodashDebounce((filter, displayedFilters) => {
    changeParams({
      type: SET_FILTER,
      payload: {
        filter: removeEmpty(filter),
        displayedFilters,
      },
    });
  }, debounce);

  const setFilters = useCallback(
    (filter, displayedFilters, cbDebounce = true) =>
      cbDebounce
        ? debouncedSetFilters(filter, displayedFilters)
        : changeParams({
            type: SET_FILTER,
            payload: {
              filter: removeEmpty(filter),
              displayedFilters,
            },
          }),
    [changeParams],
  );

  const hideFilter = useCallback(
    (filterName) => {
      changeParams({
        type: HIDE_FILTER,
        payload: filterName,
      });
    },
    [changeParams],
  );

  const showFilter = useCallback(
    (filterName, defaultValue) => {
      changeParams({
        type: SHOW_FILTER,
        payload: {
          filterName,
          defaultValue,
        },
      });
    },
    [changeParams],
  );

  return [
    {
      displayedFilters: displayedFilterValues,
      filterValues,
      requestSignature,
      ...query,
    },
    {
      changeParams,
      setPage,
      setPerPage,
      setSort,
      setFilters,
      hideFilter,
      showFilter,
    },
  ];
};
