import React, { useState } from 'react';

import dayjs from 'dayjs';
import { DateInput, SelectInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Box, Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, Switch } from '@mui/material';

import { QUOTE_NON_PARTNER_TEMPLATES, QUOTE_PARTNER_TEMPLATES } from '@/constants/quoteTemplates';

const getSwimLanes = (serviceGroup) => {
  const isMovingServices = serviceGroup.service_codes.includes('LOADINGUNLOADING');

  if (isMovingServices) {
    return [8, 14];
  }
  return [8, 11, 14, 17];
};

const allBusinessHours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

const PreferredDate = () => {
  const { setValue, watch } = useFormContext();

  const [showNonSwimlaneHours, setShowNonSwimlaneHours] = useState(false);

  const selectedTemplate = watch('selected_template');
  const firstServiceGroup = watch('service_groups.0');

  const slotHours = showNonSwimlaneHours ? allBusinessHours : getSwimLanes(firstServiceGroup);

  const handleSwimlaneSwitchChange = (event) => {
    setShowNonSwimlaneHours(event.target.checked);
    setValue('preferred.start_time', '');
    setValue('preferred.end_time', '');
  };

  const isLongDistanceTemplate = selectedTemplate?.id === QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE.id;
  const isPodsLoadingUnloadingTemplate = selectedTemplate?.id === QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING.id;

  const twoDatesRequired = isLongDistanceTemplate || isPodsLoadingUnloadingTemplate;

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Preferred Date" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <DateInput source="preferred.start_date" label={twoDatesRequired ? 'Loading Date' : 'Date'} required />
          </Grid>
          <Grid item xs={2}>
            <SelectInput
              source="preferred.start_time"
              label={twoDatesRequired ? 'Loading Time' : 'Time'}
              choices={slotHours.map((hour) => ({ id: hour, name: dayjs().hour(hour).format('h A') }))}
              required
            />
          </Grid>
          {twoDatesRequired ? (
            <>
              <Grid item xs={2}>
                <DateInput source="preferred.end_date" label="Unloading Date" required />
              </Grid>
              <Grid item xs={2}>
                <SelectInput
                  source="preferred.end_time"
                  label="Unloading Time"
                  choices={slotHours.map((hour) => ({ id: hour, name: dayjs().hour(hour).format('h A') }))}
                  required
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={4} mt={2}>
            <FormControlLabel
              label="Show Non Swimlane Hours"
              control={<Switch value={showNonSwimlaneHours} onChange={handleSwimlaneSwitchChange} />}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PreferredDate;
