import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import {
  THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION,
  THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD,
  THIRD_PARTY_CREW_ACCOUNT_TAB,
} from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewSectionHeader from '../../../Common/SectionHeader';

const ThirdPartyCrewContentDetailsSectionHeader = ({ tab, section, title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const contextMenuItemOverview = [
    ...(permissions.tools?.hqadmin?.third_party_crew_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
          {
            key: 'edit-account-status',
            onClick: () => {
              navigate(
                `/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/${THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD.STATUS}/edit`,
              );
            },
            component: 'Edit status',
          },
        ]
      : []),
    ...(permissions.tools?.hqadmin?.third_party_crew_accounts?.deactivate
      ? [
          {
            key: 'post-deactivate-account',
            onClick: () => {
              navigate(
                `/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/${THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD.DEACTIVATE}/edit`,
              );
            },
            component: 'Deactivate Third Party Crew',
          },
        ]
      : []),
  ];

  const contextMenuItemContactInfo = [
    ...(permissions.tools?.hqadmin?.third_party_crew_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
          // Temp remove until location update built with google auto complete/lookup
          // {
          //   key: 'edit-location',
          //   onClick: () => {
          //     navigate(`/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/${THIRD_PARTY_CREW_ACCOUNT_CONTACTINFO_CARD.EDITLOCATION}/edit`);
          //   },
          //   component: 'Edit location',
          // },
        ]
      : []),
  ];

  const contextMenuItemRecruitment = [
    ...(permissions.tools?.hqadmin?.third_party_crew_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemPayment = [
    ...(permissions.tools?.hqadmin?.third_party_crew_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.THIRD_PARTY_CREW}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemDefault = [];

  switch (section) {
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      contextMenuItems = contextMenuItemOverview;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.CONTACT_INFO:
      contextMenuItems = contextMenuItemContactInfo;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.RECRUITMENT:
      contextMenuItems = contextMenuItemRecruitment;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.PAYMENT:
      contextMenuItems = contextMenuItemPayment;
      break;
    default:
      contextMenuItems = contextMenuItemDefault;
  }

  return <ThirdPartyCrewSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

ThirdPartyCrewContentDetailsSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

ThirdPartyCrewContentDetailsSectionHeader.defaultProps = {
  tab: THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS,
};
export default ThirdPartyCrewContentDetailsSectionHeader;
