import React from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import {
  DateInput,
  Form,
  Labeled,
  SelectInput,
  TextField,
  maxValue,
  minValue,
  useDataProvider,
  useNotify,
  useRecordContext,
  useShowContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Alert, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import { useFormContext } from 'react-hook-form';
import { Schedule } from '@mui/icons-material';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit">
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const Duration = () => {
  const { watch } = useFormContext();
  const { start_timezone: startTimezone, end_timezone: endTimezone } = useRecordContext();

  const startDate = watch('start_date');
  const startTime = watch('start_time');
  const endDate = watch('end_date');
  const endTime = watch('end_time');

  const startDateObj = dayjs(startDate).hour(startTime).minute(0).tz(startTimezone, true);
  const endDateObj = dayjs(endDate).hour(endTime).minute(0).tz(endTimezone, true);

  return (
    <Box textAlign="center" mt={5}>
      <Box>
        <Schedule fontSize="large" />
      </Box>
      <Typography>{`${endDateObj.diff(startDateObj, 'hours')} hours`}</Typography>
    </Box>
  );
};

const allBusinessHours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

const DateTimeForm = () => {
  const { watch } = useFormContext();

  const startDate = watch('start_date');
  const endDate = watch('end_date');

  const validateStartDate = [minValue(dayjs().format('YYYY-MM-DD')), maxValue(dayjs(endDate).format('YYYY-MM-DD'))];
  const validateEndDate = [minValue(dayjs(startDate).format('YYYY-MM-DD'))];

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Manual Date / Time Adjustment" />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid container item xs={8}>
            <Grid item xs={3}>
              <DateInput source="start_date" validate={validateStartDate} required />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="start_time"
                choices={allBusinessHours.map((hour) => ({ id: hour, name: dayjs().hour(hour).format('h A') }))}
                required
              />
            </Grid>
            <Grid item xs={3} mt={1}>
              <Labeled>
                <TextField source="start_timezone" />
              </Labeled>
            </Grid>
            <Grid item xs={3} mt={1}>
              <Labeled>
                <TextField source="start_local_time" label="Current Local Time" />
              </Labeled>
            </Grid>
            <Grid item xs={3}>
              <DateInput source="end_date" validate={validateEndDate} required />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="end_time"
                choices={allBusinessHours.map((hour) => ({ id: hour, name: dayjs().hour(hour).format('h A') }))}
                required
              />
            </Grid>
            <Grid item xs={3} mt={1}>
              <Labeled>
                <TextField source="end_timezone" />
              </Labeled>
            </Grid>
            <Grid item xs={3} mt={1}>
              <Labeled>
                <TextField source="end_local_time" label="Current Local Time" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Duration />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const LDTransitManualDateTime = ({ serviceGroupId }) => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const serviceGroup = _.find(quoteRecord?.service_groups, (group) => group.id === serviceGroupId);

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - LD Transit Date / Time updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('LD Transit Date / Time updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating LD Transit Date / Time', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating LD Transit date/time on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = ({
    start_date: startDate,
    start_time: startTime,
    end_date: endDate,
    end_time: endTime,
    start_timezone: startTimezone,
    end_timezone: endTimezone,
  }) => {
    const startDateObj = dayjs(startDate).hour(startTime).minute(0).tz(startTimezone, true);
    const endDateObj = dayjs(endDate).hour(endTime).minute(0).tz(endTimezone, true);

    const operationsNeeded = [
      {
        op: 'replace',
        path: `/service_groups/${serviceGroupId}/start_time`,
        value: {
          start_datetime: startDateObj.toISOString(),
        },
      },
      {
        op: 'replace',
        path: `/service_groups/${serviceGroupId}/service_details`,
        value: {
          duration: endDateObj.diff(startDateObj, 'hours'),
          service_workers: {
            TRANSIT: serviceGroup.services[0].quoted_workers,
          },
        },
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  return (
    <>
      <Alert variant="filled" severity="warning" mt={3} component={Box}>
        This is Long Distance transit, make sure you have approved the changes with carrier operations.
      </Alert>
      <Form
        record={{
          start_date: dayjs(serviceGroup.start_datetime).toDate(),
          start_time: dayjs(serviceGroup.start_datetime).tz(serviceGroup.start_timezone).hour(),
          start_timezone: serviceGroup.start_timezone,
          start_local_time: dayjs().tz(serviceGroup.start_timezone).format('h:mm A'),
          end_date: dayjs(serviceGroup.end_datetime).toDate(),
          end_time: dayjs(serviceGroup.end_datetime).tz(serviceGroup.end_timezone).hour(),
          end_timezone: serviceGroup.end_timezone,
          end_local_time: dayjs().tz(serviceGroup.end_timezone).format('h:mm A'),
        }}
        onSubmit={onSubmit}
      >
        <DateTimeForm />
        <FormControls />
      </Form>
    </>
  );
};

LDTransitManualDateTime.propTypes = {
  serviceGroupId: string.isRequired,
};

export default LDTransitManualDateTime;
