import React from 'react';

import { string } from 'prop-types';
import { SelectArrayInput } from 'react-admin';

import { ORDER_STATUS } from '@/constants/orderStatus';

const OrderStatusFilter = ({ source }) => {
  const choiceMap = Object.keys(ORDER_STATUS).map((key) => ({
    id: key,
    name: ORDER_STATUS[key],
  }));
  return <SelectArrayInput source={source} choices={choiceMap} />;
};

OrderStatusFilter.propTypes = {
  source: string.isRequired,
};

export default OrderStatusFilter;
