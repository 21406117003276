import React from 'react';

import { Datagrid, useRecordContext } from 'react-admin';

import { Card, CardContent, Divider } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import QualificationLevelField from '@/fields/QualificationLevelField';
import ServiceNameField from '@/fields/ServiceNameField';
import EmptyResourceList from '@/shared/EmptyResourceList';

import MarketSectionHeader from '../Common/SectionHeader';

// Temp removed until we can clean up, base pay have UUID in order to fetch, and this is trying to access via productId which is not an available filter
// const EditBasePayButton = () => {
//   const record = useRecordContext();
//   const { product_id: productId, market_id: marketId } = record;

//   return (
//     <EditButton
//       label=""
//       color="secondary"
//       component={Link}
//       to={`/${resources.MARKETS}/${marketId}/basepay/${productId}/edit`}
//     />
//   );
// };

const BasePay = () => {
  const record = useRecordContext();

  const contextMenuItems = [
    // Temp remove until create/edit can be cleaned up - same UUID/productId issue as Edit
    // ...(permissions.tools?.hqadmin?.market_base_pay?.create
    //   ? [
    //       {
    //         key: 'add',
    //         onClick: () => {
    //           navigate(`/${resources.MARKETS}/${record.id}/basepay/create`);
    //         },
    //         component: 'Add base pay',
    //       },
    //     ]
    //   : []),
  ];

  // const canUpdateBasePay = permissions?.tools?.hqadmin?.market_base_pay?.update;

  if (!record) return null;

  return (
    <Card mt={3}>
      <MarketSectionHeader title="Base Pay" contextMenuItems={contextMenuItems} />
      <Divider />
      <CardContent>
        <List
          disableSyncWithLocation
          resource={resources.MARKETS}
          sort={{ field: 'product_id', order: 'ASC' }}
          queryOptions={{ meta: { subResource: 'basepays', resourceId: record.id } }}
          actions={false}
          hasEdit
          empty={<EmptyResourceList text="No Base Pays on Market" />}
          storeKey={`${resources.MARKETS}.basepays.listParams`}
        >
          <Datagrid bulkActionButtons={false}>
            <ServiceNameField source="product_id" label="Service" sortable={false} />
            <QualificationLevelField source="qualification_level" label="Qualification" sortable={false} />
            <CurrencyField source="rate" label="Rate" textAlign="left" sortable={false} />

            {/* {canUpdateBasePay ? <EditBasePayButton /> : null} */}
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

export default BasePay;
