import React from 'react';
import { useSidebarState } from 'react-admin';

import { IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

const MySidebarToggleButton = () => {
  const [sidebarOpen, setSidebarOpen] = useSidebarState();

  return (
    <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} size="large">
      {sidebarOpen ? <ArrowBackIosNew /> : <ArrowForwardIos />}
    </IconButton>
  );
};

export default MySidebarToggleButton;
