export const CUSTOMER_ACCOUNT_DETAIL_SECTION = {
  OVERVIEW: 'overview',
};

export const CUSTOMER_ACCOUNT_TAB = {
  DETAILS: 'details',
  CHARGES: 'charges',
  ORDERS: 'orders',
  QUOTES: 'quotes',
  SUPPORT: 'support',
};

export const CUSTOMER_CHARGES_SECTION = {
  PAYMENT_METHOD: 'payment-method',
  HISTORY_OF_CHARGES: 'history-of-charges',
};

export const CUSTOMER_ORDER_SECTION = {
  ORDER_HISTORY: 'order-history',
};
export const CUSTOMER_STATUS = [
  { id: 'Active', name: 'Active' },
  { id: 'Opportunity', name: 'Opportunity' },
  { id: 'Do Not Book', name: 'Do Not Book' },
];

export const CUSTOMER_SOURCE = [
  { id: '123 Load Board', name: '123 Load Board' },
  { id: 'AllConnect', name: 'AllConnect' },
  { id: 'All My Sons', name: 'All My Sons' },
  { id: 'AllWebLeads', name: 'AllWebLeads' },
  { id: 'AMLI', name: 'AMLI' },
  { id: "Angie's List", name: "Angie's List" },
  { id: 'Another Bellhop', name: 'Another Bellhop' },
  { id: 'ApartmentList.com', name: 'ApartmentList.com' },
  { id: 'Apartments.com Site Search', name: 'Apartments.com Site Search' },
  { id: 'Atlanta Moving Pros', name: 'Atlanta Moving Pros' },
  { id: 'Avail', name: 'Avail' },
  { id: 'Avvinue', name: 'Avvinue' },
  { id: 'Bark', name: 'Bark' },
  { id: 'BDS', name: 'BDS' },
  { id: 'Bellhop Site - Booking Flow', name: 'Bellhop Site - Booking Flow' },
  { id: 'Beyond Self Storage', name: 'Beyond Self Storage' },
  { id: 'Brookfield', name: 'Brookfield' },
  { id: 'Citizen Home Solutions', name: 'Citizen Home Solutions' },
  { id: 'Clever', name: 'Clever' },
  { id: 'College Recruiter', name: 'College Recruiter' },
  { id: 'Consumer Affairs', name: 'Consumer Affairs' },
  { id: 'Cortland Apartments', name: 'Cortland Apartments' },
  { id: 'Craigslist', name: 'Craigslist' },
  { id: 'Crye-Leike', name: 'Crye-Leike' },
  { id: 'CubeSmart', name: 'CubeSmart' },
  { id: 'Dallas Moving Pros', name: 'Dallas Moving Pros' },
  { id: 'DAT Load Board', name: 'DAT Load Board' },
  { id: 'dotdash', name: 'dotdash' },
  { id: 'DOT Registry', name: 'DOT Registry' },
  { id: 'Drive for Bellhops', name: 'Drive for Bellhops' },
  { id: 'Email Capture Blog', name: 'Email Capture Blog' },
  { id: 'Email Capture MLP', name: 'Email Capture MLP' },
  { id: 'Email Only', name: 'Email Only' },
  { id: 'Equate', name: 'Equate' },
  { id: 'Equate Live Transfers', name: 'Equate Live Transfers' },
  { id: 'Flatrate Moving', name: 'Flatrate Moving' },
  { id: 'Flyhomes', name: 'Flyhomes' },
  { id: 'From a Friend', name: 'From a Friend' },
  { id: 'GMB Request a Quote', name: 'GMB Request a Quote' },
  { id: 'Google Ads Lead Form', name: 'Google Ads Lead Form' },
  { id: 'Google Local Services', name: 'Google Local Services' },
  { id: 'Google My Business', name: 'Google My Business' },
  { id: 'havenly', name: 'havenly' },
  { id: 'Hello Alfred', name: 'Hello Alfred' },
  { id: 'HireAHelper', name: 'HireAHelper' },
  { id: 'HomeAdvisor Exact Match', name: 'HomeAdvisor Exact Match' },
  { id: 'HomeAdvisor Market Match', name: 'HomeAdvisor Market Match' },
  { id: 'Home Depot', name: 'Home Depot' },
  { id: 'homeexpert', name: 'homeexpert' },
  { id: 'HomeGuage', name: 'HomeGuage' },
  { id: 'Ideal Agent', name: 'Ideal Agent' },
  { id: 'Inbound Call', name: 'Inbound Call' },
  { id: 'Inbound Call - Drive for Bellhops', name: 'Inbound Call - Drive for Bellhops' },
  { id: 'Indeed', name: 'Indeed' },
  { id: 'Intercom Chat', name: 'Intercom Chat' },
  { id: 'IRELO', name: 'IRELO' },
  { id: 'Job Case', name: 'Job Case' },
  { id: 'Landing', name: 'Landing' },
  { id: 'Las Vegas Giveaway', name: 'Las Vegas Giveaway' },
  { id: 'Lead Hero', name: 'Lead Hero' },
  { id: 'leadvision', name: 'leadvision' },
  { id: 'Livly', name: 'Livly' },
  { id: 'LocalMovingPros.com', name: 'LocalMovingPros.com' },
  { id: 'LongDistanceMover.com', name: 'LongDistanceMover.com' },
  { id: 'MCR', name: 'MCR' },
  { id: 'Mediabids', name: 'Mediabids' },
  { id: 'Metropolitan Movers', name: 'Metropolitan Movers' },
  { id: 'Move Buddha', name: 'Move Buddha' },
  { id: 'MoveEasy', name: 'MoveEasy' },
  { id: 'MoveHQ', name: 'MoveHQ' },
  { id: 'MoveInc', name: 'MoveInc' },
  { id: 'Move Matcher', name: 'Move Matcher' },
  { id: 'Movermatch', name: 'Movermatch' },
  { id: 'Moverscom', name: 'Moverscom' },
  { id: 'Moves for Seniors', name: 'Moves for Seniors' },
  { id: 'moving.com', name: 'moving.com' },
  { id: 'movingbiz', name: 'movingbiz' },
  { id: 'Movingful', name: 'Movingful' },
  { id: 'movinglabor-lead', name: 'movinglabor-lead' },
  { id: 'movingwaldo', name: 'movingwaldo' },
  { id: 'Mr. Cooper', name: 'Mr. Cooper' },
  { id: 'MYMOVE', name: 'MYMOVE' },
  { id: 'MyMovingLoads', name: 'MyMovingLoads' },
  { id: 'newkeyz', name: 'newkeyz' },
  { id: 'Offerpad', name: 'Offerpad' },
  { id: 'OJO', name: 'OJO' },
  { id: 'OneSource', name: 'OneSource' },
  { id: 'Other', name: 'Other' },
  { id: 'Outbound Call', name: 'Outbound Call' },
  { id: 'Partnerships', name: 'Partnerships' },
  { id: 'PetScreening', name: 'PetScreening' },
  { id: 'PODS', name: 'PODS' },
  { id: 'PODS CORP RELO', name: 'PODS CORP RELO' },
  { id: 'poweron', name: 'poweron' },
  { id: 'property managers', name: 'property managers' },
  { id: 'Purchased List', name: 'Purchased List' },
  { id: 'Quote Runner', name: 'Quote Runner' },
  { id: 'Quote Runner Exclusive', name: 'Quote Runner Exclusive' },
  { id: 'Quote Runner LIVE', name: 'Quote Runner LIVE' },
  { id: 'Real Estate Bidders', name: 'Real Estate Bidders' },
  { id: 'realtor referrals', name: 'realtor referrals' },
  { id: 'Recleim', name: 'Recleim' },
  { id: 'Redfin', name: 'Redfin' },
  { id: 'redfin concierge', name: 'redfin concierge' },
  { id: 'Referral', name: 'Referral' },
  { id: 'rego', name: 'rego' },
  { id: 'Rent Application', name: 'Rent Application' },
  { id: 'Rexdirect', name: 'Rexdirect' },
  { id: 'RexDirect Live', name: 'RexDirect Live' },
  { id: 'RexHomes', name: 'RexHomes' },
  { id: 'San Jose Moving Pros', name: 'San Jose Moving Pros' },
  { id: 'SeveralBrands', name: 'SeveralBrands' },
  { id: 'Shyft', name: 'Shyft' },
  { id: 'site-ld-form', name: 'site-ld-form' },
  { id: 'Snag', name: 'Snag' },
  { id: 'Social Media', name: 'Social Media' },
  { id: 'sold.com', name: 'sold.com' },
  { id: 'Steady', name: 'Steady' },
  { id: 'Storage', name: 'Storage' },
  { id: 'Suited Connector', name: 'Suited Connector' },
  { id: 'Swift Homes', name: 'MCSwift HomesR' },
  { id: 'thespruce', name: 'thespruce' },
  { id: 'Thumbtack', name: 'Thumbtack' },
  { id: 'TruckStop Load Board', name: 'TruckStop Load Board' },
  { id: 'Updatr', name: 'Updatr' },
  { id: 'usnews', name: 'usnews' },
  { id: 'Utility Connect', name: 'Utility Connect' },
  { id: 'Yelp', name: 'Yelp' },
  { id: 'Yelp RAQ', name: 'Yelp RAQ' },
  { id: 'Zendesk Lead', name: 'Zendesk Lead' },
  { id: 'Zillow', name: 'Zillow' },
  { id: 'Zillow Builder', name: 'Zillow Builder' },
  { id: 'Zillow - Outbound', name: 'Zillow - Outbound' },
  { id: 'Zippy Shell', name: 'Zippy Shell' },
  { id: 'Zippy Shell Corp Relo', name: 'Zippy Shell Corp Relo' },
];

export const CUSTOMER_HEAR_ABOUT_US = [
  { id: 'Affiliates/Partners - Groupon', name: 'Affiliates/Partners - Groupon' },
  { id: 'All My Sons/Another Moving Company', name: 'All My Sons/Another Moving Company' },
  { id: 'Billboard', name: 'Billboard' },
  { id: 'Blog', name: 'Blog' },
  { id: 'Friend/Family Referral', name: 'Friend/Family Referral' },
  { id: 'Google/Bing - Search Engine', name: 'Google/Bing - Search Engine' },
  { id: 'Outbound lead', name: 'Outbound lead' },
  { id: 'PODS', name: 'PODS' },
  { id: 'Repeat Customer', name: 'Repeat Customer' },
  { id: 'Skipped', name: 'Skipped' },
  { id: 'Social Media', name: 'Social Media' },
  { id: 'Truck', name: 'Truck' },
  { id: 'Used Bellhop before', name: 'Used Bellhop before' },
  { id: 'Yard Sign', name: 'Yard Sign' },
  { id: 'Yelp', name: 'Yelp' },
];

export const CUSTOMER_RECORD_TYPE = [{ id: 'Customer', name: 'Customer' }];
