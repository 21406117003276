import React from 'react';
import { shape, string, bool } from 'prop-types';
import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

const PercentageField = ({ source, textFieldProps, emptyText, isFraction }) => {
  const record = useRecordContext();
  // if undefined and not exactly zero - we want to display 0% below
  if (!record?.[source] && record?.[source] !== 0)
    return (
      <Typography component="span" {...textFieldProps}>
        {emptyText}
      </Typography>
    );

  const value = isFraction ? record[source] * 100 : record[source];
  const numberFormat = new Intl.NumberFormat();
  const formattedvalue = numberFormat.format(value);

  return (
    <Typography component="span" {...textFieldProps}>
      {formattedvalue}%
    </Typography>
  );
};

PercentageField.propTypes = {
  source: string.isRequired,
  textFieldProps: shape({}),
  emptyText: string,
  isFraction: bool,
};

PercentageField.defaultProps = {
  textFieldProps: {},
  emptyText: '',
  isFraction: true,
};

export default PercentageField;
