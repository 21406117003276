import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';
import CustomerCreate from './Create';
import CustomerEdit from './Edit';
import CustomerList from './List';
import CustomerShow from './Show';

const customers = () => ({
  name: resources.CUSTOMERS,
  create: CustomerCreate,
  list: CustomerList,
  show: CustomerShow,
  children: [<Route key="customer-edit" path=":accountId/:tab/:section/edit" element={<CustomerEdit />} />],
});

export default customers;
