import React from 'react';

import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import { useDataProvider, useNotify, useRefresh, useShowContext } from 'react-admin';
import { useMutation } from 'react-query';

import { RemoveCircle } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';

import * as resources from '@/api/resources';

const RemovePromoCode = ({ adjustmentId }) => {
  const { record: quoteRecord } = useShowContext();

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutate: operationUpdate, isLoading } = useMutation(([resource, params]) =>
    dataProvider.operationUpdate(resource, params),
  );

  const onError = (error) => {
    mx.track('Order Management - Quote - Error removing Promo Code', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred removing promo code - ${error}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track('Order Management - Quote - Promo Code removed', {
      quoteId: quoteRecord?.id,
    });
    notify('Promo code removed', {
      type: 'success',
    });
    refresh();
  };

  const removePromoCode = () => {
    const operationsNeeded = [
      {
        op: 'remove',
        path: `/promo_code/${adjustmentId}`,
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  return (
    <Box mr={1}>
      {isLoading ? (
        <Box width={40} height={40} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={24} />
        </Box>
      ) : (
        <IconButton onClick={removePromoCode}>
          <RemoveCircle color="accent" />
        </IconButton>
      )}
    </Box>
  );
};

RemovePromoCode.propTypes = {
  adjustmentId: string.isRequired,
};

export default RemovePromoCode;
