import MuiButton from './MuiButton';
import MuiCard from './MuiCard';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDrawer from './MuiDrawer';
import MuiTableCell from './MuiTableCell';
import RaBulkActionsToolbar from './RaBulkActionsToolbar';
import RaChipField from './RaChipField';
import RaDatagrid from './RaDatagrid';
import RaLabeled from './RaLabeled';
import RaMenuItemLink from './RaMenuItemLink';
import RaNotification from './RaNotification';
import RaSidebar from './RaSidebar';
import RaToolbar from './RaToolbar';

const components = ({ palette }) => ({
  MuiButton: MuiButton({ palette }),
  MuiCard: MuiCard({ palette }),
  MuiCardContent: MuiCardContent({ palette }),
  MuiCardHeader: MuiCardHeader({ palette }),
  MuiCssBaseline: MuiCssBaseline({ palette }),
  MuiDrawer: MuiDrawer({ palette }),
  MuiTableCell: MuiTableCell({ palette }),
  RaBulkActionsToolbar: RaBulkActionsToolbar({
    palette,
  }),
  RaChipField: RaChipField({ palette }),
  RaDatagrid: RaDatagrid({ palette }),
  RaLabeled: RaLabeled({ palette }),
  RaMenuItemLink: RaMenuItemLink({ palette }),
  RaNotification: RaNotification({ palette }),
  RaSidebar: RaSidebar({ palette }),
  RaToolbar: RaToolbar({ palette }),
});

export default components;
