const ORDER_ITEM_STATUS = {
  CANCELLED: 'Cancelled',
  COMPLETE: 'Complete',
  OPEN: 'Open',
  REMOVED: 'Removed',
};

const ORDER_ITEM_ACTIVE_STATUSES = ['COMPLETE', 'OPEN'];

export { ORDER_ITEM_STATUS, ORDER_ITEM_ACTIVE_STATUSES };
