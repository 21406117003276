const TXN_STATUS = {
  CANCELED: 'Canceled',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  OPEN: 'Open',
  NEEDS_REVIEW: 'Needs Review',
  ON_HOLD: 'On Hold',
  PROCESS_PENDING: 'Process Pending',
};

const TXN_PAYMENT_STATUS = {
  AUTHORIZED: 'Authorized',
  CAPTURED: 'Captured',
  DISPUTE_WARNING: 'Dispute Warning',
  DISPUTED: 'Disputed',
  PARTIALLY_CAPTURED: 'Partially Captured',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'Partially Refunded',
  UNCAPTURED: 'Uncaptured',
};

const TXN_ACTIVE_STATUSES = [
  TXN_STATUS.COMPLETED,
  TXN_STATUS.OPEN,
  TXN_STATUS.NEEDS_REVIEW,
  TXN_STATUS.ON_HOLD,
  TXN_STATUS.PROCESS_PENDING,
];

export { TXN_STATUS, TXN_PAYMENT_STATUS, TXN_ACTIVE_STATUSES };
