import React from 'react';

import _ from 'lodash';
import { string } from 'prop-types';
import { FunctionField, Labeled, RecordContextProvider, useShowContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Chip, Divider, Grid } from '@mui/material';

import { SERVICES } from '@/constants/quotes';
import CurrencyField from '@/fields/CurrencyField';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import getServiceName from '@/utils/serviceName/getServiceName';
import { LocalShipping, PeopleAlt, Schedule } from '@mui/icons-material';

const ContextInfo = ({ serviceGroupId }) => {
  const { record: quoteRecord } = useShowContext();

  const serviceGroup = _.find(quoteRecord?.service_groups, (group) => group.id === serviceGroupId);

  if (!quoteRecord || !serviceGroup) return null;

  return (
    <RecordContextProvider value={serviceGroup}>
      <Card mt={3} component={Box}>
        <CardHeader title="Current Quote Info" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Labeled label="Date / Time">
                <PrefDateTimeField source="start_datetime" timezoneSource="start_timezone" dayjsFormat="M/D/YYYY h:mm A" />
              </Labeled>
            </Grid>
            <Grid item xs={3} alignItems="center" display="flex" justifyContent="center">
              {serviceGroup.services?.map((service) => (
                <Chip key={service.id} label={getServiceName(service.service_id)} mr={1} component={Box} />
              ))}
            </Grid>
            <Grid item xs={3} alignItems="center" display="flex" gap={2} justifyContent="center">
              <Box display="flex" alignItems="center" gap={0.5}>
                <Schedule />
                <FunctionField render={(record) => <TextFieldWrapper value={`${record.duration} hours`} />} />
              </Box>
              {serviceGroup.services?.map((service) => (
                <Box key={service.id} display="flex" alignItems="center" gap={0.5}>
                  {service.service_id === SERVICES.TRANSIT.id ? <LocalShipping /> : <PeopleAlt />}
                  <TextFieldWrapper value={service.quoted_workers} />
                </Box>
              ))}
            </Grid>
            <Grid item xs={3} justifyContent="center" display="flex">
              <Labeled label="Cost">
                <CurrencyField source="total_cost" fractionDigits={0} />
              </Labeled>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </RecordContextProvider>
  );
};

ContextInfo.propTypes = {
  serviceGroupId: string.isRequired,
};

export default ContextInfo;
