import React from 'react';

import { LoadingIndicator, useShowContext } from 'react-admin';

import { Box } from '@mui/material';

import CustomerDetailsOverviewSection from './Overview';

const CustomerContentDetails = () => {
  const { record, isLoading, isFetching } = useShowContext();

  if (!record) return null;

  return (
    <Box width="100%">
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <CustomerDetailsOverviewSection />
      )}
    </Box>
  );
};

export default CustomerContentDetails;
