import React from 'react';

import { Edit, Form, SaveButton, SelectInput, ShowButton, TextInput, required, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { COUNTRIES, STATES } from '@/constants/location';

const CarrierDetailsLocationEdit = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Edit
      resource={resources.CARRIERS}
      id={record.id}
      mutationOptions={{ meta: { subResource: 'DETAILS/CONTACTINFO/UPDATELOCATION' } }}
      mutationMode="pessimistic"
      redirect={`/${resources.CARRIERS}/${record.id}/show`}
      actions={false}
    >
      <Card mt={3} component={Box}>
        <Box py={4} mx={2}>
          <Typography variant="h5">Contact Info - Edit Location</Typography>
        </Box>

        <Divider />

        <CardContent>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput source="line_1" validate={[required()]} fullWidth />
                <TextInput source="line_2" validate={[]} fullWidth />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="city" validate={[required()]} fullWidth />
                <SelectInput
                  source="state"
                  choices={STATES}
                  validate={[required()]}
                  fullWidth
                  label="States"
                  defaultValue="AK"
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  source="postal_code"
                  validate={[required()]}
                  inputProps={{ maxLength: 5 }}
                  fullWidth
                  placeholder="ex:11111"
                />

                <SelectInput
                  source="country"
                  choices={COUNTRIES}
                  validate={[required()]}
                  fullWidth
                  label="Country"
                  defaultValue="US"
                />
              </Grid>
            </Grid>

            <Grid item xs={4} display="flex" justifyContent="space-between">
              <ShowButton label="Cancel" icon={null} color="secondary" />
              <SaveButton label="Update" icon={null} />
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </Edit>
  );
};

export default CarrierDetailsLocationEdit;
