import React, { useContext } from 'react';

import dayjs from 'dayjs';
import { shape } from 'prop-types';
import { RecordContextProvider } from 'react-admin';

import { ArrowForward, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Tooltip, Typography, styled } from '@mui/material';

import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPES_DISPLAY } from '@/constants/pricingLayers';
import { SERVICES } from '@/constants/serviceName';
import MarketNameField from '@/fields/MarketNameField';
import { QuoteContext } from '@/providers/Quote';
import formatCurrency from '@/utils/currency/formatCurrency';
import getServiceName from '@/utils/serviceName/getServiceName';

const QuotePricingAccordion = styled(Accordion)(({ theme }) => {
  const isLightMode = theme.mode === 'light';
  return {
    '&:first-of-type, &:last-of-type': {
      backgroundColor: isLightMode ? theme.palette.neutral.lightest : theme.palette.secondary.darker,
      borderRadius: 0,
    },
    '&.MuiAccordion-root::before': {
      display: 'none',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      flexDirection: 'row-reverse',
    },
    '& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 36,
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
      paddingLeft: theme.spacing(1),
    },
    '& .MuiCollapse-root': {
      margin: theme.spacing(0),
    },
    '& .MuiAccordionDetails-root': {
      backgroundColor: isLightMode ? theme.palette.neutral.lighter : theme.palette.secondary.darkest,
      padding: theme.spacing(1, 2, 1, 4),
      marginBottom: theme.spacing(1),
    },
  };
});

const PriceLayer = ({ layer, service }) => {
  const isLDTransit = layer.service_id === `LD${SERVICES.TRANSIT.id}`;
  const isFixedPricing = service.estimated_pricing_strategy === 'FIXED';
  const hasApplicableFixedModifier = isFixedPricing && layer.fixed_price_modifier !== 1;

  const fixedTooltip = '(Base price + Per worker price x workers) x hours';
  const hourlyTooltip = 'Base price + Per worker price x workers';

  const fixedFormula = `(${formatCurrency(layer.base_price)} + ${formatCurrency(layer.per_worker_price)} x ${
    service.quoted_workers
  } workers) x ${service.quoted_hours} hrs`;
  const hourlyFormula = `${formatCurrency(layer.base_price)} + ${formatCurrency(layer.per_worker_price)} x ${
    service.quoted_workers
  } workers`;

  const layerCaption = () => {
    switch (layer.type) {
      case PRICING_LAYER_TYPES.SERVICE:
        if (isLDTransit) {
          return (
            <>
              <RecordContextProvider value={{ start_market_id: layer.start_market_id, end_market_id: layer.end_market_id }}>
                <Typography lineHeight={2} variant="caption" pl={1}>
                  <MarketNameField textFieldProps={{ variant: 'caption' }} source="start_market_id" />
                  <ArrowForward sx={{ mx: 1, fontSize: 16 }} />
                  <MarketNameField textFieldProps={{ variant: 'caption' }} source="end_market_id" />
                </Typography>
              </RecordContextProvider>
              <Tooltip title="Base price + Per truck price x trucks">
                <Typography component="span" variant="caption" pl={1}>
                  {formatCurrency(layer.base_price)} + {formatCurrency(layer.per_worker_price)} x {service.quoted_workers}{' '}
                  {`truck${service.quoted_workers !== 1 ? 's' : ''}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            <Tooltip title={isFixedPricing ? fixedTooltip : hourlyTooltip}>
              <Typography component="span" variant="caption" pl={1}>
                {isFixedPricing ? fixedFormula : hourlyFormula}
              </Typography>
            </Tooltip>
            {isFixedPricing ? (
              <Tooltip title="Total Hourly Price x Hours">
                <Typography variant="caption" pl={1}>
                  {formatCurrency(layer.base_price + layer.per_worker_price * service.quoted_workers)} / hr x{' '}
                  {service.quoted_hours} hrs
                </Typography>
              </Tooltip>
            ) : null}
            {hasApplicableFixedModifier ? (
              <Tooltip title="Total Fixed Price x Fixed Price Modifier">
                <Typography variant="caption" pl={1}>
                  {formatCurrency(
                    (layer.base_price + layer.per_worker_price * service.quoted_workers) * service.quoted_hours,
                  )}{' '}
                  x {layer.fixed_price_modifier}
                </Typography>
              </Tooltip>
            ) : null}
          </>
        );
      case PRICING_LAYER_TYPES.DAY_OF_MONTH:
        return (
          <Typography variant="caption" pl={1}>
            {dayjs().date(layer.day_of_month).format('D')}
          </Typography>
        );
      case PRICING_LAYER_TYPES.DAY_OF_WEEK:
        return (
          <Typography variant="caption" pl={1}>
            {dayjs().day(layer.day_of_week).format('dddd')}
          </Typography>
        );
      case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
        return (
          <Typography variant="caption" pl={1}>
            {dayjs()
              .month(layer.month_of_year - 1)
              .format('MMMM')}
          </Typography>
        );
      default:
        return null;
    }
  };

  const layerPrice = () => {
    switch (layer.type) {
      case PRICING_LAYER_TYPES.SERVICE:
        if (isLDTransit || !isFixedPricing) {
          return `${formatCurrency(layer.base_price + layer.per_worker_price * service.quoted_workers)}${
            !isFixedPricing ? ' / hr' : ''
          }`;
        }
        return formatCurrency(
          (layer.base_price + layer.per_worker_price * service.quoted_workers) *
            service.quoted_hours *
            layer.fixed_price_modifier,
        );
      case PRICING_LAYER_TYPES.DAY_OF_MONTH:
      case PRICING_LAYER_TYPES.DAY_OF_WEEK:
      case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
        return `${formatCurrency(layer.price)}${!isFixedPricing ? ' / hr' : ''}`;
      default:
        return '';
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Box display="flex" flexDirection="column">
        <Typography>
          {isLDTransit ? PRICING_LAYER_TYPES_DISPLAY[PRICING_LAYER_TYPES.LANE] : PRICING_LAYER_TYPES_DISPLAY[layer.type]}
        </Typography>
        {layerCaption()}
      </Box>
      <Typography>{layerPrice()}</Typography>
    </Box>
  );
};

PriceLayer.propTypes = {
  layer: shape({}).isRequired,
  service: shape({}).isRequired,
};

const ServicePricingLayerBreakdown = ({ service, serviceGroup }) => {
  const { applicablePricingLayersByServiceId } = useContext(QuoteContext);

  if (!service || !applicablePricingLayersByServiceId) return null;

  const applicablePricingLayers = applicablePricingLayersByServiceId[service.id];
  const isFixedPricing = service.estimated_pricing_strategy === 'FIXED';

  return (
    <QuotePricingAccordion key={service.id} elevation={0}>
      <AccordionSummary sx={{}} expandIcon={<ExpandMore />}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6">{getServiceName(service.service_id)}</Typography>
          <Typography variant="h6">
            {service.estimated_pricing_strategy === 'HOURLY'
              ? formatCurrency(service.quoted_unit_price * serviceGroup.duration)
              : formatCurrency(service.quoted_unit_price)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography textTransform="capitalize" textAlign="center">
          {service.estimated_pricing_strategy.toLowerCase()} Pricing
        </Typography>
        {applicablePricingLayers?.map((layer) => (
          <PriceLayer service={service} layer={layer} key={`${layer.id}-${layer.type}`} />
        ))}
        {!isFixedPricing ? (
          <>
            <Box py={1}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display="flex" flexDirection="column">
                <Typography>Total</Typography>
              </Box>
              <Typography>{`${formatCurrency(service.quoted_unit_price)} / hr`}</Typography>
            </Box>
          </>
        ) : null}
      </AccordionDetails>
    </QuotePricingAccordion>
  );
};

ServicePricingLayerBreakdown.propTypes = {
  service: shape({}).isRequired,
  serviceGroup: shape({}).isRequired,
};

export default ServicePricingLayerBreakdown;
