import React from 'react';

import {
  Form,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewDetailsOverviewEdit = () => (
  <Card mt={3} component={Box}>
    <CardHeader title="Overview" />

    <Divider />

    <CardContent>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextInput source="name" label="Name" validate={[required()]} fullWidth />
          </Grid>

          <Grid item xs={3}>
            <ReferenceInput
              source="primary_market"
              reference={resources.MARKETS}
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" label="Market" fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={3}>
            <NumberInput source="truck_count" label="Truck count" validate={[]} fullWidth />
          </Grid>
        </Grid>

        <Divider />
        <CustomToolbar />
      </Form>
    </CardContent>
  </Card>
);

export default ThirdPartyCrewDetailsOverviewEdit;
