import { FEE_TYPES } from './quotes';

const ADJUSTMENT_NAMES = {
  APPEASEMENT: 'Appeasement',
  BASE_FEE: 'Base Fee',
  BILL_ADJUSTMENT: 'Bill Adjustment',
  FEE: 'Fee',
  MILEAGE_FEE: 'Mileage Fee',
  SERVICE_FEE: 'Service Fee',
  SETTLEMENT: 'Settlement',
  PROMOCODE: 'Promo Code',
  [FEE_TYPES.ADDSERV_CRATING_FEE.id]: FEE_TYPES.ADDSERV_CRATING_FEE.name,
  [FEE_TYPES.ADDSERV_JUNKREMOVAL_FEE.id]: FEE_TYPES.ADDSERV_JUNKREMOVAL_FEE.name,
  [FEE_TYPES.ADDSERV_VEHICLETRANSPORT_FEE.id]: FEE_TYPES.ADDSERV_VEHICLETRANSPORT_FEE.name,
  [FEE_TYPES.ADDSERV_TVMOUNT_FEE.id]: FEE_TYPES.ADDSERV_TVMOUNT_FEE.name,
  [FEE_TYPES.ADDSERV_OTHER_FEE.id]: FEE_TYPES.ADDSERV_OTHER_FEE.name,
  [FEE_TYPES.HOM_FEE.id]: FEE_TYPES.HOM_FEE.name,
  [FEE_TYPES.MARKUP_FEE.id]: FEE_TYPES.MARKUP_FEE.name,
  [FEE_TYPES.PACKINGSUPPLIES_FEE.id]: FEE_TYPES.PACKINGSUPPLIES_FEE.name,
  [FEE_TYPES.STOP_FEE.id]: FEE_TYPES.STOP_FEE.name,
  [FEE_TYPES.TRAVEL_FEE.id]: FEE_TYPES.TRAVEL_FEE.name,
  [FEE_TYPES.AMEX_FEE.id]: FEE_TYPES.AMEX_FEE.name,
  LATE_CANCELLATION: 'Late Cancellation Fee',
};

const PRICE_ADJUSTMENT_TYPES = ['APPEASEMENT', 'BILL_ADJUSTMENT'];

const SETTLEMENT_TYPES = ['SETTLEMENT'];

export { ADJUSTMENT_NAMES, PRICE_ADJUSTMENT_TYPES, SETTLEMENT_TYPES };
