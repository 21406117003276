import React from 'react';
import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import TextFieldWrapper from '@/fields/TextFieldWrapper';
import getQualificationLevel from '@/utils/qualificationLevel/getQualificationLevel';

const QualificationLevelField = ({ source, textFieldProps }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;
  const qualification = getQualificationLevel(record[source]);
  return <TextFieldWrapper value={qualification} textFieldProps={textFieldProps} />;
};

QualificationLevelField.propTypes = {
  source: string.isRequired,
  textFieldProps: shape({}),
};

QualificationLevelField.defaultProps = {
  textFieldProps: {},
};

export default QualificationLevelField;
