import React from 'react';

import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Form,
  ReferenceInput,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';

const ProsDetailsOverviewEdit = () => {
  const filterToQuery = (query) => ({ name: query });

  const emptyStringNull = (value) => {
    if (!value) return '';
    return value;
  };

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview" />

      <Divider />

      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput source="first_name" required fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="last_name" required fullWidth />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                source="company_id"
                reference="accounts"
                enableGetChoices={({ name }) => Boolean(name?.length > 0)}
                emptyText="N/A"
              >
                <AutocompleteInput optionText="name" filterToQuery={filterToQuery} label="Company" default />
              </ReferenceInput>
            </Grid>

            <Grid item xs={2}>
              <DateInput source="admiral_effective_date" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <DateInput source="admiral_end_date" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Referred by" source="referred_by_text" fullWidth parse={emptyStringNull} />
            </Grid>

            <Grid item xs={4}>
              <ReferenceInput
                source="primary_market"
                reference={resources.MARKETS}
                perPage={999}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput optionText="name" label="Market" fullWidth />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <BooleanInput source="exclude_from_performance_management" fullWidth />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Update" icon={null} />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default ProsDetailsOverviewEdit;
