const SLOT_TRANSACTION_FAMILY = {
  BASE: {
    id: 'BASE',
    name: 'Base',
  },
  BONUS: {
    id: 'BONUS',
    name: 'Bonus',
  },
  OTHER: {
    id: 'OTHER',
    name: 'Other',
  },
  ACCESSORIAL: {
    id: 'ACCESSORIAL',
    name: 'Accessorial',
  },
  CONTEST: {
    id: 'CONTEST',
    name: 'Contest',
  },
  TIP: {
    id: 'TIP',
    name: 'Tip',
  },
};

const SLOT_TRANSACTION_REASON = {
  BASE: {
    id: 'BASE',
    name: 'Base',
  },
  ADVOCATE: {
    id: 'ADVOCATE',
    name: 'Advocate',
  },
  CLAIM: {
    id: 'CLAIM',
    name: 'Claim',
  },
  'CONTRACTED RATE': {
    id: 'CONTRACTED RATE',
    name: 'Contracted Rate',
  },
  ENGAGEMENT: {
    id: 'ENGAGEMENT',
    name: 'Engagement',
  },
  PEAK: {
    id: 'PEAK',
    name: 'Peak',
  },
  PERFORMANCE: {
    id: 'PERFORMANCE',
    name: 'Performance',
  },
  REFERRAL: {
    id: 'REFERRAL',
    name: 'Referral',
  },
  TRAVEL: {
    id: 'TRAVEL',
    name: 'Travel',
  },
  'DAILY MINIMUM DELTA': {
    id: 'DAILY MINIMUM DELTA',
    name: 'Daily Minimum Delta',
  },
  EQUIPMENT: {
    id: 'EQUIPMENT',
    name: 'Equipment',
  },
  'LATE CANCELLATION': {
    id: 'LATE CANCELLATION',
    name: 'Late Cancellation',
  },
  MARKETING: {
    id: 'MARKETING',
    name: 'Marketing',
  },
  RENTAL: {
    id: 'RENTAL',
    name: 'Rental',
  },
  'WEEKLY MINIMUM DELTA': {
    id: 'WEEKLY MINIMUM DELTA',
    name: 'Weekly Minimum Delta',
  },
  'RESCHEDULE AFTER START': {
    id: 'RESCHEDULE AFTER START',
    name: 'Reschedule After Start',
  },
  STOP: {
    id: 'STOP',
    name: 'Stop',
  },
  LAYOVER: {
    id: 'LAYOVER',
    name: 'Layover',
  },
  TONU: {
    id: 'TONU',
    name: 'TONU',
  },
  DETENTION: {
    id: 'DETENTION',
    name: 'Detention',
  },
  TIP: {
    id: 'TIP',
    name: 'Tip',
  },
};

const SLOT_TRANSACTION_REASON_BY_FAMILY = {
  [SLOT_TRANSACTION_FAMILY.BASE.id]: {
    [SLOT_TRANSACTION_REASON.BASE.id]: SLOT_TRANSACTION_REASON.BASE,
  },
  [SLOT_TRANSACTION_FAMILY.BONUS.id]: {
    [SLOT_TRANSACTION_REASON.ADVOCATE.id]: SLOT_TRANSACTION_REASON.ADVOCATE,
    [SLOT_TRANSACTION_REASON.CLAIM.id]: SLOT_TRANSACTION_REASON.CLAIM,
    [SLOT_TRANSACTION_REASON.ENGAGEMENT.id]: SLOT_TRANSACTION_REASON.ENGAGEMENT,
    [SLOT_TRANSACTION_REASON.PEAK.id]: SLOT_TRANSACTION_REASON.PEAK,
    [SLOT_TRANSACTION_REASON.PERFORMANCE.id]: SLOT_TRANSACTION_REASON.PERFORMANCE,
    [SLOT_TRANSACTION_REASON.REFERRAL.id]: SLOT_TRANSACTION_REASON.REFERRAL,
    [SLOT_TRANSACTION_REASON.TRAVEL.id]: SLOT_TRANSACTION_REASON.TRAVEL,
  },
  [SLOT_TRANSACTION_FAMILY.OTHER.id]: {
    [SLOT_TRANSACTION_REASON['DAILY MINIMUM DELTA'].id]: SLOT_TRANSACTION_REASON['DAILY MINIMUM DELTA'],
    [SLOT_TRANSACTION_REASON.EQUIPMENT.id]: SLOT_TRANSACTION_REASON.EQUIPMENT,
    [SLOT_TRANSACTION_REASON['LATE CANCELLATION'].id]: SLOT_TRANSACTION_REASON['LATE CANCELLATION'],
    [SLOT_TRANSACTION_REASON.MARKETING.id]: SLOT_TRANSACTION_REASON.MARKETING,
    [SLOT_TRANSACTION_REASON.RENTAL.id]: SLOT_TRANSACTION_REASON.RENTAL,
    [SLOT_TRANSACTION_REASON['WEEKLY MINIMUM DELTA'].id]: SLOT_TRANSACTION_REASON['WEEKLY MINIMUM DELTA'],
    [SLOT_TRANSACTION_REASON['RESCHEDULE AFTER START'].id]: SLOT_TRANSACTION_REASON['RESCHEDULE AFTER START'],
  },
  [SLOT_TRANSACTION_FAMILY.ACCESSORIAL.id]: {
    [SLOT_TRANSACTION_REASON.EQUIPMENT.id]: SLOT_TRANSACTION_REASON.EQUIPMENT,
    [SLOT_TRANSACTION_REASON.STOP.id]: SLOT_TRANSACTION_REASON.STOP,
    [SLOT_TRANSACTION_REASON.LAYOVER.id]: SLOT_TRANSACTION_REASON.LAYOVER,
    [SLOT_TRANSACTION_REASON.TONU.id]: SLOT_TRANSACTION_REASON.TONU,
    [SLOT_TRANSACTION_REASON.DETENTION.id]: SLOT_TRANSACTION_REASON.DETENTION,
  },
  [SLOT_TRANSACTION_FAMILY.CONTEST.id]: {
    [SLOT_TRANSACTION_REASON.ENGAGEMENT.id]: SLOT_TRANSACTION_REASON.ENGAGEMENT,
    [SLOT_TRANSACTION_REASON.MARKETING.id]: SLOT_TRANSACTION_REASON.MARKETING,
    [SLOT_TRANSACTION_REASON.PERFORMANCE.id]: SLOT_TRANSACTION_REASON.PERFORMANCE,
  },
  [SLOT_TRANSACTION_FAMILY.TIP.id]: {
    [SLOT_TRANSACTION_REASON.TIP.id]: SLOT_TRANSACTION_REASON.TIP,
  },
};

export { SLOT_TRANSACTION_FAMILY, SLOT_TRANSACTION_REASON, SLOT_TRANSACTION_REASON_BY_FAMILY };
