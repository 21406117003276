import React, { useContext } from 'react';

import { RecordContextProvider, usePermissions } from 'react-admin';
import { useNavigate } from 'react-router';

import { Info, Warning } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';

import { QuoteContext } from '@/providers/Quote';
import PaymentMethodAccount from '@/resources/orders/Show/OrderContent/Invoice/PaymentMethods/PaymentMethodAccount';
import PaymentMethodCard from '@/resources/orders/Show/OrderContent/Invoice/PaymentMethods/PaymentMethodCard';

const AccountPaymentMethod = () => {
  const { accountId, accountDefaultPaymentMethod: paymentMethod, needCustomerPaymentMethod } = useContext(QuoteContext);
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const canCreateAccountPaymentMethod = permissions.tools?.hqadmin?.customer_payment_method?.create;

  if (!accountId) return null;

  return (
    <Card>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Payment Method</Typography>
        {canCreateAccountPaymentMethod ? (
          <Button variant="outlined" onClick={() => navigate(`edit/update-account-payment-method`)}>
            Update
          </Button>
        ) : null}
      </Box>
      <Divider />
      <CardContent>
        {!needCustomerPaymentMethod ? (
          <>
            <Box mb={1} display="flex" gap={1}>
              <Info />
              <Typography>100% Partner Paid</Typography>
            </Box>
            {paymentMethod ? (
              <Box mb={1}>
                <Typography>Default payment method on customer account will not be charged.</Typography>
              </Box>
            ) : null}
            <Divider />
          </>
        ) : null}
        {!paymentMethod ? (
          <Box display="flex" gap={1} pt={1}>
            {needCustomerPaymentMethod ? <Warning color="error" /> : null}
            <Typography>No default payment method</Typography>
          </Box>
        ) : (
          <Box mt={1}>
            <RecordContextProvider value={paymentMethod}>
              {paymentMethod.stripe_card_id ? <PaymentMethodCard /> : <PaymentMethodAccount />}
            </RecordContextProvider>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AccountPaymentMethod;
