import React from 'react';

import { useRecordContext } from 'react-admin';

import { Box, Card, Typography } from '@mui/material';

const CarrierHeader = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card px={3} py={2} component={Box}>
      <Box>
        <Typography variant="h5" component="span" pr={1}>
          Carrier:
        </Typography>
        <Box display="inline-flex" alignItems="center">
          <Typography variant="h5" mr={1}>
            {record?.name}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default CarrierHeader;
