import React from 'react';
import { Box, Card, CardHeader, Divider } from '@mui/material';
import LocationsContent from '@/shared/LocationsContent';
import { arrayOf, bool, shape } from 'prop-types';

const LocationsCard = ({ jobLocations, isLoading }) => (
  <Card>
    <CardHeader title="Locations" />
    <Divider />
    <Box>
      <LocationsContent data={jobLocations} isLoading={isLoading} />
    </Box>
  </Card>
);

LocationsCard.propTypes = {
  jobLocations: arrayOf(shape({})),
  isLoading: bool.isRequired,
};

LocationsCard.defaultProps = {
  jobLocations: [],
};

export default LocationsCard;
