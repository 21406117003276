export const THIRD_PARTY_CREW_RECORD_TYPE = 'Third Party Crew';

export const THIRD_PARTY_CREW_ACCOUNT_TAB = {
  AVAILABILITY: 'availability',
  CREW_LEADS: 'crew-leads',
  DETAILS: 'details',
  JOB_HISTORY: 'job-history',
  PAYOUT_CONFIG: 'payout-config',
  PERFORMANCE: 'performance',
  SKILLS: 'skills',
  SUPPORT: 'support',
  TRANSACTIONS: 'transactions',
};

export const THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION = {
  OVERVIEW: 'overview',
  CONTACT_INFO: 'contact-info',
  STATS: 'stats',
  RECRUITMENT: 'recruitment',
  PAYMENT: 'payment',
};

export const THIRD_PARTY_CREW_ACCOUNT_SKILL_SECTION = {
  SKILL_LIST: 'skill-list',
};

export const THIRD_PARTY_CREW_ACCOUNT_PERFORMANCE_SECTION = {
  RATING: 'rating',
  LATE_RATE: 'late-rate',
  DAMAGE: 'damage',
  REMOVAL: 'removal',
};

export const THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD = {
  STATUS: 'status',
  DEACTIVATE: 'deactivate',
};

export const THIRD_PARTY_CREW_ACCOUNT_CONTACT_INFO_CARD = {
  LOCATION: 'location',
};

export const THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_STATUSES = [
  { id: 'Active', name: 'Active' },
  { id: 'Onboarding', name: 'Onboarding' },
  { id: 'Pending Review', name: 'Pending Review' },
  { id: 'Opportunity', name: 'Opportunity' },
  { id: 'Do Not Book', name: 'Do Not Book' },
  { id: 'Inactive', name: 'Inactive' },
];

export const THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_REASONS = [{ id: 'Other', name: 'Other' }];

export const THIRD_PARTY_CREW_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS = [
  { id: 'No Show', name: 'No Show' },
  { id: 'Seasonal', name: 'Seasonal' },
  { id: 'Quality', name: 'Quality' },
  { id: 'Bellhop XP', name: 'Bellhop XP' },
  { id: 'Frozen Account', name: 'Frozen Account' },
  { id: 'Past Balance Outstanding', name: 'Past Balance Outstanding' },
  { id: 'Harassing Behavior', name: 'Harassing Behavior' },
  { id: 'Fraudulent Activity', name: 'Fraudulent Activity' },
  { id: 'Other', name: 'Other' },
  { id: 'Self selected (In-App)', name: 'Self selected (In-App)' },
  { id: 'Deactivated from poor - Damage Rate', name: 'Deactivated from poor - Damage Rate' },
  { id: 'Deactivated from poor - Late Rate', name: 'Deactivated from poor - Late Rate' },
  { id: 'Deactivated from poor - Removal Rate', name: 'Deactivated from poor - Removal Rate' },
  { id: 'Deactivated from poor - Damage Rate, Late Rate', name: 'Deactivated from poor - Damage Rate, Late Rate' },
  { id: 'Deactivated from poor - Damage Rate, Removal Rate', name: 'Deactivated from poor - Damage Rate, Removal Rate' },
  { id: 'Deactivated from poor - Late Rate, Removal Rate', name: 'Deactivated from poor - Late Rate, Removal Rate' },
  {
    id: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
    name: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
  },
  { id: 'Background Check', name: 'Background Check' },
];

export const THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS = [{ id: 'Inactive', name: 'Inactive' }];

export const THIRD_PARTY_CREW_ACCOUNT_TSHIRT_SIZE = [
  { id: 'S', name: 'Small' },
  { id: 'M', name: 'Medium' },
  { id: 'L', name: 'Large' },
  { id: 'XL', name: 'X-Large' },
  { id: 'XXL', name: 'XX-Large' },
  { id: '2XL', name: '2X-Large' },
];

export const LEAD_SOURCES = [
  { id: '123 Load Board', name: '123 Load Board' },
  { id: 'Craigslist', name: 'Craigslist' },
  { id: 'DAT Load Board', name: 'DAT Load Board' },
  { id: 'DOT Registry', name: 'DOT Registry' },
  { id: 'Drive for Bellhops', name: 'Drive for Bellhops' },
  { id: 'Inbound Call - Drive for Bellhops', name: 'Inbound Call - Drive for Bellhops' },
  { id: 'Purchased List', name: 'Purchased List' },
  { id: 'Referral', name: 'Referral' },
  { id: 'TruckStop Load Board', name: 'TruckStop Load Board' },
];

export const THIRD_PARTY_CREW_BACKGROUND_CHECKS = [
  { id: 'created', name: 'Created' },
  { id: 'clear', name: 'Clear' },
  { id: 'pending', name: 'Pending' },
  { id: 'processing', name: 'Processing' },
  { id: 'proceed', name: 'Proceed' },
  { id: 'cancelled', name: 'Cancelled' },
];

export const THIRD_PARTY_CREW_PAYOUT_PROVIDERS = [
  { id: 'PayReel', name: 'PayReel' },
  { id: 'Stripe - Bellhop Inc', name: 'Stripe - Bellhop Inc' },
  { id: 'Stripe - BHH Servies', name: 'Stripe - BHH Services' },
];

export const THIRD_PARTY_CREW_ACCOUNT_TYPE = [
  { id: 'Third Party Crew', name: 'Crew' },
  { id: 'Third Party Worker', name: 'Worker' },
];
