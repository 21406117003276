export const THIRD_PARTY_WORKER_RECORD_TYPE = 'Third Party Worker';

export const THIRD_PARTY_WORKER_ACCOUNT_TAB = {
  DETAILS: 'details',
  JOB_HISTORY: 'job-history',
  SUPPORT: 'support',
};

export const THIRD_PARTY_WORKER_ACCOUNT_DETAIL_SECTION = {
  OVERVIEW: 'overview',
};

export const THIRD_PARTY_WORKER_ACCOUNT_OVERVIEW_CARD = {
  STATUS: 'status',
  DEACTIVATE: 'deactivate',
};

export const THIRD_PARTY_WORKER_ACCOUNT_DROPDOWN_STATUSES = [
  { id: 'Active', name: 'Active' },
  { id: 'Onboarding', name: 'Onboarding' },
  { id: 'Pending Review', name: 'Pending Review' },
  { id: 'Opportunity', name: 'Opportunity' },
  { id: 'Do Not Book', name: 'Do Not Book' },
  { id: 'Inactive', name: 'Inactive' },
];

export const THIRD_PARTY_WORKER_ACCOUNT_DROPDOWN_REASONS = [{ id: 'Other', name: 'Other' }];

export const THIRD_PARTY_WORKER_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS = [
  { id: 'No Show', name: 'No Show' },
  { id: 'Seasonal', name: 'Seasonal' },
  { id: 'Quality', name: 'Quality' },
  { id: 'Bellhop XP', name: 'Bellhop XP' },
  { id: 'Frozen Account', name: 'Frozen Account' },
  { id: 'Past Balance Outstanding', name: 'Past Balance Outstanding' },
  { id: 'Harassing Behavior', name: 'Harassing Behavior' },
  { id: 'Fraudulent Activity', name: 'Fraudulent Activity' },
  { id: 'Other', name: 'Other' },
  { id: 'Self selected (In-App)', name: 'Self selected (In-App)' },
  { id: 'Deactivated from poor - Damage Rate', name: 'Deactivated from poor - Damage Rate' },
  { id: 'Deactivated from poor - Late Rate', name: 'Deactivated from poor - Late Rate' },
  { id: 'Deactivated from poor - Removal Rate', name: 'Deactivated from poor - Removal Rate' },
  { id: 'Deactivated from poor - Damage Rate, Late Rate', name: 'Deactivated from poor - Damage Rate, Late Rate' },
  { id: 'Deactivated from poor - Damage Rate, Removal Rate', name: 'Deactivated from poor - Damage Rate, Removal Rate' },
  { id: 'Deactivated from poor - Late Rate, Removal Rate', name: 'Deactivated from poor - Late Rate, Removal Rate' },
  {
    id: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
    name: 'Deactivated from poor - Damage Rate, Late Rate, Removal Rate',
  },
  { id: 'Background Check', name: 'Background Check' },
];

export const THIRD_PARTY_WORKER_ACCOUNT_DROPDOWN_DEACTIVATE_STATUS = [{ id: 'Inactive', name: 'Inactive' }];
