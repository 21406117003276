import React from 'react';

import { EditBase, Form, SaveButton, SelectInput, ShowButton, TextInput, required, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PRO_ACCOUNT_DROPDOWN_STATUSES } from '@/constants/prosAccount';

import ReasonsInput from './ReasonsInput';

const CarrierDetailsOverviewStatusEdit = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <EditBase
      resource={resources.CARRIERS}
      id={record.id}
      mutationOptions={{ meta: { subResource: 'DETAILS/OVERVIEW/STATUS' } }}
      mutationMode="pessimistic"
      redirect={`/${resources.CARRIERS}/${record.id}/show`}
      actions={false}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Overview - Edit Status" />

        <Divider />
        <Form>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <SelectInput
                  source="new_status"
                  choices={PRO_ACCOUNT_DROPDOWN_STATUSES}
                  validate={[required()]}
                  fullWidth
                  label="Status"
                  defaultValue={record.status}
                />
              </Grid>

              <Grid item xs={4}>
                <ReasonsInput />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="context" validate={[required()]} fullWidth label="Status Context" />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <ShowButton label="Cancel" icon={null} color="secondary" />
              <SaveButton label="Update" icon={null} />
            </Box>
          </CardContent>
        </Form>
      </Card>
    </EditBase>
  );
};

export default CarrierDetailsOverviewStatusEdit;
