import React, { useEffect } from 'react';
import { useLogin } from 'react-admin';
import { Box, Button, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { parse } from 'query-string';

const Login = () => {
  const login = useLogin();
  const location = useLocation();

  useEffect(() => {
    const { code, state } = parse(location.search);
    if (code && state) {
      login({ code, state, location });
    }
  }, []);

  const handleLogin = () => {
    login({
      targetUrl: location.state?.nextPathname ?? '/',
    });
  };

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{
        backgroundColor: ({ palette }) => palette.secondary.darkest,
      }}
    >
      <Box width={160} mb={25}>
        <Button variant="contained" color="neutral" onClick={handleLogin} fullWidth>
          <Typography variant="h6">Login</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
