import React from 'react';

import { useParams } from 'react-router';

import { MARKET_TRAVEL_PAY_SECTION } from '@/constants/market';

import MarketTravelPayOverviewEdit from './Overview';

const MarketTravelPayEdit = () => {
  const { section: currentSection } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case MARKET_TRAVEL_PAY_SECTION.OVERVIEW:
      EditComponent = <MarketTravelPayOverviewEdit />;
      break;
    default:
      break;
  }

  return EditComponent;
};

export default MarketTravelPayEdit;
