import React from 'react';

import { oneOf } from 'prop-types';
import { FunctionField, TextField } from 'react-admin';

import { PRICING_LAYER_TYPES } from '@/constants/pricingLayers';
import DayOfWeekField from '@/fields/DayOfWeekField';
import MonthOfYearField from '@/fields/MonthOfYearField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';

const DateNumericHeader = ({ type }) => {
  const headerProps = { textFieldProps: { variant: 'h6' } };

  switch (type) {
    case PRICING_LAYER_TYPES.DAY_OF_WEEK:
      return <DayOfWeekField source="day" {...headerProps} />;
    case PRICING_LAYER_TYPES.DAY_OF_MONTH:
      return <TextField source="day" {...headerProps} />;
    case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
      return <MonthOfYearField source="month" oneIndexed {...headerProps} />;
    case PRICING_LAYER_TYPES.LEAD_TIME:
      return (
        <FunctionField
          render={({ days }) => <TextFieldWrapper value={`${days} day${days !== 1 ? 's' : ''}`} {...headerProps} />}
        />
      );
    default:
      return null;
  }
};

DateNumericHeader.propTypes = {
  type: oneOf(Object.values(PRICING_LAYER_TYPES)).isRequired,
};

export default DateNumericHeader;
