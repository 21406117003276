import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { useCreate, useGetList, useNotify, usePermissions, useRedirect, useShowContext } from 'react-admin';

import { Box, Button, CircularProgress } from '@mui/material';

import * as resources from '@/api/resources';
import { ORDER_RESCHEDULABLE_STATUSES } from '@/constants/orderStatus';
import { OrderActionContext } from '@/providers/OrderAction';

const RescheduleAction = () => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const redirect = useRedirect();

  const { setAction } = useContext(OrderActionContext);

  const [create, { isLoading }] = useCreate();

  const {
    record: { id: orderId, long_distance: isOrderLongDistance, status: orderStatus },
  } = useShowContext();

  const { data: jobsData, isLoading: isCompletedJobsLoading } = useGetList(
    resources.JOBS,
    {
      filter: {
        order_id: orderId,
        status: ['OPEN', 'COMPLETE'],
      },
    },
    {
      enabled: Boolean(orderId),
    },
  );

  const completedJobsData = jobsData?.filter(({ status }) => status === 'COMPLETE');

  const canRescheduleOrderPerm = permissions?.tools?.hqadmin?.quotes?.create;
  const canRescheduleLDOrderPerm = permissions?.tools?.hqadmin?.ld_quotes?.create;
  const isOrderReschedulable = ORDER_RESCHEDULABLE_STATUSES.includes(orderStatus);

  const onSuccess = (data) => {
    mx.track('Order Management - Quote - Quote created from Order', {
      orderId,
    });

    redirect(`/quotes/${data.id}/edit?orderId=${orderId}`);
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error creating Quote from Order', {
      orderId,
      error,
    });

    notify(`Error occurred while creating quote from order - ${error.status} - ${error.message}`, { type: 'error' });
  };

  const createQuote = () => {
    create(
      resources.QUOTES,
      {
        data: {
          order_id: orderId,
          ...(isOrderLongDistance
            ? {
                lock_price_service_ids: jobsData?.map(({ id }) => id),
              }
            : {}),
        },
      },
      { onSuccess, onError },
    );
  };

  const initReschedule = () => {
    if (completedJobsData?.length) {
      // Partially completed order - display action panel to confirm mid service reschedule
      setAction('reschedule_order');
    } else {
      createQuote();
    }
  };

  let rescheduleAvailable = false;
  if (isOrderReschedulable) {
    if (isOrderLongDistance && canRescheduleLDOrderPerm) {
      rescheduleAvailable = true;
    }
    if (!isOrderLongDistance && canRescheduleOrderPerm) {
      rescheduleAvailable = true;
    }
  }

  return rescheduleAvailable ? (
    <Box display="flex" justifyContent="center" mt={2}>
      {isLoading || isCompletedJobsLoading ? (
        <CircularProgress />
      ) : (
        <Button variant="outlined" color="primary" onClick={initReschedule}>
          Reschedule Order
        </Button>
      )}
    </Box>
  ) : null;
};

export default RescheduleAction;
