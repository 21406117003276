import React from 'react';

import { useRecordContext } from 'react-admin';

import OrderHistory from './OrderHistory';

const CustomerOrders = () => {
  const record = useRecordContext();

  if (!record) return null;
  return <OrderHistory />;
};

export default CustomerOrders;
