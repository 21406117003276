import React, { useEffect, useState } from 'react';

import mx from 'mixpanel-browser';
import { LoadingIndicator, ShowBase, useRecordContext, useShowContext } from 'react-admin';
import { useParams } from 'react-router-dom';

import { Box, Card, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import CustomersContent from './CustomersContent';
import CustomersTabs from './CustomersTab';

const CustomerHeader = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card px={3} py={2} component={Box}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="h5" component="span" pr={1}>
            Customer:
          </Typography>
          <Box display="inline-flex" alignItems="center">
            <Typography variant="h5" mr={1}>
              {record?.name}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

const CustomerAccount = () => {
  const { record: { id } = {}, isLoading, isFetching } = useShowContext();

  const params = useParams();
  const tabValue = params['*'];

  const [currentTab, setCurrentTab] = useState(tabValue !== '' ? tabValue : 'details');

  const trackOrderTabChange = (selectedTab) => {
    mx.track('Resource Show - Tab Changed', {
      resource: resources.CUSTOMERS,
      resourceId: id,
      selectedTab,
    });
  };

  useEffect(() => {
    if (tabValue !== '') {
      setCurrentTab(tabValue);
      trackOrderTabChange(tabValue);
    } else {
      setCurrentTab('details');
    }
  }, [tabValue]);

  useEffect(() => {
    if (id) {
      mx.track('Resource Show - Viewed', {
        resource: resources.CUSTOMERS,
        resourceId: id,
      });
    }
  }, [id]);

  return (
    <Box mt={3}>
      {!id && (isLoading || isFetching) ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <>
          <CustomerHeader />
          <Card pt={1} component={Box} mt={3}>
            <CustomersTabs currentTab={currentTab} />
            <Box
              p={3}
              // Change the color of the background sx
              sx={{
                backgroundColor: ({ palette }) =>
                  palette.mode === 'light' ? palette.neutral.light : palette.neutral.darkest,
              }}
            >
              <CustomersContent currentTab={currentTab} />
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

const CustomerShow = () => (
  <ShowBase>
    <CustomerAccount />
  </ShowBase>
);

export default CustomerShow;
