import { MenuItemLinkClasses } from 'react-admin';

export default ({ palette }) => ({
  styleOverrides: {
    root: {
      fontWeight: 'bold',
      fontSize: 14,
      color: palette.mode === 'light' ? palette.neutral.darkest : palette.neutral.lightest,
      [`&.${MenuItemLinkClasses.active}`]: {
        color: palette.primary.main,
        backgroundColor: palette.mode === 'light' ? palette.primary.lightest : palette.secondary.darkest,
        [`.${MenuItemLinkClasses.icon}`]: {
          color: palette.primary.main,
        },
      },
      [`&.${MenuItemLinkClasses.active}:hover`]: {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
      [`&:not(.${MenuItemLinkClasses.active}):hover`]: {
        backgroundColor: palette.mode === 'light' ? palette.neutral.lighter : palette.neutral.darker,
      },
      [`& .${MenuItemLinkClasses.icon}`]: {
        minWidth: 16,
        marginRight: 8,
        fontSize: 8,
        color: palette.mode === 'light' ? palette.neutral.darkest : palette.neutral.lightest,
      },
    },
  },
});
