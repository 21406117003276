import React from 'react';

import { DateField, DateTimeInput, FunctionField, ReferenceField, SelectInput, TextField } from 'react-admin';

import { Check, Clear } from '@mui/icons-material';

import * as resources from '@/api/resources';
import { GUARANTEED_STATUS } from '@/constants/marketplace';
import MarketNameField from '@/fields/MarketNameField';

const ordersManagementColumns = [
  <TextField source="confirmation_id" key="confirmation_id" />,
  <ReferenceField source="account_id" label="Customer" reference={resources.CUSTOMERS} link="show" key="account_id">
    <TextField source="name" />
  </ReferenceField>,
  <TextField source="status" key="status" />,
  <DateField source="start_date_time" label="Start Date" key="start_date_time" />,
  <DateField source="created_at" label="Created Date" key="created_at" />,
  <MarketNameField source="start_market_id" label="Start Market" key="start_market_id" />,
  <MarketNameField source="end_market_id" label="End Market" key="end_market_id" />,
  <ReferenceField source="partner_id" label="Partner" reference={resources.ACCOUNTS} key="partner_id">
    <TextField source="name" />
  </ReferenceField>,
  <ReferenceField source="affiliate_id" label="Affiliate" reference={resources.ACCOUNTS} key="affiliate_id">
    <TextField source="name" />
  </ReferenceField>,
  <FunctionField label="Guaranteed" render={(record) => (record.guaranteed ? <Check /> : <Clear />)} />,
  <FunctionField render={(record) => (record.long_distance ? 'LD' : 'Local')} key="local_ld" />,
];

const ordersManagementFilters = [
  <DateTimeInput source="start_date_time_lte" label="Starts Before" />,
  <DateTimeInput source="end_date_time_gte" label="Ends After" />,
  <DateTimeInput source="created_at_gte" label="Created After" />,
  <DateTimeInput source="created_at_lte" label="Created Before" />,
  <SelectInput source="guaranteed" choices={GUARANTEED_STATUS} />,
];

const ordersManagementFilterDefaultValues = {};

export { ordersManagementColumns, ordersManagementFilterDefaultValues, ordersManagementFilters };
