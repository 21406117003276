import { node } from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

const MountedScriptsContext = createContext({});

const MountedScriptsProvider = ({ children }) => {
  const [scripts, setScripts] = useState([]);

  const updateScripts = (key) => {
    setScripts((prevScripts) => [...prevScripts, key]);
  };

  const value = useMemo(() => ({ scripts, updateScripts }), [scripts]);

  return <MountedScriptsContext.Provider value={value}>{children}</MountedScriptsContext.Provider>;
};

MountedScriptsProvider.propTypes = {
  children: node.isRequired,
};

export { MountedScriptsContext, MountedScriptsProvider };
