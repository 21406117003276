import React from 'react';

import dayjs from 'dayjs';
import {
  Datagrid,
  FunctionField,
  LoadingIndicator,
  RecordContextProvider,
  ReferenceManyField,
  TextField,
  useGetList,
  useShowContext,
} from 'react-admin';

import { Box, Card, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { JOB_SLOT_STATUS } from '@/constants/jobSlotStatus';
import CurrencyField from '@/fields/CurrencyField';
import NullableReferenceField from '@/fields/NullableReferenceField';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import QualificationLevelField from '@/fields/QualificationLevelField';
import SlotAccountNameField from '@/fields/SlotAccountNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';

import JobHeader from './JobHeader';
import SlotActionMenu from './SlotActionMenu';

const JobContent = () => (
  <Box>
    <ReferenceManyField
      reference={resources.JOB_SLOTS}
      target="job_id"
      filter={{
        status: [JOB_SLOT_STATUS.ASSIGNED, JOB_SLOT_STATUS.CANCELLED, JOB_SLOT_STATUS.UNASSIGNED],
        aggregate_transaction_summary: true,
      }}
      sort={{
        field: 'minimum_qualification_level',
        order: 'DESC',
      }}
      perPage={100}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <NullableReferenceField source="account_id" reference={resources.ACCOUNTS} label="Name">
          <SlotAccountNameField />
        </NullableReferenceField>
        <QualificationLevelField source="minimum_qualification_level" label="Skill Level" />
        <TextField source="status" />
        <PrefDateTimeField
          source="clock_in_date_time"
          timezoneSource="clock_in_timezone"
          label="Clock In"
          dayjsFormat="h:mm A"
          emptyText="Pending"
          tooltipFormat="M/D/YYYY h:mm A"
        />
        <PrefDateTimeField
          source="clock_out_date_time"
          timezoneSource="clock_out_timezone"
          label="Clock Out"
          dayjsFormat="h:mm A"
          emptyText="Pending"
          tooltipFormat="M/D/YYYY h:mm A"
        />
        <FunctionField
          label="Duration"
          render={(record) => {
            if (!record.clock_in_date_time || !record.clock_out_date_time) return null;
            const clockInDateTime = dayjs(record.clock_in_date_time);
            const clockOutDateTime = dayjs(record.clock_out_date_time);

            const clockDuration = clockOutDateTime.diff(clockInDateTime, 'hours', true).toFixed(2);

            return <TextFieldWrapper value={`${clockDuration}`} />;
          }}
        />
        <CurrencyField source="transaction_summary.bonus" label="Bonus" sortable={false} />
        <CurrencyField source="transaction_summary.total" label="Total" sortable={false} />
        <SlotActionMenu textAlign="right" />
      </Datagrid>
    </ReferenceManyField>
  </Box>
);

const OrderContentCrewInfo = () => {
  const { record: { id: orderId } = {} } = useShowContext();

  const { data, isLoading } = useGetList(
    resources.JOBS,
    {
      filter: {
        order_id: orderId,
        status: ['CANCELLED', 'COMPLETE', 'OPEN'],
      },
      sort: { field: 'start_date_time', order: 'ASC' },
      pagination: { perPage: 10, page: 1 },
    },
    { enabled: Boolean(orderId) },
  );

  if (!orderId) return null;

  return (
    <Grid container spacing={2}>
      {!data && isLoading ? (
        <Grid item xs={12} textAlign="center">
          <LoadingIndicator width="100%" size={100} />
        </Grid>
      ) : (
        data.map((job) => (
          <Grid item xs={12} key={job.id}>
            <Card>
              <RecordContextProvider value={job}>
                <JobHeader />
                <Divider />
                <JobContent />
              </RecordContextProvider>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default OrderContentCrewInfo;
