import React, { useContext, useEffect, useState } from 'react';
import { RecordContextProvider, ReferenceField, useListContext, useRecordContext, useShowContext } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Button, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JOB_SLOT_STATUS } from '@/constants/jobSlotStatus';
import { JOB_ACTIVE_STATUSES } from '@/constants/jobStatus';
import SlotAccountNameField from '@/fields/SlotAccountNameField';
import { OrderActionContext } from '@/providers/OrderAction';
import ListBase from '@/ReactAdmin/List/ListBase';
import getServiceName from '@/utils/serviceName/getServiceName';

import CurrencyInput from '../CurrencyInput';
import TipsTarget from '../TipsTarget';
import { FIELD_NAMES, TARGET_OPTIONS } from '../TipsTarget/constants';

const Slot = () => {
  const record = useRecordContext();
  const { control, watch } = useFormContext();
  const tipsTargetOption = watch(FIELD_NAMES.TARGET_OPTION);

  const fieldsDisabled =
    tipsTargetOption === TARGET_OPTIONS.SPLIT || tipsTargetOption === TARGET_OPTIONS.EVEN || tipsTargetOption === '';

  if (!record) return null;
  return (
    <Box display="flex" justifyContent="space-between">
      <ReferenceField source="account_id" reference={resources.ACCOUNTS} link={false}>
        <SlotAccountNameField />
      </ReferenceField>
      <Box maxWidth={100}>
        <Controller
          defaultValue=""
          name={`tips.${record.id}.amount`}
          control={control}
          render={({ field }) => <CurrencyInput disabled={fieldsDisabled} field={field} />}
        />
      </Box>
    </Box>
  );
};

const SlotsIterator = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) return null;
  return (
    <Box mt={2} ml={2}>
      {data?.map((slot) => (
        <RecordContextProvider value={slot} key={slot.id}>
          <Box mb={2}>
            <Slot />
          </Box>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

const Job = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <>
      <Typography>{getServiceName(record.product_id)}</Typography>
      <ListBase
        resource={resources.JOB_SLOTS}
        filter={{
          job_id: record.id,
          status: JOB_SLOT_STATUS.ASSIGNED,
        }}
      >
        <SlotsIterator />
      </ListBase>
    </>
  );
};

const JobsIterator = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) return null;

  return (
    <Box pt={1}>
      {data?.map((job, index) => (
        <RecordContextProvider value={job} key={job.id}>
          <Box mt={index > 0 ? 4 : 0}>
            <Job />
          </Box>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

const TipsForm = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record: { id: orderId } = {} } = useShowContext();
  const { watch } = useFormContext();

  const allValues = watch();
  const tipsTargetOption = watch(FIELD_NAMES.TARGET_OPTION);

  const [tipsTotal, setTipsTotal] = useState(0);

  useEffect(() => {
    const total = Object.keys(allValues.tips).reduce((prev, curr) => prev + allValues.tips[curr].amount * 100, 0);
    setTipsTotal((total / 100).toFixed(2));
  }, [allValues]);

  return (
    <>
      <TipsTarget />
      <Divider />
      {tipsTargetOption ? (
        <>
          <Box p={2}>
            <ListBase
              resource={resources.JOBS}
              filter={{
                order_id: orderId,
                status: JOB_ACTIVE_STATUSES,
              }}
            >
              <JobsIterator />
            </ListBase>
          </Box>
          <Divider />
          <Box py={2} display="flex" justifyContent="flex-end">
            <Typography component={Box} mr={1}>
              Total:
            </Typography>
            <Typography width={115}>{`$ ${tipsTotal}`}</Typography>
          </Box>
          <Divider />
          <Box pt={2} px={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default TipsForm;
