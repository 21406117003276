import React from 'react';

import { EditBase, Form, SaveButton, ShowButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const UserEdit = () => (
  <EditBase mutationOptions={{ meta: { subResource: '' } }} mutationMode="pessimistic" redirect="show">
    <Card mt={3} component={Box}>
      <CardHeader title="Overview" />

      <Divider />

      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput source="name" required fullWidth />
            </Grid>

            <Grid item xs={4}>
              <TextInput source="email" required fullWidth />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid item xs={4} display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="neutral" />
            <SaveButton label="Update" icon={null} />
          </Grid>
        </CardContent>
      </Form>
    </Card>
  </EditBase>
);

export default UserEdit;
