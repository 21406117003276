import React from 'react';

import {
  BooleanField,
  DateField,
  DeleteWithConfirmButton,
  ListButton,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { Box, Divider } from '@mui/material';

const AdminProductShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" emptyText="N/A" />
      <TextField source="name" emptyText="N/A" />
      <TextField source="product_id" emptyText="N/A" />
      <TextField source="group_id" emptyText="N/A" />
      <TextField source="payment_gateway" emptyText="N/A" />
      <TextField source="address_quantity" emptyText="N/A" />
      <NumberField source="minimum_workers" emptyText="N/A" />
      <NumberField source="maximum_workers" emptyText="N/A" />
      <NumberField source="minimum_duration" emptyText="N/A" />
      <NumberField source="maximum_duration" emptyText="N/A" />
      <TextField source="serviceable_address_type" emptyText="N/A" />
      <NumberField source="non_aggregate_rank" emptyText="N/A" />
      <NumberField source="aggregate_rank" emptyText="N/A" />
      <NumberField source="recommended_sequence" emptyText="N/A" />
      <TextField source="family" emptyText="N/A" />
      <TextField source="distance_type" emptyText="N/A" />
      <BooleanField source="unclaimable" />
      <BooleanField source="use_aggregate_rank" />
      <BooleanField source="ignore_rule_quote_availability" />
      <BooleanField source="customizable" />
      <BooleanField source="is_active" label="isActive?" />
      <BooleanField source="is_deleted" />
      <BooleanField source="claimable" />
      <BooleanField source="ignore_rule_assign_claimable" />
      <DateField source="created_at" />
    </SimpleShowLayout>
    <Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" p={3}>
        <ListButton label="Go Back" icon={null} color="secondary" />
        <DeleteWithConfirmButton
          confirmContent="You will not be able to recover this record. Are you sure?"
          confirmColor="warning"
        />
      </Box>
    </Box>
  </Show>
);

export default AdminProductShow;
