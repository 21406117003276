import React from 'react';

import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { CUSTOMER_ACCOUNT_TAB } from '@/constants/customerAccount';

import CustomerChargesEdit from './Charges';
import CustomerDetailsEdit from './Details';

const CustomerAccountEdit = () => {
  const { tab: currentTab } = useParams();

  return (
    <>
      <Box display={currentTab === CUSTOMER_ACCOUNT_TAB.DETAILS ? 'block' : 'none'}>
        <CustomerDetailsEdit />
      </Box>
      <Box display={currentTab === CUSTOMER_ACCOUNT_TAB.CHARGES ? 'block' : 'none'}>
        <CustomerChargesEdit />
      </Box>
    </>
  );
};

export default CustomerAccountEdit;
