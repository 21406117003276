import React from 'react';

import { useParams } from 'react-router';

import {
  PRO_ACCOUNT_CONTACT_INFO_CARD,
  PRO_ACCOUNT_DETAIL_SECTION,
  PRO_ACCOUNT_OVERVIEW_CARD,
} from '@/constants/prosAccount';

import ProsDetailsContactInfoEdit from './ContactInfo';
import ProsDetailsOverviewEdit from './Overview';
import ProsDetailsOverviewDeactivateAccount from './Overview/DeactivateAccount';
import ProsDetailsOverviewStatusEdit from './Overview/Status';
import ProsDetailsPaymentEdit from './Payment';
import ProsDetailsRecruitmentEdit from './Recruitment';
import ProsDetailsOverviewLocationEdit from './ContactInfo/Location';

const ProsContentDetailsEdit = () => {
  const { section: currentSection, status } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case PRO_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      switch (status) {
        case PRO_ACCOUNT_OVERVIEW_CARD.STATUS:
          EditComponent = <ProsDetailsOverviewStatusEdit />;
          break;
        case PRO_ACCOUNT_OVERVIEW_CARD.DEACTIVATE:
          EditComponent = <ProsDetailsOverviewDeactivateAccount />;
          break;
        default:
          EditComponent = <ProsDetailsOverviewEdit />;
          break;
      }
      break;
    case PRO_ACCOUNT_DETAIL_SECTION.CONTACT_INFO:
      switch (status) {
        case PRO_ACCOUNT_CONTACT_INFO_CARD.LOCATION:
          EditComponent = <ProsDetailsOverviewLocationEdit />;
          break;
        default:
          EditComponent = <ProsDetailsContactInfoEdit />;
          break;
      }
      break;
    case PRO_ACCOUNT_DETAIL_SECTION.RECRUITMENT:
      EditComponent = <ProsDetailsRecruitmentEdit />;
      break;
    case PRO_ACCOUNT_DETAIL_SECTION.PAYMENT:
      EditComponent = <ProsDetailsPaymentEdit />;
      break;
    default:
      EditComponent = <>Unhandled</>;
      break;
  }

  return EditComponent;
};

export default ProsContentDetailsEdit;
