import React from 'react';

import { isEmpty } from 'lodash';
import mx from 'mixpanel-browser';
import { Form, useDataProvider, useNotify, useShowContext } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';

import { QUOTE_PRODUCT_BRE_PRICE_PARTNERS } from '@/constants/quotePartners';
import ProductForm from '../shared/ProductForm';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const AddProduct = () => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Product created', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Product added on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error creating Product', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred adding product on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = ({ amount, type }) => {
    const operationsNeeded = [
      {
        op: 'add',
        path: '/products',
        value: {
          product_id: type,
          estimated_quantity: 1,
          estimated_unit_price: Number(amount),
        },
      },
      QUOTE_PRODUCT_BRE_PRICE_PARTNERS.includes(quoteRecord?.partner_id)
        ? {
            op: 'replace',
            path: '/refresh_prices',
            value: {},
          }
        : {},
    ].filter((op) => !isEmpty(op));

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  return (
    <Form record={{ amount: 0, type: '' }} onSubmit={onSubmit}>
      <ProductForm />
      <FormControls />
    </Form>
  );
};

export default AddProduct;
