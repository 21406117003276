import React, { useContext, useState } from 'react';

import { usePermissions, useRecordContext } from 'react-admin';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';

const SlotActionMenu = () => {
  const { setAction, setMetadata } = useContext(OrderActionContext);
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSlotActionMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeSlotActionMenu = (event) => {
    if (event) {
      // This is specifically needed for this menu due to the row underneath the menu being clickable
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const menuItems = [
    permissions?.tools?.hqadmin?.job_slot_clock_times?.update ? (
      <MenuItem
        key="update_job_slot_clock_times"
        onClick={(event) => {
          setAction('update_job_slot_clock_times');
          setMetadata({ job_slot_id: record?.id });

          closeSlotActionMenu(event);
        }}
      >
        Edit clock in/out times
      </MenuItem>
    ) : null,
    permissions?.tools?.hqadmin?.job_slots?.delete ? (
      <MenuItem
        key="delete_job_slot"
        onClick={(event) => {
          setAction('delete_job_slot');
          setMetadata({ job_slot_id: record?.id });

          closeSlotActionMenu(event);
        }}
      >
        Remove Job Slot
      </MenuItem>
    ) : null,
  ].filter((item) => !!item);

  return menuItems.length > 0 ? (
    <Box>
      <IconButton onClick={handleSlotActionMenu}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeSlotActionMenu}>
        {menuItems}
      </Menu>
    </Box>
  ) : null;
};

export default SlotActionMenu;
