import React, { useContext } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import {
  DateInput,
  Form,
  FunctionField,
  Labeled,
  SelectInput,
  TimeInput,
  useGetOne,
  useNotify,
  useReference,
  useShowContext,
  useUpdate,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { Schedule } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { ORDER_CREW_EDIT_TIME_LOG_REASONS, WARNING_ALERT_ORDER_TEXT } from '@/constants/order';
import { OrderActionContext } from '@/providers/OrderAction';
import OrderAlertField from '@/resources/orders/Show/OrderContent/Common/OrderAlertField';

dayjs.extend(utc);
dayjs.extend(timezone);

const UpdateClockTimesForm = ({ clockInTimezone, clockOutTimezone }) => {
  const { setAction } = useContext(OrderActionContext);

  const enteredClockInDate = useWatch({ name: 'clock_in_date' });
  const enteredClockInTime = useWatch({ name: 'clock_in_time' });
  const enteredClockOutDate = useWatch({ name: 'clock_out_date' });
  const enteredClockOutTime = useWatch({ name: 'clock_out_time' });

  const validAllEnteredTimes = enteredClockInDate && enteredClockInTime && enteredClockOutDate && enteredClockOutTime;

  const { record } = useShowContext();

  let clockDuration;
  if (validAllEnteredTimes) {
    const clockInDateTime = dayjs(enteredClockInDate)
      .hour(dayjs(enteredClockInTime).hour())
      .minute(dayjs(enteredClockInTime).minute())
      .second(0);
    const clockOutDateTime = dayjs(enteredClockOutDate)
      .hour(dayjs(enteredClockOutTime).hour())
      .minute(dayjs(enteredClockOutTime).minute())
      .second(0);

    clockDuration = clockOutDateTime.diff(clockInDateTime, 'hours', true).toFixed(2);
  }
  return (
    <>
      <Box display="flex" justifyContent="space-between" mx={4} mr={6}>
        <Box>
          <DateInput source="clock_in_date" label="Clock In" />
        </Box>
        <Box>
          <TimeInput source="clock_in_time" label="Clock In" />
        </Box>
        <Box mt={1} ml={2}>
          <Labeled label="Worker's Local Time">
            <FunctionField
              render={() => (enteredClockInTime ? dayjs(enteredClockInTime).tz(clockInTimezone).format('h:mm A') : null)}
            />
          </Labeled>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mx={4} mr={6}>
        <Box>
          <DateInput source="clock_out_date" label="Clock Out" />
        </Box>
        <Box>
          <TimeInput source="clock_out_time" label="Clock Out" />
        </Box>
        <Box mt={1} ml={2}>
          <Labeled label="Worker's Local Time">
            <FunctionField
              render={() => (enteredClockOutTime ? dayjs(enteredClockOutTime).tz(clockOutTimezone).format('h:mm A') : null)}
            />
          </Labeled>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mx={4} mr={6}>
        <SelectInput
          source="time_log_edit_reason"
          choices={ORDER_CREW_EDIT_TIME_LOG_REASONS}
          label="Update time log reasons"
          fullWidth
          required
        />
      </Box>
      {clockDuration ? (
        <Box textAlign="center" pb={2}>
          <Schedule />
          <Typography>{clockDuration ?? 'test'} hours</Typography>
        </Box>
      ) : null}
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between" sx={{ maxWidth: 700 }}>
        <OrderAlertField
          variant="filled"
          severity="warning"
          text={WARNING_ALERT_ORDER_TEXT.PAST_8_DAYS_RECONCILE}
          orderStartDate={record?.start_date_time}
        />
      </Box>
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

UpdateClockTimesForm.propTypes = {
  clockInTimezone: string.isRequired,
  clockOutTimezone: string.isRequired,
};

const UpdateClockTimes = () => {
  const { setAction, metadata: { job_slot_id: jobSlotId } = {} } = useContext(OrderActionContext);
  const { data: slotData, isLoading: recordIsLoading } = useGetOne(
    resources.JOB_SLOTS,
    { id: jobSlotId },
    { enabled: Boolean(jobSlotId) },
  );

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: slotData?.job_id,
    options: { enabled: Boolean(slotData?.job_id) },
  });

  const [update, { isLoading: updateIsLoading }] = useUpdate();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const onSettled = (data, error) => {
    if (error) {
      const message = error?.body?.detail ?? error.message;
      notify(`Edit Clock in/out failed - ${error.status} - ${message}`, {
        type: 'error',
      });
      mx.track('Order Management - Error Edit Clock in/out', {
        resource: resources.JOB_SLOTS,
        resourceId: jobSlotId,
        error,
      });
    } else if (data) {
      mx.track('Order Management - Edit Clock in/out', {
        resource: resources.JOB_SLOTS,
        resourceId: jobSlotId,
      });

      notify('Edit Clock in/out successful', { type: 'success' });
      setAction(null);
      // Invalidate event history after update
      queryClient.invalidateQueries({
        queryKey: ['job_slots', 'getList', { meta: { resourceId: jobSlotId, subResource: 'event_history' } }],
      });
    }
  };

  const onSubmit = (submittedData) => {
    const newClockIn = submittedData.clock_in_time;
    const newClockInDate = newClockIn ? dayjs(newClockIn) : null;
    const newClockOut = submittedData.clock_out_time;
    const newClockOutDate = newClockOut ? dayjs(newClockOut) : null;

    const newData = {
      ...(newClockIn && {
        clock_in_date_time: dayjs(submittedData?.clock_in_date)
          .hour(newClockInDate.hour())
          .minute(newClockInDate.minute())
          .second(0),
        clock_in_timezone: jobData?.start_timezone,
      }),
      ...(newClockOut && {
        clock_out_date_time: dayjs(submittedData?.clock_out_date)
          .hour(newClockOutDate.hour())
          .minute(newClockOutDate.minute())
          .second(0),
        clock_out_timezone: jobData?.end_timezone,
      }),
      time_log_edit_reason: submittedData.time_log_edit_reason,
    };

    update(
      resources.JOB_SLOTS,
      {
        id: jobSlotId,
        data: newData,
        meta: {
          subResource: 'clock_times',
        },
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={575}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Edit Clock in/out Times
      </Typography>
      <Divider />
      <Box>
        {!slotData || !jobData || recordIsLoading || updateIsLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form
            onSubmit={onSubmit}
            record={{
              ...slotData,
              clock_in_date: new Date(slotData.clock_in_date_time ?? jobData?.start_date_time),
              clock_in_time: slotData.clock_in_date_time ? new Date(slotData.clock_in_date_time) : null,
              clock_out_date: new Date(slotData.clock_out_date_time ?? jobData?.end_date_time),
              clock_out_time: slotData.clock_out_date_time ? new Date(slotData.clock_out_date_time) : null,
            }}
          >
            <Box my={2} mx={2}>
              <Typography variant="body">
                <strong>Note:</strong> Select times in your timezone
              </Typography>
            </Box>
            <UpdateClockTimesForm clockInTimezone={jobData?.start_timezone} clockOutTimezone={jobData?.end_timezone} />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default UpdateClockTimes;
