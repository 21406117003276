import React from 'react';
import { RecordContextProvider, useGetOne, useShowContext } from 'react-admin';

import { Alert, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import PaymentMethodAccount from './PaymentMethodAccount';
import PaymentMethodCard from './PaymentMethodCard';

const PaymentMethods = () => {
  const { record: { id: orderId } = {} } = useShowContext();

  const { data, error, isLoading, isFetching, isError } = useGetOne(resources.ORDERS, {
    id: orderId,
    meta: {
      subResource: 'payment_info',
    },
  });

  if (!orderId) return null;

  return (
    <Card component={Box} mb={2}>
      <CardHeader title="Payment Method" />
      <Divider />
      {isError || error ? (
        <Box p={2}>
          <Alert variant="filled" severity="error" pt={0}>
            <Typography variant="body2">Error</Typography>
            <Typography variant="body2" component={Box} mt={1}>
              Could not load payment method for account - possibly partner paid order with no customer payment method
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {isLoading || isFetching ? (
        <CardContent component={Box} textAlign="center">
          <CircularProgress />
        </CardContent>
      ) : null}
      {data ? (
        <CardContent>
          <RecordContextProvider value={data}>
            {data.stripe_card_id ? <PaymentMethodCard /> : <PaymentMethodAccount />}
          </RecordContextProvider>
        </CardContent>
      ) : null}
    </Card>
  );
};

export default PaymentMethods;
