import React, { useContext, useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import mx from 'mixpanel-browser';
import { bool } from 'prop-types';
import { Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

import CardInput from '@/shared/stripe/CardInput';

const EditPaymentMethodForm = ({ isUpdating }) => {
  const { setAction } = useContext(OrderActionContext);

  return (
    <>
      <Box p={2}>
        <Typography>
          <strong>Note:</strong> This only updates the payment method for open transactions on order, to set a new default
          payment method for the customer account, please open the account page and update.
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <Box mb={2}>
          <CardInput />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isUpdating ?? null}
          endIcon={isUpdating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

EditPaymentMethodForm.propTypes = {
  isUpdating: bool.isRequired,
};

const EditPaymentMethod = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [update, { isLoading }] = useUpdate();

  const notify = useNotify();
  const refresh = useRefresh();

  const stripe = useStripe();
  const elements = useElements();

  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error updating payment method - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track(`Order Management - Payment Method updated`, {
        resource: resources.ORDERS,
        resourceId: orderId,
        confirmationId,
      });

      notify('Charge updated', {
        type: 'success',
      });
      refresh();
      setAction(null);
    }
  };

  const onSubmit = ({ cardholderName }) => {
    setIsStripeLoading(true);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement, { name: cardholderName }).then((result) => {
      if (result.token) {
        const paymentMethod = {
          provider: 'STRIPE_TOKEN',
          token: result.token.id,
        };
        update(
          resources.ORDERS,
          {
            id: orderId,
            meta: {
              subResource: 'payment_method',
            },
            data: paymentMethod,
          },
          { onSettled },
        );
      } else if (result.error) {
        notify(result.error.message, { type: 'error' });
      }
      setIsStripeLoading(false);
    });
  };

  return (
    <Box px={2} py={4} minWidth={500} maxWidth={500}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Edit Payment Method
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={onSubmit}>
          <EditPaymentMethodForm isUpdating={isLoading || isStripeLoading} />
        </Form>
      </Box>
    </Box>
  );
};

export default EditPaymentMethod;
