import React from 'react';

import { CreateBase, Form, SaveButton, useNotify } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';

import InputFilterSection from './Common/InputFilterSection';

const CreateAvailabilityConfiguration = () => {
  const availConfigObj = {
    name: '',
    type: '',
    start_date: null,
    end_date: null,
    start_time: '08:00:00.000',
    end_time: '22:00:00.000',
    all_qualifications: false,
    all_services: false,
    all_markets: false,
    qualification_level: 0,
    quantity: 0,
    markets: null,
    products: null,
  };

  const notify = useNotify();

  const onError = (error) => {
    notify(`Error on creating availability configuration - ${error.status} - ${error.message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    notify(`Availability configuration ${data?.type} has been created!`, {
      type: 'success',
    });
  };

  return (
    <CreateBase record={availConfigObj} mutationOptions={{ onError, onSuccess }}>
      <Card mt={3} component={Box}>
        <CardHeader title="Availability Configuration" />

        <Divider />
        <Form>
          <CardContent>
            <InputFilterSection />
          </CardContent>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="flex-end">
              <SaveButton label="Save" icon={null} />
            </Box>
          </CardContent>
        </Form>
      </Card>
    </CreateBase>
  );
};

export default CreateAvailabilityConfiguration;
