import React from 'react';

import { string } from 'prop-types';

import { Box } from '@mui/material';

import { ORDER_TABS } from '@/constants/order';

import OrderContentCompliance from './Compliance';
import OrderContentCrewInfo from './CrewInfo';
import OrderContentDetails from './Details';
import OrderContentInventory from './Inventory';
import OrderContentInvoice from './Invoice';
import OrderContentLocations from './Locations';
import OrderContentHistory from './History';
import OrderContentSupport from './Support';

const OrderContent = ({ currentTab }) => {
  let content;
  switch (currentTab) {
    case ORDER_TABS.DETAILS:
      content = <OrderContentDetails />;
      break;
    case ORDER_TABS.INVOICE:
      content = <OrderContentInvoice />;
      break;
    case ORDER_TABS.CREW_INFO:
      content = <OrderContentCrewInfo />;
      break;
    case ORDER_TABS.LOCATIONS:
      content = <OrderContentLocations />;
      break;
    case ORDER_TABS.INVENTORY:
      content = <OrderContentInventory />;
      break;
    case ORDER_TABS.COMPLIANCE:
      content = <OrderContentCompliance />;
      break;
    case ORDER_TABS.HISTORY:
      content = <OrderContentHistory />;
      break;
    case ORDER_TABS.SUPPORT:
      content = <OrderContentSupport />;
      break;
    default:
      content = <>Unhandled</>;
  }
  return <Box>{content}</Box>;
};

OrderContent.propTypes = {
  currentTab: string.isRequired,
};

export default OrderContent;
