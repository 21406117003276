import React, { useState } from 'react';

import _ from 'lodash';
import { number, string } from 'prop-types';
import { BooleanInput, NumberInput, SelectInput, useRecordContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import HiddenInput from '@/components/inputs/HiddenInput';
import { INVENTORY_ADDITIONAL_ITEMS, INVENTORY_ROOMS } from '@/constants/quotes';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import Incrementer from '@/shared/Incrementer';
import { INVENTORY_AREA_CHOICES } from '@/constants/inventory';
import { QUOTE_PARTNER_INVENTORY_AREA_CHOICES } from '@/constants/quotePartners';

import OfferpadInventoryInput from '../../shared/OfferpadInventoryInput';
import ZippyInventoryInput from '../../shared/ZippyInventoryInput';

const RoomIncrementer = ({ roomSlug, index }) => {
  const record = useRecordContext();
  const { setValue } = useFormContext();

  const initialValue = _.find(record?.inventory?.rooms, (room) => room.slug === roomSlug)?.count;

  const [roomCount, setRoomCount] = useState(initialValue ?? 0);

  const updateRoomCount = (newValue) => {
    setRoomCount(newValue);
    setValue(`inventory.rooms.${index}.count`, newValue, { shouldDirty: true });
  };

  return (
    <Box display="flex" alignItems="center">
      <HiddenInput source={`inventory.rooms.${index}.count`} />
      <Incrementer value={roomCount} setValue={updateRoomCount} minLimit={0} maxLimit={10} />
      <TextFieldWrapper value={INVENTORY_ROOMS[roomSlug]?.name} />
    </Box>
  );
};

RoomIncrementer.propTypes = {
  roomSlug: string.isRequired,
  index: number.isRequired,
};

const AreaInput = () => {
  const partner = useWatch({ name: 'partner_id' });
  let areaChoices = INVENTORY_AREA_CHOICES;

  if (QUOTE_PARTNER_INVENTORY_AREA_CHOICES[partner]) {
    areaChoices = QUOTE_PARTNER_INVENTORY_AREA_CHOICES[partner];
  }

  return (
    <>
      <HiddenInput source="inventory.area" />
      <Box width={150} mb={3}>
        <SelectInput label="Area / Sq Ft" source="inventory.area" choices={areaChoices} required fullWidth />
      </Box>
    </>
  );
};

const ItemIncrementer = ({ itemSlug, index }) => {
  const { setValue } = useFormContext();

  const [roomCount, setRoomCount] = useState(0);

  const updateRoomCount = (newValue) => {
    setRoomCount(newValue);
    setValue(`inventory.additional_items.${index}.count`, newValue, { shouldDirty: true });
  };

  return (
    <Box display="flex" alignItems="center">
      <HiddenInput source={`inventory.additional_items.${index}.count`} />
      <Incrementer value={roomCount} setValue={updateRoomCount} minLimit={0} maxLimit={10} />
      <TextFieldWrapper value={INVENTORY_ADDITIONAL_ITEMS[itemSlug]?.name} />
    </Box>
  );
};

ItemIncrementer.propTypes = {
  itemSlug: string.isRequired,
  index: number.isRequired,
};

const Inventory = () => {
  const record = useRecordContext();

  const partner = useWatch({ name: 'partner_id' });

  return (
    <Card my={3} component={Box}>
      <CardHeader title="Inventory" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <AreaInput />
          </Grid>
          <Grid item xs={1}>
            <NumberInput source="inventory.access_flags.stairs" label="Stairs" fullWidth min={0} />
          </Grid>
          <Grid item xs={2} mt={2} ml={2}>
            <BooleanInput source="inventory.access_flags.elevator" label="Elevator" />
          </Grid>
          <Grid item xs={3} mt={2}>
            <BooleanInput source="inventory.access_flags.elevator_reserved" label="Elevator Reserved" />
          </Grid>
          <Grid item xs={3} mt={2}>
            <BooleanInput source="inventory.access_flags.long_walk_to_truck" label="Long Walk to Truck" />
          </Grid>
        </Grid>
        {!['OFFERPAD', 'ZIPPY'].includes(partner) ? (
          <>
            <Grid container spacing={2}>
              {record?.inventory?.rooms?.map((room, index) => (
                <Grid item xs={12 / 2} md={12 / 3} lg={12 / 5} key={room.slug}>
                  <RoomIncrementer roomSlug={room.slug} index={index} />
                </Grid>
              ))}
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid container spacing={2}>
              {record?.inventory?.additional_items?.map((item, index) => (
                <Grid item xs={12 / 2} md={12 / 3} lg={12 / 5} key={item.slug}>
                  <ItemIncrementer itemSlug={item.slug} index={index} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
        {partner === 'OFFERPAD' ? <OfferpadInventoryInput /> : null}
        {partner === 'ZIPPY' ? <ZippyInventoryInput /> : null}
      </CardContent>
    </Card>
  );
};

export default Inventory;
