import { useMemo } from 'react';

import { useNotify, useRefresh, useUpdate } from 'react-admin';

import * as resources from '@/api/resources';

const useReconcileJobSlotTransactions = () => {
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    notify(`Error reconciling slot transactions - ${error.status} - ${error.message}`, { type: 'error' });
  };

  const onSuccess = () => {
    refresh();
    notify('Slot transactions reconciled', { type: 'success' });
  };

  const reconcileTransactions = ({ jobSlotId }) => {
    update(
      resources.JOB_SLOTS,
      {
        id: jobSlotId,
        meta: {
          subResource: 'reconcile_transactions',
        },
      },
      {
        onError,
        onSuccess,
      },
    );
  };

  const data = useMemo(
    () => ({
      reconcileTransactions,
    }),
    [],
  );

  return [data, { isLoading }];
};

export default useReconcileJobSlotTransactions;
