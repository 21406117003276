import React from 'react';

import { useRecordContext } from 'react-admin';

import HistoryOfCharges from './HistoryOfCharges';
import PaymentMethodCardList from './PaymentMethodCardList';

const CustomerContentCharges = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <>
      <PaymentMethodCardList />
      <HistoryOfCharges />
    </>
  );
};

export default CustomerContentCharges;
