const PRODUCT_NAMES = {
  ADDITIONALSTOP: 'Additional Stop',
  ASSEMBLY: 'Assembly',
  CONTRACT_PRICE: 'Contract Price',
  DISASSEMBLY: 'Disassembly',
  EXCESSMILEAGE: 'Excess Mileage',
  EXCESSQUAREFOOTAGE: 'Excess Square Footage',
  PACKINGMATERIALS: 'Packing Materials',
};

const PRODUCTS = {
  ADDITIONALSTOP: {
    id: 'ADDITIONALSTOP',
    name: 'Additional Stop',
  },
  ADJACENTMARKET: {
    id: 'ADJACENTMARKET',
    name: 'Adjacent Market',
  },
  ASSEMBLY: {
    id: 'ASSEMBLY',
    name: 'Assembly',
  },
  CONTRACT_PRICE: {
    id: 'CONTRACT_PRICE',
    name: 'Contract Price',
  },
  DISASSEMBLY: {
    id: 'DISASSEMBLY',
    name: 'Disassembly',
  },
  ELEVATOR: {
    id: 'ELEVATOR',
    name: 'Elevator',
  },
  EXCESSMILEAGE: {
    id: 'EXCESSMILEAGE',
    name: 'Excess Mileage',
  },
  EXCESSQUAREFOOTAGE: {
    id: 'EXCESSQUAREFOOTAGE',
    name: 'Excess Square Footage',
  },
  LONGHAUL: {
    id: 'LONGHAUL',
    name: 'Long Haul',
  },
  PACKINGMATERIALS: {
    id: 'PACKINGMATERIALS',
    name: 'Packing Materials',
  },
  RCV_INSURANCE: {
    id: 'RCV_INSURANCE',
    name: 'Replacement Value Valuation Coverage',
  },
  STAIRS: {
    id: 'STAIRS',
    name: 'Stairs',
  },
};

export { PRODUCTS, PRODUCT_NAMES };
