import { node } from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

const OrderActionContext = createContext({});

const OrderActionProvider = ({ children }) => {
  const [action, setAction] = useState('');
  const [metadata, setMetadata] = useState({});

  const value = useMemo(() => ({ action, setAction, metadata, setMetadata }), [action, metadata]);

  return <OrderActionContext.Provider value={value}>{children}</OrderActionContext.Provider>;
};

OrderActionProvider.propTypes = {
  children: node.isRequired,
};

export { OrderActionContext, OrderActionProvider };
