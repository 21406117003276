import React from 'react';

import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { Grid, ImageList, ImageListItem, List, ListItem, ListItemText, Typography } from '@mui/material';

const CustomerWalkthroughForm = ({ source, imageListStyle, imageStyle, listItemTextStyle }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  const value = record[source];

  return (
    <Typography component="div">
      <List>
        {value?.map((obj) => (
          <ListItem key={obj.id}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <ListItemText
                  primary={`Question: ${obj.question}`}
                  secondary={`\nUnboxedTVs:\n${obj.unboxed_tvs}`}
                  sx={listItemTextStyle}
                />
              </Grid>
              <Grid item xs={4}>
                <ImageList sx={imageListStyle} cols={6} rowHeight={100}>
                  {obj.photos?.length
                    ? obj.photos.map((photo) => (
                        <ImageListItem key={`${obj.id}-${photo}`}>
                          <a href={photo} target="_blank" rel="noreferrer">
                            <img src={photo} srcSet={photo} alt={obj.id} loading="lazy" style={imageStyle} />
                          </a>
                        </ImageListItem>
                      ))
                    : ''}
                </ImageList>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Typography>
  );
};

CustomerWalkthroughForm.propTypes = {
  source: string.isRequired,
  imageListStyle: shape({}).isRequired,
  imageStyle: shape({}).isRequired,
  listItemTextStyle: shape({}).isRequired,
};

export default CustomerWalkthroughForm;
