import React from 'react';

import _ from 'lodash';
import { bool, number, string } from 'prop-types';
import { Datagrid, NumberField, Pagination, useListContext, useRecordContext } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { ChangeHistory, Remove, TrendingDown, TrendingUp } from '@mui/icons-material';
import { Alert, Box, Card, CircularProgress, Divider, Typography } from '@mui/material';

import ListBase from '@/ReactAdmin/List/ListBase';
import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import DayJsField from '@/fields/DayJsField';
import MarketNameField from '@/fields/MarketNameField';
import ServiceNameField from '@/fields/ServiceNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import EmptyResourceList from '@/shared/EmptyResourceList';
import formatCurrency from '@/utils/currency/formatCurrency';

const DeltaLabel = ({ text }) => (
  <Box display="flex" justifyContent="flex-end" alignItems="center" gap={0.5} textAlign="right">
    <span>{text}</span>
    <ChangeHistory fontSize="small" />
  </Box>
);

DeltaLabel.propTypes = {
  text: string.isRequired,
};

const DeltaIcon = ({ delta }) => {
  if (delta < 0) {
    return <TrendingDown fontSize="small" color="error" />;
  }
  if (delta > 0) {
    return <TrendingUp fontSize="small" color="success" />;
  }
  return <Remove fontSize="small" color="neutral" />;
};

DeltaIcon.propTypes = {
  delta: number.isRequired,
};

const DeltaField = ({ source, inputName, isCurrency }) => {
  const record = useRecordContext();

  const inputValue = useWatch({ name: inputName });

  if (!source) return null;

  const delta = (inputValue ?? 0) - (record[source] ?? 0);

  return (
    <Box display="flex" justifyContent="space-between" minWidth={75}>
      <DeltaIcon delta={delta} />
      <TextFieldWrapper value={isCurrency ? formatCurrency(delta) : delta} />
    </Box>
  );
};

DeltaField.propTypes = {
  source: string.isRequired,
  inputName: string.isRequired,
  isCurrency: bool,
};

DeltaField.defaultProps = {
  isCurrency: true,
};

const PriceDeltaCard = ({ minimumInputValid }) => {
  const { isFetching, isLoading, total } = useListContext();

  return (
    <Card component={Box} mt={2}>
      <Box py={2} px={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Price Delta</Typography>
        <Alert variant="filled" severity="info">
          Delta is displayed against <strong>current</strong> pricing, not any upcoming changes
        </Alert>
      </Box>
      <Divider />
      {minimumInputValid ? (
        <>
          {isFetching || isLoading ? (
            <Box display="flex" justifyContent="center" py={2}>
              <CircularProgress size={50} />
            </Box>
          ) : null}
          <Datagrid
            isLoading={isFetching || isLoading}
            bulkActionButtons={false}
            empty={<EmptyResourceList text="No layers to compare against" />}
          >
            <ServiceNameField source="service_id" />
            <DayJsField format="MM/DD/YYYY" source="effective_date" />
            <MarketNameField source="market_id" label="Market" />
            <CurrencyField source="price" />
            <DeltaField label={<DeltaLabel text="Price" />} textAlign="right" source="price" inputName="price" />
            <NumberField source="multiplier" emptyText="N/A" />
            <DeltaField
              label={<DeltaLabel text="Multiplier" />}
              textAlign="right"
              source="multiplier"
              inputName="multiplier"
              isCurrency={false}
            />
          </Datagrid>
          {total > 0 ? <Pagination /> : null}
        </>
      ) : (
        <EmptyResourceList text="Minimum inputs required" />
      )}
    </Card>
  );
};

PriceDeltaCard.propTypes = {
  minimumInputValid: bool.isRequired,
};

const PriceDelta = () => {
  const selectedEffectiveDate = useWatch({ name: 'effective_date' });
  const selectedDateNumericType = useWatch({ name: 'date_numeric_type' });
  const selectedDateNumeric = useWatch({ name: 'date_numeric' });
  const selectedMarketIds = useWatch({ name: 'market_ids' });
  const selectedServiceIds = useWatch({ name: 'service_ids' });

  const minimumInputValid = Boolean(
    selectedEffectiveDate &&
      selectedMarketIds &&
      selectedServiceIds &&
      selectedDateNumeric &&
      !_.isEmpty(selectedMarketIds) &&
      !_.isEmpty(selectedServiceIds) &&
      !_.isEmpty(selectedDateNumeric),
  );

  return (
    <ListBase
      resource={resources.PRICING_LAYERS}
      filter={{
        is_current: true,
        service_id: selectedServiceIds,
        market_id: selectedMarketIds,
        ...(selectedDateNumericType && selectedDateNumeric ? { [selectedDateNumericType]: selectedDateNumeric } : {}),
        type: selectedDateNumericType,
      }}
      sort={{ field: 'market_id', order: 'DESC' }}
      storeKey={`${resources.PRICING_LAYERS}.date.price_delta`}
      queryOptions={{
        enabled: minimumInputValid,
      }}
    >
      <PriceDeltaCard minimumInputValid={minimumInputValid} />
    </ListBase>
  );
};

export default PriceDelta;
