import React, { useContext, useState } from 'react';
import { usePermissions, useShowContext } from 'react-admin';

import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';

import { ORDER_STATUS } from '@/constants/orderStatus';
import useReconcileOrderTransactions from '@/hooks/transactions/useReconcileOrderTransactions';
import { OrderActionContext } from '@/providers/OrderAction';

const OrderTransactionsMenu = () => {
  const { record: { id: orderId, status: orderStatus } = {}, isLoading } = useShowContext();
  const { setAction } = useContext(OrderActionContext);
  const { permissions } = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleTransactionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeTransactionMenu = () => setAnchorEl(null);

  const [{ reconcileTransactions }, { isLoading: reconcileTransactionsIsLoading }] = useReconcileOrderTransactions();

  if (isLoading) {
    return null;
  }

  const menuItems = [
    permissions?.tools.hqadmin?.order_price_adjustments?.create ? (
      <MenuItem
        key="add_discount"
        onClick={() => {
          setAction('add_discount');
          closeTransactionMenu();
        }}
      >
        Add discount
      </MenuItem>
    ) : null,
    permissions?.tools.hqadmin?.order_price_adjustments?.create && orderStatus !== ORDER_STATUS.CREATED.toUpperCase() ? (
      <MenuItem
        key="add_fee"
        onClick={() => {
          setAction('add_fee');
          closeTransactionMenu();
        }}
      >
        Add fee
      </MenuItem>
    ) : null,
    permissions?.tools.hqadmin?.order_settlements?.create ? (
      <MenuItem
        key="add_settlement"
        onClick={() => {
          setAction('add_settlement');
          closeTransactionMenu();
        }}
      >
        Add settlement
      </MenuItem>
    ) : null,
    permissions?.tools.hqadmin?.tips?.create ? (
      <MenuItem
        key="add_tips"
        onClick={() => {
          setAction('add_tips');
          closeTransactionMenu();
        }}
      >
        Add tips
      </MenuItem>
    ) : null,
    permissions?.tools.hqadmin?.order_charges?.create ? (
      <MenuItem
        key="create_charge"
        onClick={() => {
          setAction('create_charge');
          closeTransactionMenu();
        }}
      >
        Create Charge
      </MenuItem>
    ) : null,
    permissions?.tools.hqadmin?.order_payment_method?.update ? (
      <MenuItem
        key="edit_payment_method"
        onClick={() => {
          setAction('edit_payment_method');
          closeTransactionMenu();
        }}
      >
        Edit Payment Method
      </MenuItem>
    ) : null,
    permissions?.tools.hqadmin?.order_transactions?.reconcile ? (
      <MenuItem
        key="reconcile_transactions"
        onClick={() => {
          reconcileTransactions({ orderId });
          closeTransactionMenu();
        }}
        disabled={reconcileTransactionsIsLoading ?? null}
      >
        Reconcile Transactions
      </MenuItem>
    ) : null,
  ].filter((item) => item); // Filter out null entries

  return menuItems.length > 0 ? (
    <>
      <IconButton onClick={handleTransactionMenu}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeTransactionMenu}>
        {menuItems}
      </Menu>
    </>
  ) : null;
};

export default OrderTransactionsMenu;
