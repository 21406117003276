import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, useCreate, useNotify, useRefresh, useShowContext, TextInput } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

import CurrencyInput from '../AddTips/CurrencyInput';
import ADJUSTMENT_REASONS from './adjustmentReasons';

const AddPriceAdjustmentForm = () => {
  const { setAction } = useContext(OrderActionContext);
  const { control } = useFormContext();

  return (
    <>
      <Box p={2}>
        <Box mb={2}>
          <Controller
            defaultValue=""
            name="unitprice"
            control={control}
            rules={{ max: 1000 }}
            render={({ field, fieldState }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="unitprice-label">Amount</InputLabel>
                <CurrencyInput field={field} fieldState={fieldState} />
                {fieldState?.error && fieldState?.error?.type === 'max' ? (
                  <Typography variant="body2" color="error.main" component={Box} pt={1}>
                    Maximum price adjustment of $1,000 allowed
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            defaultValue=""
            name="reason"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="reason-label">Reason</InputLabel>
                <Select labelId="reason-label" label="Reason" {...field} required>
                  {ADJUSTMENT_REASONS.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            defaultValue=""
            name="context"
            control={control}
            render={({ field }) => <TextField variant="outlined" label="Context" fullWidth {...field} />}
          />
        </Box>
        <Box>
          <TextInput source="zendeskId" label="Zendesk Ticket Id" variant="outlined" fullWidth />
        </Box>
        <Box>
          <TextInput source="zendeskContext" label="Zendesk Context" variant="outlined" multiline fullWidth />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const AddPriceAdjustment = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error adding price adjustment - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track('Order Management - Price Adjustment created', {
        resource: resources.ORDERS,
        resourceId: orderId,
        confirmationId,
      });

      notify('Price Adjustment created', {
        type: 'success',
      });
      refresh();
      setAction(null);
    }
  };

  const onSubmit = ({ unitprice, reason, context, zendeskId, zendeskContext }) => {
    const actionMetadata = {
      zendesk_id: zendeskId,
      context: zendeskContext,
    };

    const data = {
      unitprice: parseInt(0 - Number(unitprice) * 100, 10), // subtract from zero for negative and multiply by 100 to get cents values
      reason,
      context,
      metadata: actionMetadata,
    };

    create(
      resources.ORDERS,
      {
        meta: {
          subResource: 'price_adjustments',
          resourceId: orderId,
        },
        data,
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Price Adjustment
      </Typography>
      <Divider />
      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form onSubmit={onSubmit}>
            <AddPriceAdjustmentForm />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default AddPriceAdjustment;
