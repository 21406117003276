import mx from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  FunctionField,
  Labeled,
  LoadingIndicator,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyField,
  ShowBase,
  TextField,
  useGetList,
  useReference,
  useShowContext,
  useStore,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { JOB_SLOT_STATUS } from '@/constants/jobSlotStatus';
import { PREFERENCES_KEYS, PREFERENCES_VALUES } from '@/constants/preferences';
import NullableReferenceField from '@/fields/NullableReferenceField';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import QualificationLevelField from '@/fields/QualificationLevelField';
import SlotAccountNameField from '@/fields/SlotAccountNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import LocationsContent from '@/shared/LocationsContent';
import OrderHeader from '@/shared/OrderHeader';
import RouteInfo from '@/shared/RouteInfo';
import prefDayJs from '@/utils/datetime/prefDayJs';
import getServiceName from '@/utils/serviceName/getServiceName';
import JobsSectionHeader from '../Common/SectionHeader';
import AsideActionDrawer from './AsideActionDrawer';

const JobSlotsDatagrid = () => (
  <Datagrid rowClick="show" bulkActionButtons={false}>
    <QualificationLevelField source="minimum_qualification_level" />
    <NullableReferenceField source="account_id" reference={resources.ACCOUNTS} label="Account">
      <SlotAccountNameField />
    </NullableReferenceField>
    <TextField source="status" />
    <PrefDateTimeField
      source="clock_in_date_time"
      timezoneSource="clock_in_timezone"
      label="Clock In"
      dayjsFormat="h:mm A"
      emptyText="Pending"
      tooltipFormat="M/D/YYYY h:mm A"
    />
    <PrefDateTimeField
      source="clock_out_date_time"
      timezoneSource="clock_out_timezone"
      label="Clock Out"
      dayjsFormat="h:mm A"
      emptyText="Pending"
      tooltipFormat="M/D/YYYY h:mm A"
    />
  </Datagrid>
);

const Job = () => {
  const [timePreference] = useStore(PREFERENCES_KEYS.UI.TIME_PREFERENCE);
  const { record, isLoading, isFetching } = useShowContext();

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: record?.order_id,
    enabled: Boolean(record?.order_id),
  });

  const {
    data: locationsData,
    isLoading: isLoadingLocations,
    isFetching: isFetchingLocations,
  } = useGetList(
    resources.LOCATIONS,
    {
      filter: { job_id: record?.id },
      sort: { field: 'sequence', order: 'ASC' },
    },
    { enabled: Boolean(record?.id) },
  );

  useEffect(() => {
    if (record) {
      mx.track('Resource Show - Viewed', {
        resource: resources.JOBS,
        resourceId: record.id,
        confirmationId: orderData?.confirmation_id,
      });
    }
  }, [record]);

  const [asideAction, setAsideAction] = useState('');

  if (!record || !orderData) return null;

  return (
    <Box width="100%" py={3}>
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <>
          <RecordContextProvider value={orderData}>
            <OrderHeader />
          </RecordContextProvider>
          <Card mt={3} component={Box}>
            <Box px={3} py={2} justifyContent="space-between" alignItems="center">
              <JobsSectionHeader title={getServiceName(record?.product_id)} setAsideAction={setAsideAction} />
            </Box>
            <Divider />
            <CardContent>
              <Box>
                <Grid container>
                  <Grid item xs={3}>
                    <Grid>
                      <Labeled label="Status">
                        <TextField source="status" />
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled
                        label={timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL ? 'Local Start' : 'Start'}
                      >
                        <FunctionField
                          render={(jobRecord) =>
                            prefDayJs({
                              date: jobRecord.start_date_time,
                              timezone: jobRecord.start_timezone,
                              timePreference,
                            }).format('MM/DD/YYYY, h:mm A')
                          }
                        />
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled label={timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL ? 'Local End' : 'End'}>
                        <FunctionField
                          render={(jobRecord) =>
                            prefDayJs({
                              date: jobRecord.end_date_time,
                              timezone: jobRecord.end_timezone,
                              timePreference,
                            }).format('MM/DD/YYYY, h:mm A')
                          }
                        />
                      </Labeled>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid>
                      <Labeled label="Pricing Strategy">
                        <TextField source="pricing_strategy" />
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled label="Start Market">
                        <ReferenceField source="start_market_id" reference={resources.MARKETS}>
                          <TextField source="name" />
                        </ReferenceField>
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled label="End Market">
                        <ReferenceField source="end_market_id" reference={resources.MARKETS}>
                          <TextField source="name" />
                        </ReferenceField>
                      </Labeled>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid>
                      <Labeled label="Scheduled Duration">
                        <FunctionField
                          render={(jobRecord) => <TextFieldWrapper value={Number(jobRecord.duration).toFixed(2)} />}
                        />
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled label="Billable Duration">
                        <FunctionField
                          render={(jobRecord) => <TextFieldWrapper value={Number(jobRecord.billable_duration).toFixed(2)} />}
                        />
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled label="Workers">
                        <TextField source="workers" />
                      </Labeled>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid>
                      <Labeled label="Expiration Date">
                        <PrefDateTimeField
                          source="marketplace_match_expiry"
                          dayjsFormat="MM/DD/YYYY, h:mm A"
                          timezoneSource="start_timezone"
                        />
                      </Labeled>
                    </Grid>

                    <Grid>
                      <Labeled label="Match Status">
                        <TextField source="marketplace_match_status" />
                      </Labeled>
                    </Grid>
                    <Grid>
                      <Labeled label="Supply Type">
                        <TextField source="marketplace_supply_type" />
                      </Labeled>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Card component={Box} mt={3}>
            <CardHeader title="Slots" />
            <ReferenceManyField
              reference={resources.JOB_SLOTS}
              target="job_id"
              filter={{
                status: [
                  JOB_SLOT_STATUS.ASSIGNED,
                  JOB_SLOT_STATUS.CANCELLED,
                  JOB_SLOT_STATUS.UNASSIGNED,
                  JOB_SLOT_STATUS.REMOVED,
                ],
              }}
              sort={{
                field: 'minimum_qualification_level',
                order: 'DESC',
              }}
              perPage={100}
            >
              <JobSlotsDatagrid />
            </ReferenceManyField>
          </Card>

          <Card component={Box} mt={3}>
            <CardHeader title="Locations" />
            <Divider />
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <LocationsContent data={locationsData} isLoading={isLoadingLocations || isFetchingLocations} />
              </Box>
              {locationsData?.length > 1 ? (
                <>
                  <Divider orientation="vertical" flexItem />
                  <RouteInfo locations={locationsData} />
                </>
              ) : null}
            </Box>
          </Card>
        </>
      )}
      <AsideActionDrawer asideAction={asideAction} open={Boolean(asideAction)} onClose={() => setAsideAction(null)} />
    </Box>
  );
};

const JobShow = () => (
  <ShowBase>
    <Job />
  </ShowBase>
);

export default JobShow;
