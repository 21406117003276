import React, { useState } from 'react';

import { string } from 'prop-types';
import { RecordContextProvider, useGetList, useGetOne, useRecordContext } from 'react-admin';

import { ExpandMore, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { THIRD_PARTY_CREW_ACCOUNT_PERFORMANCE_SECTION } from '@/constants/thirdPartyCrewAccount';
import PercentageField from '@/fields/PercentageField';

import PerformanceList from './List';

const createBody = (performanceObj) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%" p={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box>
            <IconButton onClick={handleExpandClick}>{expanded ? <ExpandMore /> : <KeyboardArrowRight />}</IconButton>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="h5" component="span">
              {performanceObj.headers.header1}
            </Typography>
            <Typography variant="h5" component="span" color="secondary">
              {performanceObj.headerData.headerdata1}
            </Typography>
            <Typography variant="h5" component="span">
              {performanceObj.headers.header2}
            </Typography>
            <Typography variant="h5" component="span" color="secondary">
              {performanceObj.headerData.headerdata2}
            </Typography>
            {performanceObj.headers.header3 ? (
              <>
                <Typography variant="h5" component="span">
                  {performanceObj.headers.header3}
                </Typography>
                <Typography variant="h5" component="span" color="secondary">
                  {performanceObj.headerData.headerdata3 ? 'Poor' : 'Good'}
                </Typography>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Collapse in={expanded} component={Box}>
        <Box p={2} pb={0}>
          <PerformanceList section={performanceObj.section} />
        </Box>
      </Collapse>
    </>
  );
};

const PerformanceCollapse = ({ section, title }) => {
  const record = useRecordContext();

  const { data } = useGetOne(
    resources.THIRD_PARTY_CREW,
    { id: record.id, meta: { subResource: 'worker_stats' } },
    { enabled: Boolean(record.id) },
  );

  const performanceObj = {
    textBody: '',
    headers: {
      header1: '',
      header2: '',
      header3: '',
    },
    headerData: {
      headerdata1: '',
      headerdata2: '',
      headerdata3: '',
    },
    data: '',
    section: '',
  };

  switch (section) {
    case THIRD_PARTY_CREW_ACCOUNT_PERFORMANCE_SECTION.RATING:
      performanceObj.headers.header1 = `${title}: `;
      performanceObj.headers.header2 = 'Job Count: ';
      performanceObj.headers.header3 = 'Status: ';
      performanceObj.headerData.headerdata1 = data?.average_rating;
      performanceObj.headerData.headerdata2 = data?.lifetime_completed_jobs;
      performanceObj.headerData.headerdata3 = record?.poor_performance;
      performanceObj.section = section;
      performanceObj.textBody = createBody(performanceObj);
      break;
    case THIRD_PARTY_CREW_ACCOUNT_PERFORMANCE_SECTION.LATE_RATE:
      performanceObj.data = useGetList(
        resources.THIRD_PARTY_CREW,
        {
          filter: { is_late: true },
          meta: { subResource: 'worker_stats/performance', resourceId: record?.id },
        },
        { enabled: Boolean(record?.id) },
      );
      performanceObj.headers.header1 = `${title}: `;
      performanceObj.headers.header2 = 'Job: ';
      performanceObj.headerData.headerdata1 = data?.late_rate ? (
        <RecordContextProvider value={data}>
          <PercentageField source="late_rate" textFieldProps={{ variant: 'h5' }} />
        </RecordContextProvider>
      ) : (
        0
      );
      performanceObj.headerData.headerdata2 = performanceObj.data.total;
      performanceObj.section = section;
      performanceObj.textBody = createBody(performanceObj);
      break;
    case THIRD_PARTY_CREW_ACCOUNT_PERFORMANCE_SECTION.DAMAGE:
      performanceObj.data = useGetList(
        resources.THIRD_PARTY_CREW,
        {
          filter: { is_damage: true },
          meta: { subResource: 'worker_stats/performance', resourceId: record?.id },
        },
        { enabled: Boolean(record?.id) },
      );
      performanceObj.headers.header1 = `${title}: `;
      performanceObj.headers.header2 = 'Job: ';
      performanceObj.headerData.headerdata1 = data?.damage_rate ? (
        <RecordContextProvider value={data}>
          <PercentageField source="damage_rate" textFieldProps={{ variant: 'h5' }} />
        </RecordContextProvider>
      ) : (
        0
      );
      performanceObj.headerData.headerdata2 = performanceObj.data.total;
      performanceObj.section = section;
      performanceObj.textBody = createBody(performanceObj);
      break;
    case THIRD_PARTY_CREW_ACCOUNT_PERFORMANCE_SECTION.REMOVAL:
      performanceObj.data = useGetList(
        resources.THIRD_PARTY_CREW,
        {
          filter: { status: 'Removed' },
          meta: { subResource: 'jobs', resourceId: record?.id },
        },
        { enabled: Boolean(record?.id) },
      );
      performanceObj.headers.header1 = `${title}: `;
      performanceObj.headers.header2 = 'Job: ';
      performanceObj.headerData.headerdata1 = data?.removal_rate ? (
        <RecordContextProvider value={data}>
          <PercentageField source="removal_rate" textFieldProps={{ variant: 'h5' }} />
        </RecordContextProvider>
      ) : (
        0
      );
      performanceObj.headerData.headerdata2 = performanceObj.data.total;
      performanceObj.section = section;
      performanceObj.textBody = createBody(performanceObj);
      break;
    default:
  }

  if (!record || !data) return null;

  return performanceObj.textBody;
};

PerformanceCollapse.propTypes = {
  section: string.isRequired,
  title: string.isRequired,
};

export default PerformanceCollapse;
