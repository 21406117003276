import React from 'react';
import { Box, Card, Typography } from '@mui/material';

const ViewUndefinedCard = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h5">View not defined</Typography>
    </Box>
  </Card>
);

export default ViewUndefinedCard;
