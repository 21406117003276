import React from 'react';

import { NumberInput, SelectInput, TextInput, usePermissions } from 'react-admin';

import { Box, Card, CardContent } from '@mui/material';

import { ADD_FEES_CHOICES, FEE_TYPES } from '@/constants/quotes';

const FeeForm = () => {
  const { permissions } = usePermissions();

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  const canAddBaseMileageFees = permissions?.tools?.hqadmin?.quote_base_mileage_fee?.update;

  const feeChoices = canAddBaseMileageFees
    ? [FEE_TYPES.BASE_FEE, FEE_TYPES.MILEAGE_FEE, ...ADD_FEES_CHOICES]
    : ADD_FEES_CHOICES;

  return (
    <Card component={Box} mb={3}>
      <CardContent>
        <SelectInput source="type" choices={feeChoices} required variant="outlined" />
        <Box width={250}>
          <NumberInput source="amount" format={formatValue} parse={parseValue} required fullWidth min={0} max={50000} />
        </Box>
        <TextInput source="description" />
      </CardContent>
    </Card>
  );
};

export default FeeForm;
