import React from 'react';
import { Labeled, RecordContextProvider, TextField, useGetOne, useRecordContext } from 'react-admin';

import { CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import PercentageField from '@/fields/PercentageField';

const AccountStats = () => {
  const record = useRecordContext();

  const { data } = useGetOne(
    resources.ACCOUNTS,
    { id: record?.id, meta: { subResource: 'worker_stats', resourceId: record?.id } },
    { enabled: Boolean(record?.id) },
  );

  if (!record || !data) return null;

  return (
    <RecordContextProvider value={data}>
      <CardHeader title="Stats" />
      <Divider />
      <CardContent>
        <Grid container spacing={1} textAlign="center">
          <Grid item xs={6}>
            <Labeled>
              <TextField source="lifetime_completed_jobs" label="Moves" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <TextField source="average_rating" label="Rating" />
            </Labeled>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardHeader title="Rates" />
      <Divider />
      <CardContent>
        <Grid container spacing={1} textAlign="center">
          <Grid item xs={6}>
            <Labeled>
              <PercentageField source="removal_rate" label="Removal" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <PercentageField source="late_rate" label="Late" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <PercentageField source="damage_rate" label="Damage" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <PercentageField source="no_pad_wrap_rate" label="No Pad / Wrap" />
            </Labeled>
          </Grid>
        </Grid>
      </CardContent>
    </RecordContextProvider>
  );
};

export default AccountStats;
