import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import _ from 'lodash';

import { MARKET_TIMEZONES } from '@/constants/marketTimezones';
import { QUOTE_PARTNER_TEMPLATES } from '@/constants/quoteTemplates';
import { SERVICES } from '@/constants/serviceName';

dayjs.extend(tz);

const podsTransform = (data) => {
  const retData = _.cloneDeep(data);

  if (retData?.selected_template?.id === QUOTE_PARTNER_TEMPLATES.PODS.LOADING_UNLOADING.id) {
    // Multi location PODS - split loading / unloading dates to appropriate services
    retData.service_groups = retData.service_groups?.map((serviceGroup) => {
      const serviceGroupLocations = retData.locations?.map(({ location: { id: locationId } }) => locationId);

      const startDateTime = dayjs(retData.preferred.start_date)
        .hour(retData.preferred.start_time)
        .minute(0)
        .second(0)
        .millisecond(0);
      const endDateTime = dayjs(retData.preferred.end_date)
        .hour(retData.preferred.end_time)
        .minute(0)
        .second(0)
        .millisecond(0);

      const startTimezone = MARKET_TIMEZONES[retData.locations?.[0].location.market_id];
      const endTimezone = MARKET_TIMEZONES[retData.locations?.[retData.locations.length - 1].location.market_id];

      if (serviceGroup.service_codes.includes(SERVICES.LOADING.id)) {
        return {
          ...serviceGroup,
          locations: [serviceGroupLocations[0]],
          start_datetime: startDateTime.tz(startTimezone, true).toISOString(),
        };
      }

      return {
        ...serviceGroup,
        locations: [serviceGroupLocations[serviceGroupLocations.length - 1]],
        start_datetime: endDateTime.tz(endTimezone, true).toISOString(),
      };
    });
  } else if (
    retData?.selected_template?.id === QUOTE_PARTNER_TEMPLATES.PODS.LOADING.id ||
    retData?.selected_template?.id === QUOTE_PARTNER_TEMPLATES.PODS.UNLOADING.id
  ) {
    retData.service_groups = retData.service_groups?.map((serviceGroup) => {
      const serviceGroupLocations = retData.locations?.map(({ location: { id: locationId } }) => locationId);

      const startDateTime = dayjs(retData.preferred.start_date)
        .hour(retData.preferred.start_time)
        .minute(0)
        .second(0)
        .millisecond(0);

      const startTimezone = MARKET_TIMEZONES[retData.locations?.[0].location.market_id];

      return {
        ...serviceGroup,
        locations: [serviceGroupLocations[0]],
        start_datetime: startDateTime.tz(startTimezone, true).toISOString(),
      };
    });
  }

  return retData;
};

export default podsTransform;
