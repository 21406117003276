import React from 'react';

import { string } from 'prop-types';
import { Datagrid, DateField, ReferenceField, TextField, useRecordContext } from 'react-admin';

import * as resources from '@/api/resources';
import { PRO_ACCOUNT_PERFORMANCE_SECTION } from '@/constants/prosAccount';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';
import toPascalCase from '@/utils/toPascalCase';

const PerformanceList = ({ section }) => {
  const record = useRecordContext();
  let datagridData = '';
  let filter = {};
  const subRes = section === PRO_ACCOUNT_PERFORMANCE_SECTION.REMOVAL ? 'jobs' : 'worker_stats/performance';

  switch (section) {
    case PRO_ACCOUNT_PERFORMANCE_SECTION.RATING:
      datagridData = (
        <Datagrid bulkActionButtons={false}>
          <ReferenceField reference={resources.ORDERS} source="id" label="Order #" link="show" sortable={false}>
            <TextField source="confirmation_id" label="Order #" />
          </ReferenceField>
          <DateField source="job_date" label="Date" />
          <TextField source="star_rating" label="Rating" />
        </Datagrid>
      );
      break;
    case PRO_ACCOUNT_PERFORMANCE_SECTION.LATE_RATE:
      datagridData = (
        <Datagrid bulkActionButtons={false}>
          <ReferenceField reference={resources.ORDERS} source="id" label="Order #" link="show" sortable={false}>
            <TextField source="confirmation_id" label="Order #" />
          </ReferenceField>
          <DateField source="job_date" label="Date" />
        </Datagrid>
      );
      filter = { is_late: true };
      break;
    case PRO_ACCOUNT_PERFORMANCE_SECTION.DAMAGE:
      datagridData = (
        <Datagrid bulkActionButtons={false}>
          <ReferenceField reference={resources.ORDERS} source="id" label="Order #" link="show" sortable={false}>
            <TextField source="confirmation_id" label="Order #" />
          </ReferenceField>
          <DateField source="job_date" label="Date" />
        </Datagrid>
      );
      filter = { is_damage: true };
      break;
    case PRO_ACCOUNT_PERFORMANCE_SECTION.REMOVAL:
      datagridData = (
        <Datagrid bulkActionButtons={false}>
          <ReferenceField reference={resources.ORDERS} source="order_id" label="Order #" link="show" sortable={false}>
            <TextField source="confirmation_id" />
          </ReferenceField>
          <DateField source="start_datetime" label="Date" />
        </Datagrid>
      );
      filter = { status: 'Removed' };
      break;
    default:
  }

  if (!record) return null;

  return (
    <List
      disableSyncWithLocation
      resource={resources.ACCOUNTS}
      queryOptions={{ meta: { subResource: `${subRes}`, resourceId: record.id } }}
      exporter={false}
      actions={false}
      filter={filter}
      empty={<EmptyResourceList text={`No ${toPascalCase(section)} performance on Pro account`} />}
      storeKey={`${resources.ACCOUNTS}.performance.${section}.listParams`}
      sort={{ field: subRes === 'jobs' ? 'start_datetime' : 'job_date', order: 'DESC' }}
    >
      {datagridData}
    </List>
  );
};
PerformanceList.propTypes = {
  section: string.isRequired,
};

export default PerformanceList;
