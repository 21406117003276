import React from 'react';
import { FunctionField, useRecordContext } from 'react-admin';

import { Avatar, Box, Typography } from '@mui/material';

const SlotAccountNameField = () => {
  const record = useRecordContext();

  if (!record) {
    return <>Unassigned</>;
  }

  return (
    <FunctionField
      label="Name"
      render={(rec) => (
        <Box display="flex" alignItems="center">
          <Avatar
            component={Box}
            src={rec.image_url}
            mr={1}
            sx={{
              width: 36,
              height: 36,
            }}
          />
          <Typography variant="body2">{rec.name}</Typography>
        </Box>
      )}
    />
  );
};

export default SlotAccountNameField;
