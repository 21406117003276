import React, { useState } from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { number, string } from 'prop-types';
import { Form, useDataProvider, useNotify, useRecordContext, useShowContext } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { LocalShipping, PeopleAlt, Schedule } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import HiddenInput from '@/components/inputs/HiddenInput';
import Incrementer from '@/shared/Incrementer';
import { DURATION_MIN_MAX_BY_SERVICE, SERVICES, WORKERS_MIN_MAX_BY_SERVICE } from '@/constants/quotes';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateOverview}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const ServiceIncrementer = ({ source, min, max }) => {
  const serviceGroupRecord = useRecordContext();
  const { setValue } = useFormContext();

  const [count, setCount] = useState(_.get(serviceGroupRecord, source));

  const updateCount = (newValue) => {
    setCount(newValue);
    setValue(source, newValue, { shouldDirty: true });
  };

  return (
    <Box display="flex" alignItems="center">
      <HiddenInput source={source} />
      <Incrementer value={count} setValue={updateCount} minLimit={min} maxLimit={max} />
    </Box>
  );
};

ServiceIncrementer.propTypes = {
  source: string.isRequired,
  min: number,
  max: number,
};

ServiceIncrementer.defaultProps = {
  min: 0,
  max: 10,
};

const ServiceDetailsForm = () => {
  const record = useRecordContext();
  const {
    formState: { errors },
  } = useFormContext();

  const { services } = record;

  if (!record || !services?.length) return null;

  return (
    <Card component={Box} mb={2}>
      <CardContent>
        <Box mx="auto" my={3} display="flex" maxWidth={300} justifyContent="space-between">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Schedule />
            <ServiceIncrementer
              source="duration"
              min={DURATION_MIN_MAX_BY_SERVICE?.[services?.[0]?.service_id]?.min ?? 0}
              max={DURATION_MIN_MAX_BY_SERVICE?.[services?.[0]?.service_id]?.max ?? 100}
            />
            <Typography>Hours</Typography>
          </Box>
          {services?.map((service, index) => (
            <Box key={service.id} display="flex" flexDirection="column" alignItems="center">
              {service.service_id === 'TRANSIT' ? <LocalShipping /> : <PeopleAlt />}
              <ServiceIncrementer
                source={`services.${index}.quoted_workers`}
                min={WORKERS_MIN_MAX_BY_SERVICE?.[service.service_id]?.min ?? 0}
                max={WORKERS_MIN_MAX_BY_SERVICE?.[service.service_id]?.max ?? 100}
              />
              <Typography>{service.service_id === 'TRANSIT' ? 'Trucks' : 'Workers'}</Typography>
            </Box>
          ))}
        </Box>
        {errors?.twoTruckError ? (
          <Box display="flex" justifyContent="center">
            <Alert variant="filled" severity="error">
              <Typography variant="body1">Two Truck Minimums Error</Typography>
              <Typography variant="body2">{errors.twoTruckError.message.message}</Typography>
            </Alert>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

const ModifyServiceDetails = ({ serviceGroupId }) => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const serviceGroup = _.find(quoteRecord?.service_groups, (group) => group.id === serviceGroupId);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Service Details updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Service details updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Service Details', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating service details on quote - ${error}`, { type: 'error' });
  };

  const validate = (data) => {
    const errors = {};

    const serviceDetails = {
      duration: data.duration,
      service_workers: data.services?.reduce(
        (prev, service) => ({
          ...prev,
          [service.service_id]: service.quoted_workers,
        }),
        {},
      ),
    };

    const trucks = serviceDetails.service_workers[SERVICES.TRANSIT.id];
    const loadingUnloadingWorkers = serviceDetails.service_workers[SERVICES.LOADINGUNLOADING.id];

    if (trucks && trucks > 1 && typeof loadingUnloadingWorkers !== 'undefined') {
      if (loadingUnloadingWorkers < 5 || serviceDetails.duration < 5) {
        errors.twoTruckError = 'Hours and workers must be 5 or greater';
      }
    }

    return errors;
  };

  const onSubmit = (data) => {
    const serviceDetails = {
      duration: data.duration,
      service_workers: data.services?.reduce(
        (prev, service) => ({
          ...prev,
          [service.service_id]: service.quoted_workers,
        }),
        {},
      ),
    };

    const operationsNeeded = [
      {
        op: 'replace',
        path: `/service_groups/${serviceGroupId}/service_details`,
        value: serviceDetails,
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  if (!quoteRecord || !serviceGroup) return null;

  return (
    <Form record={serviceGroup} onSubmit={onSubmit} validate={validate}>
      <ServiceDetailsForm />
      <FormControls />
    </Form>
  );
};

ModifyServiceDetails.propTypes = {
  serviceGroupId: string.isRequired,
};

export default ModifyServiceDetails;
