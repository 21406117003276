import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import mx from 'mixpanel-browser';
import {
  BooleanInput,
  CreateBase,
  DateInput,
  Form,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
  minValue,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useReference,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';

import { Alert, Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { SLOT_TRANSACTION_FAMILY, SLOT_TRANSACTION_REASON_BY_FAMILY } from '@/constants/slotTransactions';
import getQualificationLevel from '@/utils/qualificationLevel/getQualificationLevel';
import getServiceName from '@/utils/serviceName/getServiceName';

const JobSlotInformation = () => {
  const record = useRecordContext();

  const { data: jobSlotData } = useGetOne(
    resources.JOB_SLOTS,
    {
      id: record?.job_slot_id,
    },
    { enabled: Boolean(record?.job_slot_id) },
  );
  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: jobSlotData?.job_id,
    options: { enabled: Boolean(jobSlotData?.job_id) },
  });

  useEffect(() => {
    mx.track('Resource Create - Viewed', {
      resource: resources.JOB_SLOT_BONUS,
    });
  }, []);

  return (
    <Card>
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">Create Job Slot Transaction</Typography>
        <Typography variant="h5">{`${getServiceName(jobData?.product_id)} - ${getQualificationLevel(
          jobSlotData?.minimum_qualification_level,
        )} - ${dayjs(jobData?.start_date_time).format('MMM D, YYYY @ h:mm A')}`}</Typography>
      </Box>
    </Card>
  );
};

const TransactionCreateForm = () => {
  const record = useRecordContext();
  const { data: jobSlotData } = useGetOne(
    resources.JOB_SLOTS,
    {
      id: record?.job_slot_id,
    },
    { enabled: Boolean(record?.job_slot_id) },
  );

  const { watch } = useFormContext();
  const transactionFamily = watch('transaction_family');

  const isAssigned = Boolean(jobSlotData?.account_id);
  const reasonDisabled = !transactionFamily || !isAssigned;
  const defaultFamily = isAssigned ? '' : 'BONUS';
  const defaultReason = isAssigned ? '' : 'CLAIM';

  return (
    <>
      {!isAssigned && (
        <Box pt={2} px={2}>
          <Alert variant="filled" severity="warning">
            Slot is unassigned - Only able to add Claim Bonus
          </Alert>
        </Box>
      )}
      <Box width={250} p={2}>
        <SelectInput
          fullWidth
          required
          source="transaction_family"
          disabled={!isAssigned}
          defaultValue={defaultFamily}
          choices={isAssigned ? Object.values(SLOT_TRANSACTION_FAMILY) : [SLOT_TRANSACTION_FAMILY.BONUS]}
        />
        <SelectInput
          fullWidth
          required
          source="transaction_reason"
          choices={Object.values(SLOT_TRANSACTION_REASON_BY_FAMILY[transactionFamily] ?? [])}
          defaultValue={defaultReason}
          disabled={reasonDisabled}
        />
        <TextInput required source="description" multiline fullWidth />
        <NumberInput required source="amount" max={10000} fullWidth />
        <DateInput required source="date_to_process" validate={minValue(dayjs().format('YYYY-MM-DD'))} fullWidth />
        <BooleanInput
          source="auto_capture"
          fullWidth
          helperText="Auto-Capture will flip to True once the move is completed and job slot transactions reconcile. If you want the job slot transactions to payout regardless of the job status then flip Auto-Capture to True prior to move completion."
        />
        <BooleanInput source="locked" fullWidth />
      </Box>
      <Divider />
      <Box p={2} textAlign="right">
        <SaveButton />
      </Box>
    </>
  );
};

const JobSlotTransactionCreate = () => {
  const location = useLocation();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const record = location?.state?.record;

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error creating slot transaction - ${error.status} - ${error.message}`, { type: 'error' });
    } else {
      mx.track('Resource - Created', {
        resource: resources.JOB_SLOT_TRANSACTIONS,
        resourceId: data.id,
        metadata: {
          family: data.transaction_family,
          reason: data.transaction_reason,
        },
      });
      notify('Slot transaction created', { type: 'success' });
      refresh();
      redirect('show', resources.JOB_SLOTS, record.job_slot_id);
    }
  };

  const transform = (data) => ({
    ...data,
    amount: parseInt(Number(data.amount) * 100, 10),
  });

  const validate = (data) => {
    const errors = {};

    if (dayjs(data.date_to_process).day() !== 2) {
      errors.date_to_process = 'Date must be a Tuesday';
    }

    if (dayjs(data.date_to_process).isBefore(dayjs())) {
      errors.date_to_process = 'Date must be in the future';
    }

    return errors;
  };

  return (
    <CreateBase record={record ?? {}} mutationOptions={{ onSettled }} transform={transform}>
      <Box my={2}>
        <JobSlotInformation />
      </Box>
      <Card>
        <Form validate={validate}>
          <TransactionCreateForm />
        </Form>
      </Card>
    </CreateBase>
  );
};

export default JobSlotTransactionCreate;
