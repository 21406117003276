import React, { useContext } from 'react';

import { useShowContext } from 'react-admin';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';

import { QuoteContext } from '@/providers/Quote';
import { Add } from '@mui/icons-material';

const QuoteActions = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();

  const { forceInventoryUpdate, hasNoInventory } = useContext(QuoteContext);

  if (!quoteRecord) return null;

  return (
    <Card component={Box} mb={2}>
      <CardHeader title="Quote Actions" />
      <Divider />
      <CardContent>
        <Box display="flex" gap={1}>
          <Button
            startIcon={<Add />}
            onClick={() => navigate('edit/add-service-group')}
            disabled={forceInventoryUpdate}
            variant="outlined"
            sx={{ mr: 'auto' }}
          >
            Add Service
          </Button>
          <Button variant="outlined" onClick={() => navigate('edit/modify-locations')} disabled={forceInventoryUpdate}>
            Modify Locations
          </Button>
          {!hasNoInventory ? (
            <Button variant="outlined" onClick={() => navigate('edit/modify-inventory')}>
              Modify Inventory
            </Button>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuoteActions;
