import React from 'react';

import { Loading, RecordContextProvider, SimpleShowLayout, TextField, useGetOne, useShowContext } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import PercentageField from '@/fields/PercentageField';

import ProsSectionHeader from '../../Common/SectionHeader';

const ProsDetailsStatsSection = () => {
  const { record } = useShowContext();

  const { data, isLoading, error } = useGetOne(
    resources.ACCOUNTS,
    { id: record.id, meta: { subResource: 'worker_stats' } },
    { enabled: Boolean(record.id) },
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    <Card mt={3} component={Box}>
      <CardContent>
        <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Stats</Typography>
        </Box>
        <Divider />
      </CardContent>
    </Card>;
  }

  if (!data) return null;

  return (
    <Card mt={3} component={Box}>
      <ProsSectionHeader title="Stats" contextMenuItems={[]} />

      <Divider />
      <CardContent>
        <RecordContextProvider value={data}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="lifetime_completed_jobs" label="Job Count" emptyText="N/A" />
                <TextField source="average_rating" label="Customer rating" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>

            <Grid item xs={4}>
              <SimpleShowLayout>
                <PercentageField source="late_rate" emptyText="N/A" />
                <PercentageField source="damage_rate" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>

            <Grid item xs={4}>
              <SimpleShowLayout>
                <PercentageField source="no_pad_wrap_rate" label="Pad / Wrap rate" emptyText="N/A" />
                <PercentageField source="removal_rate" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </RecordContextProvider>
      </CardContent>
    </Card>
  );
};

export default ProsDetailsStatsSection;
