import React from 'react';
import { usePermissions, useShowContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import Breakdown from './Breakdown';
import PaymentMethods from './PaymentMethods';
import PromoCode from './PromoCode';
import Transactions from './Transactions';
import TransactionMenu from './Transactions/OrderTransactionsMenu';

const OrderContentInvoice = () => {
  const { permissions } = usePermissions();
  const { record: { id: orderId } = {} } = useShowContext();

  if (!orderId) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Card component={Box} mb={2}>
          <CardHeader title="Scheduled Quote" />
          <Divider />
          <CardContent>
            <Breakdown status="scheduled" />
          </CardContent>
        </Card>
        <Card component={Box} mb={2}>
          <CardHeader title="Receipt" />
          <Divider />
          <CardContent>
            <Breakdown status="completed" />
          </CardContent>
        </Card>
        {/* {permissions?.tools?.hqadmin?.tips?.create ? <AddTips /> : null} */}
      </Grid>
      <Grid item xs={3}>
        {permissions?.tools?.hqadmin?.payment_info?.view ? <PaymentMethods /> : null}
        <Card>
          <CardHeader title="Promo Code" />
          <Divider />
          <CardContent>
            <PromoCode />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {permissions?.tools.hqadmin?.transactions?.view ? (
          <Card>
            <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Transactions</Typography>
              <TransactionMenu />
            </Box>
            <Divider />
            <Transactions />
          </Card>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default OrderContentInvoice;
