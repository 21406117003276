import React from 'react';

import { useRecordContext, useReference, useShowContext } from 'react-admin';

import { Info } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { string } from 'prop-types';

const BillingAccountTooltip = ({ accountMessage }) => (
  <Box p={1}>
    <Typography variant="h6">Billing Account</Typography>
    <Typography variant="body2" whiteSpace="pre-line">
      {accountMessage}
    </Typography>
  </Box>
);

BillingAccountTooltip.propTypes = {
  accountMessage: string.isRequired,
};

const TransactionInfo = () => {
  const record = useRecordContext();
  const { record: orderRecord } = useShowContext();

  const { account_id: orderAccount } = orderRecord ?? {};

  const { referenceRecord: accountReferenceRecord } = useReference({
    reference: resources.ACCOUNTS,
    id: record?.related_account_id,
    options: { enabled: Boolean(record?.related_account_id) },
  });

  if (!record || !orderRecord) return null;

  if (orderAccount !== record?.related_account_id) {
    let accountMessage;
    if (accountReferenceRecord) {
      accountMessage = `${accountReferenceRecord?.account_record_type_name} - ${accountReferenceRecord?.name}`;
    } else {
      accountMessage = `Unknown Account - ${record?.related_account_id}`;
    }
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Tooltip title={<BillingAccountTooltip accountMessage={accountMessage} />}>
          <Info color="primary" />
        </Tooltip>
      </Box>
    );
  }

  return null;
};

TransactionInfo.propTypes = {};

export default TransactionInfo;
