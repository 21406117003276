import React from 'react';

import {
  CreateBase,
  email,
  Form,
  ListButton,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  useNotify,
  useTheme,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { CUSTOMER_SOURCE } from '@/constants/customerAccount';
import { COUNTRIES, STATES } from '@/constants/location';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between">
    <ListButton resource={resources.CARRIERS} label="Cancel" icon={null} />
    <SaveButton label="Create" icon={null} />
  </Box>
);

const CarrierAccountCreate = () => {
  const notify = useNotify();
  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');

  const onError = (data) => {
    const error = data.body.detail;
    const displayErrorMessage = error?.[0]?.replace(':', '') ?? 'Unknown Error';
    notify(`Error on creating carrier Account: ${displayErrorMessage}`, {
      type: 'error',
    });
  };

  return (
    <CreateBase mutationOptions={{ onError }} transform={(data) => ({ ...data, email: data?.email?.toLowerCase() })}>
      <Card mt={3} component={Box}>
        <CardHeader title="Create New Carrier Account" />
        <Divider />

        <Form>
          <Box
            p={2}
            sx={{
              backgroundColor: ({ palette }) => (lightMode ? palette.neutral.light : palette.neutral.darkest),
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={3} lg={6}>
                        <TextInput source="name" label="Name" fullWidth required />
                      </Grid>
                      <Grid item xs={3} lg={6}>
                        <TextInput source="phone" label="Phone" fullWidth required />
                      </Grid>
                      <Grid item xs={3} lg={6}>
                        <TextInput source="email" label="Email" fullWidth required validate={[email()]} />
                      </Grid>
                      <Grid item xs={3} lg={6}>
                        <ReferenceInput
                          source="primary_market"
                          reference={resources.MARKETS}
                          perPage={999}
                          sort={{ field: 'name', order: 'ASC' }}
                        >
                          <SelectInput label="Primary Market" optionText="name" fullWidth required />
                        </ReferenceInput>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Card>
                  <CardHeader title="Address" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextInput source="line_1" fullWidth required />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="line_2" fullWidth />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="city" fullWidth required />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectInput source="state" choices={STATES} fullWidth required />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="postal_code" fullWidth required />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectInput source="country" choices={COUNTRIES} fullWidth required defaultValue="US" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Card>
                  <CardHeader title="Optional" />
                  <Divider />
                  <CardContent>
                    <SelectInput source="source" label="Source" choices={CUSTOMER_SOURCE} fullWidth />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <CardContent>
            <FormControls />
          </CardContent>
        </Form>
      </Card>
    </CreateBase>
  );
};

export default CarrierAccountCreate;
