import React from 'react';

import {
  CreateBase,
  Form,
  ListButton,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { STATES } from '@/constants/location';
import { LEAD_SOURCES } from '@/constants/thirdPartyCrewAccount';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ListButton resource={resources.THIRD_PARTY_CREW} label="Cancel" icon={null} />
    <SaveButton label="Create" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewAccountCreate = () => {
  const transform = (data) => ({
    ...data,
    location: { ...data.location, country: 'US' },
    email: data?.email?.toLowerCase(),
  });

  return (
    <CreateBase transform={transform}>
      <Card mt={3} component={Box}>
        <CardHeader title="Create New Third Party Crew Account" />

        <Divider />

        <CardContent>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput source="name" label="Name" validate={[required()]} fullWidth />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="phone" label="Phone number" validate={[required()]} fullWidth />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="email" label="Email address" validate={[required()]} fullWidth />
              </Grid>

              <Grid item xs={3}>
                <TextInput source="location.line_1" label="Address line 1" validate={[required()]} fullWidth />
              </Grid>

              <Grid item xs={3}>
                <TextInput source="location.line_2" label="Address line 2" validate={[]} fullWidth />
              </Grid>

              <Grid item xs={2}>
                <TextInput source="location.city" label="City" validate={[required()]} fullWidth />
              </Grid>

              <Grid item xs={2}>
                <SelectInput label="State" source="location.state" choices={STATES} fullWidth required />
              </Grid>

              <Grid item xs={2}>
                <TextInput source="location.postal_code" label="Postal code" validate={[required()]} fullWidth />
              </Grid>

              <Grid item xs={3}>
                <SelectInput source="source" label="Lead source" choices={LEAD_SOURCES} fullWidth />
              </Grid>
            </Grid>

            <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Optional</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <ReferenceInput
                  source="primary_market"
                  reference={resources.MARKETS}
                  perPage={999}
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <SelectInput optionText="name" label="Market" fullWidth />
                </ReferenceInput>
              </Grid>
            </Grid>

            <Divider />

            <CustomToolbar />
          </Form>
        </CardContent>
      </Card>
    </CreateBase>
  );
};
export default ThirdPartyCrewAccountCreate;
