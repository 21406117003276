import React, { useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { bool } from 'prop-types';
import { EditBase, Form, useEditContext, useNotify, useRecordContext, useRedirect, useRefresh } from 'react-admin';
import { useNavigate, useParams } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import CardInput from '@/shared/stripe/CardInput';
import { CUSTOMER_ACCOUNT_TAB } from '@/constants/customerAccount';

const FormControls = ({ isStripeLoading }) => {
  const { isLoading: isSaving } = useEditContext();
  const customerRecord = useRecordContext();
  const navigate = useNavigate();

  const navigateBack = () => navigate(`/${resources.CUSTOMERS}/${customerRecord.id}/show`);

  const isUpdating = isSaving || isStripeLoading;

  return (
    <Box display="flex" justifyContent="space-between">
      <Button variant="contained" color="neutral" onClick={navigateBack}>
        Back
      </Button>
      <Button
        variant="contained"
        type="submit"
        disabled={isUpdating ?? null}
        endIcon={isUpdating ? <CircularProgress size={18} /> : null}
      >
        Save
      </Button>
    </Box>
  );
};

FormControls.propTypes = {
  isStripeLoading: bool.isRequired,
};

const PaymentMethodForm = () => {
  const record = useRecordContext();
  return (
    <Box>
      <CardInput cardholderName={`${record.first_name} ${record.last_name}`} />
    </Box>
  );
};

const PaymentMethodFormWrapper = () => {
  const { save } = useEditContext();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const elements = useElements();
  const stripe = useStripe();

  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const onError = (error) => {
    notify(`Error adding payment method to customer - ${error.status} - ${error.message}`, { type: 'error' });
  };

  const onSuccess = () => {
    notify('Payment method added to customer', {
      type: 'success',
    });
    refresh();
    redirect(`/${resources.CUSTOMERS}/${record.id}/show/${CUSTOMER_ACCOUNT_TAB.CHARGES}`);
  };

  const onSubmit = (data) => {
    setIsStripeLoading(true);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement, { name: data.cardholderName }).then((result) => {
      if (result.token) {
        const paymentMethod = {
          provider: 'STRIPE_TOKEN',
          token: result.token.id,
        };
        save(paymentMethod, { onError, onSuccess });
      } else if (result.error) {
        notify(result.error.message, { type: 'error' });
      }
      setIsStripeLoading(false);
    });
  };

  if (!record) return null;

  return (
    <Form onSubmit={onSubmit}>
      <PaymentMethodForm />
      <Divider />
      <CardContent>
        <FormControls isStripeLoading={isStripeLoading} />
      </CardContent>
    </Form>
  );
};

const CustomerChargesPaymentMethodEdit = () => {
  const { accountId } = useParams();

  return (
    <EditBase
      resource={resources.CUSTOMERS}
      id={accountId}
      mutationOptions={{ meta: { subResource: 'payment_method' } }}
      mutationMode="pessimistic"
      redirect={`/${resources.CUSTOMERS}/${accountId}/show`}
      actions={false}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Add Payment Method" />
        <Divider />
        <PaymentMethodFormWrapper />
      </Card>
    </EditBase>
  );
};

export default CustomerChargesPaymentMethodEdit;
