import React from 'react';

import dayjs from 'dayjs';
import { string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import TextFieldWrapper from '../TextFieldWrapper';

const DayJsField = ({ source, format }) => {
  const record = useRecordContext();

  if (!record || !record?.[source]) return null;

  return <TextFieldWrapper textFieldProps={{ whiteSpace: 'pre-wrap' }} value={dayjs(record[source]).format(format)} />;
};

DayJsField.propTypes = {
  source: string.isRequired,
  format: string,
};

DayJsField.defaultProps = {
  format: 'M/D/YYYY h:mm A',
};

export default DayJsField;
