export default {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontSize: 38,
  },
  h2: {
    fontSize: 34,
  },
  h3: {
    fontSize: 30,
  },
  h4: {
    fontSize: 26,
  },
  h5: {
    fontSize: 22,
  },
  h6: {
    fontSize: 18,
  },
  body3: {
    fontSize: 12,
  },
};
