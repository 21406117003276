import React, { forwardRef, useState } from 'react';

import { useCheckForApplicationUpdate } from 'react-admin';

import { Alert, Button, Snackbar } from '@mui/material';

const CustomAppUpdateNotification = forwardRef((props, ref) => (
  <Alert
    ref={ref}
    color="primary"
    severity="info"
    action={
      <Button color="inherit" variant="outlined" size="small" onClick={() => window.location.reload()}>
        Update
      </Button>
    }
  >
    A new version of HQ Admin is available. Please Update / Refresh.
  </Alert>
));

const CheckForAppUpdate = () => {
  const [updateNeeded, setUpdateNeeded] = useState(false);

  useCheckForApplicationUpdate({
    interval: 15 * 60 * 1000, // 15 minutes
    disabled: process.env.REACT_APP_ENVIRONMENT === 'local',
    onNewVersionAvailable: () => setUpdateNeeded(true),
  });

  return (
    <Snackbar
      open={updateNeeded}
      message={<CustomAppUpdateNotification />}
      onClose={() => {}}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        '&.MuiSnackbar-root': {
          top: (theme) => theme.spacing(2),
        },
        '& .MuiSnackbarContent-root, & .MuiSnackbarContent-message': {
          padding: 0,
        },
      }}
    />
  );
};

export default CheckForAppUpdate;
