import React, { useContext } from 'react';
import { usePermissions, useShowContext } from 'react-admin';

import { Box, Button } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';
import { ORDER_CANCELLABLE_STATUSES } from '@/constants/orderStatus';

const CancelAction = () => {
  const { setAction } = useContext(OrderActionContext);
  const { permissions } = usePermissions();

  const {
    record: { status: orderStatus },
  } = useShowContext();

  const canCancelOrderPerm = permissions?.tools?.hqadmin?.orders?.cancel;
  const isOrderStatusCancellable = ORDER_CANCELLABLE_STATUSES.includes(orderStatus);

  return canCancelOrderPerm && isOrderStatusCancellable ? (
    <Box display="flex" justifyContent="center" mt={2}>
      <Button variant="outlined" color="accent" onClick={() => setAction('cancel_order')}>
        Cancel Order
      </Button>
    </Box>
  ) : null;
};

export default CancelAction;
