import React from 'react';

import { AutocompleteInput, Form, ReferenceInput, SaveButton, SelectInput, ShowButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { TITLE } from '@/constants/carrierAccount';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_RECORD_TYPE_ARRAY } from '@/constants/account';

const CarrierDetailsOverviewEdit = () => {
  const filterToQuery = (query) => ({
    name: query,
    account_type: CARRIER_ACCOUNT_RECORD_TYPE_ARRAY,
    account_status: 'Active',
  });
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview" />

      <Divider />

      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput source="name" label="Name" required fullWidth />
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput
                source="primary_market"
                reference={resources.MARKETS}
                perPage={999}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput optionText="name" label="Market" fullWidth />
              </ReferenceInput>
            </Grid>

            <Grid item xs={3}>
              <ReferenceInput
                source="company_id"
                reference="accounts"
                enableGetChoices={({ name }) => Boolean(name?.length > 0)}
                emptyText="N/A"
              >
                <AutocompleteInput optionText="name" filterToQuery={filterToQuery} label="Company" default />
              </ReferenceInput>
            </Grid>

            <Grid item xs={3}>
              <SelectInput source="person_title" label="Title" choices={TITLE} fullWidth />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Update" icon={null} />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default CarrierDetailsOverviewEdit;
