export default ({ palette }) => ({
  '& .account-availability-calendar .react-calendar': {
    maxWidth: '100%',
    background: palette.mode === 'light' ? palette.neutral.lightest : palette.secondary.darker,
    border: `1px solid ${palette.mode === 'light' ? palette.neutral.light : palette.neutral.dark}`,
    lineHeight: '1.125em',
    '&, *, *:before, *:after': {
      boxSizing: 'border-box',
    },
    button: {
      margin: 0,
      border: 0,
      outline: 'none',
    },
    '&__tile': {
      maxWidth: '100%',
      padding: 0,
      background: 'none',
      textAlign: 'center',
      lineHeight: '16px',
      color: palette.text.primary,
      '&:disabled': {
        backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.secondary.darkest,
      },
      '&--now, &--now.unavailable': {
        backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.secondary.dark,
      },
    },
    '&__navigation': {
      display: 'flex',
      height: 44,
      marginBottom: 4,
      borderBottom: `1px solid ${palette.mode === 'light' ? palette.neutral.light : palette.neutral.dark}`,
      button: {
        minWidth: 44,
        background: 'none',
        color: palette.text.primary,
        '&:disabled': {
          backgroundColor: palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest,
        },
        '&:enabled:hover': {
          backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.secondary.dark,
        },
      },
      '&__label': {
        fontWeight: 'bold',
        fontSize: '16px',
      },
    },
    '&__month-view': {
      '&__weekdays': {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '0.75em',
        '&__weekday': {
          padding: '0.5em',
        },
        abbr: {
          textDecoration: 'none',
        },
      },
      '&__days__day': {
        position: 'relative',
        borderWidth: '2px',
        abbr: {
          display: 'none',
        },
        '&--neighboringMonth': {
          backgroundColor: palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest,
        },
      },
    },
    '&__year-view': {
      '& button:enabled:hover': {
        cursor: 'pointer',
        backgroundColor: palette.mode === 'light' ? palette.secondary.lighter : palette.secondary.dark,
      },
      '& .react-calendar__tile': {
        padding: '2em 0.5em',
      },
    },
    // Custom availability cells
    '& .cell': {
      width: '100%',
      '& .cell-container': {
        display: 'flex',
        margin: 10,
        border: `1px solid ${palette.mode === 'light' ? palette.neutral.light : palette.neutral.dark}`,
        borderRadius: 4,
        '& .cell-divisions': {
          flex: 1,
          minHeight: 70,
          '&:hover': {
            opacity: 0.75,
          },
        },
        '& .cell-text-container': {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
  },
});
