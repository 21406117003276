import React from 'react';

import { NumberInput } from 'react-admin';

import { Grid } from '@mui/material';

const ZippyInventoryInput = () => (
  <Grid container spacing={4}>
    <Grid item xs={4} xl={12 / 7}>
      <NumberInput
        source="inventory.extra_data.number_of_shells"
        label="Number of Shells"
        min={1}
        max={2}
        defaultValue={1}
      />
    </Grid>
    <Grid item xs={4} xl={12 / 7}>
      <NumberInput
        source="inventory.extra_data.miles_to_origin"
        label="Miles to Origin"
        min={0}
        max={500}
        defaultValue={0}
      />
    </Grid>
    <Grid item xs={4} xl={12 / 7}>
      <NumberInput source="inventory.extra_data.extra_stop" label="Stops" min={0} max={50} defaultValue={0} />
    </Grid>
    <Grid item xs={3} xl={12 / 7}>
      <NumberInput source="inventory.extra_data.assembly" label="Assembly Items" min={0} max={50} defaultValue={0} />
    </Grid>
    <Grid item xs={3} xl={12 / 7}>
      <NumberInput source="inventory.extra_data.disassembly" label="Disassembly Items" min={0} max={50} defaultValue={0} />
    </Grid>
    <Grid item xs={3} xl={12 / 7}>
      <NumberInput source="inventory.extra_data.long_haul" label="Long Haul" min={0} max={50} defaultValue={0} />
    </Grid>
    <Grid item xs={3} xl={12 / 7}>
      <NumberInput source="inventory.extra_data.adjacent_market" label="Adjacent Market" min={0} max={50} defaultValue={0} />
    </Grid>
  </Grid>
);

export default ZippyInventoryInput;
