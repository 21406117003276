import React from 'react';

import { Labeled, NumberField } from 'react-admin';

import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const ZippyInventory = () => (
  <Card>
    <CardHeader title="Zippyshell Inventory" />
    <Divider />
    <CardContent>
      <Grid container spacing={1} px={2}>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.number_of_shells" label="# of Shells" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.miles_to_origin" label="Miles to Origin" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.assembly" label="Assembly Items" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.disassembly" label="Disassembly Items" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.extra_stop" label="Stops" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.long_haul" label="Long Haul" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <NumberField source="extra_data.adjacent_market" label="Adjacent Market" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ZippyInventory;
