import React from 'react';

import { Datagrid, DateField, FunctionField, ReferenceField, TextField, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, IconButton, Tooltip } from '@mui/material';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_RECORD_TYPE_ARRAY } from '@/constants/account';
import ZendeskTicketStatusField from '@/fields/ZendeskTicketStatusField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';
import ZendeskIcon from '@/shared/ZendeskIcon';
import { THIRD_PARTY_CREW_RECORD_TYPE } from '@/constants/thirdPartyCrewAccount';

const AccountTickets = () => {
  const record = useRecordContext();

  if (!record) return null;

  const accountType = record?.account_record_type_name ? record.account_record_type_name : record.account_record_type;

  let resourceType = null;

  if (accountType.includes('Third Party Worker')) {
    resourceType = resources.THIRD_PARTY_WORKER;
  } else if (accountType.includes('Bellhop')) {
    resourceType = resources.ACCOUNTS;
  } else if (accountType.includes('Customer')) {
    resourceType = resources.CUSTOMERS;
  } else if (CARRIER_ACCOUNT_RECORD_TYPE_ARRAY.includes(accountType)) {
    resourceType = resources.CARRIERS;
  } else if (accountType.includes(THIRD_PARTY_CREW_RECORD_TYPE)) {
    resourceType = resources.THIRD_PARTY_CREW;
  }

  return (
    <Card component={Box}>
      <CardHeader title="Tickets" />
      <Divider />
      <CardContent>
        <List
          disableSyncWithLocation
          resource={resources.ZENDESK_TICKETS}
          exporter={false}
          actions={false}
          empty={<EmptyResourceList text="No Zendesk Tickets available" />}
          storeKey={`${resourceType}.tickets.listParams`}
          filter={{ account_id: record.id }}
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="Ticket ID"
              sortable={false}
              render={({ id }) => (
                <Box display="flex" gap={0.5} alignItems="center">
                  <Tooltip title="Open Zendesk ticket" placement="top">
                    <IconButton size="small" href={`${process.env.REACT_APP_ZENDESK_TICKET_URI}/${id}`} target="_blank">
                      <Box width={20} height={20} display="flex" alignItems="center">
                        <ZendeskIcon />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <TextField source="id" sortable={false} />
                </Box>
              )}
            />
            <TextField source="subject" sortable={false} />
            <ZendeskTicketStatusField source="status" sortable={false} />
            <ReferenceField reference={resources.ZENDESK_USERS} source="requester_id" sortable={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField reference={resources.ZENDESK_USERS} source="assignee_id" sortable={false}>
              <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" sortable={false} />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

export default AccountTickets;
