import AccountAvailabilityCalendar from './AccountAvailabilityCalendar';
import QuoteFlexCalendar from './QuoteFlexCalendar';

export default ({ palette }) => ({
  styleOverrides: {
    body: {
      ...AccountAvailabilityCalendar({ palette }),
      ...QuoteFlexCalendar({ palette }),
    },
  },
});
