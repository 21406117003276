import React from 'react';

import { Datagrid, DateField, ReferenceField, TextField, useRecordContext } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { CUSTOMER_ACCOUNT_TAB, CUSTOMER_CHARGES_SECTION } from '@/constants/customerAccount';
import ConcatenatingStringField from '@/fields/ConcatenatingStringField';
import CurrencyField from '@/fields/CurrencyField';
import ListBase from '@/ReactAdmin/List/ListBase';
import EmptyResourceList from '@/shared/EmptyResourceList';

import CustomerChargesSectionHeader from '../Common/SectionHeader';

const CustomerContentHistoryofCharges = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card mt={3}>
      <CustomerChargesSectionHeader
        title="History of Charges"
        tab={CUSTOMER_ACCOUNT_TAB.CHARGES}
        section={CUSTOMER_CHARGES_SECTION.HISTORY_OF_CHARGES}
      />
      <Divider />
      <Box>
        <ListBase
          disableSyncWithLocation
          resource={resources.CUSTOMERS}
          queryOptions={{ meta: { subResource: 'transaction_history', resourceId: record.id } }}
          exporter={false}
          perPage={100}
          empty={<EmptyResourceList text="No Charge history on Customer account" />}
          storeKey={`${resources.CUSTOMERS}.transactions.listParams`}
          sort={{ field: 'date', order: 'DESC' }}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              reference={resources.ORDERS}
              source="order_id"
              label="Confirmation #"
              link={(chargeRecord) => `/orders/${chargeRecord.order_id}/show/invoice`}
              sortable={false}
            >
              <TextField source="confirmation_id" />
            </ReferenceField>

            <DateField source="date" label="Date" sortable={false} />
            <CurrencyField source="amount" label="Amount" textAlign="left" sortable={false} />
            <TextField source="status" label="Status" sortable={false} />
            <ConcatenatingStringField start="****" source="card_last_4_digits" label="Card number" sortable={false} />
          </Datagrid>
        </ListBase>
      </Box>
    </Card>
  );
};

export default CustomerContentHistoryofCharges;
