import * as resources from '@/api/resources';
import OrderList from './List';
import OrderShow from './Show';

const orders = () => ({
  name: resources.ORDERS,
  list: OrderList,
  show: OrderShow,
});

export default orders;
