import React from 'react';

import { string } from 'prop-types';

import { CUSTOMER_ORDER_SECTION } from '@/constants/customerAccount';

import CustomerSectionHeader from '../../../Common/SectionHeader';

const CustomerOrdersSectionHeader = ({ section, title }) => {
  const contextMenuItems = [];

  switch (section) {
    case CUSTOMER_ORDER_SECTION.ORDER_HISTORY:
      break;
    default:
      break;
  }

  return <CustomerSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

CustomerOrdersSectionHeader.propTypes = {
  section: string.isRequired,
  title: string.isRequired,
};

export default CustomerOrdersSectionHeader;
