import React from 'react';

import { EditBase, useNotify, useRedirect, useRefresh } from 'react-admin';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import * as resources from '@/api/resources';
import { PRO_ACCOUNT_TAB } from '@/constants/prosAccount';

import ProHeader from '../Show/ProHeader';
import ProsContentDetailsEdit from './Details';

const ProsAccountEdit = () => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const { tab: currentTab, accountId } = useParams();

  const onError = ({ message, status, body: { detail } }) => {
    let displayMessage = detail ?? message;
    if (detail.includes('not worker account')) {
      displayMessage = 'Not worker account';
    }
    notify(`Error updating account - ${status} - ${displayMessage}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    refresh();
    notify(`Account updated`, {
      type: 'success',
    });
    redirect('show', resources.ACCOUNTS, accountId);
  };

  return (
    <EditBase
      id={accountId}
      mutationMode="pessimistic"
      mutationOptions={{
        onError,
        onSuccess,
      }}
      transform={(data) => ({ ...data, email: data?.email?.toLowerCase() })}
    >
      <Box mt={3}>
        <ProHeader />
      </Box>
      <Box display={currentTab === PRO_ACCOUNT_TAB.DETAILS ? 'block' : 'none'}>
        <ProsContentDetailsEdit />
      </Box>
    </EditBase>
  );
};

export default ProsAccountEdit;
