import React from 'react';

import dayjs from 'dayjs';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';

import { Box, Tooltip } from '@mui/material';

const getHourAvailability = (availabilities) => {
  let hourly = {};

  if (availabilities.length > 1) {
    // merge all hourly availabilities
    const mergedHourly = {};

    for (let i = 0; i < availabilities.length; i += 1) {
      const { hourly: partHourly } = availabilities[i];

      Object.keys(partHourly).forEach((hour) => {
        mergedHourly[hour] = mergedHourly[hour] || partHourly[hour];
      });
    }

    hourly = mergedHourly;
  } else if (availabilities.length === 1) {
    hourly = availabilities[0].hourly;
  }

  return hourly;
};

const DateCell = ({ date, view, availabilities, activeStartDate }) => {
  if (view !== 'month' || !dayjs(date).isSame(dayjs(activeStartDate), 'month')) {
    return null;
  }

  const currAvailabilities = availabilities.filter((val) => dayjs(val.dateTime).isSame(dayjs(date), 'date'));
  const day = dayjs(date).format('D');

  const hourly = getHourAvailability(currAvailabilities);

  return (
    <div className="cell-container">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, index) => {
        const available = hourly[index] ?? false;
        const timeStart = dayjs()
          .hour(8 + index)
          .minute(0)
          .format('h:mma');
        const timeEnd = dayjs()
          .hour(8 + index + 1)
          .minute(0)
          .format('h:mma');

        return (
          <Tooltip title={`${timeStart} - ${timeEnd}`} key={item}>
            <Box
              className="cell-divisions"
              sx={{
                backgroundColor: ({ palette }) => {
                  if (palette.mode === 'light') {
                    return available ? 'primary.lighter' : 'neutral.lighter';
                  }
                  return available ? 'primary.dark' : 'neutral.medium';
                },
              }}
            />
          </Tooltip>
        );
      })}
      <div className="cell-text-container">{day}</div>
    </div>
  );
};

DateCell.propTypes = {
  date: shape({}).isRequired,
  view: string.isRequired,
  availabilities: arrayOf(shape({ dateTime: string, hourly: shape({ [oneOfType([number, string])]: string }) })).isRequired,
  activeStartDate: shape({}).isRequired,
};

export default DateCell;
