import React from 'react';

import { string } from 'prop-types';

import { Box } from '@mui/material';

import { CUSTOMER_ACCOUNT_TAB } from '@/constants/customerAccount';

import Charges from './Charges';
import Details from './Details';
import Orders from './Orders';
import Quotes from './Quotes';
import Support from './Support';

const CustomerContent = ({ currentTab }) => {
  let content;
  switch (currentTab) {
    case CUSTOMER_ACCOUNT_TAB.DETAILS:
      content = <Details />;
      break;
    case CUSTOMER_ACCOUNT_TAB.CHARGES:
      content = <Charges />;
      break;
    case CUSTOMER_ACCOUNT_TAB.ORDERS:
      content = <Orders />;
      break;
    case CUSTOMER_ACCOUNT_TAB.QUOTES:
      content = <Quotes />;
      break;
    case CUSTOMER_ACCOUNT_TAB.SUPPORT:
      content = <Support />;
      break;
    default:
      content = <>Unhandled</>;
  }
  return <Box>{content}</Box>;
};

CustomerContent.propTypes = {
  currentTab: string.isRequired,
};

export default CustomerContent;
