import React from 'react';

import { BooleanInput, DateInput, Edit, NumberInput, SaveButton, ShowButton, SimpleForm, TextInput } from 'react-admin';

import { Box, Divider } from '@mui/material';

const CustomToolbar = () => (
  <Box>
    <Divider />
    <Box display="flex" justifyContent="space-between" p={3}>
      <ShowButton label="Cancel" icon={null} color="secondary" />
      <SaveButton label="Update" icon={null} type="button" />
    </Box>
  </Box>
);

const AdminStripeTransactionEdit = () => {
  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="name" />
        <TextInput source="description" />
        <NumberInput source="amount" format={formatValue} parse={parseValue} />
        <NumberInput source="authorization_amount" format={formatValue} parse={parseValue} />
        <NumberInput source="retained_revenue_amount" format={formatValue} parse={parseValue} />
        <TextInput source="due_date" />
        <TextInput source="date_to_process" />
        <TextInput source="payment_status" />
        <DateInput source="processed_date" />
        <TextInput source="transaction_status" />
        <TextInput source="transaction_type" />
        <TextInput source="transaction_reason" />
        <TextInput source="family" />
        <TextInput source="transaction_id" />
        <BooleanInput source="authorize_only" />
        <BooleanInput source="auto_capture" />
        <TextInput source="connected_account_code" />
        <TextInput source="connected_account_id" />
        <TextInput source="currency_iso" />
        <TextInput source="customer_id" />
        <TextInput source="parent_transaction_id" />
        <TextInput source="parent_transaction_code" />
        <TextInput source="payment_gateway_code" />
        <TextInput source="payment_method_code" />
        <TextInput source="reattempted_transaction" />
        <TextInput source="reattempted_transaction_code" />
        <TextInput source="related_account_code" />
        <TextInput source="stripe_customer_code" />
        <TextInput source="job_slot_code" />
        <TextInput source="order_code" />
        <TextInput source="recordtypeid" />
      </SimpleForm>
    </Edit>
  );
};

export default AdminStripeTransactionEdit;
