import mx from 'mixpanel-browser';
import React, { useEffect } from 'react';
import {
  BooleanInput,
  Datagrid,
  DateTimeInput,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  SingleFieldList,
  TextField,
} from 'react-admin';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import JobStatusFilter from '@/components/filters/JobStatusFilter';
import ServiceNameFilter from '@/components/filters/ServiceNameFilter';
import { JOB_SLOT_STATUS } from '@/constants/jobSlotStatus';
import { INVENTORY_STATUS } from '@/constants/marketplace';
import { SERVICES } from '@/constants/serviceName';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import ServiceNameField from '@/fields/ServiceNameField';
import SlotAvatarField from '@/fields/SlotAvatarField';
import ListViewChangedTracker from '@/shared/events/ListViewChangedTracker';
import { Box, Card, Divider, Typography } from '@mui/material';

const JobsDatagrid = () => (
  <Datagrid rowClick="show" bulkActionButtons={false}>
    <ServiceNameField source="product_id" />
    <ReferenceField source="order_id" reference={resources.ORDERS} link="show">
      <TextField source="confirmation_id" />
    </ReferenceField>
    <ReferenceField source="order_id" label="Order Status" reference={resources.ORDERS}>
      <TextField source="status" />
    </ReferenceField>
    <PrefDateTimeField
      source="start_date_time"
      timezoneSource="start_timezone"
      label="Start"
      dayjsFormat="M/DD/YYYY h:mm A"
    />
    <PrefDateTimeField source="end_date_time" timezoneSource="end_timezone" label="End" dayjsFormat="M/DD/YYYY h:mm A" />
    <ReferenceField source="start_market_id" label="Start Market" reference={resources.MARKETS}>
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="end_market_id" label="End Market" reference={resources.MARKETS}>
      <TextField source="name" />
    </ReferenceField>
    <TextField source="status" />
    <ReferenceField source="partner_id" label="Partner" reference={resources.ACCOUNTS}>
      <TextField source="name" />
    </ReferenceField>
    <FunctionField render={(record) => (record.reschedule_type === 'HQ' ? 'Yes' : 'No')} label="HQRS" />
    <ReferenceManyField
      label="Crew"
      reference={resources.JOB_SLOTS}
      target="job_id"
      filter={{
        status: [JOB_SLOT_STATUS.ASSIGNED, JOB_SLOT_STATUS.CANCELLED, JOB_SLOT_STATUS.UNASSIGNED],
      }}
      sort={{
        field: 'minimum_qualification_level',
        order: 'DESC',
      }}
      perPage={100}
      sortable={false}
    >
      <SingleFieldList linkType="show" sx={{ alignItems: 'center', py: 1 }}>
        <Box mr={0.5}>
          <SlotAvatarField enableTooltip />
        </Box>
      </SingleFieldList>
    </ReferenceManyField>
  </Datagrid>
);

const JobList = () => {
  const filters = [
    <JobStatusFilter source="status" alwaysOn />,
    <BooleanInput source="fulfilled" />,
    <ReferenceInput source="market_id" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ServiceNameFilter source="product_id" choices={Object.values(SERVICES)} />,
    <DateTimeInput source="start_date_time_gte" label="Starts After" />,
    <DateTimeInput source="start_date_time_lte" label="Starts Before" />,
    <DateTimeInput source="created_at_gte" label="Created After" />,
    <DateTimeInput source="created_at_lte" label="Created Before" />,
    <SelectInput
      source="partner_id"
      choices={[
        { id: 'COOPER', name: 'Mr. Cooper' },
        { id: 'CUBESMART', name: 'Cubesmart' },
        { id: 'OFFERPAD', name: 'Offerpad' },
        { id: 'PODS', name: 'PODS' },
        { id: 'ZIPPY', name: 'Zippyshell' },
      ]}
    />,
    <BooleanInput source="long_distance" />,
    <BooleanInput source="reschedule_type" label="HQRS" />,
    <SelectInput source="marketplace_supply_type" label="Supply Type" choices={INVENTORY_STATUS} />,
  ];

  useEffect(() => {
    mx.track('Resource List - Viewed', {
      resource: resources.JOBS,
    });
  }, []);

  return (
    <Card mt={3} component={Box}>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Jobs</Typography>
      </Box>
      <Divider />
      <List
        resource={resources.JOBS}
        filters={filters}
        filterDefaultValues={{
          status: ['OPEN'],
          fulfilled: false,
        }}
        disableSyncWithLocation
        exporter={false}
      >
        <ListViewChangedTracker />
        <JobsDatagrid />
      </List>
    </Card>
  );
};

export default JobList;
