const REASONS_BY_TYPE = {
  HQ: [
    'Pro No Show (<48 hrs)',
    'Pro Removal (>48 hrs)',
    'Driver No Show (<48 hrs)',
    'Driver Removal (>48 hrs)',
    'Driver Stacking',
    'Pro Stacking',
    'Pro Spot Never Filled',
    'Driver Spot Never Filled',
    'Training Move',
    'Recruit Certification',
    'Weather Conditions',
    'TOS Violation',
    'Late Pro',
    'Late Driver',
    'Previous Job Run Over',
    'Payment Hold Failure',
    'Truck Broke Down',
    'HQ Booking Mistake',
    'Pro Allocation',
    'Driver Allocation',
    'HQ Ops',
    'HQ Re-estimation',
  ],
  'HQ on Behalf of Customer': [
    'Weather Conditions',
    'Booking Mistake',
    'Concerns with TOS',
    "Didn't Have a Set Date",
    'Found Friends/Family to Do It',
    'No Availability for Requested Reschedule',
    'Personal/Family Crisis',
    'Pricing',
    'COVID-19',
    'No Longer Moving',
    'Scheduling Conflict',
    'Quality Concerns/Reviews',
    'False Cancel',
  ],
  Customer: [
    'Pro Spot Never Filled',
    'Driver Spot Never Filled',
    'Weather Conditions',
    'TOS Violation',
    'Late Pro',
    'Late Driver',
    'Previous Job Run Over',
    'Payment Hold Failure',
    'Truck Broke Down',
    'HQ Booking Mistake',
    'HQ Re-estimation',
    'Driver Decommit (24-72 hrs)',
    'Driver No Show (<24 hrs)',
    'Pro Decommit (24-72 hrs)',
    'Pro No Show (<24 hrs)',
    'Booking Mistake',
    'Concerns with TOS',
    "Didn't Have a Set Date",
    "Didn't Need the Help Anymore",
    'Found Friends/Family to Do It',
    'No Availability for Requested Reschedule',
    'Personal/Family Crisis',
    'Pricing',
    "Service Wasn't Good Fit/TOS",
    'Other',
    'Service Failure',
    'COVID-19',
    'Partner Service Failure',
    'Pro Removal (>72 hrs)',
    'Driver Removal (>72 hrs)',
    'No Longer Moving',
    'Scheduling Conflict',
    'Quality Concerns/Reviews',
    'Authorization Failed',
    'False Cancel',
  ],
};

export default REASONS_BY_TYPE;
