import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input, InputAdornment } from '@mui/material';

const CurrencyInput = ({ field: { onChange, value, name }, fieldState: { error }, disabled }) => {
  const { register } = useFormContext();

  return (
    <Input
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      onChange={(e) => {
        // Avoiding type=number due to scroll bugs with that input type
        // This ensures value always remains a number
        // First we determine if raw entry cast as Number is a number, if not we parse float
        const newValue = Number.isNaN(Number(e.target.value)) ? parseFloat(e.target.value) : e.target.value;
        // Second check covers if parseFloat returned NaN - only occurs when first entered character is non-numeric
        e.target.value = Number.isNaN(newValue) ? '' : newValue;
        onChange(e);
      }}
      error={!!error}
      autoComplete="off"
      disabled={disabled}
      value={value}
      inputProps={{ ...register(name), inputMode: 'numeric', pattern: '[0-9]*(.[0-9][0-9])*' }}
    />
  );
};

CurrencyInput.propTypes = {
  field: shape({
    onChange: func.isRequired,
    value: oneOfType([string, number]).isRequired,
    name: string.isRequired,
  }).isRequired,
  fieldState: shape({
    error: shape({}),
  }),
  disabled: bool,
};

CurrencyInput.defaultProps = {
  disabled: false,
  fieldState: {
    error: null,
  },
};

export default CurrencyInput;
