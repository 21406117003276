import React from 'react';
import { Button, Link, usePermissions, useShowContext } from 'react-admin';

import { Box, Grid, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import * as resources from '@/api/resources';
import QualificationLevelField from '@/fields/QualificationLevelField';

const SlotHeader = () => {
  const { permissions } = usePermissions();
  const { record } = useShowContext();

  if (!record) return null;

  return (
    <Box px={3} py={2}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={4}>
          <QualificationLevelField
            source="minimum_qualification_level"
            textFieldProps={{
              component: 'div',
              variant: 'h5',
            }}
          />
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography variant="h5" component={Box}>
            {record.status}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          {!record?.account_id && permissions.tools?.fulfillment?.claim_bonus?.create ? (
            <Button
              variant="outlined"
              startIcon={<Add />}
              component={Link}
              to={{
                pathname: `/${resources.JOB_SLOT_TRANSACTIONS}/create`,
                state: {
                  record: {
                    job_slot_id: record.id,
                  },
                },
              }}
              label="Add Claim Bonus"
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

SlotHeader.propTypes = {};

export default SlotHeader;
