import React from 'react';

import { Box } from '@mui/material';
import { string } from 'prop-types';

import { PROMO_CODES_TABS } from '@/constants/promoCode';

import PromoCodeDetails from './Details';

const PromoCodeContent = ({ currentTab }) => (
  <Box display={currentTab === PROMO_CODES_TABS.DETAILS ? 'block' : 'none'}>
    <PromoCodeDetails />
  </Box>
);

PromoCodeContent.propTypes = {
  currentTab: string.isRequired,
};

export default PromoCodeContent;
