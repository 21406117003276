const ZENDESK_TICKET_STATUS = {
  CLOSED: {
    id: 'closed',
    name: 'Closed',
  },
  HOLD: {
    id: 'hold',
    name: 'On-hold',
  },
  NEW: {
    id: 'new',
    name: 'New',
  },
  OPEN: {
    id: 'open',
    name: 'Open',
  },
  PENDING: {
    id: 'pending',
    name: 'Pending',
  },
  SOLVED: {
    id: 'solved',
    name: 'Solved',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { ZENDESK_TICKET_STATUS };
