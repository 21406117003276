import React from 'react';

import mx from 'mixpanel-browser';
import { useDataProvider, useListContext, useNotify, usePermissions, useShowContext } from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, Chip, Divider, IconButton, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import formatCurrency from '@/utils/currency/formatCurrency';

import { FEE_TYPES, MODIFIABLE_FEES } from '@/constants/quotes';
import { Add, Edit, RemoveCircleOutlined } from '@mui/icons-material';

const Fees = () => {
  const { record: { id: quoteId } = {}, refetch } = useShowContext();
  const { data } = useListContext();
  const navigate = useNavigate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteId}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Fee removed', {
      quoteId,
    });
    refetch();
    navigateOverview();
    notify('Quote updated', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error removing Fee', {
      quoteId,
      error,
    });
    notify(`Error occurred removing fee on quote - ${error}`, { type: 'error' });
  };

  const fees = data.filter((adjustment) => adjustment.adjustment_family === 'Fee');

  const removeFee = (feeId) => {
    const params = {
      data: [
        {
          op: 'remove',
          path: `/fees/${feeId}`,
        },
      ],
      id: quoteId,
      meta: {
        operationPatch: true,
      },
    };
    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  if (!data || !fees) return null;

  const canEditDeleteBaseMileageFees = permissions?.tools?.hqadmin?.quote_base_mileage_fee?.update;

  return (
    <Card>
      <CardHeader title="Fees" />
      <Divider />
      <CardContent>
        {fees?.length ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {fees.map((fee) => {
              const chipLabel = `${FEE_TYPES[fee.adjustment_id]?.name ?? 'Unknown Fee'} - ${formatCurrency(
                fee.estimated_amount,
              )}${fee.adjustment_description ? ` - ${fee.adjustment_description}` : ''}`;
              const baseOrMileageFee =
                fee.adjustment_id === FEE_TYPES.BASE_FEE.id || fee.adjustment_id === FEE_TYPES.MILEAGE_FEE.id;
              const feeModifiable =
                MODIFIABLE_FEES.includes(fee.adjustment_id) || (canEditDeleteBaseMileageFees && baseOrMileageFee);
              return feeModifiable ? (
                <Box display="flex" gap={1} alignItems="center" key={fee.id}>
                  <Chip label={chipLabel} />
                  <IconButton color="primary" title="Edit Fee" onClick={() => navigate(`edit/edit-fee/${fee.id}`)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="accent" title="Remove Fee" onClick={() => removeFee(fee.id)}>
                    <RemoveCircleOutlined />
                  </IconButton>
                </Box>
              ) : (
                <Box key={fee.id}>
                  <Chip label={chipLabel} />
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography>No fees on quote</Typography>
        )}
      </CardContent>
      <Divider />
      <CardContent>
        <Button startIcon={<Add />} variant="outlined" onClick={() => navigate(`edit/add-fee`)}>
          Add Fee
        </Button>
      </CardContent>
    </Card>
  );
};

export default Fees;
