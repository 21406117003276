import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { DateInput, Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JobSlotActionContext } from '@/providers/JobSlotAction';
import { OrderActionContext } from '@/providers/OrderAction';
import * as dateValidation from '../../../../utils/validate/date/index';

const UnpauseTransaction = () => {
  const { setAction: setJobAction, metadata: jobMetadata } = useContext(JobSlotActionContext);
  const { setAction: setOrderAction, metadata: orderMetadata } = useContext(OrderActionContext);

  const { record: { id: recordId } = {}, resource: drawerResource } = useShowContext();
  const orderTransactionId = orderMetadata?.transactionId;
  const jobTransactionId = jobMetadata?.transactionId;

  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error, { resource, id, meta }) => {
    refresh();
    if (resource === resources.JOB_SLOTS) {
      mx.track('Order Management - Error Unpausing Job Slot Transaction', {
        jobSlotId: id,
        transactionId: meta?.subResourceId,
      });
      notify(`Error unpausing job slot transaction - ${error.status} - ${error.message}`, { type: 'error' });
    }
    if (resource === resources.ORDERS) {
      mx.track('Order Management - Error Unpausing Order Transaction', {
        orderId: id,
        transactionId: meta?.subResourceId,
      });
      notify(`Error unpausing order transaction - ${error.status} - ${error.message}`, { type: 'error' });
    }
  };

  const onSuccess = (data, { resource, id, meta }) => {
    refresh();
    if (resource === resources.JOB_SLOTS) {
      mx.track('Order Management - Job Slot Transaction Unpaused', {
        jobSlotId: id,
        transactionId: meta?.subResourceId,
      });
      notify('Job Slot Transaction unpaused', { type: 'success' });
    }
    if (resource === resources.ORDERS) {
      mx.track('Order Management - Order Transaction Unpaused', {
        orderId: id,
        transactionId: meta?.subResourceId,
      });
      notify('Order Transaction unpaused', { type: 'success' });
    }
    if (setOrderAction) setOrderAction(null);
    if (setJobAction) setJobAction(null);
  };

  const onSubmit = (data) => {
    if (drawerResource === resources.ORDERS) {
      update(
        resources.ORDERS,
        {
          data: { auto_capture: true, date_to_process: data.date_to_process },
          id: recordId,
          meta: {
            subResource: 'charges',
            subResourceId: orderTransactionId,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    }
    if (drawerResource === resources.JOB_SLOTS) {
      update(
        resources.JOB_SLOTS,
        {
          data: { auto_capture: true, date_to_process: data.date_to_process },
          id: recordId,
          meta: {
            subResource: 'transactions',
            subResourceId: jobTransactionId,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    }
  };

  const validate = (data) => {
    const errors = {};

    if (drawerResource === resources.JOB_SLOTS && !dateValidation.isTuesday(data.date_to_process)) {
      errors.date_to_process = 'Date must be a Tuesday';
    }

    if (!dateValidation.isFuture(data.date_to_process)) {
      errors.date_to_process = 'Date must be in the future';
    }

    return errors;
  };

  return (
    <Box>
      <Form onSubmit={onSubmit} validate={validate}>
        <Box px={2} py={4} minWidth={425}>
          <Typography variant="h4" component={Box} pb={4} pl={2}>
            Unpause Transaction
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DateInput source="date_to_process" required label="Date to process" />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box pt={2} px={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="neutral"
            disabled={isLoading}
            onClick={() => {
              setOrderAction(null);
              setJobAction(null);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading ?? null}
            endIcon={isLoading ? <CircularProgress size={18} /> : null}
          >
            Submit
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default UnpauseTransaction;
