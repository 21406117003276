import React from 'react';

import { useGetList, useShowContext } from 'react-admin';

import { OpenInNew } from '@mui/icons-material';
import { Button } from '@mui/material';

import * as resources from '@/api/resources';
import { JOB_ACTIVE_STATUSES } from '@/constants/jobStatus';

const CustomerDashboardButton = () => {
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();

  const { data: jobData } = useGetList(resources.JOBS, { filter: { order_id: orderId, status: JOB_ACTIVE_STATUSES } });
  const { data: locationData } = useGetList(
    resources.LOCATIONS,
    { filter: { job_id: jobData?.[0]?.id } },
    { enabled: Boolean(jobData?.[0]?.id) },
  );
  const zip = locationData?.[0]?.postal_id;

  if (!zip) return null;

  return (
    <Button
      href={`${process.env.REACT_APP_DASHBOARD_URL}?code=${confirmationId}&zip=${zip}&autoLogin=true`}
      target="_blank"
      endIcon={<OpenInNew />}
    >
      Customer Dashboard
    </Button>
  );
};

export default CustomerDashboardButton;
