import { useMemo } from 'react';

import mx from 'mixpanel-browser';
import { useNotify, useRefresh, useUpdate } from 'react-admin';

import * as resources from '@/api/resources';

const usePauseTransaction = () => {
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error, { resource, id, meta }) => {
    refresh();
    if (resource === resources.JOB_SLOTS) {
      mx.track('Order Management - Error Pausing Job Slot Transaction', {
        jobSlotId: id,
        transactionId: meta?.subResourceId,
      });
      notify(`Error pausing job slot transaction - ${error.status} - ${error.message}`, { type: 'error' });
    }
    if (resource === resources.ORDERS) {
      mx.track('Order Management - Error Pausing Order Transaction', {
        orderId: id,
        transactionId: meta?.subResourceId,
      });
      notify(`Error pausing order transaction - ${error.status} - ${error.message}`, { type: 'error' });
    }
  };

  const onSuccess = (data, { resource, id, meta }) => {
    refresh();
    if (resource === resources.JOB_SLOTS) {
      mx.track('Order Management - Job Slot Transaction Paused', {
        jobSlotId: id,
        transactionId: meta?.subResourceId,
      });
      notify('Job Slot Transaction paused', { type: 'success' });
    }
    if (resource === resources.ORDERS) {
      mx.track('Order Management - Order Transaction Paused', {
        orderId: id,
        transactionId: meta?.subResourceId,
      });
      notify('Order Transaction paused', { type: 'success' });
    }
  };

  const pauseTransaction = ({ orderId, jobSlotId, transactionId }) => {
    if (orderId) {
      update(
        resources.ORDERS,
        {
          data: { auto_capture: false },
          id: orderId,
          meta: {
            subResource: 'charges',
            subResourceId: transactionId,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    }
    if (jobSlotId) {
      update(
        resources.JOB_SLOTS,
        {
          data: { auto_capture: false },
          id: jobSlotId,
          meta: {
            subResource: 'transactions',
            subResourceId: transactionId,
          },
        },
        {
          onError,
          onSuccess,
        },
      );
    }
  };

  const data = useMemo(
    () => ({
      pauseTransaction,
    }),
    [],
  );

  return [data, { isLoading }];
};

export default usePauseTransaction;
