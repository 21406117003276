/*
 * This is a direct clone of RA's List component in order to fix filter persistence in local storage
 * Only notable changes in List stack is in useListParams
 *
 * The following items needed to be clone in order to support changing that hook
 * List -> ListBase -> useListController -> useListParams
 *
 * PR was opened on react-admin official repo to potentially fix this in a future release
 *
 * Disabled eslint rules for prop-types to minimize changes to their original code
 */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import { arrayOf, bool, element, number, oneOfType, shape, string } from 'prop-types';
import * as React from 'react';
import { ListView, TitlePropType } from 'react-admin';

import ListBase from './ListBase';

/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 *
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * The <List> component accepts the following props:
 *
 * - actions
 * - aside: Side Component
 * - children: List Layout
 * - component
 * - disableAuthentication
 * - disableSyncWithLocation
 * - empty: Empty Page Component
 * - emptyWhileLoading
 * - exporter
 * - filters: Filter Inputs
 * - filter: Permanent Filter
 * - filterDefaultValues
 * - pagination: Pagination Component
 * - perPage: Pagination Size
 * - queryOptions
 * - sort: Default Sort Field & Order
 * - title
 * - sx: CSS API
 *
 * @example
 * const postFilters = [
 *     <TextInput label="Search" source="q" alwaysOn />,
 *     <TextInput label="Title" source="title" />
 * ];
 * export const PostList = (props) => (
 *     <List {...props}
 *         title="List of posts"
 *         sort={{ field: 'published_at' }}
 *         filter={{ is_published: true }}
 *         filters={postFilters}
 *     >
 *         <Datagrid>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 */
const List = ({
  debounce,
  disableAuthentication,
  disableSyncWithLocation,
  exporter,
  filter,
  filterDefaultValues,
  perPage,
  queryOptions,
  resource,
  sort,
  storeKey,
  ...rest
}) => (
  <ListBase
    debounce={debounce}
    disableAuthentication={disableAuthentication}
    disableSyncWithLocation={disableSyncWithLocation}
    exporter={exporter}
    filter={filter}
    filterDefaultValues={filterDefaultValues}
    perPage={perPage}
    queryOptions={queryOptions}
    resource={resource}
    sort={sort}
    storeKey={storeKey}
  >
    <ListView {...rest} />
  </ListBase>
);

List.propTypes = {
  // the props you can change
  actions: oneOfType([bool, element]),
  aside: element,
  children: element.isRequired,
  className: string,
  emptyWhileLoading: bool,
  filter: shape({}),
  filterDefaultValues: shape({}),
  filters: oneOfType([element, arrayOf(element)]),
  pagination: oneOfType([element, bool]),
  perPage: number,
  sort: shape({
    field: string,
    order: string,
  }),
  sx: shape({}),
  title: TitlePropType,
  // the props managed by react-admin
  disableSyncWithLocation: bool,
  hasCreate: bool,
  hasEdit: bool,
  hasList: bool,
  hasShow: bool,
  resource: string,
};

List.defaultProps = {
  filter: {},
  perPage: 10,
};

export default List;
