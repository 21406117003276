import React, { useState } from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Form, useCreate, useNotify } from 'react-admin';

import { Box, Modal } from '@mui/material';

import * as resources from '@/api/resources';
import objectDiff from '@/utils/objectDiff';
import toPascalCase from '@/utils/toPascalCase';

import CorrectedAddress from './CorrectedAddress';
import EditLocationForm from './EditLocationForm';

const EditLocationModal = ({ open, onClose, selectedLocation, locations, setLocations }) => {
  const [create] = useCreate();
  const notify = useNotify();

  const [displayCorrectedAddress, setDisplayCorrectedAddress] = useState(false);
  const [submittedAddress, setSubmittedAddress] = useState({});
  const [correctedAddress, setCorrectedAddress] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  if (!selectedLocation && selectedLocation === null) return null;

  const locationToEdit = _.find(locations, (location) => location.location_id === selectedLocation);

  const updateLocations = (newLocation) => {
    const newLocationData = [...locations];

    if (!locationToEdit) {
      mx.track('Order Management - Create Quote - Location locally added');
      newLocationData.push({
        location: newLocation,
        location_id: newLocation.id,
      });
      onClose();
      setLocations(newLocationData);
      notify('Location set successfully', { type: 'success' });
      return;
    }

    const index = _.findIndex(newLocationData, (location) => location.location.id === locationToEdit.location.id);

    newLocationData[index] = {
      location: newLocation,
      location_id: newLocation.id,
    };

    onClose();
    setLocations(newLocationData);

    mx.track('Order Management - Create Quote - Location locally updated');
    notify('Location set successfully', { type: 'success' });
  };

  const setLocationOnSuccess = (data, variables) => {
    const submittedData = variables.data;
    const newLocation = {
      ...data,
      line_1: toPascalCase(data.line_1),
      line_2: data.line_2 ? toPascalCase(data.line_2) : '',
      city: toPascalCase(data.city),
    };

    const dataDiff = objectDiff(
      {
        ...newLocation,
        line_1: newLocation.line_1?.toLowerCase() ?? '',
        line_2: newLocation.line_2?.toLowerCase() ?? '',
      },
      {
        ...submittedData,
        line_1: submittedData.line_1?.toLowerCase() ?? '',
        line_2: submittedData.line_2?.toLowerCase() ?? '',
      },
    );

    delete dataDiff.id; // Location ID is expected to be different
    delete dataDiff.market_id; // Ignore market - clear location edge case - market restriction handled above

    if (!_.isEmpty(dataDiff)) {
      mx.track('Order Management - Create Quote - Location - Corrected Address displayed', {
        submittedLocation: submittedData,
        correctedLocation: newLocation,
      });
      notify('Location was auto corrected - Verify location for accuracy', { type: 'warning' });
      setDisplayCorrectedAddress(true);
      setSubmittedAddress(submittedData);
      setCorrectedAddress(newLocation);
    } else {
      updateLocations(newLocation);
    }
    setIsUpdating(false);
  };

  const setLocationOnError = (error) => {
    const { message, status } = error;
    mx.track('Order Management - Create Quote - Location - Error adding / updating local Location', {
      error,
    });
    if (status === 404) {
      notify('Location is not in service area', { type: 'error' });
    } else if (status === 400 && message.includes('Unable to validate the address provided:')) {
      notify('Could not validate address', { type: 'error' });
    } else {
      notify('Error occurred while saving location', { type: 'error' });
    }
    setIsUpdating(false);
  };

  const onSubmit = (submittedData) => {
    setIsUpdating(true);
    const data = {
      ...submittedData.location,
      country: 'US',
    };
    delete data.id;
    delete data.market_id;

    create(
      resources.LOCATIONS,
      { data },
      {
        onError: setLocationOnError,
        onSuccess: setLocationOnSuccess,
      },
    );
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (displayCorrectedAddress) {
      setDisplayCorrectedAddress(false);
      setSubmittedAddress({});
      setCorrectedAddress({});
      onClose();
      updateLocations(correctedAddress);
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      sx={{
        outline: 0,
      }}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="80vw"
        overflow="auto"
        bgcolor="background.paper"
        color="text.primary"
        boxShadow={24}
        sx={{
          transform: 'translate(-50%, -50%)',
          outline: 0,
        }}
      >
        {displayCorrectedAddress ? (
          <CorrectedAddress
            onClose={handleModalClose}
            submittedAddress={submittedAddress}
            correctedAddress={correctedAddress}
          />
        ) : (
          <Form record={locationToEdit ?? {}} onSubmit={onSubmit}>
            <EditLocationForm onClose={handleModalClose} isUpdating={isUpdating} />
          </Form>
        )}
      </Box>
    </Modal>
  );
};

EditLocationModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  selectedLocation: string,
  locations: arrayOf(shape({})),
  setLocations: func.isRequired,
};

EditLocationModal.defaultProps = {
  selectedLocation: null,
  locations: [],
};

export default EditLocationModal;
