import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { PREFERENCES_VALUES } from '@/constants/preferences';

dayjs.extend(utc);
dayjs.extend(tz);

// Returns dayjs date object using timezone if user preference is to display in local time
const prefDayJs = ({ date, timezone, timePreference, keepLocalTime = false }) => {
  let retDate = dayjs(date);

  if (timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL) {
    retDate = retDate.tz(timezone ?? 'America/Detroit', keepLocalTime);
  }
  return retDate;
};

export default prefDayJs;
