import React from 'react';

import { BooleanField, DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

import CurrencyField from '@/fields/CurrencyField';

const AdminStripeTransactionShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" emptyText="N/A" />
      <TextField source="name" emptyText="N/A" />
      <TextField source="description" emptyText="N/A" />
      <CurrencyField source="amount" textAlign="left" />
      <CurrencyField source="authorization_amount" emptyText="N/A" textAlign="left" />
      <CurrencyField source="retained_revenue_amount" emptyText="N/A" textAlign="left" />
      <DateField source="due_date" emptyText="N/A" />
      <DateField source="date_to_process" emptyText="N/A" />
      <TextField source="payment_status" emptyText="N/A" />
      <DateField source="processed_date" emptyText="N/A" />
      <TextField source="transaction_status" emptyText="N/A" />
      <TextField source="transaction_type" emptyText="N/A" />
      <TextField source="transaction_reason" emptyText="N/A" />
      <TextField source="family" emptyText="N/A" />
      <TextField source="transaction_id" emptyText="N/A" label="Transaction id" />
      <BooleanField source="authorize_only" emptyText="N/A" />
      <BooleanField source="auto_capture" emptyText="N/A" />
      <BooleanField source="non_stripe" emptyText="N/A" />
      <TextField source="connected_account_code" emptyText="N/A" />
      <TextField source="connected_account_id" emptyText="N/A" label="Connected account id" />
      <TextField source="customer_id" emptyText="N/A" label="Customer id" />
      <TextField source="stripe_customer_code" emptyText="N/A" />
      <TextField source="payment_gateway_code" emptyText="N/A" />
      <TextField source="payment_method_code" emptyText="N/A" />
      <TextField source="related_account_code" emptyText="N/A" />
      <TextField source="parent_transaction_id" emptyText="N/A" label="Parent transaction id" />
      <TextField source="parent_transaction_code" emptyText="N/A" />
      <TextField source="reattempted_transaction" emptyText="N/A" />
      <TextField source="reattempted_transaction_code" emptyText="N/A" />
      <TextField source="job_slot_code" emptyText="N/A" />
      <TextField source="order_code" emptyText="N/A" />
      <TextField source="currency_iso" emptyText="N/A" />
      <TextField source="error_code" emptyText="N/A" />
      <TextField source="error_message" emptyText="N/A" />
      <BooleanField source="isdeleted" emptyText="N/A" />
    </SimpleShowLayout>
  </Show>
);

export default AdminStripeTransactionShow;
