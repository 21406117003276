import React, { useEffect, useState } from 'react';

import { func } from 'prop-types';
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  TextField,
  TextInput,
  useNotify,
  useRecordContext,
  useUnselectAll,
} from 'react-admin';

import { Avatar, Box, Card, CardHeader, Checkbox, Tooltip } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';
import ListViewChangedTracker from '@/shared/events/ListViewChangedTracker';
import { THIRD_PARTY_CREW_RECORD_TYPE } from '@/constants/thirdPartyCrewAccount';
import { Groups, Person, QuestionMark } from '@mui/icons-material';
import { THIRD_PARTY_WORKER_RECORD_TYPE } from '@/constants/thirdPartyWorkerAccount';

const AssignField = ({ setSelectedAccount }) => {
  const accountRecord = useRecordContext();
  const [isChecked, setIsChecked] = useState(false);
  const notify = useNotify();

  const handleCheckboxChange = (event) => {
    if (event.target.checked && isChecked) {
      notify(`You can only select one mover!`, { type: 'error' });
    } else {
      setIsChecked(event.target.checked);
      setSelectedAccount(accountRecord);
    }
  };

  return <Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" />;
};

const ThirdPartyMoverDatagrid = ({ setSelectedAccount }) => (
  <Datagrid rowClick={false} bulkActionButtons={false}>
    <AssignField setSelectedAccount={setSelectedAccount} />
    <FunctionField cellClassName="no-x-padding" render={(rec) => <Avatar src={rec.image_url} />} />
    <FunctionField
      render={(rec) => {
        if (rec.account_record_type_name === THIRD_PARTY_CREW_RECORD_TYPE) {
          return (
            <Tooltip title="Crew">
              <Groups />
            </Tooltip>
          );
        }
        if (rec.account_record_type_name === THIRD_PARTY_WORKER_RECORD_TYPE) {
          return (
            <Tooltip title="Worker">
              <Person />
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Unknown account type">
            <QuestionMark />
          </Tooltip>
        );
      }}
    />
    <ReferenceField reference={resources.ACCOUNTS} source="id" label="Name" link="show" sortable={false}>
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField reference={resources.ACCOUNTS} source="company_id" label="Company" link="show" sortable={false}>
      <TextField source="name" />
    </ReferenceField>
  </Datagrid>
);

const ThirdPartyMoverList = ({ setSelectedAccount }) => {
  const unselectAll3PM = useUnselectAll(resources.ACCOUNTS);

  useEffect(() => {
    unselectAll3PM();
  }, []);

  const [hideDatagrid, setHideDatagrid] = useState(false);

  const handleInputChange = (event) => {
    if (event.target.value !== '') {
      setHideDatagrid(true);
    } else {
      setHideDatagrid(false);
    }
  };
  const accountFilters = [<TextInput source="name" alwaysOn autoComplete="off" onChange={handleInputChange} />];

  return (
    <Card variant="outlined">
      <CardHeader title="Search Third Party Crews" />
      <List
        component={Box}
        resource={resources.THIRD_PARTY_CREW}
        pagination={false}
        filters={accountFilters}
        actions={false}
        exporter={false}
        hasCreate={false}
        empty={<EmptyResourceList text="No Third Party Crew accounts" />}
        storeKey={`order.convertToBhn.${resources.THIRD_PARTY_CREW}.listParams`}
        disableSyncWithLocation
      >
        <ListViewChangedTracker />
        {hideDatagrid ? <ThirdPartyMoverDatagrid setSelectedAccount={setSelectedAccount} /> : null}
      </List>
    </Card>
  );
};

ThirdPartyMoverList.propTypes = {
  setSelectedAccount: func.isRequired,
};

ThirdPartyMoverDatagrid.propTypes = {
  setSelectedAccount: func.isRequired,
};
AssignField.propTypes = {
  setSelectedAccount: func.isRequired,
};

export default ThirdPartyMoverList;
