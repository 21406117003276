import React from 'react';

import { useParams } from 'react-router';

import { CUSTOMER_ACCOUNT_DETAIL_SECTION } from '@/constants/customerAccount';

import CustomerDetailsOverviewEdit from './Overview';

const CustomerDetailsEdit = () => {
  const { section: currentSection } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case CUSTOMER_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      EditComponent = <CustomerDetailsOverviewEdit />;
      break;
    default:
      break;
  }

  return EditComponent;
};

export default CustomerDetailsEdit;
