import * as resources from '@/api/resources';
import UserCreate from './Create';
import UserList from './List';
import UserShow from './Show';
import UserEdit from './Edit';

const users = ({
  tools: {
    hqadmin: { users: usersPerm },
  },
}) =>
  usersPerm?.view
    ? {
        name: resources.USERS,
        create: usersPerm.create ? UserCreate : null,
        edit: usersPerm.edit ? UserEdit : null,
        list: UserList,
        show: UserShow,
      }
    : false;

export default users;
