import React, { useContext, useMemo } from 'react';

import dayjs from 'dayjs';
import { DateField, Labeled, NumberField, RecordContextProvider } from 'react-admin';

import { Dialog, DialogContent, DialogTitle, Divider, Grid, Tooltip, Typography } from '@mui/material';

import CurrencyField from '@/fields/CurrencyField';
import MarketNameField from '@/fields/MarketNameField';
import ServiceNameField from '@/fields/ServiceNameField';
import { ServiceLayerSummaryContext } from '@/providers/Pricing/ServiceLayerSummary';

const LayerGroupDialog = () => {
  const { filters, selectedLayerGroup, setSelectedLayerGroup } = useContext(ServiceLayerSummaryContext);

  const isSingleLayer = selectedLayerGroup?.layers?.length === 1;
  const singleLayerData = selectedLayerGroup?.layers?.[0];

  const sortedGroupLayers = useMemo(() => {
    if (!selectedLayerGroup?.layers) return [];
    if (!filters) return [];

    return [...selectedLayerGroup.layers].sort((a, b) => a.start_market_id.localeCompare(b.start_market_id));
  }, [selectedLayerGroup, filters]);

  return (
    <Dialog maxWidth="md" open={!!selectedLayerGroup} onClose={() => setSelectedLayerGroup(null)} fullWidth>
      {!filters || !selectedLayerGroup ? null : (
        <>
          <DialogTitle>As of {dayjs(filters.effective_date_lte).format('M/D/YYYY')}</DialogTitle>
          <Divider />
          <DialogContent>
            <RecordContextProvider value={selectedLayerGroup}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Labeled label="Base Price">
                    <RecordContextProvider value={singleLayerData}>
                      <CurrencyField source="base_price" />
                    </RecordContextProvider>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled label="Per Worker Price">
                    <RecordContextProvider value={singleLayerData}>
                      <CurrencyField source="per_worker_price" />
                    </RecordContextProvider>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled label="Fixed Price Modifier">
                    <RecordContextProvider value={singleLayerData}>
                      <NumberField source="fixed_price_modifier" />
                    </RecordContextProvider>
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled label="Service">
                    <RecordContextProvider value={singleLayerData}>
                      <ServiceNameField source="service_id" />
                    </RecordContextProvider>
                  </Labeled>
                </Grid>
                {isSingleLayer ? (
                  <Grid item xs={4}>
                    <Labeled label="Market">
                      <RecordContextProvider value={{ market: singleLayerData.start_market_id }}>
                        <MarketNameField source="market" />
                      </RecordContextProvider>
                    </Labeled>
                  </Grid>
                ) : null}
                {isSingleLayer ? (
                  <Grid item xs={4}>
                    <Labeled label="Effective Since">
                      <RecordContextProvider value={singleLayerData}>
                        <DateField source="effective_date" />
                      </RecordContextProvider>
                    </Labeled>
                  </Grid>
                ) : null}
                {!isSingleLayer ? (
                  <Grid item xs={12}>
                    <Typography variant="body1" fontSize="0.8em">
                      Market List
                    </Typography>
                    <Grid container spacing={2} mt={0}>
                      {sortedGroupLayers.map((layer) => (
                        <RecordContextProvider value={layer} key={layer.id}>
                          <Grid item xs={4}>
                            <Tooltip title={`Effective since ${dayjs(layer.effective_date).format('M/D/YYYY')}`}>
                              <span>
                                <MarketNameField source="start_market_id" />
                              </span>
                            </Tooltip>
                          </Grid>
                        </RecordContextProvider>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </RecordContextProvider>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default LayerGroupDialog;
