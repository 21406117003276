const getAccountTypeText = ({ account_record_type_name: recordTypeName } = {}) => {
  if (!recordTypeName) return null;

  let accountTypeText;

  switch (recordTypeName) {
    case 'Third Party Crew':
    case 'Third Party Worker':
      accountTypeText = recordTypeName;
      break;
    case 'Carrier':
    case 'Multi-ID Carrier Child':
    case 'Multi-ID Carrier Parent':
    case 'Single-ID Carrier':
      accountTypeText = 'Carrier';
      break;
    case 'Bellhop':
      accountTypeText = 'Pro';
      break;
    case 'Customer':
      accountTypeText = recordTypeName;
      break;
    default:
      accountTypeText = 'Unknown Account';
  }

  return accountTypeText;
};

export default getAccountTypeText;
