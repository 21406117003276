import React from 'react';

import { BooleanInput, Create, ListButton, NumberInput, SaveButton, SelectInput, SimpleForm, TextInput } from 'react-admin';

import { Box, Divider } from '@mui/material';

import { DISTANCE_TYPE, FAMILY, SERVICEABLE_ADDRESS_TYPE, PAYMENT_GATEWAY } from '@/constants/product';

const CustomToolbar = () => (
  <Box>
    <Divider />
    <Box display="flex" justifyContent="space-between" p={3}>
      <ListButton label="Cancel" icon={null} color="secondary" />
      <SaveButton label="Create" icon={null} />
    </Box>
  </Box>
);

const AdminProductCreate = () => {
  const validateMaxNotLowerThanMin = (values) => {
    const errors = {};
    if (values.minimum_workers > values.maximum_workers) {
      errors.maximum_workers = 'Maximum workers must be greater than the minimum workers!';
    }

    if (values.minimum_duration > values.maximum_duration) {
      errors.maximum_duration = 'Maximum duration must be greater than the minimum duration!';
    }

    const objectSize = Object.keys(errors).length;
    if (objectSize > 0) {
      return errors;
    }

    return null;
  };

  return (
    <Create redirect="show">
      <SimpleForm toolbar={<CustomToolbar />} validate={validateMaxNotLowerThanMin}>
        <TextInput source="name" required />
        <TextInput source="product_code" required />
        <TextInput source="group_code" />
        <SelectInput source="payment_gateway" choices={PAYMENT_GATEWAY} />
        <NumberInput source="address_quantity" required />
        <NumberInput source="minimum_workers" required />
        <NumberInput source="maximum_workers" required />
        <NumberInput source="minimum_duration" required />
        <NumberInput source="maximum_duration" required />
        <SelectInput source="serviceable_address_type" choices={SERVICEABLE_ADDRESS_TYPE} />
        <NumberInput source="non_aggregate_rank" required />
        <NumberInput source="aggregate_rank" required />
        <NumberInput source="recommended_sequence" required />
        <SelectInput source="family" choices={FAMILY} />
        <SelectInput source="distance_type" choices={DISTANCE_TYPE} />
        <TextInput source="image_url" />
        <BooleanInput source="unclaimable" />
        <BooleanInput source="customizable" />
        <BooleanInput source="claimable" />
        <BooleanInput source="ignore_rule_quote_availability" />
        <BooleanInput source="use_aggregate_rank" />
      </SimpleForm>
    </Create>
  );
};

export default AdminProductCreate;
