import React from 'react';
import {
  LoadingIndicator,
  RecordContextProvider,
  useGetList,
  useRecordContext,
  useReference,
  useShowContext,
} from 'react-admin';

import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import getServiceName from '@/utils/serviceName/getServiceName';

const JobChip = () => {
  const record = useRecordContext();

  const { referenceRecord: jobRecord, isLoading } = useReference({
    reference: 'jobs',
    id: record?.id,
    options: { enabled: Boolean(record?.id) },
  });

  if (!record) return null;

  return !jobRecord && isLoading ? (
    <Box display="inline-flex">
      <LoadingIndicator />
    </Box>
  ) : (
    <Chip label={`${getServiceName(jobRecord.product_id)} - ${record?.sequence}`} component={Box} ml={1} />
  );
};

const OrderContentLocations = () => {
  const { record: { id: orderId } = {} } = useShowContext();

  const { data, isLoading } = useGetList(
    resources.LOCATIONS,
    {
      filter: { order_id: orderId },
      sort: { field: 'sequence', order: 'ASC' },
    },
    { enabled: Boolean(orderId) },
  );

  if (!orderId) return null;

  return (
    <Grid container spacing={2}>
      {!data && isLoading ? (
        <Grid item xs={12} textAlign="center">
          <LoadingIndicator width="100%" size={100} />
        </Grid>
      ) : (
        data.map((loc) => (
          <Grid item xs={12}>
            <Card>
              <CardContent key={loc.id}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="body2">{`${loc.street_address_1}`}</Typography>
                    {loc.street_address_2 ? <Typography variant="body2">{`${loc.street_address_2}`}</Typography> : null}
                    <Typography variant="body2">{`${loc.city}, ${loc.state} ${loc.postal_id}`}</Typography>
                  </Box>
                  <Box>
                    <Chip label={loc.market_id} />
                    {loc.jobs.map((job) => (
                      <RecordContextProvider value={job}>
                        <JobChip />
                      </RecordContextProvider>
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default OrderContentLocations;
