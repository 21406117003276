import React, { useContext, useMemo } from 'react';

import { Datagrid, DateField, FunctionField, Labeled, RecordContextProvider, useGetList } from 'react-admin';

import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { ServiceLayerSummaryContext } from '@/providers/Pricing/ServiceLayerSummary';

import { PRICING_LAYER_TYPES, SERVICES } from '@/constants/pricingLayers';
import CurrencyField from '@/fields/CurrencyField';
import getServiceName from '@/utils/serviceName/getServiceName';

const Summary = () => {
  const { dataEnabled, filters, setSelectedLayerGroup } = useContext(ServiceLayerSummaryContext);

  const sort = { field: 'id', order: 'DESC' };
  const { data, isLoading, isFetching } = useGetList(
    `${resources.PRICING_LAYERS}/summary`,
    {
      filter: {
        type: PRICING_LAYER_TYPES.SERVICE,
        ...filters,
        service_id: filters.service_id?.length
          ? filters.service_id
          : Object.values(SERVICES)
              .filter(({ id }) => id !== SERVICES.LDTRANSIT.id)
              .map(({ id }) => id),
      },
      sort,
    },
    {
      enabled: dataEnabled,
    },
  );

  // Sorts pricing layers within each service group by base price then by per worker price
  const sortedByBasePriceData = useMemo(() => {
    if (!data) return [];

    return data.map((group) => ({
      ...group,
      prices: (Object.values(group.prices) ?? []).sort(
        (a, b) => a.base_price - b.base_price || a.per_worker_price - b.per_worker_price,
      ),
    }));
  }, [data]);

  return (
    <Card mt={2} component={Box}>
      <CardHeader title="Services" />
      <Divider />
      <CardContent
        sx={{
          backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest),
        }}
      >
        {isLoading || isFetching ? (
          <Box textAlign="center">
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {sortedByBasePriceData?.map((servicePricingGroup) => (
              <Grid key={servicePricingGroup.id} xs={12} md={6} lg={4} item>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box minWidth={100} textAlign="center">
                    {getServiceName(servicePricingGroup.id)}
                  </Box>
                  <Box my={1} width="100%">
                    <Divider />
                  </Box>
                  <Card component={Box} mt={1} ml={0} display="flex" justifyContent="center">
                    {/* If Market filter active only one layer will return simple layout instead of datagrid */}
                    {filters.market_id ? (
                      <CardContent>
                        <RecordContextProvider value={servicePricingGroup.prices[0].layers[0]}>
                          <Box display="flex" gap={4}>
                            <Labeled>
                              <CurrencyField source="base_price" />
                            </Labeled>
                            <Labeled>
                              <CurrencyField source="per_worker_price" />
                            </Labeled>
                            <Labeled label="Effective Since">
                              <DateField source="effective_date" />
                            </Labeled>
                          </Box>
                        </RecordContextProvider>
                      </CardContent>
                    ) : (
                      <Datagrid
                        data={servicePricingGroup?.prices}
                        total={servicePricingGroup?.prices?.length ?? 0}
                        isLoading={isLoading || isFetching}
                        sort={sort}
                        bulkActionButtons={false}
                        rowClick={(_, __, record) => {
                          setSelectedLayerGroup({
                            type: 'service',
                            ...record,
                          });
                          return false;
                        }}
                      >
                        <CurrencyField source="base_price" />
                        <CurrencyField source="per_worker_price" />
                        <FunctionField
                          render={({ markets }) => `${markets.length} market${markets.length === 1 ? '' : 's'}`}
                        />
                      </Datagrid>
                    )}
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default Summary;
