import React from 'react';

import dayjs from 'dayjs';
import { LoadingIndicator, useGetOne } from 'react-admin';
import { arrayOf, shape, string } from 'prop-types';

import { Box, Typography } from '@mui/material';
import { DirectionsOutlined, Schedule } from '@mui/icons-material';

import * as resources from '@/api/resources';

const RouteInfo = ({ locations }) => {
  const {
    data: routeData,
    isLoading: isRouteLoading,
    isFetching: isRouteFetching,
  } = useGetOne(
    `${resources.UTILITY}/route`,
    {
      meta: { filter: { ids: locations?.map(({ id }) => id) } },
    },
    { enabled: Boolean(locations?.length > 1), retry: false },
  );

  if (isRouteLoading || isRouteFetching) {
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );
  }

  const dayjsDuration = dayjs.duration(routeData?.travel_time, 'minutes');
  let travelTimeText = dayjsDuration.format('H[h] m[m]');

  if (routeData?.travel_time < 60) {
    // Less than an hour, only show minutes
    travelTimeText = dayjsDuration.format('m[m]');
  } else if (routeData?.travel_time > 24 * 60) {
    // Greater than 24 hours, only show hours
    travelTimeText = dayjsDuration.format('D[d] H[h] m[m]');
  }

  if (!routeData) return null;

  return (
    <Box display="flex" gap={2} px={2}>
      <Box textAlign="center">
        <DirectionsOutlined />
        <Typography variant="body2">{routeData?.driving_distance?.toFixed(0)} miles</Typography>
      </Box>
      <Box textAlign="center">
        <Schedule />
        <Typography variant="body2">{travelTimeText}</Typography>
      </Box>
    </Box>
  );
};

RouteInfo.propTypes = {
  locations: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ).isRequired,
};

export default RouteInfo;
