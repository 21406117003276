import React from 'react';
import { RecordContextProvider, useGetManyReference, useRecordContext } from 'react-admin';

import { Box, Card, CircularProgress, Divider } from '@mui/material';
import * as resources from '@/api/resources';
import Job from './Job';

const JobsPanel = () => {
  const record = useRecordContext();

  const { data, isLoading, isFetching } = useGetManyReference(
    resources.JOBS,
    {
      target: 'order_id',
      id: record?.id,
      filter: {
        status: ['COMPLETE', 'OPEN'],
      },
      sort: {
        field: 'start_date_time',
        order: 'ASC',
      },
    },
    {
      enabled: Boolean(record?.id),
    },
  );

  if (!data || isLoading || isFetching) return <CircularProgress />;
  if (data.length === 0) return <Card>No jobs found</Card>;

  return (
    <Card component={Box} my={2}>
      {data?.map((job, i, { length }) => (
        <RecordContextProvider value={job} key={job.id}>
          <Job />
          {i < length - 1 && <Divider />}
        </RecordContextProvider>
      ))}
    </Card>
  );
};

export default JobsPanel;
