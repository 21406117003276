import React from 'react';

import {
  AutocompleteInput,
  BooleanInput,
  EditBase,
  Form,
  ReferenceInput,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_REGION, MARKET_STATUSES } from '@/constants/market';
import MarketHeader from '@/resources/markets/Show/MarketHeader';

const FormControls = () => (
  <Grid item xs={4} display="flex" justifyContent="space-between">
    <ShowButton label="Cancel" icon={null} />
    <SaveButton label="Update" icon={null} />
  </Grid>
);

const DetailsForm = () => {
  const filterToQuery = (query) => ({ name: query });

  return (
    <Form>
      <CardContent>
        <Grid container spacing={2} px={2}>
          <Grid item xs={3}>
            <TextInput source="name" label="Name" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              source="market_manager"
              reference={resources.USERS}
              enableGetChoices={({ name }) => Boolean(name?.length > 0)}
              emptyText="N/A"
            >
              <AutocompleteInput optionText="name" filterToQuery={filterToQuery} label="Market Manager" default />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <ReferenceInput
              source="pro_recruiting_specialist"
              reference={resources.USERS}
              enableGetChoices={({ name }) => Boolean(name?.length > 0)}
              emptyText="N/A"
            >
              <AutocompleteInput optionText="name" filterToQuery={filterToQuery} label="Recruit Specialist" default />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <SelectInput source="status" label="Status" choices={MARKET_STATUSES} fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="market_center_street_1" label="Address line 1" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="market_center_street_2" label="Address line 2" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <TextInput source="market_center_city" label="City" fullWidth />
          </Grid>
          <Grid item xs={1}>
            <TextInput source="market_center_state" label="State" fullWidth />
          </Grid>
          <Grid item xs={1}>
            <TextInput source="market_center_zip" label="Zip" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <SelectInput source="region" label="Region" choices={MARKET_REGION} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="is_pod_available" label="PODS Enabled" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="is_marketplace" label="Marketplace" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="is_labor_only" label="Labor Only" fullWidth />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <FormControls />
      </CardContent>
    </Form>
  );
};

const MarketDetailsPrimaryInformationEdit = () => {
  const { marketId } = useParams();

  return (
    <EditBase
      resource={resources.MARKETS}
      id={marketId}
      mutationMode="pessimistic"
      redirect={`/${resources.MARKETS}/${marketId}/show`}
      actions={false}
    >
      <Box mt={3}>
        <MarketHeader />
      </Box>
      <Card mt={3} component={Box}>
        <CardHeader title="Primary Information" />
        <Divider />
        <DetailsForm />
      </Card>
    </EditBase>
  );
};

export default MarketDetailsPrimaryInformationEdit;
