import React from 'react';

import { string } from 'prop-types';
import dayjs from 'dayjs';

import { Alert } from '@mui/material';

const OrderAlertField = ({ variant, severity, text, orderStartDate }) => {
  let alert = null;

  const today = dayjs();
  const pastReconcile = today.diff(dayjs(orderStartDate), 'day') > 8;

  if (pastReconcile) {
    alert = (
      <Alert variant={variant} severity={severity}>
        {text}
      </Alert>
    );
  }

  return alert;
};

OrderAlertField.propTypes = {
  variant: string.isRequired,
  severity: string.isRequired,
  text: string.isRequired,
  orderStartDate: string.isRequired,
};

export default OrderAlertField;
