import React from 'react';

import { Labeled, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION, THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewSectionHeader from '../Common/SectionHeader';

const ThirdPartyCrewDetailsRecruitmentSection = () => (
  <Card mt={3} component={Box}>
    <ThirdPartyCrewSectionHeader
      title="Recruitment"
      contextMenuItems={[]}
      tab={THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS}
      section={THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.RECRUITMENT}
    />

    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="breezy_candidate_id" emptyText="N/A" />
          </Labeled>
        </Grid>

        <Grid item xs={4}>
          <Labeled>
            <TextField source="breezy_company_id" emptyText="N/A" />
          </Labeled>
        </Grid>

        <Grid item xs={4}>
          <Labeled>
            <TextField source="breezy_position_id" emptyText="N/A" />
          </Labeled>
        </Grid>

        <Grid item xs={4}>
          <Labeled>
            <TextField source="background_check" emptyText="N/A" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ThirdPartyCrewDetailsRecruitmentSection;
