import React from 'react';

import { Labeled, RecordContextProvider, TextField, useGetOne, useShowContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';

const AssignmentCard = () => {
  const { record: jobSlotRecord } = useShowContext();
  const { data: userData } = useGetOne(
    resources.USERS,
    { id: jobSlotRecord.assigned_by_user_id, meta: { subResource: '' } },
    { enabled: Boolean(jobSlotRecord?.assigned_by_user_id) },
  );

  if (!jobSlotRecord) return null;
  return (
    <Card component={Box} mt={2}>
      <CardHeader title="Assignment" />
      <Divider />
      <CardContent>
        <Grid container px={1}>
          <Grid item xs={6}>
            <RecordContextProvider value={userData}>
              <Labeled label="Assigned by User">
                <TextField source="name" />
              </Labeled>
            </RecordContextProvider>
          </Grid>
          <Grid item xs={6}>
            <Labeled label="Assignment Method">
              <TextField source="assignment_method" />
            </Labeled>
          </Grid>
          {jobSlotRecord?.removal_reason ? (
            <Grid item xs={6}>
              <Labeled label="Removal Reason">
                <TextField source="removal_reason" />
              </Labeled>
            </Grid>
          ) : null}
          {jobSlotRecord?.removal_details ? (
            <Grid item xs={6}>
              <Labeled label="Removal Details">
                <TextField source="removal_details" />
              </Labeled>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssignmentCard;
