import React from 'react';
import { required, SelectArrayInput } from 'react-admin';
import { string } from 'prop-types';
import { JOB_STATUS } from '@/constants/jobStatus';

const JobStatusFilter = ({ source }) => {
  const choiceMap = Object.keys(JOB_STATUS).map((key) => ({
    id: key,
    name: JOB_STATUS[key],
  }));
  return <SelectArrayInput source={source} choices={choiceMap} validate={required()} />;
};

JobStatusFilter.propTypes = {
  source: string.isRequired,
};

export default JobStatusFilter;
