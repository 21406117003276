import React from 'react';

import mx from 'mixpanel-browser';
import { Form, useDataProvider, useNotify, useShowContext } from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useFormState } from 'react-hook-form';

import { Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';
import GoogleMapsScript from '@/shared/GoogleMapsScript';
import objectDiff from '@/utils/objectDiff';

import ServiceGroupLocations from './ServiceGroupLocations';
import QuoteLocations from './QuoteLocations';

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const LocationsForm = () => {
  const { record: quoteRecord, refetch } = useShowContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Locations updated', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateBack();
    notify('Locations updated on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Locations', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred updating locations on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = (data) => {
    const operationsNeeded = [];

    const diff = objectDiff(data, quoteRecord);

    const serviceGroupLocationsChanged = diff?.service_groups
      ?.map((serviceGroup, index) =>
        serviceGroup.locations?.some((location) => location.location_id) || serviceGroup.locations?.length === 0 // When location removed, empty array is in diff
          ? index
          : null,
      )
      .filter((item) => item !== null);

    if (serviceGroupLocationsChanged?.length) {
      serviceGroupLocationsChanged.forEach((indexChanged) => {
        operationsNeeded.push({
          op: 'replace',
          path: `/service_groups/${data.service_groups[indexChanged].id}/locations`,
          value: data.service_groups[indexChanged].locations.map((location) => location.location_id),
        });
      });
    }

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  if (!quoteRecord) return null;

  return (
    <Form record={quoteRecord} onSubmit={onSubmit}>
      <QuoteLocations />
      <ServiceGroupLocations />
      <FormControls />
    </Form>
  );
};

const ModifyLocations = () => (
  <>
    <GoogleMapsScript />
    <Box>
      <LocationsForm />
    </Box>
  </>
);

export default ModifyLocations;
