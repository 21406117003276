import React from 'react';

import { Form, SaveButton, ShowButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const CarrierDetailsContactInfoEdit = () => (
  <Card mt={3} component={Box}>
    <CardHeader title="Contact info" />

    <Divider />

    <Form>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextInput source="phone" label="Phone number" required fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="email" label="Email address" required fullWidth />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <ShowButton label="Cancel" icon={null} color="secondary" />
          <SaveButton label="Update" icon={null} />
        </Box>
      </CardContent>
    </Form>
  </Card>
);

export default CarrierDetailsContactInfoEdit;
