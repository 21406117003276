import React from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

import { Box, Grid, Typography } from '@mui/material';

import formatCurrency from '@/utils/currency/formatCurrency';
import { arrayOf, number, shape, string } from 'prop-types';

dayjs.extend(utc);
dayjs.extend(tz);

const CalendarDay = ({ minCost, quoteData, slotHours }) => {
  const displaySlotAbbr = true;

  return (
    <Box>
      <Box minHeight={24}>
        <Typography>{minCost ? formatCurrency(minCost, 0) : null}</Typography>
      </Box>
      <Grid container justifyContent="center" spacing={1} minHeight={24}>
        {quoteData?.length
          ? slotHours.map((slotHour) => {
              const slotQuote = _.find(
                quoteData,
                (quote) => dayjs(quote.start_datetime).tz(quote.timezone).hour() === slotHour && quote.is_available,
              );
              let slotAbbr = '';
              switch (slotHour) {
                case 8:
                  slotAbbr = '8a';
                  break;
                case 11:
                  slotAbbr = '11a';
                  break;
                case 14:
                  slotAbbr = '2p';
                  break;
                case 17:
                  slotAbbr = '5p';
                  break;
                default:
                  break;
              }
              return (
                <Grid item>
                  <Box
                    px={0.5}
                    py={0.25}
                    backgroundColor={slotQuote ? 'primary.light' : 'accent.light'}
                    {...(displaySlotAbbr ? {} : { width: 8, height: 8 })}
                  >
                    {displaySlotAbbr ? (
                      <Typography variant="body2" color="neutral.darkest">
                        {slotAbbr}
                      </Typography>
                    ) : null}
                  </Box>
                </Grid>
              );
            })
          : null}
      </Grid>
    </Box>
  );
};

CalendarDay.propTypes = {
  minCost: number,
  quoteData: arrayOf(shape({})),
  serviceGroupId: string.isRequired,
  slotHours: arrayOf(number),
};

CalendarDay.defaultProps = {
  minCost: null,
  quoteData: [],
  slotHours: [],
};

export default CalendarDay;
