import React from 'react';

import {
  Edit,
  Form,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_STATUSES } from '@/constants/thirdPartyCrewAccount';

import ReasonsInput from './ReasonsInput';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewDetailsOverviewStatusEdit = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const redirect = useRedirect();

  if (!record) return null;

  return (
    <Edit
      resource={resources.THIRD_PARTY_CREW}
      id={record.id}
      mutationOptions={{
        meta: {
          subResource: 'DETAILS/OVERVIEW/STATUS',
        },
        onSuccess: () => {
          refresh();
          redirect(`/${resources.THIRD_PARTY_CREW}/${record.id}/show`);
        },
      }}
      mutationMode="pessimistic"
      actions={false}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Overview - Edit Status" />

        <Divider />

        <CardContent>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SelectInput
                  source="new_status"
                  choices={THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_STATUSES}
                  validate={[required()]}
                  fullWidth
                  label="Status"
                  defaultValue={record.status}
                />
              </Grid>

              <Grid item xs={3}>
                <ReasonsInput />
              </Grid>

              <Grid item xs={3}>
                <TextInput source="context" validate={[required()]} fullWidth label="Status Context" />
              </Grid>
            </Grid>

            <Divider />
            <CustomToolbar />
          </Form>
        </CardContent>
      </Card>
    </Edit>
  );
};

export default ThirdPartyCrewDetailsOverviewStatusEdit;
