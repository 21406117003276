import _ from 'lodash';

import { QUOTE_NON_PARTNER_TEMPLATES, QUOTE_PARTNER_TEMPLATES } from '@/constants/quoteTemplates';
import inventoryTransform from './inventoryTransform';
import localServiceGroupsTransform from './localServiceGroupsTransform';
import longDistanceServiceGroupsTransform from './longDistanceServiceGroupsTransform';
import podsTransform from './podsTransform';
import zippyTransform from './zippyTransform';
import productsTransform from './productsTransform';

const quoteDataTransform = (data, create) =>
  // Pass through the create hook callback for use in special transforms
  new Promise((resolve, reject) => {
    // React Admin can accept Promise as transform
    // async self invoking function used so we can make synchronous calls as part of the creation process
    // This synchronous operation is currently only utilized in the Zippyshell quote process to handle locations
    (async () => {
      let quoteData = _.cloneDeep(data);

      quoteData = inventoryTransform(quoteData);

      const partnerTemplateMatch = Object.keys(QUOTE_PARTNER_TEMPLATES).find((partner) => partner === quoteData?.partner_id);

      if (Object.values(QUOTE_NON_PARTNER_TEMPLATES).find((template) => template.id === quoteData?.selected_template?.id)) {
        // Non partner templates
        if (quoteData?.selected_template?.id !== QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE.id) {
          // Hourly labor or local move
          quoteData = localServiceGroupsTransform(quoteData);
        }

        if (quoteData?.selected_template?.id === QUOTE_NON_PARTNER_TEMPLATES.LONG_DISTANCE.id) {
          // Long distance
          quoteData = longDistanceServiceGroupsTransform(quoteData);
        }
      } else if (partnerTemplateMatch) {
        // Partner ID matched configured partner templates
        if (quoteData?.partner_id === 'PODS') {
          quoteData = podsTransform(quoteData);
        }
        if (quoteData?.partner_id === 'ZIPPY') {
          // Zippy transform is special in that it makes data call for extra warehouse location need to await
          // This can have an "error" property that gets caught upon return
          quoteData = await zippyTransform(quoteData, create);
        }
      } else {
        // Unknown selected template return empty object to cause quote error
        return reject(new Error('Unknown template'));
      }

      quoteData = productsTransform(quoteData);

      // Cleanup items that create quote endpoint does not use
      delete quoteData.locations;
      delete quoteData.marketsData;
      delete quoteData.selected_template;
      delete quoteData.selected_quote_type;
      delete quoteData.preferred;

      return resolve(quoteData);
    })();
  });

export default quoteDataTransform;
