import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';
import PayoutConfigCreate from '@/shared/accounts/PayoutConfigCreate';
import PayoutConfigEdit from '@/shared/accounts/PayoutConfigEdit';

import CarrierCreate from './Create';
import CarrierSkillsCreate from './Create/Skills';
import CarrierEdit from './Edit';
import CarrierSkillsEdit from './Edit/Skills';
import CarrierList from './List';
import CarrierShow from './Show';

const carriers = () => ({
  name: resources.CARRIERS,
  create: CarrierCreate,
  list: CarrierList,
  show: CarrierShow,
  children: [
    <Route key="carrier-edit" path=":accountId/:tab/:section/edit" element={<CarrierEdit />} />,
    <Route key="carrier-status-edit" path=":accountId/:tab/:section/:status/edit" element={<CarrierEdit />} />,
    <Route key="carrier-skill-create" path=":accountId/create/carrier_account_skill" element={<CarrierSkillsCreate />} />,
    <Route
      key="carrier-skill-edit"
      path=":accountId/account_products/:accountProductId/edit"
      element={<CarrierSkillsEdit />}
    />,
    <Route
      key="carrier-payout-config-edit"
      path=":accountId/payout_config/:payoutConfigId/edit"
      element={<PayoutConfigEdit />}
    />,
    <Route key="carrier-payout-config-create" path=":accountId/payout_config/create" element={<PayoutConfigCreate />} />,
  ],
});

export default carriers;
