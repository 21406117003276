import React from 'react';

import {
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  required,
  SelectInput,
  TextField,
  useRecordContext,
} from 'react-admin';

import { Card, CardContent, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import QualificationLevelField from '@/fields/QualificationLevelField';
import ServiceNameField from '@/fields/ServiceNameField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import ProsSectionHeader from '../Common/SectionHeader';

const JobFilters = [
  <SelectInput
    source="status"
    choices={[
      { id: 'Assigned', name: 'Assigned' },
      { id: 'Unassigned', name: 'Unassigned' },
      { id: 'Completed', name: 'Completed' },
      { id: 'Removed', name: 'Removed' },
      { id: 'Cancelled', name: 'Cancelled' },
    ]}
    validate={[required()]}
    alwaysOn
  />,
];

const JobHistory = () => {
  // get context of the current record being used
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card>
      <ProsSectionHeader title="Job History" contextMenuItems={[]} />
      <Divider />
      <CardContent>
        <List
          disableSyncWithLocation
          resource={resources.ACCOUNTS}
          queryOptions={{ meta: { subResource: 'jobs', resourceId: record.id } }}
          exporter={false}
          actions={false}
          filters={JobFilters}
          filterDefaultValues={{ status: 'Assigned' }}
          empty={<EmptyResourceList text="No Job history on Pro account" />}
          storeKey={`${resources.ACCOUNTS}.job_history.listParams`}
          sort={{ field: 'start_datetime', order: 'DESC' }}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField reference={resources.ORDERS} source="order_id" label="Order" link="show" sortable={false}>
              <TextField source="confirmation_id" />
            </ReferenceField>
            <ServiceNameField source="product_id" label="Job" />
            <QualificationLevelField source="qualification_level" label="Qualification" />
            <DateField source="start_datetime" label="Date" />
            <ReferenceField source="partner_id" label="Partner" reference={resources.ACCOUNTS} sortable={false}>
              <TextField source="name" />
            </ReferenceField>
            <FunctionField label="LD" render={(slot) => (slot.long_distance ? 'LD' : 'Local')} />
            <CurrencyField source="amount" label="Total" />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

export default JobHistory;
