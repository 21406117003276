import React from 'react';

import { string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { CurrencyExchange, Warning } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

const WorkerTransactionTypeField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !record?.[source]) return null;

  const transactionType = record?.[source];
  const isNonStripeTransaction = Boolean(record?.non_stripe);

  const icon = () => {
    switch (transactionType) {
      case 'Reversal':
        return (
          <Tooltip title="Reversal">
            <CurrencyExchange />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  if (isNonStripeTransaction) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Tooltip title="This is a non Stripe transaction">
          <Warning color="warning" />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {icon()}
    </Box>
  );
};

WorkerTransactionTypeField.propTypes = {
  source: string.isRequired,
};

export default WorkerTransactionTypeField;
