import * as resources from '@/api/resources';

import ProductCreate from './Create';
import ProductEdit from './Edit';
import ProductList from './List';
import ProductShow from './Show';

const products = () => ({
  name: resources.PRODUCTS,
  create: ProductCreate,
  edit: ProductEdit,
  list: ProductList,
  show: ProductShow,
});

export default products;
