import React, { useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useGetList, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CssBaseline, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { PREFERENCES_VALUES } from '@/constants/preferences';
import AvailabilityCalendar from '@/shared/AvailabilityCalendar';
import transformAvailabilities from '@/utils/availability/transformAvailabilities';

import CarriersSectionHeader from '../Common/SectionHeader';

dayjs.extend(utc);
dayjs.extend(timezone);

const Availability = () => {
  // get context of the current record being used
  const record = useRecordContext();
  if (!record) return null;

  const [refDate, setRefDate] = useState();

  const calendarStartDateTime = dayjs(refDate).startOf('month').add(1, 'hour').utc().format();
  const calendarEndDateTime = dayjs(refDate).endOf('month').utc().format();
  const timePreference = PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL;

  const { data, isLoading, refetch } = useGetList(
    resources.CARRIERS,
    {
      meta: { resourceId: record.id, subResource: 'availability' },
      filter: { start_date_time: calendarStartDateTime, end_date_time: calendarEndDateTime },
    },
    { enabled: Boolean(record.id) },
  );

  // Note: This is where the calendar's timezone handling takes place
  const dataTimezone = data?.length ? data[0].timezone : 'America/Detroit';
  const availabilities = transformAvailabilities(data, timePreference, dataTimezone);

  return (
    <Card>
      {/* CssBaseline needed to be loaded to override calendar styling */}
      <CssBaseline />
      <CarriersSectionHeader title="Availability" timezoneDisplay={dataTimezone} contextMenuItems={[]} />
      <Divider />
      <CardContent>
        <Box className="account-availability-calendar">
          <AvailabilityCalendar
            timezone={data?.length ? data[0].timezone : null}
            availabilities={availabilities}
            setRefDate={setRefDate}
            loading={isLoading}
            loadData={refetch}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Availability;
