import React from 'react';

import { string } from 'prop-types';
import { useGetOne, useNotify, useRecordContext } from 'react-admin';

import { Box, Button } from '@mui/material';

import * as resources from '@/api/resources';

const ContractUrlButton = ({ source }) => {
  const record = useRecordContext();
  const notify = useNotify();

  if (!record[source]) return null;

  const onError = ({ message, status }) => {
    notify(`Failed to get contract URL - ${status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    window.open(data.url, '_blank');
  };

  const { refetch } = useGetOne(
    resources.IRONCLAD_CONTRACTS,
    {
      id: record.order_id,
      meta: { subResource: `contract_request/${record[source]}/url` },
    },
    {
      enabled: false,
      onError,
      onSuccess,
    },
  );

  const handleButtonClick = () => {
    refetch();
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Button onClick={handleButtonClick} size="small" variant="contained">
        View PDF File
      </Button>
    </Box>
  );
};

ContractUrlButton.propTypes = {
  source: string.isRequired,
};

export default ContractUrlButton;
