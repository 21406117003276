import _ from 'lodash';
import onlyUnique from '../onlyUnique';

const serviceGroupHasService = (serviceGroup, serviceId) =>
  _.findIndex(serviceGroup?.services, (service) => service.service_id === serviceId) >= 0;

const serviceGroupIsLongDistance = (serviceGroup) =>
  serviceGroup.locations.map(({ location }) => location.market_id).filter(onlyUnique).length > 1;

// Temporary disable until next util written
// eslint-disable-next-line import/prefer-default-export
export { serviceGroupHasService, serviceGroupIsLongDistance };
