import React from 'react';

import { Datagrid, FunctionField, ReferenceInput, SelectInput, TextField, TextInput } from 'react-admin';

import { Box, Card, Chip, Divider, Typography } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import { PRO_ACCOUNT_DROPDOWN_STATUSES } from '@/constants/prosAccount';
import MarketNameField from '@/fields/MarketNameField';
import EmptyResourceList from '@/shared/EmptyResourceList';

const AccountFilter = [
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
  <TextInput source="email" alwaysOn resettable autoComplete="off" />,
  <TextInput source="phone" alwaysOn resettable autoComplete="off" />,
  <SelectInput source="account_status" label="Status" choices={PRO_ACCOUNT_DROPDOWN_STATUSES} alwaysOn />,
  <ReferenceInput source="market" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <TextInput source="id" label="Id" autoComplete="off" />,
];

const ProsAccountList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Pro Accounts</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.ACCOUNTS}
      filters={AccountFilter}
      filterDefaultValues={{ account_status: ['Active'] }}
      filter={{
        account_type: ['Bellhop'],
      }}
      exporter={false}
      empty={<EmptyResourceList text="No Pro accounts" />}
      hasCreate={false}
      storeKey={`${resources.ACCOUNTS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="status" sortable={false} />
        <MarketNameField source="primary_market" sortable={false} />
        <FunctionField
          render={(record) => (
            <>
              {record.is_admiral ? <Chip label="Admiral" /> : null}
              {record.is_hhq ? <Chip label="HHG" /> : null}
            </>
          )}
        />
      </Datagrid>
    </List>
  </Card>
);

export default ProsAccountList;
