import React from 'react';
import { Route } from 'react-router';

import * as resources from '@/api/resources';
import PayoutConfigCreate from '@/shared/accounts/PayoutConfigCreate';
import PayoutConfigEdit from '@/shared/accounts/PayoutConfigEdit';

import ProsSkillsCreate from './Create/Skills';
import ProsAccountEdit from './Edit';
import ProsSkillsEdit from './Edit/Skills';
import ProsAccountList from './List';
import ProsAccountShow from './Show';

const accounts = () => ({
  name: resources.ACCOUNTS,
  list: ProsAccountList,
  show: ProsAccountShow,
  children: [
    <Route key="pro-edit" path=":accountId/:tab/:section/edit" element={<ProsAccountEdit />} />,
    <Route key="pro-status-edit" path=":accountId/:tab/:section/:status/edit" element={<ProsAccountEdit />} />,
    <Route key="pro-skill-create" path=":accountId/create/pro_account_skill" element={<ProsSkillsCreate />} />,
    <Route key="pro-skill-edit" path=":accountId/account_products/:accountProductId/edit" element={<ProsSkillsEdit />} />,
    <Route
      key="pro-payout-config-edit"
      path=":accountId/payout_config/:payoutConfigId/edit"
      element={<PayoutConfigEdit />}
    />,
    <Route key="pro-payout-config-create" path=":accountId/payout_config/create" element={<PayoutConfigCreate />} />,
  ],
});

export default accounts;
