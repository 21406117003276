import { ADJUSTMENT_NAMES } from '@/constants/adjustmentName';
import getAdjustmentName from '@/utils/adjustmentName/getAdjustmentName';
import { Grid, Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import { useRecordContext } from 'react-admin';

/*
 * ServiceNameField
 *
 * Given a product code will display service name
 *
 */
const AdjustmentNameField = ({ source }) => {
  const record = useRecordContext();

  if (!record[source]) return null;

  const adjustmentName = getAdjustmentName(record[source]);

  return adjustmentName === ADJUSTMENT_NAMES.APPEASEMENT ? (
    <Grid direction="column">
      <Grid item>{record.appeasement_reason}</Grid>
      <Grid item>
        <Typography variant="body2">{record.appeasement_context}</Typography>
      </Grid>
    </Grid>
  ) : (
    adjustmentName
  );
};

AdjustmentNameField.propTypes = {
  source: string.isRequired,
};

export default AdjustmentNameField;
