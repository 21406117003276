import dayjs from 'dayjs';
import React, { useContext } from 'react';
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  usePermissions,
  useReference,
  useShowContext,
} from 'react-admin';

import { Add, Edit, Help, Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import JobStatusFilter from '@/components/filters/JobStatusFilter';
import { JOB_SLOT_STATUS } from '@/constants/jobSlotStatus';
import CountdownTimerField from '@/fields/CountdownTimerField';
import OrderFulfillmentStatusField from '@/fields/OrderFulfillmentStatusField';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import ProductNameField from '@/fields/ProductNameField';
import ServiceNameField from '@/fields/ServiceNameField';
import SlotAvatarField from '@/fields/SlotAvatarField';
import { OrderActionContext } from '@/providers/OrderAction';
import EmptyResourceList from '@/shared/EmptyResourceList';
import ListViewChangedTracker from '@/shared/events/ListViewChangedTracker';

import TagsField from '@/fields/TagsField';
import OrderCustomer from '../../OrderCustomer';
import BookDraftOrderButton from './BookDraftOrderButton';
import CancelAction from './CancelAction';
import CompleteAction from './CompleteAction';
import ConvertToBhnButton from './ConvertToBhnButton';
import CustomerDashboardButton from './CustomerDashboardButton';
import JobExpandPanel from './JobExpandPanel';
import OperatorNotes from './OperatorNotes';
import RescheduleAction from './RescheduleAction';

const JobsDatagrid = () => (
  <Datagrid rowClick="show" bulkActionButtons={false} expand={<JobExpandPanel />}>
    <ServiceNameField label="Service" source="product_id" />
    <TextField source="status" />
    <PrefDateTimeField
      label="Date/time"
      source="start_date_time"
      timezoneSource="start_timezone"
      dayjsFormat="MMM D, YYYY @ h:mm A"
    />
    <FunctionField
      label="Duration"
      render={({ start_date_time: startDateTime, end_date_time: endDateTime }) => {
        const duration = dayjs(endDateTime).diff(dayjs(startDateTime), 'hour');
        return `${duration} hours`;
      }}
    />
    <TextField source="workers" />
    <ReferenceManyField
      label="Crew"
      reference={resources.JOB_SLOTS}
      target="job_id"
      filter={{
        status: [JOB_SLOT_STATUS.ASSIGNED, JOB_SLOT_STATUS.CANCELLED, JOB_SLOT_STATUS.UNASSIGNED],
      }}
      sort={{
        field: 'minimum_qualification_level',
        order: 'DESC',
      }}
      perPage={100}
      sortable={false}
    >
      <SingleFieldList linkType="show" sx={{ alignItems: 'center', py: 1 }}>
        <Box mr={0.5}>
          <SlotAvatarField enableTooltip />
        </Box>
      </SingleFieldList>
    </ReferenceManyField>
  </Datagrid>
);

const OrderContentDetails = () => {
  const {
    record: {
      id: orderId,
      account_id: customerId,
      long_distance: isLongDistance,
      guaranteed,
      partner_id: partner,
      affiliate_id: affiliate,
      reschedule_type: rescheduleType,
      reschedule_reason: rescheduleReason,
      cancel_type: cancelType,
      cancel_reason: cancelReason,
      transaction_failure_review: transactionFailureReview,
      tags,
    } = {},
  } = useShowContext();

  const { setAction } = useContext(OrderActionContext);
  const { permissions } = usePermissions();

  const { referenceRecord: customerData } = useReference({
    reference: resources.CUSTOMERS,
    id: customerId,
    options: { enabled: Boolean(customerId) },
  });

  const jobFilters = [<JobStatusFilter source="status" alwaysOn />];

  const canAddOperatorNote = permissions?.tools?.hqadmin?.order_operator_notes?.create;

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Jobs" />
              <Divider />
              <List
                storeKey={`${resources.ORDERS}.${resources.JOBS}.listParams`}
                resource={resources.JOBS}
                filter={{ order_id: orderId }}
                filters={jobFilters}
                filterDefaultValues={{
                  status: ['CANCELLED', 'COMPLETE', 'OPEN'],
                }}
                sort={{
                  field: 'start_date_time',
                  order: 'ASC',
                }}
                actions={false}
                disableSyncWithLocation
              >
                <>
                  <ListViewChangedTracker />
                  <JobsDatagrid />
                </>
              </List>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card component={Box} height="100%">
              <CardHeader title="Products" />
              <Divider />
              <List
                storeKey={`${resources.ORDERS}.products.listParams`}
                resource={resources.ORDERS}
                queryOptions={{
                  meta: {
                    subResource: 'products',
                    resourceId: orderId,
                  },
                }}
                actions={false}
                disableSyncWithLocation
                empty={<EmptyResourceList text="No Products on Order" />}
              >
                <>
                  <ListViewChangedTracker />
                  <Datagrid bulkActionButtons={false}>
                    <ProductNameField source="product_id" />
                    <TextField source="status" />
                  </Datagrid>
                </>
              </List>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card component={Box} height="100%">
              <CardHeader title="Marketplace" />
              <Divider />
              <Grid container spacing={2} textAlign="center">
                <Grid item xs={6}>
                  <Labeled label="Supply Type">
                    <TextField source="marketplace_supply_type" />
                  </Labeled>
                </Grid>
                <Grid item xs={6}>
                  <Labeled label="Expiration Time">
                    <CountdownTimerField
                      source="marketplace_match_expiry"
                      dayjsFormat="D[d] H[h] m[m] s[s]"
                      timezoneSource="start_time_zone"
                    />
                  </Labeled>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box px={3} py={2} display="flex" justifyContent="space-between">
                <Box display="flex">
                  <Typography variant="h5" mr={1}>
                    Operator Notes
                  </Typography>
                  <Tooltip title="These Operator Notes are internal to Bellhop Operators - they are not visible to any external parties such as customers, Pros, BPPs, and Carriers. To leave an external-facing note for the customer and crew, go to Inventory Notes.">
                    <Icon>
                      <Help />
                    </Icon>
                  </Tooltip>
                </Box>
                {canAddOperatorNote ? (
                  <Button variant="outlined" startIcon={<Add />} onClick={() => setAction('add_operator_note')}>
                    Add Note
                  </Button>
                ) : null}
              </Box>
              <Divider />
              <OperatorNotes />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Info</Typography>
            <IconButton onClick={() => setAction('update_order')} size="small">
              <Edit color="primary" />
            </IconButton>
          </Box>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={1} flexWrap="wrap">
                <Chip label={isLongDistance ? 'LD' : 'Local'} />
                {partner ? (
                  <ReferenceField source="partner_id" reference={resources.ACCOUNTS}>
                    <Tooltip title="Partner" placement="top">
                      <span>
                        <ChipField source="name" />
                      </span>
                    </Tooltip>
                  </ReferenceField>
                ) : null}
                {affiliate ? (
                  <ReferenceField source="affiliate_id" reference={resources.ACCOUNTS}>
                    <Tooltip title="Affiliate" placement="top">
                      <span>
                        <ChipField source="name" />
                      </span>
                    </Tooltip>
                  </ReferenceField>
                ) : null}
                <Chip label={guaranteed ? 'Guaranteed' : 'Non Guaranteed'} />
                {transactionFailureReview ? <Chip color="error" label="Txn Failure" icon={<Warning />} /> : null}
              </Box>
              <Box pl={2}>
                <OrderFulfillmentStatusField />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Labeled label="Start Date">
                <DateField source="start_date_time" />
              </Labeled>
              <Labeled label="End Date">
                <DateField source="end_date_time" />
              </Labeled>
              <Labeled label="Created Date">
                <DateField source="created_at" />
              </Labeled>
            </Box>
            {rescheduleType ? (
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Labeled label="Reschedule Type">
                  <TextField source="reschedule_type" />
                </Labeled>
                {rescheduleReason && (
                  <Labeled label="Reschedule Reason">
                    <TextField source="reschedule_reason" />
                  </Labeled>
                )}
              </Box>
            ) : null}
            {cancelReason && cancelType ? (
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Labeled label="Cancel Type">
                  <TextField source="cancel_type" />
                </Labeled>
                <Labeled label="Cancel Reason">
                  <TextField source="cancel_reason" />
                </Labeled>
              </Box>
            ) : null}
            {tags?.length ? (
              <Box mt={1}>
                <TagsField source="tags" />
              </Box>
            ) : null}
            <CompleteAction />
            <CancelAction />
            <RescheduleAction />
            <ConvertToBhnButton />
            <BookDraftOrderButton />
          </CardContent>
        </Card>
        <Card component={Box} mt={2}>
          <CardHeader title="Customer" />
          <Divider />
          <CardContent>
            <RecordContextProvider value={customerData}>
              <OrderCustomer />
            </RecordContextProvider>
          </CardContent>
          <Divider />
          <Box p={1} textAlign="center">
            <CustomerDashboardButton />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderContentDetails;
