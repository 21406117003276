import * as resources from '@/api/resources';

import JobSlotShow from './Show';

const jobSlots = () => ({
  name: resources.JOB_SLOTS,
  show: JobSlotShow,
});

export default jobSlots;
