import React from 'react';

import { useParams } from 'react-router';

import { CUSTOMER_CHARGES_SECTION } from '@/constants/customerAccount';

import CustomerChargesPaymentMethodEdit from './PaymentMethod';

const CustomerChargesEdit = () => {
  const { section: currentSection } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case CUSTOMER_CHARGES_SECTION.PAYMENT_METHOD:
      EditComponent = <CustomerChargesPaymentMethodEdit />;
      break;
    default:
      break;
  }

  return EditComponent;
};

export default CustomerChargesEdit;
