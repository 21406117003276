import React, { useContext } from 'react';

import dayjs from 'dayjs';
import { usePermissions } from 'react-admin';

import { Box, Card, Grid, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES } from '@/constants/pricingLayers';
import { DateLayerSummaryContext, DateLayerSummaryProvider } from '@/providers/Pricing/DateLayerSummary';
import LinkButton from '@/shared/LinkButton';

import LayerGroupDialog from './LayerGroupDialog';
import PricingOptionsForm from './PricingOptionsForm';
import Summary from './Summary';

const SummaryLayout = () => {
  const { dataEnabled, filters } = useContext(DateLayerSummaryContext);

  if (!dataEnabled) return null;

  return (
    <>
      <Box mx={2} mt={2}>
        <Typography variant="h4">As of {dayjs(filters.effective_date_lte).format('M/D/YYYY')}</Typography>
      </Box>
      <Grid container spacing={{ xs: 0, lg: 2 }}>
        <Grid item xs={12} lg={6}>
          <Summary dateLayerType={PRICING_LAYER_TYPES.DAY_OF_WEEK} />
          <Summary dateLayerType={PRICING_LAYER_TYPES.LEAD_TIME} />
          <Summary dateLayerType={PRICING_LAYER_TYPES.MONTH_OF_YEAR} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Summary dateLayerType={PRICING_LAYER_TYPES.DAY_OF_MONTH} />
        </Grid>
      </Grid>
    </>
  );
};

const PricingLayersDateLayerSummary = () => {
  const { permissions } = usePermissions();

  return (
    <>
      <Card mt={3} component={Box}>
        <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Date Layer Summary</Typography>
          <Box display="flex" gap={2}>
            {permissions.tools?.hqadmin?.pricing_layers?.create ? (
              <LinkButton label="Create Pricing Layers" to={`/${resources.PRICING_LAYERS}/date_layer_create_batch`} />
            ) : null}
            <Tooltip title="Use for debugging / troubleshooting">
              <span>
                <LinkButton label="Raw Data View" to={`/${resources.PRICING_LAYERS}`} />
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Card>
      <DateLayerSummaryProvider>
        <LayerGroupDialog />
        <PricingOptionsForm />
        <SummaryLayout />
      </DateLayerSummaryProvider>
    </>
  );
};

export default PricingLayersDateLayerSummary;
