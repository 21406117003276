import { ordersAllFilters, ordersAllFilterDefaultValues } from './all';
import { ordersFulfillmentColumns, ordersFulfillmentFilters, ordersFulfillmentFilterDefaultValues } from './fulfillment';
import { ordersManagementColumns, ordersManagementFilterDefaultValues, ordersManagementFilters } from './management';

const views = {
  all: {
    filters: ordersAllFilters,
    filterDefaultValues: ordersAllFilterDefaultValues,
  },
  fulfillment: {
    columns: ordersFulfillmentColumns,
    filters: ordersFulfillmentFilters,
    filterDefaultValues: ordersFulfillmentFilterDefaultValues,
  },
  management: {
    columns: ordersManagementColumns,
    filters: ordersManagementFilters,
    filterDefaultValues: ordersManagementFilterDefaultValues,
  },
};

export default views;
