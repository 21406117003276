import React, { useContext } from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { bool, string } from 'prop-types';
import { useCreate, useDataProvider, useGetOne, useNotify, useShowContext } from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { Button } from '@mui/material';

import * as resources from '@/api/resources';
import { QuoteContext } from '@/providers/Quote';

const AddRemoveTruckAction = ({ add, serviceGroupId }) => {
  const { record: quoteRecord, refetch } = useShowContext();
  const { forceInventoryUpdate } = useContext(QuoteContext);
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();
  const [create] = useCreate();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const serviceGroup = _.find(quoteRecord?.service_groups, (group) => group.id === serviceGroupId);
  const serviceGroupLocationIds = serviceGroup?.locations?.map(({ location_id: id }) => id);

  const { data: { travel_time: travelTime } = {} } = useGetOne(
    `${resources.UTILITY}/route`,
    {
      meta: { filter: { ids: serviceGroupLocationIds } },
    },
    { enabled: Boolean(serviceGroupLocationIds?.length > 1), retry: false },
  );

  const onSuccess = () => {
    const addRemoveString = add ? 'added to' : 'removed from';
    mx.track(`Order Management - Quote - Truck ${addRemoveString} Quote`, {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify(`Truck ${addRemoveString} quote`, { type: 'success' });
  };

  const onError = (error) => {
    const addRemoveString = add ? 'adding truck to' : 'removing truck from';
    mx.track(`Order Management - Quote - Error ${addRemoveString} Quote`, {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred ${addRemoveString} quote - ${error}`, { type: 'error' });
  };

  const modifyServiceGroup = () => {
    const allServices = serviceGroup.services?.reduce(
      (prev, service) => ({
        ...prev,
        [service.service_id]: service.quoted_workers,
      }),
      {},
    );

    const nonTransitServices = serviceGroup.services
      ?.filter(({ service_id: serviceId }) => serviceId !== 'TRANSIT' && serviceId !== 'LDTRANSIT')
      .reduce(
        (prev, service) => ({
          ...prev,
          [service.service_id]: service.quoted_workers,
        }),
        {},
      );

    const serviceDetails = {
      duration: serviceGroup.duration,
      service_workers: {
        ...(add ? { ...allServices, TRANSIT: 'estimate' } : nonTransitServices), // `estimate` is temporarily value, replaced with estimated trucks below
      },
      inventory: quoteRecord?.inventory,
    };

    const operationsNeeded = [
      {
        op: 'replace',
        path: `/service_groups/${serviceGroupId}/service_details`,
        value: serviceDetails,
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    if (add) {
      create(
        `${resources.UTILITY}/estimate/service`,
        {
          data: {
            service_id: 'TRANSIT',
            property_type: serviceGroup?.locations?.[0]?.location?.property_type ?? 'SINGLE_FAMILY_HOME',
            travel_time_in_minutes: travelTime,
            ...quoteRecord.inventory,
          },
        },
        {
          onSuccess: (data) => {
            params.data[0].value.service_workers = {
              ...params.data[0].value.service_workers,
              TRANSIT: data.crew_size,
            };
            operationUpdate([resources.QUOTES, params], { onSuccess, onError });
          },
          onError,
        },
      );
    } else {
      operationUpdate([resources.QUOTES, params], { onSuccess, onError });
    }
  };

  return (
    <Button
      key={`${add ? 'add' : 'remove'}-truck`}
      disabled={forceInventoryUpdate}
      onClick={modifyServiceGroup}
      variant="outlined"
    >
      {`${add ? 'Add' : 'Remove'} Truck`}
    </Button>
  );
};

AddRemoveTruckAction.propTypes = {
  add: bool,
  serviceGroupId: string.isRequired,
};

AddRemoveTruckAction.defaultProps = {
  add: false,
};

export default AddRemoveTruckAction;
