import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, Button, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { WARNING_ALERT_ORDER_TEXT } from '@/constants/order';
import { OrderActionContext } from '@/providers/OrderAction';
import OrderAlertField from '@/resources/orders/Show/OrderContent/Common/OrderAlertField';

const CompleteForm = () => {
  const { setAction } = useContext(OrderActionContext);

  const { record } = useShowContext();

  return (
    <>
      <Box p={2}>
        <Typography>Any assigned slots not clocked in will be marked as No Show</Typography>
        <Typography pt={1}>
          Any assigned slots clocked in that have not been clocked out will be clocked out at the current time
        </Typography>
      </Box>
      <Divider />
      <OrderAlertField
        variant="filled"
        severity="warning"
        text={WARNING_ALERT_ORDER_TEXT.PAST_8_DAYS_RECONCILE}
        orderStartDate={record?.start_date_time}
      />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const CompleteJob = () => {
  const { setAction, metadata } = useContext(OrderActionContext);
  const {
    record: { confirmation_id: confirmationId },
  } = useShowContext();
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Job Complete failed - ${error.status} - ${error.message}`, {
        type: 'error',
      });
    } else if (data) {
      mx.track(`Order Management - Job Completed`, {
        resource: resources.JOBS,
        resourceId: metadata?.job_id,
        confirmationId,
      });

      notify('Job Completed', {
        type: 'success',
      });
      refresh();
      setAction(null);
    }
  };

  const handleSubmit = () => {
    update(
      resources.JOBS,
      {
        id: metadata?.job_id,
        data: {},
        meta: {
          method: 'POST',
          subResource: 'complete',
        },
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} maxWidth={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Complete Job
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <CompleteForm />
        </Form>
      </Box>
    </Box>
  );
};

export default CompleteJob;
