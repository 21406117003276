import React from 'react';
import { Route } from 'react-router';

import * as resources from '@/api/resources';

import JobSlotTransactionCreate from './Create';
import JobSlotTransactionEdit from './Edit';

const jobSlotTransactions = () => ({
  name: resources.JOB_SLOT_TRANSACTIONS,
  create: JobSlotTransactionCreate,
  children: [
    <Route key="job-slot-transaction-edit" path=":jobSlotId/:transactionId/edit" element={<JobSlotTransactionEdit />} />,
  ],
});

export default jobSlotTransactions;
