import React, { useState, useEffect } from 'react';

import { Box, Card, CardContent, Divider, Grid, Typography, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';

import { WORKER_TYPE } from '@/constants/supplyinventory';

import SupplyInventorySectionHeader from './Common/SectionHeader';

const SupplyInventoryCreate = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    switch (selectedValue) {
      case '1':
        navigate(`lead`);
        break;
      case '2':
        navigate(`standard`);
        break;
      case '3':
        navigate(`transit`);
        break;
      default:
        break;
    }
  }, [selectedValue]);

  return (
    <Card mt={3} component={Box}>
      <SupplyInventorySectionHeader title="Create Supply Inventory" />
      <Divider />
      <CardContent>
        <Grid item xs={12} mt={1}>
          <Typography variant="h5">Please Select a Worker Type.</Typography>
        </Grid>
        <Grid mt={1} container display="flex">
          <Select value={0} onChange={handleChange} displayEmpty variant="filled">
            <MenuItem value="0">Choose Worker Type</MenuItem>
            {WORKER_TYPE.map((workertype) => (
              <MenuItem key={workertype.id} value={workertype.id}>
                {workertype.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SupplyInventoryCreate;
