import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import {
  CARRIER_ACCOUNT_DETAIL_SECTION,
  CARRIER_ACCOUNT_OVERVIEW_CARD,
  CARRIER_ACCOUNT_TAB,
} from '@/constants/carrierAccount';

import CarrierSectionHeader from '../../../Common/SectionHeader';

const CarriersDetailsSectionHeader = ({ tab, section, title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const contextMenuItemOverview = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
          {
            key: 'edit-account-status',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/${CARRIER_ACCOUNT_OVERVIEW_CARD.STATUS}/edit`);
            },
            component: 'Edit Status',
          },
        ]
      : []),
    ...(permissions.tools?.hqadmin?.carrier_accounts?.deactivate
      ? [
          {
            key: 'post-deactivate-account',
            onClick: () => {
              navigate(
                `/${resources.CARRIERS}/${record.id}/${tab}/${section}/${CARRIER_ACCOUNT_OVERVIEW_CARD.DEACTIVATE}/edit`,
              );
            },
            component: 'Deactivate Carrier',
          },
        ]
      : []),
  ];

  const contextMenuItemContactInfo = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
          // Temp remove until location returned on carriers
          // {
          //   key: 'edit-location',
          //   onClick: () => {
          //     navigate(
          //       `/${resources.CARRIERS}/${record.id}/${tab}/${section}/${CARRIER_ACCOUNT_CONTACTINFO_CARD.EDITLOCATION}/edit`,
          //     );
          //   },
          //   component: 'Edit Location',
          // },
        ]
      : []),
  ];

  const contextMenuItemContract = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemTruck = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemAuthority = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemPayment = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  const contextMenuItemDefault = [];

  switch (section) {
    case CARRIER_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      contextMenuItems = contextMenuItemOverview;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.CONTACT_INFO:
      contextMenuItems = contextMenuItemContactInfo;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.CONTRACT:
      contextMenuItems = contextMenuItemContract;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.TRUCK:
      contextMenuItems = contextMenuItemTruck;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.AUTHORITY:
      contextMenuItems = contextMenuItemAuthority;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.PAYMENT:
      contextMenuItems = contextMenuItemPayment;
      break;
    default:
      contextMenuItems = contextMenuItemDefault;
  }

  return <CarrierSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

CarriersDetailsSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

CarriersDetailsSectionHeader.defaultProps = {
  tab: CARRIER_ACCOUNT_TAB.DETAILS,
};
export default CarriersDetailsSectionHeader;
