import React, { useContext } from 'react';

import dayjs from 'dayjs';
import { usePermissions } from 'react-admin';

import { Box, Card, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { ServiceLayerSummaryContext, ServiceLayerSummaryProvider } from '@/providers/Pricing/ServiceLayerSummary';
import LinkButton from '@/shared/LinkButton';

import LayerGroupDialog from './LayerGroupDialog';
import PricingOptionsForm from './PricingOptionsForm';
import Summary from './Summary';

const SummaryLayout = () => {
  const { dataEnabled, filters } = useContext(ServiceLayerSummaryContext);

  if (!dataEnabled) return null;

  return (
    <>
      <Box mx={2} mt={2}>
        <Typography variant="h4">As of {dayjs(filters.effective_date_lte).format('M/D/YYYY')}</Typography>
      </Box>
      <Summary />
    </>
  );
};

const PricingLayersServiceLayerSummary = () => {
  const { permissions } = usePermissions();

  return (
    <>
      <Card mt={3} component={Box}>
        <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Service Layer Summary</Typography>
          <Box display="flex" gap={2}>
            {permissions.tools?.hqadmin?.pricing_layers?.create ? (
              <LinkButton label="Create Pricing Layers" to={`/${resources.PRICING_LAYERS}/service_layer_create_batch`} />
            ) : null}
            <Tooltip title="Use for debugging / troubleshooting">
              <span>
                <LinkButton label="Raw Data View" to={`/${resources.PRICING_LAYERS}`} />
              </span>
            </Tooltip>
          </Box>
        </Box>
      </Card>
      <ServiceLayerSummaryProvider>
        <LayerGroupDialog />
        <PricingOptionsForm />
        <SummaryLayout />
      </ServiceLayerSummaryProvider>
    </>
  );
};

export default PricingLayersServiceLayerSummary;
