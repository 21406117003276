import React, { useEffect, useState } from 'react';

import { func, oneOfType, shape, string } from 'prop-types';
import { useGetList } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';

const PromoCodeAutocomplete = ({ field: { value, onChange } }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState('Start typing to find a promo code');

  const { data: promoCodeData, isLoading } = useGetList(
    'promo_codes',
    {
      filter: {
        search: inputValue,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
    },
    {
      enabled: inputValue !== '',
    },
  );

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      setNoOptionsText('Start typing to find a promo code');
    }
  }, [inputValue]);

  useEffect(() => {
    setOptions(promoCodeData || []);
    if (promoCodeData?.length === 0) {
      setNoOptionsText('No matching promo codes found');
    }
  }, [promoCodeData]);

  return (
    <Autocomplete
      id="promo-code-autocomplete"
      loading={isLoading}
      options={options}
      noOptionsText={noOptionsText}
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue);
      }}
      isOptionEqualToValue={(option, testValue) => option?.id === testValue?.id || testValue === ''}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(item) => item?.id ?? ''}
      renderInput={(params) => <TextField {...params} label="Promo Code" fullWidth />}
      renderOption={(props, option) => (
        <Box {...props}>
          <Box display="flex" justifyContent="space-between" mx={2} my={1} width="100%">
            <Typography variant="body2">{option.id}</Typography>
            {option.promo_id_type === 'Percent' ? (
              <Typography variant="body2">{`${option.promo_id_percent}%`}</Typography>
            ) : null}
            {option.promo_id_type === 'Amount' ? (
              <Typography variant="body2">{`$${(Number(option.promo_id_amount) / 100).toFixed(2)}`}</Typography>
            ) : null}
          </Box>
        </Box>
      )}
    />
  );
};

PromoCodeAutocomplete.propTypes = {
  field: shape({
    value: oneOfType([shape({}), string]),
    onChange: func.isRequired,
  }),
};

PromoCodeAutocomplete.defaultProps = {
  field: {
    value: null,
  },
};

const PromoCodeForm = () => {
  const { control } = useFormContext();

  return (
    <Box>
      <Controller
        defaultValue=""
        name="promo_code_id"
        control={control}
        render={({ field }) => <PromoCodeAutocomplete field={field} />}
      />

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button type="submit" variant="outlined">
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default PromoCodeForm;
