import { MARKET_BELLHOP_NATION, MARKET_STATUS } from '@/constants/market';
import { QUOTE_PARTNER_TEMPLATES, QUOTE_TEMPLATE_UNAVAILABLE_REASONS } from '@/constants/quoteTemplates';

const allUnavailable = [
  {
    ...QUOTE_PARTNER_TEMPLATES.ZIPPY.LOADING,
    available: false,
  },
  {
    ...QUOTE_PARTNER_TEMPLATES.ZIPPY.UNLOADING,
    available: false,
  },
];

const zippyTemplates = ({ locations, marketsData, uniqueMarkets }) => {
  if (!locations || locations?.length === 0) {
    // No locations, all templates unavailable
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS],
    }));
  }

  if (uniqueMarkets?.length > 1) {
    // Multiple markets, all templates unavailable
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_MARKET],
    }));
  }

  if (marketsData?.every((market) => market.status === MARKET_STATUS.INACTIVE.id)) {
    // All markets inactive, all templates unavailable
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.INACTIVE_MARKET],
    }));
  }

  if (uniqueMarkets.includes(MARKET_BELLHOP_NATION)) {
    // Only one market and it is bellhop nation, all templates unavailable
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.BELLHOP_NATION_MARKET],
    }));
  }

  if (locations?.length !== 2) {
    // Zippy moves require exactly 2 addresses - warehouse and customer address
    return allUnavailable.map((template) => ({
      ...template,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.ZIPPY_LOCATIONS],
    }));
  }

  return [
    {
      ...QUOTE_PARTNER_TEMPLATES.ZIPPY.LOADING,
      available: true,
    },
    {
      ...QUOTE_PARTNER_TEMPLATES.ZIPPY.UNLOADING,
      available: true,
    },
  ];
};

export default zippyTemplates;
