import React from 'react';

import {
  Form,
  SaveButton,
  ShowButton,
  TextInput,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between" mt={1}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} />
  </Box>
);

const ThirdPartyWorkerDetailsOverviewEdit = () => {
  const record = useRecordContext();

  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - updating worker failed - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    notify(`Worker: ${data.name} has been updated`, {
      type: 'success',
    });
    refresh();
    redirect(`/${resources.THIRD_PARTY_WORKER}/${record.id}/show`);
  };

  const onSubmit = ({ primary_email: email, name, phone }) => {
    const crewCode = record.company_id;
    const data = {
      name,
      phone,
      email: email?.toLowerCase(),
      crewCode,
    };

    update(
      resources.THIRD_PARTY_WORKER,
      {
        id: record.id,
        data,
      },
      { mutationMode: 'pessimistic', onSuccess, onError },
    );
  };

  if (!record) return null;
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Overview" />

      <Divider />

      <CardContent>
        <Form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput source="name" required fullWidth />
            </Grid>

            <Grid item xs={3}>
              <TextInput source="phone" required fullWidth />
            </Grid>

            <Grid item xs={3}>
              <TextInput source="primary_email" required fullWidth />
            </Grid>
          </Grid>

          <Divider />
          <FormControls />
        </Form>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyWorkerDetailsOverviewEdit;
