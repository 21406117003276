const getAddressParts = (parts) => {
  const types = [
    'street_number',
    'route',
    'locality',
    'sublocality',
    'neighborhood',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'country',
    'postal_code',
  ];
  return parts.reduce((acc, part) => {
    part.types.forEach((type) => {
      if (types.includes(type)) {
        acc[type] = part.short_name;
      }
    });
    return acc;
  }, {});
};

const normalizeParts = (parts) => {
  const types = ['street_number', 'route', 'administrative_area_level_1', 'country', 'postal_code'];
  const missingParts =
    types.map((key) => parts[key]).includes(undefined) ||
    !(
      parts.locality ||
      parts.sublocality ||
      parts.neighborhood ||
      parts.administrative_area_level_3 ||
      parts.administrative_area_level_2
    );
  if (missingParts) {
    throw new Error('missing parts');
  }
  return {
    line_1: `${parts.street_number} ${parts.route}`,
    city:
      parts.locality ??
      parts.sublocality ??
      parts.neighborhood ??
      parts.administrative_area_level_3 ??
      parts.administrative_area_level_2,
    state: parts.administrative_area_level_1,
    country: parts.country,
    postal_id: parts.postal_code,
  };
};

export { getAddressParts, normalizeParts };
