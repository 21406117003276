import React, { useContext } from 'react';

import { bool } from 'prop-types';
import { BooleanInput, Form, NumberInput, SelectInput, useCreate, useNotify, useRefresh } from 'react-admin';

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MarketActionContext } from '@/providers/MarketAction';

import { MARKET_PRODUCT_SERVICES } from '@/constants/market';

const CreateMarketProductForm = ({ isCreating }) => {
  const { setAction } = useContext(MarketActionContext);

  return (
    <>
      <Box px={2} pt={2}>
        <Box>
          <SelectInput source="product_code" choices={MARKET_PRODUCT_SERVICES} fullWidth required />
        </Box>
        <Box>
          <NumberInput source="distance_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="customer_rating_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="job_count_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="cost_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="maximum_lead_days" fullWidth />
        </Box>
        <Box>
          <NumberInput source="removal_rate_weight" fullWidth />
        </Box>
        <Box>
          <NumberInput source="job_details_lead_time" fullWidth />
        </Box>
        <Box>
          <BooleanInput source="skip_autoassign" fullWidth />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isCreating ?? null}
          startIcon={isCreating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

CreateMarketProductForm.propTypes = {
  isCreating: bool.isRequired,
};

const CreateMarketProduct = () => {
  const { setAction, metadata } = useContext(MarketActionContext);
  const [create, { isLoading: isCreating }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - adding Market Product failed - ${error.status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    notify(`Market product: ${data.product_id} added`, {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    create(
      resources.MARKET_PRODUCTS,
      {
        data,
        meta: {
          resourceId: metadata.id,
          subResource: 'products',
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Market Product
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <CreateMarketProductForm isCreating={isCreating} />
        </Form>
      </Box>
    </Box>
  );
};

export default CreateMarketProduct;
