import React from 'react';

import { Form, SaveButton, ShowButton, TextInput, Toolbar, required, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewDetailsContactInfoEdit = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Contact Info" />

      <Divider />

      <CardContent>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput source="phone" label="Phone number" validate={[required()]} fullWidth />
            </Grid>

            <Grid item xs={3}>
              <TextInput source="email" label="Email address" validate={[required()]} fullWidth />
            </Grid>
          </Grid>

          <Divider />
          <CustomToolbar />
        </Form>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDetailsContactInfoEdit;
