import React from 'react';
import { string } from 'prop-types';

import { Box, Typography } from '@mui/material';

const EmptyResourceList = ({ text }) => (
  <Box width="100%" my={2}>
    <Typography variant="h5" textAlign="center">
      {text}
    </Typography>
  </Box>
);

EmptyResourceList.propTypes = {
  text: string,
};

EmptyResourceList.defaultProps = {
  text: 'No resources found',
};

export default EmptyResourceList;
