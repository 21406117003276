import React from 'react';

import { BooleanField, Datagrid, EmailField, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

const StripeCustomerFilter = [
  <TextInput source="account_code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="email" alwaysOn resettable autoComplete="off" />,
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
];

const AdminStripeCustomersList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Stripe Customers</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.ADMIN_STRIPE_CUSTOMERS}
      filters={StripeCustomerFilter}
      exporter={false}
      empty={<EmptyResourceList text="No Stripe Customers" />}
      hasCreate={false}
      storeKey={`${resources.ADMIN_STRIPE_CUSTOMERS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" emptyText="N/A" />
        <TextField source="name" emptyText="N/A" />
        <EmailField source="email" emptyText="N/A" />
        <BooleanField source="isdeleted" emptyText="N/A" />
        <TextField source="bt_stripe__account__r__code__c" emptyText="N/A" label="Account code" />
        <TextField source="bt_stripe__customer_id__c" emptyText="N/A" label="Customer id" />
        <TextField source="bt_stripe__default_source_id__c" emptyText="N/A" label="Default source id" />
        <TextField source="bt_stripe__payment_gateway__r__code__c" emptyText="N/A" label="Payment gateway" />
      </Datagrid>
    </List>
  </Card>
);

export default AdminStripeCustomersList;
