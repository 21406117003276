import { useMemo } from 'react';
import { useLocation } from 'react-router';

// Custom hook to return supported query parameters for special handling within React Admin
const useCustomParams = () => {
  const { search } = useLocation();

  const supportedParams = ['view'];

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return supportedParams.reduce((prev, curr) => {
      const paramValue = searchParams.get(curr);
      return {
        ...prev,
        [curr]: paramValue,
      };
    }, {});
  }, [search]);

  return params;
};

export default useCustomParams;
