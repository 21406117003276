export default ({ palette }) => ({
  '& .quote-flex-calendar .react-calendar': {
    maxWidth: '100%',
    background: palette.mode === 'light' ? palette.neutral.lightest : palette.secondary.darker,
    border: `1px solid ${palette.mode === 'light' ? palette.neutral.light : palette.neutral.dark}`,
    lineHeight: '1.125em',
    '&, *, *:before, *:after': {
      boxSizing: 'border-box',
    },
    button: {
      margin: 0,
      border: 0,
      outline: 'none',
    },
    'button:enabled:hover': {
      cursor: 'pointer',
    },
    '&__tile': {
      maxWidth: '100%',
      padding: '8px 4px',
      background: 'none',
      textAlign: 'center',
      lineHeight: '16px',
      color: palette.text.primary,
      '&.unavailable': {
        color: palette.neutral.medium,
      },
      '&:disabled': {
        backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.secondary.darkest,
        color: palette.neutral.medium,
      },
      '&:enabled:hover, &:enabled:focus': {
        backgroundColor: palette.mode === 'light' ? palette.secondary.lighter : palette.secondary.dark,
      },
      '&--now, &--now.unavailable': {
        color: palette.primary.main,
      },
      '&--active': {
        background: palette.mode === 'light' ? palette.secondary.lighter : palette.secondary.dark,
      },
    },
    '&__navigation': {
      display: 'flex',
      height: 44,
      marginBottom: 4,
      borderBottom: `1px solid ${palette.mode === 'light' ? palette.neutral.light : palette.neutral.dark}`,
      button: {
        minWidth: 44,
        background: 'none',
        color: palette.text.primary,
        '&:disabled': {
          backgroundColor: palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest,
        },
        '&:enabled:hover, &:enabled:focus': {
          backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.secondary.dark,
        },
      },
      '&__label': {
        fontWeight: 'bold',
        fontSize: '16px',
      },
    },
    '&__month-view': {
      '&__weekdays': {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '0.75em',
        '&__weekday': {
          padding: '0.5em',
        },
        abbr: {
          textDecoration: 'none',
        },
      },
      '&__days__day': {
        '&--weekend': {
          // color: 'red',
        },
        '&--neighboringMonth': {
          color: palette.neutral.medium,
        },
      },
    },
    '&__year-view': {
      '& .react-calendar__tile': {
        padding: '2em 0.5em',
      },
    },
  },
});
