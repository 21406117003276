import React from 'react';

import { EditBase, Form, NumberInput, SaveButton, ShowButton } from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_TAB } from '@/constants/market';
import MarketHeader from '@/resources/markets/Show/MarketHeader';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between">
    <ShowButton label="Cancel" icon={null} />
    <SaveButton label="Update" icon={null} />
  </Box>
);

const TravelPayForm = () => (
  <Form>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <NumberInput source="mileage_minimum" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source="tier_1_mileage_maximum" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source="mileage_maximum" fullWidth />
        </Grid>

        <Grid item xs={4}>
          <NumberInput source="tier_1_mileage_reimbursement" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source="tier_2_mileage_reimbursement" fullWidth />
        </Grid>
      </Grid>
    </CardContent>
    <Divider />
    <CardContent>
      <FormControls />
    </CardContent>
  </Form>
);

const MarketTravelPayOverviewEdit = () => {
  const { marketId } = useParams();

  return (
    <EditBase
      resource={resources.MARKETS}
      id={marketId}
      mutationMode="pessimistic"
      redirect={`/${resources.MARKETS}/${marketId}/show/${MARKET_TAB.TRAVEL_PAY}`}
      actions={false}
    >
      <Box mt={3}>
        <MarketHeader />
      </Box>
      <Card mt={3} component={Box}>
        <CardHeader title="Overview" />
        <Divider />
        <TravelPayForm />
      </Card>
    </EditBase>
  );
};

export default MarketTravelPayOverviewEdit;
