import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';

import ThirdPartyWorkerCreate from './Create';
import ThirdPartyWorkerEdit from './Edit';
import ThirdPartyWorkerShow from './Show';

const thirdPartyWorker = () => ({
  name: resources.THIRD_PARTY_WORKER,
  show: ThirdPartyWorkerShow,
  children: [
    <Route key="third-party-worker-show-edit" path=":accountId/:tab/:section/edit" element={<ThirdPartyWorkerEdit />} />,
    <Route
      key="third-party-worker-show-deactivate"
      path=":accountId/:tab/:section/:status/edit"
      element={<ThirdPartyWorkerEdit />}
    />,
    <Route key="third-party-worker-create" path=":crew_code/create" element={<ThirdPartyWorkerCreate />} />,
  ],
});

export default thirdPartyWorker;
