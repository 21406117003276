import React from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';
import { bool, oneOf } from 'prop-types';
import { Datagrid, FunctionField, NumberField, Pagination, TextField, useListContext } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { Alert, Box, Card, CircularProgress, Divider, Typography } from '@mui/material';

import ListBase from '@/ReactAdmin/List/ListBase';
import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPES_DISPLAY } from '@/constants/pricingLayers';
import CurrencyField from '@/fields/CurrencyField';
import DayJsField from '@/fields/DayJsField';
import DayOfWeekField from '@/fields/DayOfWeekField';
import MarketNameField from '@/fields/MarketNameField';
import MonthOfYearField from '@/fields/MonthOfYearField';
import ServiceNameField from '@/fields/ServiceNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import EmptyResourceList from '@/shared/EmptyResourceList';

const DateNumericField = ({ type }) => {
  switch (type) {
    case PRICING_LAYER_TYPES.DAY_OF_WEEK:
      return <DayOfWeekField source="day_of_week" />;
    case PRICING_LAYER_TYPES.DAY_OF_MONTH:
      return <TextField source="day_of_month" />;
    case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
      return <MonthOfYearField source="month_of_year" oneIndexed />;
    case PRICING_LAYER_TYPES.LEAD_TIME:
      return (
        <FunctionField
          render={({ lead_time_in_days: leadTime }) => (
            <TextFieldWrapper value={`${leadTime} day${leadTime !== 1 ? 's' : ''}`} />
          )}
        />
      );
    default:
      return null;
  }
};

DateNumericField.propTypes = {
  type: oneOf(Object.values(PRICING_LAYER_TYPES)).isRequired,
};

const CollisionDetectionCard = ({ minimumInputValid }) => {
  const { isFetching, isLoading, total } = useListContext();
  const selectedDateNumericType = useWatch({ name: 'date_numeric_type' });

  return (
    <Card mt={2} component={Box}>
      <Box py={2} px={3} display="flex" justifyContent="space-between" alignItems="center" minHeight={80}>
        <Typography variant="h5">Collisions</Typography>
        {total > 0 ? (
          <Alert variant="filled" severity="error">
            Conflict errors are very likely if these inputs are submitted
          </Alert>
        ) : null}
      </Box>
      <Divider />
      {minimumInputValid ? (
        <>
          {isFetching || isLoading ? (
            <Box display="flex" justifyContent="center" py={2}>
              <CircularProgress size={50} />
            </Box>
          ) : null}
          <Datagrid
            isLoading={isFetching || isLoading}
            bulkActionButtons={false}
            empty={<EmptyResourceList text="No collisions detected" />}
          >
            <ServiceNameField source="service_id" />
            <DayJsField format="MM/DD/YYYY" source="effective_date" />
            <DateNumericField type={selectedDateNumericType} label={PRICING_LAYER_TYPES_DISPLAY[selectedDateNumericType]} />
            <MarketNameField source="market_id" label="Market" />
            <CurrencyField source="price" emptyText="N/A" />
            <NumberField source="multiplier" emptyText="N/A" />
          </Datagrid>
          {total > 0 ? <Pagination /> : null}
        </>
      ) : (
        <EmptyResourceList text="Minimum inputs required" />
      )}
    </Card>
  );
};

CollisionDetectionCard.propTypes = {
  minimumInputValid: bool.isRequired,
};

const CollisionDetection = () => {
  const selectedEffectiveDate = useWatch({ name: 'effective_date' });
  const selectedDateNumericType = useWatch({ name: 'date_numeric_type' });
  const selectedDateNumeric = useWatch({ name: 'date_numeric' });
  const selectedMarketIds = useWatch({ name: 'market_ids' });
  const selectedServiceIds = useWatch({ name: 'service_ids' });

  const minimumInputValid = Boolean(
    selectedEffectiveDate &&
      selectedMarketIds &&
      selectedServiceIds &&
      selectedDateNumeric &&
      !_.isEmpty(selectedMarketIds) &&
      !_.isEmpty(selectedServiceIds) &&
      !_.isEmpty(selectedDateNumeric),
  );

  return (
    <ListBase
      resource={resources.PRICING_LAYERS}
      filter={{
        service_id: selectedServiceIds,
        market_id: selectedMarketIds,
        effective_date: dayjs(selectedEffectiveDate).format('YYYY-MM-DD'),
        ...(selectedDateNumericType && selectedDateNumeric ? { [selectedDateNumericType]: selectedDateNumeric } : {}),
        type: selectedDateNumericType,
      }}
      sort={{ field: 'market_id', order: 'DESC' }}
      storeKey={`${resources.PRICING_LAYERS}.date.collisions`}
      queryOptions={{
        enabled: minimumInputValid,
      }}
    >
      <CollisionDetectionCard minimumInputValid={minimumInputValid} />
    </ListBase>
  );
};

export default CollisionDetection;
