import React from 'react';

import { Labeled, SimpleShowLayout, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CUSTOMER_ACCOUNT_DETAIL_SECTION, CUSTOMER_ACCOUNT_TAB } from '@/constants/customerAccount';
import MarketNameField from '@/fields/MarketNameField';
import TagsField from '@/fields/TagsField';

import CustomerDetailsSectionHeader from '../Common/SectionHeader';

const CustomerDetailsOverviewSection = () => (
  <Card component={Box}>
    <CustomerDetailsSectionHeader
      title="Overview"
      tab={CUSTOMER_ACCOUNT_TAB.DETAILS}
      section={CUSTOMER_ACCOUNT_DETAIL_SECTION.OVERVIEW}
    />
    <Divider />
    <CardContent>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="first_name" emptyText="N/A" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="phone" label="Phone number" emptyText="N/A" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="account_record_type_name" label="Account type" emptyText="N/A" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="last_name" emptyText="N/A" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="email" label="Email address" emptyText="N/A" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <MarketNameField source="primary_market" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="status" emptyText="N/A" />
            </Labeled>
          </Grid>
          <Grid item xs={8}>
            <Labeled>
              <TagsField source="tags" />
            </Labeled>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </CardContent>
  </Card>
);

export default CustomerDetailsOverviewSection;
