import React from 'react';

import { EmailField, Labeled, ReferenceField, TextField, useRecordContext } from 'react-admin';

import { Card, CardContent, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import {
  THIRD_PARTY_WORKER_ACCOUNT_DETAIL_SECTION,
  THIRD_PARTY_WORKER_ACCOUNT_TAB,
} from '@/constants/thirdPartyWorkerAccount';

import ThirdPartyWorkerDetailsSectionHeader from '../Common/SectionHeader';

const ThirdPartyWorkerDetailsOverviewSection = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card>
      <ThirdPartyWorkerDetailsSectionHeader
        title="Overview"
        tab={THIRD_PARTY_WORKER_ACCOUNT_TAB.DETAILS}
        section={THIRD_PARTY_WORKER_ACCOUNT_DETAIL_SECTION.OVERVIEW}
      />

      <Divider />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Labeled>
              <TextField source="name" emptyText="N/A" />
            </Labeled>
          </Grid>

          <Grid item xs={4}>
            <Labeled>
              <TextField source="status" emptyText="N/A" />
            </Labeled>
          </Grid>

          <Grid item xs={4}>
            <Labeled>
              <TextField source="phone" emptyText="N/A" />
            </Labeled>
          </Grid>

          <Grid item xs={4}>
            <Labeled>
              <EmailField source="primary_email" />
            </Labeled>
          </Grid>

          <Grid item xs={4}>
            <Labeled>
              <TextField source="account_record_type_name" label="Account Type" emptyText="N/A" />
            </Labeled>
          </Grid>

          <Grid item xs={4}>
            <Labeled>
              <ReferenceField
                source="company_id"
                reference={resources.THIRD_PARTY_CREW}
                label="Company"
                link="show"
                emptyText="N/A"
              >
                <TextField source="name" emptyText="N/A" />
              </ReferenceField>
            </Labeled>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyWorkerDetailsOverviewSection;
