import { NotificationClasses } from 'react-admin';

export default ({ palette }) => ({
  styleOverrides: {
    root: {
      [`& .${NotificationClasses.success}`]: {
        backgroundColor: palette.success.main,
      },
    },
  },
});
