import { DatagridClasses } from 'react-admin';

export default ({ palette }) => ({
  styleOverrides: {
    root: {
      [`& .${DatagridClasses.headerCell}`]: {
        backgroundColor: palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.dark,
      },
      [`& .${DatagridClasses.rowOdd}`]: {
        backgroundColor: palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest,
      },
      [`& .${DatagridClasses.expandedPanel}`]: {
        backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.neutral.dark,
      },
    },
  },
});
