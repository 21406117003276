import { PRODUCTS } from './productName';

const QUOTE_PRODUCT_MAP = {
  OFFERPAD: {
    'inventory.extra_data.assembly': PRODUCTS.ASSEMBLY.id,
    'inventory.extra_data.disassembly': PRODUCTS.DISASSEMBLY.id,
    'inventory.extra_data.elevator': PRODUCTS.ELEVATOR.id,
    'inventory.extra_data.extra_stop': PRODUCTS.ADDITIONALSTOP.id,
    'inventory.extra_data.stairs': PRODUCTS.STAIRS.id,
  },
  ZIPPY: {
    'inventory.extra_data.adjacent_market': PRODUCTS.ADJACENTMARKET.id,
    'inventory.extra_data.assembly': PRODUCTS.ASSEMBLY.id,
    'inventory.extra_data.disassembly': PRODUCTS.DISASSEMBLY.id,
    'inventory.extra_data.elevator': PRODUCTS.ELEVATOR.id,
    'inventory.extra_data.extra_stop': PRODUCTS.ADDITIONALSTOP.id,
    'inventory.extra_data.long_haul': PRODUCTS.LONGHAUL.id,
    'inventory.extra_data.stairs': PRODUCTS.STAIRS.id,
  },
};

// eslint-disable-next-line import/prefer-default-export
export { QUOTE_PRODUCT_MAP };
