import React, { useContext } from 'react';

import { Box, Button, Drawer, Typography } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';

import UnpauseTransaction from '@/shared/ActionDrawer/UnpauseTransaction';
import AddDiscount from './AddDiscount';
import AddFee from './AddFee';
import AddJobSlot from './AddJobSlot';
import AddOperatorNote from './AddOperatorNote';
import AddPriceAdjustment from './AddPriceAdjustment';
import AddSettlement from './AddSettlement';
import AddTips from './AddTips';
import BookDraftOrder from './BookDraftOrder';
import CancelCharge from './CancelCharge';
import CancelOrder from './CancelOrder';
import CompleteJob from './CompleteJob';
import CompleteOrder from './CompleteOrder';
import ConvertOrderToBhn from './ConvertOrderToBhn';
import CreateCharge from './CreateCharge';
import DeleteJobSlot from './DeleteJobSlot';
import EditCharge from './EditCharge';
import EditNotes from './EditNotes';
import EditPaymentMethod from './EditPaymentMethod';
import EditProduct from './EditProduct';
import RefundCharge from './RefundCharge';
import RescheduleOrder from './RescheduleOrder';
import UpdateClockTimes from './UpdateClockTimes';
import UpdateOrder from './UpdateOrder';

const UnknownAction = () => {
  const { action, setAction } = useContext(OrderActionContext);
  return (
    <Box px={6} py={4} minWidth={400}>
      <Typography variant="h5" component={Box} pb={4}>{`Unknown Action - ${action}`}</Typography>
      <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
        Close
      </Button>
    </Box>
  );
};

const OrderActionsDrawer = () => {
  const { action, setAction } = useContext(OrderActionContext);

  if (!action) return null;

  let drawerContent;
  switch (action) {
    case 'add_job_slot':
      drawerContent = <AddJobSlot />;
      break;
    case 'add_operator_note':
      drawerContent = <AddOperatorNote />;
      break;
    case 'add_discount':
      drawerContent = <AddDiscount />;
      break;
    case 'add_fee':
      drawerContent = <AddFee />;
      break;
    case 'add_settlement':
      drawerContent = <AddSettlement />;
      break;
    case 'add_price_adjustment':
      drawerContent = <AddPriceAdjustment />;
      break;
    case 'add_tips':
      drawerContent = <AddTips />;
      break;
    case 'book_draft_order':
      drawerContent = <BookDraftOrder />;
      break;
    case 'cancel_charge':
      drawerContent = <CancelCharge />;
      break;
    case 'cancel_order':
      drawerContent = <CancelOrder />;
      break;
    case 'complete_job':
      drawerContent = <CompleteJob />;
      break;
    case 'complete_order':
      drawerContent = <CompleteOrder />;
      break;
    case 'convert_to_bhn':
      drawerContent = <ConvertOrderToBhn />;
      break;
    case 'create_charge':
      drawerContent = <CreateCharge />;
      break;
    case 'delete_job_slot':
      drawerContent = <DeleteJobSlot />;
      break;
    case 'edit_charge':
      drawerContent = <EditCharge />;
      break;
    case 'edit_notes':
      drawerContent = <EditNotes />;
      break;
    case 'edit_payment_method':
      drawerContent = <EditPaymentMethod />;
      break;
    case 'edit_product':
      drawerContent = <EditProduct />;
      break;
    case 'refund_charge':
      drawerContent = <RefundCharge />;
      break;
    case 'reschedule_order':
      drawerContent = <RescheduleOrder />;
      break;
    case 'update_job_slot_clock_times':
      drawerContent = <UpdateClockTimes />;
      break;
    case 'update_order':
      drawerContent = <UpdateOrder />;
      break;
    case 'unpause':
      drawerContent = <UnpauseTransaction />;
      break;
    default:
      drawerContent = <UnknownAction />;
  }

  return (
    <Drawer anchor="right" open={Boolean(action)} onClose={() => setAction(null)}>
      {drawerContent}
    </Drawer>
  );
};

export default OrderActionsDrawer;
