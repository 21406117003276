const JOB_SLOT_STATUS = {
  ASSIGNED: 'Assigned',
  CANCELLED: 'Cancelled',
  REMOVED: 'Removed',
  UNASSIGNED: 'Unassigned',
};

const JOB_SLOT_ACTIVE_STATUSES = [JOB_SLOT_STATUS.ASSIGNED, JOB_SLOT_STATUS.UNASSIGNED];

export { JOB_SLOT_STATUS, JOB_SLOT_ACTIVE_STATUSES };
