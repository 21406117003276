import React from 'react';

import { Datagrid, EditButton, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider, Typography } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import EmptyResourceList from '@/shared/EmptyResourceList';

const UsersFilter = [
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
  <TextInput source="email" alwaysOn resettable autoComplete="off" />,
];

const UserList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Users</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.USERS}
      exporter={false}
      filters={UsersFilter}
      empty={<EmptyResourceList text="No Users" />}
      storeKey={`${resources.USERS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" sortable={false} />
        <TextField source="email" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  </Card>
);

export default UserList;
