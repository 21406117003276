import React from 'react';

import {
  EditBase,
  Form,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  ShowButton,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

import { useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { CUSTOMER_RECORD_TYPE, CUSTOMER_STATUS } from '@/constants/customerAccount';
import { ACCOUNT_TAG_CHOICES } from '@/constants/tags';

const DetailsForm = () => {
  const record = useRecordContext();

  return (
    <CardContent>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextInput source="first_name" validate={[required()]} fullWidth />
            <TextInput source="phone" label="Phone number" validate={[]} fullWidth />
            <ReferenceInput
              source="primary_market"
              reference={resources.MARKETS}
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" label="Market" disabled />
            </ReferenceInput>
          </Grid>

          <Grid item xs={4}>
            <TextInput source="last_name" validate={[required()]} fullWidth />
            <SelectInput source="status" choices={CUSTOMER_STATUS} validate={[required()]} fullWidth />
          </Grid>

          <Grid item xs={4}>
            <TextInput
              source="email"
              label="Email address"
              defaultValue={record.primary_email}
              type="email"
              validate={[]}
              fullWidth
            />
            <SelectInput
              disabled
              source="account_record_type_name"
              label="Account type"
              choices={CUSTOMER_RECORD_TYPE}
              validate={[required()]}
              fullWidth
            />
          </Grid>
        </Grid>

        <SelectArrayInput source="tags" choices={ACCOUNT_TAG_CHOICES} />

        <Grid item xs={4} display="flex" justifyContent="space-between">
          <ShowButton label="Cancel" icon={null} />
          <SaveButton label="Update" icon={null} />
        </Grid>
      </Form>
    </CardContent>
  );
};

const CustomerDetailsOverviewEdit = () => {
  const { accountId } = useParams();

  return (
    <EditBase
      resource={resources.CUSTOMERS}
      id={accountId}
      mutationOptions={{ meta: { subResource: 'customers' } }}
      mutationMode="pessimistic"
      redirect={`/${resources.CUSTOMERS}/${accountId}/show`}
      actions={false}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Overview" />
        <Divider />
        <CardContent>
          <DetailsForm />
        </CardContent>
      </Card>
    </EditBase>
  );
};

export default CustomerDetailsOverviewEdit;
