import React, { useEffect, useState } from 'react';
import {
  Create,
  Form,
  SaveButton,
  TextInput,
  useGetIdentity,
  useGetMany,
  useNotify,
  useRecordContext,
  useRedirect,
  useSaveContext,
} from 'react-admin';
import { useLocation } from 'react-router';
import { node } from 'prop-types';
import mx from 'mixpanel-browser';

import { Avatar, Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { Send } from '@mui/icons-material';

const HOPPER_UUID = '00000000-0000-0000-0000-000000000000';

const defaultValue = {
  title: '',
  message: '',
  urgent: false,
  sender_id: HOPPER_UUID,
  user_ids: [],
  source: 'Fulfillment',
  type: '',
};

const CommunicationsRoot = ({ children }) => (
  <Card
    sx={{
      mx: 3,
      p: 3,
      flex: '1 1 auto',
    }}
  >
    {children}
  </Card>
);

CommunicationsRoot.propTypes = {
  children: node.isRequired,
};

const Aside = () => {
  const record = useRecordContext();

  const { data: receipientAccounts } = useGetMany(
    resources.ACCOUNTS,
    { ids: record.user_ids },
    { enabled: Boolean(record) },
  );

  return receipientAccounts ? (
    <Card width={400} component={Box}>
      <CardHeader title="Receipients" />
      <Divider />
      <CardContent>
        {receipientAccounts.map((rec) => (
          <Box key={rec.id} display="flex" alignItems="center" mb={2}>
            <Avatar
              component={Box}
              src={rec.image_url}
              sx={{
                width: 36,
                height: 36,
              }}
            />
            <Typography ml={1}>{rec.name}</Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  ) : null;
};

const SaveToolbar = () => {
  const { saving } = useSaveContext();

  const buttonProps = {
    ...(saving ? { disabled: true } : { alwaysEnable: true }),
  };

  return (
    <Box display="flex" justifyContent="flex-end" mt={5}>
      <SaveButton icon={null} endIcon={<Send />} label="Send" {...buttonProps} />
    </Box>
  );
};

const CommunicationsCreate = (props) => {
  const location = useLocation();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useRedirect();

  const record = location?.state?.record;

  const CHARACTER_LIMIT = 400;

  const [values, setValues] = useState({
    title: record.title,
    message: record.message,
  });

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const transform = (data) => ({
    ...data,
    metadata: JSON.stringify(data.metadata),
    created_by: identity?.id,
    sender_id: HOPPER_UUID,
    type: 'Fulfillment',
    source: 'FULFILLMENT',
  });

  const onSettled = (data, error) => {
    if (error) {
      notify(`Error - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      mx.track('Resource - Created', {
        resource: resources.COMMUNICATIONS,
        resourceId: data.id,
        type: data.type,
        receipients: data.user_ids,
        metadata: data.metadata,
      });
      notify('Communication sent', {
        type: 'success',
      });
      redirect('show', resources.JOB_SLOTS, record?.metadata.job_slot_id);
    }
  };

  const validateComm = (formValues) => {
    const errors = {};

    if (!formValues.title) {
      errors.title = 'Title is required';
    }
    if (!formValues.message) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  useEffect(() => {
    mx.track('Resource Create - Viewed', {
      resource: resources.COMMUNICATIONS,
    });
  }, []);

  return (
    <Create
      record={record || defaultValue}
      {...props}
      component={CommunicationsRoot}
      aside={<Aside />}
      transform={transform}
      mutationOptions={{ onSettled }}
    >
      {record?.user_ids?.length > 0 ? (
        <Box>
          <Form validate={validateComm}>
            <Box>
              <TextInput source="title" variant="outlined" fullWidth required onChange={handleChange('subject')} />
              <TextInput
                source="message"
                variant="outlined"
                multiline
                fullWidth
                required
                maxRows={12}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                onChange={handleChange('body')}
              />
            </Box>
            <Box display="flex" justifyContent="end">
              <Typography
                sx={{
                  color: ({ palette }) => palette.text.secondary,
                }}
                variant="body4"
              >
                {`${CHARACTER_LIMIT - values.message.length} Characters left`}
              </Typography>
            </Box>
            <SaveToolbar />
          </Form>
        </Box>
      ) : (
        <Box>No receipients</Box>
      )}
    </Create>
  );
};

export default CommunicationsCreate;
