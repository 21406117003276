import React from 'react';

import { string } from 'prop-types';
import { usePermissions } from 'react-admin';
import { useNavigate } from 'react-router';

import { Description, LocationCity, Support } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';

import { THIRD_PARTY_WORKER_ACCOUNT_TAB } from '@/constants/thirdPartyWorkerAccount';

const ThirdPartyWorkerTabs = ({ currentTab }) => {
  const { permissions } = usePermissions();

  const canViewZendesk = permissions?.tools?.hqadmin?.zendesk_support?.view;

  const tabs = [
    { id: THIRD_PARTY_WORKER_ACCOUNT_TAB.DETAILS, icon: <Description /> },
    { id: THIRD_PARTY_WORKER_ACCOUNT_TAB.JOB_HISTORY, icon: <LocationCity /> },
    ...(canViewZendesk ? [{ id: THIRD_PARTY_WORKER_ACCOUNT_TAB.SUPPORT, icon: <Support /> }] : []),
  ];

  const navigate = useNavigate();

  return (
    <Tabs
      value={currentTab}
      onChange={(e, newTab) => {
        navigate(newTab);
      }}
      sx={{
        borderBottom: ({ palette }) => `1px solid ${palette.mode === 'dark' ? palette.neutral.dark : palette.neutral.light}`,
        '& .MuiTab-root': {
          textTransform: 'capitalize',
          minHeight: 0,
        },
      }}
    >
      {tabs.map(({ id, icon }, index) => (
        <Tab
          id={`third-party-worker-details-tab-${index}`}
          key={id}
          label={id.replace('-', ' ')}
          value={id}
          icon={icon}
          iconPosition="start"
          aria-controls={`third-party-worker-DETAILS-TABPanel-${index}`}
        />
      ))}
      ;
    </Tabs>
  );
};

ThirdPartyWorkerTabs.propTypes = {
  currentTab: string.isRequired,
};

export default ThirdPartyWorkerTabs;
