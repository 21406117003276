import { useEffect, useMemo, useState } from 'react';

import { useGetManyAggregate, useGetOne } from 'react-admin';
import { useWatch } from 'react-hook-form';

import * as resources from '@/api/resources';
import { QUOTE_STANDARD_SERVICE_PARTNERS } from '@/constants/quotePartners';
import { QUOTE_TYPES } from '@/constants/quoteTemplates';

import useUniqueMarkets from '../useUniqueMarkets';
import partnerServiceTemplates from './partnerServiceTemplates';
import standardServiceTemplates from './standardServiceTemplates';

// Custom hook to return templates based on available market items and market info
const useQuoteTemplates = () => {
  const locations = useWatch({ name: 'locations' });
  const partner = useWatch({ name: 'partner_id' });
  const affiliate = useWatch({ name: 'affiliate_id' });
  const selectedQuoteType = useWatch({ name: 'selected_quote_type' });

  const [uniqueMarkets] = useUniqueMarkets({ locations });

  const { data: marketsData } = useGetManyAggregate(
    resources.MARKETS,
    { ids: uniqueMarkets },
    { enabled: Boolean(uniqueMarkets?.length) },
  );

  const { data: routeData } = useGetOne(
    `${resources.UTILITY}/route`,
    {
      meta: { filter: { ids: locations?.map(({ location_id: id }) => id) } },
    },
    { enabled: Boolean(locations?.length > 1), retry: false },
  );

  const [availableTemplates, setAvailableTemplates] = useState([]);

  const updateAvailableTemplates = () => {
    if (!locations) return;
    if (selectedQuoteType !== QUOTE_TYPES.BELLHOP && !partner && !affiliate) {
      // Partner / Affiliate Quote type requires a selection, return no templates
      setAvailableTemplates([]);
      return;
    }

    if (partner && !QUOTE_STANDARD_SERVICE_PARTNERS.includes(partner)) {
      setAvailableTemplates(partnerServiceTemplates({ partner, locations, marketsData, uniqueMarkets }));
      return;
    }

    setAvailableTemplates(standardServiceTemplates({ locations, marketsData, uniqueMarkets, routeData }));
  };

  const data = useMemo(() => availableTemplates ?? [], [availableTemplates]);

  useEffect(() => {
    updateAvailableTemplates();
  }, [locations, partner, uniqueMarkets, routeData]);

  return [data];
};

export default useQuoteTemplates;
