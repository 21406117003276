import React from 'react';
import { bool } from 'prop-types';
import { useRecordContext, useReference } from 'react-admin';
import { Avatar, Box, CircularProgress, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';

import * as resources from '@/api/resources';
import getQualificationLevel from '@/utils/qualificationLevel/getQualificationLevel';

const SlotAvatarField = ({ enableTooltip }) => {
  const record = useRecordContext();

  const {
    referenceRecord: account,
    isLoading,
    isFetching,
  } = useReference({
    reference: resources.ACCOUNTS,
    id: record?.account_id,
    options: {
      enabled: Boolean(record?.account_id),
    },
  });

  if (!record) return null;

  if (record.account_id && (isLoading || isFetching)) {
    return (
      <Box display="flex" alignItems="center" py={1} px={1}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  const avatar = account ? (
    <Avatar
      component={Box}
      src={account.image_url}
      sx={{
        width: 36,
        height: 36,
      }}
    />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: ({ palette }) => palette.neutral.lighter,
        border: ({ palette }) => `2px solid ${palette.accent.main}`,
      }}
    >
      <Add sx={{ fontSize: 16 }} />
    </Box>
  );

  if (enableTooltip) {
    const qualLevel = getQualificationLevel(record.minimum_qualification_level);
    const tooltipTitle = record.account_id ? `${account?.name} - ${qualLevel}` : `Unassigned - ${qualLevel}`;
    return <Tooltip title={tooltipTitle}>{avatar}</Tooltip>;
  }

  return avatar;
};

SlotAvatarField.propTypes = {
  enableTooltip: bool,
};

SlotAvatarField.defaultProps = {
  enableTooltip: false,
};

export default SlotAvatarField;
