import React from 'react';

import { useRecordContext } from 'react-admin';

import { Box } from '@mui/material';

import ThirdPartyWorkerDetailsOverviewSection from './Overview';

const Details = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Box width="100%">
      <ThirdPartyWorkerDetailsOverviewSection />
    </Box>
  );
};

export default Details;
