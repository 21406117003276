import React from 'react';

import { Edit, SaveButton, ShowButton, SimpleForm, TextInput } from 'react-admin';

import { Box, Divider } from '@mui/material';

const CustomToolbar = () => (
  <Box>
    <Divider />
    <Box display="flex" justifyContent="space-between" p={3}>
      <ShowButton label="Cancel" icon={null} color="secondary" />
      <SaveButton label="Update" icon={null} type="button" />
    </Box>
  </Box>
);

const AdminStripeCustomersEdit = () => (
  <Edit redirect="show">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="bt_stripe__account__r__code__c" label="Account code" />
      <TextInput source="bt_stripe__customer_id__c" label="Customer id" />
      <TextInput source="bt_stripe__default_source_id__c" label="Default source id" />
      <TextInput source="bt_stripe__default_source__r__code__c" label="Default source code" />
      <TextInput source="bt_stripe__payment_gateway__r__code__c" label="Payment gateway" />
    </SimpleForm>
  </Edit>
);

export default AdminStripeCustomersEdit;
