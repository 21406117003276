import React from 'react';

import { Labeled, NumberField } from 'react-admin';

import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const OfferpadInventory = () => (
  <Card>
    <CardHeader title="Offerpad Inventory" />
    <Divider />
    <CardContent>
      <Grid container spacing={1} px={2}>
        <Grid item xs={12 / 5}>
          <Labeled>
            <NumberField source="extra_data.weight" label="Weight" />
          </Labeled>
        </Grid>
        <Grid item xs={12 / 5}>
          <Labeled>
            <NumberField source="extra_data.miles_to_origin" label="Miles to Origin" />
          </Labeled>
        </Grid>
        <Grid item xs={12 / 5}>
          <Labeled>
            <NumberField source="extra_data.extra_stop" label="Stops" />
          </Labeled>
        </Grid>
        <Grid item xs={12 / 5}>
          <Labeled>
            <NumberField source="extra_data.assembly" label="Assembly Items" />
          </Labeled>
        </Grid>
        <Grid item xs={12 / 5}>
          <Labeled>
            <NumberField source="extra_data.disassembly" label="Disassembly Items" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default OfferpadInventory;
