import React from 'react';

import dayjs from 'dayjs';
import { RecordContextProvider, ShowButton, useGetManyReference, useRecordContext } from 'react-admin';

import { Box, LinearProgress, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JOB_SLOT_STATUS } from '@/constants/jobSlotStatus';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import getServiceName from '@/utils/serviceName/getServiceName';

import JobSlot from './JobSlot';

const Job = () => {
  const record = useRecordContext();

  const {
    data: jobSlotsData,
    isLoading,
    isFetching,
  } = useGetManyReference(
    resources.JOB_SLOTS,
    {
      target: 'job_id',
      id: record?.id,
      filter: {
        status: [JOB_SLOT_STATUS.ASSIGNED, JOB_SLOT_STATUS.UNASSIGNED],
      },
      sort: {
        field: 'minimum_qualification_level',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100,
      },
    },
    {
      enabled: Boolean(record?.id),
    },
  );

  const duration = dayjs(record.end_date_time).diff(dayjs(record.start_date_time), 'hour');

  if (!record) return null;

  return (
    <Box px={4} py={2} display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="body2" component={Box} mr={4} minWidth={150}>
        {getServiceName(record.product_id)}
      </Typography>
      <Box mr={4}>
        <PrefDateTimeField source="start_date_time" timezoneSource="start_timezone" dayjsFormat="MMM D @ h:mmA" />
      </Box>
      <Typography variant="body2" component={Box} mr={4}>
        {`${duration} hours`}
      </Typography>
      {!jobSlotsData || isLoading || isFetching ? (
        <Box width={200} py={2}>
          <LinearProgress />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" maxWidth="60%" flexWrap="wrap">
          {jobSlotsData.map((js) => (
            <RecordContextProvider value={js} key={js.id}>
              <JobSlot />
            </RecordContextProvider>
          ))}
        </Box>
      )}
      <Box ml="auto">
        <ShowButton label="Go to Job" resource={resources.JOBS} startIcon={null} />
      </Box>
    </Box>
  );
};

Job.propTypes = {};

export default Job;
