import React from 'react';

import { useRecordContext } from 'react-admin';

import { SETTLEMENT_COVERAGE_TYPES, SETTLEMENT_PARTY_AT_FAULT, SETTLEMENT_REASONS } from '@/constants/settlements';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { string } from 'prop-types';
import { SERVICES } from '@/constants/serviceName';

export const SettlementCoverageTypeField = ({ source }) => {
  const record = useRecordContext();

  if (!record?.[source]) return null;

  return <TextFieldWrapper value={SETTLEMENT_COVERAGE_TYPES?.[record[source]]?.name ?? record[source]} />;
};

SettlementCoverageTypeField.propTypes = {
  source: string.isRequired,
};

export const SettlementReasonField = ({ source }) => {
  const record = useRecordContext();

  if (!record?.[source]) return null;

  return <TextFieldWrapper value={SETTLEMENT_REASONS?.[record[source]]?.name ?? record[source]} />;
};

SettlementReasonField.propTypes = {
  source: string.isRequired,
};

export const SettlementPartyAtFaultField = ({ source }) => {
  const record = useRecordContext();

  if (!record?.[source]) return null;

  // Party At Fault Value can be a service id, one of hardcoded fault values, if matches neither list, display raw value
  const partyAtFaultValue =
    SERVICES?.[record?.[source]]?.name ?? SETTLEMENT_PARTY_AT_FAULT?.record?.[source]?.name ?? record?.[source];

  return <TextFieldWrapper value={partyAtFaultValue} />;
};

SettlementPartyAtFaultField.propTypes = {
  source: string.isRequired,
};
