import React, { useEffect } from 'react';

import { CardElement, useElements } from '@stripe/react-stripe-js';
import mx from 'mixpanel-browser';
import { string } from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Card, CardContent, Divider, TextField as MuiTextField, useTheme as useMuiTheme } from '@mui/material';
import { useTheme } from 'react-admin';

const CardInput = ({ cardholderName }) => {
  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');
  const theme = useMuiTheme();

  const { control } = useFormContext();
  const elements = useElements();

  const stripeEventLog = (eventType, details) => {
    mx.track('Stripe Event', {
      eventType,
      ...(details ? { details } : {}),
    });
  };

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        cardElement.on('loaderror', ({ error }) => stripeEventLog('Load Error', { error }));
        cardElement.on('loaderstart', () => stripeEventLog('Loader Start'));
        cardElement.on('ready', () => stripeEventLog('Ready'));
      }
    }
    return () => {
      if (elements) {
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
          cardElement.off('loaderror');
          cardElement.off('loaderstart');
          cardElement.off('ready');
        }
      }
    };
  }, []);

  return (
    <Card
      component={Box}
      m={3}
      width={400}
      sx={{
        backgroundColor: ({ palette }) => (lightMode ? palette.neutral.lighter : palette.neutral.darkest),
      }}
    >
      <CardContent>
        <Box>
          <Controller
            defaultValue={cardholderName}
            name="cardholderName"
            control={control}
            render={({ field }) => <MuiTextField label="Cardholder Name" required fullWidth {...field} />}
          />
          {/* <TextInput source="cardholder_name" validate={[required()]} defaultValue={``} /> */}
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        <Box>
          <CardElement
            options={{
              disableLink: true,
              hidePostalCode: true,
              style: {
                base: {
                  backgroundColor: lightMode ? theme?.palette.neutral.lighter : theme?.palette.neutral.darkest,
                  color: lightMode ? theme?.palette.text.primary : theme?.palette.neutral.lightest,
                },
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

CardInput.propTypes = {
  cardholderName: string,
};

CardInput.defaultProps = {
  cardholderName: '',
};

export default CardInput;
