import React, { useMemo } from 'react';

import dayjs from 'dayjs';
import { Labeled, RecordContextProvider, TextField, useGetList, useRecordContext } from 'react-admin';

import { Help, Info } from '@mui/icons-material';
import { Box, CardContent, CardHeader, CircularProgress, Divider, Grid, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { EVENT_ACTIONS, EVENT_FIELDS } from '@/constants/events';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import UserField from '@/fields/UserField';
import { eventNewValueByField, eventPrevValueByField, latestEventByField } from '@/utils/events';

const ClockEdits = () => {
  const {
    id: jobSlotId,
    clock_in_timezone: slotClockInTimezone,
    clock_out_timezone: slotClockOutTimezone,
  } = useRecordContext();
  const { data, isLoading, isFetching } = useGetList(resources.JOB_SLOTS, {
    meta: {
      resourceId: jobSlotId,
      subResource: 'event_history',
    },
    sort: {
      field: 'timestamp',
      order: 'DESC',
    },
    pagination: {
      perPage: 999,
      page: 1,
    },
  });

  const clockedEventData = useMemo(() => {
    if (!data) return null;
    const clockedEvents = data?.filter((event) => event.action === EVENT_ACTIONS.SLOT_CLOCKED);
    const latestClockInEvent = latestEventByField(clockedEvents, EVENT_FIELDS.MOBILE_CLOCK_IN, EVENT_FIELDS.NONE_VALUE);
    const latestClockOutEvent = latestEventByField(clockedEvents, EVENT_FIELDS.MOBILE_CLOCK_OUT, EVENT_FIELDS.NONE_VALUE);
    const latestClockInValue = eventNewValueByField(latestClockInEvent, EVENT_FIELDS.MOBILE_CLOCK_IN);
    const latestClockOutValue = eventNewValueByField(latestClockOutEvent, EVENT_FIELDS.MOBILE_CLOCK_OUT);

    return { rawEvents: clockedEvents, latestClockInValue, latestClockOutValue };
  }, [data]);

  const manualClockEditEventData = useMemo(
    () => data?.filter((event) => event.action === EVENT_ACTIONS.SLOT_EDITED),
    [data],
  );
  const latestManualEditReason = manualClockEditEventData?.[0]?.metadata?.time_log_edit_reason;
  const latestManualEditUser = manualClockEditEventData?.[0]?.user_id;
  const oldestManualEdit = manualClockEditEventData?.[manualClockEditEventData.length - 1];
  const oldestClockOutPreviousValue = eventPrevValueByField(oldestManualEdit, 'clock_out_date_time');

  if (isLoading || isFetching) {
    return (
      <Box display="flex" justifyContent="center" py={2} width="100%">
        <CircularProgress size={40} />
      </Box>
    );
  }

  return !manualClockEditEventData?.length ? (
    // No manual edit events exist
    <CardContent>
      <Typography textAlign="center" variant="h6">
        No Manual Edits
      </Typography>
    </CardContent>
  ) : (
    // Manual edit events exist - display original clock data and last edited reason
    <>
      <Box py={2} px={3} display="flex" justifyContent="space-between">
        <Typography variant="h6">Manually Edited</Typography>
        <Tooltip
          title={`Edited ${manualClockEditEventData?.length} time${manualClockEditEventData?.length === 1 ? '' : 's'}`}
        >
          <Help />
        </Tooltip>
      </Box>
      <Divider />
      <CardContent>
        <Box px={3}>
          <RecordContextProvider value={{ edit_reason: latestManualEditReason, user_id: latestManualEditUser }}>
            <Grid container>
              <Grid item xs={12} xl={6}>
                <Labeled label="Most Recent Edit Reason">
                  <TextField source="edit_reason" />
                </Labeled>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Labeled label="Last Edited By">
                  <UserField source="user_id" emptyText="No user ID on event" clipboardUserSource="email" />
                </Labeled>
              </Grid>
            </Grid>
          </RecordContextProvider>
        </Box>
      </CardContent>
      <Divider />
      <CardHeader title="Original Clock Times" titleTypographyProps={{ variant: 'h6' }} />
      <Divider />
      {clockedEventData?.rawEvents?.length ? (
        <CardContent>
          <RecordContextProvider
            value={{
              clock_in_date_time: clockedEventData?.latestClockInValue,
              clock_out_date_time:
                clockedEventData?.latestClockOutValue ??
                (oldestClockOutPreviousValue ? dayjs(oldestClockOutPreviousValue).tz('UTC', true) : null),
              clock_in_timezone: slotClockInTimezone,
              clock_out_timezone: slotClockOutTimezone,
            }}
          >
            <Grid container textAlign="center">
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Labeled label="Clock In">
                    <PrefDateTimeField
                      source="clock_in_date_time"
                      timezoneSource="clock_in_timezone"
                      dayjsFormat="h:mm a"
                      emptyText="Pending"
                    />
                  </Labeled>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Labeled label="Clock Out">
                    <PrefDateTimeField
                      source="clock_out_date_time"
                      timezoneSource="clock_out_timezone"
                      dayjsFormat="h:mm a"
                      emptyText="No app clock out"
                    />
                  </Labeled>
                  {!clockedEventData?.latestClockOutValue ? (
                    <Box pl={2}>
                      <Tooltip title="Clocked out by captain">
                        <Info />
                      </Tooltip>
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </RecordContextProvider>
        </CardContent>
      ) : (
        <CardContent>
          <Typography textAlign="center" variant="h6">
            No clock times from app
          </Typography>
        </CardContent>
      )}
    </>
  );
};

export default ClockEdits;
