import React from 'react';

import { SelectInput, useGetMany } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { CheckCircle } from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { QUOTE_AFFILIATES, QUOTE_PARTNERS } from '@/constants/quotePartners';
import { QUOTE_TYPES } from '@/constants/quoteTemplates';

const PartnerAffiliate = () => {
  const { setValue, watch } = useFormContext();

  const selectedQuoteType = watch('selected_quote_type');
  const convertDraft = useWatch({ name: 'convert_draft' });

  const { data: partners } = useGetMany(resources.ACCOUNTS, { ids: QUOTE_PARTNERS });
  const { data: affiliates } = useGetMany(resources.ACCOUNTS, {
    ids: QUOTE_AFFILIATES.filter((affiliate) => !QUOTE_PARTNERS.includes(affiliate)),
  });

  const cardSxProp = {
    backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest),
  };

  const selectBellhopQuote = () => {
    setValue('partner_id', null, { shouldDirty: true });
    setValue('affiliate_id', null, { shouldDirty: true });
    setValue('selected_quote_type', QUOTE_TYPES.BELLHOP, { shouldDirty: true });
  };

  const selectPartnerQuote = () => {
    setValue('affiliate_id', null, { shouldDirty: true });
    setValue('selected_quote_type', QUOTE_TYPES.PARTNER, { shouldDirty: true });
  };

  const selectAffiliateQuote = () => {
    setValue('partner_id', null, { shouldDirty: true });
    setValue('selected_quote_type', QUOTE_TYPES.AFFILIATE, { shouldDirty: true });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card
              sx={{
                ...cardSxProp,
                ...(selectedQuoteType === QUOTE_TYPES.BELLHOP
                  ? {
                      borderColor: ({ palette }) => palette.primary.main,
                    }
                  : {}),
              }}
            >
              <CardActionArea onClick={selectBellhopQuote} disabled={selectedQuoteType === QUOTE_TYPES.BELLHOP ?? null}>
                <Box px={3} py={2} display="flex" justifyContent="space-between">
                  <Typography variant="h5">Bellhop</Typography>
                  {selectedQuoteType === QUOTE_TYPES.BELLHOP ? <CheckCircle color="primary" /> : null}
                </Box>
              </CardActionArea>
              <Divider />
              <CardContent>
                <Box mx="auto" maxWidth={79} pt={2}>
                  <img src="/svgs/LogoMobile.svg" alt="Bellhop Logo" width="100%" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {!convertDraft ? (
            <>
              <Grid item xs={4}>
                <Card
                  sx={{
                    ...cardSxProp,
                    ...(selectedQuoteType === QUOTE_TYPES.PARTNER
                      ? {
                          borderColor: ({ palette }) => palette.primary.main,
                        }
                      : {}),
                  }}
                >
                  <CardActionArea onClick={selectPartnerQuote} disabled={selectedQuoteType === QUOTE_TYPES.PARTNER ?? null}>
                    <Box px={3} py={2} display="flex" justifyContent="space-between">
                      <Typography variant="h5">Partner</Typography>
                      {selectedQuoteType === QUOTE_TYPES.PARTNER ? <CheckCircle color="primary" /> : null}
                    </Box>
                  </CardActionArea>
                  <Divider />
                  <CardContent>
                    <Box display="flex" justifyContent="center">
                      <SelectInput
                        disabled={selectedQuoteType !== QUOTE_TYPES.PARTNER ?? null}
                        source="partner_id"
                        choices={partners?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) ?? []}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  sx={{
                    ...cardSxProp,
                    ...(selectedQuoteType === QUOTE_TYPES.AFFILIATE
                      ? {
                          borderColor: ({ palette }) => palette.primary.main,
                        }
                      : {}),
                  }}
                >
                  <CardActionArea
                    onClick={selectAffiliateQuote}
                    disabled={selectedQuoteType === QUOTE_TYPES.AFFILIATE ?? null}
                  >
                    <Box px={3} py={2} display="flex" justifyContent="space-between">
                      <Typography variant="h5">Affiliate</Typography>
                      {selectedQuoteType === QUOTE_TYPES.AFFILIATE ? <CheckCircle color="primary" /> : null}
                    </Box>
                    <Divider />
                  </CardActionArea>
                  <CardContent>
                    <Box display="flex" justifyContent="center">
                      <SelectInput
                        disabled={selectedQuoteType !== QUOTE_TYPES.AFFILIATE ?? null}
                        source="affiliate_id"
                        choices={affiliates?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) ?? []}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PartnerAffiliate;
