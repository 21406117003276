import React from 'react';

import { BooleanField, Datagrid, TextField } from 'react-admin';

import { Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

const AdminProductList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Products</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={resources.PRODUCTS}
      exporter={false}
      empty={<EmptyResourceList text="No Products" />}
      storeKey={`${resources.PRODUCTS}.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" emptyText="N/A" />
        <TextField source="product_id" emptyText="N/A" />
        <TextField source="name" emptyText="N/A" />
        <TextField source="distance_type" emptyText="N/A" />
        <TextField source="family" emptyText="N/A" />
        <BooleanField source="is_active" label="isActive?" />
      </Datagrid>
    </List>
  </Card>
);

export default AdminProductList;
