import React from 'react';

import { BooleanField, DateField, ReferenceField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

import { Avatar, Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { PRO_ACCOUNT_DETAIL_SECTION, PRO_ACCOUNT_TAB } from '@/constants/prosAccount';
import MarketNameField from '@/fields/MarketNameField';
import * as resources from '@/api/resources';

import ProsDetailsSectionHeader from '../Common/SectionHeader';

const ProsDetailsOverviewSection = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card>
      <ProsDetailsSectionHeader
        title="Overview"
        tab={PRO_ACCOUNT_TAB.DETAILS}
        section={PRO_ACCOUNT_DETAIL_SECTION.OVERVIEW}
      />

      <Divider />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <SimpleShowLayout>
              <Avatar
                component={Box}
                src={record.image_url}
                sx={{
                  width: 100,
                  height: 100,
                }}
              />
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="first_name" emptyText="N/A" />
              <TextField source="last_name" emptyText="N/A" />
              <ReferenceField label="Market Manager" source="primary_market" reference={resources.MARKETS} emptyText="N/A">
                <ReferenceField
                  reference={resources.USERS}
                  source="market_manager"
                  sortable={false}
                  link={false}
                  emptyText="N/A"
                >
                  <TextField source="name" emptyText="N/A" />
                </ReferenceField>
              </ReferenceField>
              <BooleanField source="exclude_from_performance_management" emptyText="N/A" />
              <ReferenceField source="company_id" reference="accounts" label="Company" emptyText="N/A">
                <TextField source="name" emptyText="N/A" />
              </ReferenceField>
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField source="status" emptyText="N/A" />
              <MarketNameField source="primary_market" />
              {record.is_admiral ? <DateField source="admiral_effective_date" emptyText="N/A" /> : null}
              <BooleanField source="poor_performance" emptyText="N/A" />
              {record.status === 'Inactive' ? <TextField source="inactive_reason" emptyText="N/A" /> : null}
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={3}>
            <SimpleShowLayout>
              <DateField source="joined_date" emptyText="N/A" />
              <TextField source="account_record_type_name" emptyText="N/A" />
              {record.is_admiral ? <DateField source="admiral_end_date" emptyText="N/A" /> : null}
              <TextField source="referred_by_text" emptyText="N/A" label="Referred by" />
              {record.status === 'Inactive' ? <TextField source="inactive_reason_text" emptyText="N/A" /> : null}
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProsDetailsOverviewSection;
