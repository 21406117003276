import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { string } from 'prop-types';
import {
  CreateBase,
  DateInput,
  Form,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPES_DISPLAY, SERVICES } from '@/constants/pricingLayers';
import { useCustomParams } from '@/hooks';

const FormControls = ({ layerType }) => {
  const redirect = useRedirect();

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="neutral"
          onClick={() => redirect(`/${resources.PRICING_LAYERS}?view=${layerType}`)}
        >
          Cancel
        </Button>
        <SaveButton label="Save" icon={null} />
      </CardContent>
    </Card>
  );
};

FormControls.propTypes = {
  layerType: string.isRequired,
};

const formatValue = (value) => (value / 100).toFixed(2);
const parseValue = (value) => Math.round(parseFloat(value) * 100);

const DateLayerInputs = [
  <Grid item xs={3} />,
  <Grid item xs={3}>
    <ReferenceInput
      required
      source="market_id"
      reference={resources.MARKETS}
      perPage={999}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput fullWidth optionText="name" />
    </ReferenceInput>
  </Grid>,
  <Grid item xs={2}>
    <NumberInput source="price" format={formatValue} parse={parseValue} />
  </Grid>,
  <Grid item xs={2}>
    <NumberInput source="multiplier" min={0} />
  </Grid>,
];

const InputsByLayerType = {
  [PRICING_LAYER_TYPES.SERVICE]: [
    <Grid item xs={3}>
      <ReferenceInput
        required
        source="start_market_id"
        reference={resources.MARKETS}
        perPage={999}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput fullWidth optionText="name" />
      </ReferenceInput>
    </Grid>,
    <Grid item xs={3}>
      <ReferenceInput
        required
        source="end_market_id"
        reference={resources.MARKETS}
        perPage={999}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput fullWidth optionText="name" />
      </ReferenceInput>
    </Grid>,
    <Grid item xs={2}>
      <NumberInput source="base_price" format={formatValue} parse={parseValue} required min={0} defaultValue={0} />
    </Grid>,
    <Grid item xs={2}>
      <NumberInput source="per_worker_price" format={formatValue} parse={parseValue} required min={0} defaultValue={0} />
    </Grid>,
    <Grid item xs={2}>
      <NumberInput source="fixed_price_modifier" required min={0} defaultValue={1} />
    </Grid>,
    // TODO: Currently bugged on core - api
    // < Grid item xs = { 6} >
    // <SelectArrayInput
    //   fullWidth
    //   label="Partners"
    //   source="partner_id"
    //   choices={[
    //     { id: 'ALL_MY_SONS', name: 'All My Sons' },
    //     { id: 'COOPER', name: 'Mr. Cooper' },
    //     { id: 'CUBESMART', name: 'Cubesmart' },
    //     { id: 'HAVENLY', name: 'Havenly Inc' },
    //     { id: 'HELLO_ALFRED', name: 'Hello Alfred' },
    //     { id: 'MOVED', name: 'Moved.com' },
    //     { id: 'OFFERPAD', name: 'Offerpad' },
    //     { id: 'PODS', name: 'PODS' },
    //     { id: 'Recruit_Certs', name: 'Recruit Certification' },
    //     { id: 'ReloSolutionsGroup', name: 'Relo Solutions Group' },
    //     { id: 'SHYFT', name: 'Shyft' },
    //     { id: 'WALKBOARD', name: 'Walkboard' },
    //     { id: 'VIRTUO', name: 'Virtuo' },
    //     { id: 'ZIPPY', name: 'Zippyshell' },
    //     { id: 'ZIPPYCORPRELO', name: 'Zippy Corp Relo' },
    //   ]}
    // />
    // </Grid >
  ],
  [PRICING_LAYER_TYPES.DAY_OF_MONTH]: [
    ...DateLayerInputs,
    <Grid item xs={6} />,
    <Grid item xs={2}>
      <NumberInput source="day_of_month" required min={1} max={31} />
    </Grid>,
  ],
  [PRICING_LAYER_TYPES.DAY_OF_WEEK]: [
    ...DateLayerInputs,
    <Grid item xs={6} />,
    <Grid item xs={2}>
      <NumberInput source="day_of_week" required min={1} max={7} />
    </Grid>,
  ],
  [PRICING_LAYER_TYPES.MONTH_OF_YEAR]: [
    ...DateLayerInputs,
    <Grid item xs={6} />,
    <Grid item xs={2}>
      <NumberInput source="month_of_year" required min={1} max={12} />
    </Grid>,
  ],
  [PRICING_LAYER_TYPES.HOUR_OF_DAY]: [
    ...DateLayerInputs,
    <Grid item xs={6} />,
    <Grid item xs={2}>
      <NumberInput source="hour_of_day" required min={1} max={12} />
    </Grid>,
  ],
  [PRICING_LAYER_TYPES.LEAD_TIME]: [
    ...DateLayerInputs,
    <Grid item xs={6} />,
    <Grid item xs={2}>
      <NumberInput source="lead_time_in_days" required min={0} />
    </Grid>,
  ],
};

const PricingLayersCreate = () => {
  const { view } = useCustomParams();
  const navigate = useNavigate();
  const redirect = useRedirect();
  const notify = useNotify();

  const validView = Object.values(PRICING_LAYER_TYPES).includes(view);

  const [layerType, setLayerType] = useState(validView ? view : PRICING_LAYER_TYPES.SERVICE);

  const transform = (data) => ({
    ...data,
    type: layerType,
    effective_date: dayjs(data.effective_date).format('YYYY-MM-DD'),
  });

  useEffect(() => {
    if (validView) {
      setLayerType(view);
    } else {
      navigate(`/${resources.PRICING_LAYERS}/create?view=${PRICING_LAYER_TYPES.SERVICE}`, { replace: true });
    }
  }, [validView, view]);

  return (
    <CreateBase
      resource={`${resources.PRICING_LAYERS}`}
      mutationOptions={{
        onSuccess: () => redirect(`/${resources.PRICING_LAYERS}/?view=${layerType}`),
        onError: () => notify('Error occurred creating pricing layer', { type: 'error' }),
      }}
      transform={transform}
    >
      <Form>
        <Card component={Box} mt={3}>
          <CardHeader title={`Add ${PRICING_LAYER_TYPES_DISPLAY[layerType]} Pricing Layer`} />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <DateInput source="effective_date" required defaultValue={Date.now()} />
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  required
                  label="Service"
                  autoComplete="off"
                  source="service_id"
                  choices={Object.values(SERVICES)}
                />
              </Grid>
              {InputsByLayerType[layerType]}
            </Grid>
          </CardContent>
        </Card>
        <FormControls layerType={layerType} />
      </Form>
    </CreateBase>
  );
};

export default PricingLayersCreate;
