import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, useCreate, useNotify, useRefresh, useShowContext } from 'react-admin';

import { Box, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

import TipsForm from './TipsForm';

const AddTips = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record: { id: orderId, confirmation_id: confirmationId } = {} } = useShowContext();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = (submittedData) => {
    // react hook form stores tips with job slot ID as the key, transform this to a standard array of objects [{ id, amount }]
    // also multiply user entry by 100 to send cents to API
    // Filter null values after map
    const tipsData = Object.keys(submittedData.tips)
      .map((slotId) => {
        if (Number(submittedData.tips[slotId].amount) > 0) {
          return { job_slot_code: slotId, tip_value: parseInt(Number(submittedData.tips[slotId].amount) * 100, 10) };
        }
        return null;
      })
      .filter((tip) => Boolean(tip));

    create(
      resources.ORDERS,
      {
        meta: {
          subResource: 'tips',
          resourceId: orderId,
        },
        data: {
          tips: tipsData,
        },
      },
      {
        onSettled: (data, error) => {
          if (error) {
            notify(`Error adding tips - ${error.status} - ${error.message}`, { type: 'error' });
          } else if (data) {
            notify('Tips added successfully', {
              type: 'success',
            });
            mx.track('Order Management - Tips created', {
              resource: resources.ORDERS,
              resourceId: orderId,
              confirmationId,
            });
            refresh();
            setAction(null);
          }
        },
      },
    );
  };

  if (!orderId) return null;

  return (
    <Box px={2} py={4} minWidth={500}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Tips
      </Typography>
      <Divider />
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Form onSubmit={onSubmit} record={{ tips: [] }}>
          <TipsForm />
        </Form>
      )}
    </Box>
  );
};

export default AddTips;
