import React, { useContext } from 'react';

import { usePermissions, useShowContext } from 'react-admin';

import { Box, Button } from '@mui/material';

import { OrderActionContext } from '@/providers/OrderAction';

const BookDraftOrderButton = () => {
  const {
    record: { status: orderStatus },
  } = useShowContext();

  const { permissions } = usePermissions();

  const isDraftOrder = orderStatus === 'DRAFT';
  const canBookDraftOrder = permissions?.tools?.hqadmin?.ld_orders?.create && isDraftOrder;

  const { setAction } = useContext(OrderActionContext);

  const handleButtonClick = () => {
    setAction('book_draft_order');
  };

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {isDraftOrder && canBookDraftOrder ? (
        <Button onClick={handleButtonClick} variant="outlined">
          Book Draft Order
        </Button>
      ) : null}
    </Box>
  );
};

export default BookDraftOrderButton;
