import React from 'react';

import { string } from 'prop-types';

import { Box } from '@mui/material';

import { MARKET_TAB } from '@/constants/market';

import MarketBasePay from './Basepay';
import MarketDetails from './Details';
import MarketPerformanceThresholds from './PerformanceThresholds';
import MarketServiceArea from './ServiceArea';
import MarketTravelPay from './TravelPay';
import MarketProduct from './MarketProduct';

const MarketsContent = ({ currentTab }) => {
  let content;
  switch (currentTab) {
    case MARKET_TAB.DETAILS:
      content = <MarketDetails />;
      break;
    case MARKET_TAB.PERFORMANCE_THRESHOLDS:
      content = <MarketPerformanceThresholds />;
      break;
    case MARKET_TAB.TRAVEL_PAY:
      content = <MarketTravelPay />;
      break;
    case MARKET_TAB.SERVICE_AREA:
      content = <MarketServiceArea />;
      break;
    case MARKET_TAB.BASE_PAY:
      content = <MarketBasePay />;
      break;
    case MARKET_TAB.MARKET_PRODUCT:
      content = <MarketProduct />;
      break;
    default:
      content = <>Unhandled</>;
  }
  return <Box>{content}</Box>;
};

MarketsContent.propTypes = {
  currentTab: string.isRequired,
};

export default MarketsContent;
