import React from 'react';

import { Labeled, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CARRIER_ACCOUNT_DETAIL_SECTION, CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';

import CarrierDetailsSectionHeader from '../Common/SectionHeader';

const Authority = () => (
  <Card mt={3} component={Box}>
    <CarrierDetailsSectionHeader
      title="Authority"
      tab={CARRIER_ACCOUNT_TAB.DETAILS}
      section={CARRIER_ACCOUNT_DETAIL_SECTION.AUTHORITY}
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="broker_authority" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="common_authority" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="contract_authority" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField label="DOT Authority" source="dot_authority" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="insurance_authority_updated" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="liability_expiration_date" emptyText="N/A" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default Authority;
