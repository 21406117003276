import React from 'react';

import { BooleanField, Labeled, ReferenceField, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_DETAIL_SECTION, MARKET_TAB } from '@/constants/market';
import LocationFormatField from '@/fields/LocationFormatField';

import DetailsSectionHeader from '../Common/SectionHeader';

const PrimaryInformation = () => (
  <Card component={Box}>
    <DetailsSectionHeader
      title="Primary Information"
      tab={MARKET_TAB.DETAILS}
      section={MARKET_DETAIL_SECTION.PRIMARY_INFORMATION}
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="name" label="Name" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="id" label="Code" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <ReferenceField
              reference={resources.USERS}
              source="market_manager"
              sortable={false}
              link={false}
              emptyText="N/A"
            >
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <ReferenceField
              reference={resources.USERS}
              source="pro_recruiting_specialist"
              label="Recruit Specialist"
              sortable={false}
              link={false}
              emptyText="N/A"
            >
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="status" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="market_type" label="Type" />
          </Labeled>
        </Grid>
        <Grid item xs={2}>
          <Labeled label="PODS Enabled">
            <BooleanField source="is_pod_available" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={2}>
          <Labeled label="Marketplace">
            <BooleanField source="is_marketplace" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={2}>
          <Labeled label="Labor Only">
            <BooleanField source="is_labor_only" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="region" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled label="City Center Address">
            <LocationFormatField
              street1="market_center_street_1"
              street2="market_center_street_2"
              city="market_center_city"
              state="market_center_state"
              zip="market_center_zip"
            />
          </Labeled>
        </Grid>
        {/* Temp removing - not currently returned by core API endpoint
        <Grid item xs={4}>
          <Labeled>
            <TextField source="payout_provider" emptyText="N/A" />
          </Labeled>
        </Grid> */}
      </Grid>
    </CardContent>
  </Card>
);

export default PrimaryInformation;
