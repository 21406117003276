const ITEMS_CATEGORY = 'Items';
const ROOMS_CATEGORY = 'Rooms';
const ASSEMBLY_CATEGORY = 'Assembly';

const INVENTORY_CATEGORIES = [ITEMS_CATEGORY, ROOMS_CATEGORY, ASSEMBLY_CATEGORY];

const KNOWN_INVENTORY_KEYS = {
  [ITEMS_CATEGORY]: ['beds', 'tables', 'boxes', 'tables', 'bookcases', 'dressers', 'televisions', 'couches'],
  [ROOMS_CATEGORY]: ['bedrooms'],
  [ASSEMBLY_CATEGORY]: ['assemblyBeds', 'assemblyOther'],
};

const EXCLUDE_INVENTORY_KEYS = ['newMovers', 'newHours', 'numberPeople', 'yearsSpent'];

const INVENTORY_LABELS = {
  assemblyBeds: 'Beds',
  assemblyOther: 'Other',
};

const SPECIAL_INVENTORY_TYPES = ['OFFERPAD', 'ZIPPY'];

const INVENTORY_AREA_CHOICES = [
  {
    id: 1000,
    name: '1 - 1,000',
  },
  {
    id: 2000,
    name: '1,001 - 2,000',
  },
  {
    id: 3500,
    name: '2,001 - 3,500',
  },
  {
    id: 5000,
    name: '3,501 - 5,000',
  },
  {
    id: 5001,
    name: '5,001 +',
  },
];

const DEFAULT_INVENTORY_NOTES = `TVs:\nBoxes:\nAdditional Services:\n`;

export {
  DEFAULT_INVENTORY_NOTES,
  INVENTORY_CATEGORIES,
  INVENTORY_AREA_CHOICES,
  INVENTORY_LABELS,
  KNOWN_INVENTORY_KEYS,
  EXCLUDE_INVENTORY_KEYS,
  SPECIAL_INVENTORY_TYPES,
};
