import React from 'react';

import { DateField, EmailField, FunctionField, Labeled, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION, THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import toPascalCase from '@/utils/toPascalCase';

import ThirdPartyCrewContentDetailsSectionHeader from '../Common/SectionHeader';

const ThirdPartyCrewDetailsContactInfoSection = () => (
  <Card mt={3} component={Box}>
    <ThirdPartyCrewContentDetailsSectionHeader
      title="Contact Info"
      tab={THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS}
      section={THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.CONTACT_INFO}
    />

    <Divider />

    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="phone" label="Phone Number" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <EmailField source="email" label="Email Address" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <DateField source="birthday" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="tshirt_size" label="T-shirt Size" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <FunctionField
              label="Mailing Address"
              render={({ location }) => (
                <TextFieldWrapper
                  value={formatAddressParts({
                    ...location,
                    line_1: location.street_address_1 ? toPascalCase(location.street_address_1) : '',
                    line_2: location.street_address_2 ? toPascalCase(location.street_address_2) : '',
                    city: toPascalCase(location.city),
                  })}
                />
              )}
            />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ThirdPartyCrewDetailsContactInfoSection;
