import React, { useCallback, useState } from 'react';
import { LoadingIndicator, Logout, useGetIdentity, useStore, useTheme } from 'react-admin';

import { DarkMode, LightMode, Timer, TimerOff } from '@mui/icons-material';
import { AppBar, Avatar, Box, IconButton, Menu, Tooltip, Typography, useTheme as useMuiTheme } from '@mui/material';

import { PREFERENCES_KEYS, PREFERENCES_VALUES } from '@/constants/preferences';

const MyAppBar = () => {
  const { identity } = useGetIdentity();
  const [themeMode, setTheme] = useTheme();
  const lightMode = Boolean(themeMode === 'light');
  const { palette } = useMuiTheme();

  const [timePreference, setTimePreference] = useStore(
    PREFERENCES_KEYS.UI.TIME_PREFERENCE,
    PREFERENCES_VALUES.UI.TIME_PREFERENCE.USER,
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const open = Boolean(anchorEl);

  return (
    <AppBar
      elevation={0}
      sx={{
        position: 'static',
        backgroundColor: lightMode ? palette.neutral.lightest : palette.secondary.darker,
        color: lightMode ? palette.neutral.darkest : palette.neutral.lightest,
      }}
    >
      <Box
        minHeight={48}
        display="flex"
        sx={{
          borderBottom: `2px solid ${lightMode ? palette.neutral.light : palette.neutral.dark}`,
        }}
      >
        <Box display="flex" alignItems="center" py={1}>
          <Typography variant="h6" ml={5} mr={2}>
            HQ Admin
          </Typography>
        </Box>
        <Box ml="auto" mr={1} display="flex" alignItems="center" py={1.5} pr={1}>
          <Tooltip
            title={
              timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL ? 'Switch to My time' : 'Switch to Local time'
            }
          >
            <IconButton
              color="inherit"
              onClick={() =>
                setTimePreference(
                  timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL
                    ? PREFERENCES_VALUES.UI.TIME_PREFERENCE.USER
                    : PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL,
                )
              }
            >
              {timePreference === PREFERENCES_VALUES.UI.TIME_PREFERENCE.LOCAL ? <Timer /> : <TimerOff />}
            </IconButton>
          </Tooltip>
          <Tooltip title={lightMode ? 'Switch to Dark mode' : 'Switch to Light mode'}>
            <IconButton color="inherit" onClick={() => setTheme(lightMode ? 'dark' : 'light')}>
              {lightMode ? <DarkMode /> : <LightMode />}
            </IconButton>
          </Tooltip>
          <LoadingIndicator />
          <Box display="flex" alignItems="center">
            <Tooltip title="User Menu">
              <IconButton onClick={handleMenu}>
                {identity?.avatar ? <Avatar src={identity.avatar} alt={identity.fullName} /> : null}
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            id="menu-appbar"
            disableScrollLock
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <Logout />
          </Menu>
        </Box>
      </Box>
    </AppBar>
  );
};

export default MyAppBar;
