export default () => ({
  styleOverrides: {
    root: {
      '&.no-x-padding': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
});
