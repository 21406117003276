import React, { useContext, useState } from 'react';

import { Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';
import ThirdPartyMoverList from './ThirdPartyMoverList';

const ConvertOrderToBhn = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record } = useShowContext();
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [update, { isLoading: isUpdating }] = useUpdate();

  const refresh = useRefresh();
  const notify = useNotify();

  const handleSubmit = () => {
    const onSettled = (data, error) => {
      if (error) {
        notify(`${selectedAccount.name} assigned failed`, { type: 'error' });
      } else if (data) {
        notify(`${selectedAccount.name} assigned`, { type: 'success' });
        refresh();
        setAction(null);
      }
    };

    update(
      `${resources.ORDERS}/${record.id}/bhn/convertobhn`,
      {
        id: selectedAccount.id,
        previousData: {},
      },
      { onSettled },
    );
  };

  return (
    <Box px={6} py={4} width={750}>
      <Typography variant="h4" component={Box} pb={4}>
        Assign Third Party Crew for Order: {record.confirmation_id}
      </Typography>
      <Box>
        <Box mt={2}>Please select a crew.</Box>
        <ThirdPartyMoverList setSelectedAccount={setSelectedAccount} />
        <Form onSubmit={handleSubmit}>
          {selectedAccount ? <Box mt={2}>You have selected: {selectedAccount?.name}</Box> : ''}
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isUpdating ?? null}
              endIcon={isUpdating ? <CircularProgress size={18} /> : null}
            >
              Submit
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default ConvertOrderToBhn;
