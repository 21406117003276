import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import mx from 'mixpanel-browser';
import {
  BooleanInput,
  DateInput,
  EditBase,
  Form,
  Labeled,
  minValue,
  NumberInput,
  SaveButton,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useReference,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import SlotTransactionFamilyField from '@/fields/SlotTransactionFamilyField';
import SlotTransactionReasonField from '@/fields/SlotTransactionReasonField';
import getQualificationLevel from '@/utils/qualificationLevel/getQualificationLevel';
import getServiceName from '@/utils/serviceName/getServiceName';

const JobSlotInformation = () => {
  const record = useRecordContext();

  const { data: jobSlotData } = useGetOne(
    resources.JOB_SLOTS,
    {
      id: record?.job_slot_id,
    },
    { enabled: Boolean(record?.job_slot_id) },
  );
  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: jobSlotData?.job_id,
    options: { enabled: Boolean(jobSlotData?.job_id) },
  });

  useEffect(() => {
    mx.track('Resource Create - Viewed', {
      resource: resources.JOB_SLOT_BONUS,
    });
  }, []);

  return (
    <Card>
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">Edit Job Slot Transaction</Typography>
        <Typography variant="h5">{`${getServiceName(jobData?.product_id)} - ${getQualificationLevel(
          jobSlotData?.minimum_qualification_level,
        )} - ${dayjs(jobData?.start_date_time).format('MMM D, YYYY @ h:mm A')}`}</Typography>
      </Box>
    </Card>
  );
};

const TransactionEditForm = () => {
  const record = useRecordContext();
  const { data: jobSlotData, isLoading: isJobSlotLoading } = useGetOne(
    resources.JOB_SLOTS,
    {
      id: record?.job_slot_id,
    },
    { enabled: Boolean(record?.job_slot_id) },
  );

  const { permissions } = usePermissions();

  const canLockTransaction = permissions?.tools?.hqadmin?.job_slot_transactions?.locked;

  if (!record || (!jobSlotData && isJobSlotLoading)) return <CircularProgress />;

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <>
      <Box width={250} p={2} display="flex" justifyContent="space-between">
        <Labeled label="Transaction Family">
          <SlotTransactionFamilyField source="family" />
        </Labeled>
        <Labeled label="Reason">
          <SlotTransactionReasonField source="reason" />
        </Labeled>
      </Box>
      <Box width={250} p={2}>
        <NumberInput source="amount" format={formatValue} parse={parseValue} required fullWidth min={0} max={10000} />
        <DateInput required source="date_to_process" validate={minValue(dayjs().format('YYYY-MM-DD'))} />
        <BooleanInput source="auto_capture" />
        {canLockTransaction ? <BooleanInput source="locked" /> : null}
      </Box>
      <Divider />
      <Box p={2} textAlign="right">
        <SaveButton />
      </Box>
    </>
  );
};

const JobSlotTransactionEdit = () => {
  const { jobSlotId, transactionId } = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = (data) => {
    mx.track('Resource - Updated', {
      resource: resources.JOB_SLOT_TRANSACTIONS,
      resourceId: data.id,
      metadata: {
        family: data.family,
        reason: data.reason,
      },
    });
    if (data.locked) {
      mx.track('Order Management - Job Slot Transaction Locked', {
        resource: resources.JOB_SLOT_TRANSACTIONS,
        resourceId: data.id,
        metadata: {
          job_slot_id: data.job_slot_id,
          family: data.family,
          reason: data.reason,
        },
      });
    }
    notify('Slot transaction updated', { type: 'success' });
    refresh();
    redirect('show', resources.JOB_SLOTS, jobSlotId);
  };

  const onError = (error) => {
    notify(`Error updating slot transaction - ${error.status} - ${error.message}`, { type: 'error' });
  };

  const transform = (data) => ({
    ...data,
    job_slot_id: jobSlotId,
  });

  const validate = (data) => {
    const errors = {};

    if (dayjs(data.date_to_process).day() !== 2) {
      errors.date_to_process = 'Date must be a Tuesday';
    }

    if (dayjs(data.date_to_process).isBefore(dayjs())) {
      errors.date_to_process = 'Date must be in the future';
    }

    return errors;
  };

  return (
    <EditBase
      resource={resources.JOB_SLOT_TRANSACTIONS}
      id={transactionId}
      queryOptions={{ meta: { filter: { job_slot_id: jobSlotId } } }}
      mutationMode="pessimistic"
      mutationOptions={{ meta: { forcedData: { job_slot_id: jobSlotId } }, onSuccess, onError }}
      transform={transform}
      redirect={false}
    >
      <Box my={2}>
        <JobSlotInformation />
      </Box>
      <Card>
        <Form validate={validate}>
          <TransactionEditForm />
        </Form>
      </Card>
    </EditBase>
  );
};

export default JobSlotTransactionEdit;
