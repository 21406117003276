import * as resources from '@/api/resources';

import StripeCustomerEdit from './Edit';
import StripeCustomerList from './List';
import StripeCustomerShow from './Show';

const stripeCustomers = () => ({
  name: resources.ADMIN_STRIPE_CUSTOMERS,
  edit: StripeCustomerEdit,
  list: StripeCustomerList,
  show: StripeCustomerShow,
});

export default stripeCustomers;
