import React from 'react';

import { Form, required, SaveButton, SelectInput, ShowButton, useNotify, useRecordContext, useRedirect } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PAYOUT_CONFIG, PAYOUT_PROVIDER } from '@/constants/account';

const ProsDetailsPaymentEdit = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const onError = ({ message, status }) => {
    notify(`Error updating account - ${status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    notify(`Account updated`, {
      type: 'success',
    });
    redirect('show', resources.ACCOUNTS, record?.id);
  };

  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Payment" />

      <Divider />
      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectInput
                source="payout_provider"
                label="Payout Provider"
                validate={[required()]}
                choices={PAYOUT_PROVIDER}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="account_payout_config"
                label="Payout config"
                choices={Object.values(PAYOUT_CONFIG)}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <ShowButton label="Cancel" icon={null} color="secondary" />
            <SaveButton label="Update" icon={null} mutationOptions={{ onError, onSuccess }} type="button" />
          </Box>
        </CardContent>
      </Form>
    </Card>
  );
};

export default ProsDetailsPaymentEdit;
