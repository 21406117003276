import React from 'react';

import { func, shape, string } from 'prop-types';
import { Form, SaveButton } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import InputFieldSection from '../InputFieldSection';
import InputFilterSection from '../InputFilterSection';
import SupplyInventorySectionHeader from '../SectionHeader';
import SelectNavigationField from '../SelectNavigationField';

const SupplyInventoryFormFrame = ({
  onSubmit,
  setfilterFormValues,
  filterformValues,
  FormValues,
  setFormValues,
  handleChange,
  title,
}) => (
  <Card mt={3} component={Box}>
    <SupplyInventorySectionHeader title={`Create Supply Inventory for ${title}`} />

    <Divider />

    <CardContent>
      <Form onSubmit={onSubmit}>
        <Grid mt={1} container display="flex">
          <Grid item xs={12} mb={1}>
            <Typography variant="h5">Switch Worker Type</Typography>
          </Grid>

          <SelectNavigationField />

          <Grid container display="flex">
            <Grid item xs={12}>
              <InputFilterSection
                obj={filterformValues}
                handleChange={(e) => handleChange(e, setfilterFormValues)}
                setFormValues={setfilterFormValues}
              />
            </Grid>
          </Grid>

          <Grid mt={2} container display="flex">
            <Grid item xs={4}>
              <InputFieldSection obj={FormValues} handleChange={(e) => handleChange(e, setFormValues)} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <SaveButton label="Save" icon={null} />
        </Grid>
      </Form>
    </CardContent>
  </Card>
);

SupplyInventoryFormFrame.propTypes = {
  onSubmit: func.isRequired,
  setfilterFormValues: func.isRequired,
  filterformValues: shape({}).isRequired,
  FormValues: shape({}).isRequired,
  setFormValues: func.isRequired,
  handleChange: func.isRequired,
  title: string.isRequired,
};

export default SupplyInventoryFormFrame;
