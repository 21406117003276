import React from 'react';

import { DateField, Labeled, NumberField, TextField, useRecordContext } from 'react-admin';

import { Avatar, Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION, THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';
import MarketNameField from '@/fields/MarketNameField';

import ThirdPartyCrewDetailsSectionHeader from '../Common/SectionHeader';

const ThirdPartyCrewDetailsOverviewSection = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card>
      <ThirdPartyCrewDetailsSectionHeader
        title="Overview"
        tab={THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS}
        section={THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.OVERVIEW}
      />

      <Divider />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2} display="flex" justifyContent="center" mt={1}>
            <Avatar
              component={Box}
              src={record.image_url}
              sx={{
                width: 100,
                height: 100,
              }}
            />
          </Grid>

          <Grid item xs={10} container spacing={2}>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="name" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <TextField source="market_manager" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <TextField source="status" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <MarketNameField source="primary_market" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <NumberField source="truck_count" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <DateField source="joined_date" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <TextField source="account_record_type_name" emptyText="N/A" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDetailsOverviewSection;
