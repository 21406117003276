import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import CurrencyInput from '../CurrencyInput';
import { FIELD_NAMES, TARGET_OPTIONS } from './constants';

const TipsTarget = () => {
  const { control, getValues, setValue, watch } = useFormContext();

  const tipsTargetOption = watch(FIELD_NAMES.TARGET_OPTION);
  const tipsTargetValue = watch(FIELD_NAMES.TARGET_VALUE);

  const targetValueDisabled = tipsTargetOption === TARGET_OPTIONS.INDIVIDUAL || tipsTargetOption === '';

  const updateTipFields = () => {
    const slotIds = Object.keys(getValues().tips);
    const targetValue = Number(tipsTargetValue) * 100;

    switch (tipsTargetOption) {
      case TARGET_OPTIONS.SPLIT: {
        const splitAmount = Math.floor(targetValue / slotIds.length) / 100;
        slotIds.forEach((slot) => setValue(`tips.${slot}.amount`, splitAmount.toFixed(2)));
        break;
      }
      case 'even-amount': {
        const evenAmount = targetValue / 100;
        slotIds.forEach((slot) => setValue(`tips.${slot}.amount`, evenAmount.toFixed(2)));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    updateTipFields();
  }, [tipsTargetOption, tipsTargetValue]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      <Controller
        name={FIELD_NAMES.TARGET_OPTION}
        defaultValue=""
        control={control}
        render={({ field }) => (
          <FormControl>
            <RadioGroup {...field} row onChange={(event, value) => field.onChange(value)} value={field.value}>
              <FormControlLabel value={TARGET_OPTIONS.SPLIT} control={<Radio />} label="Split" />
              <FormControlLabel value={TARGET_OPTIONS.EVEN} control={<Radio />} label="Even" />
              <FormControlLabel value={TARGET_OPTIONS.INDIVIDUAL} control={<Radio />} label="Individual" />
            </RadioGroup>
          </FormControl>
        )}
      />
      <Box maxWidth={100}>
        <Controller
          defaultValue=""
          name={FIELD_NAMES.TARGET_VALUE}
          control={control}
          render={({ field }) => <CurrencyInput field={field} disabled={targetValueDisabled} />}
        />
      </Box>
    </Box>
  );
};

export default TipsTarget;
