import React from 'react';

import { useParams } from 'react-router';

import { MARKET_PERFORMANCE_THRESHOLDS_SECTION } from '@/constants/market';

import MarketPerformanceThresholdsThresholdsEdit from './Thresholds';

const MarketPerformanceThresholdsEdit = () => {
  const { section: currentSection } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case MARKET_PERFORMANCE_THRESHOLDS_SECTION.THRESHOLDS:
      EditComponent = <MarketPerformanceThresholdsThresholdsEdit />;
      break;

    default:
      break;
  }

  return EditComponent;
};

export default MarketPerformanceThresholdsEdit;
