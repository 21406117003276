import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import mx from 'mixpanel-browser';
import { bool } from 'prop-types';
import {
  CreateBase,
  Form,
  ReferenceField,
  ShowButton,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  usePermissions,
  useRedirect,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import HiddenInput from '@/components/inputs/HiddenInput';
import { DEFAULT_INVENTORY_NOTES } from '@/constants/inventory';
import { MARKET_TIMEZONES } from '@/constants/marketTimezones';
import { QUOTE_TYPES } from '@/constants/quoteTemplates';
import { INVENTORY_ADDITIONAL_ITEMS, INVENTORY_ROOMS } from '@/constants/quotes';
import { QuoteProvider } from '@/providers/Quote';
import GoogleMapsScript from '@/shared/GoogleMapsScript';

import Inventory from './Inventory';
import Locations from './Locations';
import PartnerAffiliate from './PartnerAffiliate';
import PreferredDate from './PreferredDate';
import ScriptModals from './ScriptModals';
import Templates from './Templates';
import quoteDataTransform from './quoteDataTransform';

dayjs.extend(utc);
dayjs.extend(tz);

const defaultValue = {
  selected_quote_type: QUOTE_TYPES.BELLHOP,
  customer_id: '',
  inventory: {
    area: '',
    intent: 'EVERYTHING',
    rooms: Object.values(INVENTORY_ROOMS).map((room) => ({
      slug: room.id,
      count: 0,
    })),
    access_flags: {
      stairs: 0,
      elevator: false,
      elevator_reserved: false,
      long_walk_to_truck: false,
    },
    additional_items: Object.values(INVENTORY_ADDITIONAL_ITEMS).map((item) => ({
      slug: item.id,
      count: 0,
    })),
  },
  service_groups: [],
  locations: [],
  selected_template: null,
  marketsData: [],
};

const FormControls = ({ isQuoteCreating }) => {
  const { getValues } = useFormContext();

  return (
    <Card component={Box} mt={3}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <ShowButton label="Cancel" icon={null} resource={resources.CUSTOMERS} record={{ id: getValues('customer_id') }} />
          <Button
            type="submit"
            variant="contained"
            disabled={isQuoteCreating ?? null}
            startIcon={isQuoteCreating ? <CircularProgress size={18} /> : null}
          >
            Create Quote
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

FormControls.propTypes = {
  isQuoteCreating: bool.isRequired,
};

const CustomerHeader = () => (
  <Card my={3} component={Box}>
    <Box display="flex" gap={1} px={3} py={2}>
      <Typography variant="h5" component="span">
        Customer:
      </Typography>
      <ReferenceField reference={resources.CUSTOMERS} source="customer_id" label="Customer" link="show">
        <TextField variant="h5" source="name" />
      </ReferenceField>
    </Box>
  </Card>
);

const Notes = () => (
  <Card component={Box} mt={3}>
    <CardHeader title="Notes" />
    <Divider />
    <CardContent>
      <TextInput
        source="inventory.notes"
        label=""
        variant="outlined"
        multiline
        fullWidth
        minRows={6}
        maxRows={35}
        defaultValue={DEFAULT_INVENTORY_NOTES}
      />
    </CardContent>
  </Card>
);

const CreateQuoteForm = () => {
  const { watch } = useFormContext();
  const { permissions } = usePermissions();

  const selectedTemplate = watch('selected_template');
  const selectedQuoteType = watch('selected_quote_type');
  const partner = watch('partner_id');
  const affiliate = watch('affiliate_id');

  const convertDraft = useWatch({ name: 'convert_draft' });

  const validPartnerAffiliate = selectedQuoteType !== QUOTE_TYPES.BELLHOP && (partner || affiliate);
  const validQuoteType = selectedQuoteType === QUOTE_TYPES.BELLHOP || validPartnerAffiliate;

  const canQuotePartnerAffiliate = permissions?.tools?.hqadmin?.internal_partner_booking?.view;

  return (
    <>
      {canQuotePartnerAffiliate ? <PartnerAffiliate /> : null}
      <Locations />
      {validQuoteType ? (
        <>
          {!convertDraft ? <Templates /> : null}
          {selectedTemplate ? <PreferredDate /> : null}
          {selectedTemplate?.inventoryRequired ? <Inventory /> : null}
          <Notes />
        </>
      ) : (
        <Card component={Box} mt={3}>
          <CardContent>
            <Typography textAlign="center">
              Select Bellhop or a specific {selectedQuoteType} to see available services
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

const QuoteCreate = () => {
  const location = useLocation();
  const notify = useNotify();
  const redirect = useRedirect();

  const [create, { isLoading: isCreateQuoteLoading }] = useCreate();

  const [isQuoteCreating, setIsQuoteCreating] = useState(false);

  const record = location?.state?.record;

  const validate = (data) => {
    const errors = {};

    const preferredStartDate = data.preferred?.start_date;
    const preferredStartTime = data.preferred?.start_time;
    const preferredEndDate = data.preferred?.end_date;
    const preferredEndTime = data.preferred?.end_time;

    if (preferredStartDate && preferredStartTime) {
      const timezone = MARKET_TIMEZONES[data.locations?.[0].location.market_id];
      const preferredStartDateTime = dayjs(data.preferred.start_date).hour(data.preferred.start_time).tz(timezone, true);
      if (preferredStartDateTime < dayjs()) {
        errors['preferred.start_date'] = 'Date / Time must be in the future';
      }
    }

    if (preferredStartDate && preferredStartTime && preferredEndDate && preferredEndTime) {
      const preferredStartDateTime = dayjs(data.preferred.start_date).hour(data.preferred.start_time);
      const preferredEndDateTime = dayjs(data.preferred.end_date).hour(data.preferred.end_time);

      if (preferredStartDateTime.isSame(preferredEndDateTime)) {
        errors['preferred.end_date'] = 'Loading and Unloading date / time must not be the same';
      }
    }

    return errors;
  };

  const onError = (error) => {
    notify(`Error - ${error.status} - ${error.message}`, { type: 'error' });
    mx.track('Internal Booking - Error creating Quote', {
      customerId: record?.customer_id,
      error,
    });
    setIsQuoteCreating(false);
  };

  const onSuccess = (data) => {
    mx.track('Resource - Created', {
      resource: resources.QUOTES,
      resourceId: data.id,
    });
    notify('Quote created', {
      type: 'success',
    });
    setIsQuoteCreating(false);
    redirect('edit', resources.QUOTES, data.id, {}, { record: { original_order_id: record?.original_order_id } });
  };

  const handleSubmit = (data) => {
    quoteDataTransform(data, create).then((transformedData) => {
      if (transformedData.error) {
        notify(`Error occurred creating quote - ${transformedData.error.status} - ${transformedData.error.message}`, {
          type: 'error',
        });
        setIsQuoteCreating(false);
        return;
      }
      create(resources.QUOTES, { data: transformedData }, { onSuccess, onError });
    });
  };

  useEffect(() => {
    mx.track('Resource Create - Viewed', {
      resource: resources.QUOTES,
    });
  }, []);

  // This record is used to prefill the form
  // Initialize with deep clone of defaultValue
  // Then merge with lodash, cannot merge on defaultValue as lodash merges in place and will cause data leak
  const mergedDefaultRecord = _.cloneDeep(defaultValue);

  _.merge(mergedDefaultRecord, record);

  return (
    <CreateBase record={mergedDefaultRecord}>
      <GoogleMapsScript />
      <QuoteProvider>
        <Form validate={validate} reValidateMode="onSubmit" onSubmit={handleSubmit}>
          <HiddenInput source="original_order_id" />
          <HiddenInput source="selected_template" />
          <HiddenInput source="selected_quote_type" />
          <HiddenInput source="locations" />
          <HiddenInput source="marketsData" />
          <CustomerHeader />
          <CreateQuoteForm />
          <ScriptModals />
          <FormControls isQuoteCreating={isQuoteCreating || isCreateQuoteLoading} />
        </Form>
      </QuoteProvider>
    </CreateBase>
  );
};

export default QuoteCreate;
