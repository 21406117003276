import React from 'react';

import { Form, SaveButton, SelectInput, ShowButton, Toolbar, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { THIRD_PARTY_CREW_PAYOUT_PROVIDERS } from '@/constants/thirdPartyCrewAccount';
import { PAYOUT_CONFIG } from '@/constants/account';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewDetailsPaymentEdit = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Payment" />

      <Divider />

      <CardContent>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectInput
                source="payout_provider"
                label="Payout provider"
                choices={THIRD_PARTY_CREW_PAYOUT_PROVIDERS}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="account_payout_config"
                label="Payout config"
                choices={Object.values(PAYOUT_CONFIG)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider />
          <CustomToolbar />
        </Form>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDetailsPaymentEdit;
