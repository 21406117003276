import React, { useContext, useState } from 'react';

import _ from 'lodash';
import mx from 'mixpanel-browser';
import { arrayOf, string } from 'prop-types';
import {
  Confirm,
  ListContextProvider,
  RecordContextProvider,
  useDelete,
  useGetList,
  useGetMany,
  useList,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
  useTheme,
} from 'react-admin';

import { AddCircleOutline, Business, Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_ACTION } from '@/constants/market';
import { MarketActionContext } from '@/providers/MarketAction';

const PartnerServiceabilityTooltip = ({ partners }) => {
  const { data: partnersData, isLoading } = useGetMany(
    resources.ACCOUNTS,
    {
      ids: partners,
    },
    {
      enabled: Boolean(partners),
    },
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box p={0.5}>
      <Typography>Partner Serviceability</Typography>
      <Box py={0.25}>
        <Divider />
      </Box>
      <Typography variant="body2" whiteSpace="pre-wrap">
        {partners
          ?.map((partnerId) => {
            const partnerData = partnersData?.find(({ id }) => id === partnerId);
            return partnerData?.name ?? partnerId;
          })
          .join(`\n`)}
      </Typography>
    </Box>
  );
};

PartnerServiceabilityTooltip.propTypes = {
  partners: arrayOf(string).isRequired,
};

const DeletePostalCode = () => {
  const { record: marketRecord } = useShowContext();
  const postalCode = useRecordContext();
  const { permissions } = usePermissions();
  const refresh = useRefresh();
  const notify = useNotify();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [deleteOne, { isLoading: isDeleting }] = useDelete();

  const onError = (error) => {
    mx.track('Market Management - Error deleting Postal Code', {
      marketId: marketRecord?.id,
      postalCode: postalCode?.postal_id,
      error,
    });
    let { message } = error;

    if (error.body?.detail) {
      message = error.body.detail;
    }
    notify(`Error deleting Postal Code - ${error.status} - ${message}`, {
      type: 'error',
    });
    setDeleteConfirmOpen(false);
  };

  const onSuccess = () => {
    mx.track('Market Management - Postal Code Deleted', {
      marketId: marketRecord?.id,
      postalCode: postalCode?.postal_id,
    });
    notify('Postal Code deleted', { type: 'success' });
    refresh();
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteOne(
      resources.MARKETS,
      {
        id: marketRecord?.id,
        meta: {
          subResource: 'postal_codes',
          resourceId: postalCode?.id,
        },
      },
      {
        mutationMode: 'pessimistic',
        onError,
        onSuccess,
      },
    );
  };

  const openDeleteConfirm = () => setDeleteConfirmOpen(true);
  const closeDeleteConfirm = () => setDeleteConfirmOpen(false);

  const canDeletePostalCodes = permissions?.tools?.hqadmin?.market_postal_codes?.delete;

  return canDeletePostalCodes ? (
    <>
      <IconButton size="small" color="accent" onClick={openDeleteConfirm}>
        <RemoveCircleOutline fontSize="small" />
      </IconButton>
      <Confirm
        isOpen={deleteConfirmOpen}
        loading={isDeleting}
        title={`Delete Postal Code ${postalCode.postal_id}`}
        content={
          <>
            <Typography>Are you sure you want to delete this postal code?</Typography>
            {isDeleting ? (
              <Box pt={2} textAlign="center">
                <CircularProgress />
              </Box>
            ) : null}
          </>
        }
        onConfirm={handleDeleteConfirm}
        onClose={closeDeleteConfirm}
      />
    </>
  ) : null;
};

const PostalCodeCard = () => {
  const { setAction, setMetadata } = useContext(MarketActionContext);
  const postalCode = useRecordContext();
  const { permissions } = usePermissions();

  const canUpdatePostalCodes = permissions?.tools?.hqadmin?.market_postal_codes?.update;

  const partnerServiceability = postalCode?.partner_serviceability?.filter((item) => !!item);

  return (
    <Card>
      <Box pl={2} pr={1} py={1} display="flex" gap={2} alignItems="center">
        <Typography variant="h6">{postalCode.postal_id}</Typography>
        {partnerServiceability && !_.isEmpty(partnerServiceability) ? (
          <Box display="flex" gap={0.5}>
            <Typography>{partnerServiceability.length}x</Typography>
            <Tooltip title={<PartnerServiceabilityTooltip partners={partnerServiceability} />}>
              <Business />
            </Tooltip>
          </Box>
        ) : null}
        <Box ml="auto">
          {canUpdatePostalCodes ? (
            <IconButton
              size="small"
              onClick={() => {
                setMetadata(postalCode);
                setAction(MARKET_ACTION.UPDATE_POSTAL_CODE);
              }}
            >
              <Edit fontSize="small" color="primary" />
            </IconButton>
          ) : null}
          <DeletePostalCode />
        </Box>
      </Box>
    </Card>
  );
};

const PostalCodes = () => {
  const { data, isLoading, isFetching } = useListContext();
  const { setAction } = useContext(MarketActionContext);
  const { permissions } = usePermissions();

  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');

  const canCreatePostalCodes = permissions?.tools?.hqadmin?.market_postal_codes?.create;

  if (isLoading || isFetching) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (!data) return null;

  return (
    <Card>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Postal Codes</Typography>
        {canCreatePostalCodes ? (
          <Box>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              onClick={() => setAction(MARKET_ACTION.CREATE_POSTAL_CODE)}
            >
              Add Postal Code
            </Button>
          </Box>
        ) : null}
      </Box>
      <Divider />
      <Box
        p={2}
        sx={{ backgroundColor: ({ palette }) => (lightMode ? palette.neutral.lighter : palette.secondary.darkest) }}
      >
        <Grid container spacing={2}>
          {data.map((postalCode) => (
            <Grid item xs={6} md={3} lg={12 / 5} xl={2} key={postalCode.id}>
              <RecordContextProvider value={postalCode}>
                <PostalCodeCard />
              </RecordContextProvider>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
};

const MarketServiceArea = () => {
  const record = useRecordContext();

  const {
    data: postalCodesData,
    isLoading,
    isFetching,
  } = useGetList(
    resources.MARKETS,
    {
      meta: {
        resourceId: record?.id,
        subResource: 'postal_codes',
      },
      pagination: {
        perPage: 1000,
        page: 1,
      },
    },
    {
      enabled: Boolean(record?.id),
    },
  );

  const listContext = useList({
    data: postalCodesData,
    isLoading,
    isFetching,
    sort: { field: 'postal_id', order: 'ASC' },
  });

  if (!record) return null;

  return (
    <Card>
      <Box>
        <ListContextProvider value={listContext}>
          <PostalCodes />
        </ListContextProvider>
      </Box>
    </Card>
  );
};

export default MarketServiceArea;
