import React from 'react';
import { ReferenceField, SimpleShowLayout, TextField, useRecordContext, useShowContext } from 'react-admin';
import * as resources from '@/api/resources';

const OrderCustomer = () => {
  const record = useRecordContext();
  const { record: { partner_id: partner } = {} } = useShowContext();

  if (!record) return null;

  return (
    <SimpleShowLayout record={record}>
      <ReferenceField source="id" reference={resources.CUSTOMERS} link="show" label="Name">
        <TextField source="name" />
      </ReferenceField>
      {!(partner && partner === 'ZIPPYSHELL') ? <TextField source="phone" /> : null}
      <TextField source="email" />
    </SimpleShowLayout>
  );
};

export default OrderCustomer;
