import React from 'react';

import { LoadingIndicator, useShowContext } from 'react-admin';

import { Box } from '@mui/material';

import ProsDetailsContactInfoSection from './ContactInfo';
import ProsDetailsOverviewSection from './Overview';
import ProsDetailsPaymentSection from './Payment';
import ProsDetailsRecruitmentSection from './Recruitment';
import ProsDetailsStatsSection from './Stats';

const Details = () => {
  const { record, isLoading, isFetching } = useShowContext();

  if (!record) return null;

  return (
    <Box width="100%">
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <>
          <ProsDetailsOverviewSection />
          <ProsDetailsContactInfoSection />
          <ProsDetailsStatsSection />
          <ProsDetailsRecruitmentSection />
          <ProsDetailsPaymentSection />
        </>
      )}
    </Box>
  );
};

export default Details;
