import React from 'react';

import { SimpleShowLayout, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { PRO_ACCOUNT_DETAIL_SECTION, PRO_ACCOUNT_TAB } from '@/constants/prosAccount';

import ProsSectionHeader from '../Common/SectionHeader';

const ProsDetailsRecruitmentSection = () => (
  <Card mt={3} component={Box}>
    <ProsSectionHeader
      title="Recruitment"
      contextMenuItems={[]}
      tab={PRO_ACCOUNT_TAB.DETAILS}
      section={PRO_ACCOUNT_DETAIL_SECTION.RECRUITMENT}
    />

    <Divider />
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SimpleShowLayout>
            <TextField source="breezy_candidate_id" emptyText="N/A" />
            <TextField source="breezy_company_id" emptyText="N/A" />
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={4}>
          <SimpleShowLayout>
            <TextField source="breezy_position_id" emptyText="N/A" />
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={4}>
          <SimpleShowLayout>
            <TextField source="background_check" emptyText="N/A" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ProsDetailsRecruitmentSection;
