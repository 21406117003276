import React from 'react';

import { string } from 'prop-types';
import { useGetOne, useRecordContext } from 'react-admin';

import * as resources from '@/api/resources';

import { ContentCopy, Report } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import TextFieldWrapper from '../TextFieldWrapper';

const UserField = ({ emptyText, source, clipboardUserSource }) => {
  const record = useRecordContext();

  const userId = record?.[source];

  const {
    data: userData,
    isLoading,
    error,
    isError,
  } = useGetOne(resources.USERS, { id: userId }, { enabled: !!userId, retry: false });

  const clipboardValue = userData?.[clipboardUserSource];

  if (!record || !source) return null;

  if (source && !userId) return <TextFieldWrapper value={emptyText} />;

  if (isLoading) return <CircularProgress size={16} />;

  if (isError) {
    if (error.status === 404) {
      return (
        <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
          <Box>
            <Typography>Unknown User</Typography>
            <Typography variant="body3">{userId}</Typography>
          </Box>
          <Report color="error" />
        </Box>
      );
    }
    return <TextFieldWrapper value="Unknown Error fetching user" />;
  }

  return (
    <Box display="flex" gap={0.5} alignItems="center">
      <Typography variant="body2">{userData?.name}</Typography>
      {clipboardUserSource && clipboardValue ? (
        <Tooltip title={`Copy user ${clipboardUserSource} to clipboard`}>
          <IconButton onClick={() => navigator.clipboard.writeText(clipboardValue)}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  );
};

UserField.propTypes = {
  emptyText: string,
  source: string.isRequired,
  clipboardUserSource: string,
};

UserField.defaultProps = {
  emptyText: '',
  clipboardUserSource: null,
};

export default UserField;
