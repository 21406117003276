import React from 'react';
import { string } from 'prop-types';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const LinkButton = ({ label, ...props }) => (
  <Button disableElevation component={Link} variant="outlined" {...props}>
    {label}
  </Button>
);

LinkButton.propTypes = {
  label: string.isRequired,
};

export default LinkButton;
