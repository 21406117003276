import React from 'react';

import { usePermissions, useSidebarState, useTheme } from 'react-admin';

import {
  AdminPanelSettings,
  Assignment,
  Discount,
  FiberManualRecord,
  Groups,
  Inventory,
  LocationCity,
  ManageAccounts,
  PriceChange,
  SupervisedUserCircle,
  ViewTimeline,
} from '@mui/icons-material';
import { Box, useTheme as useMuiTheme } from '@mui/material';

import NewMenuItemLink from '@/ReactAdmin/Menu/MenuItemLink';
import * as resources from '@/api/resources';

const ModuleBar = () => {
  const [sidebarOpen] = useSidebarState();
  const { permissions } = usePermissions();
  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');
  const { palette } = useMuiTheme();

  if (!permissions) return null;

  return (
    <Box
      width={sidebarOpen ? 240 : 60}
      zIndex={100}
      sx={{
        background: lightMode ? palette.neutral.lightest : palette.secondary.darker,
        transitionProperty: 'width, align-items',
        transitionDuration: '195ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
        transitionDelay: '0ms',
      }}
    >
      <Box height="100%" p={1} display="flex" flexDirection="column" alignItems="flex-start" flexShrink={0}>
        <Box width="100%">
          {permissions.tools?.fulfillment?.view && (
            <>
              <NewMenuItemLink
                disabled
                to="/"
                primaryText={sidebarOpen ? 'Fulfillment' : ''}
                leftIcon={<SupervisedUserCircle />}
                sx={{
                  padding: 0.5,
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />
              <Box ml={2}>
                <NewMenuItemLink
                  to={{
                    pathname: '/orders',
                    search: 'view=fulfillment',
                  }}
                  targetView="fulfillment"
                  primaryText="Orders"
                  leftIcon={<FiberManualRecord fontSize="xsmall" />}
                />
                <NewMenuItemLink to={resources.JOBS} primaryText="Jobs" leftIcon={<FiberManualRecord fontSize="xsmall" />} />
              </Box>
            </>
          )}

          {permissions.tools?.hqadmin?.account_management?.view && (
            <>
              <NewMenuItemLink
                to="/"
                disabled
                primaryText={sidebarOpen ? 'Account Management' : ''}
                leftIcon={<ManageAccounts />}
                sx={{
                  padding: 0.5,
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />

              {permissions.tools?.hqadmin.pro_accounts?.view && (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={{
                      pathname: '/accounts',
                    }}
                    primaryText="Pros"
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              )}

              {permissions.tools?.hqadmin.customer_accounts?.view && (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={{
                      pathname: '/customers',
                    }}
                    primaryText="Customers"
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              )}

              {permissions.tools?.hqadmin.carrier_accounts?.view && (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={{
                      pathname: '/carriers',
                    }}
                    primaryText="Carriers"
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              )}

              {permissions.tools?.hqadmin.third_party_crew_accounts?.view && (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={{
                      pathname: '/third_party_crew',
                    }}
                    primaryText="Third Party Crew"
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              )}
            </>
          )}

          {permissions.tools?.hqadmin?.view ? (
            <>
              <NewMenuItemLink
                disabled
                to="/"
                primaryText={sidebarOpen ? 'Order Management' : ''}
                leftIcon={<Assignment />}
                sx={{
                  padding: 0.5,
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />
              <Box ml={2}>
                <NewMenuItemLink
                  to={{
                    pathname: '/orders',
                    search: 'view=management',
                  }}
                  targetView="management"
                  primaryText="Orders"
                  leftIcon={<FiberManualRecord fontSize="xsmall" />}
                />
              </Box>
            </>
          ) : null}

          {permissions.tools?.hqadmin?.markets?.view ? (
            <NewMenuItemLink
              to="/markets"
              primaryText={sidebarOpen ? 'Market Configuration' : ''}
              leftIcon={<LocationCity />}
              sx={{
                padding: 0.5,
              }}
            />
          ) : null}

          {permissions.tools?.hqadmin?.promo_codes?.view ? (
            <NewMenuItemLink
              to="/promo_codes"
              primaryText={sidebarOpen ? 'Promo Codes' : ''}
              leftIcon={<Discount />}
              sx={{
                padding: 0.5,
              }}
            />
          ) : null}

          {permissions.tools?.hqadmin?.supply_growth_inventory?.create ? (
            <NewMenuItemLink
              to={`${resources.SUPPLYINVENTORY}/create`}
              primaryText={sidebarOpen ? 'Supply Inventory' : ''}
              leftIcon={<Inventory />}
              sx={{
                padding: 0.5,
              }}
            />
          ) : null}

          {permissions.tools?.hqadmin?.availability_configuration?.admin ? (
            <NewMenuItemLink
              to={`${resources.AVAILABILITY_CONFIGURATION}/create`}
              primaryText={sidebarOpen ? 'Availability Configuration' : ''}
              leftIcon={<ViewTimeline />}
              sx={{
                padding: 0.5,
              }}
            />
          ) : null}

          {permissions.tools?.hqadmin?.pricing_layers?.view ? (
            <>
              <NewMenuItemLink
                disabled
                to="/"
                primaryText={sidebarOpen ? 'Pricing' : ''}
                leftIcon={<PriceChange />}
                sx={{
                  padding: 0.5,
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />
              <Box ml={2}>
                <NewMenuItemLink
                  to={`${resources.PRICING_LAYERS}/service_layer_summary`}
                  primaryText={sidebarOpen ? 'Service Layers' : ''}
                  leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  sx={{
                    padding: 0.5,
                  }}
                />
              </Box>
              <Box ml={2}>
                <NewMenuItemLink
                  to={`${resources.PRICING_LAYERS}/lane_layer_summary`}
                  primaryText={sidebarOpen ? 'Lane Layers' : ''}
                  leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  sx={{
                    padding: 0.5,
                  }}
                />
              </Box>
              <Box ml={2}>
                <NewMenuItemLink
                  to={`${resources.PRICING_LAYERS}/date_layer_summary`}
                  primaryText={sidebarOpen ? 'Date Layers' : ''}
                  leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  sx={{
                    padding: 0.5,
                  }}
                />
              </Box>
            </>
          ) : null}

          {permissions.tools?.hqadmin?.users?.view ? (
            <NewMenuItemLink
              to={`${resources.USERS}`}
              primaryText={sidebarOpen ? 'Users' : ''}
              leftIcon={<Groups />}
              sx={{
                padding: 0.5,
              }}
            />
          ) : null}

          {permissions.tools?.hqadmin?.admin ? (
            <>
              <NewMenuItemLink
                disabled
                to="/"
                primaryText={sidebarOpen ? 'Admin' : ''}
                leftIcon={<AdminPanelSettings />}
                sx={{
                  padding: 0.5,
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />
              {permissions.tools?.hqadmin?.connected_accounts?.admin ? (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={resources.ADMIN_CONNECTED_ACCOUNTS}
                    primaryText={sidebarOpen ? 'Connected Accounts' : ''}
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              ) : null}
              {permissions.tools?.hqadmin?.order_items?.admin ? (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={resources.ADMIN_ORDER_ITEMS}
                    primaryText={sidebarOpen ? 'Order Items' : ''}
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              ) : null}
              {permissions.tools?.hqadmin?.stripe_customers?.admin ? (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={resources.ADMIN_STRIPE_CUSTOMERS}
                    primaryText={sidebarOpen ? 'Stripe Customers' : ''}
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              ) : null}
              {permissions.tools?.hqadmin?.stripe_transactions?.admin ? (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={resources.ADMIN_STRIPE_TRANSACTIONS}
                    primaryText={sidebarOpen ? 'Stripe Transactions' : ''}
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              ) : null}
              {permissions.tools?.hqadmin?.products?.admin ? (
                <Box ml={2}>
                  <NewMenuItemLink
                    to={resources.PRODUCTS}
                    primaryText={sidebarOpen ? 'Products' : ''}
                    leftIcon={<FiberManualRecord fontSize="xsmall" />}
                  />
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

ModuleBar.propTypes = {};

export default ModuleBar;
