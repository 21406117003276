import React from 'react';

import { useRecordContext } from 'react-admin';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';

const PaymentMethodAccount = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Box display="flex">
      {record?.stripe_customer_id ? (
        <Box pr={1}>
          <Tooltip title="Open Customer in Stripe" placement="top">
            <IconButton href={`${process.env.REACT_APP_STRIPE_URI}/customers/${record?.stripe_customer_id}`} target="_blank">
              <Box width={30} height={30} borderRadius="50%" overflow="hidden">
                <img src="/svgs/stripe/stripe-icon-square.svg" alt="Stripe Logo" width="100%" />
              </Box>
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}
      <Box>
        <Typography>{record?.holder_name}</Typography>
      </Box>
    </Box>
  );
};

export default PaymentMethodAccount;
