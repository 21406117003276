import React from 'react';

import dayjs from 'dayjs';
import { CreateBase, DateInput, Form, NumberInput, SelectArrayInput, minValue, required } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/pricingLayers';

import MarketGroupSelection from '../shared/MarketGroupSelection';
import CollisionDetection from './CollisionDetection';
import FormControls from './FormControls';
import PriceDelta from './PriceDelta';

const formatValue = (value) => (value / 100).toFixed(2);
const parseValue = (value) => Math.round(parseFloat(value) * 100);

const ServiceLayerCreateBatch = () => {
  const minEffectiveDate = dayjs().add(1, 'day').format('YYYY-MM-DD'); // Minimum date for effective date is tomorrow, cannot input new pricing for the past
  const minEffectiveDateValidator = minValue(minEffectiveDate);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  const effectiveDateValidate = [required(), ...(isProduction ? [minEffectiveDateValidator] : [])];

  return (
    <CreateBase resource={`${resources.PRICING_LAYERS}/batch`}>
      <Form>
        <Card component={Box} mt={3}>
          <CardHeader title="Create Batch - Service Pricing Layers" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2} lg={1.5}>
                <DateInput source="effective_date" validate={effectiveDateValidate} defaultValue={minEffectiveDate} />
              </Grid>
              <Grid item xs={10}>
                <SelectArrayInput
                  fullWidth
                  label="Service"
                  autoComplete="off"
                  source="service_ids"
                  // All services except LDTRANSIT - handled via lane page input
                  choices={Object.values(SERVICES).filter(({ id }) => id !== SERVICES.LDTRANSIT.id)}
                  // required to use this validator with SaveButton type="button"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="base_price"
                  format={formatValue}
                  parse={parseValue}
                  validate={required()}
                  min={0}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="per_worker_price"
                  format={formatValue}
                  parse={parseValue}
                  validate={required()}
                  min={0}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput source="fixed_price_modifier" min={0} defaultValue={1} validate={required()} />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="base_fee"
                  min={0}
                  defaultValue={0}
                  validate={required()}
                  format={formatValue}
                  parse={parseValue}
                />
              </Grid>
              <Grid item xs={12}>
                <MarketGroupSelection />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <CollisionDetection />
          </Grid>
          <Grid item xs={12} lg={7}>
            <PriceDelta />
          </Grid>
        </Grid>
        <FormControls />
      </Form>
    </CreateBase>
  );
};

export default ServiceLayerCreateBatch;
