import React from 'react';

import { Drawer } from '@mui/material';
import { bool, func, string } from 'prop-types';

import ConvertOrderToBhn from './ConvertOrderToBhn';

const AsideActionDrawer = ({ asideAction, open, onClose }) => {
  const objNavigation = {
    convertordertobhn: 'convertordertobhn',
  };

  if (!asideAction) return null;

  let drawerContent;
  switch (asideAction) {
    case objNavigation.convertordertobhn:
      drawerContent = <ConvertOrderToBhn onClose={onClose} />;
      break;
    default:
      drawerContent = <>Unknown Action</>;
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {drawerContent}
    </Drawer>
  );
};

AsideActionDrawer.propTypes = {
  asideAction: string,
  open: bool.isRequired,
  onClose: func.isRequired,
};

AsideActionDrawer.defaultProps = {
  asideAction: null,
};

export default AsideActionDrawer;
