import React, { useEffect, useState } from 'react';

import { useRecordContext, useReference } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { Error, LocalShipping, Warning } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_BELLHOP_NATION } from '@/constants/market';

const LocationWarnings = () => {
  const location = useRecordContext();
  const partner = useWatch({ name: 'partner_id' });

  const { referenceRecord: marketData } = useReference({
    reference: resources.MARKETS,
    id: location?.market_id,
    options: { enabled: Boolean(location?.market_id) },
  });

  const [showPodsWarning, setShowPodsWarning] = useState(false);

  useEffect(() => {
    if (partner === 'PODS' && !marketData?.is_pod_available) {
      setShowPodsWarning(true);
    } else {
      setShowPodsWarning(false);
    }
  }, [partner, marketData]);

  return (
    <Box display="flex" gap={1} px={1}>
      {location.market_id === MARKET_BELLHOP_NATION ? (
        <Tooltip title="Location is out of service area">
          <Warning color="warning" />
        </Tooltip>
      ) : null}
      {marketData?.is_labor_only ? (
        <Tooltip title="Location is in labor only market">
          <LocalShipping color="error" />
        </Tooltip>
      ) : null}
      {marketData?.status === 'Inactive' && location.market_id !== MARKET_BELLHOP_NATION ? (
        <Tooltip title="Location is in inactive market">
          <Warning color="warning" />
        </Tooltip>
      ) : null}
      {showPodsWarning ? (
        <Tooltip title="Location is not PODS market">
          <Error color="error" />
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default LocationWarnings;
