import React, { useEffect, useRef } from 'react';

import { func, shape, string } from 'prop-types';
import { Title } from 'react-admin';
import { useLocation } from 'react-router';

import { ArrowBack, Refresh, Report } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

const MyError = ({ error, errorInfo, resetErrorBoundary }) => {
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  return (
    <div>
      <Title title="Error" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>
          <Report color="error" /> Something Went Wrong{' '}
        </h1>
        <Box display="flex" gap={3}>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={() => {
              window.history.go(-1);
            }}
          >
            Go Back
          </Button>
          <Button
            variant="outlined"
            startIcon={<Refresh />}
            onClick={() => {
              window.history.go();
            }}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <h3>{error.toString()}</h3>
      <pre>{errorInfo?.componentStack}</pre>
    </div>
  );
};

MyError.propTypes = {
  error: shape({}),
  errorInfo: shape({
    componentStack: string,
  }),
  resetErrorBoundary: func.isRequired,
};

MyError.defaultProps = {
  error: {},
  errorInfo: {
    componentStack: '',
  },
};

export default MyError;
