/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import { Datagrid, SaveButton, TextField, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Add, Save } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES, SERVICES } from '@/constants/pricingLayers';
import MarketNameField from '@/fields/MarketNameField';

const FORM_DEFAULT = {
  layers: null,
  lane_layers: null,
};

const FormControls = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { getValues, reset, formState } = useFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorData, setErrorData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(false);

  const dataProvider = useDataProvider();

  const { mutate: createMultiple } = useMutation(([resource, params]) => dataProvider.createMultiple(resource, params));

  const transform = (data) => ({
    type: PRICING_LAYER_TYPES.LANE,
    layers: data.map((layer) => ({
      ...layer,
      service_id: SERVICES.LDTRANSIT.id,
    })),
  });

  const onSettled = () => {
    setIsSubmitting(false);
  };

  const onSuccess = (redirectOnSuccess) => {
    notify('Pricing Layers created successfully', { type: 'success' });
    if (redirectOnSuccess) {
      redirect(`/${resources.PRICING_LAYERS}/service_layer_summary`);
    } else {
      reset(FORM_DEFAULT);
    }
  };

  const onError = (error) => {
    const { message, data } = error.body;
    if (error.status === 409) {
      notify(`${message} - ${data?.length} conflicting rows`, { type: 'error' });
      setErrorData(data);
      return;
    }
    notify(`Error occurred creating Pricing Layers - ${message}`, { type: 'error' });
  };

  const submitForm = useCallback(
    async (e, redirectOnSuccess) => {
      e.preventDefault();
      if (Object.values(formState.errors)?.length) return;
      setIsSubmitting(true);
      const { layers } = getValues();
      createMultiple(
        [
          `${resources.PRICING_LAYERS}/batch`,
          {
            data: transform(layers),
          },
        ],
        {
          onSettled,
          onSuccess: () => onSuccess(redirectOnSuccess),
          onError,
        },
      );
    },
    [formState],
  );

  useEffect(() => {
    if (Object.values(formState.errors)?.length) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [formState]);

  return (
    <>
      <Card mt={3} component={Box}>
        <CardContent component={Box} display="flex" justifyContent="space-between">
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="neutral"
              onClick={() => redirect(`/${resources.PRICING_LAYERS}/service_layer_summary`)}
            >
              Cancel
            </Button>
            <Button variant="contained" color="neutral" onClick={() => reset(FORM_DEFAULT)}>
              Reset Form
            </Button>
          </Box>
          <Box display="flex" gap={2}>
            <SaveButton
              type="button"
              label="Save and Add More"
              icon={isSubmitting ? <CircularProgress size={18} /> : <Add />}
              onClick={(e) => submitForm(e, false)}
              disabled={isSubmitting || saveDisabled ? true : undefined}
            />
            <SaveButton
              type="button"
              label="Save"
              onClick={(e) => submitForm(e, true)}
              icon={isSubmitting ? <CircularProgress size={18} /> : <Save />}
              disabled={isSubmitting || saveDisabled ? true : undefined}
            />
          </Box>
        </CardContent>
      </Card>
      {errorData ? (
        <Card mt={3} component={Box}>
          <CardHeader title="Error Data" />
          <Divider />
          <Datagrid data={errorData} bulkActionButtons={false} sort={false}>
            <TextField source="effective_date" />
            <MarketNameField source="start_market_id" />
            <MarketNameField source="end_market_id" />
          </Datagrid>
        </Card>
      ) : null}
    </>
  );
};

export default FormControls;
