import React from 'react';

import { string } from 'prop-types';

import { CreditCard } from '@mui/icons-material';

const CreditCardIcon = ({ brand }) => {
  const brandCompareStr = brand.toLowerCase().replaceAll(' ', '-');
  switch (brandCompareStr) {
    case 'visa':
    case 'discover':
    case 'american-express':
    case 'mastercard':
      return <img src={`/svgs/credit-card/${brandCompareStr}-light-large.svg`} alt={`${brand} logo`} width="100%" />;
    default:
      return <CreditCard />;
  }
};

CreditCardIcon.propTypes = {
  brand: string.isRequired,
};

export default CreditCardIcon;
