import { node } from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

const JobSlotActionContext = createContext({});

const JobSlotActionProvider = ({ children }) => {
  const [action, setAction] = useState('');
  const [metadata, setMetadata] = useState({});

  const value = useMemo(() => ({ action, setAction, metadata, setMetadata }), [action, metadata]);

  return <JobSlotActionContext.Provider value={value}>{children}</JobSlotActionContext.Provider>;
};

JobSlotActionProvider.propTypes = {
  children: node.isRequired,
};

export { JobSlotActionContext, JobSlotActionProvider };
