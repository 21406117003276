const MARKET_TIMEZONES = {
  ATLANTA_GA: 'America/New_York',
  AUSTIN_TX: 'America/Chicago',
  BALTIMORE_MD: 'America/New_York',
  BIRMINGHAM_AL: 'America/Chicago',
  BELLHOPNATION: 'America/New_York',
  BOSTON_MA: 'America/New_York',
  CHARLOTTE_NC: 'America/New_York',
  CHATTANOOGA_TN: 'America/New_York',
  CHICAGO_IL: 'America/Chicago',
  CINCINNATI_OH: 'America/New_York',
  CLEVELAND_OH: 'America/New_York',
  COLORADO_SPRINGS_CO: 'America/Denver',
  COLUMBUS_OH: 'America/New_York',
  CORPUS_CHRISTI_TX: 'America/Chicago',
  'DALLAS-FORT-WORTH_TX': 'America/Chicago',
  DENVER_CO: 'America/Denver',
  DETROIT_MI: 'America/New_York',
  GREENSBORO_NC: 'America/New_York',
  HOUSTON_TX: 'America/Chicago',
  INDIANAPOLIS_IN: 'America/Indiana/Indianapolis',
  JACKSONVILLE_FL: 'America/New_York',
  KANSAS_CITY_MO: 'America/Chicago',
  KNOXVILLE_TN: 'America/New_York',
  LAS_VEGAS_NV: 'America/Los_Angeles',
  LEXINGTON_KY: 'America/New_York',
  LOS_ANGELES_CA: 'America/Los_Angeles',
  LOUISVILLE_KY: 'America/New_York',
  MEMPHIS_TN: 'America/Chicago',
  MIAMI_FL: 'America/New_York',
  MINNEAPOLIS_MN: 'America/Chicago',
  MYRTLE_BEACH_SC: 'America/New_York',
  NASHVILLE_TN: 'America/Chicago',
  NEW_ORLEANS_LA: 'America/New_York',
  NEW_YORK_NY: 'America/New_York',
  ORANGE_COUNTY_CA: 'America/Los_Angeles',
  ORLANDO_FL: 'America/New_York',
  PHILADELPHIA_PA: 'America/New_York',
  PHOENIX_AZ: 'America/Phoenix',
  PITTSBURGH_PA: 'America/New_York',
  PORTLAND_OR: 'America/Los_Angeles',
  'RALEIGH-DURHAM-CHAPEL-HILL_NC': 'America/New_York',
  RICHMOND_VA: 'America/New_York',
  RIVERSIDE_CA: 'America/Los_Angeles',
  ROCHESTER_NY: 'America/New_York',
  SACRAMENTO_CA: 'America/Los_Angeles',
  SAINT_LOUIS_MO: 'America/Chicago',
  SALT_LAKE_CITY_UT: 'America/Denver',
  SAN_ANTONIO_TX: 'America/Chicago',
  SAN_DIEGO_CA: 'America/Los_Angeles',
  SAN_FRANCISCO_CA: 'America/Los_Angeles',
  SAN_JOSE_CA: 'America/Los_Angeles',
  SEATTLE_WA: 'America/Los_Angeles',
  TAMPA_FL: 'America/New_York',
  TUCSON_AZ: 'America/Denver',
  VENTURA_CA: 'America/Los_Angeles',
  'WASHINGTON_D.C._DC': 'America/New_York',
};

// eslint-disable-next-line import/prefer-default-export
export { MARKET_TIMEZONES };
