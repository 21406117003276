import React from 'react';

import { DateField, Labeled, ReferenceField, TextField, useShowContext } from 'react-admin';

import { Avatar, Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CARRIER_ACCOUNT_DETAIL_SECTION, CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';
import MarketNameField from '@/fields/MarketNameField';

import CarrierDetailsSectionHeader from '../Common/SectionHeader';

const Overview = () => {
  const { record } = useShowContext();

  return (
    <Card>
      <CarrierDetailsSectionHeader
        title="Overview"
        tab={CARRIER_ACCOUNT_TAB.DETAILS}
        section={CARRIER_ACCOUNT_DETAIL_SECTION.OVERVIEW}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2} display="flex" justifyContent="center" mt={1}>
            <Avatar
              component={Box}
              src={record.image_url}
              sx={{
                width: 100,
                height: 100,
              }}
            />
          </Grid>
          <Grid item xs={10} container spacing={2}>
            <Grid item xs={8}>
              <Labeled>
                <TextField source="name" emptyText="N/A" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="account_record_type" emptyText="N/A" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="status" emptyText="N/A" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <ReferenceField source="company_id" reference="accounts" label="Company" emptyText="N/A">
                  <TextField source="name" emptyText="N/A" />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="person_title" label="Title" emptyText="N/A" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <MarketNameField source="primary_market" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled>
                <DateField source="joined_date" emptyText="N/A" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Overview;
