import React, { useContext } from 'react';

import { Box, Button, Drawer, Typography } from '@mui/material';

import { JobSlotActionContext } from '@/providers/JobSlotAction';

import UnpauseTransaction from '@/shared/ActionDrawer/UnpauseTransaction';
import CancelCharge from './CancelCharge';
import OverrideAssign from './OverrideAssign';
import ReverseTransfer from './ReverseTransfer';
import Unassign from './Unassign';

const UnknownAction = () => {
  const { action, setAction, setMetadata } = useContext(JobSlotActionContext);
  return (
    <Box px={6} py={4} minWidth={400}>
      <Typography variant="h5" component={Box} pb={4}>{`Unknown Action - ${action}`}</Typography>
      <Button
        variant="contained"
        color="neutral"
        onClick={() => {
          setMetadata(null);
          setAction(null);
        }}
      >
        Close
      </Button>
    </Box>
  );
};

const JobSlotActionsDrawer = () => {
  const { action, setAction, setMetadata } = useContext(JobSlotActionContext);

  if (!action) return null;

  let drawerContent;
  switch (action) {
    case 'cancel_charge':
      drawerContent = <CancelCharge />;
      break;
    case 'override_assign':
      drawerContent = <OverrideAssign />;
      break;
    case 'reverse_transfer':
      drawerContent = <ReverseTransfer />;
      break;
    case 'unassign':
      drawerContent = <Unassign />;
      break;
    case 'unpause':
      drawerContent = <UnpauseTransaction />;
      break;
    default:
      drawerContent = <UnknownAction />;
  }

  return (
    <Drawer
      anchor="right"
      open={Boolean(action)}
      onClose={() => {
        setMetadata(null);
        setAction(null);
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default JobSlotActionsDrawer;
