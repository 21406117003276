import React from 'react';

import {
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  Link,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin';
import { useNavigate } from 'react-router';

import { Alert, Box, Card, Divider, Grid, Typography } from '@mui/material';

import ListBase from '@/ReactAdmin/List/ListBase';
import * as resources from '@/api/resources';
import { PAYOUT_CONFIG, PAYOUT_CONFIG_MINIMUM_TYPES } from '@/constants/account';
import CurrencyField from '@/fields/CurrencyField';
import ServiceNameField from '@/fields/ServiceNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import ContextMenuButton from '@/shared/ContextMenuButton';
import EmptyResourceList from '@/shared/EmptyResourceList';
import getAccountResourceType from '@/utils/accounts/getAccountResourceType';
import getServiceName from '@/utils/serviceName/getServiceName';

import PayoutConfigExamples from './PayoutConfigExamples';

const EditPayoutConfigButton = () => {
  const { record: accountRecord } = useShowContext();
  const { account_id: accountId, id: payoutConfigId } = useRecordContext();

  const resourceType = getAccountResourceType(accountRecord);

  return (
    <EditButton label="Edit" component={Link} to={`/${resourceType}/${accountId}/payout_config/${payoutConfigId}/edit`} />
  );
};

const PayoutConfigActions = () => {
  const { account_id: accountId, id: payoutConfigId, service: payoutConfigService } = useRecordContext();

  const { permissions } = usePermissions();
  const refresh = useRefresh();

  const canEditConfig = permissions.tools?.hqadmin?.account_payout_config?.update;
  const canDeleteConfig = permissions.tools?.hqadmin?.account_payout_config?.delete;

  if (!canEditConfig && !canDeleteConfig) return null;

  return (
    <Box display="flex" gap={2}>
      {canEditConfig ? <EditPayoutConfigButton /> : null}
      {canDeleteConfig ? (
        <DeleteButton
          id={payoutConfigId}
          resource={`${resources.ACCOUNTS}/payout_config`}
          confirmTitle={`Delete ${getServiceName(payoutConfigService)} Payout Config?`}
          confirmContent={false}
          mutationMode="pessimistic"
          mutationOptions={{
            meta: {
              data: {
                account_id: accountId,
              },
            },
            onSuccess: () => {
              refresh();
            },
          }}
        />
      ) : null}
    </Box>
  );
};

const PayoutConfig = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  if (!record) return null;

  const isMarketRate = !record.account_payout_config || record.account_payout_config === PAYOUT_CONFIG.MARKET_RATE.name;

  const contextMenuItems = [
    ...(permissions.tools?.hqadmin?.account_payout_config?.create
      ? [
          {
            key: 'add',
            onClick: () => {
              navigate(`/${resources.THIRD_PARTY_CREW}/${record.id}/payout_config/create`);
            },
            component: 'Add payout config',
          },
        ]
      : []),
  ];

  return (
    <>
      {isMarketRate ? (
        <Box mb={2}>
          <Alert variant="filled" severity="warning">
            <Box display="flex" gap={2}>
              <Typography variant="body2">
                Account is currently set to use market rate, the below configs (if any) will not be used.
              </Typography>
              {record.primary_market ? (
                <Link
                  color="warning.contrastText"
                  sx={{
                    textDecoration: 'underline',
                  }}
                  to={`/${resources.MARKETS}/${record.primary_market}/show/base-pay`}
                >
                  See applicable market rates here
                </Link>
              ) : null}
            </Box>
          </Alert>
        </Box>
      ) : null}
      <ListBase
        disableSyncWithLocation
        resource={`${resources.ACCOUNTS}/payout_config`}
        exporter={false}
        actions={false}
        filter={{ account_id: record.id }}
        empty={<EmptyResourceList text="No Payout Configs on account" />}
        storeKey={`${resources.ACCOUNTS}.${record.id}.payout_config.listParams`}
        sort={{ field: 'service', order: 'ASC' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <Card>
              <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" lineHeight={2}>
                  Payout Config
                </Typography>
                <ContextMenuButton contextMenuItems={contextMenuItems} />
              </Box>
              <Divider />
              <Datagrid bulkActionButtons={false}>
                <ServiceNameField source="service" />
                <CurrencyField source="base_hourly_amount_cents" label="Base Hourly" textAlign="left" />
                <CurrencyField source="per_resource_hourly_amount_cents" label="Per Resource Hourly" textAlign="left" />
                <CurrencyField source="minimum_amount_per_job_cents" label="Minimum" textAlign="left" />
                <FunctionField
                  label="Minimum Pay Type"
                  render={({ minimum_pay_type: minimumType }) => (
                    <TextFieldWrapper value={PAYOUT_CONFIG_MINIMUM_TYPES[minimumType].name} />
                  )}
                />
                <PayoutConfigActions />
              </Datagrid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Card>
              <Box py={2} px={3}>
                <Typography variant="h5" lineHeight={2}>
                  Rate Examples
                </Typography>
              </Box>
              <Divider />
              <PayoutConfigExamples />
            </Card>
          </Grid>
        </Grid>
      </ListBase>
    </>
  );
};

export default PayoutConfig;
