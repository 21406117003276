// Get hourly blocks from 8am to 8pm
const getHourlyAvailability = (startDate, endDate) => {
  const hourly = {};
  // Set false for hours between 8am and 8pm that don't fall within start and end times
  for (let index = 8; index < 20; index += 1) {
    hourly[index - 8] = index >= startDate.$H && index < endDate.$H;
  }

  return hourly;
};

export default getHourlyAvailability;
