import React from 'react';

import { bool, shape, string } from 'prop-types';
import { LoadingIndicator } from 'react-admin';

import { ArrowForward, House, Person } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, Tooltip, Typography } from '@mui/material';

import RouteInfo from '@/shared/RouteInfo';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import toPascalCase from '@/utils/toPascalCase';

const AddressTooltip = ({ headerText, addressText }) => (
  <Box>
    <Typography variant="body2">{headerText}</Typography>
    <Typography variant="body3">{addressText}</Typography>
  </Box>
);

AddressTooltip.propTypes = {
  headerText: string.isRequired,
  addressText: string.isRequired,
};

const DistanceToJobCard = ({ jobStartLocation, accountLocation, isLoading }) => {
  if (isLoading) return <LoadingIndicator />;

  if (!jobStartLocation || !accountLocation) return null;

  const proAddressText = formatAddressParts({
    ...accountLocation,
    line_1: toPascalCase(accountLocation.street_address_1),
    line_2: toPascalCase(accountLocation.street_address_2),
    city: toPascalCase(accountLocation.city),
  });

  const jobAddressText = formatAddressParts({
    ...jobStartLocation,
    line_1: toPascalCase(jobStartLocation.street_address_1),
    line_2: toPascalCase(jobStartLocation.street_address_2),
    city: toPascalCase(jobStartLocation.city),
  });

  return (
    <Card>
      <CardHeader title="Distance to Job" />
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="center" gap={1} alignItems="center">
          <Tooltip title={<AddressTooltip headerText="Pro Home Address" addressText={proAddressText} />}>
            <Person fontSize="large" />
          </Tooltip>
          <ArrowForward />
          <Tooltip title={<AddressTooltip headerText="Job Starting Address" addressText={jobAddressText} />}>
            <House fontSize="large" />
          </Tooltip>
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="center">
          <RouteInfo locations={[accountLocation, jobStartLocation]} />
        </Box>
      </CardContent>
    </Card>
  );
};

DistanceToJobCard.propTypes = {
  jobStartLocation: shape({}),
  accountLocation: shape({}),
  isLoading: bool.isRequired,
};

DistanceToJobCard.defaultProps = {
  jobStartLocation: null,
  accountLocation: null,
};

export default DistanceToJobCard;
