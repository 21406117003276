import React from 'react';

import { EditBase, useRedirect, useRefresh } from 'react-admin';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';

import CarrierDetailsEdit from './Details';

const CarrierAccountEdit = () => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { tab: currentTab, accountId } = useParams();

  return (
    <EditBase
      id={accountId}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          refresh();
          redirect(`/${resources.CARRIERS}/${accountId}/show`);
        },
      }}
      transform={(data) => ({ ...data, email: data?.email?.toLowerCase() })}
    >
      <Box display={currentTab === CARRIER_ACCOUNT_TAB.DETAILS ? 'block' : 'none'}>
        <CarrierDetailsEdit />
      </Box>
    </EditBase>
  );
};

export default CarrierAccountEdit;
