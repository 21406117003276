import React, { useContext } from 'react';

import { oneOf } from 'prop-types';
import { RecordContextProvider, useGetList } from 'react-admin';

import { Box, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPES_DISPLAY } from '@/constants/pricingLayers';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { DateLayerSummaryContext } from '@/providers/Pricing/DateLayerSummary';
import formatCurrency from '@/utils/currency/formatCurrency';

import DateNumericHeader from '../DateNumericHeader';

const Summary = ({ dateLayerType }) => {
  const { filters, setSelectedLayerGroup } = useContext(DateLayerSummaryContext);

  const isDataEnabled = Boolean(filters?.effective_date_lte && (filters?.market_id || filters?.service_id));

  const { data, isLoading, isFetching } = useGetList(
    `${resources.PRICING_LAYERS}/summary`,
    {
      filter: {
        type: dateLayerType,
        ...filters,
      },
    },
    {
      enabled: isDataEnabled,
    },
  );

  const numGridItems = {
    [PRICING_LAYER_TYPES.DAY_OF_MONTH]: 31,
    [PRICING_LAYER_TYPES.DAY_OF_WEEK]: 7,
    [PRICING_LAYER_TYPES.LEAD_TIME]: 8,
    [PRICING_LAYER_TYPES.MONTH_OF_YEAR]: 12,
  };

  const dateDataKey = {
    [PRICING_LAYER_TYPES.DAY_OF_MONTH]: 'day',
    [PRICING_LAYER_TYPES.DAY_OF_WEEK]: 'day',
    [PRICING_LAYER_TYPES.LEAD_TIME]: 'days',
    [PRICING_LAYER_TYPES.MONTH_OF_YEAR]: 'month',
  };

  return (
    <Card mt={2} component={Box}>
      <CardHeader title={PRICING_LAYER_TYPES_DISPLAY[dateLayerType]} />
      <Divider />
      <CardContent
        sx={{
          backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest),
        }}
      >
        {isLoading || isFetching ? (
          <Box textAlign="center">
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {Array.from(Array(numGridItems[dateLayerType]).keys()).map((dateIndex) => {
              let dateValue = dateIndex;
              switch (dateLayerType) {
                case PRICING_LAYER_TYPES.DAY_OF_WEEK:
                case PRICING_LAYER_TYPES.DAY_OF_MONTH:
                case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
                  dateValue += 1;
                  break;
                default:
                  break;
              }

              const dateData = data?.find((dataGroup) => dataGroup[dateDataKey[dateLayerType]] === dateValue);
              const numColumns = numGridItems[dateLayerType] > 8 ? 4 : numGridItems[dateLayerType];
              return (
                <Grid key={`${dateLayerType}.${dateValue}`} item xs={12} md={12 / numColumns}>
                  {dateData ? (
                    <RecordContextProvider value={dateData}>
                      <Box display="flex" flexDirection={{ xs: 'row', md: 'column' }} alignItems="center">
                        <Box minWidth={100} textAlign="center">
                          <DateNumericHeader type={dateLayerType} />
                        </Box>
                        <Box my={1} width="100%" display={{ xs: 'none', md: 'block' }}>
                          <Divider />
                        </Box>
                        <Box
                          width="80%"
                          mt={{ xs: 0, md: 1 }}
                          ml={{ xs: 2, md: 0 }}
                          display="flex"
                          gap={2}
                          flexDirection={{ xs: 'row', md: 'column' }}
                          alignItems="center"
                        >
                          {Object.keys(dateData.prices).map((price) => (
                            <Card key={`${dateLayerType}.${dateValue}.${price}`} width="100%" component={Box}>
                              <CardActionArea
                                onClick={() =>
                                  setSelectedLayerGroup({
                                    type: dateLayerType,
                                    price,
                                    [dateDataKey[dateLayerType]]: dateValue,
                                    ...dateData.prices[price],
                                  })
                                }
                              >
                                <CardContent textAlign="center" component={Box} sx={{ p: 1 }}>
                                  <Box textAlign="center">
                                    {price.startsWith('multi_') ? (
                                      <TextFieldWrapper
                                        value={`${price.replace('multi_', '')}x`}
                                        textFieldProps={{ variant: 'body1' }}
                                      />
                                    ) : (
                                      <TextFieldWrapper
                                        value={`${formatCurrency(price, 0)}`}
                                        textFieldProps={{ variant: 'body1' }}
                                      />
                                    )}
                                  </Box>
                                  {!filters.service_id || !filters.market_id ? (
                                    <Box mt={1}>
                                      <Divider />
                                    </Box>
                                  ) : null}
                                  {!filters.service_id ? (
                                    <Box mt={1}>
                                      <TextFieldWrapper value={`${dateData.prices[price].services.length} services`} />
                                    </Box>
                                  ) : null}
                                  {!filters.market_id ? (
                                    <Box mt={1}>
                                      <TextFieldWrapper value={`${dateData.prices[price].markets.length} markets`} />
                                    </Box>
                                  ) : null}
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          ))}
                        </Box>
                      </Box>
                    </RecordContextProvider>
                  ) : (
                    <RecordContextProvider value={{ [dateDataKey[dateLayerType]]: dateValue }}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: 'row', md: 'column' }}
                        gap={{ xs: 2, md: 0 }}
                        py={{ xs: 2, md: 0 }}
                        alignItems={{ xs: 'center' }}
                      >
                        <Box minWidth={100} textAlign="center">
                          <DateNumericHeader type={dateLayerType} />
                        </Box>
                        <Box width="100%" my={1} display={{ xs: 'none', md: 'block' }}>
                          <Divider />
                        </Box>
                        <Box display="flex" ml={{ xs: 1, md: 0 }}>
                          <TextFieldWrapper value="No layers" />
                        </Box>
                      </Box>
                    </RecordContextProvider>
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

Summary.propTypes = {
  dateLayerType: oneOf([Object.values(PRICING_LAYER_TYPES)]).isRequired,
};

export default Summary;
