import mx from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Box, CircularProgress } from '@mui/material';

import * as resources from '@/api/resources';

import { bool, func } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import PromoCodeForm from './PromoCodeForm';

const ResetForm = ({ resetForm, setResetForm }) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (resetForm) {
      reset({ promo_code_id: '' });
      setResetForm(false);
    }
  }, [resetForm]);
  return null;
};

ResetForm.propTypes = {
  resetForm: bool.isRequired,
  setResetForm: func.isRequired,
};

const AddPromoCode = () => {
  const {
    record: { id: orderId, confirmation_id: confirmationId },
  } = useShowContext();
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const [resetForm, setResetForm] = useState(false);

  const onError = ({ message, status }) => {
    if (status === 404 && message.includes('not found')) {
      notify('Promo code not found', {
        type: 'error',
      });
    } else if (message.includes('promo code already applied')) {
      notify('Promo code already applied on order', {
        type: 'error',
      });
    } else if (message.includes('promocode to a completed order')) {
      notify('Cannot apply a Promo Code to a completed order', {
        type: 'error',
      });
    }
  };

  const onSuccess = () => {
    mx.track('Order Management - Promo code applied', {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
    });

    notify('Promo code applied', {
      type: 'success',
    });

    refresh();
    setResetForm(true);
  };

  const onSubmit = (submittedData) => {
    const promoObject = submittedData.promo_code_id;
    update(
      resources.ORDERS,
      {
        id: orderId,
        meta: {
          subResource: 'promo_codes',
          method: 'PUT',
        },
        data: {
          promo_code_id: promoObject.id,
        },
      },
      {
        onError,
        onSuccess,
      },
    );
  };

  return (
    <Form onSubmit={onSubmit}>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <PromoCodeForm />
          <ResetForm resetForm={resetForm} setResetForm={setResetForm} />
        </>
      )}
    </Form>
  );
};

export default AddPromoCode;
