import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, SelectInput, useNotify, useRedirect, useReference, useRefresh, useShowContext, useUpdate } from 'react-admin';

import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { JobSlotActionContext } from '@/providers/JobSlotAction';

const OverrideAssign = () => {
  const { metadata, setAction, setMetadata } = useContext(JobSlotActionContext);
  const { record: jobSlotRecord } = useShowContext();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: jobSlotRecord?.job_id,
    options: { enabled: Boolean(jobSlotRecord?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const { referenceRecord: assignedAccountData } = useReference({
    reference: resources.ACCOUNTS,
    id: metadata?.account_id,
    enabled: Boolean(metadata?.account_id),
  });

  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onError = (error) => {
    notify(`Override assign failed - ${error.status} - ${error.message}`, {
      type: 'error',
    });
  };

  const onSuccess = (data) => {
    const jobIsTransit = jobData?.product_id.indexOf('TRANSIT') >= 0;

    mx.track(`Fulfillment - ${jobIsTransit ? 'Transit' : 'Labor'} override assigned`, {
      resource: resources.JOB_SLOTS,
      resourceId: jobSlotRecord?.id,
      parentResource: resources.JOBS,
      parentResourceId: jobSlotRecord?.job_id,
      confirmationId: orderData?.confirmation_id,
      assignedId: data?.account_id,
    });

    notify(`${assignedAccountData?.name} assigned via override`, {
      type: 'success',
    });
    setMetadata(null);
    setAction(null);
    refresh();
    redirect('show', resources.JOBS, jobSlotRecord.job_id);
  };

  const handleSubmit = ({ reason }) => {
    update(
      resources.JOB_SLOTS,
      {
        id: jobSlotRecord?.id,
        data: { account_id: metadata.account_id, override: reason },
        previousData: {},
      },
      {
        mutationMode: 'pessimistic',
        onError,
        onSuccess,
      },
    );
  };

  const overrideReason = [
    { id: 'already_assigned', name: 'Already Assigned' },
    { id: 'qualification', name: 'Qualification' },
    { id: 'total', name: 'All Reasons' },
  ];

  return (
    <Box px={6} py={4} minWidth={400} maxWidth={500}>
      <Typography variant="h4" component={Box} pb={4}>
        Override Assign
      </Typography>
      <Divider />
      <Box>
        <Box py={2}>
          <Alert severity="warning" variant="filled">
            <Typography>Account not available to assign</Typography>
            <Typography>{metadata?.message}</Typography>
          </Alert>
        </Box>
        <Divider />
        <Form onSubmit={handleSubmit}>
          <Box pt={2}>
            <SelectInput
              label="Override Reason"
              source="reason"
              choices={overrideReason}
              required
              fullWidth
              variant="outlined"
            />
          </Box>
          <Divider />
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="neutral"
              onClick={() => {
                setMetadata(null);
                setAction(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isUpdating ?? null}
              endIcon={isUpdating ? <CircularProgress size={18} /> : null}
            >
              Submit
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

OverrideAssign.propTypes = {};

export default OverrideAssign;
