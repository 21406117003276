import React from 'react';

import { EditBase, Form, SaveButton, ShowButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

const ButtonBar = () => (
  <Box display="flex" justifyContent="space-between" mt={3}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} type="button" />
  </Box>
);

const AdminConnectedAccountEdit = () => (
  <EditBase redirect="show">
    <Form>
      <Card component={Box} mt={3}>
        <CardContent>
          <Grid container spacing={2} p={2}>
            <Grid item xs={2}>
              <TextInput source="bt_stripe__connect_account_type__c" label="Bt Stripe Connected Account Type" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextInput source="bt_stripe__connected_account_id__c" label="Bt Stripe Connected account ID" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextInput source="bt_stripe__account__r__id__c" label="Bt Stripe Account ID" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextInput source="bt_stripe__payment_gateway__r__id__c" label="Bt Stripe Payment Gateway ID" fullWidth />
            </Grid>
          </Grid>
          <Divider />
          <ButtonBar />
        </CardContent>
      </Card>
    </Form>
  </EditBase>
);

export default AdminConnectedAccountEdit;
