export default ({ palette }) => ({
  styleOverrides: {
    containedPrimary: {
      color: palette.neutral.lightest,
      background: palette.mode === 'light' ? palette.primary.main : palette.primary.dark,
      '&:hover': {
        background: palette.mode === 'light' ? palette.primary.dark : palette.primary.main,
      },
    },
    containedNeutral: {
      color: palette.mode === 'light' ? palette.neutral.darkest : palette.neutral.lightest,
      background: palette.mode === 'light' ? palette.neutral.main : palette.neutral.dark,
      '&:hover': {
        background: palette.mode === 'light' ? palette.neutral.lighter : palette.neutral.darkest,
      },
    },
    containedAccent: {
      color: palette.neutral.lightest,
      background: palette.accent.main,
      '&:hover': {
        background: palette.accent.dark,
      },
    },
  },
});
