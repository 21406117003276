import React from 'react';

import { string } from 'prop-types';

import { Box } from '@mui/material';

import { THIRD_PARTY_WORKER_ACCOUNT_TAB } from '@/constants/thirdPartyWorkerAccount';

import Details from './Details';
import JobHistory from './JobHistory';
import Support from './Support';

const ThirdPartyWorkerContent = ({ currentTab }) => {
  let content;

  switch (currentTab) {
    case THIRD_PARTY_WORKER_ACCOUNT_TAB.DETAILS:
      content = <Details />;
      break;
    case THIRD_PARTY_WORKER_ACCOUNT_TAB.JOB_HISTORY:
      content = <JobHistory />;
      break;
    case THIRD_PARTY_WORKER_ACCOUNT_TAB.SUPPORT:
      content = <Support />;
      break;
    default:
      content = <>Unhandled</>;
  }
  return <Box>{content}</Box>;
};

ThirdPartyWorkerContent.propTypes = {
  currentTab: string.isRequired,
};

export default ThirdPartyWorkerContent;
