import React, { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mx from 'mixpanel-browser';
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  TextField,
  TextInput,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useReference,
  useRefresh,
  useShowContext,
  useUnselectAll,
  useUpdate,
} from 'react-admin';

import { AddCircleOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardHeader, CircularProgress } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import { JobSlotActionContext } from '@/providers/JobSlotAction';
import EmptyResourceList from '@/shared/EmptyResourceList';
import ListViewChangedTracker from '@/shared/events/ListViewChangedTracker';

dayjs.extend(utc);
dayjs.extend(timezone);

const AssignField = () => {
  const { setAction, setMetadata } = useContext(JobSlotActionContext);
  const accountRecord = useRecordContext();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const notify = useNotify();
  const { record: jobSlotRecord } = useShowContext();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: jobSlotRecord?.job_id,
    options: { enabled: Boolean(jobSlotRecord?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const onError = (error) => {
    const overrideObj = { message: '', account_id: '' };
    overrideObj.account_id = accountRecord?.id;
    const errorMessage = error?.body?.detail;

    if (errorMessage.includes(accountRecord?.id)) {
      overrideObj.message = errorMessage.replace(accountRecord?.id, accountRecord?.name);
    } else {
      overrideObj.message = errorMessage;
    }

    setMetadata(overrideObj);
    setAction('override_assign');
  };

  const onSuccess = () => {
    mx.track('Fulfillment - Labor Assigned', {
      resource: resources.JOB_SLOTS,
      resourceId: jobSlotRecord?.id,
      parentResource: resources.JOBS,
      parentResourceId: jobSlotRecord?.job_id,
      confirmationId: orderData?.confirmation_id,
      assignedId: accountRecord?.id,
    });
    notify(`${accountRecord.name} assigned`, {
      type: 'success',
    });

    refresh();
    redirect('show', resources.JOBS, jobSlotRecord.job_id);
  };

  const [update] = useUpdate(
    resources.JOB_SLOTS,
    {
      id: jobSlotRecord?.id,
      data: { account_id: accountRecord.id },
      previousData: {},
    },
    { mutationMode: 'pessimistic', onError, onSuccess },
  );

  return (
    <Button
      startIcon={<AddCircleOutline />}
      onClick={(e) => {
        e.preventDefault();
        update();
      }}
    >
      Assign
    </Button>
  );
};

const AllWorkersDatagrid = () => {
  const { record } = useShowContext();
  const { selectedIds, isFetching, isLoading } = useListContext();

  const { permissions } = usePermissions();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const [prevSelectedIds, setPrevSelectedIds] = useState([]);

  const canAssign = permissions.tools?.fulfillment?.job_slot?.update && permissions.tools?.fulfillment?.job_slot?.assign;

  const isListLoading = isFetching || isLoading;

  useEffect(() => {
    const selectedDiff = selectedIds.length - prevSelectedIds.length;

    if (selectedDiff === 0) {
      return;
    }
    let eventName = 'Resource List - Select';
    if (selectedDiff > 1) {
      eventName = 'Resource List - Select All';
    } else if (selectedDiff < -1) {
      eventName = 'Resource List - Unselect All';
    } else if (selectedDiff === 1) {
      eventName = 'Resource List - Select One';
    } else if (selectedDiff === -1) {
      eventName = 'Resource List - Unselect One';
    }
    mx.track(eventName, {
      resource: resources.ACCOUNTS,
      parentResource: resources.JOB_SLOTS,
      parentResourceId: record?.id,
      grandparentResource: resources.JOBS,
      grandparentResourceId: record?.job_id,
      confirmationId: orderData?.confirmation_id,
      selectedIds,
    });
    setPrevSelectedIds(selectedIds);
  }, [selectedIds]);

  if (isListLoading) {
    return (
      <Box display="flex" justifyContent="center" py={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Datagrid rowClick={null} bulkActionButtons={false} empty={<EmptyResourceList text="No pros found" />}>
      <FunctionField render={(rec) => <Avatar src={rec.image_url} />} />
      <ReferenceField reference={resources.ACCOUNTS} source="id" label="Name" link="show" sortBy="false">
        <TextField source="name" />
      </ReferenceField>
      {canAssign && <AssignField />}
    </Datagrid>
  );
};

const AllWorkerList = () => {
  const { record } = useShowContext();

  const { referenceRecord: jobData, isLoading: isJobLoading } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const unselectAllProsLabor = useUnselectAll(resources.ACCOUNTS);

  useEffect(() => {
    unselectAllProsLabor();
  }, []);

  const [hideDatagrid, setHideDatagrid] = useState(false);

  const handleInputChange = (event) => {
    const newValue = event.target?.value ?? '';

    if (newValue.trim() !== '') {
      setHideDatagrid(true);
    } else {
      setHideDatagrid(false);
    }
  };
  const accountFilters = [<TextInput source="name" alwaysOn autoComplete="off" onChange={handleInputChange} />];

  if (!jobData && isJobLoading) return <>Loading</>;

  return (
    <Card variant="outlined">
      <CardHeader title="Search All Pros" />
      <List
        component={Box}
        resource={resources.ACCOUNTS}
        filter={{
          account_type: ['Bellhop'],
        }}
        pagination={false}
        filters={accountFilters}
        actions={false}
        storeKey={`${resources.ACCOUNTS}.allWorkers.listParams`}
        disableSyncWithLocation
      >
        <ListViewChangedTracker />
        {hideDatagrid ? <AllWorkersDatagrid /> : <EmptyResourceList text="Start typing to search pros" />}
      </List>
    </Card>
  );
};

export default AllWorkerList;
