import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';
import PayoutConfigCreate from '@/shared/accounts/PayoutConfigCreate';
import PayoutConfigEdit from '@/shared/accounts/PayoutConfigEdit';

import ThirdPartyCrewCreate from './Create';
import ThirdPartyCrewSkillsCreate from './Create/Skills';
import ThirdPartyCrewEdit from './Edit';
import ThirdPartyCrewSkillsEdit from './Edit/Skills';
import ThirdPartyCrewList from './List';
import ThirdPartyCrewShow from './Show';

const thirdPartyCrew = () => ({
  name: resources.THIRD_PARTY_CREW,
  create: ThirdPartyCrewCreate,
  list: ThirdPartyCrewList,
  show: ThirdPartyCrewShow,
  children: [
    <Route key="third-party-crew-edit" path=":accountId/:tab/:section/edit" element={<ThirdPartyCrewEdit />} />,
    <Route
      key="third-party-crew-status-edit"
      path=":accountId/:tab/:section/:status/edit"
      element={<ThirdPartyCrewEdit />}
    />,
    <Route
      key="third-party-crew-skill-create"
      path=":accountId/create/third_party_crew_account_skill"
      element={<ThirdPartyCrewSkillsCreate />}
    />,
    <Route
      key="third-party-crew-skill-edit"
      path=":accountId/account_products/:accountProductId/edit"
      element={<ThirdPartyCrewSkillsEdit />}
    />,
    <Route
      key="third-party-crew-payout-config-edit"
      path=":accountId/payout_config/:payoutConfigId/edit"
      element={<PayoutConfigEdit />}
    />,
    <Route
      key="third-party-crew-payout-config-create"
      path=":accountId/payout_config/create"
      element={<PayoutConfigCreate />}
    />,
  ],
});

export default thirdPartyCrew;
