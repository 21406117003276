import React from 'react';

import Compliance from './Compliance';
import OrderAttachments from './OrderAttachments';

const OrderContentCompliance = () => (
  <>
    <Compliance />
    <OrderAttachments />
  </>
);
export default OrderContentCompliance;
