import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import {
  THIRD_PARTY_WORKER_ACCOUNT_DETAIL_SECTION,
  THIRD_PARTY_WORKER_ACCOUNT_OVERVIEW_CARD,
  THIRD_PARTY_WORKER_ACCOUNT_TAB,
} from '@/constants/thirdPartyWorkerAccount';

import * as resources from '@/api/resources';

import ThirdPartyWorkerSectionHeader from '../../../Common/SectionHeader';

const ThirdPartyWorkerContentDetailsSectionHeader = ({ tab, section, title }) => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const isActive = record.status === 'Active';

  let contextMenuItems = [];

  const contextMenuItemOverview = [
    ...(permissions.tools?.hqadmin?.third_party_worker_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.THIRD_PARTY_WORKER}/${record.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
    ...(permissions.tools?.hqadmin?.third_party_worker_accounts?.deactivate && isActive
      ? [
          {
            key: 'patch-deactivate-account',
            onClick: () => {
              navigate(
                `/${resources.THIRD_PARTY_WORKER}/${record.id}/${tab}/${section}/${THIRD_PARTY_WORKER_ACCOUNT_OVERVIEW_CARD.DEACTIVATE}/edit`,
              );
            },
            component: 'Deactivate Third Party Worker',
          },
        ]
      : []),
  ];

  const contextMenuItemDefault = [];

  switch (section) {
    case THIRD_PARTY_WORKER_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      contextMenuItems = contextMenuItemOverview;
      break;
    default:
      contextMenuItems = contextMenuItemDefault;
  }

  return <ThirdPartyWorkerSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

ThirdPartyWorkerContentDetailsSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

ThirdPartyWorkerContentDetailsSectionHeader.defaultProps = {
  tab: THIRD_PARTY_WORKER_ACCOUNT_TAB.DETAILS,
};
export default ThirdPartyWorkerContentDetailsSectionHeader;
