import React from 'react';

import { ArrayField, Form, useShowContext } from 'react-admin';

import { Box, Card, Grid } from '@mui/material';

import AccountPaymentMethod from './AccountPaymentMethod';
import Fees from './Fees';
import Products from './Products';
import QuoteActionForm from './QuoteActionForm';
import QuoteActions from './QuoteActions';
import QuoteTotals from './QuoteTotals';
import QuoteWarnings from './QuoteWarnings';
import ServiceGroups from './ServiceGroups';

const QuoteOverview = () => {
  const { record: quoteRecord } = useShowContext();

  if (!quoteRecord) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Box>
          <QuoteWarnings />
          <QuoteActions />
          <ArrayField source="service_groups">
            <ServiceGroups />
          </ArrayField>
          <ArrayField source="products">
            <Products />
          </ArrayField>
          <ArrayField source="price_adjustments">
            <Fees />
          </ArrayField>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <QuoteTotals />
        </Box>
        <Box mt={2}>
          <AccountPaymentMethod />
        </Box>
        <Box mt={2}>
          <Card>
            <Form>
              <QuoteActionForm />
            </Form>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default QuoteOverview;
