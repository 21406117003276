import _ from 'lodash';

import { QUOTE_PARTNER_TEMPLATES } from '@/constants/quoteTemplates';

const inventoryTransform = (data) => {
  const retData = _.cloneDeep(data);
  if (!retData?.selected_template?.inventoryRequired) {
    retData.inventory.area = 100;
    retData.inventory.is_room_based_inventory = false;
  } else if (retData?.partner_id === 'ZIPPY') {
    retData.inventory.is_room_based_inventory = false;
    retData.inventory.rooms = [];
    if (retData?.selected_template?.id === QUOTE_PARTNER_TEMPLATES.ZIPPY.LOADING.id) {
      retData.inventory.extra_data.zippy_move_type = 'load';
    } else if (retData?.selected_template?.id === QUOTE_PARTNER_TEMPLATES.ZIPPY.UNLOADING.id) {
      retData.inventory.extra_data.zippy_move_type = 'unload';
    }
  } else if (retData?.partner_id === 'OFFERPAD') {
    retData.inventory.is_room_based_inventory = false;
    retData.inventory.rooms = [];
  }

  return retData;
};

export default inventoryTransform;
