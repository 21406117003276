import React from 'react';

import { BooleanField, Datagrid, EditButton, useListContext, usePermissions, useRecordContext } from 'react-admin';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Card, CardContent, CircularProgress, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import QualificationLevelField from '@/fields/QualificationLevelField';
import ServiceNameField from '@/fields/ServiceNameField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import CarriersSectionHeader from '../Common/SectionHeader';

const EditAccountProductButton = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const { product_id: productID, account_id: accountId } = record;

  if (!permissions.tools?.hqadmin?.carrier_account_skills?.update) return null;

  return (
    <EditButton
      label="Edit"
      component={Link}
      to={`/${resources.CARRIERS}/${accountId}/account_products/${productID}/edit`}
    />
  );
};

const SkillsDatagrid = () => {
  const { isLoading, isFetching } = useListContext();

  if (isLoading || isFetching) {
    return (
      <Box minWidth={500} display="flex" justifyContent="center" py={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Datagrid bulkActionButtons={false}>
      <ServiceNameField source="product_id" label="Skill" sortable={false} />
      <QualificationLevelField source="qualification_level" label="Qualification" sortable={false} />
      <BooleanField source="skip_autoassign" label="Skip Auto Assign" sortable={false} />
      <EditAccountProductButton />
    </Datagrid>
  );
};

const Skills = () => {
  // get context of the current record being used
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const contextMenuItems = [
    ...(permissions.tools?.hqadmin?.carrier_accounts?.update
      ? [
          {
            key: 'add',
            onClick: () => {
              navigate(`/${resources.CARRIERS}/${record.id}/create/carrier_account_skill`);
            },
            component: 'Add skill',
          },
        ]
      : []),
  ];

  if (!record) return null;

  return (
    <Box display="flex">
      <Card>
        <CarriersSectionHeader title="Skills" contextMenuItems={contextMenuItems} />
        <Divider />
        <CardContent>
          <Box display="flex">
            <List
              disableSyncWithLocation
              resource={resources.CARRIERS}
              actions={false}
              pagination={false}
              queryOptions={{ meta: { subResource: 'account_products', resourceId: record.id } }}
              empty={<EmptyResourceList text="No Skills on Carrier account" />}
              storeKey={`${resources.CARRIERS}.skills.listParams`}
            >
              <SkillsDatagrid />
            </List>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Skills;
