import React from 'react';

import { bool, func, shape } from 'prop-types';

import { Box, Card, CardActionArea, CardContent, Divider, Tooltip, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import TemplateUnavailableTooltip from './TemplateUnavailableTooltip';

const TemplateCard = ({ template, selectTemplate, selected }) => (
  <Card
    sx={{
      backgroundColor: ({ palette }) => (palette.mode === 'light' ? palette.neutral.lighter : palette.secondary.darkest),
      opacity: template.available ? 1 : 0.6,
      ...(selected
        ? {
            borderColor: ({ palette }) => palette.primary.main,
          }
        : {}),
    }}
  >
    <Tooltip title={template.available ? null : <TemplateUnavailableTooltip template={template} />}>
      <span>
        <CardActionArea onClick={() => selectTemplate(template)} disabled={selected || !template.available}>
          <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">{template.name}</Typography>
            {selected ? <CheckCircle color="primary" /> : null}
          </Box>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
              {template.icons?.map((icon) => icon)}
            </Box>
          </CardContent>
        </CardActionArea>
      </span>
    </Tooltip>
  </Card>
);

TemplateCard.propTypes = {
  template: shape({}).isRequired,
  selectTemplate: func.isRequired,
  selected: bool,
};

TemplateCard.defaultProps = {
  selected: false,
};

export default TemplateCard;
