import React from 'react';
import { BooleanField, DateField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

import CurrencyField from '@/fields/CurrencyField';

const AdminOrderItemShow = () => (
  <Show>
    <SimpleShowLayout>
      <CurrencyField source="unitprice" textAlign="left" />
      <TextField source="marketplace_match_status" emptyText="N/A" />
      <TextField source="createdbyid" emptyText="N/A" />
      <TextField source="end_date_time__c" emptyText="N/A" />
      <TextField source="pricebookentry__id__c" emptyText="N/A" />
      <TextField source="pricing_strategy__c" emptyText="N/A" />
      <TextField source="skip_autoassign__c" emptyText="N/A" />
      <TextField source="tip_job_slot__c" emptyText="N/A" />
      <TextField source="_hc_err" emptyText="N/A" />
      <DateField source="createddate" emptyText="N/A" />
      <TextField source="end_date_time_time_zone__c" emptyText="N/A" />
      <TextField source="lastmodifieddate" emptyText="N/A" />
      <TextField source="product_family__c" emptyText="N/A" />
      <TextField source="start_date_time__c" emptyText="N/A" />
      <TextField source="tip_job_slot__r__id__c" emptyText="N/A" />
      <TextField source="product_id_rollup__c" emptyText="N/A" />
      <TextField source="appeasement_context__c" emptyText="N/A" />
      <TextField source="currency__c" emptyText="N/A" />
      <TextField source="end_market__c" emptyText="N/A" />
      <TextField source="marketplace_match_expiry__c" emptyText="N/A" />
      <TextField source="product2__id__c" emptyText="N/A" />
      <TextField source="start_date_time_time_zone__c" emptyText="N/A" />
      <TextField source="tip_type__c" emptyText="N/A" />
      <TextField source="pricebookentryid" emptyText="N/A" />
      <TextField source="appeasement_reason__c" emptyText="N/A" />
      <TextField source="customer_reviewed__c" emptyText="N/A" />
      <TextField source="end_market__r__market_id__c" emptyText="N/A" />
      <TextField source="order__id__c" emptyText="N/A" />
      <TextField source="product2id" emptyText="N/A" />
      <TextField source="start_market__c" emptyText="N/A" />
      <TextField source="tyson_assignment__c" emptyText="N/A" />
      <TextField source="sfid" emptyText="N/A" />
      <TextField source="appeasement_type__c" emptyText="N/A" />
      <TextField source="description" emptyText="N/A" />
      <TextField source="fulfilled__c" emptyText="N/A" />
      <TextField source="order_item_lead_account__r__id__c" emptyText="N/A" />
      <TextField source="promo_id__c" emptyText="N/A" />
      <TextField source="start_market__r__market_id__c" emptyText="N/A" />
      <TextField source="_hc_lastop" emptyText="N/A" />
      <TextField source="billable_duration__c" emptyText="N/A" />
      <TextField source="discount__c" emptyText="N/A" />
      <TextField source="guaranteed__c" emptyText="N/A" />
      <TextField source="orderid" emptyText="N/A" />
      <TextField source="promo_id__r__code__c" emptyText="N/A" />
      <TextField source="status__c" emptyText="N/A" />
      <TextField source="workers__c" emptyText="N/A" />
      <TextField source="quotelineitemid" emptyText="N/A" />
      <TextField source="completed_date_time__c" emptyText="N/A" />
      <TextField source="driving_distance__c" emptyText="N/A" />
      <BooleanField source="isdeleted" emptyText="N/A" />
      <TextField source="package_quote_id__c" emptyText="N/A" />
      <NumberField source="quantity" emptyText="N/A" />
      <TextField source="supply_type__c" emptyText="N/A" />
      <TextField source="systemmodstamp" emptyText="N/A" />
      <TextField source="delta_reversed__c" emptyText="N/A" />
      <TextField source="id" emptyText="N/A" />
      <TextField source="concurrent__c" emptyText="N/A" />
      <NumberField source="duration__c" emptyText="N/A" />
      <TextField source="lastmodifiedbyid" emptyText="N/A" />
      <TextField source="payout_strategy__c" emptyText="N/A" />
      <TextField source="sales_price__c" emptyText="N/A" />
      <TextField source="order_item_lead_account__c" emptyText="N/A" />
    </SimpleShowLayout>
  </Show>
);

export default AdminOrderItemShow;
