import React from 'react';
import { BooleanField, Datagrid, FunctionField, TextField, useGetOne, useShowContext } from 'react-admin';

import { Tooltip } from '@mui/material';

import ListBase from '@/ReactAdmin/List/ListBase';
import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import PrefDateTimeField from '@/fields/PrefDateTimeField';

import TransactionInfo from './TransactionInfo';
import TransactionMenu from './TransactionMenu';
import TransactionTotals from './TransactionTotals';

const Transactions = () => {
  const { record: { id: orderId } = {} } = useShowContext();

  const { data: accountPaymentMethod } = useGetOne(resources.ORDERS, {
    id: orderId,
    meta: {
      subResource: 'payment_info',
    },
  });

  if (!orderId) return null;

  return (
    <ListBase
      resource={resources.ORDERS}
      queryOptions={{ meta: { subResource: 'transactions', resourceId: orderId } }}
      filter={{ acct_type: ['Charge', 'Adjustment'] }}
    >
      <TransactionTotals />
      <Datagrid bulkActionButtons={false}>
        <TransactionInfo />
        <TextField source="description" sortable={false} />
        <TextField source="payment_status" sortable={false} />
        <TextField source="transaction_status" sortable={false} />
        <BooleanField source="authorize_only" sortable={false} />
        <BooleanField source="auto_capture" sortable={false} />
        <PrefDateTimeField source="date_to_process" dayjsFormat="MM/DD/YYYY" sortable={false} />
        <PrefDateTimeField source="due_date" dayjsFormat="MM/DD/YYYY" sortable={false} />
        <PrefDateTimeField
          source="processed_date"
          dayjsFormat="MM/DD/YYYY"
          label="Processed Date"
          invalidText="Pending"
          sortable={false}
        />
        <CurrencyField source="amount" sortable={false} />
        <CurrencyField source="retained_amount" sortable={false} />
        <FunctionField
          label="Payment Method"
          render={(record) => {
            const isDefault = record.payment_method_id === accountPaymentMethod?.id;
            return isDefault ? (
              <Tooltip title="Payment method on transaction matches customer account default payment method">
                <span>Default</span>
              </Tooltip>
            ) : (
              <Tooltip title="Payment method on transaction does NOT match customer account default payment method">
                <span>Non Default</span>
              </Tooltip>
            );
          }}
        />
        <TransactionMenu />
      </Datagrid>
    </ListBase>
  );
};

export default Transactions;
