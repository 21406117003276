import * as resources from '@/api/resources';

import PromoCodeCreate from './Create';
import PromoCodeEdit from './Edit';
import PromoCodeList from './List';
import PromoCodeShow from './Show';

const promoCodes = () => ({
  name: resources.PROMO_CODES,
  create: PromoCodeCreate,
  edit: PromoCodeEdit,
  list: PromoCodeList,
  show: PromoCodeShow,
});

export default promoCodes;
