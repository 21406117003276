import React, { useContext, useEffect, useState } from 'react';

import { ChipField, RecordContextProvider, ReferenceField, useGetManyAggregate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Edit, Person, RemoveCircleOutline, Warehouse, Warning } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, CardHeader, Chip, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import LocationWarnings from '@/components/resources/quotes/shared/LocationWarnings';
import locationsHasStatePrivacyScript from '@/components/resources/quotes/shared/scriptPrompts/locationsHasStatePrivacyScript';
import { SCRIPT_MODALS } from '@/constants/scriptModals';
import { QuoteContext } from '@/providers/Quote';
import formatAddressParts from '@/utils/locations/formatAddressParts';

import RouteInfo from '@/shared/RouteInfo';
import getUniqueMarkets from '../../shared/getUniqueMarkets';
import EditLocationModal from './EditLocationModal';

const Locations = () => {
  const { watch } = useFormContext();
  const [locations, setLocations] = useState(watch('locations'));
  const [uniqueMarkets, setUniqueMarkets] = useState(getUniqueMarkets(watch('locations')));

  const partner = useWatch({ name: 'partner_id' });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();

  const { setValue } = useFormContext();
  const { openScriptModal } = useContext(QuoteContext);

  const { refetch } = useGetManyAggregate(
    resources.MARKETS,
    { ids: uniqueMarkets },
    {
      enabled: Boolean(uniqueMarkets?.length),
      onSuccess: (data) => {
        setValue('marketsData', data);
      },
    },
  );

  const selectLocation = (locationId) => {
    setSelectedLocation(locationId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedLocation(null);
  };

  const updateLocations = (newLocations) => {
    // Check if new locations needs privacy script - if old has it skip as the modal would've been shown before
    const needsPrivacyModal = locationsHasStatePrivacyScript(newLocations) && !locationsHasStatePrivacyScript(locations);
    if (needsPrivacyModal) {
      openScriptModal(SCRIPT_MODALS.STATE_PRIVACY);
    }
    setLocations(newLocations);
    setUniqueMarkets(getUniqueMarkets(newLocations));
    setValue('locations', newLocations, { shouldDirty: true });
  };

  const removeLocation = (locationId) => {
    updateLocations(locations?.filter(({ location }) => location.id !== locationId));
  };

  useEffect(() => {
    // This fires more often than it should, but is only reliable way to enforce market data gets loaded which is required for creating quote
    // Attempts to utilize useMemo kept causing this not to fire when it needed to
    refetch();
  }, [locations, uniqueMarkets]);

  const isZippy = partner === 'ZIPPY';

  const zippyChip = (index) => {
    if (index === 0) return <Chip icon={<Warehouse />} label="Warehouse Address" />;
    if (index === 1) return <Chip icon={<Person />} label="Customer Address" />;
    return <Chip icon={<Warning />} label="Unknown - Remove Address" />;
  };

  return (
    <>
      <EditLocationModal
        open={modalOpen}
        onClose={closeModal}
        selectedLocation={selectedLocation}
        locations={locations}
        setLocations={updateLocations}
      />
      <Card component={Box} mt={3}>
        <CardHeader title="Locations" />
        {isZippy ? (
          <>
            <Divider />
            <Box p={2}>
              <Alert variant="filled" severity="info">
                For Zippyshell orders - enter warehouse address first and then customer address second
              </Alert>
            </Box>
          </>
        ) : null}
        <Divider />
        {locations?.length ? (
          <>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box flex={1}>
                  {locations?.map(({ location }, index) => (
                    <Box display="flex" key={location.id} alignItems="center">
                      <Button onClick={() => selectLocation(location.id)} startIcon={<Edit />}>
                        Edit
                      </Button>
                      <Button color="accent" onClick={() => removeLocation(location.id)} startIcon={<RemoveCircleOutline />}>
                        Remove
                      </Button>
                      <Typography px={1}>{formatAddressParts(location)}</Typography>
                      <ReferenceField
                        record={{ market_id: location.market_id }}
                        source="market_id"
                        reference={resources.MARKETS}
                      >
                        <ChipField source="name" />
                      </ReferenceField>
                      {isZippy ? zippyChip(index) : null}
                      <RecordContextProvider value={location}>
                        <LocationWarnings />
                      </RecordContextProvider>
                    </Box>
                  ))}
                </Box>
                {locations?.length > 1 ? (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <RouteInfo locations={locations?.map(({ location }) => location)} />
                  </>
                ) : null}
              </Box>
            </CardContent>
            <Divider />
          </>
        ) : null}
        <CardContent>
          <Button disabled={(isZippy && locations?.length >= 2) ?? null} onClick={() => selectLocation('')}>
            Add Location
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default Locations;
