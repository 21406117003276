import React from 'react';

import { useParams } from 'react-router';

import {
  CARRIER_ACCOUNT_CONTACT_INFO_CARD,
  CARRIER_ACCOUNT_DETAIL_SECTION,
  CARRIER_ACCOUNT_OVERVIEW_CARD,
} from '@/constants/carrierAccount';

import CarrierDetailsAuthorityEdit from './Authority';
import CarrierDetailsContactInfoEdit from './ContactInfo';
import CarrierDetailsContactInfoLocationEdit from './ContactInfo/Location';
import CarrierDetailsContractEdit from './Contract';
import CarrierDetailsOverviewEdit from './Overview';
import CarrierDetailsOverviewDeactivateAccount from './Overview/DeactivateAccount';
import CarrierDetailsOverviewStatusEdit from './Overview/Status';
import CarrierDetailsPaymentEdit from './Payment';
import CarrierDetailsTruckEdit from './Truck';

const CarrierDetailsEdit = () => {
  const { section: currentSection, status } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case CARRIER_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      switch (status) {
        case CARRIER_ACCOUNT_OVERVIEW_CARD.STATUS:
          EditComponent = <CarrierDetailsOverviewStatusEdit />;
          break;
        case CARRIER_ACCOUNT_OVERVIEW_CARD.DEACTIVATE:
          EditComponent = <CarrierDetailsOverviewDeactivateAccount />;
          break;
        default:
          EditComponent = <CarrierDetailsOverviewEdit />;
          break;
      }
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.CONTACT_INFO:
      switch (status) {
        case CARRIER_ACCOUNT_CONTACT_INFO_CARD.LOCATION:
          EditComponent = <CarrierDetailsContactInfoLocationEdit />;
          break;
        default:
          EditComponent = <CarrierDetailsContactInfoEdit />;
          break;
      }
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.CONTRACT:
      EditComponent = <CarrierDetailsContractEdit />;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.PAYMENT:
      EditComponent = <CarrierDetailsPaymentEdit />;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.TRUCK:
      EditComponent = <CarrierDetailsTruckEdit />;
      break;
    case CARRIER_ACCOUNT_DETAIL_SECTION.AUTHORITY:
      EditComponent = <CarrierDetailsAuthorityEdit />;
      break;
    default:
      break;
  }

  return EditComponent;
};

export default CarrierDetailsEdit;
