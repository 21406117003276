import { useRecordContext } from 'react-admin';
import { string } from 'prop-types';
import getProductName from '@/utils/productName/getProductName';

/*
 * ServiceNameField
 *
 * Given a product code will display service name
 *
 */
const ProductNameField = ({ source }) => {
  const record = useRecordContext();

  if (!record[source]) return null;

  return getProductName(record[source]);
};

ProductNameField.propTypes = {
  source: string.isRequired,
};

export default ProductNameField;
