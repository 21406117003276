import React from 'react';

import { string, func } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';

import { MARKET_ACTION } from '@/constants/market';

import SectionHeader from '../../../Common/SectionHeader';

const MarketProductSectionHeader = ({ title, setAction, setMetadata }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const canCreateMarketProduct = permissions?.tools?.hqadmin?.market_product?.admin;

  const contextMenuItems = [
    ...(canCreateMarketProduct
      ? [
          {
            key: 'create',
            onClick: () => {
              setAction(MARKET_ACTION.CREATE_MARKET_PRODUCT);
              setMetadata(record);
            },
            component: 'create new market product',
          },
        ]
      : []),
  ];

  return <SectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

MarketProductSectionHeader.propTypes = {
  title: string.isRequired,
  setAction: func.isRequired,
  setMetadata: func.isRequired,
};

export default MarketProductSectionHeader;
