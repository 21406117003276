import React, { useState } from 'react';

import dayjs from 'dayjs';
import { Datagrid, SaveButton, TextField, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Add, Save } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES } from '@/constants/pricingLayers';
import MarketNameField from '@/fields/MarketNameField';
import ServiceNameField from '@/fields/ServiceNameField';

const FormControls = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { getValues, reset, trigger } = useFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorData, setErrorData] = useState();

  const dataProvider = useDataProvider();

  const { mutate: createMultiple } = useMutation(([resource, params]) => dataProvider.createMultiple(resource, params));

  const transform = (data) => ({
    type: PRICING_LAYER_TYPES.SERVICE,
    effective_date: dayjs(data.effective_date).format('YYYY-MM-DD'),
    service_ids: data.service_ids,
    market_ids: data.market_ids,
    base_price: data.base_price,
    base_fee: data.base_fee,
    per_worker_price: data.per_worker_price,
    fixed_price_modifier: data.fixed_price_modifier,
  });

  const onSettled = () => {
    setIsSubmitting(false);
  };

  const onSuccess = (redirectOnSuccess) => {
    notify('Pricing Layers created successfully', { type: 'success' });
    setErrorData(null);
    if (redirectOnSuccess) {
      redirect(`/${resources.PRICING_LAYERS}/service_layer_summary`);
    } else {
      reset();
    }
  };

  const onError = (error) => {
    const { message, data } = error.body;
    if (error.status === 409) {
      notify(`${message} - ${data?.length} conflicting rows`, { type: 'error' });
      setErrorData(data);
      return;
    }
    notify(`Error occurred creating Pricing Layers - ${message}`, { type: 'error' });
  };

  const submitForm = async (e, redirectOnSuccess) => {
    setIsSubmitting(true);
    e.preventDefault();
    const result = await trigger();
    const data = getValues();
    if (result) {
      createMultiple(
        [
          `${resources.PRICING_LAYERS}/batch`,
          {
            data: transform(data),
          },
        ],
        {
          onSettled,
          onSuccess: () => onSuccess(redirectOnSuccess),
          onError,
        },
      );
    } else {
      notify('Form is invalid, check above for errors', { type: 'error' });
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Card mt={3} component={Box}>
        <CardContent component={Box} display="flex" justifyContent="space-between">
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="neutral"
              onClick={() => redirect(`/${resources.PRICING_LAYERS}/service_layer_summary`)}
            >
              Cancel
            </Button>
            <Button variant="contained" color="neutral" onClick={() => reset()}>
              Reset Form
            </Button>
          </Box>
          <Box display="flex" gap={2}>
            <SaveButton
              type="button"
              label="Save and Add More"
              icon={isSubmitting ? <CircularProgress size={18} /> : <Add />}
              onClick={(e) => submitForm(e, false)}
              disabled={isSubmitting ?? null}
            />
            <SaveButton
              type="button"
              label="Save"
              onClick={(e) => submitForm(e, true)}
              icon={isSubmitting ? <CircularProgress size={18} /> : <Save />}
              disabled={isSubmitting ?? null}
            />
          </Box>
        </CardContent>
      </Card>
      {errorData ? (
        <Card mt={3} component={Box}>
          <CardHeader title="Error Data" />
          <Divider />
          <Datagrid data={errorData} bulkActionButtons={false} sort={false}>
            <TextField source="effective_date" />
            <ServiceNameField source="service_id" />
            <MarketNameField source="start_market_id" label="Market" />
          </Datagrid>
        </Card>
      ) : null}
    </>
  );
};

export default FormControls;
