import * as resources from '@/api/resources';

export const AVAILABLE_TAGS = {
  PARTNER_TIER_1: {
    name: 'Partner - Tier 1',
    available: [resources.ACCOUNTS, resources.ORDERS],
  },
  PARTNER_TIER_2: {
    name: 'Partner - Tier 2',
    available: [resources.ACCOUNTS, resources.ORDERS],
  },
  PARTNER_TIER_3: {
    name: 'Partner - Tier 3',
    available: [resources.ACCOUNTS, resources.ORDERS],
  },
};

export const ACCOUNT_TAG_CHOICES = Object.keys(AVAILABLE_TAGS)
  .filter((tagKey) => AVAILABLE_TAGS[tagKey].available.includes(resources.ACCOUNTS))
  .map((tagKey) => ({ id: tagKey, name: AVAILABLE_TAGS[tagKey].name }));

export const ORDER_TAG_CHOICES = Object.keys(AVAILABLE_TAGS)
  .filter((tagKey) => AVAILABLE_TAGS[tagKey].available.includes(resources.ORDERS))
  .map((tagKey) => ({ id: tagKey, name: AVAILABLE_TAGS[tagKey].name }));
