import React from 'react';

import { Labeled, TextField, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid, IconButton } from '@mui/material';

import { THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION, THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewContentDetailsSectionHeader from '../Common/SectionHeader';

const ThirdPartyCrewDetailsPaymentSection = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card mt={3} component={Box}>
      <ThirdPartyCrewContentDetailsSectionHeader
        title="Payment"
        tab={THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS}
        section={THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.PAYMENT}
      />

      <Divider />
      <CardContent>
        <Grid container spacing={2} px={2}>
          <Grid item xs={3}>
            <Labeled>
              <TextField source="payout_provider" />
            </Labeled>
          </Grid>
          {record?.payout_provider !== 'PayReel' ? (
            <>
              <Grid item xs={3}>
                <Labeled>
                  <TextField source="payout_account_type" />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <TextField source="account_payout_config" emptyText="N/A" />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <Labeled label="Stripe link">
                    <Box display="flex">
                      <IconButton
                        href={`${process.env.REACT_APP_STRIPE_URI}/connect/accounts/${record?.stripe_id}/activity`}
                        target="_blank"
                      >
                        <Box width={30} height={30} borderRadius="50%" overflow="hidden">
                          <img src="/svgs/stripe/stripe-icon-square.svg" alt="Stripe Logo" width="100%" />
                        </Box>
                      </IconButton>
                    </Box>
                  </Labeled>
                </Labeled>
              </Grid>
            </>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDetailsPaymentSection;
