import { useCustomParams } from '@/hooks';
import { ListItemIcon, MenuItem, styled, Tooltip, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { element, func, object, oneOfType, shape, string } from 'prop-types';
import React, { forwardRef, useCallback } from 'react';
import { MenuItemLinkClasses, useSidebarState } from 'react-admin';
import { useMatch } from 'react-router';
import { Link } from 'react-router-dom';

const LinkRef = forwardRef((props, ref) => <Link ref={ref} {...props} />);

const StyledMenuItem = styled(MenuItem, {
  name: 'RaMenuItemLink',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.text.secondary,

  [`&.${MenuItemLinkClasses.active}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${MenuItemLinkClasses.icon}`]: { minWidth: theme.spacing(5) },
}));

const MenuItemLink = forwardRef((props, ref) => {
  const { className, to, targetView, primaryText, leftIcon, onClick, tooltipProps, ...rest } = props;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { view } = useCustomParams();
  const [open, setOpen] = useSidebarState();
  const handleMenuTap = useCallback(
    (e) => {
      if (isSmall) {
        setOpen(false);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [setOpen, isSmall, onClick],
  );

  const destination = (typeof to === 'string' ? to : to?.pathname) || '';
  const match = useMatch({ path: destination }) && targetView === view;

  const menuItem = () => (
    <StyledMenuItem
      className={clsx(className, {
        [MenuItemLinkClasses.active]: !!match,
      })}
      component={LinkRef}
      ref={ref}
      to={to}
      {...rest}
      onClick={handleMenuTap}
    >
      {leftIcon && <ListItemIcon className={MenuItemLinkClasses.icon}>{leftIcon}</ListItemIcon>}
      {primaryText}
    </StyledMenuItem>
  );

  return open ? (
    menuItem()
  ) : (
    <Tooltip title={primaryText} placement="right" {...tooltipProps}>
      {menuItem()}
    </Tooltip>
  );
});

MenuItemLink.propTypes = {
  className: string,
  leftIcon: element,
  onClick: func,
  primaryText: string,
  to: oneOfType([string, object]).isRequired,
  tooltipProps: shape({}),
  targetView: string,
};

MenuItemLink.defaultProps = {
  className: null,
  leftIcon: null,
  onClick: null,
  primaryText: '',
  tooltipProps: {},
  targetView: null,
};

export default MenuItemLink;
