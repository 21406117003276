import React from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  TextField,
  usePermissions,
  useShowContext,
} from 'react-admin';

import { Add, Cached, Lock, LockOpen } from '@mui/icons-material';
import { Box, Card, Divider, Button as MuiButton, Typography } from '@mui/material';

import ListBase from '@/ReactAdmin/List/ListBase';
import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import WorkerTransactionTypeField from '@/fields/WorkerTransactionTypeField';
import useReconcileJobSlotTransactions from '@/hooks/transactions/useReconcileJobSlotTransactions';

import TransactionMenu from './TransactionMenu';

const Transactions = () => {
  const { record } = useShowContext();
  const { permissions } = usePermissions();

  const [{ reconcileTransactions }, { isLoading: reconcileTransactionsIsLoading }] = useReconcileJobSlotTransactions();

  if (!record) return null;

  return (
    <Card>
      <Box px={2} p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Transactions</Typography>
        {record?.account_id && permissions.tools?.fulfillment?.claim_bonus?.create ? (
          <Button
            variant="outlined"
            startIcon={<Add />}
            component={Link}
            to={{
              pathname: `/${resources.JOB_SLOT_TRANSACTIONS}/create`,
              state: {
                record: {
                  job_slot_id: record.id,
                },
              },
            }}
            label="Add"
          />
        ) : null}
        {permissions?.tools?.hqadmin?.job_slot_transactions?.reconcile ? (
          <MuiButton
            variant="outlined"
            startIcon={<Cached />}
            onClick={() => {
              reconcileTransactions({ jobSlotId: record?.id });
            }}
            disabled={reconcileTransactionsIsLoading ?? null}
          >
            Reconcile
          </MuiButton>
        ) : null}
      </Box>
      <Divider />
      <ListBase
        resource={resources.JOB_SLOT_TRANSACTIONS}
        filter={{ job_slot_id: record?.id }}
        storeKey={`${resources.JOB_SLOT_TRANSACTIONS}.listParams`}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            render={() => (
              <Box display="flex">
                {record?.account_id ? <WorkerTransactionTypeField source="transaction_type" label="" /> : null}
                <Box display="flex">
                  <BooleanField
                    source="locked"
                    looseValue
                    TrueIcon={Lock}
                    FalseIcon={LockOpen}
                    valueLabelTrue="LOCKED"
                    valueLabelFalse="Unlocked"
                  />
                </Box>
              </Box>
            )}
          />
          <TextField source="transaction_status" />
          <DateField source="date_to_process" />
          <CurrencyField source="amount" textAlign="left" />
          <BooleanField source="auto_capture" />
          <TextField source="reason" />
          <DateField source="processed_date" emptyText="Pending" />
          <FunctionField
            label="Paid Amount"
            render={(txn) =>
              txn.processed_date ? (
                <CurrencyField source="retained_amount" textAlign="left" />
              ) : (
                <TextFieldWrapper value="Pending" />
              )
            }
          />
          <TransactionMenu />
        </Datagrid>
      </ListBase>
    </Card>
  );
};

export default Transactions;
