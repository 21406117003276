import React from 'react';
import { BooleanInput, DateField, DateTimeInput, FunctionField, ReferenceField, SelectInput, TextField } from 'react-admin';

import { Check, Clear } from '@mui/icons-material';

import * as resources from '@/api/resources';
import { GUARANTEED_STATUS, MATCH_STATUS } from '@/constants/marketplace';
import MarketNameField from '@/fields/MarketNameField';
import OrderFulfillmentStatusField from '@/fields/OrderFulfillmentStatusField';

const ordersFulfillmentColumns = [
  <TextField source="confirmation_id" key="confirmation_id" />,
  <TextField source="status" key="status" />,

  <DateField source="start_date_time" label="Start Date" key="start_date_time" />,
  <DateField source="end_date_time" label="End Date" key="end_date_time" />,
  <ReferenceField source="account_id" label="Customer" reference={resources.CUSTOMERS} link="show" key="account_id">
    <TextField source="name" />
  </ReferenceField>,
  <MarketNameField source="start_market_id" label="Start Market" key="start_market_id" />,
  <MarketNameField source="end_market_id" label="End Market" key="end_market_id" />,
  <ReferenceField source="partner_id" label="Partner" reference={resources.ACCOUNTS} key="partner_id">
    <TextField source="name" />
  </ReferenceField>,
  <ReferenceField source="affiliate_id" label="Affiliate" reference={resources.ACCOUNTS} key="affiliate_id">
    <TextField source="name" />
  </ReferenceField>,
  <FunctionField render={(record) => (record.reschedule_type === 'HQ' ? 'Yes' : 'No')} label="HQRS" key="hqrs" />,
  <FunctionField label="Guaranteed" render={(record) => (record.guaranteed ? <Check /> : <Clear />)} />,
  <FunctionField render={(record) => (record.long_distance ? 'LD' : 'Local')} key="local_ld" />,
  <OrderFulfillmentStatusField key="fulfillment_status" />,
];

const ordersFulfillmentFilters = [
  <BooleanInput source="fulfilled" />,
  <BooleanInput source="reschedule_type" label="HQRS" />,
  <DateTimeInput source="start_date_time_gte" label="Starts After" />,
  <DateTimeInput source="start_date_time_lte" label="Starts Before" />,
  <DateTimeInput source="created_at_gte" label="Created After" />,
  <DateTimeInput source="created_at_lte" label="Created Before" />,
  <SelectInput source="marketplace_match_status" label="Match Status" choices={MATCH_STATUS} />,
  <SelectInput source="guaranteed" choices={GUARANTEED_STATUS} />,
];

const ordersFulfillmentFilterDefaultValues = {
  fulfilled: false,
};

export { ordersFulfillmentColumns, ordersFulfillmentFilterDefaultValues, ordersFulfillmentFilters };
