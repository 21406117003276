import React, { useEffect, useState } from 'react';

import {
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useNavigate } from 'react-router';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import ServiceNameFilter from '@/components/filters/ServiceNameFilter';
import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPE_OPTIONS, SERVICES } from '@/constants/pricingLayers';
import CurrencyField from '@/fields/CurrencyField';
import DayOfWeekField from '@/fields/DayOfWeekField';
import MarketNameField from '@/fields/MarketNameField';
import MonthOfYearField from '@/fields/MonthOfYearField';
import ServiceNameField from '@/fields/ServiceNameField';
import { useCustomParams } from '@/hooks';
import EmptyResourceList from '@/shared/EmptyResourceList';
import { Add } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Tab, Tabs } from '@mui/material';
import dayjs from 'dayjs';
import { string } from 'prop-types';

const pricingLayerFilters = [
  <DateInput source="effective_date" label="Effective date equals" />,
  <DateInput source="effective_date_lt" label="Effective date before" />,
  <DateInput source="effective_date_lte" label="Effective date before or equal" />,
  <DateInput source="effective_date_gt" label="Effective date after" />,
  <DateInput source="effective_date_gte" label="Effective date after or equal" />,
  <ServiceNameFilter alwaysOn source="service_id" multiSelect={false} choices={Object.values(SERVICES)} />,
];

const pricingDateLayerFilters = [
  <ReferenceInput
    alwaysOn
    source="market_id"
    reference={resources.MARKETS}
    perPage={999}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const pricingLayerTypeFilters = {
  [PRICING_LAYER_TYPES.SERVICE]: [
    ...pricingLayerFilters,
    <ReferenceInput
      alwaysOn
      source="start_market_id"
      reference={resources.MARKETS}
      perPage={999}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="end_market_id"
      reference={resources.MARKETS}
      perPage={999}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <SelectInput
      alwaysOn
      source="partner_id"
      choices={[
        { id: 'ALL_MY_SONS', name: 'All My Sons' },
        { id: 'COOPER', name: 'Mr. Cooper' },
        { id: 'CUBESMART', name: 'Cubesmart' },
        { id: 'HAVENLY', name: 'Havenly Inc' },
        { id: 'HELLO_ALFRED', name: 'Hello Alfred' },
        { id: 'MOVED', name: 'Moved.com' },
        { id: 'OFFERPAD', name: 'Offerpad' },
        { id: 'PODS', name: 'PODS' },
        { id: 'Recruit_Certs', name: 'Recruit Certification' },
        { id: 'ReloSolutionsGroup', name: 'Relo Solutions Group' },
        { id: 'SHYFT', name: 'Shyft' },
        { id: 'WALKBOARD', name: 'Walkboard' },
        { id: 'VIRTUO', name: 'Virtuo' },
        { id: 'ZIPPY', name: 'Zippyshell' },
        { id: 'ZIPPYCORPRELO', name: 'Zippy Corp Relo' },
      ]}
    />,
  ],
  [PRICING_LAYER_TYPES.DAY_OF_MONTH]: [...pricingLayerFilters, ...pricingDateLayerFilters],
  [PRICING_LAYER_TYPES.DAY_OF_WEEK]: [...pricingLayerFilters, ...pricingDateLayerFilters],
  [PRICING_LAYER_TYPES.MONTH_OF_YEAR]: [...pricingLayerFilters, ...pricingDateLayerFilters],
  [PRICING_LAYER_TYPES.HOUR_OF_DAY]: [...pricingLayerFilters, ...pricingDateLayerFilters],
  [PRICING_LAYER_TYPES.LEAD_TIME]: [...pricingLayerFilters, ...pricingDateLayerFilters],
};

const PricingLayerActions = ({ layerType }) => {
  const { effective_date: effectiveDate } = useRecordContext();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const canDeleteLayer = permissions.tools?.hqadmin?.pricing_layers?.delete;
  const isLayerInFuture = dayjs(effectiveDate).isAfter(dayjs());

  if (!canDeleteLayer) return null;

  return (
    <Box display="flex" gap={2}>
      {canDeleteLayer && isLayerInFuture ? (
        <DeleteButton
          confirmTitle={false}
          mutationMode="pessimistic"
          mutationOptions={{
            meta: {
              data: {
                type: layerType,
              },
            },
            onSuccess: () => navigate(`/${resources.PRICING_LAYERS}?view=${layerType}`),
          }}
        />
      ) : null}
    </Box>
  );
};

PricingLayerActions.propTypes = {
  layerType: string.isRequired,
};

const PricingLayersList = () => {
  const { view } = useCustomParams();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const validView = Object.values(PRICING_LAYER_TYPES).includes(view);

  const [layerType, setLayerType] = useState(validView ? view : PRICING_LAYER_TYPES.SERVICE);

  const handleTabChange = (_, newType) => navigate(`/${resources.PRICING_LAYERS}?view=${newType}`);

  const commonColumns = [
    <DateField key="effective_date" source="effective_date" />,
    <ServiceNameField key="service_id" source="service_id" />,
  ];

  const columns = {
    [PRICING_LAYER_TYPES.SERVICE]: [
      ...commonColumns,
      <MarketNameField key="start_market_id" source="start_market_id" />,
      <MarketNameField key="end_market_id" source="end_market_id" />,
      <CurrencyField key="base_price" source="base_price" />,
      <CurrencyField key="per_worker_price" source="per_worker_price" />,
      <NumberField key="fixed_price_modifier" source="fixed_price_modifier" />,
      <CurrencyField key="base_fee" source="base_fee" />,
      <ReferenceField key="partner_id" source="partner_id" reference={resources.ACCOUNTS} sortable={false}>
        <TextField source="name" />
      </ReferenceField>,
    ],
    [PRICING_LAYER_TYPES.DAY_OF_MONTH]: [
      ...commonColumns,
      <MarketNameField key="market_id" source="market_id" />,
      <TextField key="day_of_month" source="day_of_month" />,
      <CurrencyField key="price" source="price" />,
      <NumberField key="multiplier" source="multiplier" />,
    ],
    [PRICING_LAYER_TYPES.DAY_OF_WEEK]: [
      ...commonColumns,
      <MarketNameField key="market_id" source="market_id" />,
      <DayOfWeekField key="day_of_week" source="day_of_week" oneIndexed />,
      <CurrencyField key="price" source="price" />,
      <NumberField key="multiplier" source="multiplier" />,
    ],
    [PRICING_LAYER_TYPES.MONTH_OF_YEAR]: [
      ...commonColumns,
      <MarketNameField key="market_id" source="market_id" />,
      <MonthOfYearField key="month_of_year" source="month_of_year" oneIndexed />,
      <CurrencyField key="price" source="price" />,
      <NumberField key="multiplier" source="multiplier" />,
    ],
    [PRICING_LAYER_TYPES.HOUR_OF_DAY]: [
      ...commonColumns,
      <MarketNameField key="market_id" source="market_id" />,
      <NumberField key="hour_of_day" source="hour_of_day" />,
      <CurrencyField key="price" source="price" />,
      <NumberField key="multiplier" source="multiplier" />,
    ],
    [PRICING_LAYER_TYPES.LEAD_TIME]: [
      ...commonColumns,
      <MarketNameField key="market_id" source="market_id" />,
      <NumberField key="lead_time_in_days" source="lead_time_in_days" />,
      <CurrencyField key="price" source="price" />,
      <NumberField key="multiplier" source="multiplier" />,
    ],
  };

  useEffect(() => {
    if (validView) {
      setLayerType(view);
    } else {
      navigate(`/${resources.PRICING_LAYERS}?view=${PRICING_LAYER_TYPES.SERVICE}`, { replace: true });
    }
  }, [validView, view]);

  return (
    <Card component={Box} mt={3}>
      <Tabs variant="fullWidth" centered value={layerType} indicatorColor="primary" onChange={handleTabChange}>
        {PRICING_LAYER_TYPE_OPTIONS.filter(({ id }) => id !== PRICING_LAYER_TYPES.LANE).map(({ id, name }) => (
          <Tab key={id} value={id} label={name} />
        ))}
      </Tabs>
      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          {permissions.tools?.hqadmin?.pricing_layers?.create ? (
            <Button startIcon={<Add />} onClick={() => navigate(`/${resources.PRICING_LAYERS}/create?view=${layerType}`)}>
              Create
            </Button>
          ) : null}
        </Box>
        <List
          disableSyncWithLocation
          resource={resources.PRICING_LAYERS}
          filters={pricingLayerTypeFilters[layerType]}
          filter={{ type: layerType }}
          exporter={false}
          hasCreate={false}
          empty={<EmptyResourceList text="No pricing layers found" />}
          storeKey={`${resources.PRICING_LAYERS}.${layerType}.listParams`}
          sort={{ field: 'effective_date', order: 'DESC' }}
        >
          <Datagrid bulkActionButtons={false}>
            {columns[layerType]}
            <PricingLayerActions layerType={layerType} />
          </Datagrid>
        </List>
        <Box display="flex" justifyContent="flex-end">
          <Button startIcon={<Add />} onClick={() => navigate(`/${resources.PRICING_LAYERS}/create?view=${layerType}`)}>
            Create
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PricingLayersList;
