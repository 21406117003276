import React, { useEffect, useState } from 'react';

import { LoadingIndicator, ShowBase, useShowContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import mx from 'mixpanel-browser';

import { Box, Card } from '@mui/material';

import * as resources from '@/api/resources';

import PromoCodeContent from './PromoCodeContent';
import PromoCodeHeader from './PromoCodeHeader';
import PromoCodeTabs from './PromoCodeTabs';

const PromoCodeRecord = () => {
  const { record: { id } = {}, isLoading, isFetching } = useShowContext();

  const params = useParams();
  const tabValue = params['*'];

  const [currentTab, setCurrentTab] = useState(tabValue !== '' ? tabValue : 'details');

  const trackOrderTabChange = (selectedTab) => {
    mx.track('Resource Show - Tab Changed', {
      resource: resources.PROMO_CODES,
      resourceId: id,
      selectedTab,
    });
  };

  useEffect(() => {
    if (tabValue !== '') {
      setCurrentTab(tabValue);
      trackOrderTabChange(tabValue);
    } else {
      setCurrentTab('details');
    }
  }, [tabValue]);

  useEffect(() => {
    if (id) {
      mx.track('Resource Show - Viewed', {
        resource: resources.PROMO_CODES,
        resourceId: id,
      });
    }
  }, [id]);

  return (
    <Box mt={3}>
      {!id && (isLoading || isFetching) ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <>
          <PromoCodeHeader />
          <Card pt={1} component={Box} mt={3}>
            <PromoCodeTabs currentTab={currentTab} />
            <Box
              p={3}
              sx={{
                backgroundColor: ({ palette }) =>
                  palette.mode === 'light' ? palette.neutral.light : palette.neutral.darkest,
              }}
            >
              <PromoCodeContent currentTab={currentTab} />
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

const PromoCodeShow = () => (
  <ShowBase>
    <PromoCodeRecord />
  </ShowBase>
);

export default PromoCodeShow;
