import React from 'react';

import { Datagrid, DateField, ReferenceField, TextField, useRecordContext } from 'react-admin';

import { Card, CardContent, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

import CarriersSectionHeader from '../Common/SectionHeader';

const Transactions = () => {
  // get context of the current record being used
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card>
      <CarriersSectionHeader title="Transactions" contextMenuItems={[]} />
      <Divider />
      <CardContent>
        <List
          disableSyncWithLocation
          resource={resources.CARRIERS}
          queryOptions={{ meta: { subResource: 'transactions', resourceId: record.id } }}
          exporter={false}
          actions={false}
          empty={<EmptyResourceList text="No Payout history on Carrier account" />}
          storeKey={`${resources.CARRIERS}.payout_history.listParams`}
          sort={{ field: 'date_to_process', order: 'DESC' }}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField reference={resources.ORDERS} source="order" label="Order" link="show" sortable={false}>
              <TextField source="confirmation_id" />
            </ReferenceField>
            <TextField source="transaction_status" label="Status" />
            <TextField source="transaction_family" label="Family" />
            <DateField source="date_to_process" label="Txn Date" />
            <ReferenceField reference={resources.JOB_SLOTS} source="job_slot_id" label="Job Date" sortable={false}>
              <ReferenceField reference={resources.JOBS} source="job_id">
                <DateField source="start_date_time" />
              </ReferenceField>
            </ReferenceField>
            <CurrencyField source="amount" />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};

export default Transactions;
