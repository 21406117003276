import React from 'react';

import { useParams } from 'react-router';

import {
  THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION,
  THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD,
} from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewDetailsContactInfoEdit from './ContactInfo';
import ThirdPartyCrewDetailsOverviewEdit from './Overview';
import ThirdPartyCrewDetailsOverviewDeactivateAccount from './Overview/DeactivateAccount';
import ThirdPartyCrewDetailsOverviewStatusEdit from './Overview/Status';
import ThirdPartyCrewDetailsPaymentEdit from './Payment';
import ThirdPartyCrewDetailsRecruitmentEdit from './Recruitment';

const ThirdPartyCrewContentDetailsEdit = () => {
  const { section: currentSection, status } = useParams();

  let EditComponent = null;

  switch (currentSection) {
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      switch (status) {
        case THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD.STATUS:
          EditComponent = <ThirdPartyCrewDetailsOverviewStatusEdit />;
          break;
        case THIRD_PARTY_CREW_ACCOUNT_OVERVIEW_CARD.DEACTIVATE:
          EditComponent = <ThirdPartyCrewDetailsOverviewDeactivateAccount />;
          break;
        default:
          EditComponent = <ThirdPartyCrewDetailsOverviewEdit />;
          break;
      }
      break;
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.CONTACT_INFO:
      switch (status) {
        default:
          EditComponent = <ThirdPartyCrewDetailsContactInfoEdit />;
          break;
      }
      break;
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.RECRUITMENT:
      EditComponent = <ThirdPartyCrewDetailsRecruitmentEdit />;
      break;
    case THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.PAYMENT:
      EditComponent = <ThirdPartyCrewDetailsPaymentEdit />;
      break;
    default:
      EditComponent = <>Unhandled</>;
      break;
  }

  return EditComponent;
};

export default ThirdPartyCrewContentDetailsEdit;
