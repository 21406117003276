import dayjs from 'dayjs';

const isQuoteStale = (quote) => {
  if (!quote) return null;

  if (dayjs(quote.updated_at) < dayjs().subtract(3, 'days')) {
    return {
      stale: true,
      message: 'Greater than 3 days old',
    };
  }

  return {
    stale: false,
  };
};

export default isQuoteStale;
