import React from 'react';

import {
  CreateBase,
  Form,
  NumberInput,
  RecordContextProvider,
  SaveButton,
  SelectInput,
  useGetList,
  useRedirect,
  useReference,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/serviceName';
import getAccountResourceType from '@/utils/accounts/getAccountResourceType';

import { PAYOUT_CONFIG_MINIMUM_TYPES } from '@/constants/account';
import AccountHeader from '../AccountHeader';

const FormControls = () => {
  const { accountId } = useParams();
  const redirect = useRedirect();

  const { referenceRecord: accountRecord } = useReference({ reference: resources.ACCOUNTS, id: accountId });

  const resourceType = getAccountResourceType(accountRecord);

  return (
    <Card mt={3} component={Box}>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="neutral"
          onClick={() => redirect(`/${resourceType}/${accountId}/show/payout-config`)}
        >
          Cancel
        </Button>
        <SaveButton label="Save" icon={null} />
      </CardContent>
    </Card>
  );
};

const PayoutConfigCreate = () => {
  const { accountId } = useParams();
  const redirect = useRedirect();

  const { referenceRecord: accountRecord } = useReference({ reference: resources.ACCOUNTS, id: accountId });

  const { data: accountPayoutConfigs } = useGetList(`${resources.ACCOUNTS}/payout_config`, {
    filter: {
      account_id: accountId,
    },
  });

  const resourceType = getAccountResourceType(accountRecord);

  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  const transform = (data) => ({
    ...data,
    account_id: accountId,
  });

  if (!accountRecord) return null;

  return (
    <>
      <Box mt={3}>
        <RecordContextProvider value={accountRecord}>
          <AccountHeader />
        </RecordContextProvider>
      </Box>
      <CreateBase
        resource={`${resources.ACCOUNTS}/payout_config`}
        mutationOptions={{
          onSuccess: () => redirect(`/${resourceType}/${accountId}/show/payout-config`),
        }}
        transform={transform}
      >
        <Form>
          <Card component={Box} mt={3}>
            <CardHeader title="Add Payout Config" />
            <Divider />
            <CardContent>
              <SelectInput
                source="service"
                choices={Object.values(SERVICES).filter((service) =>
                  accountPayoutConfigs?.every((payoutConfig) => payoutConfig.service !== service.id),
                )}
              />
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <NumberInput
                    label="Base Hourly"
                    source="base_hourly_amount_cents"
                    format={formatValue}
                    parse={parseValue}
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput
                    label="Per Resource Hourly"
                    source="per_resource_hourly_amount_cents"
                    format={formatValue}
                    parse={parseValue}
                    required
                    min={0}
                  />
                </Grid>
                <Grid item xs={2}>
                  <NumberInput
                    label="Minimum"
                    source="minimum_amount_per_job_cents"
                    format={formatValue}
                    parse={parseValue}
                    required
                    min={0}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SelectInput
                    required
                    fullWidth
                    source="minimum_pay_type"
                    defaultValue={PAYOUT_CONFIG_MINIMUM_TYPES.SERVICE_MINIMUM.id}
                    choices={Object.values(PAYOUT_CONFIG_MINIMUM_TYPES)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <FormControls />
        </Form>
      </CreateBase>
    </>
  );
};

export default PayoutConfigCreate;
