import { ZENDESK_TICKET_STATUS } from '@/constants/zendeskTickets';

export const lightPalette = {
  mode: 'light',
  background: {
    paper: '#ffffff',
  },
  text: {
    primary: '#3E454B',
    secondary: '#747C82',
    default: '#B6B8BB',
    warning: '#F57F17',
    info: 'red',
  },
  primary: {
    lightest: '#ECFCF8',
    lighter: '#B1F0E2',
    light: '#3DDB93',
    main: '#16D3A9',
    dark: '#0F8D71',
  },
  secondary: {
    lightest: '#E5EDFF',
    lighter: '#CDDBFB',
    light: '#6994F4',
    main: '#4C5A7A',
    dark: '#4663A3',
    darker: '#233152',
    darkest: '#1C2743',
  },
  neutral: {
    lightest: '#FFFFFF',
    lighter: '#F5F7F9',
    light: '#E1EAF1',
    main: '#E1EAF1',
    medium: '#747C82',
    dark: '#58627B',
    darkest: '#3E454B',
  },
  accent: {
    lighter: '#FBC9D2',
    light: '#FDC2CC',
    main: '#FA4767',
    dark: '#A72F45',
  },
  error: {
    main: '#FA4767',
  },
  warning: {
    main: '#F57F17',
  },
  success: {
    main: '#3DDB93',
  },
  info: {
    main: '#E5EDFF',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.CLOSED.id}`]: {
    main: '#D8DCDE',
    contrastText: '#333',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.HOLD.id}`]: {
    main: '#2F3941',
    contrastText: '#fff',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.NEW.id}`]: {
    main: '#FFB648',
    contrastText: '#333',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.OPEN.id}`]: {
    main: '#E34F32',
    contrastText: '#fff',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.PENDING.id}`]: {
    main: '#3091EC',
    contrastText: '#fff',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.SOLVED.id}`]: {
    main: '#87929D',
    contrastText: '#fff',
  },
};

export const darkPalette = {
  mode: 'dark',
  background: {
    default: '#1C2743',
    paper: '#1C2743',
  },
  text: {
    primary: '#F5F7F9',
    secondary: '#E1EAF1',
    default: '#B6B8BB',
    warning: '#F57F17',
  },
  primary: {
    lightest: '#ECFCF8',
    lighter: '#B1F0E2',
    light: '#3DDB93',
    main: '#16D3A9',
    dark: '#0F8D71',
  },
  secondary: {
    light: '#CDDBFB',
    main: '#6994F4',
    dark: '#4663A3',
    darker: '#233152',
    darkest: '#1C2743',
  },
  neutral: {
    lightest: '#FFFFFF',
    lighter: '#F5F7F9',
    light: '#E1EAF1',
    main: '#E1EAF1',
    medium: '#747C82',
    dark: '#58627B',
    darkest: '#3E454B',
  },
  accent: {
    light: '#FDC2CC',
    main: '#FA4767',
    dark: '#A72F45',
  },
  error: {
    main: '#FA4767',
  },
  warning: {
    main: '#F57F17',
  },
  success: {
    main: '#3DDB93',
  },
  info: {
    main: '#4663A3',
  },
  // Colors pulled from Zendesk color palette
  [`zendesk.${ZENDESK_TICKET_STATUS.CLOSED.id}`]: {
    main: '#D8DCDE',
    contrastText: '#333',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.HOLD.id}`]: {
    main: '#2F3941',
    contrastText: '#fff',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.NEW.id}`]: {
    main: '#FFB648',
    contrastText: '#333',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.OPEN.id}`]: {
    main: '#E34F32',
    contrastText: '#fff',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.PENDING.id}`]: {
    main: '#3091EC',
    contrastText: '#fff',
  },
  [`zendesk.${ZENDESK_TICKET_STATUS.SOLVED.id}`]: {
    main: '#87929D',
    contrastText: '#fff',
  },
};
