import { MountedScriptsContext } from '@/providers/MountedScripts';
import { useContext } from 'react';

const GoogleMapsScript = () => {
  const { scripts, updateScripts } = useContext(MountedScriptsContext);
  const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&callback=callbackGoogleScript`;
  const scriptKey = 'google';

  if (typeof window !== 'undefined') {
    const googleScript = document.getElementById(`mounted_${scriptKey}`);

    window.callbackGoogleScript = () => {
      updateScripts(scriptKey);
    };

    if (!googleScript && !scripts.includes(scriptKey)) {
      const id = `mounted_${scriptKey}`;

      const script = document.createElement('script');
      script.id = id;
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);
    }
  }

  return null;
};

export default GoogleMapsScript;
