import React from 'react';

import { useListContext } from 'react-admin';

import { LocalShipping, PeopleAlt } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';

const ServicesChips = () => {
  const { data } = useListContext();

  const serviceChipIcon = (service) => {
    if (service.service_id === 'TRANSIT') {
      return <LocalShipping />;
    }
    return <PeopleAlt />;
  };

  if (!data) return null;

  return data.map((service) => (
    <Box key={service.id}>
      <Chip icon={serviceChipIcon(service)} label={service.quoted_workers} />
    </Box>
  ));
};

export default ServicesChips;
