import React from 'react';
import { Link, useCreatePath, useRecordContext } from 'react-admin';

import { Box } from '@mui/material';

import * as resources from '@/api/resources';
import SlotAvatarField from '@/fields/SlotAvatarField';

const JobSlot = () => {
  const createPath = useCreatePath();
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Box mr={0.5}>
      <Link
        to={createPath({
          resource: resources.JOB_SLOTS,
          type: 'show',
          id: record.id,
        })}
      >
        <SlotAvatarField enableTooltip />
      </Link>
    </Box>
  );
};

JobSlot.propTypes = {};

export default JobSlot;
