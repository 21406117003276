import React from 'react';
import { useRecordContext, useReference } from 'react-admin';

import * as resources from '@/api/resources';
import { Box, Tooltip } from '@mui/material';
import { Check, Engineering, LocalFireDepartment } from '@mui/icons-material';
import { getAssignedSlotCount, getTotalSlotCount, isLaborFunctional, isTransitFunctional } from '@/utils/fulfillment';
import { onlyLaborJobs, onlyTransitJobs } from '@/utils/jobs';

const OrderFulfillmentStatusField = () => {
  const record = useRecordContext();

  const {
    referenceRecord: orderFulfillmentData,
    isLoading,
    isFetching,
  } = useReference({
    reference: resources.FULFILLMENT_STATUS,
    options: {
      meta: {
        fulfilmentStatusType: 'order',
      },
    },
    id: record?.id,
    enabled: Boolean(record?.id),
  });

  const assignedSlots = getAssignedSlotCount(orderFulfillmentData?.jobs);
  const totalSlots = getTotalSlotCount(orderFulfillmentData?.jobs);

  let fulfillmentStatus = 'Non Functional';

  if (assignedSlots === totalSlots) {
    fulfillmentStatus = 'Fulfilled';
  } else {
    const laborJobs = orderFulfillmentData?.jobs?.filter(onlyLaborJobs);
    const transitJobs = orderFulfillmentData?.jobs?.filter(onlyTransitJobs);

    const orderHasTransit = Boolean(transitJobs.length);

    const laborFunctional = isLaborFunctional(laborJobs);
    const transitFunctional = isTransitFunctional(transitJobs);

    if ((orderHasTransit && transitFunctional && laborFunctional) || (!orderHasTransit && laborFunctional)) {
      fulfillmentStatus = 'Functional';
    }
  }

  return (
    <Box display="flex" alignItems="center" whiteSpace="nowrap" justifyContent="space-between" minWidth={65}>
      {!orderFulfillmentData || isFetching || isLoading ? null : (
        <>
          <Box mr={1}>{`${assignedSlots} / ${totalSlots}`}</Box>
          <Tooltip title={fulfillmentStatus}>
            {(() => {
              switch (fulfillmentStatus) {
                case 'Fulfilled':
                  return <Check color="success" />;
                case 'Functional':
                  return <Engineering color="warning" />;
                case 'Non Functional':
                default:
                  return <LocalFireDepartment color="accent" />;
              }
            })()}
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default OrderFulfillmentStatusField;
