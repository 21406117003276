import React from 'react';

import { BooleanInput, Form, NumberInput, SaveButton, SelectInput, ShowButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { TRUCK_SIZE } from '@/constants/carrierAccount';

const CarrierDetailsContractEdit = () => (
  <Card mt={3} component={Box}>
    <CardHeader title="Truck" />

    <Divider />

    <Form>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <NumberInput source="truck_count" validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="truck_size_1" choices={TRUCK_SIZE} validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="truck_size_2" choices={TRUCK_SIZE} validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="driver_count" validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="truck_size_1_quantity" validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="truck_size_2_quantity" validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="mc_number" validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="dot_number" validate={[]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput source="regional_mover" validate={[]} fullWidth />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <ShowButton label="Cancel" icon={null} color="secondary" />
          <SaveButton label="Update" icon={null} />
        </Box>
      </CardContent>
    </Form>
  </Card>
);

export default CarrierDetailsContractEdit;
