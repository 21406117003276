import React from 'react';
import { SelectArrayInput, SelectInput } from 'react-admin';

import { SERVICE_NAMES } from '@/constants/serviceName';

const { string, bool, arrayOf, shape } = require('prop-types');

const ServiceNameFilter = ({ choices, source, multiSelect }) => {
  if (!multiSelect) {
    return <SelectInput source={source} choices={choices} />;
  }

  return <SelectArrayInput source={source} choices={choices} />;
};

ServiceNameFilter.propTypes = {
  choices: arrayOf(shape({ id: string, name: string })),
  source: string.isRequired,
  multiSelect: bool,
};

ServiceNameFilter.defaultProps = {
  // Populate default from constant - override with prop
  choices: Object.keys(SERVICE_NAMES).map((key) => ({
    id: key,
    name: SERVICE_NAMES[key],
  })),
  multiSelect: true,
};

export default ServiceNameFilter;
