const PRODUCTS_TABS = {
  DETAILS: 'details',
};

const FAMILY = [
  { id: 'Fee', name: 'Fee' },
  { id: 'None', name: 'None' },
  { id: 'Reduction', name: 'Reduction' },
  { id: 'Service', name: 'Service' },
  { id: 'Package', name: 'Package' },
  { id: 'Product', name: 'Product' },
];

const SERVICEABLE_ADDRESS_TYPE = [
  { id: 'ALL', name: 'All' },
  { id: 'Any', name: 'Any' },
  { id: 'Destination', name: 'Destination' },
  { id: 'None', name: 'None' },
  { id: 'Origin', name: 'Origin' },
  { id: 'Product', name: 'Product' },
];

const DISTANCE_TYPE = [
  { id: 'Local', name: 'Local' },
  { id: 'Long Distance', name: 'Long Distance' },
];

const PAYMENT_GATEWAY = [
  { id: 'BELLHOP_INC', name: 'BELLHOP INC' },
  { id: 'BHH_Services_LLC', name: 'BHH Services LLC' },
  { id: 'Test_BHH_Services_LLC', name: 'Test BHH Services LLC' },
];

export { PRODUCTS_TABS, FAMILY, SERVICEABLE_ADDRESS_TYPE, DISTANCE_TYPE, PAYMENT_GATEWAY };
