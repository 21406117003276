import React from 'react';

import { BooleanInput, DateInput, Edit, NumberInput, SaveButton, ShowButton, SimpleForm, TextInput } from 'react-admin';

import { Box, Divider } from '@mui/material';

const CustomToolbar = () => (
  <Box>
    <Divider />
    <Box display="flex" justifyContent="space-between" p={3}>
      <ShowButton label="Cancel" icon={null} color="secondary" />
      <SaveButton label="Update" icon={null} type="button" />
    </Box>
  </Box>
);

const AdminOrderItemEdit = () => {
  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<CustomToolbar />}>
        <NumberInput source="unitprice" format={formatValue} parse={parseValue} required min={0} />
        <TextInput source="marketplace_match_status" />
        <DateInput source="end_date_time__c" />
        <TextInput source="pricebookentry__id__c" />
        <TextInput source="pricing_strategy__c" />
        <TextInput source="skip_autoassign__c" />
        <TextInput source="tip_job_slot__c" />
        <TextInput source="_hc_err" />
        <TextInput source="end_date_time_time_zone__c" />
        <TextInput source="product_family__c" />
        <DateInput source="start_date_time__c" />
        <TextInput source="tip_job_slot__r__id__c" />
        <TextInput source="product_id_rollup__c" />
        <TextInput source="appeasement_context__c" />
        <TextInput source="currency__c" />
        <TextInput source="end_market__c" />
        <TextInput source="marketplace_match_expiry__c" />
        <TextInput source="product2__id__c" />
        <TextInput source="start_date_time_time_zone__c" />
        <TextInput source="tip_type__c" />
        <TextInput source="pricebookentryid" />
        <TextInput source="appeasement_reason__c" />
        <TextInput source="customer_reviewed__c" />
        <TextInput source="end_market__r__market_id__c" />
        <TextInput source="order__id__c" />
        <TextInput source="product2id" />
        <TextInput source="start_market__c" />
        <TextInput source="tyson_assignment__c" />
        <TextInput source="appeasement_type__c" />
        <TextInput source="description" />
        <TextInput source="fulfilled__c" />
        <TextInput source="order_item_lead_account__r__id__c" />
        <TextInput source="promo_id__c" />
        <TextInput source="start_market__r__market_id__c" />
        <TextInput source="_hc_lastop" />
        <TextInput source="billable_duration__c" />
        <TextInput source="discount__c" />
        <BooleanInput source="guaranteed__c" />
        <TextInput source="orderid" />
        <TextInput source="promo_id__r__code__c" />
        <TextInput source="status__c" />
        <NumberInput source="workers__c" />
        <TextInput source="quotelineitemid" />
        <TextInput source="completed_date_time__c" />
        <DateInput source="driving_distance__c" />
        <TextInput source="package_quote_id__c" />
        <NumberInput source="quantity" />
        <TextInput source="supply_type__c" />
        <TextInput source="delta_reversed__c" />
        <BooleanInput source="concurrent__c" />
        <NumberInput source="duration__c" />
        <TextInput source="payout_strategy__c" />
        <TextInput source="sales_price__c" />
        <TextInput source="order_item_lead_account__c" />
      </SimpleForm>
    </Edit>
  );
};

export default AdminOrderItemEdit;
