import React from 'react';

import { DateInput, Form, SaveButton, SelectInput, ShowButton } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { BROKER_AUTHORITY, COMMON_AUTHORITY, CONTRACT_AUTHORITY, DOT_AUTHORITY } from '@/constants/carrierAccount';

const CarrierDetailsAuthorityEdit = () => (
  <Card mt={3} component={Box}>
    <CardHeader title="Authority" />

    <Divider />

    <Form>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectInput source="broker_authority" choices={BROKER_AUTHORITY} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="common_authority" choices={COMMON_AUTHORITY} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="contract_authority" choices={CONTRACT_AUTHORITY} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <DateInput source="insurance_authority_updated" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="dot_authority" choices={DOT_AUTHORITY} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <DateInput source="liability_expiration_date" fullWidth />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <ShowButton label="Cancel" icon={null} color="secondary" />
          <SaveButton label="Update" icon={null} />
        </Box>
      </CardContent>
    </Form>
  </Card>
);

export default CarrierDetailsAuthorityEdit;
