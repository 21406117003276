import React, { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mx from 'mixpanel-browser';
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  required,
  SelectInput,
  TextField,
  useGetList,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useReference,
  useRefresh,
  useShowContext,
  useUnselectAll,
  useUpdate,
} from 'react-admin';

import { Avatar, Box, Button, Card, CardHeader } from '@mui/material';

import { AddCircleOutline } from '@mui/icons-material';

import * as resources from '@/api/resources';
import { CARRIER_ACCOUNT_RECORD_TYPE_ARRAY } from '@/constants/account';
import { JobSlotActionContext } from '@/providers/JobSlotAction';
import List from '@/ReactAdmin/List';
import ListViewChangedTracker from '@/shared/events/ListViewChangedTracker';
import LinkButton from '@/shared/LinkButton';
import getServiceName from '@/utils/serviceName/getServiceName';
import AccountActionsField from '../AccountAccountField';

dayjs.extend(utc);
dayjs.extend(timezone);

const eligibleFilters = [
  <SelectInput
    source="transit_status"
    label="Type"
    alwaysOn
    validate={required()}
    choices={[
      { id: 'available', name: 'Available' },
      { id: 'unsubmitted', name: 'Unsubmitted' },
    ]}
  />,
];

const EligibleTransitActionButtons = () => {
  const { selectedIds } = useListContext();
  const { record: jobSlotRecord } = useShowContext();
  const { referenceRecord: jobRecord } = useReference({
    reference: resources.JOBS,
    id: jobSlotRecord?.job_id,
    options: { enabled: Boolean(jobSlotRecord?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobRecord?.order_id,
    enabled: Boolean(jobRecord?.order_id),
  });

  const { data: locationsData } = useGetList(
    resources.LOCATIONS,
    {
      filter: { job_id: jobRecord?.id },
      sort: { field: 'sequence', order: 'ASC' },
    },
    { enabled: Boolean(jobRecord?.id) },
  );

  const metadata = {
    job_id: jobSlotRecord.job_id,
    job_slot_id: jobSlotRecord.id,
  };

  const title = `${getServiceName(jobRecord?.product_id)}`;
  const startingLocation = locationsData?.[0]; // 0 index is always lowest sequence location as the getList call above enforcing sorting on sequence ASC
  const startingLocationString = `${startingLocation?.street_address_1}, ${startingLocation?.city}`;
  const message = `Hey this is Bellhop HQ. We have an open ${getServiceName(jobRecord?.product_id)} job on ${dayjs(
    jobRecord?.start_date_time,
  ).format(
    'MMM D, YYYY @ h:mm A',
  )}, starting at ${startingLocationString}. If you're interested, claim the job below.\n\nBest,\nHopper`;

  const trackFulfillmentMessage = () => {
    mx.track('Fulfillment - Eligible Transit - Create Message', {
      jobId: jobSlotRecord?.job_id,
      jobSlotId: jobSlotRecord?.id,
      confirmationId: orderData?.confirmation_id,
      selectedIds,
    });
  };

  return (
    <LinkButton
      label="Create Message"
      to="/communications/create"
      state={{
        record: {
          user_ids: selectedIds,
          title,
          message,
          metadata,
        },
      }}
      onClick={trackFulfillmentMessage}
    />
  );
};

const AssignField = () => {
  const { setAction, setMetadata } = useContext(JobSlotActionContext);
  const accountRecord = useRecordContext();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const notify = useNotify();
  const { record: jobSlotRecord } = useShowContext();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: jobSlotRecord?.job_id,
    options: { enabled: Boolean(jobSlotRecord?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const onError = (error) => {
    const overrideObj = { message: '', account_id: '' };
    overrideObj.account_id = accountRecord?.id;
    const errorMessage = error?.body?.detail;

    if (errorMessage.includes(accountRecord?.id)) {
      overrideObj.message = errorMessage.replace(accountRecord?.id, accountRecord?.name);
    } else {
      overrideObj.message = errorMessage;
    }

    setMetadata(overrideObj);
    setAction('override_assign');
  };

  const onSuccess = () => {
    mx.track('Fulfillment - Transit Assigned', {
      resource: resources.JOB_SLOTS,
      resourceId: jobSlotRecord?.id,
      parentResource: resources.JOBS,
      parentResourceId: jobSlotRecord?.job_id,
      confirmationId: orderData?.confirmation_id,
      assignedId: accountRecord?.id,
    });
    notify(`${accountRecord.name} assigned`, {
      type: 'success',
    });

    refresh();
    redirect('show', resources.JOBS, jobSlotRecord.job_id);
  };

  const [update] = useUpdate(
    resources.JOB_SLOTS,
    {
      id: jobSlotRecord?.id,
      data: { account_id: accountRecord.id },
      previousData: {},
    },
    { mutationMode: 'pessimistic', onError, onSuccess },
  );

  if (jobSlotRecord?.account_id) {
    // Remove assign button on assigned slots - must unassign first
    return null;
  }

  return (
    <Button
      startIcon={<AddCircleOutline />}
      onClick={(e) => {
        e.preventDefault();
        update();
      }}
    >
      Assign
    </Button>
  );
};

const EligibleTransitDatagrid = () => {
  const { permissions } = usePermissions();
  const { record } = useShowContext();
  const { selectedIds } = useListContext();

  const { referenceRecord: jobData } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const { referenceRecord: orderData } = useReference({
    reference: resources.ORDERS,
    id: jobData?.order_id,
    enabled: Boolean(jobData?.order_id),
  });

  const [prevSelectedIds, setPrevSelectedIds] = useState([]);

  const canCreateCommunication = permissions.tools?.fulfillment?.communication?.create;
  const canAssign = permissions.tools?.fulfillment?.job_slot?.update && permissions.tools?.fulfillment?.job_slot?.assign;

  useEffect(() => {
    const selectedDiff = selectedIds.length - prevSelectedIds.length;

    if (selectedDiff === 0) {
      return;
    }
    let eventName = 'Resource List - Select';
    if (selectedDiff > 1) {
      eventName = 'Resource List - Select All';
    } else if (selectedDiff < -1) {
      eventName = 'Resource List - Unselect All';
    } else if (selectedDiff === 1) {
      eventName = 'Resource List - Select One';
    } else if (selectedDiff === -1) {
      eventName = 'Resource List - Unselect One';
    }
    mx.track(eventName, {
      resource: resources.ELIGIBLE_LABOR,
      parentResource: resources.JOB_SLOTS,
      parentResourceId: record?.id,
      grandparentResource: resources.JOBS,
      grandparentResourceId: record?.job_id,
      confirmationId: orderData?.confirmation_id,
    });
    setPrevSelectedIds(selectedIds);
  }, [selectedIds]);

  return (
    <Datagrid rowClick={null} bulkActionButtons={canCreateCommunication ? <EligibleTransitActionButtons /> : false}>
      <FunctionField render={(rec) => <Avatar src={rec.image_url} />} />
      <FunctionField
        render={(rec) =>
          CARRIER_ACCOUNT_RECORD_TYPE_ARRAY.includes(rec.account_record_type_name) ? (
            <ReferenceField reference={resources.CARRIERS} source="id" label="Name" link="show" sortBy="false">
              <TextField source="name" />
            </ReferenceField>
          ) : (
            <ReferenceField reference={resources.ACCOUNTS} source="id" label="Name" link="show" sortBy="false">
              <TextField source="name" />
            </ReferenceField>
          )
        }
      />

      {canAssign && <AssignField />}
      <AccountActionsField />
    </Datagrid>
  );
};

const EligibleTransitList = () => {
  const { record } = useShowContext();

  const { referenceRecord: jobData, isLoading: isJobLoading } = useReference({
    reference: resources.JOBS,
    id: record?.job_id,
    options: { enabled: Boolean(record?.job_id) },
  });

  const unselectAllEligibleLabor = useUnselectAll(resources.ELIGIBLE_TRANSIT);

  useEffect(() => {
    unselectAllEligibleLabor();
  }, []);

  if (!jobData && isJobLoading) return <>Loading</>;

  return (
    <Card variant="outlined">
      <CardHeader title="Eligible Transit" />
      <List
        component={Box}
        resource={resources.ELIGIBLE_TRANSIT}
        filter={{
          job_slot_id: record?.id,
          aggregate_accounts: true,
        }}
        filters={eligibleFilters}
        filterDefaultValues={{
          transit_status: 'available',
        }}
        actions={false}
        storeKey={`${resources.ELIGIBLE_TRANSIT}.listParams`}
        disableSyncWithLocation
      >
        <ListViewChangedTracker />
        <EligibleTransitDatagrid />
      </List>
    </Card>
  );
};

EligibleTransitList.propTypes = {};
EligibleTransitDatagrid.propTypes = {};
AssignField.propTypes = {};

export default EligibleTransitList;
