import { useRecordContext } from 'react-admin';
import { string } from 'prop-types';
import getServiceName from '@/utils/serviceName/getServiceName';

/*
 * ServiceNameField
 *
 * Given a product code will display service name
 *
 */
const ServiceNameField = ({ source }) => {
  const record = useRecordContext();

  if (!record[source]) return null;

  return getServiceName(record[source]);
};

ServiceNameField.propTypes = {
  source: string.isRequired,
};

export default ServiceNameField;
