export const JOBS = 'jobs';
export const ORDERS = 'orders';
export const JOB_SLOTS = 'job_slots';
export const LOCATIONS = 'locations';
export const ACCOUNTS = 'accounts';
export const CUSTOMERS = 'customers';
export const MARKETS = 'markets';
export const ELIGIBLE_LABOR = 'eligible_labor';
export const ELIGIBLE_TRANSIT = 'eligible_transit';
export const JOB_SLOT_BONUS = 'job_slot_bonus';
export const JOB_SLOT_TRANSACTIONS = 'job_slot_transactions';
export const COMMUNICATIONS = 'communications';
export const FULFILLMENT_STATUS = 'fulfillment_status';
export const PAYMENT_INFO = 'payment_info';
export const CARRIERS = 'carriers';
export const USERS = 'users';
export const QUOTES = 'quotes';
export const MARKET_PRODUCTS = 'market_products';
export const SUPPLYINVENTORY = 'supply_inventory';
export const PRODUCTS = 'products';
export const PROMO_CODES = 'promo_codes';
export const IRONCLAD_CONTRACTS = 'ironclad_contracts';
export const THIRD_PARTY_CREW = 'third_party_crew';
export const THIRD_PARTY_WORKER = 'third_party_worker';
export const UTILITY = 'utility';
export const ADMIN = 'admin';
export const ADMIN_CONNECTED_ACCOUNTS = `${ADMIN}/connectedaccounts`;
export const ADMIN_ORDER_ITEMS = `${ADMIN}/orderitems`;
export const ADMIN_STRIPE_CUSTOMERS = `${ADMIN}/stripe_customer`;
export const ADMIN_STRIPE_TRANSACTIONS = `${ADMIN}/stripe_transaction`;
export const AVAILABILITY_CONFIGURATION = `${ADMIN}/availability_configuration`;
export const ZENDESK = 'zendesk';
export const ZENDESK_TICKETS = `${ZENDESK}/tickets`;
export const ZENDESK_USERS = `${ZENDESK}/users`;
export const PRICING_LAYERS = 'pricing_layers';
