import React from 'react';

import { Datagrid, TextField, TextInput, SelectInput, useRedirect } from 'react-admin';

import { Box, Card, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import MarketNameField from '@/fields/MarketNameField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';
import { THIRD_PARTY_CREW_ACCOUNT_TYPE } from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewSectionHeader from './Common/SectionHeader';
import AccountTypeField from './AccountTypeField';
import CompanyField from './CompanyField';

const AccountFilter = [
  <TextInput source="name" alwaysOn resettable autoComplete="off" />,
  <TextInput source="email" alwaysOn resettable autoComplete="off" />,
  <TextInput source="phone" alwaysOn resettable autoComplete="off" />,
  <TextInput source="id" label="Id" autoComplete="off" />,
  <SelectInput source="account_type" label="Type" choices={THIRD_PARTY_CREW_ACCOUNT_TYPE} />,
];

const ThirdPartyCrewAccountList = () => {
  const redirect = useRedirect();

  return (
    <Card mt={3} component={Box}>
      <Box px={3} py={2}>
        <ThirdPartyCrewSectionHeader title="Third Party Crew Accounts" />
      </Box>

      <Divider />

      <List
        disableSyncWithLocation
        resource={resources.THIRD_PARTY_CREW}
        filters={AccountFilter}
        exporter={false}
        empty={<EmptyResourceList text="No Third Party Crew Accounts" />}
        hasCreate={false}
        storeKey={`${resources.THIRD_PARTY_CREW}.listParams`}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id, resource, record) =>
            record.account_record_type_name === THIRD_PARTY_CREW_ACCOUNT_TYPE[0].id
              ? 'show'
              : redirect(`/${resources.THIRD_PARTY_WORKER}/${id}/show`)
          }
        >
          <TextField source="name" sortable={false} />
          <CompanyField source="company_id" />
          <AccountTypeField source="account_record_type_name" sortable={false} label="Type" />
          <TextField source="email" label="Email" sortable={false} />
          <TextField source="phone" sortable={false} />
          <TextField source="status" sortable={false} />
          <MarketNameField source="primary_market" sortable={false} />
        </Datagrid>
      </List>
    </Card>
  );
};

export default ThirdPartyCrewAccountList;
