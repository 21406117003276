import React from 'react';

import {
  BooleanInput,
  CreateBase,
  Form,
  RecordContextProvider,
  SaveButton,
  SelectInput,
  TextInput,
  useGetList,
  useRedirect,
  useReference,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { SERVICES } from '@/constants/serviceName';
import { THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';

import ThirdPartyCrewHeader from '../../Show/ThirdPartyCrewHeader';

const ThirdPartyCrewSkillsCreate = () => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { accountId } = useParams();

  const { referenceRecord: account } = useReference({ reference: resources.THIRD_PARTY_CREW, id: accountId });

  const { data: accountSkills } = useGetList(resources.THIRD_PARTY_CREW, {
    meta: {
      resourceId: accountId,
      subResource: 'account_products',
    },
  });

  if (!account || !accountSkills) return null;

  return (
    <>
      <Box mt={3}>
        <RecordContextProvider value={account}>
          <ThirdPartyCrewHeader />
        </RecordContextProvider>
      </Box>

      <CreateBase
        resource={resources.THIRD_PARTY_CREW}
        mutationOptions={{
          meta: {
            resourceId: accountId,
            subResource: 'account_product',
          },
          onSuccess: () => {
            refresh();
            redirect(`/${resources.THIRD_PARTY_CREW}/${accountId}/show/${THIRD_PARTY_CREW_ACCOUNT_TAB.SKILLS}`);
          },
        }}
      >
        <Box display="flex" mt={3}>
          <Card component={Box} minWidth={400}>
            <CardHeader title="Add Skill" />

            <Divider />

            <Form>
              <CardContent>
                <TextInput source="account_id" hidden defaultValue={accountId} validate={[]} sx={{ display: 'none' }} />

                <SelectInput
                  required
                  source="product_id"
                  label="Skill"
                  choices={Object.values(SERVICES).filter((service) =>
                    accountSkills?.every((accountSkill) => accountSkill.product_id !== service.id),
                  )}
                  validate={[]}
                  fullWidth
                />
                <SelectInput
                  required
                  source="qualification_level"
                  label="Qualification"
                  choices={[
                    { id: '0', name: 'Recruit' },
                    { id: '1', name: 'Standard' },
                    { id: '2', name: 'Lead' },
                    { id: '3', name: 'Captain' },
                  ]}
                  validate={[]}
                  fullWidth
                />
                <BooleanInput source="skip_autoassign" label="Skip auto-assign" fullWidth />
              </CardContent>
              <Divider />
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="neutral"
                    onClick={() =>
                      redirect(`/${resources.THIRD_PARTY_CREW}/${accountId}/show/${THIRD_PARTY_CREW_ACCOUNT_TAB.SKILLS}`)
                    }
                  >
                    Cancel
                  </Button>
                  <SaveButton label="Add" icon={null} />
                </Box>
              </CardContent>
            </Form>
          </Card>
        </Box>
      </CreateBase>
    </>
  );
};

export default ThirdPartyCrewSkillsCreate;
