import React from 'react';

import {
  BooleanInput,
  CreateBase,
  ListButton,
  NumberInput,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
} from 'react-admin';

import { Box, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';

import { MARKET_REGION, MARKET_STATUSES } from '@/constants/market';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ListButton resource="markets" label="Cancel" icon={null} />
    <SaveButton label="Create" icon={null} />
  </Toolbar>
);

const MarketCreate = () => (
  <CreateBase>
    <Card mt={3} component={Box}>
      <CardHeader title="Create Market" />

      <Divider />

      <TabbedForm toolbar={<CustomToolbar />}>
        <TabbedForm.Tab label="Primary information">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput source="name" label="Name" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="id" label="Code" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="market_owner" label="Market owner" fullWidth disabled />
            </Grid>
            <Grid item xs={3}>
              <SelectInput source="status" label="Status" choices={MARKET_STATUSES} fullWidth />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="market_center_street_1" label="Address line 1" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextInput source="market_center_street_2" label="Address line 2" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="market_center_city" label="City" fullWidth />
            </Grid>
            <Grid item xs={1}>
              <TextInput source="market_center_state" label="State" fullWidth />
            </Grid>
            <Grid item xs={1}>
              <TextInput source="market_center_zip" label="Zip" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <SelectInput source="region" label="Region" choices={MARKET_REGION} fullWidth />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput source="is_pod_available" label="PODS Enabled" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput source="is_marketplace" label="Marketplace" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput source="is_labor_only" label="Labor Only" fullWidth />
            </Grid>
            {/* Payout provider not accepted in POST endpoint
            <Grid item xs={3}>
              <SelectInput source="payout_provider" label="Payout provider" choices={MARKET_PAYOUT_PROVIDERS} fullWidth />
            </Grid> */}
          </Grid>
        </TabbedForm.Tab>

        <TabbedForm.Tab label="Travel pay">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <NumberInput source="mileage_minimum" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <NumberInput source="tier_1_mileage_maximum" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <NumberInput source="mileage_maximum" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <NumberInput source="tier_1_mileage_reimbursement" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <NumberInput source="tier_2_mileage_reimbursement" fullWidth />
            </Grid>
          </Grid>
        </TabbedForm.Tab>

        <TabbedForm.Tab label="Performance thresholds">
          <Grid container spacing={2} px={2}>
            {/* Late Rate Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Late Rate</Typography>
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="late_rate_excellent_threshold" label="Excellent Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="late_rate_good_threshold" label="Good Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="late_rate_needs_review_threshold" label="Needs Review Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="late_rate_poor_threshold" label="Poor Threshold" fullWidth />
            </Grid>
            <Divider />

            {/* Removal Rate Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Removal Rate</Typography>
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="removal_rate_excellent_threshold" label="Excellent Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="removal_rate_good_threshold" label="Good Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="removal_rate_needs_review_threshold" label="Needs Review Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="removal_rate_poor_threshold" label="Poor Threshold" fullWidth />
            </Grid>
            <Divider />

            {/* Damage Rate Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Damage Rate</Typography>
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="damage_rate_excellent_threshold" label="Excellent Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="damage_rate_good_threshold" label="Good Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="damage_rate_needs_review_threshold" label="Needs Review Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="damage_rate_poor_threshold" label="Poor Threshold" fullWidth />
            </Grid>
            <Divider />

            {/* No Pad Wrap Rate Section */}
            <Grid item xs={12}>
              <Typography variant="h6">No Pad Wrap Rate</Typography>
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="no_pad_wrap_excellent_threshold" label="Excellent Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="no_pad_wrap_good_threshold" label="Good Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="no_pad_wrap_needs_review_threshold" label="Needs Review Threshold" fullWidth />
            </Grid>
            <Grid item xs={3}>
              <NumberInput source="no_pad_wrap_poor_threshold" label="Poor Threshold" fullWidth />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      </TabbedForm>
    </Card>
  </CreateBase>
);

export default MarketCreate;
