import React from 'react';

import { useParams } from 'react-router';

import { WORKER_TYPE } from '@/constants/supplyinventory';

import SupplyInventoryCreateLead from './Lead';
import SupplyInventoryCreateStandard from './Standard';
import SupplyInventoryCreateTransit from './Transit';

const SupplyInventoryPageController = () => {
  const { section: currentSection } = useParams();
  let CreateComponent = null;

  switch (currentSection) {
    case WORKER_TYPE[0].name.toLowerCase():
      CreateComponent = <SupplyInventoryCreateLead />;
      break;
    case WORKER_TYPE[1].name.toLowerCase():
      CreateComponent = <SupplyInventoryCreateStandard />;
      break;
    case WORKER_TYPE[2].name.toLowerCase():
      CreateComponent = <SupplyInventoryCreateTransit />;
      break;
    default:
      CreateComponent = null;
      break;
  }
  return CreateComponent;
};

export default SupplyInventoryPageController;
