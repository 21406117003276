/*
 * This is a direct clone of RA's ListBase component in order to fix filter persistence in local storage
 * Only notable changes in List stack is in useListParams
 *
 * The following items needed to be clone in order to support changing that hook
 * List -> ListBase -> useListController -> useListParams
 *
 * PR was opened on react-admin official repo to potentially fix this in a future release
 */
import * as React from 'react';
import { node, string } from 'prop-types';
import { ListContextProvider, ResourceContextProvider } from 'react-admin';
import { useListController } from './useListController';

/**
 * Call useListController and put the value in a ListContext
 *
 * Base class for <List> components, without UI.
 *
 * Accepts any props accepted by useListController:
 * - filter: permanent filter applied to the list
 * - filters: Filter element, to display the filters
 * - filterDefaultValues: object;
 * - perPage: Number of results per page
 * - sort: Default sort
 * - exporter: exported function
 *
 * @example // Custom list layout
 *
 * const PostList = props => (
 *     <ListBase {...props} perPage={10}>
 *         <div>
 *              List metrics...
 *         </div>
 *         <Grid container>
 *             <Grid item xs={8}>
 *                 <SimpleList primaryText={record => record.title} />
 *             </Grid>
 *             <Grid item xs={4}>
 *                 List instructions...
 *             </Grid>
 *         </Grid>
 *         <div>
 *             Post related links...
 *         </div>
 *     </ListBase>
 * );
 */
const ListBase = ({ children, ...props }) => (
  <ResourceContextProvider value={props.resource}>
    <ListContextProvider value={useListController(props)}>{children}</ListContextProvider>
  </ResourceContextProvider>
);

ListBase.propTypes = {
  children: node.isRequired,
  resource: string.isRequired,
};

export default ListBase;
