import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { arrayOf, element, func, oneOfType, shape, string } from 'prop-types';
import React, { useState } from 'react';
import ContextMenu from '../ContextMenu';

const ContextMenuButton = ({ contextMenuItems }) => {
  const [clickEvent, setClickEvent] = useState();

  if (!contextMenuItems?.length) return null;

  return (
    <>
      <IconButton onClick={setClickEvent}>
        <MoreVert />
      </IconButton>

      <ContextMenu contextItems={contextMenuItems} clickEvent={clickEvent} />
    </>
  );
};

ContextMenuButton.propTypes = {
  contextMenuItems: arrayOf(shape({ key: string, onClick: func, component: oneOfType([string, element]) })),
};

ContextMenuButton.defaultProps = {
  contextMenuItems: [],
};

export default ContextMenuButton;
