import { PRODUCTS } from './productName';

const QUOTE_PARTNERS = [
  'AGOYU',
  'ALL_MY_SONS',
  'BELLHOP',
  'CEND',
  'HAVENLY',
  'HELLO_ALFRED',
  'JK_MOVING',
  'MOVE_CADDY',
  'MOVED',
  'OFFERPAD',
  'PODS',
  'Recruit_Certs',
  'Redfin_Conci',
  'ReloSolutionsGroup',
  'SHYFT',
  'WALKBOARD',
  'VIRTUO',
  'ZIPPY',
  'ZIPPYCORPRELO',
];

const QUOTE_PARTNER_PAID_PARTNERS = [
  'CEND',
  'HAVENLY',
  'HELLO_ALFRED',
  'JK_MOVING',
  'MOVE_CADDY',
  'MOVED',
  'Redfin_Conci',
  'ReloSolutionsGroup',
  'SHYFT',
  'WALKBOARD',
  'ZIPPY',
  'ZIPPYCORPRELO',
];

const QUOTE_STANDARD_SERVICE_PARTNERS = [
  'AGOYU',
  'ALL_MY_SONS',
  'BELLHOP',
  'CEND',
  'HAVENLY',
  'HELLO_ALFRED',
  'JK_MOVING',
  'MOVE_CADDY',
  'MOVED',
  'Recruit_Certs',
  'Redfin_Conci',
  'ReloSolutionsGroup',
  'SHYFT',
  'WALKBOARD',
  'VIRTUO',
];

const QUOTE_CONTRACT_PRICE_PARTNERS = [
  'AGOYU',
  'CEND',
  'HAVENLY',
  'HELLO_ALFRED',
  'JK_MOVING',
  'MOVE_CADDY',
  'MOVED',
  'OFFERPAD',
  'WALKBOARD',
  'VIRTUO',
  'ZIPPY',
];

const QUOTE_LD_PRICING_ENGINE_PARTNERS = ['BELLHOP', 'ZIPPYCORPRELO'];

const QUOTE_PRODUCT_BRE_PRICE_PARTNERS = ['OFFERPAD', 'ZIPPY'];

const QUOTE_PARTNER_INVENTORY_AREA_CHOICES = {
  OFFERPAD: [
    {
      id: 1699,
      name: '0 - 1,700',
    },
    {
      id: 2398,
      name: '1,701 - 2,399',
    },
    {
      id: 2799,
      name: '2,400 - 2,800',
    },
    {
      id: 2801,
      name: '2,801 +',
    },
  ],
};

const QUOTE_AFFILIATES = [
  'e30272fb-6700-4b2c-ba46-06105ac25515', // Ark
  'AVA',
  'Bellhop_Referrals',
  'CITIZEN_HOME_SOLUTIONS',
  'CUBESMART',
  'c69ab725-bcf3-4ce2-95fd-45eb91433d70', // ElseAL
  'ac712d33-0b6e-460b-8c8b-b5f0b8d56e04', // Go Mini's
  'HOMEBINDER',
  'HOME_DEPOT',
  '4c7f7a84-199a-44a8-98df-4f4751d4e5f9', // Invitation Homes
  'JMG',
  '631b3df0-8450-4121-ab2b-316e221f31ad', // Move Easy
  'MOVINGFUL',
  'MY_UTILITIES',
  'OUR_MOVING_CONCIERGE',
  'POWERON',
  'PODS',
  'REDFIN',
  'REDWOOD',
  'RHINO',
  '6c04a5b8-174b-48a4-bc02-ca01eaab8c1f', // Rent a crate
  'SMART_CITY',
];

const QUOTE_CUSTOMER_PAID_PRODUCTS = {
  OFFERPAD: [PRODUCTS.ADDITIONALSTOP.id, PRODUCTS.ASSEMBLY.id, PRODUCTS.DISASSEMBLY.id],
};

export {
  QUOTE_AFFILIATES,
  QUOTE_CONTRACT_PRICE_PARTNERS,
  QUOTE_CUSTOMER_PAID_PRODUCTS,
  QUOTE_LD_PRICING_ENGINE_PARTNERS,
  QUOTE_PARTNERS,
  QUOTE_PARTNER_INVENTORY_AREA_CHOICES,
  QUOTE_PARTNER_PAID_PARTNERS,
  QUOTE_PRODUCT_BRE_PRICE_PARTNERS,
  QUOTE_STANDARD_SERVICE_PARTNERS,
};
