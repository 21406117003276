import accounts from '@/resources/accounts';
import carriers from '@/resources/carriers';
import communications from '@/resources/communications';
import customers from '@/resources/customers';
import jobSlotTransactions from '@/resources/jobSlotTransaction';
import jobSlots from '@/resources/jobSlots';
import jobs from '@/resources/jobs';
import markets from '@/resources/markets';
import orders from '@/resources/orders';
import pricingLayers from '@/resources/pricingLayers';
import promoCodes from '@/resources/promoCodes';
import quotes from '@/resources/quotes';
import thirdPartyCrew from '@/resources/thirdPartyCrew';
import thirdPartyWorker from '@/resources/thirdPartyWorker';
import users from '@/resources/users';

import connectedAccounts from '@/resources/admin/connectedAccounts';
import orderItems from '@/resources/admin/orderItems';
import products from '@/resources/admin/products';
import stripeCustomers from '@/resources/admin/stripeCustomers';
import stripeTransactions from '@/resources/admin/stripeTransactions';

/* Admin Resources with standard views */
const ADMIN_RESOURCES = [connectedAccounts, orderItems, products, stripeCustomers, stripeTransactions];

/* Standard Resources with standard views - may include custom routes - see individual resource definition */
const STANDARD_RESOURCES = [
  accounts,
  carriers,
  communications,
  customers,
  jobs,
  jobSlots,
  jobSlotTransactions,
  markets,
  orders,
  pricingLayers,
  promoCodes,
  quotes,
  thirdPartyCrew,
  thirdPartyWorker,
  users,
];

export { ADMIN_RESOURCES, STANDARD_RESOURCES };
