import React, { createContext, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { node } from 'prop-types';

const DateLayerSummaryContext = createContext({});

const DateLayerSummaryProvider = ({ children }) => {
  const [filters, setFilters] = useState({ effective_date_lte: dayjs().format('YYYY-MM-DD') });
  const [selectedLayerGroup, setSelectedLayerGroup] = useState(null);
  const [dataEnabled, setDataEnabled] = useState(false);

  useEffect(() => {
    switch (filters?.date_lookup) {
      case 'current':
        if (filters?.effective_date_lte && (filters?.market_id || filters?.service_id)) {
          setDataEnabled(true);
          return;
        }
        break;
      case 'historical':
      case 'upcoming':
        if (filters?.effective_date_lte && filters?.market_id && filters?.service_id) {
          setDataEnabled(true);
          return;
        }
        break;
      default:
        break;
    }
    setDataEnabled(false);
  }, [filters]);

  const value = useMemo(
    () => ({
      filters,
      setFilters,
      dataEnabled,
      setDataEnabled,
      selectedLayerGroup,
      setSelectedLayerGroup,
    }),
    [dataEnabled, filters, selectedLayerGroup],
  );

  return <DateLayerSummaryContext.Provider value={value}>{children}</DateLayerSummaryContext.Provider>;
};

DateLayerSummaryProvider.propTypes = {
  children: node.isRequired,
};

export { DateLayerSummaryContext, DateLayerSummaryProvider };
