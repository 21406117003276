import React from 'react';

import { string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import { ORDER_ATTACHMENT_FORMS } from '@/constants/order';
import CustomerWalkthroughForm from './CustomerWalkthroughForm';
import NoteForm from './NoteForm';
import PreMoveForm from './PreMoveForm';
import PodsForm from './PodsForm';

const MoverNotes = ({ source }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  const imageListStyle = { width: 800, height: 300 };

  const imageStyle = {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
  };

  const listItemTextStyle = { maxWidth: 300, wordWrap: 'break-word', whiteSpace: 'pre-line' };

  let content;
  switch (record.attachment_type) {
    case ORDER_ATTACHMENT_FORMS.CUSTOMER_WALKTHROUGH:
      content = (
        <CustomerWalkthroughForm
          source={source}
          imageListStyle={imageListStyle}
          imageStyle={imageStyle}
          listItemTextStyle={listItemTextStyle}
        />
      );
      break;
    case ORDER_ATTACHMENT_FORMS.NOTE:
      content = (
        <NoteForm
          source={source}
          imageListStyle={imageListStyle}
          imageStyle={imageStyle}
          listItemTextStyle={listItemTextStyle}
        />
      );
      break;
    case ORDER_ATTACHMENT_FORMS.PODS:
      content = (
        <PodsForm
          source={source}
          imageListStyle={imageListStyle}
          imageStyle={imageStyle}
          listItemTextStyle={listItemTextStyle}
        />
      );
      break;
    case ORDER_ATTACHMENT_FORMS.PRE_MOVE:
      content = (
        <PreMoveForm
          source={source}
          imageListStyle={imageListStyle}
          imageStyle={imageStyle}
          listItemTextStyle={listItemTextStyle}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  return content;
};

MoverNotes.propTypes = {
  source: string.isRequired,
};

export default MoverNotes;
