import React from 'react';

import { string } from 'prop-types';
import { usePermissions, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import { CUSTOMER_ACCOUNT_DETAIL_SECTION, CUSTOMER_ACCOUNT_TAB } from '@/constants/customerAccount';

import CustomerSectionHeader from '../../../Common/SectionHeader';

const CustomerDetailsSectionHeader = ({ tab, section, title }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  let contextMenuItems = [];

  const overviewMenuItems = [
    ...(permissions.tools?.hqadmin?.customer_accounts?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(`/${resources.CUSTOMERS}/${record?.id}/${tab}/${section}/edit`);
            },
            component: 'Edit details',
          },
        ]
      : []),
  ];

  switch (section) {
    case CUSTOMER_ACCOUNT_DETAIL_SECTION.OVERVIEW:
      contextMenuItems = overviewMenuItems;
      break;
    default:
      break;
  }

  return <CustomerSectionHeader title={title} contextMenuItems={contextMenuItems} />;
};

CustomerDetailsSectionHeader.propTypes = {
  tab: string,
  section: string.isRequired,
  title: string.isRequired,
};

CustomerDetailsSectionHeader.defaultProps = {
  tab: CUSTOMER_ACCOUNT_TAB.DETAILS,
};
export default CustomerDetailsSectionHeader;
