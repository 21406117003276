const PREFERENCES_KEYS = {
  UI: {
    TIME_PREFERENCE: 'preferences.ui.timePreference',
  },
};

const PREFERENCES_VALUES = {
  UI: {
    TIME_PREFERENCE: {
      LOCAL: 'local',
      USER: 'user',
    },
  },
};

export { PREFERENCES_KEYS, PREFERENCES_VALUES };
