import React from 'react';

import { CreateBase, Form, ListButton, SaveButton, TextInput } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';

const UserCreate = () => (
  <CreateBase resource={resources.USERS} redirect="show">
    <Card mt={3} component={Box}>
      <CardHeader title="Create User" />
      <Divider />
      <Form>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput source="name" required fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="email" required fullWidth />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid item xs={4} display="flex" justifyContent="space-between">
            <ListButton label="Cancel" icon={null} color="neutral" />
            <SaveButton label="Submit" icon={null} />
          </Grid>
        </CardContent>
      </Form>
    </Card>
  </CreateBase>
);

export default UserCreate;
