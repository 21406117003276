import React from 'react';

import { string } from 'prop-types';
import { useInput } from 'react-admin';

const HiddenInput = ({ source }) => {
  const { id, field } = useInput({ source });

  return <input id={id} {...field} type="hidden" />;
};

HiddenInput.propTypes = {
  source: string.isRequired,
};

export default HiddenInput;
