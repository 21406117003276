import React from 'react';
import { shape, string } from 'prop-types';
import { useRecordContext } from 'react-admin';

import TextFieldWrapper from '@/fields/TextFieldWrapper';

const ConcatenatingStringField = ({ start, end, source, textFieldProps }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  const value = record[source];
  const concatenateValue = `${start}${value}${end}`;

  return <TextFieldWrapper value={concatenateValue} textFieldProps={textFieldProps} />;
};

ConcatenatingStringField.propTypes = {
  start: string,
  end: string,
  source: string.isRequired,
  textFieldProps: shape({}),
};

ConcatenatingStringField.defaultProps = {
  textFieldProps: {},
  start: '',
  end: '',
};

export default ConcatenatingStringField;
