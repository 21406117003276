import { BulkActionsToolbarClasses } from 'react-admin';

export default ({ palette }) => ({
  styleOverrides: {
    root: {
      [`& .${BulkActionsToolbarClasses.toolbar}`]: {
        backgroundColor: palette.mode === 'light' ? palette.neutral.light : palette.secondary.darkest,
        transform: 'translateY(-64px)',
        height: 64,
      },
      [`& .${BulkActionsToolbarClasses.collapsed}`]: {
        transform: 'translateY(0)',
        height: 0,
      },
      [`& .${BulkActionsToolbarClasses.title}`]: {
        alignItems: 'center',
        color: palette.mode === 'light' ? palette.neutral.darkest : palette.neutral.lightest,
      },
    },
  },
});
