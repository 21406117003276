import React from 'react';

import { LoadingIndicator, RecordContextProvider, useGetOne, useShowContext } from 'react-admin';

import { Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import AccessFlags from './AccessFlags';
import AdditionalItems from './AdditionalItems';
import Estimation from './Estimation';
import Notes from './Notes';
import Rooms from './Rooms';
import OfferpadInventory from './specialInventory/OfferpadInventory';
import ZippyInventory from './specialInventory/ZippyInventory';

const Inventory = () => {
  const { record: { id: orderId, partner_id: partnerId } = {} } = useShowContext();

  const { data, isLoading } = useGetOne(
    resources.ORDERS,
    {
      id: orderId,
      meta: {
        subResource: 'inventory',
      },
    },
    { enabled: Boolean(orderId) },
  );

  if (!orderId) return null;

  if (!data && isLoading) {
    return (
      <Grid item xs={12} textAlign="center">
        <LoadingIndicator width="100%" size={100} />
      </Grid>
    );
  }

  if (!data) {
    return (
      <Grid item xs={12} textAlign="center">
        <Typography variant="h4">No Inventory Data found</Typography>
      </Grid>
    );
  }

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Notes />
        </Grid>

        <Grid container item xs={6} gap={2} alignContent="flex-start">
          <Grid item xs={12}>
            <Estimation />
          </Grid>
          <Grid item xs={12}>
            <AccessFlags />
          </Grid>
          {data.is_room_based_inventory ? (
            <>
              <Grid item xs={12}>
                <Rooms />
              </Grid>
              <Grid item xs={12}>
                <AdditionalItems />
              </Grid>
            </>
          ) : null}
          {partnerId === 'ZIPPY' ? (
            <Grid item xs={12}>
              <ZippyInventory />
            </Grid>
          ) : null}
          {partnerId === 'OFFERPAD' ? (
            <Grid item xs={12}>
              <OfferpadInventory />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

export default Inventory;
