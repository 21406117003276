import React, { useContext, useEffect } from 'react';

import dayjs from 'dayjs';
import { DateInput, RadioButtonGroupInput, ReferenceInput, SelectInput } from 'react-admin';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';

import { Alert, Box, Button, Card, CardContent } from '@mui/material';

import * as resources from '@/api/resources';
import { LaneLayerSummaryContext } from '@/providers/Pricing/LaneLayerSummary';

const PricingOptions = () => {
  const { setDataEnabled, setFilters } = useContext(LaneLayerSummaryContext);
  const { getValues, formState, reset, setValue, clearErrors } = useFormContext();

  const dateLookup = useWatch({ name: 'date_lookup' });
  const selectedStartMarket = useWatch({ name: 'start_market_id' });
  const selectedEndMarket = useWatch({ name: 'end_market_id' });
  const isHistorical = dateLookup === 'historical';

  useEffect(() => {
    const { isDirty, isSubmitSuccessful } = formState;
    if (isDirty) {
      setDataEnabled(false);
    }
    if (isSubmitSuccessful) {
      const formValues = getValues();
      reset(formValues, { keepDirty: false });
      // Filter null entries from form data and set in context
      setFilters(Object.fromEntries(Object.entries(formValues).filter(([, v]) => v != null)));
    }
  }, [formState]);

  useEffect(() => {
    if (dateLookup === 'current') {
      const nowDateString = dayjs().format('YYYY-MM-DD');
      setValue('effective_date_lte', nowDateString);
      setValue('is_current', true);
    } else {
      // Want to unset this filter for non current lookup, because layers may be a mix of current/non current that make up historical results
      setValue('is_current', null);
    }
  }, [dateLookup]);

  useEffect(() => {
    clearErrors(['minimumFilter', 'equalMarkets']);
  }, [dateLookup, selectedStartMarket, selectedEndMarket]);

  return (
    <Card mt={3} component={Box}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <RadioButtonGroupInput
              label=""
              defaultValue="current"
              source="date_lookup"
              choices={[
                { id: 'current', name: 'Current' },
                { id: 'historical', name: 'Historical' },
              ]}
            />
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Box display={isHistorical ? 'block' : 'none'}>
              <DateInput source="effective_date_lte" required label="Effective Date" />
            </Box>
            <ReferenceInput
              source="start_market_id"
              reference={resources.MARKETS}
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="end_market_id"
              reference={resources.MARKETS}
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {Object.values(formState.errors).map((error) => (
              <Alert variant="outlined" severity="warning">
                {error.message}
              </Alert>
            ))}
          </Box>
          <Box>
            <Button variant="outlined" type="submit">
              Search
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const PricingOptionsForm = () => {
  const methods = useForm({ defaultValues: { date_lookup: 'current', effective_date_lte: dayjs().format('YYYY-MM-DD') } });

  const onSubmit = (data) => {
    if (!data.start_market_id && !data.end_market_id) {
      methods.setError('minimumFilter', {
        type: 'manual',
        message: 'Please select a start or end market',
      });
      return;
    }
    if (data.start_market_id === data.end_market_id) {
      methods.setError('equalMarkets', {
        type: 'manual',
        message: 'Please select different start and end markets',
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PricingOptions />
      </form>
    </FormProvider>
  );
};

export default PricingOptionsForm;
