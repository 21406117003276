import React from 'react';

import { BooleanField, EmailField, Show, SimpleShowLayout, TextField } from 'react-admin';

const AdminStripeCustomersShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" emptyText="N/A" />
      <TextField source="name" emptyText="N/A" />
      <EmailField source="email" emptyText="N/A" />
      <BooleanField source="isdeleted" emptyText="N/A" />
      <TextField source="bt_stripe__account__r__code__c" emptyText="N/A" label="Account code" />
      <TextField source="bt_stripe__customer_id__c" emptyText="N/A" label="Customer id" />
      <TextField source="bt_stripe__default_source_id__c" emptyText="N/A" label="Default source id" />
      <TextField source="bt_stripe__default_source__r__code__c" emptyText="N/A" label="Default source code" />
      <TextField source="bt_stripe__payment_gateway__r__code__c" emptyText="N/A" label="Payment gateway" />
    </SimpleShowLayout>
  </Show>
);

export default AdminStripeCustomersShow;
