import React, { useState } from 'react';

import mx from 'mixpanel-browser';
import { RecordContextProvider, useGetList, useShowContext, useTheme } from 'react-admin';

import { Refresh } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

import * as resources from '@/api/resources';
import AlertActiveDevelopment from '@/shared/AlertActiveDevelopment';

import Event from './Event';

const OrderContentHistory = () => {
  const [themeMode] = useTheme();
  const lightMode = Boolean(themeMode === 'light');

  const { record: { id: orderId } = {} } = useShowContext();
  const [includeEvents, setIncludeEvents] = useState({
    orderEvents: false,
    orderItemEvents: false,
    jobSlotEvents: false,
    slotTransactionEvents: false,
    orderTransactionEvents: false,
  });

  const { data, isLoading, isFetching, refetch } = useGetList(
    resources.ORDERS,
    {
      meta: {
        resourceId: orderId,
        subResource: 'event_history',
      },
      filter: {
        include: includeEvents,
      },
      pagination: {
        perPage: 999,
        page: 1,
      },
    },
    {
      enabled: false,
    },
  );

  const isUpdating = Boolean(isLoading || isFetching);

  const handleChange = (event) => {
    setIncludeEvents({
      ...includeEvents,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card component={Box}>
          <CardHeader title="Order History" />
          <AlertActiveDevelopment />
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="space-between" pb={data?.length ? 2 : 0}>
              <Box>
                <FormControlLabel
                  label="Order"
                  control={<Switch name="orderEvents" checked={includeEvents.orderEvents} onChange={handleChange} />}
                />
                <FormControlLabel
                  label="Order Items"
                  control={<Switch name="orderItemEvents" checked={includeEvents.orderItemEvents} onChange={handleChange} />}
                />
                <FormControlLabel
                  label="Job Slots"
                  control={<Switch name="jobSlotEvents" checked={includeEvents.jobSlotEvents} onChange={handleChange} />}
                />
                <FormControlLabel
                  label="Slot Transactions"
                  control={
                    <Switch
                      name="slotTransactionEvents"
                      checked={includeEvents.slotTransactionEvents}
                      onChange={handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label="Transactions"
                  control={
                    <Switch
                      name="orderTransactionEvents"
                      checked={includeEvents.orderTransactionEvents}
                      onChange={handleChange}
                    />
                  }
                />
              </Box>
              <Button
                variant="outlined"
                disabled={isUpdating ?? null}
                onClick={() => {
                  refetch();
                  mx.track('Order Management - Fetching order history', {
                    resource: resources.ORDERS,
                    resourceId: orderId,
                    metadata: {
                      includeEvents,
                    },
                  });
                }}
                startIcon={isUpdating ? <CircularProgress size={20} /> : <Refresh />}
              >
                Load Event Logs
              </Button>
            </Box>
          </CardContent>
          {data?.length ? (
            <Grid
              container
              spacing={2}
              pb={2}
              sx={{ backgroundColor: ({ palette }) => (lightMode ? palette.neutral.lighter : palette.secondary.darkest) }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  background: ({ palette }) => (lightMode ? palette.neutral.lightest : palette.secondary.dark),
                  ...(lightMode
                    ? {
                        borderTop: ({ palette }) => `1px solid ${palette.neutral.main}`,
                        borderBottom: ({ palette }) => `1px solid ${palette.neutral.main}`,
                      }
                    : {}),
                }}
              >
                <Box px={4} pb={1}>
                  {/* These grid column widths must match those in the events to keep header aligned */}
                  <Grid container gap={1}>
                    {/* Spacer for expand/collapse */}
                    <Grid item xs={0.5} />
                    <Grid item xs={1.5}>
                      <Typography>Timestamp</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>Action</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>User</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {data.map((event) => (
                <Grid item xs={12} key={event.id} mx={2}>
                  <RecordContextProvider value={event}>
                    <Event />
                  </RecordContextProvider>
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderContentHistory;
