import React from 'react';
import { shape, string } from 'prop-types';
import { LinearProgress, useRecordContext, useReference } from 'react-admin';

import * as resources from '@/api/resources';
import TextFieldWrapper from '@/fields/TextFieldWrapper';

const MarketNameField = ({ source, textFieldProps }) => {
  const record = useRecordContext();

  const {
    referenceRecord: marketData,
    isLoading,
    isFetching,
  } = useReference({
    reference: resources.MARKETS,
    id: record?.[source],
    enabled: Boolean(record?.[source]),
    options: {
      staleTime: 6 * 60 * (60 * 1000), // 6 hours
    },
  });

  if (!record || !record[source]) return null;

  if (!marketData && (isLoading || isFetching)) return <LinearProgress />;

  return <TextFieldWrapper value={marketData?.name} textFieldProps={textFieldProps} />;
};

MarketNameField.propTypes = {
  source: string.isRequired,
  textFieldProps: shape({}),
};

MarketNameField.defaultProps = {
  textFieldProps: {},
};

export default MarketNameField;
