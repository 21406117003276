const formatAddressParts = ({ line_1: line1, line_2: line2, city, state, postal_id: postalId }) => {
  let formattedAddress = '';
  formattedAddress += line1 ?? '';
  formattedAddress += line2 ? ` ${line2}` : '';
  formattedAddress += city ? `, ${city}` : '';
  formattedAddress += state ? `, ${state}` : '';
  formattedAddress += postalId ? ` ${postalId}` : '';
  return formattedAddress;
};

export default formatAddressParts;
