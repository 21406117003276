import React from 'react';

import { Form, SaveButton, SelectInput, ShowButton, Toolbar, required, useRecordContext } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { THIRD_PARTY_CREW_BACKGROUND_CHECKS } from '@/constants/thirdPartyCrewAccount';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ShowButton label="Cancel" icon={null} color="secondary" />
    <SaveButton label="Update" icon={null} />
  </Toolbar>
);

const ThirdPartyCrewDetailsRecruitmentEdit = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Card mt={3} component={Box}>
      <CardHeader title="Recruitment" />

      <Divider />

      <CardContent>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectInput
                source="background_check"
                choices={THIRD_PARTY_CREW_BACKGROUND_CHECKS}
                validate={[required()]}
                label="Background check"
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider />
          <CustomToolbar />
        </Form>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDetailsRecruitmentEdit;
