import React from 'react';

import { Box, Card, CardHeader } from '@mui/material';

const PricingLayersDashboard = () => (
  <Card mt={3} component={Box}>
    <CardHeader title="Pricing Layers Dashboard" />
  </Card>
);

export default PricingLayersDashboard;
