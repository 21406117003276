import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

const EditNotesForm = () => {
  const { setAction } = useContext(OrderActionContext);
  const { control } = useFormContext();

  return (
    <>
      <Box p={2}>
        <Controller
          defaultValue=""
          name="new_notes_value"
          control={control}
          render={({ field }) => <TextField label="Notes" variant="outlined" fullWidth multiline maxRows={25} {...field} />}
        />
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const EditNotes = () => {
  const { setAction } = useContext(OrderActionContext);
  const {
    record: { id: orderId, notes, confirmation_id: confirmationId },
  } = useShowContext();

  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSettled = (data, error) => {
    if (error) {
      notify(`Saving Order notes failed - ${error.status} - ${error.message}`, {
        type: 'error',
      });
    } else if (data) {
      mx.track(`Order Management - Order Notes updated`, {
        resource: resources.ORDERS,
        resourceId: orderId,
        confirmationId,
      });

      notify('Order Notes updated', { type: 'success' });
      refresh();
      setAction(null);
    }
  };

  const onSubmit = (submittedData) => {
    update(
      resources.ORDERS,
      {
        id: orderId,
        data: submittedData,
        meta: {
          subResource: 'notes',
        },
      },
      { onSettled },
    );
  };

  return (
    <Box px={2} py={4} minWidth={500}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Edit Notes
      </Typography>
      <Divider />
      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Form onSubmit={onSubmit} record={{ new_notes_value: notes }}>
            <EditNotesForm />
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default EditNotes;
