import React from 'react';

import { Route } from 'react-router';

import * as resources from '@/api/resources';

import MarketList from './List';
import MarketShow from './Show';
import MarketCreate from './Create';
import MarketEdit from './Edit';

const markets = () => ({
  name: resources.MARKETS,
  create: MarketCreate,
  list: MarketList,
  show: MarketShow,
  children: [<Route key="market-edit" path=":marketId/edit/:tab/:section" element={<MarketEdit />} />],
});

export default markets;
