import React from 'react';

import { Labeled, LoadingIndicator, usePermissions, useShowContext } from 'react-admin';
import { useNavigate } from 'react-router';

import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MARKET_PERFORMANCE_THRESHOLDS_SECTION, MARKET_TAB } from '@/constants/market';
import PercentageField from '@/fields/PercentageField';

import MarketSectionHeader from '../Common/SectionHeader';

const MarketPerformanceThresholds = () => {
  const { record, isLoading, isFetching } = useShowContext();
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  if (!record) return null;

  const contextMenuItems = [
    ...(permissions.tools?.hqadmin?.markets?.update
      ? [
          {
            key: 'edit',
            onClick: () => {
              navigate(
                `/${resources.MARKETS}/${record?.id}/edit/${MARKET_TAB.PERFORMANCE_THRESHOLDS}/${MARKET_PERFORMANCE_THRESHOLDS_SECTION.THRESHOLDS}`,
              );
            },
            component: 'Edit thresholds',
          },
        ]
      : []),
  ];

  return (
    <Box width="100%">
      {isLoading || isFetching ? (
        <Box display="flex" justifyContent="center">
          <LoadingIndicator width="100%" size={100} />
        </Box>
      ) : (
        <Card>
          <MarketSectionHeader
            title="Thresholds"
            tab={MARKET_TAB.PERFORMANCE_THRESHOLDS}
            section={MARKET_PERFORMANCE_THRESHOLDS_SECTION.THRESHOLDS}
            contextMenuItems={contextMenuItems}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2} px={2}>
              {/* Late Rate Section */}
              <Grid item xs={12}>
                <Typography variant="h6">Late Rate</Typography>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="late_rate_excellent_threshold"
                    label="Excellent Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="late_rate_good_threshold"
                    label="Good Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="late_rate_needs_review_threshold"
                    label="Needs Review Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="late_rate_poor_threshold"
                    label="Poor Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>

              {/* Removal Rate Section */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Removal Rate</Typography>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="removal_rate_excellent_threshold"
                    label="Excellent Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="removal_rate_good_threshold"
                    label="Good Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="removal_rate_needs_review_threshold"
                    label="Needs Review Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="removal_rate_poor_threshold"
                    label="Poor Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Damage Rate Section */}
              <Grid item xs={12}>
                <Typography variant="h6">Damage Rate</Typography>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="damage_rate_excellent_threshold"
                    label="Excellent Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="damage_rate_good_threshold"
                    label="Good Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="damage_rate_needs_review_threshold"
                    label="Needs Review Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="damage_rate_poor_threshold"
                    label="Poor Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* No Pad Wrap Rate Section */}
              <Grid item xs={12}>
                <Typography variant="h6">No Pad Wrap Rate</Typography>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="no_pad_wrap_excellent_threshold"
                    label="Excellent Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="no_pad_wrap_good_threshold"
                    label="Good Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="no_pad_wrap_needs_review_threshold"
                    label="Needs Review Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <PercentageField
                    isFraction={false}
                    source="no_pad_wrap_poor_threshold"
                    label="Poor Threshold"
                    emptyText="N/A"
                  />
                </Labeled>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default MarketPerformanceThresholds;
