import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import { Form, useNotify, useRefresh, useShowContext, useUpdate } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { bool } from 'prop-types';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';

import REASONS_BY_TYPE from './cancelReaonsByType';

const CancelForm = ({ isLoading }) => {
  const { setAction } = useContext(OrderActionContext);
  const { control, watch } = useFormContext();

  const cancelType = watch('cancel_type');

  return (
    <>
      <Box p={2}>
        <Box mb={2}>
          <Controller
            defaultValue=""
            name="cancel_type"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="type-label">Type</InputLabel>
                <Select labelId="type-label" label="Type" {...field} required>
                  <MenuItem value="HQ">HQ</MenuItem>
                  <MenuItem value="HQ on Behalf of Customer">HQ on Behalf of Customer</MenuItem>
                  <MenuItem value="Customer">Customer</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Box>
        <Box>
          <Controller
            defaultValue=""
            name="cancel_reason"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="reason-label">Reason</InputLabel>
                <Select labelId="reason-label" label="Reason" {...field} required disabled={!cancelType}>
                  {REASONS_BY_TYPE[cancelType]?.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading ?? null}
          startIcon={isLoading ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

CancelForm.propTypes = {
  isLoading: bool.isRequired,
};

const CancelOrder = () => {
  const { setAction } = useContext(OrderActionContext);
  const {
    record: { id: orderId, confirmation_id: confirmationId },
  } = useShowContext();
  const [update, { isLoading, isSubmitting }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = ({ message, status }) => {
    notify(`Order Cancel failed - ${status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track(`Order Management - Order Cancelled`, {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
    });

    notify('Order Cancelled', {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    update(
      resources.ORDERS,
      {
        id: orderId,
        data: {
          cancel_type: data.cancel_type,
          cancel_reason: data.cancel_reason,
        },
        meta: {
          method: 'PUT',
          subResource: 'cancel',
        },
      },
      { onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} minWidth={425}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Cancel Order
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <CancelForm isLoading={Boolean(isLoading || isSubmitting)} />
        </Form>
      </Box>
    </Box>
  );
};

export default CancelOrder;
