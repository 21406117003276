import React from 'react';
import { number, oneOfType, shape, string } from 'prop-types';

import { Typography } from '@mui/material';

const TextFieldWrapper = ({ value, textFieldProps, variant }) => (
  <Typography component="span" variant={variant} {...textFieldProps}>
    {value}
  </Typography>
);

TextFieldWrapper.propTypes = {
  variant: string,
  value: oneOfType([string, number]).isRequired,
  textFieldProps: shape({}),
};

TextFieldWrapper.defaultProps = {
  variant: 'body2',
  textFieldProps: {},
};

export default TextFieldWrapper;
