import React from 'react';

import { EmailField, Labeled, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import { CARRIER_ACCOUNT_DETAIL_SECTION, CARRIER_ACCOUNT_TAB } from '@/constants/carrierAccount';

import CarrierDetailsSectionHeader from '../Common/SectionHeader';

const ContactInfo = () => (
  <Card mt={3} component={Box}>
    <CarrierDetailsSectionHeader
      title="Contact Info"
      tab={CARRIER_ACCOUNT_TAB.DETAILS}
      section={CARRIER_ACCOUNT_DETAIL_SECTION.CONTACT_INFO}
    />
    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <Labeled>
            <TextField source="phone" label="Phone Number" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled>
            <EmailField source="email" label="Email Address" emptyText="N/A" />
          </Labeled>
        </Grid>
        {/*
        TODO: Temp removing as locations are not returned on carriers
        <Grid item xs={4}>
          <Labeled>
            <FunctionField
              label="Mailing Address"
              render={({ location }) => (
                <TextFieldWrapper
                  value={formatAddressParts({
                    ...location,
                    line_1: location.street_address_1 ? toPascalCase(location.street_address_1) : '',
                    line_2: location.street_address_2 ? toPascalCase(location.street_address_2) : '',
                    city: toPascalCase(location.city),
                  })}
                />
              )}
            />
          </Labeled>
        </Grid> */}
      </Grid>
    </CardContent>
  </Card>
);

export default ContactInfo;
