import React from 'react';

import { EmailField, Labeled, ShowBase, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';
import UserSectionHeader from './Common/UserSectionHeader';

const UserShow = () => (
  <ShowBase>
    <Card mt={3} component={Box}>
      <UserSectionHeader title="Overview" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Labeled label="Name">
              <TextField source="name" />
            </Labeled>
          </Grid>
          <Grid item xs={4}>
            <Labeled label="Email">
              <EmailField source="email" />
            </Labeled>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </ShowBase>
);

export default UserShow;
