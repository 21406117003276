import React from 'react';

import { SelectInput, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import {
  THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_REASONS,
  THIRD_PARTY_CREW_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS,
} from '@/constants/thirdPartyCrewAccount';

const ReasonsInput = () => {
  const { watch } = useFormContext();
  const newStatus = watch('new_status');

  return (
    <SelectInput
      source="reason"
      choices={
        newStatus === 'Inactive'
          ? THIRD_PARTY_CREW_ACCOUNT_INACTIVE_STATUS_DROPDOWN_REASONS
          : THIRD_PARTY_CREW_ACCOUNT_DROPDOWN_REASONS
      }
      validate={[required()]}
      fullWidth
      label="Status Reason"
      defaultValue="Other"
    />
  );
};

export default ReasonsInput;
