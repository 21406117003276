import { SidebarClasses } from 'react-admin';

export default () => ({
  styleOverrides: {
    root: {
      height: '100vh',
      [`& .${SidebarClasses.fixed}`]: {
        height: '100vh',
      },
    },
  },
});
