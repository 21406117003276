import React from 'react';

import { bool, func } from 'prop-types';

import { Box, Button, Card, CardContent, CardHeader, Divider, Modal, Typography } from '@mui/material';

const StatePrivacy = ({ open, onClose }) => {
  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Modal open={open} onClose={handleModalClose} sx={{ outline: 0 }}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="40vw"
        overflow="auto"
        bgcolor="background.paper"
        color="text.primary"
        boxShadow={24}
        sx={{
          transform: 'translate(-50%, -50%)',
          outline: 0,
        }}
      >
        <Card>
          <CardHeader title="CA / IL Privacy Notice" />
          <Divider />
          <CardContent>
            <Typography>
              At Bellhop, privacy is a priority and we do not sell your information. You can review our privacy policy at
              bellhop.com/privacy-policy.
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="center">
              <Button variant="contained" onClick={handleModalClose}>
                Acknowledge
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

StatePrivacy.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default StatePrivacy;
