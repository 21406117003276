import React, { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';
import { oneOf } from 'prop-types';
import {
  CreateBase,
  DateInput,
  Form,
  NumberInput,
  RadioButtonGroupInput,
  SelectArrayInput,
  SelectInput,
  minValue,
  required,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICING_LAYER_TYPES, PRICING_LAYER_TYPES_DISPLAY, SERVICES } from '@/constants/pricingLayers';

import MarketGroupSelection from '../shared/MarketGroupSelection';
import CollisionDetection from './CollisionDetection';
import FormControls from './FormControls';
import PriceDelta from './PriceDelta';

const formatValue = (value) => (value ? (value / 100).toFixed(2) : '');
const parseValue = (value) => (value ? Math.round(parseFloat(value) * 100) : null);

const DATE_TYPES = [
  PRICING_LAYER_TYPES.DAY_OF_MONTH,
  PRICING_LAYER_TYPES.DAY_OF_WEEK,
  PRICING_LAYER_TYPES.MONTH_OF_YEAR,
  PRICING_LAYER_TYPES.HOUR_OF_DAY,
  PRICING_LAYER_TYPES.LEAD_TIME,
];
const DATE_TYPE_CHOICES = DATE_TYPES.map((type) => ({ id: type, name: PRICING_LAYER_TYPES_DISPLAY[type] }));

const SelectArrayDateNumeric = ({ type }) => {
  const dateNumericChoices = useMemo(() => {
    switch (type) {
      case PRICING_LAYER_TYPES.DAY_OF_MONTH:
        return Array.from(Array(31).keys()).map((dateIndex) => ({ id: dateIndex + 1, name: dateIndex + 1 }));
      case PRICING_LAYER_TYPES.DAY_OF_WEEK:
        return [
          { id: 1, name: 'Monday' },
          { id: 2, name: 'Tuesday' },
          { id: 3, name: 'Wednesday' },
          { id: 4, name: 'Thursday' },
          { id: 5, name: 'Friday' },
          { id: 6, name: 'Saturday' },
          { id: 7, name: 'Sunday' },
        ];
      case PRICING_LAYER_TYPES.MONTH_OF_YEAR:
        return [
          { id: 1, name: 'January' },
          { id: 2, name: 'February' },
          { id: 3, name: 'March' },
          { id: 4, name: 'April' },
          { id: 5, name: 'May' },
          { id: 6, name: 'June' },
          { id: 7, name: 'July' },
          { id: 8, name: 'August' },
          { id: 9, name: 'September' },
          { id: 10, name: 'October' },
          { id: 11, name: 'November' },
          { id: 12, name: 'December' },
        ];
      case PRICING_LAYER_TYPES.HOUR_OF_DAY:
        return [
          { id: 8, name: '8am' },
          { id: 9, name: '9am' },
          { id: 10, name: '10am' },
          { id: 11, name: '11am' },
          { id: 12, name: '12pm' },
          { id: 13, name: '1pm' },
          { id: 14, name: '2pm' },
          { id: 15, name: '3pm' },
          { id: 16, name: '4pm' },
          { id: 17, name: '5pm' },
          { id: 18, name: '6pm' },
          { id: 19, name: '7pm' },
        ];
      case PRICING_LAYER_TYPES.LEAD_TIME:
        return [
          { id: 0, name: '0 days' },
          { id: 1, name: '1 day' },
          { id: 2, name: '2 days' },
          { id: 3, name: '3 days' },
          { id: 4, name: '4 days' },
          { id: 5, name: '5 days' },
          { id: 6, name: '6 days' },
          { id: 7, name: '7 days' },
        ];
      default:
        return [];
    }
  });

  return (
    <SelectArrayInput
      source="date_numeric"
      label={PRICING_LAYER_TYPES_DISPLAY[type]}
      validate={required()}
      choices={dateNumericChoices}
    />
  );
};

SelectArrayDateNumeric.propTypes = {
  type: oneOf(DATE_TYPES),
};

SelectArrayDateNumeric.defaultProps = {
  type: null,
};

const DateLayerForm = () => {
  const { getValues, reset } = useFormContext();
  const selectedDateType = useWatch({ name: 'date_numeric_type' });
  const selectedPriceType = useWatch({ name: 'price_type' });

  const minEffectiveDate = dayjs().add(1, 'day').format('YYYY-MM-DD'); // Minimum date for effective date is tomorrow, cannot input new pricing for the past
  const minEffectiveDateValidator = minValue(minEffectiveDate);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  const effectiveDateValidate = [required(), ...(isProduction ? [minEffectiveDateValidator] : [])];

  useEffect(() => {
    reset({
      // On date type change, preserve all current values in form except date numeric
      ...getValues(),
      date_numeric: '',
    });
  }, [selectedDateType]);

  useEffect(() => {
    reset({
      // On price type change, preserve all current values in form except price and multiplier
      ...getValues(),
      price: null,
      multiplier: null,
    });
  }, [selectedPriceType]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={2} lg={1.5}>
        <DateInput source="effective_date" validate={effectiveDateValidate} defaultValue={minEffectiveDate} />
      </Grid>
      <Grid item xs={2}>
        <SelectInput
          label="Date Type"
          source="date_numeric_type"
          validate={required()}
          choices={DATE_TYPE_CHOICES}
          defaultValue={PRICING_LAYER_TYPES.DAY_OF_MONTH}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectArrayDateNumeric type={selectedDateType} />
      </Grid>
      <Grid item xs={2}>
        <RadioButtonGroupInput
          source="price_type"
          choices={[
            { id: 'price', name: 'Price' },
            { id: 'multiplier', name: 'Multiplier' },
          ]}
          defaultValue="price"
        />
      </Grid>
      {selectedPriceType === 'price' ? (
        <Grid item xs={2}>
          <NumberInput source="price" format={formatValue} parse={parseValue} validate={required()} />
        </Grid>
      ) : null}
      {selectedPriceType === 'multiplier' ? (
        <Grid item xs={2}>
          <NumberInput source="multiplier" validate={[minValue(0), required()]} />
        </Grid>
      ) : null}
      <Grid item xs={4}>
        <SelectArrayInput
          fullWidth
          label="Service"
          autoComplete="off"
          source="service_ids"
          choices={Object.values(SERVICES)}
          // required to use this validator with SaveButton type="button"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12}>
        <MarketGroupSelection />
      </Grid>
    </Grid>
  );
};

const DateLayerCreateBatch = () => (
  <CreateBase resource={`${resources.PRICING_LAYERS}/batch`}>
    <Form>
      <Card component={Box} mt={3}>
        <CardHeader title="Create Batch - Date Pricing Layers" />
        <Divider />
        <CardContent>
          <DateLayerForm />
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <CollisionDetection />
        </Grid>
        <Grid item xs={12} lg={7}>
          <PriceDelta />
        </Grid>
      </Grid>
      <FormControls />
    </Form>
  </CreateBase>
);

export default DateLayerCreateBatch;
