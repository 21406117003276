import * as resources from '@/api/resources';

import CommunicationsCreate from './Create';

const communications = () => ({
  name: resources.COMMUNICATIONS,
  create: CommunicationsCreate,
});

export default communications;
