import React from 'react';

import { Datagrid, SelectInput, TextField, TextInput } from 'react-admin';

import { Box, Card, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import List from '@/ReactAdmin/List';
import EmptyResourceList from '@/shared/EmptyResourceList';

const OrderItemFilter = [
  <TextInput source="code__c" label="Code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="order__code__c" label="Order Code" alwaysOn resettable autoComplete="off" />,
  <TextInput source="product2__code__c" label="Product2 Code" alwaysOn resettable autoComplete="off" />,

  <SelectInput
    source="status__c"
    label="Status"
    choices={[
      { id: 'OPEN', name: 'OPEN' },
      { id: 'COMPLETE', name: 'COMPLETE' },
      { id: 'CANCELLED', name: 'CANCELLED' },
      { id: 'REMOVED', name: 'REMOVED' },
    ]}
    resettable
  />,
];

const AdminOrderItemList = () => (
  <Card mt={3} component={Box}>
    <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Order Items</Typography>
    </Box>
    <Divider />
    <List
      disableSyncWithLocation
      resource={`${resources.ADMIN}/orderitems`}
      filters={OrderItemFilter}
      exporter={false}
      empty={<EmptyResourceList text="No Order Items" />}
      hasCreate={false}
      storeKey={`${resources.ADMIN}_orderitems.listParams`}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" emptyText="N/A" />
        <TextField source="product2__id__c" emptyText="N/A" />
        <TextField source="order__id__c" emptyText="N/A" />
        <CurrencyField source="unitprice" textAlign="left" />
      </Datagrid>
    </List>
  </Card>
);

export default AdminOrderItemList;
